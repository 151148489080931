import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro'

const ClearBoth = styled.div`
clear: both;
display:block;
height:0px;
width: 100%;
`

class ObjReportItem extends PureComponent {
    render() {
        console.log('>>>>>>>>', this.props.data)
        return (

            <div className="itmeRowCount" id={this.props.id}>
                <div className="leftItemRow">

                </div>
                <div className="rightItemRow fullRow">
                    <span className={'itemRowChiled'}>{this.props.data.obj_type === 'main' ? this.props.data.pl_name : this.props.data.pl_name + ': ' + this.props.data.obj_name}, </span>
                    <ClearBoth/>
                </div>
                <ClearBoth/>
            </div>
        );
    }
}

ObjReportItem.propTypes = {};

export default ObjReportItem;
