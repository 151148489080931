/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import React, { useMemo, useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { objectsCompare, generateSize } from '../tools/diagramTools';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import DiagramTitle from './diagram_title';
import DataViewScore from './data_views/DataViewScore';
import DataViewPieChart from './data_views/DataViewPieChart';
import DataViewDynamics from './data_views/DataViewDynamics';
import DataViewTable from './data_views/DataViewTable';
import DataViewFunnel from './data_views/DataViewFunnel';
import DataViewVerticalBarDistribution from './data_views/DataViewVerticalBarDistribution';
import DataViewHorizontalBarColorDistribution from './data_views/DataViewHorizontalBarColorDistribution';
import DataViewChord from './data_views/DataViewChord';
import DataViewHeatmap from './data_views/DataViewHeatmap';
import DataViewCalendar from './data_views/DataViewCalendar';
import DataViewMarkDown from './data_views/DataViewMarkDown';
import DataViewOutdoorMap from './data_views/DataViewOutdoorMap';

import ContextMenuButton from './context_menu_button';
import Updater from './updater';

import { DiagramsContext } from '../index';

const MAX_FUNNEL_SET = 20;

const Diagram = React.memo(
    ({ dataView, isFreeHeight, iteration = 0, iterationsNumber = 1, editable, fullScreenShow = false, keepRatio = true, ...props }) => {
        const diagramContextValue = useContext(DiagramsContext);
        const {
            permanentCloudData,
            scrollWidth,
            colors,
            defaultSize,
            customInputParamsValues,
            globalInputParamsValues,
            reportData,
            lang,
            diagramOptionsByID,
            fullScreenId,
            storedInputParamsValues,
            currentSubSection,
            currentSection,
            storeInputParamsValues,
            diagramsInnerParams,
            reportingObjectsById,
            _refresh,
            _toggleModal,
            diagramInnerAction,
            cabinetMode,
            onContextMenuReportParamsClick,
            generateAtomicTableData,
            onSaveXLSXClick,
            onFullScreenClick,
            fullScreenExit,
            changeDiagramHeight,
            dataViewsByGroupID,
            deleteDiagramFromC_D,
            statusLoadDataDiagram
        } = diagramContextValue;

        const diagramWrapperRef = useRef();
        const { t } = useTranslation();
        const [stateInputParamsValues, setStateInputParamsValues] = useState({});
        const [clientHeight, set_clientHeight] = useState(null);
        let movement_timer = null;

        useLayoutEffect(() => {
            clearInterval(movement_timer);
            movement_timer = setTimeout(
                set_clientHeight(() => diagramWrapperRef.current.clientHeight),
                100
            );
        }, [reportData, diagramWrapperRef.current && diagramWrapperRef.current.clientHeight]);

        const changeFinalSizes = () => {
            changeDiagramHeight(dataView.group.id, dataView.data_view_id, diagramWrapperRef.current.clientHeight);
        };

        useEffect(() => {
            if (!clientHeight) return;
            changeDiagramHeight(dataView.group.id, dataView.data_view_id, clientHeight);
        }, [clientHeight, dataView]);

        useEffect(() => {
            const unitedParamsValues = {
                ...customInputParamsValues,
                ...globalInputParamsValues,
            };

            const newInputParamsValues = {};
            dataView.data_report.input_parameters.forEach((parameter) => {
                newInputParamsValues[parameter.key_name] = unitedParamsValues[parameter.key_name];
            });

            setStateInputParamsValues({ ...newInputParamsValues });

            if (!objectsCompare(unitedParamsValues, storedInputParamsValues[currentSubSection])) {
                if (!objectsCompare(newInputParamsValues, stateInputParamsValues)) {
                    _refresh(dataView, null, newInputParamsValues);
                }

                fullScreenId === null && storeInputParamsValues(unitedParamsValues, currentSubSection);
            }
        }, [customInputParamsValues, globalInputParamsValues, dataView, lang, dataViewsByGroupID, diagramOptionsByID, fullScreenId]);

        const params = { iteration, iterationsNumber, cabinetMode };

        const validDataTypes = {
            dynamics_diagram: ['dynamics'],
            markdown_data_view: ['markdown'],
            score_diagram: ['comparison_score', 'score'],
            pie_chart_diagram: ['distribution_one_line'],
            table_data_view: ['table'],
            funnel_diagram: ['funnel_pair', 'funnel_set'],
            vertical_bar_distribution_diagram: [
                'distribution_over_hours_of_day',
                'distribution_over_days_of_week',
                'distribution_one_line',
            ],
            horizontal_bar_obj_distribution_diagram: ['distribution_over_objects'],
            horizontal_bar_distribution_diagram: ['external_intersection_arr'],
            mutual_intersections_chord_diagram: [],
            dynamics_calendar_diagram: ['dynamics'],
            outdoor_map_diagram: ['distribution_over_objects', 'external_intersection_arr'],
            large_vertical_bar_distribution_diagram: [],
            ad_space_ids: [],
        };

        const drawDiagram = (params) => {
            if (
                params.dataViewType &&
                params.dataView &&
                validDataTypes[params.dataViewType] &&
                !validDataTypes[params.dataViewType].includes(params.dataView.data_report.data_type.key_name)
            ) {
                console.log(params.dataViewType, params.dataView.data_report.data_type.key_name);
                return (
                    <NoSuchDataType>
                        <span>{`${params.dataView.data_report.data_type.key_name}`}</span>
                        <span>{t('Тип данных не поддерживается')}</span>
                    </NoSuchDataType>
                );
            } else if (params.dataViewType && params.dataView && !validDataTypes[params.dataViewType]) {
                console.log(params.dataViewType, params.dataView.data_report.data_type.key_name);
                return (
                    <NoSuchDataType>
                        <span>{`${params.dataViewType}`}</span>
                        <span>{`${params.dataView.data_report.data_type.key_name}`}</span>
                        <span>{t('Тип диаграмм или тип данных не поддерживается')}</span>
                    </NoSuchDataType>
                );
            }

            switch (params.dataViewType) {
                case 'score_diagram':
                    return <DataViewScore {...params} />;
                case 'markdown_data_view':
                    return <DataViewMarkDown {...params} changeFinalSizes={changeFinalSizes} />;
                case 'pie_chart_diagram':
                    return <DataViewPieChart {...params} />;
                case 'dynamics_diagram':
                    return (
                        <DataViewDynamics
                            diagramInnerParams={diagramsInnerParams[params.dataViewType]}
                            diagramInnerAction={diagramInnerAction}
                            {...params}
                            {...diagramContextValue}
                        />
                    );
                case 'table_data_view':
                    return <DataViewTable {...params} />;
                case 'funnel_diagram':
                    if (params.data.funnels.length === 0) {
                        return <ErrorContainer>No Data</ErrorContainer>;
                    } else if (params.data.funnels[0].data_aspects === undefined) {
                        return <ErrorContainer>There's no data_aspects</ErrorContainer>;
                    }
                    return <DataViewFunnel MAX_FUNNEL_SET={MAX_FUNNEL_SET} {...params} />;
                case 'vertical_bar_distribution_diagram':
                    return <DataViewVerticalBarDistribution {...params} />;
                case 'horizontal_bar_obj_distribution_diagram':
                case 'horizontal_bar_distribution_diagram':
                    return <DataViewHorizontalBarColorDistribution {...params} />;
                case 'mutual_intersections_chord_diagram':
                    return <DataViewChord {...params} />;
                case 'mutual_intersections_heatmap_diagram':
                    return <DataViewHeatmap {...params} />;
                case 'dynamics_calendar_diagram':
                    return <DataViewCalendar {...params} />;
                case 'outdoor_map_diagram':
                    return <DataViewOutdoorMap reportingObjectsById={reportingObjectsById} {...params} />;
                case 'large_vertical_bar_distribution_diagram':
                    return <p>This diagram isn't finished</p>;

                case 'ad_space_ids':
                    return <p>This diagram isn't finished</p>;

                default:
                    return <div>{params.dataViewType}</div>;
            }
        };

        const _deleteDiagramFromC_D = (args) => () => {
            deleteDiagramFromC_D(args);
        };

        const parseError = (err) => {
            if (!err) return {};
            return JSON.parse(JSON.stringify(err));
        };

        const unitedParamsValues = {
            ...customInputParamsValues,
            ...globalInputParamsValues,
        };

        const inputParamsValues = useMemo(() => {
            const result = {};
            dataView.data_report.input_parameters.forEach((parameter) => {
                result[parameter.key_name] = unitedParamsValues[parameter.key_name];
            });
            return result;
        }, [stateInputParamsValues, dataView.data_report.input_parameters]);

        const render = () => {
            const indicators = diagramOptionsByID[dataView.data_view_id] && diagramOptionsByID[dataView.data_view_id].indicators;
            const settings = diagramOptionsByID[dataView.data_view_id] && diagramOptionsByID[dataView.data_view_id].settings;
            // const keepRatio = diagramOptionsByID[dataView.data_view_id].fullScreen.keepRatio;
            // const fullScreenShow = dataView.data_view_id === fullScreenId;
            // console.log(reportData[dataView.data_view_id]);
            const showDiagram =
                defaultSize &&
                reportData &&
                reportData[dataView.data_view_id] &&
                reportData[dataView.data_view_id].response &&
                reportData[dataView.data_view_id].response !== null &&
                reportData[dataView.data_view_id].isSuccess;

            if (showDiagram) {
                params.colors = colors;
                params.scrollWidth = scrollWidth;
                params.dataViewType = dataView.data_view_type.key_name;
                params.data = reportData[dataView.data_view_id].response;
                params.isSuccess = reportData[dataView.data_view_id].isSuccess;
                params.inputParamsValues = inputParamsValues;
                params.dataView = dataView;
                params.lang = lang;
                params.indicators = indicators;
                params.settings = settings;
                params.fullScreenShow = fullScreenShow;
                params.keepRatio = keepRatio;
                params.size = generateSize(dataView, defaultSize);
                params.isSpinner = false;
            }
            else if (
                defaultSize &&
                reportData &&
                reportData[dataView.data_view_id] &&
                reportData[dataView.data_view_id].error &&
                parseError(reportData[dataView.data_view_id].error).name !== 'AbortError'
            ) {
                params.isSuccess = false;
                params.isSpinner = false;
                params.error = {
                    name: '',
                    message: t('Не удалось получить данные: ') + ' ' + parseError(reportData[dataView.data_view_id].error).err,
                };
            } else if (
                reportData &&
                reportData[dataView.data_view_id] &&
                !reportData[dataView.data_view_id].isSuccess &&
                reportData[dataView.data_view_id].error &&
                parseError(reportData[dataView.data_view_id].error).name !== 'AbortError'
            ) {
                params.isSpinner = false;
                params.error = reportData[dataView.data_view_id].error;
            }
        
            else {
                params.isSpinner = true;

                if (reportData?.[dataView.data_view_id]) {
                    
                    params.isSpinner = reportData[dataView.data_view_id].statusLoadData;
                    if (!reportData[dataView.data_view_id].statusLoadData) {
                        if (Object.keys(reportData[dataView.data_view_id]).length <= 1) {
                            params.error = {
                                name: '',
                                message: t('Нет данных по этой диаграмме'),
                            };
                        }
                }
                }
            
            }

            // console.log('>>>>>>>>>>>>>>', reportData[dataView.data_view_id]);
            
            

            const diagram = drawDiagram(params);

            // console.log('>>>>>>>>>>>>>>>', params.isSpinner, params.isSuccess);
            const element = (
                <DiagramWrapper ref={diagramWrapperRef} isFreeHeight={isFreeHeight} fullScreenShow={fullScreenShow} keepRatio={keepRatio}>
                    {params.iteration === 0 && (
                        <DiagramTitle title={dataView.data_report} lang={lang} keyName={dataView.data_report.data_type.key_name} />
                    )}
                    {!params.isSpinner && params.isSuccess ? (
                        diagram
                    ) : !params.isSpinner && !params.isSuccess && params.error ? (
                        <ErrorContainer>
                            <p>{params.error.name}</p>
                            <p>{params.error.message}</p>
                        </ErrorContainer>
                    ) : (
                        <SpinnerContainer>
                            <ClipLoader color={'#46b875'} loading={true} size={30} />
                        </SpinnerContainer>
                    )}
                    {params.cabinetMode === '' && (
                        <React.Fragment>
                            {/* {params.dataView ? ( */}
                            <ContextMenuButton
                                {...params}
                                diagramOptionsByID={diagramOptionsByID}
                                fullScreenId={fullScreenId}
                                fullScreenShow={fullScreenShow}
                                onContextMenuReportParamsClick={onContextMenuReportParamsClick}
                                generateAtomicTableData={generateAtomicTableData}
                                onSaveXLSXClick={onSaveXLSXClick}
                                onFullScreenClick={onFullScreenClick}
                                fullScreenExit={fullScreenExit}
                                inputParamsValues={stateInputParamsValues}
                                onClickReloadDiagram={_refresh}
                                _toggleModal={_toggleModal}
                                dataView={dataView}
                                showDiagram={showDiagram}
                                editable={editable}
                            />
                            {/* ) : null} */}
                            {/* <LogOverlay
                            inputParamsValues={stateInputParamsValues}
                            dataView={dataView}
                            index={dataView.data_view_id}
                            log={log}
                            show={logOverlay.show}
                            onShowLogToggle={onShowLogToggle(false)}
                            onClickReloadDiagram={refresh}
                        /> */}

                            <Updater
                                settings={settings}
                                inputParamsValues={inputParamsValues}
                                dataView={dataView}
                                index={dataView.data_view_id}
                                onClickReloadDiagram={_refresh}
                            />
                            {editable && <CloseIcon iconName="SkypeCircleMinus" onClick={_deleteDiagramFromC_D({ dataView })} />}
                        </React.Fragment>
                    )}
                </DiagramWrapper>
            );

            return element;
        };

        return render();
    }
);

export default Diagram;

export const getIterations = (dataView, data) => {
    // console.log(dataView.data_report.data_type.key_name);
    // console.log(data);

    if (!data || !data.data || !data.data.data) return { iterationsNumber: 1 };

    switch (dataView.data_report.data_type.key_name) {
        case 'funnel_set':
            try {
                return { iterationsNumber: Math.min(MAX_FUNNEL_SET, data.data.data.funnels.length) };
            } catch (error) {
                console.warn(error);
                return { iterationsNumber: 1 };
            }
        case 'table':
            try {
                return { iterationsNumber: data.data.data.rows.length, padding: '0px' };
            } catch (error) {
                console.warn(error);
                return { iterationsNumber: 1 };
            }

        default:
            return { iterationsNumber: 1 };
    }
};

const DiagramWrapper = styled.div`
    box-sizing: border-box;
    padding: 7px;
    position: relative;
    width: auto;
    height: ${(p) => {
        if (p.fullScreenShow && !p.keepRatio) {
            return '100%';
        }
        if (p.fullScreenShow && p.keepRatio) {
            return 'auto';
        }
        if (!p.isFreeHeight) {
            return '100%';
        } else {
            return 'auto';
        }
    }};
    /* overflow: ${(p) => (p.isFreeHeight ? 'visible' : 'hidden')}; */
`;

const NoSuchDataType = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        text-align: center;
    }
`;

const SpinnerContainer = styled.div`
    width: 100%;
    height: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
`;

const CloseIcon = styled(Icon)`
    cursor: pointer;
    position: absolute;
    top: -7px;
    left: -7px;
    color: #ff9f9f;
    :hover {
        color: #ff6161;
    }
`;

const ErrorContainer = styled.div`
    width: 100%;
    height: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    p {
        text-align: center;
    }
`;
