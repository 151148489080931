import React from 'react';
import styled from 'styled-components/macro';

const Title = styled.h3`
    font-family: ProximaNova;
    display: inline-block;
    margin-top: 12px;
    font-size: 16pt;
    font-weight: 500;
    margin-bottom: 12px;
`;

export default React.memo(({ title }) => {
    return <Title>{title}</Title>;
});
