import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import ExpandArrow from './expand_arrow';
// import Dash from '../../pl_panel/components/dash';

export default React.memo(({ itemId, expand, item, ...props }) => {
    const symbolClassName =
        item.type === 'owner'
            ? 'fa fa-child'
            : item.type === 'project'
            ? 'fas fa-project-diagram'
            : item.type === 'pl'
            ? 'fas fa-thumbtack'
            : null;

    // const counter = item.counter !== undefined ? `(${item.counter})` : '';
    const titleOpacity = 1;
    // const titleOpacity = item.counter === undefined || item.counter === 0 ? 0.5 : 1;

    const toggleExpandArrow = (itemId, item) => () => {
        if (item.type === 'project') {
            props.changeProjectId(item.id);
            props.toggleExpandArrow(1);
        } else if (item.type === 'owner') {
            props.toggleExpandArrow(itemId);
        }
    };

    const listItem = useMemo(() => {
        return (
            <Item>
                <Title titleOpacity={titleOpacity} onClick={toggleExpandArrow(itemId, item)}>
                    <Symbol className={symbolClassName} />
                    {`${item.name}`}
                    {/* {`${item.name} ${counter}`} */}
                    {props.hasChildren && <ExpandArrow id={itemId} expand={expand} toggleExpandArrow={props.toggleExpandArrow} />}
                </Title>
                {expand ? props.children : null}
            </Item>
        );
    }, [item, itemId, expand]);

    return <React.Fragment>{listItem}</React.Fragment>;
});

const Item = styled.li`
    list-style: none;
    /* display: flex;
    flex-direction: column; */
    /* max-width: 500px; */
    margin: 2px 0;
`;

const Title = styled.span`
    cursor: pointer;
    opacity: ${(p) => p.titleOpacity};
    /* background-color: #ddd; */
    padding: 2px 5px;
    /* border: 1px solid #777;
    border-radius: 5px; */
    /* margin: 3px 0; */
    display: flex !important;
    align-items: center;
`;

const Symbol = styled.i`
    margin-right: 10px;
    display: flex !important;
    color: rgb(19,119,225);
    /* color: ${(p) => (p.hasChildren ? 'rgb(75,181,119)' : 'rgb(19,119,225)')}; */
`;
