/* eslint-disable no-case-declarations */

import styled from 'styled-components/macro';
import React, { useEffect, useState, useMemo, useContext } from 'react';
import SegmentsControl from './segments_control/SegmentsControl';
import { DiagramsContext } from '../../index';

const CustomControl = React.memo(({ group, ...props }) => {
    const control = () => {
        switch (group.custom_key_name) {
            case 'segments_control':
                return <SegmentsControl />;
            default:
                return null;
        }
    };

    return <Plate>{control()}</Plate>;
});

export default CustomControl;

const Plate = styled.div`
    /* width: ${(p) => p.width + 'px'}; */
    background: #fff;
    padding: 7px 7px 0 7px;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); */
    flex-shrink: 0;
    /* margin-top: 8px; */
    display: flex;
    flex-wrap: wrap;
    height: 100%;
`;
