import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components/macro';

const ResetMyDashboard = React.memo((props) => {
    const { t } = useTranslation();

    const stackTokens = { childrenGap: 10 };

    const onModalButtonClick = (action, params) => (e) => {
        props.onModalButtonClick({ name: action }, params);
    };

    return (
        <Wrapper>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={'OK'} width={'100px'} onClick={onModalButtonClick('resetMyDashboard')} />
                    <DefaultButton text={t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                </Stack>
            </Footer>
        </Wrapper>
    );
});

export default ResetMyDashboard;

const StTextField = styled(TextField)`
    width: 100%;
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label:not(.FileLabel) {
        margin-top: 10px !important;
    }
`;
