import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';

// import Button from '../../button/button';

const Div = styled.div`
    overflow: auto;
    /* height: 100%; */
    box-sizing: border-box;
    /* background: rgba(242, 0, 0, 0.5); */
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: ${(props) => props.bgColor};
    /* overflow: auto; */
    flex-grow: 1;
    /* flex-basis: 100%;
    height: 100vh; */
    margin-top: 6px;
`;

const List = styled.ul`
    list-style: none;
    height: 100%;
    list-style: none;
    margin: 6px 0;
    padding: 0;
    overflow: auto;
`;

const ListItem = styled.li`
    position: relative;
    margin: 0;
    width: 100%;
    min-height: 35px;
    background: rgb(242, 242, 242);
    margin: 0;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    padding-right: 25px;
    box-sizing: border-box;

    span {
        font-family: ProximaNova;
        font-size: 12pt;
        display: flex;
        align-items: center;
        color: #328856;
        margin-right: 5px;
        /* word-wrap: break-word;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto; */
    }
`;

const SVG = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
    display: ${(p) => (p.show ? 'flex' : 'none')};
    align-items: center;
    width: 16px;
    height: 100%;
    opacity: 0.3;
    margin-left: 5px;
    :hover {
        opacity: 0.9;
    }
    transform: opacity 0.5s;
    flex-shrink: 0;
`;

const I = styled.i`
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
    color: #bbb;
    :hover {
        color: red;
    }
`;

const Wrapper = styled.div`
    flex-shrink: 0;
    margin-left: auto;
`;

const AdCampaignList = React.memo(({ singleAdCampaignSelection, multiAdCampaignSelection, adCampaignsById, customParamsSet, ...props }) => {
    return (
        <Div>
            {customParamsSet.includes('ad_campaign_ids') && multiAdCampaignSelection.length !== 0 ? (
                <List>
                    {multiAdCampaignSelection.map((item) => {
                        return (
                            <ListItem key={`AdCampaignListItem${item.id}`}>
                                <span>{`${adCampaignsById[item.id].name}`}</span>
                            </ListItem>
                        );
                    })}
                </List>
            ) : customParamsSet.includes('ad_campaign_id') && singleAdCampaignSelection && adCampaignsById[singleAdCampaignSelection.id] ? (
                <ListItem key={`AdCampaignListItem${singleAdCampaignSelection.id}`}>
                    <span>{`${adCampaignsById[singleAdCampaignSelection.id].name}`}</span>
                </ListItem>
            ) : // <NoSelectedWarning text={_t.t('Не выбрано ни одной рекламной поверхности')} />
            null}
        </Div>
    );
});

export default AdCampaignList;
