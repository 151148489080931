import React from 'react';
import PropTypes from 'prop-types';

const Header = ({
    _t,
    lang
                }) => {
    return (
        <div className={'header'}>
            <div className={'headerItemButtom'}>
                <div style={{color: 'rgb(36, 49, 56)'}} className={'ButtonItem'}>
                    {_t.t(`Параметры`)}
                    <div className={'divider active'}></div>
                </div>
            </div>
            <div className={'headerItemButtom'}>
                <div style={{color: 'rgba(36, 49, 56,.5)'}} className={'ButtonItem'}>
                    {_t.t(`Настройки`)}
                    <div className={'divider'}></div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
