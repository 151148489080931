import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import PdfTopPanel from './pdf_top_panel';
import MainPdf from '../../../pdf-params-panel/main';

const PdfPage = React.memo(({ ...props }) => {
    return (
        <Container id={props.id}>
            <PdfTopPanel {...props} />
            <Main>{props.children}</Main>
        </Container>
    );
});

export default PdfPage;

const Container = styled.section`
    box-sizing: border-box;
    width: 1200px;
    height: 1697px;
    margin: 20px auto;
    border: 1px #d3d3d3 solid;
    border-radius: 2px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

const Main = styled.div`
    padding: 0px 40px 20px 60px;
`;
