import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const TooltipAttachFile = React.memo(() => {
    const { t } = useTranslation();

    return (
        <Container>
            <p>{t('Эфирная справка в виде *.csv файла без заголовков')}</p>
            <p>[ext_ad_space_id, timestamp, duration]</p>
            <CodeWrapper>
                <code>"9CE39CEB-5342-4458-A2DA-EFAB5DF86063";"2020-03-07 08:11:57+05";7</code>
                <code>"9CE39CEB-5342-4458-C2DA-EFAB5DF86063";"2020-03-07 08:15:56+05";7</code>
                <code>"9CE39CEB-5342-4458-C2DA-9CE39CEBEFAB";"2020-03-07 08:22:01+05";6</code>
            </CodeWrapper>
            {t('или')}
            <CodeWrapper>
                <code>9CE39CEB-5342-4458-A2DA-EFAB5DF86063;2020-03-07 08:11:57+05;7</code>
                <code>9CE39CEB-5342-4458-C2DA-EFAB5DF86063;2020-03-07 08:15:56+05;7</code>
                <code>9CE39CEB-5342-4458-C2DA-9CE39CEBEFAB;2020-03-07 08:22:01+05;6</code>
            </CodeWrapper>
        </Container>
    );
});

export default TooltipAttachFile;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    p {
        margin: 0;
        margin-top: 5px;
        font-size: 16px;
    }
`;

const CodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px 0;
    /* border: 1px solid red; */
    background-color: rgb(239, 240, 241);
    code {
        font-size: 14px;
    }
`;
