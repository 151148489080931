import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeftPanel from './components/LeftPanel';

class BoxLeftPanel extends Component {
    render() {
        if (this.props.cabinetMode === '-pdf') {
            return null;
        }

        return (
            <div className={'leftPanelBox'}>
                <LeftPanel lang={this.props.lang} _t={this.props._t} />
            </div>
        );
    }
}

BoxLeftPanel.propTypes = {};

export default BoxLeftPanel;
