var moment = require('moment');

const selectCalendarType = (state, action) => {
    if (state.statusPeriodCompare === 'theSamePeriodUpTo' && action.status === 'BoxPeriod') {
        const dateUpTo = getDateUpTo(state.selected.start, state.selected.end);

        const selected = {
            selStart: state.selected.start,
            selEnd: state.selected.end,
            sel2Start: dateUpTo[0],
            sel2End: dateUpTo[1],
        };

        return Object.assign({}, state, {
            status: action.status,
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    }
    return Object.assign({}, state, {
        status: action.status,
    });
};

const getDateUpTo = (startDateBefore, endDateBefore) => {
    var a = moment(startDateBefore);
    var b = moment(endDateBefore);
    let compare = b.diff(a, 'days');

    return [
        moment(startDateBefore)
            .subtract(compare + 1, 'd')
            .toDate(),
        moment(startDateBefore).subtract(1, 'd').toDate(),
    ];
};

const dateToString = (obj) => {
    return {
        reportStart: moment(obj.selStart).format('YYYY - MM - DD'),
        reportEnd: moment(obj.selEnd).format('YYYY - MM - DD'),
        compareStart: moment(obj.sel2Start).format('YYYY - MM - DD'),
        compareEnd: moment(obj.sel2End).format('YYYY - MM - DD'),
    };
};

const setIsActive = (start, end) => {
    const a = moment(start);
    const b = moment(end);
    const diff = b.diff(a, 'days');
    // console.log(b);
    // console.log(diff);

    if (diff > 365) {
        return { sameLastWeek: false, sameLastMonth: false, sameLastYear: false };
    } else if (diff <= 365 && diff >= 30) {
        return isWholeMonthSelected(start, end)
            ? { sameLastWeek: false, sameLastMonth: true, sameLastYear: true }
            : { sameLastWeek: false, sameLastMonth: false, sameLastYear: true };
    } else if (diff < 30 && diff >= 7) {
        return { sameLastWeek: false, sameLastMonth: true, sameLastYear: true };
    } else {
        return { sameLastWeek: true, sameLastMonth: true, sameLastYear: true };
    }
};

const selectTypePeriod = (state, action) => {
    // const statusPeriodCompare =
    //   state.statusPeriodCompare === 'theSamePeriodUpTo' ? 'theSamePeriodUpTo' : 'Pending...';
    if (action.status === 'Last30Days') {
        // const dateUpTo = getDateUpTo(
        //   new Date(new Date().setDate(new Date().getDate() - 29)),
        //   new Date()
        // );

        const compareRange = getCompareRange(
            new Date(new Date().setDate(new Date().getDate() - 29)),
            new Date(),
            state.statusPeriodCompare
        );

        const selected = {
            selStart: new Date(new Date().setDate(new Date().getDate() - 29)),
            selEnd: new Date(),
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare: 'theSamePeriodUpTo',
            isActive: { sameLastWeek: false, sameLastMonth: true, sameLastYear: true },
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'CurrentMonth') {
        const date = moment(`${moment().month() + 1}-01-${moment().year()}`, 'MM-DD-YYYY').toDate();
        const compareRange = getCompareRange(
            date,
            moment(`${moment().month() + 1}-${moment().daysInMonth()}-${moment().year()}`, 'MM-DD-YYYY').toDate(),
            state.statusPeriodCompare,
            action.status
        );

        const selected = {
            selStart: date,
            selEnd: moment(`${moment().month() + 1}-${moment().daysInMonth()}-${moment().year()}`, 'MM-DD-YYYY').toDate(),
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare,
            isActive: { sameLastWeek: false, sameLastMonth: true, sameLastYear: true },
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'LastMonth') {
        const date = moment(`${moment().month()}-01-${moment().year()}`, 'MM-DD-YYYY').toDate();
        const compareRange = getCompareRange(
            date,
            moment(
                `${moment().month()}-${moment(`${moment().month()}-01-${moment().year()}`, 'MM-DD-YYYY').daysInMonth()}-${moment().year()}`,
                'MM-DD-YYYY'
            ).toDate(),
            state.statusPeriodCompare,
            action.status
        );

        const selected = {
            selStart: date,
            selEnd: moment(
                `${moment().month()}-${moment(`${moment().month()}-01-${moment().year()}`, 'MM-DD-YYYY').daysInMonth()}-${moment().year()}`,
                'MM-DD-YYYY'
            ).toDate(),
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare,
            isActive: { sameLastWeek: false, sameLastMonth: true, sameLastYear: true },
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'Last7Days') {
        const compareRange = getCompareRange(moment().subtract(6, 'd').toDate(), moment().toDate(), state.statusPeriodCompare);

        const selected = {
            selStart: moment().subtract(6, 'd').toDate(),
            selEnd: moment().toDate(),
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare,
            isActive: { sameLastWeek: true, sameLastMonth: true, sameLastYear: true },
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'Yesterday') {
        // console.log('getDate', moment().subtract(7,'d').toDate());
        const yesterday = moment().subtract(1, 'd').toDate();
        // const beforeYesterday = moment()
        //   .subtract(2, 'd')
        //   .toDate();

        const compareRange = getCompareRange(yesterday, yesterday, state.statusPeriodCompare);

        const selected = {
            selStart: yesterday,
            selEnd: yesterday,
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare,
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'Today') {
        // console.log('getDate', moment().subtract(7,'d').toDate());
        const today = moment().toDate();
        // const yesterday = moment()
        //   .subtract(1, 'd')
        //   .toDate();

        const compareRange = getCompareRange(today, today, state.statusPeriodCompare);

        const selected = {
            selStart: today,
            selEnd: today,
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
        };

        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare,
            selected: {
                start: selected.selStart,
                end: selected.selEnd,
            },
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    } else if (action.status === 'CustomPeriod') {
        // console.log('getDate', moment().subtract(7,'d').toDate());
        const dateUpTo = getDateUpTo(moment().subtract(7, 'd').toDate(), moment().toDate());
        return Object.assign({}, state, {
            statusPeriod: action.status,
            // statusPeriodCompare
        });
    }
};

const isWholeMonthSelected = (startDate, endDate) => {
    return (
        moment(startDate).format('YYYY-MM-DD') === moment(startDate).startOf('month').format('YYYY-MM-DD') &&
        moment(endDate).format('YYYY-MM-DD') === moment(endDate).endOf('month').format('YYYY-MM-DD')
    );
};

const getCompareRange = (startDate, endDate, mainPeriod, comparePeriod = null) => {
    const a = moment(startDate);
    const b = moment(endDate);
    const diff = b.diff(a, 'days');

    switch (mainPeriod) {
        case 'theSamePeriodUpTo':
            return [a.subtract(diff + 1, 'd').toDate(), a.subtract(1, 'd').toDate()];
        case 'theSamePeriodLastWeek':
            return [a.subtract(7, 'd').toDate(), b.subtract(7, 'd').toDate()];
        case 'theSamePeriodLastMonth':
            if (comparePeriod === 'CurrentMonth' || comparePeriod === 'LastMonth' || isWholeMonthSelected(startDate, endDate)) {
                return [a.subtract(1, 'months').toDate(), b.subtract(1, 'months').endOf('month').toDate()];
            } else {
                return [moment(b).subtract(1, 'months').subtract(diff, 'days').toDate(), b.subtract(1, 'months').toDate()];
            }
        case 'theSamePeriodLastYear':
            if (comparePeriod === 'CurrentMonth' || comparePeriod === 'LastMonth' || isWholeMonthSelected(startDate, endDate)) {
                return [a.subtract(1, 'years').toDate(), b.subtract(1, 'years').endOf('month').toDate()];
            } else {
                return [moment(b).subtract(1, 'years').subtract(diff, 'days').toDate(), b.subtract(1, 'years').toDate()];
            }
        default:
    }
};

const selectTypePeriodCompare = (state, action) => {
    if (action.status === 'theSamePeriodUpTo') {
        const dateUpTo = getDateUpTo(state.selected.start, state.selected.end);
        return Object.assign({}, state, {
            statusPeriodCompare: action.status,
            selected2: {
                start: dateUpTo[0],
                end: dateUpTo[1],
            },
            textInputs: {
                ...state.textInputs,
                compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
            },
        });
    } else if (action.status === 'theSamePeriodLastWeek') {
        const dateUpTo = getCompareRange(state.selected.start, state.selected.end, 'theSamePeriodLastWeek');
        return Object.assign({}, state, {
            status: 'BoxPeriod',
            statusPeriodCompare: action.status,
            selected2: {
                start: dateUpTo[0],
                end: dateUpTo[1],
            },
            textInputs: {
                ...state.textInputs,
                compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
            },
        });
    } else if (action.status === 'theSamePeriodLastMonth') {
        const dateUpTo = getCompareRange(state.selected.start, state.selected.end, 'theSamePeriodLastMonth', state.statusPeriod);
        return Object.assign({}, state, {
            statusPeriodCompare: action.status,
            selected2: {
                start: dateUpTo[0],
                end: dateUpTo[1],
            },
            textInputs: {
                ...state.textInputs,
                compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
            },
        });
    } else if (action.status === 'theSamePeriodLastYear') {
        const dateUpTo = getCompareRange(state.selected.start, state.selected.end, 'theSamePeriodLastYear');
        return Object.assign({}, state, {
            statusPeriodCompare: action.status,
            selected2: {
                start: dateUpTo[0],
                end: dateUpTo[1],
            },
            textInputs: {
                ...state.textInputs,
                compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
            },
        });
    }

    return Object.assign({}, state, {
        statusPeriodCompare: action.status,
    });
};

const setSelectedComparePeriod = (state, data) => {
    const isActive = setIsActive(data.start, data.end);

    if (state.statusPeriodCompare === 'theSamePeriodLastWeek') {
        const compareRange = getCompareRange(data.start, data.end, 'theSamePeriodLastWeek');
        return {
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
            statusPeriodCompare: isActive.sameLastWeek ? 'theSamePeriodLastWeek' : 'theSamePeriodUpTo',
        };
    } else if (state.statusPeriodCompare === 'theSamePeriodLastMonth') {
        const compareRange = getCompareRange(data.start, data.end, 'theSamePeriodLastMonth');
        return {
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
            statusPeriodCompare: isActive.sameLastMonth ? 'theSamePeriodLastMonth' : 'theSamePeriodUpTo',
        };
    } else if (state.statusPeriodCompare === 'theSamePeriodLastYear') {
        const compareRange = getCompareRange(data.start, data.end, 'theSamePeriodLastYear');
        return {
            sel2Start: compareRange[0],
            sel2End: compareRange[1],
            statusPeriodCompare: isActive.sameLastYear ? 'theSamePeriodLastYear' : 'theSamePeriodUpTo',
        };
    } else {
        return {
            sel2Start: state.selected2.start,
            sel2End: state.selected2.end,
            statusPeriodCompare: state.statusPeriodCompare,
        };
    }
};

const selectDate = (state, action) => {
    const isActive = setIsActive(action.data.start, action.data.end);

    // console.log(action.data.start);
    // console.log(action.data.end);

    let selected;
    let newStatusPeriodCompare;
    if (state.status === 'BoxPeriod') {
        if (state.statusPeriodCompare === 'theSamePeriodUpTo') {
            const dateUpTo = getDateUpTo(action.data.start, action.data.end);
            selected = {
                selStart: action.data.start,
                selEnd: action.data.end,
                sel2Start: dateUpTo[0],
                sel2End: dateUpTo[1],
            };
            // console.log(selected);

            newStatusPeriodCompare = 'theSamePeriodUpTo';
        } else if (state.statusPeriodCompare === 'CustomPeriod') {
            selected = {
                selStart: moment(action.data.start).isAfter(moment(state.selected2.end))
                    ? action.data.start
                    : moment(state.selected2.end).add(1, 'days').toDate(),
                selEnd: action.data.end,
                sel2Start: state.selected2.start,
                sel2End: state.selected2.end,
            };
            newStatusPeriodCompare = 'CustomPeriod';
        } else {
            const { sel2Start, sel2End, statusPeriodCompare } = setSelectedComparePeriod(state, action.data);
            selected = {
                selStart: action.data.start,
                selEnd: action.data.end,
                sel2Start,
                sel2End,
            };

            newStatusPeriodCompare = statusPeriodCompare;
        }

        return {
            ...state,
            statusPeriod: 'CustomPeriod',
            isActive,
            statusPeriodCompare: newStatusPeriodCompare,
            selected: { ...state.selected, start: selected.selStart, end: selected.selEnd },
            selected2: { ...state.selected2, start: selected.sel2Start, end: selected.sel2End },
            textInputs: { ...dateToString(selected) },
        };
    } else if (state.status === 'BoxComparePeriod') {
        // console.log('>>>>>>>>>>>>>>2', state.selected.start, state.selected.end)
        selected = {
            selStart: state.selected.start,
            selEnd: state.selected.end,
            sel2Start: action.data.start,
            sel2End: action.data.end,
        };

        return Object.assign({}, state, {
            statusPeriodCompare: 'CustomPeriod',
            selected2: {
                start: selected.sel2Start,
                end: selected.sel2End,
            },
            textInputs: { ...dateToString(selected) },
        });
    }
};

const getLocationsIdForDate = (state, action) => {
    // console.log('getLocationsIdForDate', action.phathName.split('/')[4].split(','), action.phathName.split('/')[5].split(','));
    let arrMainDate = [];
    let arrCompareDate = [];
    if (action.phathName.split('/')[4] !== undefined) {
        arrMainDate = action.phathName.split('/')[4].split(',');
    }
    if (action.phathName.split('/')[5] !== undefined) {
        arrCompareDate = action.phathName.split('/')[5].split(',');
    }
    if (action.phathName.split('/')[4] !== undefined || action.phathName.split('/')[5] !== undefined) {
        // console.log('getLocationsIdForDate', arrCompareDate);
        if (arrMainDate[0] !== '') {
            return Object.assign({}, state, {
                // statusPeriod: 'CustomPeriod',
                selected: {
                    start: moment(arrMainDate[0]).toDate(),
                    end: moment(arrMainDate[1]).toDate(),
                },
                selected2: {
                    start: arrCompareDate[0] === '' ? state.selected2.start : moment(arrCompareDate[0]).toDate(),
                    end: arrCompareDate[0] === '' ? state.selected2.end : moment(arrCompareDate[1]).toDate(),
                },
            });
        }
        return Object.assign({}, state, {
            // statusPeriod: 'CustomPeriod',
        });
    }
    return Object.assign({}, state, {
        // statusPeriod: 'CustomPeriod',
    });
};

const onChangeDateByTextInput = (state, action) => {
    const inputName = action.data.target.name;
    const value = action.data.target.value;
    const date = moment(value, ['YYYY - MM - DD', 'YYYY - M - D']);
    const valueArr = value.split(' - ');

    const dateIsValid = moment(value, 'YYYY - MM - DD').isValid() || moment(value, 'YYYY - M - D').isValid() ? true : false;

    const needToChangeCompareMode =
        (inputName === 'compareStart' || inputName === 'compareEnd') && state.statusPeriodCompare !== 'CustomPeriod';

    const needToChangePeriodMode =
        (inputName === 'reportStart' || inputName === 'reportEnd') && state.statusPeriodCompare === 'CustomPeriod';

    if (value.search(/^20\d{0,2} - [0-1]{0,1}\d{0,1} - [0-3]{0,1}\d{0,1}$/i) !== -1 && valueArr[1] <= 12 && valueArr[2] <= 31) {
        if (dateIsValid) {
            const dateUpTo =
                inputName === 'reportStart'
                    ? getCompareRange(date.toDate(), moment(state.selected.end).toDate(), state.statusPeriodCompare)
                    : getCompareRange(moment(state.selected.start).toDate(), date.toDate(), state.statusPeriodCompare);

            switch (inputName) {
                case 'reportStart':
                    return {
                        ...state,
                        statusPeriod: 'CustomPeriod',
                        textInputs:
                            state.statusPeriodCompare !== 'CustomPeriod'
                                ? {
                                      ...state.textInputs,
                                      [inputName]: value,
                                      compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                                      compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
                                  }
                                : { ...state.textInputs, [inputName]: value },
                        selected: {
                            ...state.selected,
                            start: date.toDate(),
                        },
                        selected2:
                            state.statusPeriodCompare !== 'CustomPeriod'
                                ? {
                                      ...state.selected2,
                                      start: dateUpTo[0],
                                      end: dateUpTo[1],
                                  }
                                : state.selected2,
                    };

                case 'reportEnd':
                    return {
                        ...state,
                        statusPeriod: 'CustomPeriod',
                        textInputs:
                            state.statusPeriodCompare !== 'CustomPeriod'
                                ? {
                                      ...state.textInputs,
                                      [inputName]: value,
                                      compareStart: moment(dateUpTo[0]).format('YYYY - MM - DD'),
                                      compareEnd: moment(dateUpTo[1]).format('YYYY - MM - DD'),
                                  }
                                : { ...state.textInputs, [inputName]: value },
                        selected: {
                            ...state.selected,
                            end: date.toDate(),
                        },
                        selected2:
                            state.statusPeriodCompare !== 'CustomPeriod'
                                ? {
                                      ...state.selected2,
                                      start: dateUpTo[0],
                                      end: dateUpTo[1],
                                  }
                                : state.selected2,
                    };

                case 'compareStart':
                    return {
                        ...state,
                        textInputs: { ...state.textInputs, [inputName]: value },
                        statusPeriodCompare: needToChangeCompareMode ? 'CustomPeriod' : state.statusPeriodCompare,
                        selected2: {
                            ...state.selected2,
                            start: date.toDate(),
                        },
                    };

                case 'compareEnd':
                    return {
                        ...state,
                        textInputs: { ...state.textInputs, [inputName]: value },
                        statusPeriodCompare: needToChangeCompareMode ? 'CustomPeriod' : state.statusPeriodCompare,
                        selected2: {
                            ...state.selected2,
                            end: date.toDate(),
                        },
                    };

                default:
                    return state;
            }
        } else {
            return { ...state, textInputs: { ...state.textInputs, [inputName]: value } };
        }
    } else {
        return state;
    }
};

let stateInit = {
    statusShowCalendar: false,

    textInputs: {
        reportStart: moment().subtract(6, 'd').format('YYYY - MM - DD'),

        reportEnd: moment().format('YYYY - MM - DD'),

        compareStart: moment().subtract(13, 'd').format('YYYY - MM - DD'),

        compareEnd: moment().subtract(7, 'd').format('YYYY - MM - DD'),
    },
    status: 'BoxPeriod',
    statusPeriod: 'Last7Days',
    statusPeriodCompare: 'theSamePeriodUpTo',
    selected: {
        start: moment().subtract(6, 'd').toDate(),
        end: moment().toDate(),
    },
    selected2: {
        start: moment().subtract(13, 'd').toDate(),
        end: moment().subtract(7, 'd').toDate(),
    },
    isActive: {
        sameLastWeek: true,
        sameLastMonth: true,
        sameLastYear: true,
    },
};

const getStatusShowPanelCalendar = (state, action) => {
    return Object.assign({}, state, {
        statusShowCalendar: !state.statusShowCalendar,
    });
};

const getClickGlobalCompanent = (state, action) => {
    const { reportStart, reportEnd, compareStart, compareEnd } = state.textInputs;

    const dateIsValid = (value) => {
        return moment(value, 'YYYY - MM - DD').isValid() || moment(value, 'YYYY - M - D').isValid() ? true : false;
    };

    if (
        moment(reportEnd, 'YYYY - MM - DD').isBefore(moment(reportStart, 'YYYY - MM - DD')) ||
        moment(compareEnd, 'YYYY - MM - DD').isBefore(moment(compareStart, 'YYYY - MM - DD')) ||
        !dateIsValid(reportStart) ||
        !dateIsValid(reportEnd) ||
        !dateIsValid(compareStart) ||
        !dateIsValid(compareEnd)
    ) {
        return { ...state, ...stateInit };
    }
    return Object.assign({}, state, {
        statusShowCalendar: false,
        status: 'BoxPeriod',
    });
};

const getDateGlobal = (state, action) => {
    return Object.assign({}, state, {
        // statusPeriod: 'CustomPeriod',
        selected: action.selected,
        selected2: action.selected2,
    });
};

const CalendarApp = (state = stateInit, action) => {
    switch (action.type) {
        case 'selectCalendarType':
            return selectCalendarType(state, action);
        case 'onCLickButton':
            return selectTypePeriod(state, action);
        case 'onClickButtonCompare':
            return selectTypePeriodCompare(state, action);
        // case 'selectDate':
        //     return selectDate(state, action);
        case 'getLocationsIdForDate':
            return getLocationsIdForDate(state, action);
        case 'onChangeDateByTextInput':
            return onChangeDateByTextInput(state, action);

        case 'selectCalendar':
            return getStatusShowPanelCalendar(state, action);
        case 'onClickGlobalCompanent':
            return getClickGlobalCompanent(state, action);
        case 'getDateGlobal':
            return getDateGlobal(state, action);
        default:
            return state;
    }
};
export default CalendarApp;
