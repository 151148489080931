import Tools, { dataToTree, generateId } from '../tools/tools';
import diagramTools from '../components/Diagrams/tools/diagramTools';
import _t from 'i18next';
import GeometryVSDataManager from '../tools/GeometryVSDataManager';
var moment = require('moment');

let stateInit = {
    isCloudDataReceived: false,
    sharedAlert: {
        show: false,
    },
    modal: {
        show: false,
        modalType: '',
        modalTitle: '',
        modalText: '',
        modalArgs: null,
        buttons: [],
    },
    Source: {
        src: {
            outdoor_ad_spaces: [],
            roomsMaps: {},
        },
        ctg: {
            colors: {},
            objectTypes: ['main_servicepoint', 'floors', 'servicepoints', 'zones'],
            reportingObjectsByPlId: undefined,
            adCampaignsById: {},
            inputParametersByName: {},
            reportingObjectsById: undefined,
            repeatingNamesByName: undefined,
            mapsObjectsByPlId: undefined,
            dataObjSetsById: undefined,
            dataObjSetsByPlId: undefined,
            dataObjSetsByParentId: undefined,
            outdoorAdSpacesById: undefined,
            availableOwnersTree: undefined,
            availableOwnersById: undefined,
            availableProjectsByOwnerId: undefined,
            availableProjectsById: undefined,
            areaPolygons: undefined,
            plGeometryByObjId: undefined,
            projectLocationsById: undefined,
        },
    },
    mapsData: {
        plans: [],
        objectName: null,
        globalUuid: null,
        uuidDataByUuid: null,
        selectedUuid: null,
        heightMapMax: 0,
        widthMapMax: 0,
        dataUneqUsers: {},
        dataUneqUsersOfZones: {},
        objAnalitic: {},
        statusLoadSelectPolygons: false,
        selectForMaps: [],
        optionTypeNewMapsObj: {},
        statusLoadVersion: false,
        selected: 0,
        uuidDataSelectedTempPlaces: {},
    },
    diagramOptionsByType: {
        score_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        markdown_data_view: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        segments_control: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        funnel_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        table_data_view: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: true, keepRatio: false },
        },
        vertical_bar_distribution_diagram: {
            indicators: ['Средняя линия'],
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        mutual_intersections_chord_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        mutual_intersections_heatmap_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        dynamics_calendar_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        dynamics_diagram: {
            indicators: ['Средняя линия', 'Тренд'],
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: true, keepRatio: true },
        },
        outdoor_map_diagram: {
            indicators: ['Размерное представление'],
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: true, keepRatio: false },
        },
        pie_chart_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: false, keepRatio: true },
        },
        horizontal_bar_obj_distribution_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: true, keepRatio: false },
        },
        horizontal_bar_distribution_diagram: {
            settings: [{ id: 1, name: 'Обновлять каждые 10 минут', checked: false }],
            fullScreen: { allow: true, keepRatio: false },
        },
    },
    reportCatalog: [],
    ParamComebackWindow: [],
    dataInputParams: {},
    // comeback_window: '',

    // engagement_threshold: 0,

    // time_detalization: '',
    input_parameter_list: [],
    inputParameters: {},
    globalInputParamsValues: {},
    statusShowRightPanel: false,
    inputParamsSelectDataView: {},
    indexDataView: null,
    // top_limit: 0,
    objInputParam: null,
    objLocationSelect: {},
    objLocationControlSelect: {},
    selected: null,
    selected2: null,
    locations: [],
    groupIDsBySubsectionKeyName: {},
    dataViewsByGroupID: null,
    diagramOptionsByID: {},
    customInputParamsValues: {
        main_date_range: [
            moment(moment().subtract(7, 'd').toDate()).format('YYYY-MM-DD'),
            moment(moment().subtract(1, 'd').toDate()).format('YYYY-MM-DD'),
        ],
        comparison_date_range: [
            moment(moment().subtract(14, 'd').toDate()).format('YYYY-MM-DD'),
            moment(moment().subtract(8, 'd').toDate()).format('YYYY-MM-DD'),
        ],
        owner_id: undefined,
        project_id: undefined,
        obj_id: null,
        obj_ids: [],
        pl_id: null,
        pl_ids: [],
        main_obj_ids: [],
        main_obj_id: null,
        contra_obj_ids: [],
        ad_campaign_id: null,
        ad_campaign_ids: [],
        indoor_ad_space_ids: [],
        outdoor_ad_space_ids: [],
        region_id: null,
        access_token: null,
    },
    reportData: null,
    statusLoadReportList: false,
    statusLoadsetDetailInputParams: false,
    statusLoadDataDiagram: false
};

const toggleSharedAlert = (state, action) => {
    const { show, type, message, position, lifeTime } = action.payload;
    if (!show) {
        return {
            ...state,
            sharedAlert: {
                show: false,
            },
        };
    } else {
        return {
            ...state,
            sharedAlert: {
                show,
                type,
                message,
                position,
                lifeTime,
            },
        };
    }
};

const fillParamsFromCloud = (state, action) => {
    if (!action.payload.response) {
        console.log('CloudData are not Received');
        return {
            ...state,
            isCloudDataReceived: true,
        };
    }

    const { parameters } = action.payload.response.cloud_state.data;
    // console.log('<><><>', parameters);
    // console.log('<><><>', state.Source.ctg);

    const dictionary = {
        ['obj_ids']: 'reportingObjectsById',
        ['pl_ids']: 'projectLocationsById',
        ['outdoor_ad_space_ids']: 'outdoorAdSpacesById',
        ['indoor_ad_space_ids']: 'indoorAdSpacesById',
    };

    let sharedAlert = stateInit.sharedAlert;

    Object.keys(dictionary).forEach((key) => {
        if (Array.isArray(parameters.customInputParamsValues[key]) && parameters.customInputParamsValues[key].length !== 0) {
            parameters.customInputParamsValues[key] = [...parameters.customInputParamsValues[key]].filter((item) => {
                if (String(item) in state.Source.ctg[dictionary[key]]) {
                    return true;
                } else {
                    sharedAlert = {
                        show: true,
                        type: 'error',
                        message: _t.t('Недостаточно прав!'),
                        position: 'top',
                        lifeTime: 30000,
                    };
                    return false;
                }
            });
        }
    });

    // console.log('CloudData are Received');

    return {
        ...state,
        customInputParamsValues: { ...state.customInputParamsValues, ...parameters.customInputParamsValues },
        globalInputParamsValues: { ...state.globalInputParamsValues, ...parameters.globalInputParamsValues },
        sharedAlert,
        isCloudDataReceived: true,
    };
};

const getPolygonGroups = (state, action) => {
    const region_groups = [...action.json];
    let areaPolygons = [];

    region_groups.forEach((group) => {
        areaPolygons.push(...group.regions.map((item) => ({ ...item, groupId: group.id })));
    });

    return {
        ...state,
        Source: {
            ...state.Source,
            src: {
                ...state.Source.src,
                region_groups,
            },
            ctg: {
                ...state.Source.ctg,
                areaPolygons,
            },
        },
    };
};

const getParamComebackWindow = (state, param, inputParameterPanel) => {
    var inputParameters = Tools.copy(state.inputParameters);
    let status = null;

    status = inputParameters['comeback_window'].details.default_value;

    return status;
};

const getParamEngagementThreshold = (state, param, inputParameterPanel) => {
    var inputParameters = Tools.copy(state.inputParameters);
    let status = null;
    status = inputParameters['engagement_threshold'].details.default_value;

    return status;
};

const getParamTimeDetalization = (state, param, inputParameterPanel) => {
    var inputParameters = Tools.copy(state.inputParameters);
    let status = null;
    status = inputParameters['time_detalization'].details.default_value;

    return status;
};

const getParamTopLimit = (state, param, inputParameterPanel) => {
    var inputParameters = Tools.copy(state.inputParameters);
    let status = null;
    // for (var i = 0; i < inputParamForRightPanel.length; i++) {
    //     if (inputParamForRightPanel[i].key_name === 'top_limit') {
    //         status = inputParamForRightPanel[i].details.default_value;
    //     }
    // }

    status = inputParameters['top_limit'].details.default_value;

    return status;
};

const getToken = (state, param, inputParameterPanel) => {
    if (param === undefined) {
        return {
            access_token: state.access_token,
        };
    }
    return {
        access_token: param.token,
    };
};

const getParamoutdoor_ad_space_ids = (state, param) => {
    return {
        outdoor_ad_space_ids: state.customInputParamsValues.outdoor_ad_space_ids,
    };
};
const getParam_owner_id = (state, param) => {
    return {
        owner_id: state.customInputParamsValues.owner_id,
    };
};
const getParamoutdoor_polygon_id = (state, param) => {
    return {
        region_id: state.customInputParamsValues.region_id,
    };
};
const getindoor_ad_space_ids = (state, param) => {
    return {
        indoor_ad_space_ids: state.customInputParamsValues.indoor_ad_space_ids,
    };
};

const getParamPlIds = (state, param) => {
    var numArr = [];
    for (var i = 0; i < param.locations.length; i++) {
        if (param.objLocationSelect[param.locations[i].id] !== undefined) {
            numArr.push(param.locations[i].id);
        }
    }
    return {
        pl_ids: numArr,
    };
};

const checkDataReport = (item) => {
    if (
        // !item.data_report ||
        !item.data_view_type ||
        !item.group
        // ||
        // !item.on_new_line ||
        // !item.order_index ||
        // !item.size
    ) {
        return false;
    } else {
        return true;
    }
};

const getDataItemDataView = (state, action) => {
    var obj = {
        reportData: {},
    };
    obj.reportData = state.reportData ? Tools.copy(state.reportData) : {};
    obj.reportData[action.dataView.data_view_id] = {
        obj: action.obj,
        ...action.data,
        error: !action.isSuccess ? action.data : null,
        isSuccess: action.isSuccess,
        statusLoadData: false
    };
    
    return Object.assign({}, state, obj);
};

const getLoadDataDataView = (state, action) => {
    var obj = {
        reportData: {},
    };
    obj.reportData = state.reportData ? Tools.copy(state.reportData) : {};
    obj.reportData[action.dataView.data_view_id] = {
        statusLoadData: true
    };
    
    return Object.assign({}, state, obj);
};

const hideSpinerDataView = (state, action) => {
    var obj = {
        reportData: {},
    };
    obj.reportData = state.reportData ? Tools.copy(state.reportData) : {};
    obj.reportData[action.dataView.data_view_id] = {
        statusLoadData: false
    };
    
    return Object.assign({}, state, obj);
};

const setReportList = (state, action) => {
    const { sectionsByKeyName, data_view_list, permanentCloudData } = action.payload;
    if (!sectionsByKeyName || !data_view_list || !permanentCloudData) {
        return state;
    }

    const inputArr = data_view_list.filter((item) => checkDataReport(item));

    if (permanentCloudData.custom_dataViews) {
        inputArr.push(...permanentCloudData.custom_dataViews);
    }

    var obj = {
        reportCatalog: inputArr,
        inputParameters: Tools.copy(state.inputParameters),
        globalInputParamsValues: Tools.copy(state.globalInputParamsValues),
        customInputParamsValues: Tools.copy(state.customInputParamsValues),
        // reportData: {},
        statusLoadReportList: true,
    };

    let groupIDsBySubsectionKeyName = {};
    let dataViewsByGroupID = {};
    const diagramOptionsByID = {};
    for (var i = 0; i < inputArr.length; i++) {
        // obj.reportData[i] = {
        //     obj: null,
        //     data: null,
        // };
        // if (state[i] !== undefined) {
        //     obj.reportData[i] = Tools.copy(state.reportData[i]);
        // }

        const el = inputArr[i];
        Object.keys(sectionsByKeyName).forEach((key) => {
            const {
                key_name,
                translations: { en, ru },
            } = sectionsByKeyName[key];

            sectionsByKeyName[key].subsections.forEach((sub) => {
                if (sub?.translations && sub.id === el.group.subsection_id) {
                    el.meta = {
                        section: { key_name, name_en: en.name, name_ru: ru.name },
                        subsection: { key_name: sub.key_name, name_en: sub.translations.en.name, name_ru: sub.translations.ru.name },
                    };
                }
            });
        });

        if (groupIDsBySubsectionKeyName[el.group.subsection_key_name] === undefined) {
            groupIDsBySubsectionKeyName[el.group.subsection_key_name] = [];
        }
        if (groupIDsBySubsectionKeyName[el.group.subsection_key_name].indexOf(el.group.id) === -1) {
            groupIDsBySubsectionKeyName[el.group.subsection_key_name].push(el.group.id);
        }
        // dataToCopyDataViewList[el.data_view_id]['index'] = i;
        // dataToCopyDataViewList[el.data_view_id] = {};

        const diagramType = el.data_view_type.key_name;
        if (
            !state.diagramOptionsByType[diagramType] ||
            !state.diagramOptionsByType[diagramType].indicators ||
            state.diagramOptionsByType[diagramType].indicators.length === 0
        ) {
            diagramOptionsByID[el.data_view_id] = {
                indicators: [],
            };
        } else {
            diagramOptionsByID[el.data_view_id] = {
                indicators: state.diagramOptionsByType[diagramType].indicators.map((item) => {
                    return { name: item, show: false };
                }),
            };
        }

        diagramOptionsByID[el.data_view_id].settings = state.diagramOptionsByType[diagramType].settings;

        diagramOptionsByID[el.data_view_id].fullScreen = state.diagramOptionsByType[diagramType].fullScreen;

        if (dataViewsByGroupID[el.group.id] === undefined) {
            dataViewsByGroupID[el.group.id] = [];
        }
        dataViewsByGroupID[el.group.id].push(el);

        for (var j = 0; j < el.data_report.input_parameters.length; j++) {
            if (el.data_report.input_parameters[j].key_name === 'access_token') {
                obj.customInputParamsValues[el.data_report.input_parameters[j].key_name] = getParameterRouter(
                    state,
                    state.dataInputParams,
                    el.data_report.input_parameters[j].key_name
                )[el.data_report.input_parameters[j].key_name];
            }
        }
    }

    obj['groupIDsBySubsectionKeyName'] = groupIDsBySubsectionKeyName;
    obj['dataViewsByGroupID'] = dataViewsByGroupID;
    obj['diagramOptionsByID'] = diagramOptionsByID;
    // console.log('setReportList>>>>Current')
    for (var g = 0; g < state.input_parameter_list.length; g++) {
        if (state.input_parameter_list[g].input_parameter_type.key_name === 'global') {
            obj.inputParameters[state.input_parameter_list[g].key_name] = state.input_parameter_list[g];
            obj.inputParameters[state.input_parameter_list[g].key_name].details['current_value'] =
                obj.inputParameters[state.input_parameter_list[g].key_name].details.default_value;
            if (obj.globalInputParamsValues[state.input_parameter_list[g].key_name] === undefined) {
                obj.globalInputParamsValues[state.input_parameter_list[g].key_name] = getParameterRouter(
                    state,
                    action.params,
                    state.input_parameter_list[g].key_name
                );
            }
        }
    }

    return Object.assign({}, state, obj);
};

const getParameterRouter = (state, param, name, inputParameterPanel = []) => {
    switch (name) {
        case 'comeback_window':
            return getParamComebackWindow(state, param, inputParameterPanel);
        case 'engagement_threshold':
            return getParamEngagementThreshold(state, param, inputParameterPanel);
        case 'getIdPL':
            return getParamPlIds(state, param);
        case 'time_detalization':
            return getParamTimeDetalization(state, param, inputParameterPanel);
        case 'access_token':
            return getToken(state, param, inputParameterPanel);
        case 'outdoor_ad_space_ids':
            return getParamoutdoor_ad_space_ids(state, param);
        case 'owner_id':
            return getParam_owner_id(state, param);
        // case 'region_id':
        //     return getParamoutdoor_polygon_id(state, param, inputParameterPanel);
        case 'indoor_ad_space_ids':
            return getindoor_ad_space_ids(state, param, inputParameterPanel);

        case 'top_limit':
            return getParamTopLimit(state, param, inputParameterPanel);
        default:
            return getParamAny(state, param, inputParameterPanel, name);
    }
};

const getParamAny = (state, param, inputParameterPanel, name) => {
    var inputParameters = Tools.copy(state.inputParameters);
    let status = null;
    for (var key in inputParameters) {
        if (inputParameters[key].key_name === name) {
            status = inputParameters[key].details.default_value;
        }
    }
    if (status === null) {
        for (var i = 0; i < state.input_parameter_list.length; i++) {
            if (state.input_parameter_list[i].key_name === name) {
                status = state.input_parameter_list[i].details.default_value;
            }
        }
    }
    let obj = {};
    obj[name] = status;
    return status;
};

const getDataInputParams = (state, action) => {
    var obj = {
        dataInputParams: action.params,
        inputParameters: {},
        globalInputParamsValues: Tools.copy(state.globalInputParamsValues),
        customInputParamsValues: Tools.copy(state.customInputParamsValues),
    };

    for (var i = 0; i < state.reportCatalog.length; i++) {
        for (var j = 0; j < state.reportCatalog[i].data_report.input_parameters.length; j++) {
            if (state.reportCatalog[i].data_report.input_parameters[j].key_name === 'access_token') {
                obj.customInputParamsValues[state.reportCatalog[i].data_report.input_parameters[j].key_name] = getParameterRouter(
                    state,
                    action.params,
                    state.reportCatalog[i].data_report.input_parameters[j].key_name
                )[state.reportCatalog[i].data_report.input_parameters[j].key_name];
            }
        }
    }

    if (obj.inputParameters.length === state.inputParameters.length) {
        obj.inputParameters = Tools.copy(state.inputParameters);
    }

    return Object.assign({}, state, obj);
};

const setDetailInputParams = (state, action) => {
    if (state.statusLoadsetDetailInputParams === false) {
        if (state.objInputParam !== null) {
            for (var r = 0; r < action.json.length; r++) {
                if (state.objInputParam[action.json[r].key_name] !== undefined) {
                    action.json[r].details.default_value = state.objInputParam[action.json[r].key_name];
                }
            }
        }
        // console.log('setReportList>>>>3',action.json)
        if (Object.keys(state.reportCatalog).length > 0) {
            var obj = {
                inputParameters: {},
                globalInputParamsValues: {},
                input_parameter_list: action.json,
                customInputParamsValues: Tools.copy(state.customInputParamsValues),
            };
            for (var i = 0; i < state.reportCatalog.length; i++) {
                for (var j = 0; j < state.reportCatalog[i].data_report.input_parameters.length; j++) {
                    if (state.reportCatalog[i].data_report.input_parameters[j].key_name === 'access_token') {
                        obj.customInputParamsValues[state.reportCatalog[i].data_report.input_parameters[j].key_name] = getParameterRouter(
                            state,
                            action.params,
                            state.reportCatalog[i].data_report.input_parameters[j].key_name,
                            action.json
                        )[state.reportCatalog[i].data_report.input_parameters[j].key_name];
                    }
                }
            }
            for (var g = 0; g < action.json.length; g++) {
                if (action.json[g].input_parameter_type.key_name === 'global') {
                    obj.inputParameters[action.json[g].key_name] = Tools.copy(action.json[g]);
                    obj.inputParameters[action.json[g].key_name].details['current_value'] =
                        obj.inputParameters[action.json[g].key_name].details.default_value;
                    // obj[action.json[g].key_name] = getParameterRouter(
                    //     state,
                    //     action.params,
                    //     action.json[g].key_name,
                    //     action.json
                    // );
                    obj.globalInputParamsValues[action.json[g].key_name] = getParameterRouter(
                        state,
                        action.params,
                        action.json[g].key_name,
                        action.json
                    );
                }
            }

            return Object.assign({}, state, {
                obj,
            });
        }
        let inputParameters = {};
        const inputParametersByName = {};

        for (var g = 0; g < action.json.length; g++) {
            inputParametersByName[action.json[g].key_name] = Tools.copy(action.json[g]);
            if (action.json[g].input_parameter_type.key_name === 'global') {
                inputParameters[action.json[g].key_name] = Tools.copy(action.json[g]);
            }
        }
        // return Object.assign({}, state, {
        //     input_parameter_list: action.json,
        //     inputParameters: inputParameters,
        //     statusLoadsetDetailInputParams: true,
        // });

        return {
            ...state,
            input_parameter_list: action.json,
            inputParameters,
            statusLoadsetDetailInputParams: true,
            Source: { ...state.Source, ctg: { ...state.Source.ctg, inputParametersByName } },
        };
    }
    return Object.assign({}, state, {});
};

const onChangeSelect = (state, action) => {
    var inputParameters = Tools.copy(state.inputParameters);

    var itemParam = inputParameters[action.key_name];
    var choiceIndex = parseInt(action.value);
    var newValue = itemParam.details.choices[choiceIndex].value;
    itemParam.details.current_value = newValue;
    var obj = Tools.copy(state.globalInputParamsValues);
    obj[itemParam.key_name] = itemParam.details.current_value;
    let obj2 = {
        inputParameters: inputParameters,
        globalInputParamsValues: obj,
    };
    obj2[itemParam.key_name] = itemParam.details.current_value;
    return Object.assign({}, state, obj2);
};

const onInputParameterChange = (state, action) => {
    return {
        ...state,
        globalInputParamsValues: {
            ...state.globalInputParamsValues,
            [action.key_name]: action.value,
        },
    };
};

const selectDataView = (state, action) => {
    // console.log(action);

    return Object.assign({}, state, {
        statusShowRightPanel: true,
        // inputParamsSelectDataView: action.data,
        indexDataView: action.index,
    });
};

const hideSettingsPanel = (state, action) => {
    return Object.assign({}, state, {
        statusShowRightPanel: false,
        indexDataView: null,
    });
};

const getLocationForInputParam = (state, action) => {
    let input_parameter_list = Tools.copy(state.input_parameter_list);
    let inputParameters = Tools.copy(state.inputParameters);
    let objInputParam = null;
    if (action.phathName.split('/')[9] !== undefined) {
        var obj = JSON.parse(action.phathName.split('/')[8].split('=')[1]);
        for (var i = 0; i < input_parameter_list.length; i++) {
            if (obj[input_parameter_list[i].key_name] !== undefined) {
                input_parameter_list[i].details['current_value'] = obj[input_parameter_list[i].key_name];
            }
        }
        // for (var j = 0; j < inputParamForRightPanel.length; j++) {
        //     if (obj[inputParamForRightPanel[j].key_name] !== undefined) {
        //         inputParamForRightPanel[j].details.default_value = obj[inputParamForRightPanel[j].key_name];
        //     }
        // }

        for (var key in inputParameters) {
            if (obj[inputParameters[key].key_name] !== undefined) {
                inputParameters[key].details.current_value = obj[inputParameters[key].key_name];
            }
        }
        objInputParam = JSON.parse(action.phathName.split('/')[8].split('=')[1]);
    }

    return Object.assign({}, state, {
        objInputParam: objInputParam,
        inputParameters: inputParameters,
        input_parameter_list: input_parameter_list,
    });
};

const selectDate = (state, action) => {
    const { mainRange, comparisonRange } = action;
    return {
        ...state,
        customInputParamsValues: {
            ...state.customInputParamsValues,
            main_date_range: mainRange,
            comparison_date_range: comparisonRange,
        },
    };
};

const getLocationsIdForDate = (state, action) => {
    if (action.phathName.split('/')[4] === undefined) {
        return {
            ...state,
        };
    }
    if (action.phathName.split('/')[5] === undefined) {
        return {
            ...state,
            customInputParamsValues: {
                ...state.customInputParamsValues,
            },
        };
    }
    return {
        ...state,
        customInputParamsValues: {
            ...state.customInputParamsValues,
            main_date_range: action.phathName.split('/')[4].split(','),
            comparison_date_range: action.phathName.split('/')[5].split(','),
        },
    };
};

const changeCustomInputParams_ReportParametersManager = (state, action) => {
    const { history, mode } = action.payload;

    const pl_id = history.lastLocationsStack.length !== 0 ? history.lastLocationsStack[0] : Number(Object.keys(history.locations)[0]);

    const obj_ids = [];
    const pl_ids = [];
    Object.keys(history.locations)
        .filter((item) => history.locations[item].active)
        .forEach((plId) => {
            pl_ids.push(Number(plId));
            obj_ids.push(...history.locations[plId].objects);
        });
    const obj_id = state.Source.ctg.reportingObjectsByPlId[pl_id].main_servicepoint.id;

    try {
        history.objSets.noPl
            .filter((item) => item.selected)
            .forEach((item) => {
                obj_ids.push(item.id);
            });
    } catch (error) {
        console.warn(error);
    }

    try {
        Object.keys(history.objSets.hasPl).forEach((key) => {
            history.objSets.hasPl[key].sets.forEach((item) => {
                if (item.selected) {
                    obj_ids.push(item.id);
                }
            });
        });
    } catch (error) {
        console.warn(error);
    }

    return {
        ...state,
        customInputParamsValues: {
            ...state.customInputParamsValues,
            obj_ids,
            pl_ids,
            pl_id,
            obj_id,
        },
    };
};

const toggleIndicator_ReportParametersManager = (state, action) => {
    // console.log(action.payload);
    const { diagramIndex, indicatorName } = action.payload;
    const indicators = [...state.diagramOptionsByID[diagramIndex].indicators].map((item) => {
        if (item.name === indicatorName) {
            return { ...item, show: !item.show };
        } else {
            return { ...item };
        }
    });

    return {
        ...state,
        diagramOptionsByID: {
            ...state.diagramOptionsByID,
            [diagramIndex]: { ...state.diagramOptionsByID[diagramIndex], indicators },
        },
    };
};

const toggleSettings_ReportParametersManager = (state, action) => {
    // console.log(action.payload);
    const { diagramIndex, settingId } = action.payload;
    const settings = [...state.diagramOptionsByID[diagramIndex].settings].map((item) => {
        if (item.id === settingId) {
            return { ...item, checked: !item.checked };
        } else {
            return { ...item };
        }
    });

    return {
        ...state,
        diagramOptionsByID: {
            ...state.diagramOptionsByID,
            [diagramIndex]: { ...state.diagramOptionsByID[diagramIndex], settings },
        },
    };
};

const objectsSort = (arr, groupName) => {
    const sortedArr = arr.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    switch (groupName) {
        case 'floors':
            return sortedArr.reverse();
        default:
            return sortedArr;
    }
};

const getObjectsAndLocationsAndObjSets = (state, action) => {
    const { locationsResponse, objectsResponse, objSetsResponse } = action.payload;
    const { src, ctg } = state.Source;
    const reportingObjectsById = {};
    const reportingObjectsByPlId = {};
    const repeatingNamesByName = {};
    const mapsObjectsByPlId = {};
    const projectLocationsById = {};
    const plGeometryByObjId = {};
    const dataObjSetsById = {};
    const dataObjSetsByParentId = {};
    const colors = { ...ctg.colors };

    locationsResponse.forEach((item) => {
        // console.log(item);

        projectLocationsById[item.id] = item;
        // const name = `${item.name} (id: ${item.id})`;
        // colors[`servicepoint:${item.id}`] = diagramTools.generateColorForObjects(name);
    });

    objectsResponse.forEach((loc) => {
        const objects = {};
        const pl_coordinates = {
            lat: Number(projectLocationsById[loc.pl_id].latitude),
            lng: Number(projectLocationsById[loc.pl_id].longitude),
        };
        Object.keys(loc).forEach((field) => {
            if (loc[field].length === 0 || field === 'main_servicepoint') {
                reportingObjectsById[loc[field].id] = {
                    ...loc[field],
                    pl_id: loc.pl_id,
                    pl_coordinates,
                };
                return;
            }
            if (ctg.objectTypes.includes(field) && field === 'zones') {
                loc[field].forEach((item) => {
                    if (!objects[item.group_marker]) {
                        const arr = [{ ...item, name: item.zone_name }];
                        objects[item.group_marker] = [...arr];
                    } else {
                        objects[item.group_marker].push({ ...item, name: item.zone_name });
                    }
                });
            } else if (ctg.objectTypes.includes(field) && field === 'floors') {
                const floors = [...loc[field]];
                objects[field] = floors.map((item) => ({
                    ...item,
                    name: `${_t.t('Этаж')} ${item.floor}`,
                }));
            } else if (ctg.objectTypes.includes(field)) {
                objects[field] = [...loc[field]];
            }
        });
        Object.keys(objects).forEach((group) => {
            objects[group] = [...objectsSort(objects[group], group)];
            objects[group].forEach((item) => {
                reportingObjectsById[item.id] = {
                    ...item,
                    pl_id: loc.pl_id,
                    pl_coordinates,
                };
            });
        });

        reportingObjectsByPlId[loc.pl_id] = {
            main_servicepoint: loc.main_servicepoint,
            pl_id: loc.pl_id,
            pl_name: loc.pl_name,
            objects,
        };

        mapsObjectsByPlId[loc.pl_id] = loc;
    });

    Object.keys(reportingObjectsById).forEach((key) => {
        const name = `(id: ${key})${reportingObjectsById[key].name}`;
        colors[`${reportingObjectsById[key].obj_type}:${key}`] = diagramTools.generateColorForObjects(name);

        if (!repeatingNamesByName[reportingObjectsById[key].name]) {
            repeatingNamesByName[reportingObjectsById[key].name] = [key];
        } else {
            repeatingNamesByName[reportingObjectsById[key].name].push(key);
        }
    });

    // const splitted = pathname.split('/');
    // let pl_ids = [];
    // let obj_ids = [];
    // const customInputParamsValues = { ...state.customInputParamsValues };

    // if (splitted.length >= 8) {

    //     new Set(obj_ids.map((objId) => reportingObjectsById[objId].pl_id)).forEach((plId) => {
    //         if (!pl_ids.includes(plId)) {
    //             pl_ids.push(plId);
    //         }
    //     });

    //     pl_ids.forEach((plId) => {
    //         const { objects } = reportingObjectsByPlId[plId];
    //         const allObjects = Object.keys(objects).reduce((acc, item) => {
    //             return objects[item] === 'pl_id' || objects[item] === 'pl_name' ? acc : [...acc, ...objects[item].map((obj) => obj.id)];
    //         }, []);
    //         if (!obj_ids.some((obj) => allObjects.includes(obj))) {
    //             obj_ids.push(reportingObjectsByPlId[plId].main_servicepoint.id);
    //         }
    //     });

    //     customInputParamsValues.obj_ids = [...Array.from(new Set(obj_ids))];
    //     customInputParamsValues.pl_ids = [...Array.from(new Set(pl_ids))];
    //     if (pl_ids[0] !== undefined) {
    //         customInputParamsValues.pl_id = pl_ids[0];
    //         customInputParamsValues.obj_id = reportingObjectsByPlId[pl_ids[0]].main_servicepoint.id;
    //     }
    // }

    objSetsResponse.forEach((item) => {
        dataObjSetsById[item.id] = item;
    });

    const noPl = [];
    const hasPl = {};

    objSetsResponse.forEach((item) => {
        if (item.project_location === null) {
            noPl.push(item);
        } else {
            if (!hasPl.hasOwnProperty(item.project_location)) {
                hasPl[item.project_location] = [item];
            } else {
                hasPl[item.project_location].push(item);
            }
        }

        const parentId = item.project_location || item.owner || item.project;
        let parentType = '';
        if (item.project_location !== null) {
            parentType = 'project_location';
        } else if (item.project !== null) {
            parentType = 'project';
        } else if (item.owner !== null) {
            parentType = 'owner';
        }
        if (!dataObjSetsByParentId.hasOwnProperty(parentId)) {
            dataObjSetsByParentId[parentId] = { parentType };
            dataObjSetsByParentId[parentId].sets = [item];
        } else {
            dataObjSetsByParentId[parentId].sets.push(item);
        }
    });
    const dataObjSetsByPlId = { noPl, hasPl };

    return {
        ...state,
        // customInputParamsValues,
        Source: {
            ...state.Source,
            src: {
                ...src,
                analytics_report_objects: objectsResponse,
                project_locations: locationsResponse,
                dataobjsets: objSetsResponse,
            },
            ctg: {
                ...ctg,
                colors,
                reportingObjectsById,
                repeatingNamesByName,
                reportingObjectsByPlId,
                mapsObjectsByPlId,
                projectLocationsById,
                dataObjSetsById,
                plGeometryByObjId,
                dataObjSetsByPlId,
                dataObjSetsByParentId,
            },
        },
    };
};

const setVersionData_source = (state, action) => {
    console.log('>>>>>>>>>>>>', action.json);
    let uuidDataByUuid = {};
    let itemUuid = null;
    let uuidDataList = [];
    let uuidDataSelected = {};
    let arrayDateToSelectedDate = [];
    let arrayDateAfterSelectedDate = [];
    if (action.json.length > 0) {
        let maxObj = {
            sortData: +moment(action.json[0].dt),
            value: action.json[0].uuid,
            label: action.json[0].dt,
        };
        // console.log('>>>>>>>>>>>>>>.', state.customInputParamsValues.main_date_range[1])
        action.json.forEach((item, index) => {
            if (index === 0) {
                itemUuid = item.uuid;
                uuidDataSelected = {
                    value: item.uuid,
                    label: item.dt,
                };
            }
            if (+moment(state.customInputParamsValues.main_date_range[1]) >= +moment(item.dt)) {
                arrayDateToSelectedDate.push({
                    dt: +moment(item.dt),
                    uuid: item.uuid,
                });
            }
            if (+moment(state.customInputParamsValues.main_date_range[1]) < +moment(item.dt)) {
                arrayDateAfterSelectedDate.push({
                    dt: +moment(item.dt),
                    uuid: item.uuid,
                });
            }
            if (maxObj.sortData < +moment(item.dt)) {
                maxObj = {
                    sortData: +moment(item.dt),
                    value: item.uuid,
                    label: item.dt,
                };
            }
            uuidDataByUuid[item.uuid] = item;
            // console.log('setVersionData_source', moment(item.dt).format('dddd, MMMM Do YYYY, h:mm:ss a'));
            uuidDataList.push({
                value: item.uuid,
                label: item.dt,
                sortData: +moment(item.dt),
            });
        });

        arrayDateToSelectedDate.sort((a, b) => {
            return b.dt - a.dt;
        });

        arrayDateAfterSelectedDate.sort((a, b) => {
            return a.dt - b.dt;
        });

        uuidDataSelected = maxObj;
        itemUuid = maxObj.value;
        // if (arrayDateToSelectedDate.length > 0) {
        //     itemUuid = arrayDateToSelectedDate[0].uuid;
        //     uuidDataSelected = {
        //         value: arrayDateToSelectedDate[0].uuid,
        //         label: arrayDateToSelectedDate[0].dt
        //     };
        // } else {
        //     if (arrayDateAfterSelectedDate[0] !== undefined) {
        //         itemUuid = arrayDateAfterSelectedDate[0].uuid;
        //         uuidDataSelected = {
        //             value: arrayDateAfterSelectedDate[0].uuid,
        //             label: arrayDateAfterSelectedDate[0].dt
        //         };
        //     }
        // }

        // console.log('>>>>>>>>>>>>>>>!', +moment(state.ctg.selected), arrayDateToSelectedDate, arrayDateAfterSelectedDate);
        // console.log('>>>>>>>>>>>>>>>>>>>', uuidDataList)
        uuidDataList.sort((a, b) => {
            return b.sortData - a.sortData;
        });
    }

    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                versionPlanSets: action.json,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
        mapsData: {
            ...state.mapsData,
            uuidDataByUuid: uuidDataByUuid,
            selectedUuid: itemUuid,
            uuidDataList: uuidDataList,
            uuidDataSelected: uuidDataSelected,
            uuidDataSelectedTempPlaces: uuidDataSelected,
        },
    };
};

const getDataByUuids_Source = (state, action) => {
    let itemUuid = null;
    let uuidDataSelected = {};
    // console.log('setVersionData_source',state.ctg.uuidDataByUuid[action.payload.data].dt);
    itemUuid = state.mapsData.uuidDataByUuid[action.payload.data].uuid;
    uuidDataSelected = {
        value: state.mapsData.uuidDataByUuid[action.payload.data].uuid,
        label: state.mapsData.uuidDataByUuid[action.payload.data].dt,
    };

    return {
        ...state,
        // src: {
        //     ...state.src,
        //     versionPlanSets: action.json
        //
        // },
        mapsData: {
            ...state.mapsData,
            selectedUuid: itemUuid,
            // uuidDataList:uuidDataList,
            uuidDataSelected: uuidDataSelected,
            statusLoadVersion: true,
        },
    };
};

const setDataByUuid_source = (state, action) => {
    let plans = action.json.plans;
    // console.log('12345>>>>>', action.json);
    plans.sort((a, b) => {
        return b.floor - a.floor;
    });

    let heightMapMax = 0;
    let widthMapMax = 0;

    let coordsPlaces = [];
    let optionTypeNewMaps;
    optionTypeNewMaps = {};
    plans.forEach((item, index) => {
        // console.log('setDataByUuid_source', item)
        if (item.height / item.scale > heightMapMax) {
            heightMapMax = item.height / item.scale;
        }
        if (item.width / item.scale > widthMapMax) {
            widthMapMax = item.width / item.scale;
        }

        let zoneMarkers = [];

        for (var key in item.layers) {
            if (key === 'place_layer') {
                let arrrayCoords = [];
                let arrayMarker = [];
                let arrayIsOpened = [];
                item.layers[key].places.forEach((item2, index2) => {
                    arrrayCoords.push(item2.coords);
                    coordsPlaces.push(item2.coords);
                    arrayMarker.push(item2.marker);
                    arrayIsOpened.push(item2.is_opened);
                    // console.log('><<<<<<<<<', GeometryVSDataManager.reportNameWithPlace(item2.marker));
                });
                // GeometryVSDataManager.reportNameWithPlace
                plans[index].layers[key]['coords'] = arrrayCoords;
                plans[index].layers[key]['markers'] = arrayMarker;
                plans[index].layers[key]['arrayIsOpened'] = arrayIsOpened;
                plans[index].layers[key]['indexOfFloor'] = index;
            } else if (key === 'zone_layer') {
                item.layers[key].groups.forEach((item2, index2) => {
                    let arrrayCoords = [];
                    let zoneMarkers = [];
                    let zoneNames = [];
                    item2.zones.forEach((item3, index3) => {
                        arrrayCoords.push(item3.coords);
                        zoneMarkers.push(item3.zone_marker);
                        zoneNames.push(item3.zone_name);
                    });

                    if (coordsPlaces.length > 0) {
                        optionTypeNewMaps['places'] = {
                            value: 'places',
                            label: 'Помещения',
                        };
                    }
                    // console.log('>>>>>>>>>><<<<<<');
                    optionTypeNewMaps[item2.group_marker] = {
                        value: item2.group_marker,
                        label: item2.group_name,
                    };
                    plans[index].layers[key].groups[index2]['coords'] = arrrayCoords;
                    plans[index].layers[key].groups[index2]['zoneMarkers'] = zoneMarkers;
                    plans[index].layers[key].groups[index2]['zoneNames'] = zoneNames;

                    plans[index].layers[item2.group_marker] = {};
                    plans[index].layers[item2.group_marker]['coords'] = arrrayCoords;
                    plans[index].layers[item2.group_marker]['zoneMarkers'] = zoneMarkers;
                    plans[index].layers[item2.group_marker]['zoneNames'] = zoneNames;
                    plans[index].layers[item2.group_marker]['indexOfFloor'] = index;
                });
            } else if (key === 'shopster_ipoint_layer') {
                let arrrayCoords = [];
                let markers = [];
                let arrayIsOpened = [];
                item.layers[key].ipoints.forEach((item2, index2) => {
                    arrrayCoords.push(item2.coords);
                    markers.push(item2.marker);
                    arrayIsOpened.push(item2.is_opened);
                });
                plans[index].layers[key]['coords'] = arrrayCoords;
                plans[index].layers[key]['markers'] = markers;
                plans[index].layers[key]['arrayIsOpened'] = arrayIsOpened;
            }
        }
    });

    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                objectPlan: action.json,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
        mapsData: {
            ...state.mapsData,
            objectName: action.json.name,
            plans: plans,
            heightMapMax: heightMapMax,
            widthMapMax: widthMapMax,
            selectForMaps: Object.values(optionTypeNewMaps),
            optionTypeNewMapsObj: optionTypeNewMaps,
            statusLoadVersion: false,
        },
    };
};

const setPLIdInfo = (state, action) => {
    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                dataInformationObject: action.json,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
        mapsData: {
            ...state.mapsData,
            objectName: action.json.name,
            globalUuid: action.json.plan_set_uuid,
        },
    };
};

const clearVersionData_ReportParametersManager = (state, action) => {
    return {
        ...state,

        mapsData: {
            plans: [],
            objectName: null,
            globalUuid: null,
            uuidDataByUuid: null,
            selectedUuid: null,
            heightMapMax: 0,
            widthMapMax: 0,
            dataUneqUsers: {},
            dataUneqUsersOfZones: {},
            objAnalitic: {},
            statusLoadSelectPolygons: false,
            selectForMaps: [],
            optionTypeNewMapsObj: {},
            statusLoadVersion: false,
            selected: 0,
        },
    };
};

const setRooms = (state, action) => {
    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                roomsMaps: action.data,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
    };
};

const selectPolygons = (state, action) => {
    return {
        ...state,
        mapsData: {
            ...state.mapsData,
            statusLoadSelectPolygons: action.status,
        },
    };
};

const setDataSelectPolygon = (state, action) => {
    return {
        ...state,

        mapsData: {
            ...state.mapsData,
            statusLoadSelectPolygons: false,
        },
    };
};

const setUnekUsers = (state, action) => {
    // console.log('>>>>>>>>>>>>>>', action.json, GeometryVSDataManager.setUneqUser(action.json));
    let dataUneqUsers = GeometryVSDataManager.setUneqUser(action.json);
    let objAnalitic = state.mapsData.objAnalitic;
    let statusLoadSelectPolygons;
    if (objAnalitic.load !== undefined) {
        statusLoadSelectPolygons = false;
    } else {
        objAnalitic['load'] = '';
    }

    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                uneqUsers: action.json,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
        mapsData: {
            ...state.mapsData,
            statusLoadSelectPolygons: statusLoadSelectPolygons,
            objAnalitic: objAnalitic,
            dataUneqUsers: dataUneqUsers,
        },
    };
};

const setUnekUsersOfZones = (state, action) => {
    let dataUneqUsersOfZones = GeometryVSDataManager.setUneqUserZones(action.json);
    let objAnalitic = state.mapsData.objAnalitic;
    let statusLoadSelectPolygons;
    if (objAnalitic.load !== undefined) {
        statusLoadSelectPolygons = false;
    } else {
        objAnalitic['load'] = '';
    }

    return {
        ...state,
        Source: {
            src: {
                ...state.Source.src,
                uneqUsersOfZones: action.json,
            },
            ctg: {
                ...state.Source.ctg,
            },
        },
        mapsData: {
            ...state.mapsData,
            statusLoadSelectPolygons: statusLoadSelectPolygons,
            objAnalitic: objAnalitic,
            dataUneqUsersOfZones: dataUneqUsersOfZones,
        },
    };
};

const getDateForMaps = (state, action) => {
    let itemUuid = null;
    let uuidDataSelected = {};
    let arrayDateToSelectedDate = [];
    let arrayDateAfterSelectedDate = [];
    if (state.mapsData.uuidDataList !== undefined) {
        state.mapsData.uuidDataList.forEach((item, index) => {
            // if (index === 0) {
            //     itemUuid = item.uuid;
            //     uuidDataSelected = {
            //         value: item.uuid,
            //         label: 'Версия: ' +  moment(item.dt).format('dddd, MMMM Do YYYY, h:mm:ss a')
            //     };
            // }
            if (+moment(action.payload.selected) >= item.sortData) {
                arrayDateToSelectedDate.push({
                    dt: item.sortData,
                    uuid: item.value,
                });
            }
            if (+moment(action.payload.selected) < item.sortData) {
                arrayDateAfterSelectedDate.push({
                    dt: item.sortData,
                    uuid: item.value,
                });
            }
            // uuidDataByUuid[item.uuid] = item;
            // // console.log('setVersionData_source', moment(item.dt).format('dddd, MMMM Do YYYY, h:mm:ss a'));
            // uuidDataList.push({
            //     value: item.uuid,
            //     label: 'Версия: ' + moment(item.dt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
            //     sortData: + moment(item.dt)
            // });
        });

        arrayDateToSelectedDate.sort((a, b) => {
            return b.dt - a.dt;
        });

        arrayDateAfterSelectedDate.sort((a, b) => {
            return a.dt - b.dt;
        });

        if (arrayDateToSelectedDate.length > 0) {
            itemUuid = arrayDateToSelectedDate[0].uuid;
            uuidDataSelected = {
                value: arrayDateToSelectedDate[0].uuid,
                label: arrayDateToSelectedDate[0].dt,
            };
        } else {
            itemUuid = arrayDateAfterSelectedDate[0].uuid;
            uuidDataSelected = {
                value: arrayDateAfterSelectedDate[0].uuid,
                label: arrayDateAfterSelectedDate[0].dt,
            };
        }
    }

    return {
        ...state,

        mapsData: {
            ...state.mapsData,
            selected: action.payload.selected,
            selectedUuid: itemUuid,
            uuidDataSelected: uuidDataSelected,
        },
    };
};

// const generateMode = (arr) => {
//     if (arr.includes('pl_id') && !arr.includes('pl_ids')) {
//         return 'singleLocation';
//     } else if (arr.includes('pl_ids') && arr.includes('obj_ids') && arr.includes('contra_obj_ids') && arr.includes('main_obj_ids')) {
//         return 'main_set_2_contra_set';
//     } else if (arr.includes('pl_ids') && arr.includes('obj_ids') && arr.includes('contra_obj_ids') && arr.includes('main_obj_id')) {
//         return 'main_2_contra_set';
//     } else if (arr.includes('pl_ids') && arr.includes('obj_ids')) {
//         return 'multiLocation_multiObject';
//     } else if (arr.includes('pl_ids')) {
//         return 'multiLocation';
//     } else {
//         return 'multiAll';
//     }
// };

// const getSections_Source = (state, action) => {
//     const data = action.payload.json;
//     console.log(data);
//     const { src, ctg } = state.Source;
//     const sectionsByKeyName = {};
//     data.forEach((item) => {
//         const subsections = item.subsections.map((item) => {
//             return { ...item, mode: generateMode(item.required_input_parameters) };
//         });
//         sectionsByKeyName[item.key_name] = item;
//         sectionsByKeyName[item.key_name].subsections = subsections;
//     });
//     return {
//         ...state,
//         Source: {
//             ...state.Source,
//             src: { ...src, section_list: data },
//             ctg: { ...ctg, sectionsByKeyName },
//         },
//     };
// };

const getAvailableOwners = (state, action) => {
    const available_owners = action.json;
    const { src, ctg } = state.Source;
    const availableOwnersTree = dataToTree(available_owners);
    const availableOwnersById = {};

    available_owners.forEach((item) => {
        availableOwnersById[item.id] = { ...item, expand: false };
    });

    return {
        ...state,
        Source: {
            ...state.Source,
            src: { ...src, available_owners },
            ctg: { ...ctg, availableOwnersTree, availableOwnersById },
        },
    };
};

const getAvailableProjects = (state, action) => {
    const available_projects = action.json;
    const { src, ctg } = state.Source;
    const availableProjectsByOwnerId = {};
    const availableProjectsById = {};
    available_projects.forEach((project) => {
        availableProjectsById[project.id] = project;
        if (!availableProjectsByOwnerId[project.owner]) {
            availableProjectsByOwnerId[project.owner] = {};
            availableProjectsByOwnerId[project.owner].projects = [];
        }
        availableProjectsByOwnerId[project.owner].projects.push(project);
    });
    return {
        ...state,
        Source: {
            ...state.Source,
            src: { ...src, available_projects },
            ctg: { ...ctg, availableProjectsByOwnerId, availableProjectsById },
        },
    };
};

const getDataObjSets = (state, action) => {
    const { src, ctg } = state.Source;
    const dataobjsets = action.json;
    const dataObjSetsById = {};

    const noPl = [];
    const hasPl = {};
    dataobjsets.forEach((item) => {
        dataObjSetsById[item.id] = item;
        if (item.project_location === null) {
            noPl.push(item);
        } else {
            if (!hasPl.hasOwnProperty(item.project_location)) {
                hasPl[item.project_location] = [item];
            } else {
                hasPl[item.project_location].push(item);
            }
        }
    });
    const dataObjSetsByPlId = { noPl, hasPl };

    return {
        ...state,
        Source: {
            ...state.Source,
            src: { ...src, dataobjsets },
            ctg: { ...ctg, dataObjSetsById, dataObjSetsByPlId },
        },
    };
};

const toggleModal = (state, action) => {
    const { args } = action.payload;
    if (!args.show) {
        return { ...state, modal: stateInit.modal };
    }
    const modal = { ...args };
    return { ...state, modal };
};

const changeOutdoorAdSpaceIds = (state, action) => {
    const { selectedSpaces } = action.payload;
    const { outdoor_ad_space_ids } = state.customInputParamsValues;

    if (!Tools.arraysCompare(selectedSpaces, outdoor_ad_space_ids)) {
        return {
            ...state,
            customInputParamsValues: {
                ...state.customInputParamsValues,
                outdoor_ad_space_ids: [...selectedSpaces],
            },
        };
    } else {
        return { ...state };
    }
};

const getOutdoorSpaces = (state, action) => {
    const outdoor_ad_spaces = action.json;
    const outdoorAdSpacesById = {};

    const colors = { ...state.Source.ctg.colors };
    if (outdoor_ad_spaces) {
        outdoor_ad_spaces.forEach((item) => {
            outdoorAdSpacesById[item.id] = item;
            const name = `${item.name} (id: ${item.id})`;
            colors[`outdooradspace:${item.id}`] = diagramTools.generateColorForObjects(name);
        });
    }
    return {
        ...state,

        Source: {
            ...state.Source,
            src: { ...state.Source.src, outdoor_ad_spaces },
            ctg: { ...state.Source.ctg, outdoorAdSpacesById, colors },
        },
    };
};

const getAdCampaigns_RPM = (state, action) => {
    const ad_campaigns = action.payload.json;
    // const ad_campaigns = action.payload.json.sort((a, b) => moment(b.updated).diff(moment(a.updated)));
    const adCampaignsById = {};
    if (ad_campaigns) {
        ad_campaigns.forEach((item) => {
            adCampaignsById[item.id] = item;
        });
    }

    return {
        ...state,
        Source: {
            ...state.Source,
            src: { ...state.Source.src, ad_campaigns },
            ctg: { ...state.Source.ctg, adCampaignsById },
        },
    };
};

const changeSelectedPolygon = (state, action) => {
    const { region_id } = action.payload;

    return {
        ...state,
        customInputParamsValues: { ...state.customInputParamsValues, region_id },
    };
};

const changeCustomParams_RPM = (state, action) => {
    const { params } = action.payload;

    return {
        ...state,
        customInputParamsValues: { ...state.customInputParamsValues, ...params },
    };
};

const contra_RPM = (state, action) => {
    // console.log(action.payload);

    return {
        ...state,
        customInputParamsValues: { ...state.customInputParamsValues, ...action.payload },
    };
};

const changeAdCampaignIds_RPM = (state, action) => {
    const { ad_campaign_id, ad_campaign_ids } = action.payload;

    return {
        ...state,
        customInputParamsValues: { ...state.customInputParamsValues, ad_campaign_id, ad_campaign_ids },
    };
};

const fillOwnerAndProject_RPM = (state, action) => {
    const { owner, project } = action.payload;
    return { ...state, customInputParamsValues: { ...state.customInputParamsValues, owner_id: owner, project_id: project } };
};

const changeProjectId_RPM = (state, action) => {
    const { project_id } = action.payload;
    return {
        ...state,
        customInputParamsValues: {
            ...state.customInputParamsValues,
            project_id,
            ad_campaign_id: stateInit.customInputParamsValues.ad_campaign_id,
            ad_campaign_ids: stateInit.customInputParamsValues.ad_campaign_ids,
        },
    };
};

const changeReportList_RPM = (state, action) => {
    const { custom_dataViews } = action.payload;

    return {
        ...state,
        // dataViewsByGroupID,
    };
};

const getDataViewList = (state, action) => {
    const data_view_list = action.payload.json;
    return {
        ...state,
        Source: {
            ...state.Source,
            src: { ...state.Source.src, data_view_list },
        },
    };
};

const test_Source = (state, action) => {
    console.log('<><><><><>', action.payload);
    return { ...state };
};

const ReportParametersManager = (state = stateInit, action) => {
    // console.log('>>>>>>>>>>>>>>>>', action.type)
    // console.log('------', state.customInputParamsValues.owner_id)
    switch (action.type) {
        case 'setReportList':
            return setReportList(state, action);
        case 'getDataInputParams':
            return getDataInputParams(state, action);
        case 'setDetailInputParams':
            return setDetailInputParams(state, action);
        case 'onChangeSelect':
            return onChangeSelect(state, action);
        case 'selectDataView':
            return selectDataView(state, action);
        case 'hideSettingsPanel':
            return hideSettingsPanel(state, action);
        case 'getDataItemDataView':
            return getDataItemDataView(state, action);
        case 'getLoadDataDataView':
            return getLoadDataDataView(state, action);
        case 'getLocationForInputParam':
            return getLocationForInputParam(state, action);
        case 'onInputParameterChange':
            return onInputParameterChange(state, action);
        case 'changeCustomInputParams_ReportParametersManager':
            return changeCustomInputParams_ReportParametersManager(state, action);
        case 'selectDate':
            return selectDate(state, action);
        case 'getLocationsIdForDate':
            return getLocationsIdForDate(state, action);
        case 'toggleIndicator_ReportParametersManager':
            return toggleIndicator_ReportParametersManager(state, action);
        case 'toggleSettings_ReportParametersManager':
            return toggleSettings_ReportParametersManager(state, action);
        case 'test_Source':
            return test_Source(state, action);
        // case 'getSections_Source':
        //     return getSections_Source(state, action);
        case 'getObjectsAndLocationsAndObjSets':
            return getObjectsAndLocationsAndObjSets(state, action);
        case 'setVersionData_source':
            return setVersionData_source(state, action);
        case 'setDataByUuid_source':
            return setDataByUuid_source(state, action);
        case 'setPLIdInfo':
            return setPLIdInfo(state, action);
        case 'setRooms':
            return setRooms(state, action);
        case 'setUnekUsers':
            return setUnekUsers(state, action);
        case 'setUnekUsersOfZones':
            return setUnekUsersOfZones(state, action);
        case 'getDataByUuids_Source':
            return getDataByUuids_Source(state, action);
        case 'selectPolygons':
            return selectPolygons(state, action);
        case 'setDataSelectPolygons':
            return setDataSelectPolygon(state, action);
        case 'clearVersionData_ReportParametersManager':
            return clearVersionData_ReportParametersManager(state, action);
        case 'getDateForMaps_Source':
            return getDateForMaps(state, action);
        case 'getDataObjSets':
            return getDataObjSets(state, action);
        case 'toggleModal':
            return toggleModal(state, action);
        case 'changeOutdoorAdSpaceIds':
            return changeOutdoorAdSpaceIds(state, action);
        case 'getOutdoorSpaces':
            return getOutdoorSpaces(state, action);
        case 'getPolygonGroups':
            return getPolygonGroups(state, action);
        case 'changeSelectedPolygon':
            return changeSelectedPolygon(state, action);
        case 'getAvailableOwners':
            return getAvailableOwners(state, action);
        case 'getAvailableProjects':
            return getAvailableProjects(state, action);
        case 'fillParamsFromCloud':
            return fillParamsFromCloud(state, action);
        case 'toggleSharedAlert':
            return toggleSharedAlert(state, action);
        case 'changeCustomParams_RPM':
            return changeCustomParams_RPM(state, action);
        case 'contra_RPM':
            return contra_RPM(state, action);
        case 'getAdCampaigns_RPM':
            return getAdCampaigns_RPM(state, action);
        case 'changeAdCampaignIds_RPM':
            return changeAdCampaignIds_RPM(state, action);
        case 'fillOwnerAndProject_RPM':
            return fillOwnerAndProject_RPM(state, action);
        case 'changeProjectId_RPM':
            return changeProjectId_RPM(state, action);
        case 'changeReportList_RPM':
            return changeReportList_RPM(state, action);
        case 'getDataViewList':
            return getDataViewList(state, action);
        case 'hideSpinerDataView':
            return hideSpinerDataView(state, action);
        default:
            return state;
    }
};

export default ReportParametersManager;

