const mergeTables = tables => {
  try {
    const keyName = tables[0].data_report.data_type.key_name;
    switch (keyName) {
      case 'comparison_score':
      case 'score':
        return tables.reduce(
          (acc, item) => {
            return [...acc, item.data.data[1]];
          },
          [tables[0].data.data[0]]
        );
      case 'dynamics':
      case 'distribution_over_days_of_week':
      case 'distribution_over_hours_of_day':
        return tables[0].data.data.map((item, i) => {
          return [
            item[0],
            ...tables.map(tbl => {
              return tbl.data.data[i][1];
            })
          ];
        });

      default:
        break;
    }
  } catch (error) {}
};

export default mergeTables;
