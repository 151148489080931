import React from 'react';
import styled from 'styled-components/macro';

const NoSelectedWarning = React.memo(props => {
    return (
        <Container>
            <i className="fa fa-warning" />
            {props.text}
        </Container>
    );
});

export default NoSelectedWarning;

const Container = styled.div`
    padding: 10px;
    margin: 10px;
    border: 1px solid #777;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    i {
        font-size: 20px;
        color: red;
    }
`;
