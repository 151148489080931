import { useMemo, useState, useEffect } from 'react';

const useHistory = (reportingObjectsById, history) => {
    const [objects, setObjects] = useState([]);
    const [canSaveSet, setCanSaveSet] = useState(true);
    useEffect(() => {
        const { objects, canSaveSet } = checkHistory;
        setObjects((p) => objects);
        setCanSaveSet(canSaveSet);
    }, [history]);

    const checkHistory = useMemo(() => {
        // const { history, reportingObjectsById } = props;
        const objects = [];
        let canSaveSet = true;
        Object.keys(history.locations).forEach((plId) => {
            const pl = history.locations[plId];
            if (pl.active) {
                pl.objects.forEach((objId, i, arr) => {
                    if (arr.length === 1 || (arr.length >= 1 && reportingObjectsById[objId].obj_type !== 'main_servicepoint')) {
                        objects.push(objId);
                    } else if (arr.length >= 1 && reportingObjectsById[objId].obj_type === 'main_servicepoint') {
                        canSaveSet = false;
                    }
                });
            }
        });
        return {
            objects,
            canSaveSet,
        };
    }, [history]);

    return { objects, canSaveSet };
};

export default useHistory;
