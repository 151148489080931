import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import GroupTitle from './group_title';
import Group from './group';
import CustomControl from './custom_controls/CustomControl';
import { DiagramsContext } from '../index';

export default React.memo(() => {
    const { t } = useTranslation();
    const groupRef = useRef();
    const {
        groups,
        lang,
        cabinetMode,
        dataViewsByGroupID,
        fullScreenId,
        currentSubSection,
        diagramOptionsByID,
        currentSection,
        sectionsByKeyName,
    } = useContext(DiagramsContext);



    const infoText = t('Для добавления диаграммы из любого раздела в My Dashboard, вызовите контекстное меню необходимой диаграммы.');

    return useMemo(() => {

        return groups && groups.groups ? (
            <MainContainer fullScreenId={fullScreenId} ref={groupRef}>
                {groups.groups.map((group, i) => {

                    const editable = sectionsByKeyName[currentSection].subsections
                        .filter((item) => item.key_name === currentSubSection)[0]
                        .data_view_groups.filter((item) => item.id === group.id)[0].editable;

                    const generateGroupContent = () => {
                        if (dataViewsByGroupID[group.id] && (group.custom_key_name === null || group.custom_key_name === '')) {
                            return <Group group={dataViewsByGroupID[group.id]} groupID={group.id} editable={editable} />;
                        } else if (group.custom_key_name) {
                            return <CustomControl group={group} />;
                        } else if (!dataViewsByGroupID[group.id] && currentSection === 'custom_section') {
                            return <Info>{infoText}</Info>;
                        } else if (!dataViewsByGroupID[group.id]) {
                            return null;
                        } else {
                            return null;
                        }
                    };

                    const showGroup =
                        fullScreenId === null
                            ? true
                            : fullScreenId !== null &&
                              dataViewsByGroupID[group.id] &&
                              dataViewsByGroupID[group.id].map((dataView) => dataView.data_view_id).some((id) => id === fullScreenId)
                            ? true
                            : group.custom_key_name
                            ? true
                            : false;
                    let title = lang === 'ru' ? group.translations['ru'].name : group.translations['en'].name;

                    return showGroup ? (
                        <Container cabinetMode={cabinetMode} key={`${group.id}-${i}-newLine`}>
                            {(group.custom_key_name === null || group.custom_key_name === '') && fullScreenId === null ? (
                                <GroupTitle title={title} group={group} editable={editable} />
                            ) : null}
                            {generateGroupContent()}
                        </Container>
                    ) : null;
                })}
            </MainContainer>
        ) : null;
    }, [groups, lang, cabinetMode, dataViewsByGroupID, fullScreenId, currentSubSection, infoText]);
});

const Container = styled.section`
    box-sizing: border-box;
    height: ${(p) => (p.fullScreenId === null ? 'auto' : '100%')};
`;

const Info = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainContainer = styled.section`
    box-sizing: border-box;
    padding: ${(p) => (p.fullScreenId === null ? '0px' : '15px')};
    padding-top: ${(p) => (p.fullScreenId === null ? '15px' : '15px')};
    height: ${(p) => (p.fullScreenId === null ? 'auto' : '100%')};
    
`;
