import React, {useEffect, useState, useMemo, useLayoutEffect} from 'react';
import styled from 'styled-components/macro';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import cloneDeep from 'lodash/cloneDeep';
import { runAction } from '../../../../../actions';
import { useTranslation } from 'react-i18next';
import CustomDetailsList from './components/custom_details_list';
import Tools from '../../../../../tools/tools';
import { getFoundSegments } from '../../../../../api/api';
import tools from "./tools/tools";
import OverlaySpinner from "../../../../overlay_spinner/overlay_spinner";


const SegmentsControl = React.memo(({ data, ...props }) => {
    const paramsSet = Tools.useGetParamSet();
    const [showTree, setShowTree] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(true);
    const [search, set_search] = useState('');
    const [theme] = useState(getTheme());
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { segmentsToShow, segments, foundSegments, addSegmentsNumber, segmentSearchValue, isShowSpinnerSegments } = useSelector((state) => state.CustomControls);

    const { isAdCampShown, singleSelection, projectSearchValue, tree, multiSelection, campaignsToShow, addCampaignsNumber } = useSelector(
        (state) => state.AdCampaigns
    );
    // const { lang } = useSelector((state) => state.TopPanel);
    const { options, token } = useSelector((state) => state.LoginApp);
    // const { adCampaignsById, outdoorAdSpacesById } = useSelector(state => state.ReportParametersManager.Source.ctg);
    const { ad_campaigns, outdoor_ad_spaces } = useSelector((state) => state.ReportParametersManager.Source.src);
    const { reportingObjectsByPlId, reportingObjectsById } = useSelector((state) => state.ReportParametersManager.Source.ctg);
    const { customInputParamsValues } = useSelector((state) => state.ReportParametersManager);
    const [width, height] = tools.useWindowSize();

    const catalog = [
        // { id: 'ID' },
        { select: '' },
        { name: 'Name' },
        // { updated: 'Updated' },
        { progress_status: 'Shopster status' },
        { count_unique: 'Uniques' },
        { segment_type: 'Type' },
        // { content_type: 'Content type' },
        { date_from: 'From' },
        { date_to: 'To' },
        { mailru_status_export: 'Mail.ru export' },
        { yandex_status_export: 'Yandex export' },
        // { delete: '' }
    ];

    const columns = useMemo(() => {
        return catalog.map((item, i) => {
            return {
                key: Object.keys(item)[0],
                name: item[Object.keys(item)[0]],
                fieldName: Object.keys(item)[0],
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
            };
        });
    }, [segments]);

    const onCreateNewClick = () => {
        toggleModal({
            show: true,
            modalType: 'new_segment',
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: { customInputParamsValues, reportingObjectsByPlId, reportingObjectsById },
            },
        });
    };

    const addIcon = { iconName: 'Add' };

    // ------------ DISPATCH ------------

    const feelTree = (ownersExpand) => {
        dispatch(runAction('feelTree_AdCampaigns', { ownersExpand }));
    };

    const toggleExpandArrow = (id) => {
        dispatch(runAction('toggleExpandArrow_AdCampaigns', { id }));
    };

    const onSelectClick = (args) => {
        dispatch(runAction('onSelectClick_AdCampaigns', { args, paramsSet }));
    };

    const toggleModal = (args) => {
        console.log('>>>>>>>>>>>>>>>', args)
        dispatch(runAction('toggleModal', { args }));
    };

    const onShowMoreClick = () => {
        dispatch(runAction('onShowMoreClick_CustomControls', {}));
    };

    useEffect(() => {
        if (search.length < 2) {
            dispatch(runAction('getFoundSegments_CustomControls', { json: null }));
            return;
        }
        let timerId;
        clearTimeout(timerId);
        timerId = setTimeout(function () {
            getFoundSegments(token, dispatch, search);
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const onChangeSegmentSearchValue = (e, segmentSearchValue) => {
        // dispatch(runAction('onChangeSegmentSearchValue_CustomControls', { segmentSearchValue }));
        set_search(() => segmentSearchValue);
    };

    // ------------ DISPATCH ------------

    const filteredSegments = useMemo(() => {
        if (foundSegments) {
            return foundSegments;
        } else {
            return segments;
        }
    }, [segments, segmentSearchValue, foundSegments]);

    return segments ? (
        <MainContainer height={height - 75}>
            <TitleWrapper>
                <Title>
                    <h2>{t('Сегменты')}</h2>
                </Title>
                <StTextField
                    // label={t('Поиск по имени проекта')}
                    placeholder={t('Поиск по имени (минимум 2 символа)')}
                    value={search}
                    onChange={onChangeSegmentSearchValue}
                    // styles={{ fieldGroup: { width: 300 } }}
                />
                {showCreateNew ? (
                    <StDefaultButton iconProps={addIcon} onClick={onCreateNewClick}>
                        {t('Создать новый сегмент')}
                    </StDefaultButton>
                ) : null}
            </TitleWrapper>

            <CustomDetailsList
                items={filteredSegments}
                columns={columns}
                toggleModal={toggleModal}
                onSelectClick={onSelectClick}
                singleSelection={singleSelection}
                multiSelection={multiSelection}
                paramsSet={paramsSet}
                customInputParamsValues={customInputParamsValues}
                reportingObjectsByPlId={reportingObjectsByPlId}
                reportingObjectsById={reportingObjectsById}
            />
            {segmentsToShow <= segments.length && !foundSegments ? (
                <StLabel disabled={false} color={`${theme.palette.themeDark}`} onClick={onShowMoreClick}>
                    {`${t('Показать еще')} ${addSegmentsNumber}`}
                </StLabel>
            ) : null}
            {isShowSpinnerSegments ? <OverlaySpinner/> : null}
        </MainContainer>
    ) : null;
});

export default SegmentsControl;

const Title = styled.div`
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #99ccae;
    padding-bottom: 5px;
    margin: 0;
    h2 {
        box-sizing: border-box;
        font-family: ProximaNova;
        font-size: 17px;
        color: rgb(36, 49, 56);
        padding: 0;
        margin: 0;
    }
`;

const StTextField = styled(TextField)`
    flex-shrink: 0;
    height: 32px !important;
    width: 400px !important;
    div {
        height: 32px !important;
    }
    margin-left: 10px !important;
`;

const StIcon = styled(FontIcon)`
    font-size: 24px;
    color: red;
    cursor: pointer;
`;

const CurrentProject = styled.div`
    height: 40px !important;
    border: 1px solid rgb(138, 136, 134);
    border-radius: 2px;
    /* width: 100%; */
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const StLabel = styled(Label)`
    width: 100%;
    background-color: inherit;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    color: ${(p) => p.color};
    /* padding: 10px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    :focus {
        outline: none;
    }
`;

const MainContainer = styled.section`
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    box-sizing: border-box;
    height: ${p => p.height}px;
    .ms-DetailsRow-cell {
        display: flex !important;
        align-items: center !important;
    }
`;

const TitleWrapper = styled.div`
    box-sizing: border-box;
    /* padding: 10px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const TreeWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
`;

const Delete = styled.div`
    cursor: pointer;
`;

const StDefaultButton = styled(DefaultButton)`
    flex-shrink: 0;
    margin-left: 10px;
`;
