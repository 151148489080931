import GeometryVSDataManager from "../../../../tools/GeometryVSDataManager";

// var AppActions = require('../actions/actions.js');
// var AppStore = require('../stores/stores.js');

import colors from '../../../../tools/colors.js';
import { connect} from 'react-redux';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import img from '../../img/arrows.png';
import imgHeatMap from '../../img/heat_map.png'
import Rainbow from "../../../rainbow";

class Panel extends Component {
    static defaultProps = {

    };

    static propTypes = {};

    internalState = {
        isMounted: null,
        data: []
    }

    state = {
        activeStatus: false,
        routerItem: null,
        widthStaticPanel: null,
        value: null,
        name: null,
        svgNode: null,
        nameItemsRouter: {},
        loadStatus: false,
        idZone: null,
        svgGlobal: null,
        routersObj: null,
        roomsTransition: null,
        rooms: null,
        dataFloors: null,
        previewItem: null,
        itemRetailName: null,
        uuidPlanSet: null,
        zones:null
	};

    componentDidMount() {
        this.internalState.isMounted = true;
        // AppStore.addChangeListener(this._onChange);
	}

    componentWillUnmount() {
        this.internalState.isMounted = false;
        // AppStore.removeChangeListener(this._onChange);
	}

    createColor(color, id) {
        var id = [];
        var obj = {
            id: id,
            color: color
        };
        this.internalState.data.push(obj);
	}

    render() {
        let objAnyValues = {
            minValue:0,
            maxValue:100
        }
        if (this.props.metricks) {
            let PanelNode = null;
            // console.log('>>>>>>>>>>>>>>>.12', this.props.selectedReportObjects);
            PanelNode = this.props.listDataDataViewForMaps.map((item, index) => {

                // let title = item.data_aspects.object.associations[0].name;

                let title = <text fill='rgb(97, 97, 97)' x="70" y="10">
                    <tspan x="70" dy="1em">{item.data_aspects.object.associations[0].name}</tspan>
                </text>

                let indexTitleSpace = 0;
                let indexTitleSpaceTemp = 0;
                if (item.data_aspects.object.associations[0].name.length > 16) {
                    for(var k = 0;k < item.data_aspects.object.associations[0].name.length; k++) {
                        // console.log('>>>>>>>>>>>>>', GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i])[k])
                        if (item.data_aspects.object.associations[0].name[k] === ' ') {
                            indexTitleSpaceTemp = k;
                        }
                        if (k === 16) {
                            if (item.data_aspects.object.associations[0].name[k] === ' ') {
                                indexTitleSpace = k;
                            }
                            else {
                                indexTitleSpace = indexTitleSpaceTemp;
                            }
                        }
                    }
                    title = <text fill='rgb(97, 97, 97)' x="70" y="4">
                        <tspan x="70" dy="1em">{item.data_aspects.object.associations[0].name.substring(0, indexTitleSpace)}</tspan>
                        <tspan x="70" dy="1em">{item.data_aspects.object.associations[0].name.substring(indexTitleSpace + 1, item.data_aspects.object.associations[0].name.length)}</tspan>
                    </text>
                    // title = item.data_aspects.object.associations[0].name.substring(0, 10) + '\n' +
                    //     item.data_aspects.object.associations[0].name.substring(item.data_aspects.object.associations[0].name.length - 10, item.data_aspects.object.associations[0].name.length);
                }
                let persent2 = (
                    item.values[0] -
                    this.props.DValueMetricsByMaps.minValue
                ) / (
                    this.props.DValueMetricsByMaps.maxValue -
                    this.props.DValueMetricsByMaps.minValue
                );

                objAnyValues = {
                    minValue:this.props.DValueMetricsByMaps.minValue,
                    maxValue:this.props.DValueMetricsByMaps.maxValue
                }
    
                let persentForWidth = (
                    item.values[0] -
                    0
                ) / (
                    this.props.DValueMetricsByMaps.maxValue -
                    0
                );

                const styleText = {
                    mixBlendMode: 'color-burn'
                }
                var color = colors.getHeatColor(persent2);
                // console.log('>>>>>>>>>>>>>>>>>>>',(item.values[0]+'').length)
                return (
                    <div key={`${index}metrics`} className="boxBar">
                        <svg className='svgBoxBar'>
                            <g>
                                <rect y='0' x='0' height="38" width={persentForWidth * 100 + '%'} style={{fill: color}} />
                                {title}
                                {/*<rect x={5} y={12} width={((item.values[0]+'').length * 8) + 4} height="15" fill={"rgba(255,255,255,.5)"}/>*/}
                                <text
                                    // style={styleText}
                                    fill='rgb(97, 97, 97)'
                                    x="10" y="10"
                                >
                                    <tspan x="10" dy="1em">{parseInt(item.values[0] * 10) / 10}</tspan>
                                </text>
                            </g>
                        </svg>
                    </div>
                )
            })
            return (
                <div id='statisticPanel' className="statisticPanel" >
                    <div>
                        <div>

                            <div className='titlePanel'>
                                <h4>{this.props.selectedReportObjects.label}</h4>
                            </div>

                            <div className="boxRatingPanel">
                                <div style={{height: window.innerHeight - 50 - 47 - 63, overflow: 'auto', width: 204 + 'px'}}>
                                    {PanelNode}
                                </div>

                                {/*<img className='scale' src={imgHeatMap} />*/}
                            </div>

                            {/*<div style={{height: window.innerHeight, overflow: 'auto', width: 204 + 'px', float: 'left'}}>*/}
                            {/*    {PanelNode}*/}
                            {/*</div>*/}
                            {/*<img className='scale' src={imgHeatMap} />*/}
                        </div>

                    </div>
                    <div className="boxRainbow">
                        <Rainbow
                            maxValue={objAnyValues.maxValue}
                            minValue={objAnyValues.minValue}
                            bottom={7}
                            left={50}
                            right={50}
                            colorFoo={colors.getHeatColor}
                        />
                    </div>
                </div>
            );
        }


        var PanelNode;
        let selectedObjectName = '';
        if (this.props.routerItem != null || this.props.statusSelectPolygons === true) {


            var barsNodes = [];

            let dataPersents = {
                min:0,
                max:100
            };



            if (this.props.uuidPlanSet !== null) {
                // let statusActiveTemp = false;
                // let persentHide = 0;
                // for (var i2223 in this.props.roomsTransition) {
                //     for (var j2222 = 0; j2222 < this.props.previewItem.length; j2222++) {
                //         if (+this.props.roomsTransition[i2223] === this.props.previewItem[j2222].idFloors) {
                //             statusActiveTemp = this.props.previewItem[j2222].statusActive
                //             if (this.props.previewItem[j2222].statusActive) {
                //                 persentHide = this.props.roomsTransitionGlobal[this.props.roomsTransition[i2223]].intersection_percent
                //             }
                //         }
                //     }
                //     // console.log('>>>>>>>>>>>>>>>>>>>>33333333', statusActiveTemp, dataPersents)
                //     if (dataPersents === null) {
                //         if (statusActiveTemp === false) {
                //             dataPersents = {
                //                 min:this.props.roomsTransitionGlobal[this.props.roomsTransition[i2223]].intersection_percent,
                //                 max:this.props.roomsTransitionGlobal[this.props.roomsTransition[i2223]].intersection_percent
                //             }
                //         }
                //
                //     }
                // }


                for (var i in this.props.roomsTransition) {


                    if (this.props.roomsTransitionGlobal[this.props.roomsTransition[i]] !== undefined) {
                        // console.log('>>>>>>>>>>>>>>>>>>>>222222', this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent,
                        //     GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]))
                        // if (persentHide !== this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent) {
                        //     if (dataPersents.min >  this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent) {
                        //         dataPersents.min = this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent;
                        //     }
                        //     if (dataPersents.max < this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent) {
                        //         dataPersents.max = this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent;
                        //     }
                        // }
                        // console.log('>>>>>>>>>>>>>>>>>>>>', this.props.roomsTransition[i])
                        var persentColor = this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent / 100;
                        var color = colors.getHeatColor(persentColor);

                        if (this.props.rooms[this.props.roomsTransition[i]] !== undefined) {

                            selectedObjectName = this.props.itemRetailName;
                            // console.log('_onChangerender', GeometryVSDataManager.reportNameWithPlace(this.props.rooms[this.props.roomsTransition[i]].markers[0]))
                            // console.log('>>>>>>>>>>>>>>>>>>>>>', this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1))
                            barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                <svg className='svgBoxBar'>
                                    <g>
                                        <rect y='0' x='0' height="38" width={this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                        <text fill='rgb(97, 97, 97)' x="80" y="18" >{' ' + GeometryVSDataManager.reportNameWithPlace(this.props.rooms[this.props.roomsTransition[i]].markers[0])}</text>
                                        {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                        <text fill='rgb(135, 135, 135)' x="25" y="18">{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                        <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                        <image xlinkHref={img} x="0" y="6" height="27px" width="27px" />
                                    </g>
                                </svg>
                            </div>);
                        }
                        else {

                            var statusActive = false;
                            for (var j = 0; j < this.props.previewItem.length; j++) {
                                // console.log('>>>>>>>>>>>>>>>>>>>>2', this.props.previewItem[j])
                                if (+this.props.roomsTransition[i] === this.props.previewItem[j].idFloors) {
                                    statusActive = this.props.previewItem[j].statusActive
                                }
                            }


                            if (GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]) !== undefined) {
                                // console.log('323232323233', this.state.zones[this.state.roomsTransition[i]].name)
                                // console.log('>>>>>>>>>>>.!!!!!', GeometryVSDataManager.reportIdWithZoneMarker(+this.props.roomsTransition[i]).length)
                                // let title = GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]);
                                selectedObjectName = this.props.zoneNameBySelected;
                                // if (GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length > 11) {
                                //     title = GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).substring(0, 5) + '...' +
                                //         GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).substring(GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length - 5, GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length);
                                // }
                                let title = <text fill='rgb(97, 97, 97)' x="80" y="10">
                                    <tspan x="80" dy="1em">{GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i])}</tspan>
                                </text>

                                let indexTitleSpace = 0;
                                let indexTitleSpaceTemp = 0;
                                if (GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length > 16) {

                                    for(var k = 0;k < GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length; k++) {
                                        // console.log('>>>>>>>>>>>>>', GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i])[k])
                                        if (GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i])[k] === ' ') {
                                            indexTitleSpaceTemp = k;
                                        }
                                        if (k === 16) {
                                            if (GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i])[k] === ' ') {
                                                indexTitleSpace = k;
                                            }
                                            else {
                                                indexTitleSpace = indexTitleSpaceTemp;
                                            }
                                        }
                                    }
                                    // console.log('>>>>>>>>>>>>>', GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]), indexTitleSpace)
                                    title = <text fill='rgb(97, 97, 97)' x="80" y="4">
                                        <tspan x="80" dy="1em">{GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).substring(0, indexTitleSpace)}</tspan>
                                        <tspan x="80" dy="1em">{GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).substring(indexTitleSpace + 1, GeometryVSDataManager.reportIdWithZoneName(+this.props.roomsTransition[i]).length)}</tspan>
                                    </text>
                                    // title = item.data_aspects.object.associations[0].name.substring(0, 10) + '\n' +
                                    //     item.data_aspects.object.associations[0].name.substring(item.data_aspects.object.associations[0].name.length - 10, item.data_aspects.object.associations[0].name.length);
                                }
                                if (statusActive === false) {
                                    // console.log('>>>>>>>>>>>>>>>>>>>>>', this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1))
                                    barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                        <svg className='svgBoxBar'>
                                            <g>
                                                <rect y='0' x='0' height="38" width={this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                                {title}
                                                {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                                <text fill='rgb(135, 135, 135)' x="25" y="18">{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                                <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                                <image xlinkHref={img} x="0" y="6" height="27px" width="27px" />
                                            </g>
                                        </svg>
                                    </div>);
                                }
                            }
                            else {
                                // if (statusActive === false) {
                                //     console.log('>>>>>>>>>>>>>>>>>>>>2', this.props.roomsTransition[i])
                                //     // console.log('>>>>>>>>>>>>>>>>>>>>>', this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1))
                                //     barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                //         <svg className='svgBoxBar'>
                                //             <g>
                                //                 <rect y='0' x='0' height="38" width={this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                //                 {/*<text fill='rgb(97, 97, 97)' x="80" y="18" >{`${this.props.roomsTransition[i]} ${this.props._t.t(`Этаж`)}`}</text>*/}
                                //                 <text fill='rgb(97, 97, 97)' x="80" y="10">
                                //                     <tspan x="80" dy="1em">{`${this.props.roomsTransition[i]} ${this.props._t.t(`Этаж`)}`}</tspan>
                                //                 </text>
                                //                 {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                //                 <text fill='rgb(135, 135, 135)' x="25" y="18">{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                //                 <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                //                 <image xlinkHref={img} x="0" y="6" height="27px" width="27px" />
                                //             </g>
                                //         </svg>
                                //     </div>);
                                // }
                                barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                    <svg className='svgBoxBar'>
                                        <g>
                                            <rect y='0' x='0' height="38" width={this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                            {/*<text fill='rgb(97, 97, 97)' x="80" y="18" >{`${this.props.roomsTransition[i]} ${this.props._t.t(`Этаж`)}`}</text>*/}
                                            <text fill='rgb(97, 97, 97)' x="80" y="10">
                                                <tspan x="80" dy="1em">{`${this.props.roomsTransition[i]} ${this.props._t.t(`Этаж`)}`}</tspan>
                                            </text>
                                            {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                            <text fill='rgb(135, 135, 135)' x="25" y="18">{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                            <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{this.props.roomsTransitionGlobal[this.props.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                            <image xlinkHref={img} x="0" y="6" height="27px" width="27px" />
                                        </g>
                                    </svg>
                                </div>);
                            }
                        }

                    }
                }
                if (dataPersents !== null) {
                    // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPersents)
                    objAnyValues = {
                        minValue:dataPersents.min,
                        maxValue:dataPersents.max
                    }
                }


            }

            // console.log('_onChangerender>>>>>>', document.getElementById('titlePanel'))
            PanelNode = <div style={{height: '100%'}}>

                <div className='titlePanel' id={'titlePanel'}>
                    <h4>{selectedObjectName}</h4>
                </div>


                <div className="boxRatingPanel">
                    <div style={{height: window.innerHeight - 50 - 47 - 18, overflow: 'auto', width: 204 + 'px'}}>
                        {barsNodes}
                    </div>

                    {/*<img className='scale' src={imgHeatMap} />*/}
                </div>
            </div>
        }
        else {
            PanelNode = <p style={{padding: '10px'}}>
                {this.props._t.t('Выделите зону для просмотра информации о пересечении с другими зонами.')}
            </p>
            return null;

        }
        if (this.props.uuidPlanSet !== null) {
            if (this.props.roomsTransition.length === 0) {
                PanelNode = <p style={{padding: '10px'}}>
                    {this.props._t.t('Выделите зону для просмотра информации о пересечении с другими зонами.')}
                </p>
                return null;
            }
        }
        if (this.props.routerItem != null || this.props.statusSelectPolygons === true) {
            return (
                <div id='statisticPanel' className="statisticPanel" >
                    <div style={{height: '100%'}}>
                        {PanelNode}
                    </div>
                    <div className="boxRainbow">
                        <Rainbow
                            maxValue={objAnyValues.maxValue}
                            minValue={objAnyValues.minValue}
                            bottom={7}
                            left={50}
                            right={50}
                            colorFoo={colors.getHeatColor}
                        />
                    </div>

                </div>
            );
        }
        else {
            return (
                null
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    var roomsTransition = []
    if (state.MapsApp.uuidPlanSet !== null) {
        var value = JSON.parse(JSON.stringify(state.MapsApp.roomsTransition));
        // важно

        var maxPersent = null;
        for (var key in value) {
            if (maxPersent == null) {
                maxPersent = value[key].intersection_percent;
            }
            else if (maxPersent != null) {
                if (maxPersent < value[key].intersection_percent) {
                    maxPersent = value[key].intersection_percent;
                }
            }
        }
        for (var keyy in value) {
            var persent = (value[keyy].intersection_percent / maxPersent) * 100;
            value[keyy].intersection_percent = persent;
        }
        var keys = Object.keys(value);
        // console.log('12345', keys.length);
        var sortedKeys = [];
        if (keys.length > 0) {
            sortedKeys = keys.sort(function(a, b){
                if (value[a].intersection_percent < value[b].intersection_percent) {
                    return 1;
                }
                if (value[a].intersection_percent > value[b].intersection_percent) {
                    return -1;
                }
                return 0;
            });
        }

        roomsTransition = sortedKeys
    }
    return {
        selectedAreaIndex:state.MapsApp.selectedAreaIndex,
        activeStatus: false,
        routerItem: null,
        widthStaticPanel: null,
        value: null,
        name: null,
        svgNode: null,
        nameItemsRouter: {},
        loadStatus: false,
        idZone: null,
        svgGlobal: null,
        roomsTransition: roomsTransition,
        roomsTransitionGlobal:state.MapsApp.roomsTransition,
        rooms: state.MapsApp.rooms,
        dataFloors: state.MapsApp.dataFloors,
        previewItem: state.MapsApp. mapPreviewItem,
        itemRetailName: state.MapsApp.itemRetailName,
        uuidPlanSet: state.MapsApp.uuidPlanSet,
        zones:state.MapsApp.zones,
        routersObj: state.MapsApp.routersObj,
        // statusSelectPolygons:state.MapsApp.statusSelectPolygons
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
};

const ConnectPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(Panel);

export default ConnectPanel;

