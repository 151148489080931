import React from 'react';
import styled from 'styled-components/macro';

export default React.memo(({ id, expand, ...props }) => {
    const arrowClassName = !expand ? 'fa fa-angle-down' : 'fa fa-angle-up';

    const toggleExpandArrow = id => e => {
        props.toggleExpandArrow(id);
    };
    return <Arrow className={arrowClassName} onClick={toggleExpandArrow(id)} />;
});

const Arrow = styled.i`
    cursor: pointer;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #777; */
    &:hover {
        border: 1px solid #777;
    }
`;
