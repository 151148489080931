import React, { Component, useState, useEffect } from 'react';
import '../style/topPanelStyle.scss';
import TopMenu from './top_menu';
import styled from 'styled-components/macro';
// import Logout from './logout';
// import LanguageSelect from './languageSelect';
import {connect, useSelector} from 'react-redux';
import { runAction } from '../../../actions';
import logoShopster from '../img/Shopster.svg';
import ShowTablesButton from './show_tables_button';
import TopCommandBar from './top_command_bar';
import hostForLocation from "../../../hostnames/hostname";
import Tools from "../../../tools/tools";
import {useTranslation} from "react-i18next";
import _t from "i18next";
// import TopCalendar from './top_calendar';

const TopPanel = ({
    status,
    onTopMenuButtonClick,
    _t,
    currentSection,
    sectionsByKeyName,
    userName,
    input_parameter_list,
    reportCatalog,
    history,
    lang,
    statusLeftPanel,
    onLanguageChange,
    routing,
    whiteLabelSettings,
    cookie,
    sectionHistory,
    cabinetMode,
    showFavoritesOnly,
    onShowTablesButtonClick,
    isCompleteTablesShown,
    dataSections,
    selectDate,
    options,
    backend,
    // onCLickButton,
    // onClickButtonCompare,
    main_date_range,
    comparison_date_range,
    pl_id,
    pl_ids,
    obj_id,
    obj_ids,
    onShowModalPDF,
    currentSubSection,
    groupIDsBySubsectionKeyName,
    dataViewsByGroupID,
    locationsСatalog,
                      projectLocationsById,
                      adCampaignsById,
                      outdoorAdSpacesById,
                      onLoadModalPDF,
    reportParams,
    ...props
}) => {
    const logo = whiteLabelSettings ? whiteLabelSettings.small_logo_url : logoShopster;
    const logoBgColor = whiteLabelSettings ? whiteLabelSettings.small_logo_background_color : 'rgba(70, 184, 117, 1)';
    const bgColor = whiteLabelSettings ? whiteLabelSettings.header_color : 'rgba(70, 184, 117, 1)';
    const { token } = useSelector((state) => state.LoginApp);
    const { allowedTypes, statusRequestNewReport } = useSelector((state) => state.Pdf);
    const { t, i18n } = useTranslation();

    const customParamsSet = Tools.useGetParamSet().custom;


    useEffect(() => {
        if (statusRequestNewReport === false) {
            // onLoadModalPDF();
            onLoadModalPDF(token);
        }
    }, [ statusRequestNewReport]);

    const getGroupsToShow = () => {
        try {
            return groupIDsBySubsectionKeyName[currentSubSection].map((item) => {
                return dataViewsByGroupID[item];
            });
        } catch (error) {
            console.log(error);
        }
    };



    const getAllowedTables = (arr) => {
        try {
            return arr
                .filter((arrItem) => {
                    return allowedTypes[arrItem.data_report.data_type.key_name] && props.reportData[arrItem.data_view_id].response;
                })
                .map((item) => {
                    const data = props.reportData[item.data_view_id].response;
                    return {
                        index: item.data_view_id,
                        data_report: item.data_report,
                        data,
                    };
                });
        } catch (error) {}
    };

    const getData = () => {

        try {
            const groupsToShow = getGroupsToShow();
            const result = groupsToShow.map((item) => {
                return {
                    groupName: item[0].group.translations[lang].name,
                    allowedTables: getAllowedTables(item),
                };
            });
            return result;
        } catch (error) {}
    };


    const _onShowModalPDF = () => {
        try {
            const indexes = [];
            const data = getData();

            // console.log('>>>>>>>>>>>', data)

            data.forEach((item) => item.allowedTables.forEach((item) => indexes.push(item.index)));
            const reportParams = indexes.reduce((acc, item) => {
                return { ...acc, ...props.reportData[item].obj };
            }, {});
            const reportParameters = {
                reportParams,
                paramsList: props.inputParametersList,
                locationsСatalog,
                projectLocationsById,
                customParamsSet,
                adCampaignsById,
                outdoorAdSpacesById,
            };
            onShowModalPDF(
                token,
                data,
                currentSubSection,
                lang,
                reportParameters
            )
        } catch (error) {
            alert(t('Пожалуйста, дождитесь загрузки диаграмм!'))
        }

    }

    return (
        <MainContainer bgColor={bgColor}>
            <Logo logoBgColor={logoBgColor} logo={logo}>
                <a href="/" />
            </Logo>
            <TopMenu
                showFavoritesOnly={showFavoritesOnly}
                dataSections={dataSections}
                onTopMenuButtonClick={onTopMenuButtonClick}
                currentSection={status}
                currentSubsection={statusLeftPanel}
                input_parameter_list={input_parameter_list}
                reportCatalog={reportCatalog}
                cabinetMode={cabinetMode}
                sectionHistory={sectionHistory}
                pl_id={pl_id}
                pl_ids={pl_ids}
                obj_id={obj_id}
                obj_ids={obj_ids}
            />
            <Wrapper>
                {/* <TopCalendar
                    selectDate={selectDate}
                    _t={_t}
                    lang={lang}
                    sectionsByKeyName={sectionsByKeyName}
                    currentSection={currentSection}
                    statusLeftPanel={statusLeftPanel}
                    main_date_range={main_date_range}
                    comparison_date_range={comparison_date_range}
                /> */}
                <div className={'rightSide'}>
                    <ShowTablesButton onShowTablesButtonClick={_onShowModalPDF} statusPDF={true} isCompleteTablesShown={isCompleteTablesShown} />
                    <ShowTablesButton onShowTablesButtonClick={onShowTablesButtonClick} isCompleteTablesShown={isCompleteTablesShown} />

                    {/* <ShareButton onShareButtonClick={props.onShareButtonClick} /> */}
                    <TopCommandBar
                        lang={lang}
                        options={options}
                        backend={backend}
                        routing={routing}
                        onChange={onLanguageChange}
                        cookie={cookie}
                        userName={userName}
                        history={history}
                        toggleModal={props.toggleModal}
                    />
                </div>
            </Wrapper>
        </MainContainer>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        routing: state.routing,
        status: state.TopPanel.status,
        sectionsByKeyName: state.TopPanel.sectionsByKeyName,
        dataSections: state.TopPanel.dataSections,
        userName: state.LoginApp.userName,
        whiteLabelSettings: state.LoginApp.white_label_settings,
        selected2: state.CalendarApp.selected2,
        arrayLocationSelectMulti: state.PanelLocationApp.arrayLocationSelectMulti,
        arrayLocationSelectNotMulti: state.PanelLocationApp.arrayLocationSelectNotMulti,
        locations: state.PanelLocationApp.locations,
        input_parameter_list: state.ReportParametersManager.input_parameter_list,
        reportCatalog: state.ReportParametersManager.reportCatalog,
        lang: state.TopPanel.lang,
        showFavoritesOnly: state.TopPanel.showFavoritesOnly,
        statusLeftPanel: state.TopPanel.statusLeftPanel,
        currentSection: state.TopPanel.status,
        statusDropDownLogout: state.TopPanel.statusDropDownLogout,
        cabinetMode: state.TopPanel.cabinetMode,
        sectionHistory: state.TopPanel.sectionHistory,
        completeTables: state.XLSX.completeTables,
        isCompleteTablesShown: state.XLSX.isCompleteTablesShown,
        options: state.LoginApp.options,
        backend: state.LoginApp.backend,
        main_date_range: state.ReportParametersManager.customInputParamsValues.main_date_range,
        comparison_date_range: state.ReportParametersManager.customInputParamsValues.comparison_date_range,
        pl_id: state.ReportParametersManager.customInputParamsValues.pl_id,
        pl_ids: state.ReportParametersManager.customInputParamsValues.pl_ids,
        obj_id: state.ReportParametersManager.customInputParamsValues.obj_id,
        obj_ids: state.ReportParametersManager.customInputParamsValues.obj_ids,
        currentSubSection: state.TopPanel.statusLeftPanel,
        groupIDsBySubsectionKeyName: state.ReportParametersManager.groupIDsBySubsectionKeyName,
        dataViewsByGroupID: state.ReportParametersManager.dataViewsByGroupID,
        reportData: state.ReportParametersManager.reportData,
        inputParametersList: state.ReportParametersManager.input_parameter_list,
        locationsСatalog: state.PanelLocationApp.objDataByObjId,
        projectLocationsById: state.ReportParametersManager.Source.ctg.projectLocationsById,
        adCampaignsById: state.ReportParametersManager.Source.ctg.adCampaignsById,
        outdoorAdSpacesById: state.ReportParametersManager.Source.ctg.outdoorAdSpacesById,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModal: (args) => {
            dispatch(runAction('toggleModal', { args }));
        },
        onTopMenuButtonClick: (status, reqInput, section, subsection) => {
            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('onShowTablesButtonClick', { isShown: false }));
            dispatch(runAction('onTopMenuButtonClick', { status, reqInput, section, subsection }));
        },
        onShareButtonClick: (shareButtonPressed) => {
            dispatch(runAction('onShareButtonClick', { shareButtonPressed }));
        },
        onDropDownLogout: (e, status) => {
            e.stopPropagation();
            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('onDropDownLogout', { status: status }));
        },
        onLanguageChange: (value) => {
            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('onShowTablesButtonClick', { isShown: false }));
            dispatch(runAction('onLanguageChange', { value }));
        },
        onClickReloadWindow: (e) => {
            window.location.replace('/');
        },
        onShowTablesButtonClick: () => {
            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('onShowTablesButtonClick', {}));
        },
        onShowModalPDF: (token, data, currentSubSection, lang, reportParams) => {

            const headers = {
                'x-token': token,
            };
            getRequest(dispatch, headers);


            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('setDataObjects', {...reportParams, lang}));
            dispatch(runAction('getStatusShowModalPDF', {status: true}));
        },

        onLoadModalPDF: (token) => {

            const headers = {
                'x-token': token,
            };
            getRequest(dispatch, headers);

            setInterval(() => {
                getRequest(dispatch, headers);
            }, 300000)

            // dispatch(runAction('hideSettingsPanel'));
            // dispatch(runAction('setDataObjects', {...reportParams, lang}));
            // dispatch(runAction('getStatusShowModalPDF', {status: true}));
        },
        // selectDate: (mainRange, comparisonRange) => {
        //     dispatch(runAction('resetShifts_Diagrams', {}));
        //     dispatch(runAction('selectDate', { mainRange, comparisonRange }));
        // },
        // onCLickButton: (status) => {
        //     console.log('>>>>>>>>>>>>', status);
        //     e.stopPropagation();
        //     dispatch(runAction('onCLickButton', { status: status }));
        // },
        onClickButtonCompare: (e, status) => {
            e.stopPropagation();
            dispatch(runAction('onClickButtonCompare', { status: status }));
        },
    };
};

const ConnectTopPanel = connect(mapStateToProps, mapDispatchToProps)(TopPanel);

const getRequest = (dispatch, headers) => {
    dispatch(
        runAction('getPdfReports', {
            url: `${hostForLocation.getHostName().export}/reports/pdf-reports/`,
            headers
        })
    );
    // setInterval(() => {
    //     dispatch(
    //         runAction('getPdfReports', {
    //             url: `${hostForLocation.getHostName().export}/reports/pdf-reports/`,
    //             headers
    //         })
    //     );
    // }, 300000)
};

export default ConnectTopPanel;

const MainContainer = styled.section`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: ${(p) => p.bgColor};
    z-index: 300;
    position: absolute;
`;

const Wrapper = styled.div`
    flex-shrink: 0;
    /* flex-grow: 1; */
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const Logo = styled.div`
    width: 238px;
    flex-shrink: 0;
    height: 100%;
    background-size: contain !important;
    box-sizing: border-box;
    border-width: 3px;
    border-style: solid;
    background: ${(p) => `url(${p.logo}) center no-repeat`};
    background-color: ${(p) => p.logoBgColor};
    border-color: ${(p) => p.logoBgColor};
    a {
         {
            display: inline-block;
            height: 50px;
            width: 238px;
            position: fixed;
            cursor: pointer;
            z-index: 10000;
        }
    }
`;
