import styled from 'styled-components/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LegendContainer = styled.section`
  width: ${props => props.widthPercent + '%'};
  flex-shrink: 0;
  overflow: auto;
  padding: 10px;
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const ListItem = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    opacity: ${props => (props.isTransparent ? 0.2 : 1)};
    cursor: pointer;
`;

const Dot = styled.div`
    width: ${props => props.size + 'px'};
    height: ${props => props.size + 'px'};
    background-color: ${props => props.color};
    margin-right: 5px;
    flex-shrink: 0;
`;

const LabelText = styled.p`
    font-family: 'ProximaNova';
    font-size: 12px;
    margin: 0;
`;

const Legend = React.memo(props => {
    const { t } = useTranslation();
    const onClick = index => e => {
        e.preventDefault();
        props.onLegendClick(index);
    };
    const renderLegend = () => {
        const { colors, labels, dotSize, isComparisonShown } = props;
        return labels.map((item, i) => {
            return (
                <ListItem
                    onClick={onClick(i)}
                    key={`ListItem-${i}`}
                    isTransparent={!isComparisonShown && item === t('Период сравнения')}
                >
                    <Dot size={dotSize} color={colors[i]} />
                    <LabelText>{item}</LabelText>
                </ListItem>
            );
        });
    };
    return (
        <LegendContainer widthPercent={props.widthPercent}>
            <List>{renderLegend()}</List>
        </LegendContainer>
    );
});

export default Legend;
