import React, {useEffect} from 'react';
import styled from "styled-components/macro";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import {runAction} from "../../../actions";
import hostForLocation from "../../../hostnames/hostname";
import moment from 'moment';
// import {useSelector} from "react-redux";
import tools from "../../Diagrams/components/custom_controls/segments_control/tools/tools";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {useTranslation} from "react-i18next";
import ReportTitle from "../../report_title";
import { IconButton } from '@fluentui/react/lib/Button';
import {DateTime} from "luxon";
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';


const DetailInfoBox = (props) => {

    const {pdfReports, hostExport, status, statusLeftPanel, sectionsByKeyName, onClickButtonDeletePdfReport} = props;
    const { lang } = useSelector((state) => state.TopPanel);
    const [width, height] = tools.useWindowSize();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.LoginApp);
    const { t, i18n } = useTranslation();

    moment.locale(lang);

    // console.log('>>>>>>>>>>>>>>>', (window.innerWidth - (328 + 816)))

    const columns = [
        {
            key: 'column1',
            name: t('Имя'),
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            calculatedWidth: (window.innerWidth - (328 + 816)),
            // targetWidthProportion:3,
            // onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <TooltipHost
                    content={item.name}
                >
                    <span >
                    {item.name}
                    </span>
                </TooltipHost>
            },
            data: 'string',
            // isPadded: true,
        },

        {
            key: 'column4',
            name: t('Создан'),
            fieldName: 'created',
            // minWidth: 100,
            // maxWidth: 150,
            isRowHeader: true,
            // isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            isResizable: true,
            calculatedWidth:150,
            // isCollapsible: true,
            data: 'string',
            // onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <span>{moment(item.created).format('DD MMM YYYY, LT')}</span>;
            },
            isPadded: true,
        },
        {
            key: 'column5',
            name: t('Статус готовности'),
            fieldName: 'progress_status',
            // minWidth: 80,
            // maxWidth: 130,
            isResizable: true,
            // isCollapsible: true,
            isRowHeader: true,
            // targetWidthProportion:1,
            // isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            data: 'number',
            // onColumnClick: this._onColumnClick,
            onRender: (item) => {
                if (item.progress_status === 'initial' || item.progress_status === 'in-progress') {
                    return <SCYellowStatus>{item.progress_status === 'in-progress' ? 'in progress' : item.progress_status}</SCYellowStatus>;
                }
                else if (item.progress_status === 'formed') {
                    return <SCGreenStatus>{item.progress_status}</SCGreenStatus>;
                }
                return <SCRedStatus>{item.progress_status}</SCRedStatus>;
            },
        },
        {
            key: 'column3',
            name: t('Загрузить'),
            fieldName: 'download_link',
            // minWidth: 50,
            // maxWidth: 70,
            // isResizable: true,
            isRowHeader: true,
            isResizable: true,
            // targetWidthProportion:1,
            isSorted: true,
            isSortedDescending: false,
            // onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item) => {
                if (item.progress_status === 'formed') {
                    return <SCLinkLoad onClick={onClickLink(item.id, item.name)} >link</SCLinkLoad>;
                }
                return <div  >--</div>;
            },
            isPadded: true,
        },
        {
            key: 'column7',
            name: t('Раздел'),
            fieldName: 'section',
            // minWidth: 50,
            // maxWidth: 80,
            // isResizable: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            // targetWidthProportion:1,
            isSortedDescending: false,
            // onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item) => {


                if (item.section) {
                    if (sectionsByKeyName[item.section]) {
                        return <TooltipHost
                            content={sectionsByKeyName[item.section].translations[lang].name}
                        >
                            <span >{sectionsByKeyName[item.section].translations[lang].name}</span>
                        </TooltipHost>;
                    }
                    else {
                        return <div  >--</div>;
                    }

                }
                return <div  >--</div>;
            },
            isPadded: true,
        },
        {
            key: 'column8',
            name: t('Подраздел'),
            fieldName: 'subsection',
            // minWidth: 100,
            // maxWidth: 100,
            // isResizable: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            // targetWidthProportion:1,
            isSortedDescending: false,
            // onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item) => {
                if (item.subsection) {
                    if (item.subsection.translations) {
                        if (!item.subsection.translations[lang].name) {
                            return <div>--</div>;
                        }
                        return <TooltipHost
                            content={item.subsection.translations[lang].name}
                        >
                            <span>{item.subsection.translations[lang].name}</span>
                        </TooltipHost>;
                    }
                    return <div>--</div>;

                }
                return <div>--</div>;
            },
            isPadded: true,
        },
        {
            key: 'column9',
            name: t('Удалить'),
            fieldName: 'delete',
            minWidth: 50,
            maxWidth: 50,
            // isResizable: true,
            isRowHeader: true,
            isResizable: true,
            // targetWidthProportion:1,
            isSorted: true,
            isSortedDescending: false,
            // onColumnClick: this._onColumnClick,
            data: 'string',
            onRender: (item) => {
                // console.log('>>>>>>>>>>>>>>>>>>>>', item)
                const deleteIcon = {
                    iconName: 'Delete',
                    styles: {
                        root: {
                            color: 'red'
                        }
                    }
                };
                const onClickDeleteButton = () => {
                    onClickButtonDeletePdfReport(item.id)
                }
                return <div>
                    <IconButton
                        iconProps={deleteIcon}
                        title="Emoji"
                        ariaLabel="Emoji"
                        onClick={onClickDeleteButton}
                        // disabled={disabled}
                        // checked={checked}
                    />
                </div>;
            },
            isPadded: true,
        },
       
    ];

    const onClickLink = (id, name) => (e) => {
        // console.log('>>>>>>>>>>>>>>>>>>>', id)
        const headers = {
            'x-token': token,
        };
        dispatch(
            runAction('getPdfReportsFileName', {
                name
            })
        );
        dispatch(
            runAction('getPdfReportsFile', {
                url: `${hostForLocation.getHostName().export}/reports/pdf-reports/${id}/download/`,
                headers
            })
        );
    }

    const subsectionName = sectionsByKeyName[status].subsections.filter((item) => item.key_name === statusLeftPanel)[0]
        .translations[lang].name;
    return (
        <SCBoxReport>
            <div>
                {/*<span>{t('PDF отчеты')}</span>*/}
                <ReportTitle
                    title={`${t('PDF отчеты')}, ${sectionsByKeyName[status].translations[lang].name}, ${subsectionName}`}
                />

                <SCBoxListReport>
                    <DetailsList
                        items={pdfReports}
                        // styles={{
                        //     root: {
                        //         height: `${height - 107}px`
                        //     }
                        // }}
                        // compact={isCompactMode}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        // getKey={this._getKey}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        isHeaderVisible={true}
                        // onItemInvoked={this._onItemInvoked}
                    />
                </SCBoxListReport>

            </div>
        </SCBoxReport>
    );
};



export default DetailInfoBox;

const SCInfoTitle = styled.div`
    //padding-left: 10px;
    height: 50px;
    display: grid;
    align-items: center;
`;

const SCBoxReport = styled.div`
  height: 100%;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  display: grid;
  padding: 4px 10px 10px;
  font-family: ProximaNova;
`;

const SCBoxListReport = styled.div`
      width: 100%;
      height: calc(100% - 17px);
      overflow: auto;
`;

const SCGreenStatus = styled.span`
    //padding-left: 10px;
   color: #46b875;
`;

const SCYellowStatus = styled.span`
    //padding-left: 10px;
   color: rgb(255,203,107);
`;

const SCRedStatus = styled.span`
    //padding-left: 10px;
   color: #d50102;
`;

const SCLinkLoad = styled.span`
  //padding-left: 10px;
  color: #01c3d5;
  cursor: pointer;
`;

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});