import _t from 'i18next';
import xlsx from 'xlsx';
import serializeData from '../components/xlsx/tools/serialize_data';
import mergeTables from '../components/xlsx/tools/merge_tables';
import download from "../tools/download";
import moment from "moment";
import {createReportParams, createReportParamsScheduler} from "../components/pdf/tools/createReportParamsForPdf";

const setStatusShowModalPDF = (state, action) => {
    // const { isShowModalPdf } = state;

    return {
        ...state,
        isShowModalPdf: action.status,
        statusSpinner: true,
    };
};

const setSchedulers = (state, action) => {
    // console.log('>>>>>>>>>>>>>>1')
    return {
        ...state,
        schedulers: action.json,
        statusSpinner: false,
    };
};

const setPdfReports = (state, action) => {
    try {
        action.json.sort((a, b) => {
            if (+moment(a.created) > +moment(b.created)) {
                return -1;
            }
            if (+moment(a.created) < +moment(b.created)) {
                return 1;
            }
            // a должно быть равным b
            return 0;
        });

        // console.log('>>>>>>>>>>>>>>2' )

        return {
            ...state,
            pdfReports: action.json,
        };
    } catch (error) {
        return state;
    }
};

const setDataObjects = (state, action) => {
    const {
        lang,
        reportParams,
        locationsСatalog,
        paramsList,
        projectLocationsById,
        customParamsSet,
        adCampaignsById,
        outdoorAdSpacesById,
    } = action;

    const additionalParams = {
        adCampaignsById,
        customParamsSet,
        projectLocationsById,
        outdoorAdSpacesById,
    };

    const mapParams = (_params) => {
        const _completedParams = _params.map((item) => {
            const maxLength = [..._params].sort((a, b) => b.length - a.length)[0].length;
            const emptyElementToAdd = maxLength - item.length;
            for (let i = 0; i < emptyElementToAdd; i++) {
                item.push(' ');
            }
            return item;
        });


    }

    const params = createReportParams(_t.t('Параметры отчета'), reportParams, paramsList, lang, locationsСatalog, additionalParams);
    const completedParams = params.map((item) => {
        const maxLength = [...params].sort((a, b) => b.length - a.length)[0].length;
        const emptyElementToAdd = maxLength - item.length;
        for (let i = 0; i < emptyElementToAdd; i++) {
            item.push(' ');
        }
        return item;
    });
    const paramsScheduler = createReportParamsScheduler(_t.t('Параметры отчета'), reportParams, paramsList, lang, locationsСatalog, additionalParams);
    const completedParamsScheduler = paramsScheduler.map((item) => {
        const maxLength = [...paramsScheduler].sort((a, b) => b.length - a.length)[0].length;
        const emptyElementToAdd = maxLength - item.length;
        for (let i = 0; i < emptyElementToAdd; i++) {
            item.push(' ');
        }
        return item;
    });
    return {
        ...state,
        reportParams: [...completedParams] ,
        reportParamsScheduler: [...completedParamsScheduler]
    };
};

const getRequestNewPdfReportStatus = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>11',action.json)
    return {
        ...state,
        statusRequestNewReport: false,
    };
};

const getRequestNewPdfReport = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>11',action.json)
    return {
        ...state,
        statusRequestNewReport: true,
    };
};

const hideSpinner = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>11',action.json)
    return {
        ...state,
        statusSpinner: false,
    };
};

const setPdfFile = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>',  action.json, state.fileName)
    download(action.json, state.fileName + '.pdf');
    return {
        ...state,
        // statusSpinner: false
    };
};

const getPdfReportsFileName = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>',  action.name)
    // download(action.json)
    return {
        ...state,
        fileName: action.name,
    };
};

const setStatusShowSpinnerPdfReports = (state, action) => {
    // console.log('>>>>>>>>>>>>>>>>>>',  action.name)
    // download(action.json)
    return {
        ...state,
        statusSpinner: action.status
    };
};

const stateInit = {
    isShowModalPdf: false,
    schedulers: [],
    pdfReports: [],
    allowedTypes: {
        external_intersection_arr: true,
        comparison_score: true,
        score: true,
        table: true,
        distribution_over_days_of_week: true,
        distribution_over_hours_of_day: true,
        distribution: true,
        distribution_one_line: true,
        distribution_over_objects: true,
        dynamics: true,
        funnel: true,
        funnel_pair: true,
        funnel_set: true,
    },
    mode: 1,
    shownIndexes: {},
    reportParams: [],
    reportParamsScheduler: [],
    statusRequestNewReport: false,
    statusSpinner: false,
    fileName: '',
    // shownIndexes: makeInitialShownIndexes(1000),
};

const Pdf = (state = stateInit, action) => {
    switch (action.type) {
        case 'getStatusShowModalPDF':
            return setStatusShowModalPDF(state, action);
        case 'setSchedulers':
            return setSchedulers(state, action);
        case 'setPdfReports':
            return setPdfReports(state, action);
        case 'setDataObjects':
            return setDataObjects(state, action);
        case 'getRequestNewPdfReportStatus':
            return getRequestNewPdfReportStatus(state, action);
        case 'getRequestNewPdfReport':
            return getRequestNewPdfReport(state, action);
        case 'hideSpinner':
            return hideSpinner(state, action);
        case 'setPdfFile':
            return setPdfFile(state, action);
        case 'getPdfReportsFileName':
            return getPdfReportsFileName(state, action);
        case 'setStatusShowSpinnerPdfReports':
            return setStatusShowSpinnerPdfReports(state, action);

        default:
            return state;
    }
};
export default Pdf;
