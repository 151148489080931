import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import AccordionSection from './components/accordion_section';

const Accordion = React.memo(({ outerSections, children, currentSubSection, allowMultipleOpen = false, ...props }) => {
    const [openSections, set_openSections] = useState({});

    useEffect(() => {
        if (Object.keys(outerSections).length !== 0) {
            set_openSections({ ...outerSections });
        }
    }, [outerSections]);

    useEffect(() => {
        // children.forEach((child) => {
        //     try {
        //         const openSections = {};
        //         if (child.props.isOpen) {
        //             openSections[child.props.label] = true;
        //         }
        //         set_openSections(openSections);
        //     } catch (error) {}
        // });

        // console.log('+++', openSections);
        if ((openSections && Object.keys(openSections).length !== 0 && children.length > 1) || children.length === 0) return;

        openSections[children[0].props.label] = true;
        set_openSections(openSections);
    }, [currentSubSection, children]);

    const onSectionClick = (label) => {
        const isOpen = !!openSections[label];
        if (allowMultipleOpen) {
            set_openSections({ ...openSections, [label]: !isOpen });
        } else {
            set_openSections({ [label]: !isOpen });
        }
    };

    let showArrows = children.length > 1;
    if (currentSubSection === 'pre_campaign') {
        showArrows = false
    }
    return (
        <AccordionWrapper>
            {children.map((child, i) => (
                <AccordionSection
                    locationsBgColor={props.locationsBgColor}
                    title_сomponent={child.props.title_сomponent}
                    key={`${child.props.label}-${i}`}
                    isOpen={!!openSections[child.props.label]}
                    label={child.props.label}
                    show={child.props.show}
                    onSectionClick={onSectionClick}
                    showArrows={showArrows}
                    disabled={child.props.disabled}
                >
                    {child}
                </AccordionSection>
            ))}
        </AccordionWrapper>
    );
});

export default Accordion;

const AccordionWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
