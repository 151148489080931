import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components/macro';

export default React.memo(() => {
    return (
        <Overlay>
            <SpinnerContainer>
                <ClipLoader color={'#46b875'} loading={true} size={30} />
            </SpinnerContainer>
        </Overlay>
    );
});

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100vh;
`;
