import React, { useState, useMemo } from 'react';
import SelectInputParameter from './select_input_parameter';
import '../style/box_input_parameters.scss';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components/macro';
import Tools from '../../../tools/tools';

const BoxInputParameters = React.memo(({ disabled, fullScreenId = null, ...props }) => {
    const { t } = useTranslation();
    const [isParamsShown, set_isParamsShown] = useState(false);
    const getGlobalParamsSet = Tools.useGetParamSet(null, null, fullScreenId).global;

    const renderGlobalParams = () => {
        try {
            const { inputParameters, lang, onInputParameterChange, globalInputParamsValues } = props;

            return (
                <Ul>
                    {getGlobalParamsSet.map((item, i) => {
                        return (
                            <Li key={`${lang}-${i}`}>
                                <SelectInputParameter
                                    parameter={inputParameters[item]}
                                    lang={lang}
                                    onInputParameterChange={onInputParameterChange}
                                    globalInputParamsValues={globalInputParamsValues}
                                />
                            </Li>
                        );
                    })}
                </Ul>
            );
        } catch (error) {
            console.log('YYYYYYYYYYY');
            return null;
        }
    };

    const onClickDropDown = (e) => {
        e.stopPropagation();
        set_isParamsShown(!isParamsShown);
    };

    try {
        const getGlobalParamsSetLength = getGlobalParamsSet.length;
        return getGlobalParamsSetLength > 0 ? renderGlobalParams() : null;
    } catch (error) {
        return null;
    }
});

export default BoxInputParameters;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1px;
    display: flex;
    /* flex-wrap: wrap; */
`;

const Li = styled.li`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
`;
