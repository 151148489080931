import React, { Component } from 'react';
import Diagrams from '../../index';

import styled from 'styled-components/macro';

const Container = styled.div`
    /* background-color: ${props => (props.cabinetMode === '-pdf' ? '#fff' : props.bgColor)}; */
    box-sizing: border-box;
    padding: 0;
    overflow: auto;
    height: 100%;
`;

const PdfMainContent = React.memo(({ ...props }) => {
    return (
        <Container>
            <Diagrams />
        </Container>
    );
});

export default PdfMainContent;
