import _t from 'i18next';
import moment from 'moment';

export const createReportParams = (reportName, params, inputParameterList, lang, locationsСatalog, additionalParams, isForInfo = false) => {
    const { customParamsSet, projectLocationsById, outdoorAdSpacesById, adCampaignsById } = additionalParams;

    try {
        const getInputParamData = (list, paramName) => {
            return list.filter((item) => item.key_name === paramName)[0];
        };

        const getChoice = (list, value) => {
            return list.filter((item) => item.value === value)[0];
        };

        const reportParams = [];
        const selectedLocations = [];
        !isForInfo && reportParams.push([_t.t('Дата составления отчета'), moment(new Date()).format('YYYY-MM-DD')]);

        // console.log('customParamsSet______', customParamsSet);
        // console.log('params______', params);
        // console.log('adCampaignsById______', adCampaignsById);

        customParamsSet
            .filter((item) => Object.keys(params).includes(item))
            .forEach((item) => {
                switch (item) {
                    case 'main_date_range':
                        reportParams.push([_t.t('Отчетный период'), `${params.main_date_range[0]} — ${params.main_date_range[1]}`]);
                        break;
                    case 'comparison_date_range':
                        try {
                            reportParams.push([
                                _t.t('Период сравнения'),
                                `${params.comparison_date_range[0]} — ${params.comparison_date_range[1]}`,
                            ]);
                        } catch (error) {}

                        break;
                    case 'access_token':
                        break;
                    case 'obj_id':
                        if (!isForInfo) {
                            const objId = params.obj_id;
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранная локация'), _t.t('Подлокация')]);
                            selectedLocations.push([
                                `${locationsСatalog[objId].pl_name} (pl_id: ${locationsСatalog[objId].pl_id})`,
                                locationsСatalog[objId].obj_type === 'main'
                                    ? `${_t.t('Весь объект')} (obj_id: ${objId})`
                                    : `${locationsСatalog[objId].obj_name} (obj_id: ${objId})`,
                            ]);
                        }
                        break;
                    case 'obj_ids':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные локации')]);
                            selectedLocations.push([_t.t('Локация'), _t.t('Подлокация')]);
                            params.obj_ids.forEach((objId) => {
                                selectedLocations.push([
                                    `${locationsСatalog[objId].pl_name} (pl_id: ${locationsСatalog[objId].pl_id})`,
                                    locationsСatalog[objId].obj_type === 'main'
                                        ? `${_t.t('Весь объект')} (obj_id: ${objId})`
                                        : `${locationsСatalog[objId].obj_name} (obj_id: ${objId})`,
                                ]);
                            });
                        }
                        break;
                    case 'ad_campaign_id':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранная рекламная кампания'), adCampaignsById[params.ad_campaign_id].name]);
                        }
                        break;
                    case 'outdoor_ad_space_ids':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные рекламные поверхности')]);
                            params.outdoor_ad_space_ids.forEach((id) => {
                                selectedLocations.push([
                                    `${outdoorAdSpacesById[id].name} (outdoor_ad_space_id: ${id})`,
                                    `${outdoorAdSpacesById[id].project_location_name}`,
                                ]);
                            });
                        }
                        break;
                    case 'pl_ids':
                        if (!isForInfo && !customParamsSet.includes('obj_ids')) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные локации')]);
                            params.pl_ids.forEach((id) => {
                                selectedLocations.push([`${projectLocationsById[id].name} (pl_id: ${id})`]);
                            });
                        }

                        break;

                    default:
                        break;
                }
            });

        Object.keys(params).forEach((item) => {
            switch (item) {
                case 'main_date_range':
                case 'comparison_date_range':
                case 'access_token':
                case 'obj_ids':
                case 'pl_ids':
                    break;

                default:
                    try {
                        const currentInputParam = getInputParamData(inputParameterList, item);
                        if (currentInputParam.details.input_type !== 'categorical') {
                            break;
                        }
                        const currentInputParamValue = () => {
                            if (currentInputParam.details.choices) {
                                const choice = getChoice(currentInputParam.details.choices, params[item]);
                                if (choice.name.en) {
                                    return lang === 'ru' ? choice.name.default : choice.name.en;
                                } else {
                                    return choice.name.default;
                                }
                            } else {
                                return params[item];
                            }
                        };
                        reportParams.push([currentInputParam[`name_${lang}`], currentInputParamValue()]);
                    } catch (error) {
                        console.warn(error);
                    }
                    break;
            }
        });
        reportParams.push(...selectedLocations);
        return reportParams;
    } catch (error) {
        console.warn(error);

        return [];
    }
};

export const createReportParamsScheduler = (reportName, params, inputParameterList, lang, locationsСatalog, additionalParams, isForInfo = false) => {
    const { customParamsSet, projectLocationsById, outdoorAdSpacesById, adCampaignsById } = additionalParams;

    try {
        const getInputParamData = (list, paramName) => {
            return list.filter((item) => item.key_name === paramName)[0];
        };

        const getChoice = (list, value) => {
            return list.filter((item) => item.value === value)[0];
        };

        const reportParams = [];
        const selectedLocations = [];
        !isForInfo && reportParams.push([_t.t('Дата составления отчета'), moment(new Date()).format('YYYY-MM-DD')]);

        // console.log('customParamsSet______', customParamsSet);
        // console.log('params______', params);
        // console.log('adCampaignsById______', adCampaignsById);

        customParamsSet
            .filter((item) => Object.keys(params).includes(item))
            .forEach((item) => {
                switch (item) {
                    // case 'main_date_range':
                    //     reportParams.push([_t.t('Отчетный период'), `${params.main_date_range[0]} — ${params.main_date_range[1]}`]);
                    //     break;
                    // case 'comparison_date_range':
                    //     try {
                    //         reportParams.push([
                    //             _t.t('Период сравнения'),
                    //             `${params.comparison_date_range[0]} — ${params.comparison_date_range[1]}`,
                    //         ]);
                    //     } catch (error) {}
                    //
                    //     break;
                    case 'access_token':
                        break;
                    case 'obj_id':
                        if (!isForInfo) {
                            const objId = params.obj_id;
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранная локация'), _t.t('Подлокация')]);
                            selectedLocations.push([
                                `${locationsСatalog[objId].pl_name} (pl_id: ${locationsСatalog[objId].pl_id})`,
                                locationsСatalog[objId].obj_type === 'main'
                                    ? `${_t.t('Весь объект')} (obj_id: ${objId})`
                                    : `${locationsСatalog[objId].obj_name} (obj_id: ${objId})`,
                            ]);
                        }
                        break;
                    case 'obj_ids':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные локации')]);
                            selectedLocations.push([_t.t('Локация'), _t.t('Подлокация')]);
                            params.obj_ids.forEach((objId) => {
                                selectedLocations.push([
                                    `${locationsСatalog[objId].pl_name} (pl_id: ${locationsСatalog[objId].pl_id})`,
                                    locationsСatalog[objId].obj_type === 'main'
                                        ? `${_t.t('Весь объект')} (obj_id: ${objId})`
                                        : `${locationsСatalog[objId].obj_name} (obj_id: ${objId})`,
                                ]);
                            });
                        }
                        break;
                    case 'ad_campaign_id':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранная рекламная кампания'), adCampaignsById[params.ad_campaign_id].name]);
                        }
                        break;
                    case 'outdoor_ad_space_ids':
                        if (!isForInfo) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные рекламные поверхности')]);
                            params.outdoor_ad_space_ids.forEach((id) => {
                                selectedLocations.push([
                                    `${outdoorAdSpacesById[id].name} (outdoor_ad_space_id: ${id})`,
                                    `${outdoorAdSpacesById[id].project_location_name}`,
                                ]);
                            });
                        }
                        break;
                    case 'pl_ids':
                        if (!isForInfo && !customParamsSet.includes('obj_ids')) {
                            selectedLocations.push([]);
                            selectedLocations.push([_t.t('Выбранные локации')]);
                            params.pl_ids.forEach((id) => {
                                selectedLocations.push([`${projectLocationsById[id].name} (pl_id: ${id})`]);
                            });
                        }

                        break;

                    default:
                        break;
                }
            });

        Object.keys(params).forEach((item) => {
            switch (item) {
                // case 'main_date_range':
                // case 'comparison_date_range':
                case 'access_token':
                case 'obj_ids':
                case 'pl_ids':
                    break;

                default:
                    try {
                        const currentInputParam = getInputParamData(inputParameterList, item);
                        if (currentInputParam.details.input_type !== 'categorical') {
                            break;
                        }
                        const currentInputParamValue = () => {
                            if (currentInputParam.details.choices) {
                                const choice = getChoice(currentInputParam.details.choices, params[item]);
                                if (choice.name.en) {
                                    return lang === 'ru' ? choice.name.default : choice.name.en;
                                } else {
                                    return choice.name.default;
                                }
                            } else {
                                return params[item];
                            }
                        };
                        reportParams.push([currentInputParam[`name_${lang}`], currentInputParamValue()]);
                    } catch (error) {
                        console.warn(error);
                    }
                    break;
            }
        });
        reportParams.push(...selectedLocations);
        return reportParams;
    } catch (error) {
        console.warn(error);

        return [];
    }
};

// export default {createReportParams};
