import React from 'react';
import styled from 'styled-components/macro';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import '../style/select_input_parameter.scss';

class SelectInputParameter extends React.PureComponent {
    onSelectChange = (e, item) => {
        const { parameter } = this.props;
        this.props.onInputParameterChange(parameter.key_name, item.key);
    };

    nameLangChoice = (lang, obj) => {
        switch (lang) {
            case 'ru':
                return obj['default'];
            case 'en':
                return obj['en'] ? obj['en'] : obj['default'];
            default:
                return obj['default'];
        }
    };

    dropdownStyles = { dropdown: { width: 180 } };

    renderOptions = () => {
        try {
            const { parameter, lang, globalInputParamsValues } = this.props;

            const options = parameter.details.choices.map((item, i) => {
                return { key: item.value, text: this.nameLangChoice(lang, item.name) };
            });

            const maxOptionsWidth = () => {
                return Math.max(...parameter.details.choices.map((item) => this.nameLangChoice(lang, item.name).length)) * 9;
            };

            const currentValue = globalInputParamsValues[parameter.key_name];
            const dropdownWidth = maxOptionsWidth() > 180 ? maxOptionsWidth() : 0;

            return (
                <StyledDropdown
                    dropdownWidth={dropdownWidth}
                    label={parameter.translations[lang].name}
                    selectedKey={currentValue !== undefined ? currentValue : undefined}
                    onChange={this.onSelectChange}
                    placeholder="Select an option"
                    options={options}
                    styles={this.dropdownStyles}
                />
            );
        } catch (error) {}
    };

    render() {
        return <div>{this.renderOptions()}</div>;
    }
}

export default SelectInputParameter;

const StyledDropdown = styled(Dropdown)`
    .ms-Dropdown-label {
        padding-top: 0;
        color: #827f7d;
    }
    .ms-Dropdown-title {
        border-color: rgba(138, 136, 134, 0.5) !important;
    }
`;
