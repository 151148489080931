import connect from 'react-redux/es/connect/connect';

import ImgPreview from './imgPreview';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PreviewMap extends Component {
    static defaultProps = {};

    static propTypes = {};

    internalState = {
        isMounted: null,
    };

    state = {
        previewItem: this.props.mapPreviewItem,
        roomsTransition: this.props.roomsTransition,
        uuidPlanSet: this.props.uuidPlanSet,
    };

    componentDidMount() {
        this.internalState.isMounted = true;
    }

    componentWillUnmount() {
        this.internalState.isMounted = false;
    }

    render() {
        var count = -1;
        var getFloorId = this.props.getFloorId;
        var mode = this.props.mode;
        var roomsTransition = this.props.roomsTransition;
        var uuidPlanSet = this.props.uuidPlanSet;
        let floorIndexSelected = this.props.floorIndexSelected;
        let Link = this.props.Link;
        // console.log('>>>>>>>>>>>>>>>>.', this.props.previewItem);
        if (this.props.previewItem != null) {
            var previewNodes = this.props.plans.map(function (prop, id) {
                count += 1;
                // console.log('>>>>>>>>>>>>>>>>.', prop.floor);
                return (
                    <ImgPreview
                        getFloorId={getFloorId}
                        uuidPlanSet={uuidPlanSet}
                        mode={mode}
                        roomsTransition={roomsTransition}
                        id={prop.floor}
                        index={id}
                        key={id}
                        prop={prop}
                        floorIndexSelected={floorIndexSelected}
                        statusSelected={floorIndexSelected === id}
                        Link={Link}
                    />
                );
            });
        } else if (this.props.previewItem == null) {
            var previewNodes = null;
        }
        if (uuidPlanSet === null) {
            return null;
        }
        return (
            <div className="previewMap">
                <div className="previewNode">{previewNodes}</div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        previewItem: state.MapsApp.mapPreviewItem,
        roomsTransition: state.MapsApp.roomsTransition,
        uuidPlanSet: state.MapsApp.uuidPlanSet,
        plans: state.ReportParametersManager.mapsData.plans,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

const ConnectPreviewMap = connect(mapStateToProps, mapDispatchToProps)(PreviewMap);

export default ConnectPreviewMap;
