import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const TooltipAttachFile = React.memo(() => {
    const { t } = useTranslation();

    return (
        <Container>
            <p>{t('Сегмент в виде *.txt файла')}</p>
            <p></p>
            <p>[ machash ]</p>
            <CodeWrapper>
                <code>336925CE5DAB507BC7AB7777CBAE9336</code>
                <code>D102592F8DE2B5A0DA78F2A5A001F03B</code>
                <code>D96E2AF89B13847805BF539659BA17E8</code>
            </CodeWrapper>
            <p>[ phone ]</p>
            <CodeWrapper>
                <code>+79771000302</code>
                <code>8945543223</code>
                <code>+7 (977) 100 03 02</code>
                <code> </code>
                <code>+7 (977) 100 - 03 - 02</code>
                <code> </code>
                <code>+7(977)100-03-02</code>
                <code>+7 9771000302</code>
            </CodeWrapper>
        </Container>
    );
});

export default TooltipAttachFile;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    p {
        margin: 0;
        margin-top: 5px;
        font-size: 16px;
    }
`;

const CodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px 0;
    /* border: 1px solid red; */
    background-color: rgb(239, 240, 241);
    code {
        font-size: 14px;
    }
`;
