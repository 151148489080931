
import TimesOfDay from './timesOfDay';
import React from 'react';
import PropTypes from 'prop-types';

function Plan(props) {

    const onHide = (e) => {
        if (e.target === document.getElementById('radiusPlanBox')) {
            props.setStatusPlanPanel(false);
            props.resetData();
        }
    
    }
    return (
        <div id='radiusPlanBox' onClick={onHide} className='radiusPlanBox'>
            <div id='radiusTimesOfDayBox' className='radiusPlanPanel'>
                <TimesOfDay
                    dataPlan={props.dataPlan}
                    time={props.time}
                    isChecked={props.isChecked}
                    statusLoadPlan={props.statusLoadPlan}
                    statusShowMaxCalendar={props.statusShowMaxCalendar}
                    title={props.title}
                    statusEdit={props.statusEdit}
                    dataMallsId={props.dataMallsId}
                    browserLangsForAnyButton={props.browserLangsForAnyButton}
                    browserLangs={props.browserLangs}

                    getTimeMax={props.getTimeMax}
                    getTimeMin={props.getTimeMin}
                    selectAllDay={props.selectAllDay}
                    onClickButtonAllLang={props.onClickButtonAllLang}
                    selectAllTime={props.selectAllTime}
                    selectAllDate={props.selectAllDate}
                    resetData={props.resetData}
                    save={props.save}
                    setStatusPlanPanel={props.setStatusPlanPanel}
                    onClickDay={props.onClickDay}
                    showMaxCalendar={props.showMaxCalendar}
                    handleEvent={props.handleEvent}
                    // onClickDay={props.onClickDay}
                    onSelectLang={props.onSelectLang}
                    token={props.token}
                    arrayLocationSelectNotMulti={props.arrayLocationSelectNotMulti}
                    _t={props._t}
                    pl_ids={props.pl_ids}
                />
            </div>
        </div>
    );
}

Plan.propTypes = {};
Plan.defaultProps = {};

export default Plan;



