import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import Tools from '../../tools/diagramTools';

const StyledSpan = styled.span`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin: 24px 0px;
`;

function commafy(num) {
    var str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }
    if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
}

const PercentRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    i {
        margin-right: 10px;
        margin-top: 3px;
        font-size: 14px;
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 16pt;
        font-family: ProximaNova;
    }
`;

const CompareRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    span {
        margin: 5px 10px;
        font-family: ProximaNova;
    }
`;

const ComparisonScore = styled.div``;

const Score = styled.div`
    padding-bottom: 30px;
`;

const Container = styled.div`
    margin-top: auto;
    padding-bottom: 10px;
`;

export default React.memo(({ ...props }) => {
    const getPersent = (countCompare, countMain) => {
        var temp = ((countMain - countCompare) / countCompare) * 100;
        if (countCompare === 0) {
            return '--|--';
        }
        return temp.toFixed(1) + '%';
    };

    const renderScore = (data, dataView) => {
        if (data === undefined || dataView === undefined) return null;
        if (dataView.data_report.data_type.key_name === 'comparison_score') {
            // console.log('>>>>>>>>>>>>>1')
            const comparisonData = data.filter((item) => item.data_aspects.time.date_range_type === 'comparison_date_range')[0];
            const mainData = data.filter((item) => item.data_aspects.time.date_range_type === 'main_date_range')[0];
            return (
                <ComparisonScore>
                    <PercentRow>
                        <span>
                            {mainData.values[0] > comparisonData.values[0] ? (
                                comparisonData.values[0] === 0 ? null : (
                                    <i style={{ color: 'rgb(48,213,103)' }} className="fa fa-play fa-rotate-270" aria-hidden="true" />
                                )
                            ) : comparisonData.values[0] === 0 ? null : (
                                <i style={{ color: 'rgb(241,74,80)' }} className="fa fa-play fa-rotate-90" aria-hidden="true" />
                            )}
                            <span>{`${getPersent(comparisonData.values[0], mainData.values[0])}`}</span>
                        </span>
                    </PercentRow>
                    <CompareRow>
                        <span
                            style={{
                                color: Tools.DLInfoWithAspects(comparisonData.data_aspects).color,
                                fontSize: comparisonData.values[0] > 9999 ? (comparisonData.values[0] > 999999 ? '10pt' : '13pt') : '16pt',
                                marginTop: comparisonData.values[0] > 9999 ? (comparisonData.values[0] > 999999 ? '11px' : '8px') : '5px',
                            }}
                            className={'mainSpan'}
                        >
                            {Number.isInteger(comparisonData.values[0])
                                ? commafy(comparisonData.values[0])
                                : commafy(comparisonData.values[0].toFixed(2))}
                        </span>
                        <span
                            style={{
                                color: Tools.DLInfoWithAspects(mainData.data_aspects).color,
                                fontSize: mainData.values[0] > 9999 ? (mainData.values[0] > 999999 ? '10pt' : '13pt') : '16pt',
                                marginTop: mainData.values[0] > 9999 ? (mainData.values[0] > 999999 ? '11px' : '8px') : '5px',
                            }}
                            className={'compareSpan'}
                        >
                            {Number.isInteger(mainData.values[0]) ? commafy(mainData.values[0]) : commafy(mainData.values[0].toFixed(2))}
                        </span>
                    </CompareRow>
                </ComparisonScore>
            );
        } else if (dataView.data_report.data_type.key_name === 'score') {
            return (
                <Score className={'rowDataView'}>
                    <StyledSpan style={{ color: Tools.DLInfoWithAspects(data[0].data_aspects).color }}>
                        {Number.isInteger(data[0].values[0]) ? commafy(data[0].values[0]) : commafy(data[0].values[0].toFixed(2))}
                    </StyledSpan>
                </Score>
            );
        } else {
            return null;
        }
    };

    return useMemo(() => {
        return <Container>{props.dataView ? renderScore(props.data.data, props.dataView) : null}</Container>;
    }, [props.dataView, props.data]);
});
