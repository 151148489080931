import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

const ObjSetInfoModal = React.memo(({ dataObjSetsById, id, reportingObjectsById_ctg, ...props }) => {
    const onContainerClick = e => {
        e.stopPropagation();
    };

    const list = useMemo(() => {
        return dataObjSetsById[id].bound_dataobjs.map(objId => {
            const object = reportingObjectsById_ctg[objId];
            return (
                <Item key={objId}>
                    <span>{object.id}</span>
                    <span>{object.name}</span>
                    <ItemType>{object.obj_type}</ItemType>
                </Item>
            );
        });
    }, []);



    return (
        <Container onClick={onContainerClick}>
            <Main>
                <ul>{list}</ul>
            </Main>
        </Container>
    );
});

export default ObjSetInfoModal;

const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    overflow: auto;
    box-sizing: border-box;
`;

const Item = styled.li`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 5fr 2fr;
    box-sizing: border-box;
    span {
        padding: 3px;
    }
    border-bottom: 1px solid #ccc;
`;

const ItemType = styled.span`
    text-align: right;
    color: #aaa;
`;

const Container = styled.div`
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
`;
