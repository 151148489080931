import React from 'react';

const AvgLineLabel = React.memo(props => {
  const style = {
    fontSize: 10
  };
  const { x, y, width, height } = props.viewBox;
  return (
    <text
      fill={props.color}
      style={style}
      x={props.position === 'start' ? x + 10 : x + width - 10}
      y={y}
      dy={-5}
      className="recharts-text recharts-label"
      textAnchor={props.position === 'start' ? 'start' : 'end'}
    >
      <tspan>{props.value}</tspan>
    </text>
  );
});

export default AvgLineLabel;
