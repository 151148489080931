import React, { Component, useLayoutEffect, useEffect, useState, useMemo } from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components/macro';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import './style/DiagramStyle.scss';
import { getDiagramsDataFromServer } from './tools/diagramTools';
import { runAction } from '../../actions';
import hostForLocation from '../../hostnames/hostname';
import GroupsList from './components/groups_list';
import LoadingSpinner from './components/loading_spinner';
import TitlesList from './components/pdf_branch/pdf_titles_list';
import PagesList from './components/pdf_branch/pdf_pages_list';
import Tools from '../../tools/tools';
import refresh from './tools/refresh';
import PagesListCustomSection from "./components/pdf_branch/pdf_page_list_custom_section";

export const DiagramsContext = React.createContext({});

const Diagrams = React.memo(() => {
    let stateController;
    const dispatch = useDispatch();
    const customParamsSet = Tools.useGetParamSet().custom;

    const {
        groupIDsBySubsectionKeyName,
        objLocationSelect,
        dataViewsByGroupID,
        reportData,
        diagramOptionsByID,
        indexDataView,
        input_parameter_list,
        statusShowRightPanel,
        selected,
        selected2,
        customInputParamsValues,
        globalInputParamsValues,
        locations,
        objLocationControlSelect,
        statusLoadDataDiagram
    } = useSelector((state) => state.ReportParametersManager);
    const { projectLocationsById, outdoorAdSpacesById, adCampaignsById, reportingObjectsById, colors, inputParametersByName } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );
    const { token, white_label_settings } = useSelector((state) => state.LoginApp);
    const { defaultSize, fullScreenId, diagramsInnerParams, storedInputParamsValues, diagramsHeights, logOverlay } = useSelector(
        (state) => state.Diagrams
    );
    const { cabinetMode, lang, dataSelectSections, scrollWidth, sectionsByKeyName, permanentCloudData } = useSelector(
        (state) => state.TopPanel
    );
    const currentSection = useSelector((state) => state.TopPanel.status);
    const currentSubSection = useSelector((state) => state.TopPanel.statusLeftPanel);
    const locationsСatalog = useSelector((state) => state.PanelLocationApp.objDataByObjId);

    // console.log(permanentCloudData);
    // console.log(permanentCloudData.custom_dataViews);

    useEffect(() => {
        onEditGroupClick();
    }, [currentSubSection]);

    const _refresh = (...args) => {
        // console.log('get diagram data _____');
        refresh(dispatch, token, ...args);
    };

    useEffect(() => {
        if (!permanentCloudData.custom_dataViews) return;
        const unitedParamsValues = {
            ...customInputParamsValues,
            ...globalInputParamsValues,
        };
        permanentCloudData.custom_dataViews.forEach((dataView) => {
            const newInputParamsValues = {};
            dataView.data_report.input_parameters.forEach((parameter) => {
                newInputParamsValues[parameter.key_name] = unitedParamsValues[parameter.key_name];
            });

            refresh(dispatch, token, dataView, null, newInputParamsValues);
        });
    }, [
        permanentCloudData &&
            permanentCloudData.custom_dataViews &&
            permanentCloudData.custom_dataViews.reduce((acc, item) => {
                return acc + item.data_view_id;
            }, ''),
    ]);

    const addDiagramToC_D = (args) => {
        dispatch(runAction('addDiagramToC_D_TopPanel', { ...args }));
        // useRefresh(dispatch, token, ...args);
    };

    const deleteDiagramFromC_D = (args) => {
        dispatch(runAction('deleteDiagramFromC_D_TopPanel', args));
    };

    useEffect(() => {
        getDataInputParams({
            objLocationSelect,
            selected,
            selected2,
            locations,
            objLocationControlSelect,
            token,
        });
        // getDetailInputParams();
    }, [dataViewsByGroupID]);

    // useEffect(() => {
    //     if (input_parameter_list.length !== 0) {
    //         getReportList();
    //     }
    // }, [input_parameter_list]);

    const _generateAtomicTableData = (buttonCoords, diagram, show) => {
        if (!diagram.dataView) return;

        generateAtomicTableData({
            diagram,
            locationsСatalog,
            customParamsSet,
            projectLocationsById,
            adCampaignsById,
            outdoorAdSpacesById,
            input_parameter_list,
        });
        if (!diagram.hasOwnProperty('id')) {
            diagram.id = diagram.dataView.data_view_id;
        }
    };

    const getGroups = () => {
        try {
            const filtered = sectionsByKeyName[currentSection].subsections.filter((item) => {
                return item.key_name === currentSubSection;
            })[0];

            switch (cabinetMode) {
                case '-pdf':
                    return {
                        groups: filtered.data_view_groups,
                        subsection_width: filtered.width,
                    };
                default:
                    return {
                        groups: filtered.data_view_groups,
                        subsection_width: filtered.width,
                    };
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const getReportParams = () => {
        if (!reportData) return {};
        return Object.keys(reportData)
            .filter((key) => {
                return reportData[key].obj !== null && reportData[key].response !== null;
            })
            .reduce((acc, key) => {
                return { ...acc, ...reportData[key].obj };
            }, {});
    };

    const _onSaveXLSXClick = () => {
        onSaveXLSXClick(lang);
    };

    // ------------ DISPATCH ------------
    const changeDiagramHeight = (groupId, diagramId, height) => {
        dispatch(runAction('changeDiagramHeight_Diagrams', { groupId, diagramId, height }));
    };

    const fullScreenExit = () => {
        dispatch(runAction('fullScreenExit'));
    };
    const onFullScreenClick = (data_view_id) => {
        dispatch(runAction('onFullScreenClick', { data_view_id }));
    };
    const onSaveXLSXClick = (lang) => {
        dispatch(runAction('saveAtomicTableFile_XLSX', { lang }));
    };
    const onContextMenuReportParamsClick = (index) => {
        dispatch(runAction('selectDataView', { index }));
    };
    const toggleModal = (args) => {
        dispatch(runAction('toggleModal', { args }));
    };

    // const getReportList = () => {
    //     dispatch(
    //         runAction('getReportList', {
    //             url: `${hostForLocation.getHostName().analytics}/content_structure/data_view_list/`,
    //         })
    //     );
    // };

    const storeInputParamsValues = (values, subsection) => {
        dispatch(runAction('storeInputParamsValues_Diagrams', { values, subsection }));
    };

    const toggleSharedAlert = (args) => {
        dispatch(runAction('toggleSharedAlert', { ...args }));
    };

    const diagramInnerAction = (actionName, newShift, diagramId, diagramType) => {
        dispatch(runAction(actionName, { newShift, diagramId, diagramType }));
    };
    // const getDetailInputParams = () => {
    //     dispatch(
    //         runAction('getDetailInputParams', {
    //             url: `${hostForLocation.getHostName().analytics}/data_structure/input_parameter_list`,
    //         })
    //     );
    // };
    const getDataInputParams = (params) => {
        dispatch(runAction('getDataInputParams', { params: params }));
    };

    const setLogOverlayShow = (args) => {
        dispatch(runAction('setLogOverlayShow_Diagrams', { args }));
    };

    const generateAtomicTableData = (args) => {
        dispatch(runAction('generateAtomicTableData', { ...args }));
    };

    const changeLayoutC_D = (args) => {
        dispatch(runAction('changeLayoutC_D_TopPanel', { ...args }));
    };

    const onEditGroupClick = (args) => {
        dispatch(runAction('onEditGroupClick_TopPanel', { ...args }));
    };

    const _toggleModal = (args) => {
        dispatch(runAction('toggleModal', { args }));
    };

    const onMoveGroupClick = (args) => {
        dispatch(runAction('onMoveGroupClick_TopPanel', { ...args }));
    };

    // ------------ DISPATCH ------------

    if (!sectionsByKeyName || !dataViewsByGroupID) return <LoadingSpinner />;

    const diagramContextValue = {
        customParamsSet,
        toggleSharedAlert,
        groupIDsBySubsectionKeyName,
        permanentCloudData,
        changeLayoutC_D,
        addDiagramToC_D,
        deleteDiagramFromC_D,
        logOverlay,
        setLogOverlayShow,
        diagramsHeights,
        changeDiagramHeight,
        scrollWidth,
        colors,
        outdoorAdSpacesById,
        projectLocationsById,
        sectionsByKeyName,
        locationsСatalog,
        input_parameter_list,
        cabinetMode,
        groups: getGroups(),
        dataViewsByGroupID,
        currentSection,
        currentSubSection,
        lang,
        diagramOptionsByID,
        DiagramParamsPanelId: indexDataView,
        showDiagramParamsPanel: statusShowRightPanel,
        defaultSize,
        reportData,
        customInputParamsValues,
        globalInputParamsValues,
        storedInputParamsValues,
        reportingObjectsById,
        generateAtomicTableData: _generateAtomicTableData,
        storeInputParamsValues,
        dataSelectSections,
        white_label_settings,
        diagramsInnerParams,
        diagramInnerAction,
        fullScreenId,
        _refresh,
        _toggleModal,
        token,
        toggleModal,
        onContextMenuReportParamsClick,
        onFullScreenClick,
        fullScreenExit,
        onEditGroupClick,
        onMoveGroupClick,
        WORK_HEIGHT: 1500,
        reportParams: getReportParams(),
        onSaveXLSXClick: _onSaveXLSXClick,
        statusLoadDataDiagram
    };
    
    return cabinetMode === '' ? (
        <React.Fragment>
            <DiagramsContext.Provider value={diagramContextValue}>
                <GroupsList />
            </DiagramsContext.Provider>
        </React.Fragment>
    ) : cabinetMode === '-pdf' ? (
        <React.Fragment>
            <DiagramsContext.Provider value={diagramContextValue}>
                {
                    currentSection === 'custom_section' ? <PagesListCustomSection/> : <PagesList />
                }

            </DiagramsContext.Provider>
        </React.Fragment>
    ) : null;
});

export default Diagrams;

const MainWrapper = styled.div`
    /* height: 100vh;
    overflow: auto; */
`;
