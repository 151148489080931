import React, { useMemo, useState, useEffect } from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import styled from 'styled-components/macro';
import { Transition } from 'react-transition-group';

const StMessageBar = styled(MessageBar)`
    background-color: ${p => {
        switch (p.type) {
            case 'success':
                return 'rgb(223, 246, 221)';
            case 'error':
                return 'rgb(253, 231, 233)';
            default:
                return 'rgb(253, 231, 233)';
        }
    }};
    width: auto;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
`;

const Container = styled.div`
    position: absolute;
    width: auto;
    z-index: 1000;
    right: 20px;
    top: ${p => (p.position === 'top' ? '20px' : 'none')};
    bottom: ${p => (p.position === 'bottom' ? '20px' : 'none')};
    transition: 0.3s;
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
    display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
`;

const Alert = React.memo(({ show, position = 'top', type = 'error', message = '', lifeTime = 3000, ...props }) => {
    useEffect(() => {
        show &&
            setTimeout(() => {
                props.dismissAlert();
            }, lifeTime);
    }, [show]);

    const messageBarType = useMemo(() => {
        switch (type) {
            case 'error':
                return MessageBarType.error;
            case 'success':
                return MessageBarType.success;

            default:
                return MessageBarType.error;
        }
    }, [type]);

    return (
        <Transition in={show} timeout={100} unmountOnExit mountOnEnter>
            {state => (
                <Container state={state} position={position}>
                    <StMessageBar
                        // actions={
                        //     <Container>
                        //         <MessageBarButton>Yes</MessageBarButton>
                        //         <MessageBarButton>No</MessageBarButton>
                        //     </div>
                        // }
                        type={type}
                        messageBarType={messageBarType}
                        isMultiline={false}
                    >
                        {message}
                    </StMessageBar>
                </Container>
            )}
        </Transition>
    );
});

export default Alert;
