import React, {useState} from 'react';
import {PrimaryButton} from "office-ui-fabric-react/lib/Button";
import {useTranslation} from "react-i18next";
import { Stack, IStackStyles, IStackTokens } from '@fluentui/react/lib/Stack';
import styled from "styled-components/macro";
import Reports from "./reports";
import moment from "moment";
import {
    // getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    // Stack,
    IStackProps,
} from '@fluentui/react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DefaultButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import {useDispatch, useSelector} from "react-redux";
import ModalContent from "./modalContent";
import Cookies from 'universal-cookie';
import {runAction} from "../../../actions";
import hostForLocation from "../../../hostnames/hostname";
import {ClipLoader} from "react-spinners";
import { DateTime } from 'luxon';
import SchedulersWrapper from "./schedulersWrapper";
const cookie = new Cookies();



const cloudAPI = (token, url, method, data) => {
    const options = {
        method: method,
        headers: {
            'Content-type': 'application/json',
            'x-token': token,
            // 'Access-Control-Allow-Origin': '*'
        },
        mode: 'cors',
        credentials: 'include',
    };

    if (data) {
        options.body = JSON.stringify(data);
    }


    return fetch(url, options).then((response) => {
        // console.log('>>>>>>>>>>>>>>>>', Promise.reject(response))
        return response.ok ? response.json() : Promise.reject(response)
    });
};


const LeftSide = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [IsShowModal, setStatusShowModal] = useState(false);
    const [titleNewReport, setTitleNewReport] = useState('');
    const [titleNewScheduler, setTitleNewScheduler] = useState('');
    const [typeCreate, setTypeCreate] = useState('.$Report');
    const [typePeriodicity, setTypePeriodicity] = useState('Weekly');
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [theme] = useState(getTheme());
    const {
        status,
        statusLeftPanel,
        sectionsByKeyName,
    } = useSelector((state) => state.TopPanel);
    const { token } = useSelector((state) => state.LoginApp);
    const {
        reportParams,
        reportParamsScheduler,
        schedulers,
        deleteSchedulers
    } = props;
    const shared_url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_shared_state/`;
    const customInputParamsValues = useSelector((state) => state.ReportParametersManager.customInputParamsValues);
    const globalInputParamsValues = useSelector((state) => state.ReportParametersManager.globalInputParamsValues);


    const headingStackTokens = { childrenGap: 50 };
    const numericalSpacingStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    const stackStyles = {
        root: {
            width: '238px'
        },
    };

    const cancelIcon = { iconName: 'Cancel' };

    const onShowModal = () => {
        setStatusShowModal(true);
    }

    const onHideModal = () => {
        setStatusShowModal(false);
        setTitleNewReport('');
        setTitleNewScheduler('');
        setTypeCreate('.$Report');
        setTypePeriodicity('Weekly');
        setSelectedDay(0);
        setSelectedDate(new Date());
    }

    // console.log('<<<<<<<<<<<<<<<<<<<<', IsShowModal)
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [

            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const stackTokens = { childrenGap: 10 };




    const onSaveNewReport = () => {

        // console.log('>>>>>>>>>>>>>>>>>>',typeCreate)
        let _cloudHash = ''
        const _data = {
            cloud_state: {
                format_version: 1,
                data: {
                    parameters: {
                        customInputParamsValues,
                        globalInputParamsValues,
                    },
                },
            },
            meta: {},
        };
        dispatch(
            runAction('getRequestNewPdfReport', {

            })
        );

        cloudAPI(token, shared_url, 'POST', _data)
            .then((response) => {
                // return response.meta.cloud_hash;

                _cloudHash = response.meta.cloud_hash;
                const dataSubsection = sectionsByKeyName[status].subsections.filter((_item) => _item.key_name === statusLeftPanel)[0];
                let data = '';
                if (typeCreate === '.$Report') {
                    data = JSON.stringify({
                        name: titleNewReport,
                        schedule_type: 'once',
                        cloud_hash: _cloudHash,
                        report_url: document.location.pathname,
                        section: status,
                        subsection: {
                            favorite: dataSubsection.favorite,
                            id: dataSubsection.id,
                            key_name: dataSubsection.key_name,
                            mode: dataSubsection.mode,
                            order_index: dataSubsection.order_index,
                            show_item_colors: dataSubsection.show_item_colors,
                            source_of_data: dataSubsection.source_of_data,
                            translations: dataSubsection.translations
                        }
                    })
                }
                else if (typeCreate === '.$Scheduler') {
                    if (typePeriodicity === 'Weekly') {
                        data = JSON.stringify({
                            name: titleNewScheduler,
                            schedule_type: 'weekly',
                            weekly_day_from: selectedDay,
                            cloud_hash: _cloudHash,
                            report_url: document.location.pathname,
                            period_type: 'week',
                            section: status,
                            subsection: {
                                favorite: dataSubsection.favorite,
                                id: dataSubsection.id,
                                key_name: dataSubsection.key_name,
                                mode: dataSubsection.mode,
                                order_index: dataSubsection.order_index,
                                show_item_colors: dataSubsection.show_item_colors,
                                source_of_data: dataSubsection.source_of_data,
                                translations: dataSubsection.translations
                            }
                        })
                    }
                    else if (typePeriodicity === 'Monthly') {
                        // console.log('>>>>>>>>>>>>>>>>', selectedDate)
                        data = JSON.stringify({
                            name: titleNewScheduler,
                            schedule_type: 'monthly',
                            monthly_day_from: selectedDate.getDate(),
                            cloud_hash: _cloudHash,
                            report_url: document.location.pathname,
                            period_type: 'month',
                            section: status,
                            subsection: {
                                favorite: dataSubsection.favorite,
                                id: dataSubsection.id,
                                key_name: dataSubsection.key_name,
                                mode: dataSubsection.mode,
                                order_index: dataSubsection.order_index,
                                show_item_colors: dataSubsection.show_item_colors,
                                source_of_data: dataSubsection.source_of_data,
                                translations: dataSubsection.translations
                            }
                        })
                    }

                }
                console.log('>>>>>>>>>>>>>>', JSON.parse(data));
                var headers = {
                    'x-token': token,
                };
                dispatch(
                    runAction('getRequestNewPdfReport', {

                    })
                );
                dispatch(
                    runAction('createPdfReports', {
                        url: `${hostForLocation.getHostName().export}/reports/schedulers/`,
                        headers,
                        data
                    })
                );

                onHideModal()
            })
            .catch((err) => {
                // setError(true);
                console.log('ERROR ', err);
            });


    }

    const processIsDisabled = (_typeCreate, _titleNewReport, _typePeriodicity, _selectedDate, _titleNewScheduler) => {

        if (_typeCreate === '.$Report') {
            return _titleNewReport === '';
        }
        else if (_typeCreate === '.$Scheduler') {
            if (_typePeriodicity === 'Weekly') {
                return _titleNewScheduler === '';
            }
            else if (_typePeriodicity === 'Monthly') {
                return _titleNewScheduler === '' || _selectedDate === '';
            }
        }
    }

    return (
        <SCLeftSideWrapper>
            <Stack horizontal disableShrink tokens={headingStackTokens}>
                <Stack
                    styles={stackStyles}
                    tokens={numericalSpacingStackTokens}>
                    <PrimaryButton
                        // styles={stackStyles}
                        text={t('Создать')}
                        onClick={onShowModal}
                        allowDisabledFocus
                        // disabled={isDisabled}
                    />
                </Stack>
            </Stack>

            <SchedulersWrapper
                deleteSchedulers={deleteSchedulers}
                schedulers={schedulers}
            />

            <Modal
                // titleAriaId={titleId}
                isOpen={IsShowModal}
                onDismiss={onHideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                // dragOptions={isDraggable ? dragOptions : undefined}
            >
                <div className={contentStyles.header}>
                    <span >{t('Новый')}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onHideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <ModalContent
                        statusLeftPanel={statusLeftPanel}
                        reportParams={reportParams}
                        reportParamsScheduler={reportParamsScheduler}
                        setTitleNewReport={setTitleNewReport}
                        titleNewReport={titleNewReport}
                        setTypeCreate={setTypeCreate}
                        setTypePeriodicity={setTypePeriodicity}
                        typePeriodicity={typePeriodicity}
                        setSelectedDay={setSelectedDay}
                        selectedDay={selectedDay}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        setTitleNewScheduler={setTitleNewScheduler}
                        titleNewScheduler={titleNewScheduler}
                    />
                    <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
                        <PrimaryButton
                            text="Ok"
                            onClick={onSaveNewReport}
                            allowDisabledFocus
                            disabled={processIsDisabled(typeCreate, titleNewReport, typePeriodicity, selectedDate, titleNewScheduler)}
                            // checked={checked}
                        />
                        <DefaultButton
                            text="Cancel"
                            onClick={onHideModal}
                            allowDisabledFocus
                            // disabled={disabled}
                            // checked={checked}
                        />

                    </Stack>
                </div>

            </Modal>

            {/*<Reports/>*/}
        </SCLeftSideWrapper>
    );
};

export default LeftSide;


const SCLeftSideWrapper = styled.div`
  height: 100%;
  
`;



