import React from 'react';
import styled from 'styled-components/macro';
import { RingLoader } from 'react-spinners';

export default React.memo(() => {
    return (
        <SpinnerContainer>
            {/* <RingLoader color={'#ddd'} loading={true} size={140} /> */}
            <GradientBox w={500} h={300} />
            <Inner>
                <GradientBox w={300} h={200} />
                <GradientBox w={400} h={200} />
            </Inner>
            <GradientBox w={716} h={50} />
        </SpinnerContainer>
    );
});

const Inner = styled.div`
    display: flex;
`;
const SpinnerContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 15px;
    /* justify-content: center;
    align-items: center; */
`;

const GradientBox = styled.div`
    break-after: always;
    margin-right: 16px;
    margin-bottom: 16px;
    width: ${p => p.w + 'px'};
    height: ${p => p.h + 'px'};
    background: rgba(255, 255, 255, 0.2);
    /* background: linear-gradient(270deg, #eee, #fff, #eee, #fff); */
    background-size: 800% 800%;

    -webkit-animation: GradientAnimation 3s ease infinite;
    -moz-animation: GradientAnimation 3s ease infinite;
    animation: GradientAnimation 3s ease infinite;

    @-webkit-keyframes GradientAnimation {
        0% {
            background: rgba(255, 255, 255, 0.2);
        }
        50% {
            background: rgba(255, 255, 255, 0.8);
        }
        100% {
            background: rgba(255, 255, 255, 0.2);
        }
    }
    @-moz-keyframes GradientAnimation {
        0% {
            background: rgba(255, 255, 255, 0.2);
        }
        50% {
            background: rgba(255, 255, 255, 0.8);
        }
        100% {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    @keyframes GradientAnimation {
        0% {
            background: rgba(255, 255, 255, 0.2);
        }
        50% {
            background: rgba(255, 255, 255, 0.8);
        }
        100% {
            background: rgba(255, 255, 255, 0.2);
        }
    }
`;
// const GradientBox = styled.div`
//     break-after: always;
//     margin-right: 16px;
//     margin-bottom: 16px;
//     width: ${p => p.w + 'px'};
//     height: ${p => p.h + 'px'};
//     background: linear-gradient(270deg, #eee, #fff, #eee, #fff);
//     background-size: 800% 800%;

//     -webkit-animation: GradientAnimation 3s ease infinite;
//     -moz-animation: GradientAnimation 3s ease infinite;
//     animation: GradientAnimation 3s ease infinite;

//     @-webkit-keyframes GradientAnimation {
//         0% {
//             background-position: 0% 50%;
//         }
//         50% {
//             background-position: 100% 51%;
//         }
//         100% {
//             background-position: 0% 50%;
//         }
//     }
//     @-moz-keyframes GradientAnimation {
//         0% {
//             background-position: 0% 50%;
//         }
//         50% {
//             background-position: 100% 51%;
//         }
//         100% {
//             background-position: 0% 50%;
//         }
//     }

//     @keyframes GradientAnimation {
//         0% {
//             background-position: 0% 50%;
//         }
//         50% {
//             background-position: 100% 51%;
//         }
//         100% {
//             background-position: 0% 50%;
//         }
//     }
// `;
