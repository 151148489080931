import React, { Component, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import '../style/topPanelStyle.scss';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DefaultButton, PrimaryButton, CompoundButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { DateRangePicker, DateRange, Calendar } from 'react-date-range';
import Tools from '../../../tools/tools';

// import * as rdrLocales from 'react-date-range/dist/locale';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import toolsForDate from '../../topPanel/tools';
import {useDispatch, useSelector} from "react-redux";
import {runAction} from "../../../actions";

// const getStatusMainDateRange = (mainDateRange) => {
//     console.log('>>>>>>>>>', toolsForDate.mainIsToday(moment,mainDateRange))
//     if (toolsForDate.mainIsToday(moment,mainDateRange)) {
//         return 'Today'
//     }
//     // toolsForDate.mainIsToday(moment,mainDateRange)
//
// }

const TopCalendar = React.memo(
    ({
        currentSection,
        sectionsByKeyName,
        lang,
        statusLeftPanel,
        fullScreenId = null,
        main_date_range,
        comparison_date_range,
        ...props
    }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const [statusShowLeft, setStatusShowCalendarLeft] = useState(false);
        const [statusShowRight, setStatusShowCalendarRight] = useState(false);
        const [statusShowForecast, setStatusShowCalendarForecast] = useState(false);
        const [dateObjMain, setDateMain] = useState({
            startDate: moment(main_date_range[0]),
            endDate: moment(main_date_range[1]),
        });
        const [dateObjCompare, setDateCompare] = useState({
            startDate: moment(comparison_date_range[0]),
            endDate: moment(comparison_date_range[1]),
        });
        const [statusForCompare, setStatusForCompare] = useState('theSamePeriodUpTo');
        const [statusForMainDateRange, setStatusForMainDateRange] = useState('Last7Days');
        const {
            datePeriod
        } = useSelector(
            (state) => state.PreCampaigns
        );

        useEffect(() => {
            let dataMain = {
                startDate: moment(main_date_range[0]),
                endDate: moment(main_date_range[1]),
            };
            setDateMain(dataMain);
            let dataCompare = {
                startDate: moment(comparison_date_range[0]),
                endDate: moment(comparison_date_range[1]),
            };
            setDateCompare(dataCompare);
            setStatusForMainDateRange(toolsForDate.getStatusMainDateRange(moment, main_date_range));
            setStatusForCompare(
                toolsForDate.getStatusComapreDateRange(moment, comparison_date_range, main_date_range, setStatusForMainDateRange)
            );
        }, [main_date_range, comparison_date_range]);

        let statusShowCompareButton = false;
        let statusShowMainButton = false;

        const customParamsSet = Tools.useGetParamSet(null, null, fullScreenId).custom;

        statusShowCompareButton = customParamsSet.indexOf('comparison_date_range') !== -1;
        statusShowMainButton = customParamsSet.indexOf('main_date_range') !== -1;

        // const onApplyCompare = (e, t) => {
        //
        //     let data = [
        //         moment(dateObjCompare.startDate).format('YYYY-MM-DD'),
        //         moment(dateObjCompare.endDate).format('YYYY-MM-DD')
        //     ]
        //
        //     setStatusShowCalendarLeft(false)
        //     setTimeout(() => {
        //         selectDate(data, 'BoxComparePeriod')
        //     }, 1)
        //
        // }
        // const onApplyMain = (e, t) => {
        //     // console.log('>>>>>>>>>>>>', dateObjMain.startDate, dateObjMain.endDate)
        //
        //     let data = [
        //         moment(dateObjMain.startDate).format('YYYY-MM-DD'),
        //         moment(dateObjMain.endDate).format('YYYY-MM-DD')
        //     ]
        //
        //     setStatusShowCalendarRight(false)
        //     setTimeout(() => {
        //         selectDate(data, 'BoxPeriod')
        //         selectDate(toolsForDate.getComparisonRange(moment, dateObjMain, statusForCompare, statusForMainDateRange), 'BoxComparePeriod')
        //     }, 1)
        //
        // }

        const onApplyCalendar = (e) => {
            let dataMain = [moment(dateObjMain.startDate).format('YYYY-MM-DD'), moment(dateObjMain.endDate).format('YYYY-MM-DD')];

            let dataCompare = [moment(dateObjCompare.startDate).format('YYYY-MM-DD'), moment(dateObjCompare.endDate).format('YYYY-MM-DD')];

            setStatusShowCalendarLeft(false);
            setStatusShowCalendarRight(false);
            setTimeout(() => {
                props.selectDate(dataMain, dataCompare);
                // selectDate(dataCompare, 'BoxComparePeriod')
            }, 1);
        };

        const hideCalendarLeft = (e) => {
            // count = count + 1;
            setStatusShowCalendarLeft(false);
            setStatusForCompare(
                toolsForDate.getStatusComapreDateRange(moment, comparison_date_range, main_date_range, setStatusForMainDateRange)
            );
        };
        const showCalendarLeft = (e) => {
            ReactGA.event({
                category: 'Calendar',
                action: 'Inner ComparisonPeriod Click',
            });
            setStatusShowCalendarLeft(true);
            setStatusShowCalendarRight(false);
        };

        const hideCalendarRight = (e) => {
            // count = count + 1;
            setStatusShowCalendarRight(false);
            setStatusForMainDateRange(toolsForDate.getStatusMainDateRange(moment, main_date_range));
        };
        const showCalendarRight = (e) => {
            ReactGA.event({
                category: 'Calendar',
                action: 'Inner MainPeriod Click',
            });
            setStatusShowCalendarRight(true);
            setStatusShowCalendarLeft(false);
        };

        const onEventMain = (e) => {
            // console.log('>>>>>>>>>>>', e)

            let data = {
                startDate: moment(e.selection.startDate),
                endDate: moment(e.selection.endDate),
            };
            setDateMain(data);
            setStatusForMainDateRange(
                toolsForDate.getStatusMainDateRange(moment, [
                    moment(e.selection.startDate).format('YYYY-MM-DD'),
                    moment(e.selection.endDate).format('YYYY-MM-DD'),
                ])
            );

            let arrCompareDate = toolsForDate.getComparisonRange(moment, data, statusForCompare, statusForMainDateRange);
            let dataCompare = {
                startDate: moment(arrCompareDate[0]),
                endDate: moment(arrCompareDate[1]),
            };
            setDateCompare(dataCompare);
            // setStatusForMainDateRange('CustomPeriod')
            // if (e.type === 'hide') {
            //     setDate(data)
            // }
        };

        const onEventForecast = (e) => {
            dispatch(runAction('getDate_PreCampaigns', { date: {
                ...e.selection
                } }));
        };

        const onEventCompare = (e) => {
            // console.log('>>>>>>>>>>>', e)

            let data = {
                startDate: moment(e.selection.startDate),
                endDate: moment(e.selection.endDate),
            };
            setDateCompare(data);
            setStatusForCompare(
                toolsForDate.getStatusComapreDateRange(moment, comparison_date_range, main_date_range, setStatusForMainDateRange)
            );
            // if (e.type === 'hide') {
            //     setDate(data)
            // }
        };

        const onClickDashCalendarLeft = () => {
            ReactGA.event({
                category: 'Calendar',
                action: 'TopMenu ComparisonPeriod Click',
            });
            setStatusShowCalendarLeft(!statusShowLeft);
            setStatusShowCalendarRight(false);
            let dataMain = {
                startDate: moment(main_date_range[0]),
                endDate: moment(main_date_range[1]),
            };
            setDateMain(dataMain);
            let dataCompare = {
                startDate: moment(comparison_date_range[0]),
                endDate: moment(comparison_date_range[1]),
            };
            setDateCompare(dataCompare);
            if (!statusShowLeft === false) {
                setStatusForCompare(
                    toolsForDate.getStatusComapreDateRange(moment, comparison_date_range, main_date_range, setStatusForMainDateRange)
                );
            }
        };


        const onClickDashCalendarForecast = () => {
            setStatusShowCalendarForecast(!statusShowForecast)
        };


        const onClickDashCalendarRight = () => {
            ReactGA.event({
                category: 'Calendar',
                action: 'TopMenu MainPeriod Click',
            });
            setStatusShowCalendarRight(!statusShowRight);
            if (!statusShowRight === false) {
                setStatusForMainDateRange(toolsForDate.getStatusMainDateRange(moment, main_date_range));
            }
            setStatusShowCalendarLeft(false);
            let dataMain = {
                startDate: moment(main_date_range[0]),
                endDate: moment(main_date_range[1]),
            };
            setDateMain(dataMain);
            let dataCompare = {
                startDate: moment(comparison_date_range[0]),
                endDate: moment(comparison_date_range[1]),
            };
            setDateCompare(dataCompare);
        };

        const theSamePeriodUpTo = (mainDate, compareDate) => {
            console.log('>>>>>>>>>>', mainDate, compareDate);

            return false;
        };

        const onClickButtonCompare = (status) => () => {
            let dataCompRange = toolsForDate.getComparisonRange(moment, dateObjMain, status, statusForMainDateRange);
            // console.log('>>>>>>>>>>@#@#@#', dataCompRange)
            let data = {
                startDate: moment(dataCompRange[0]),
                endDate: moment(dataCompRange[1]),
            };

            setStatusForCompare(
                toolsForDate.getStatusComapreDateRange(
                    moment,
                    dataCompRange,
                    [dateObjMain.startDate, dateObjMain.endDate],
                    setStatusForMainDateRange
                )
            );
            setDateCompare(data);
        };

        const onClickButtonMainDateRange = (type) => () => {
            let mainDateRange = toolsForDate.getDateByType(moment, type);

            let data = {
                startDate: moment(mainDateRange[0]),
                endDate: moment(mainDateRange[1]),
            };
            setDateMain(data);
            setStatusForMainDateRange(toolsForDate.getStatusMainDateRange(moment, [mainDateRange[0], mainDateRange[1]]));

            let arrCompareDate = toolsForDate.getComparisonRange(moment, data, statusForCompare, type);
            let dataCompare = {
                startDate: moment(arrCompareDate[0]),
                endDate: moment(arrCompareDate[1]),
            };
            setDateCompare(dataCompare);
        };

        let locale = {
            format: 'YYYY-MM-DD',
            separator: ' - ',
            applyLabel: t('Применить'),
            cancelLabel: t('Закрыть'),
            fromLabel: t('С'),
            toLabel: t('По'),
            customRangeLabel: t('Произвольный период'),
            weekLabel: t('Неделя'),
            daysOfWeek: [t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб'), t('Вс')],
            monthNames: [
                t('Январь'),
                t('Февраль'),
                t('Март'),
                t('Апрель'),
                t('Май'),
                t('Июнь'),
                t('Июль'),
                t('Август'),
                t('Сентябрь'),
                t('Октябрь'),
                t('Ноябрь'),
                t('Декабрь'),
            ],
            firstDay: 1,
        };


        var a = moment(dateObjMain.startDate);
        var b = moment(dateObjMain.endDate);

        var days = a.diff(b, 'day');

        let showButton_theSamePeriodLastWeek = false;

        if (Math.abs(days) < Math.abs(6)) {
            showButton_theSamePeriodLastWeek = true;
        }

        moment.locale(lang);


        return (
            <React.Fragment>
                <div className="boxButtonForCalendar">
                    {statusShowCompareButton ? (
                        statusLeftPanel === 'pre_campaign_static' ?
                        <StyledCompoundButton range={'comparison'} onClick={onClickDashCalendarForecast} secondaryText={t('Выберите период прогноза')}>
                            {`${moment(comparison_date_range[0]).format('DD MMM YYYY')} - ${moment(comparison_date_range[1]).format(
                                'DD MMM YYYY'
                            )}`}
                        </StyledCompoundButton>
                         :
                        <StyledCompoundButton range={'comparison'} onClick={onClickDashCalendarLeft} secondaryText={t('Период сравнения')}>
                            {`${moment(comparison_date_range[0]).format('DD MMM YYYY')} - ${moment(comparison_date_range[1]).format(
                                'DD MMM YYYY'
                            )}`}
                        </StyledCompoundButton>
                    ) : null}
                    {statusShowMainButton ? (
                        statusLeftPanel === 'pre_campaign_static' ?
                        <StyledCompoundButton range={'main'} onClick={onClickDashCalendarForecast} secondaryText={t('Выберите период прогноза')}>
                            {`${moment(datePeriod.startDate).format('DD MMM YYYY')} - ${moment(datePeriod.endDate).format(
                                'DD MMM YYYY'
                            )}`}
                        </StyledCompoundButton>
                         : statusLeftPanel === 'pre_campaign_dynamic' ? null :
                        <StyledCompoundButton range={'main'} onClick={onClickDashCalendarRight} secondaryText={t('Отчетный период')}>
                            {`${moment(main_date_range[0]).format('DD MMM YYYY')} - ${moment(main_date_range[1]).format('DD MMM YYYY')}`}
                        </StyledCompoundButton>
                    ) : null}
                </div>
                {statusLeftPanel !== 'pre_campaign_static' && statusShowLeft ? (
                    <div className="boxCalendarLib left">
                        <div onClick={hideCalendarLeft} className="backgroundModalDateRange"></div>
                        <div className="buttonBox">
                            <button onClick={showCalendarLeft} className={'left'}>
                                {t('Период сравнения')}
                            </button>
                            <button onClick={showCalendarRight} className={'right'}>
                                {t('Отчетный период')}
                            </button>
                        </div>
                        <div className="tempBox">
                            <div className="boxButton">
                                <TheSamePeriodUpTo
                                    status={statusForCompare === 'theSamePeriodUpTo'}
                                    onClick={onClickButtonCompare('theSamePeriodUpTo')}
                                >
                                    {t('Аналогичный ДО')}
                                </TheSamePeriodUpTo>
                                {showButton_theSamePeriodLastWeek ? (
                                    <TheSamePeriodLastWeek
                                        status={statusForCompare === 'theSamePeriodLastWeek'}
                                        onClick={onClickButtonCompare('theSamePeriodLastWeek')}
                                    >
                                        {t('Аналогичный на прошлой неделе')}
                                    </TheSamePeriodLastWeek>
                                ) : (
                                    <button className={'disabledButton'}>{t('Аналогичный на прошлой неделе')}</button>
                                )}
                                <TheSamePeriodLastMonth
                                    status={statusForCompare === 'theSamePeriodLastMonth'}
                                    onClick={onClickButtonCompare('theSamePeriodLastMonth')}
                                >
                                    {t('Аналогичный в прошлом месяце')}
                                </TheSamePeriodLastMonth>
                                <TheSamePeriodLastYear
                                    status={statusForCompare === 'theSamePeriodLastYear'}
                                    onClick={onClickButtonCompare('theSamePeriodLastYear')}
                                >
                                    {t('Аналогичный в прошлом году')}
                                </TheSamePeriodLastYear>
                                <CustomPeriod status={statusForCompare === 'CustomPeriod'} onClick={onClickButtonCompare('CustomPeriod')}>
                                    {t('Произвольный')}
                                </CustomPeriod>
                            </div>
                        </div>

                        <DateRange
                            onChange={onEventCompare}
                            // locale={rdrLocales[lang === 'ru' ? 'ru' : 'enGB']}
                            rangeColors={['#f49b10']}
                            ranges={[
                                {
                                    startDate: new Date(moment(dateObjCompare.startDate)),
                                    endDate: new Date(moment(dateObjCompare.endDate)),
                                    key: 'selection',
                                },
                            ]}
                            direction={'horizontal'}
                            months={2}
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            maxDate={new Date(moment(dateObjMain.startDate).subtract(1, 'days'))}
                            // onChange={this.handleSelect}
                        />
                        <StPrimaryButton text={t('Применить')} onClick={onApplyCalendar} />
                        {/* <button onClick={onApplyCalendar} className="Apply">
                        {t('Применить')}
                    </button> */}
                    </div>
                ) : null}

                {
                        statusLeftPanel === 'pre_campaign_static' && statusShowForecast ?
                        <div className="boxCalendarLib right">
                        <div onClick={hideCalendarRight} className="backgroundModalDateRange"></div>
                        <div className="buttonBox">
                            {/* <button disabled={statusShowCompareButton === false} onClick={showCalendarLeft} className={'left'}>
                                {t('Выберите период прогноза')}
                            </button>
                            <button onClick={showCalendarRight} className={'right'}>
                                {t('Выберите период прогноза')}
                            </button> */}
                        </div>
                        <div className="tempBox">
                            {/* <div className="boxButton">
                                <div className="headerButton">
                                    <Today status={statusForMainDateRange === 'Today'} onClick={onClickButtonMainDateRange('Today')}>
                                        {t('Сегодня')}
                                    </Today>
                                    <Yesterday
                                        status={statusForMainDateRange === 'Yesterday'}
                                        onClick={onClickButtonMainDateRange('Yesterday')}
                                    >
                                        {t('Вчера')}
                                    </Yesterday>
                                </div>
                                <Last7Days
                                    status={statusForMainDateRange === 'Last7Days'}
                                    onClick={onClickButtonMainDateRange('Last7Days')}
                                >
                                    {t('Последние 7 дней')}
                                </Last7Days>
                                <Last30Days
                                    status={statusForMainDateRange === 'Last30Days'}
                                    onClick={onClickButtonMainDateRange('Last30Days')}
                                >
                                    {t('Последние 30 дней')}
                                </Last30Days>
                                <CurrentMonth
                                    status={statusForMainDateRange === 'CurrentMonth'}
                                    onClick={onClickButtonMainDateRange('CurrentMonth')}
                                >
                                    {t('Текущий месяц')}
                                </CurrentMonth>
                                <LastMonth
                                    status={statusForMainDateRange === 'LastMonth'}
                                    onClick={onClickButtonMainDateRange('LastMonth')}
                                >
                                    {t('Прошлый месяц')}
                                </LastMonth>
                                <CustomPeriodMain
                                    status={statusForMainDateRange === 'CustomPeriod'}
                                    onClick={onClickButtonMainDateRange('CustomPeriod')}
                                >
                                    {t('Произвольный')}
                                </CustomPeriodMain>
                            </div> */}
                        </div>
                        <DateRange
                            onChange={onEventForecast}
                            // locale={rdrLocales[lang === 'ru' ? 'ru' : 'enGB']}
                            rangeColors={['#3c6499']}
                            // date={datePeriod}
                            ranges={[
                                {
                                    startDate: new Date(moment(datePeriod.startDate)),
                                    endDate: new Date(moment(datePeriod.endDate)),
                                    key: 'selection',
                                },
                            ]}
                            minDate={new Date(moment().add(0, 'days'))}
                            maxDate={new Date(moment(datePeriod.startDate).add(90, 'days'))}
                            direction={'horizontal'}
                            months={2}
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            // onChange={this.handleSelect}
                        />
                        <StPrimaryButton text={t('Ок')} onClick={onClickDashCalendarForecast} />
                        {/* <button onClick={onApplyCalendar} className="Apply">
                        {t('Применить')}
                    </button> */}
                    </div>
                        :
                        null 
                }

                {statusLeftPanel !== 'pre_campaign_static' && statusShowRight ? (
                    <div className="boxCalendarLib right">
                        <div onClick={hideCalendarRight} className="backgroundModalDateRange"></div>
                        <div className="buttonBox">
                            <button disabled={statusShowCompareButton === false} onClick={showCalendarLeft} className={'left'}>
                                {t('Период сравнения')}
                            </button>
                            <button onClick={showCalendarRight} className={'right'}>
                                {t('Отчетный период')}
                            </button>
                        </div>
                        <div className="tempBox">
                            <div className="boxButton">
                                <div className="headerButton">
                                    <Today status={statusForMainDateRange === 'Today'} onClick={onClickButtonMainDateRange('Today')}>
                                        {t('Сегодня')}
                                    </Today>
                                    <Yesterday
                                        status={statusForMainDateRange === 'Yesterday'}
                                        onClick={onClickButtonMainDateRange('Yesterday')}
                                    >
                                        {t('Вчера')}
                                    </Yesterday>
                                </div>
                                <Last7Days
                                    status={statusForMainDateRange === 'Last7Days'}
                                    onClick={onClickButtonMainDateRange('Last7Days')}
                                >
                                    {t('Последние 7 дней')}
                                </Last7Days>
                                <Last30Days
                                    status={statusForMainDateRange === 'Last30Days'}
                                    onClick={onClickButtonMainDateRange('Last30Days')}
                                >
                                    {t('Последние 30 дней')}
                                </Last30Days>
                                <CurrentMonth
                                    status={statusForMainDateRange === 'CurrentMonth'}
                                    onClick={onClickButtonMainDateRange('CurrentMonth')}
                                >
                                    {t('Текущий месяц')}
                                </CurrentMonth>
                                <LastMonth
                                    status={statusForMainDateRange === 'LastMonth'}
                                    onClick={onClickButtonMainDateRange('LastMonth')}
                                >
                                    {t('Прошлый месяц')}
                                </LastMonth>
                                <CustomPeriodMain
                                    status={statusForMainDateRange === 'CustomPeriod'}
                                    onClick={onClickButtonMainDateRange('CustomPeriod')}
                                >
                                    {t('Произвольный')}
                                </CustomPeriodMain>
                            </div>
                        </div>
                        <DateRange
                            onChange={onEventMain}
                            // locale={rdrLocales[lang === 'ru' ? 'ru' : 'enGB']}
                            rangeColors={['#3c6499']}
                            ranges={[
                                {
                                    startDate: new Date(moment(dateObjMain.startDate)),
                                    endDate: new Date(moment(dateObjMain.endDate)),
                                    key: 'selection',
                                },
                            ]}
                            // minDate={new Date(moment(comparison_date_range[1]).add('days', 1))}
                            direction={'horizontal'}
                            months={2}
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            // onChange={this.handleSelect}
                        />
                        <StPrimaryButton text={t('Применить')} onClick={onApplyCalendar} />
                        {/* <button onClick={onApplyCalendar} className="Apply">
                        {t('Применить')}
                    </button> */}
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
);

export default TopCalendar;

const StyledCompoundButton = styled(CompoundButton)`
    padding: 10px;
    border-color: rgba(138, 136, 134, 0.5);
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 40px;
    .ms-Button-label {
        color: ${(p) => (p.range === 'main' ? '#3c6499' : '#f49b10')};
    }
`;
const StPrimaryButton = styled(PrimaryButton)`
    position: absolute;
    right: 12px;
    bottom: 12px;
    padding: 5px;
    border-radius: 3px;
    /* background: #3c6499;
    color: #fff; */
    z-index: 1000000;
`;
const TheSamePeriodUpTo = styled.button`
    background-color: ${(p) => (p.status ? '#f49b10 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const TheSamePeriodLastWeek = styled.button`
    background-color: ${(p) => (p.status ? '#f49b10 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const TheSamePeriodLastMonth = styled.button`
    background-color: ${(p) => (p.status ? '#f49b10 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const TheSamePeriodLastYear = styled.button`
    background-color: ${(p) => (p.status ? '#f49b10 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const CustomPeriod = styled.button`
    background-color: ${(p) => (p.status ? '#f49b10 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;

const Today = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const Yesterday = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const Last7Days = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const Last30Days = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const CurrentMonth = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const LastMonth = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
const CustomPeriodMain = styled.button`
    background-color: ${(p) => (p.status ? '#3c6499 !important' : 'transparent')};
    color: ${(p) => (p.status ? '#ffffff !important' : 'transparent')};
`;
