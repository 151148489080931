import React from 'react';
import PropTypes from 'prop-types';

const WeekBox = ({
                     dataPlan,

                     onClickDay,
                     _t
                 }) => {
    var classNameMonday, classNameTuesday, classNameWednesday, classNameThursday, classNameFriday, classNameSaturday, classNameSunday;
    if (dataPlan.week_days.length === 7) {
        classNameMonday = 'activeDay';
        classNameTuesday = 'activeDay';
        classNameWednesday = 'activeDay';
        classNameThursday = 'activeDay';
        classNameFriday = 'activeDay';
        classNameSaturday = 'activeDay';
        classNameSunday = 'activeDay';
    }
    else if (dataPlan.week_days.length < 7) {
        if (dataPlan.week_days.length === 0) {
            classNameMonday = null;
            classNameTuesday = null;
            classNameWednesday = null;
            classNameThursday = null;
            classNameFriday = null;
            classNameSaturday = null;
            classNameSunday = null;
        }
        else if (dataPlan.week_days.length > 0) {
            classNameMonday = null;
            classNameTuesday = null;
            classNameWednesday = null;
            classNameThursday = null;
            classNameFriday = null;
            classNameSaturday = null;
            classNameSunday = null;
            for (var i = 0; i < dataPlan.week_days.length; i++) {
                if (dataPlan.week_days[i] === 'Monday') {
                    classNameMonday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Tuesday') {
                    classNameTuesday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Wednesday') {
                    classNameWednesday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Thursday') {
                    classNameThursday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Friday') {
                    classNameFriday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Saturday') {
                    classNameSaturday = 'activeDay';
                }
                else if (dataPlan.week_days[i] === 'Sunday') {
                    classNameSunday = 'activeDay';
                }
            }
        }
    }
    return (
        <div className='radiusWeekBox' >
            <div id='Monday' onClick={(e) => onClickDay(e)} className={classNameMonday}>
                <span>{_t.t('Пн')}</span>
            </div>
            <div id='Tuesday' onClick={(e) => onClickDay(e)} className={classNameTuesday}>
                <span>{_t.t('Вт')}</span>
            </div>
            <div id='Wednesday' onClick={(e) => onClickDay(e)} className={classNameWednesday}>
                <span>{_t.t('Ср')}</span>
            </div>
            <div id='Thursday' onClick={(e) => onClickDay(e)} className={classNameThursday}>
                <span>{_t.t('Чт')}</span>
            </div>
            <div id='Friday' onClick={(e) => onClickDay(e)} className={classNameFriday}>
                <span>{_t.t('Пт')}</span>
            </div>
            <div id='Saturday' onClick={(e) => onClickDay(e)} className={classNameSaturday}>
                <span>{_t.t('Сб')}</span>
            </div>
            <div id='Sunday' onClick={(e) => onClickDay(e)} className={classNameSunday}>
                <span>{_t.t('Вс')}</span>
            </div>
        </div>
    );
};

WeekBox.propTypes = {};
WeekBox.defaultProps = {};

export default WeekBox;