import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import ReactMarkdown from 'react-markdown/with-html';
import useParentSize from '../../tools/use_parent_size';

import '../../style/TableHotStyle.css';

const DataViewMarkDown = React.memo(({ ...props }) => {
    const [data, set_data] = useState(null);

    useEffect(() => {
        setTimeout(() => set_data(props.data), 1000);
    }, [props.data]);

    useEffect(() => {
        props.changeFinalSizes();
    }, [data]);

    const source =
        '[link text](http://dev.nodeca.com)\n\nI really like **bold text** using Markdown.\n\n> Dorothy followed her through many of the beautiful rooms in her castle.\n\n';

    return (
        <MainContainer>
            <ReactMarkdown
                // className={'MdBox'}
                source={data}
                // escapeHtml={false}
                // rawSourcePos={true}
            />
        </MainContainer>
    );
});

const MainContainer = styled.div`
    all: initial;
    width: 100%;
    box-sizing: border-box;
    padding: 0 13px;
    font-family: ProximaNova;
    color: rgb(36, 49, 56);
    height: auto;

    * {
        /* all: unset; */
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
    }
    h1 {
        font-size: 2em;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    pre {
        border: 1px solid #ccc;
        overflow: auto;
        font-family: monospace;
        /* white-space: pre; */
        margin: 1em 0px;
        width: 100%;
        padding: 0.5em;
    }
    .hljs {
        display: block;
        overflow-x: auto;
        padding: 0.5em;
        color: #333;
        background: #f8f8f8;
    }

    code,
    kbd,
    pre,
    samp {
        box-sizing: border-box;
        font-family: monospace, monospace;
        font-size: 1em;
        background: #f8f8f8;
    }
    blockquote {
        color: #666;
        margin: 0;
        padding-left: 1em;
        border-left: 0.5em #eee solid;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        th,
        td {
            padding: 6px 13px;
            border: 1px solid #dfe2e5;
        }
    }
`;

export default DataViewMarkDown;
