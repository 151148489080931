import PropTypes from 'prop-types'
import React from 'react'
import { findDOMNode } from 'react-dom'
import cn from 'classnames'

import styled from 'styled-components/macro'

import dates from './utils/dates'
import { notify } from './utils/helpers'
import { dateCellSelection, getSlotAtX, pointInBox } from './utils/selection'
import Selection, { getBoundsForNode, isEvent } from './Selection'
import ReactTooltip from "react-tooltip";
import { DateTime } from "luxon";

var moment = require('moment');
var store = require('./store.js');

const DivBoxTultip = styled.div`
    background: #000;
    display: block;
  z-index: 100000;
  overflow: visible;
`



class BackgroundCells extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selecting: false,
            x:0,
            y:0,
            teachingBubbleVisible: false
        }
    }

    componentDidMount() {
        this.props.selectable && this._selectable()
    }

    componentWillUnmount() {
        this._teardownSelectable()
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        ReactTooltip.rebuild()
        if (nextProps.selectable && !this.props.selectable) this._selectable()

        if (!nextProps.selectable && this.props.selectable)
            this._teardownSelectable()
    }

    processDate(date){
        let schedulesList = store.getState().schedulesList;
        let arrayScedules = []

        for (var i = 0; i < schedulesList.length; i++) {

            if (schedulesList[i].week_days.length === 7 && schedulesList[i].date_period.always === true) {
                arrayScedules.push(schedulesList[i])
            }
            else if (schedulesList[i].date_period.always === true && schedulesList[i].week_days.length < 7) {
                let arrayDayOfWeekNumber = []
                let objDayOfWeek = {
                    Monday:1,
                    Tuesday:2,
                    Wednesday:3,
                    Thursday:4,
                    Friday:5,
                    Saturday:6,
                    Sunday:0
                }
                for (var j = 0; j < schedulesList[i].week_days.length; j++) {
                    arrayDayOfWeekNumber.push(objDayOfWeek[schedulesList[i].week_days[j]])
                }
                // console.log('>>>>>>>>>>.', moment(date).day(), date, moment().day(schedulesList[i].week_days[j]), schedulesList[i].week_days[j])
                if (arrayDayOfWeekNumber.indexOf(moment(date).day()) !== -1) {
                    arrayScedules.push(schedulesList[i])
                }
            }
            else if (schedulesList[i].date_period.always === false && schedulesList[i].week_days.length === 7) {
                console.log('><>>>>>>>>>>', DateTime.fromFormat(schedulesList[i].date_period.to, 'yyyy.MM.dd').toMillis(), DateTime.fromFormat(schedulesList[i].date_period.from, 'yyyy.MM.dd').toMillis())
                if (DateTime.fromFormat(schedulesList[i].date_period.to, 'yyyy.MM.dd').toMillis() >= moment(date).valueOf() && DateTime.fromFormat(schedulesList[i].date_period.from, 'yyyy.MM.dd').toMillis() <= moment(date).valueOf()) {
                    arrayScedules.push(schedulesList[i])
                }
            }
            else if (schedulesList[i].date_period.always === false && schedulesList[i].week_days.length < 7) {
                let arrayDayOfWeekNumber = []
                let objDayOfWeek = {
                    Monday:1,
                    Tuesday:2,
                    Wednesday:3,
                    Thursday:4,
                    Friday:5,
                    Saturday:6,
                    Sunday:0
                }
                for (var j = 0; j < schedulesList[i].week_days.length; j++) {
                    arrayDayOfWeekNumber.push(objDayOfWeek[schedulesList[i].week_days[j]])
                }
                // console.log('>>>>>>>>>>.', moment(date).day(), date, moment().day(schedulesList[i].week_days[j]), schedulesList[i].week_days[j])
                if (arrayDayOfWeekNumber.indexOf(moment(date).day()) !== -1) {
                    if (DateTime.fromFormat(schedulesList[i].date_period.to, 'yyyy.MM.dd').toMillis() >= moment(date).valueOf() && DateTime.fromFormat(schedulesList[i].date_period.from, 'yyyy.MM.dd').toMillis() <= moment(date).valueOf()) {
                        arrayScedules.push(schedulesList[i])
                    }
                }
            }
        }


        return arrayScedules;


    }

    getTime(i) {
        let time = i * 5;
        let currentTime = {};
        if (time < 60) {
            currentTime['minuts'] = time;
            currentTime['hours'] = 0

        }
        else {
            currentTime['minuts'] = time % 60;
            currentTime['hours'] = Math.floor(time / 60)
        }
        // console.log('>>>>>>>>>>>>>>',currentTime )
        return currentTime;
    }

    correctDayTimeToStr(hours, minuts) {
        let totalMinuts = hours * 60 + minuts;
        let newHours = Math.floor(totalMinuts / 60);
        let newMinuts = totalMinuts - newHours * 60;
        return `${newHours >= 10 ? newHours : `0${newHours}`}:${newMinuts >= 10 ? newMinuts : `0${newMinuts}`}`;
    }

    createTultip(defaultPage, scheduleList,dataSchedulesLine) {
        let arrayTemp = new Array(288);
        arrayTemp.fill(defaultPage.id)
        let objTemp = {}
        objTemp[defaultPage.id] = defaultPage
        // console.log('>>>>>>>>>>.',scheduleList,dataSchedulesLine)
        if (scheduleList.length > 0) {
            for (var j = 0; j < dataSchedulesLine.length; j++) {
                objTemp[dataSchedulesLine[j].id] = dataSchedulesLine[j]
                if (dataSchedulesLine[j].time_period.always === true) {
                    arrayTemp.fill(dataSchedulesLine[j].id)
                }
                else {
                    // console.log('><><><><>>>>>', dataSchedulesLine[j])
                    if (dataSchedulesLine[j].time_period.from !== null && dataSchedulesLine[j].time_period.to !== null) {
                        let hoursFrom = +dataSchedulesLine[j].time_period.from.split(':')[0]
                        let minutsFrom = +dataSchedulesLine[j].time_period.from.split(':')[1]
                        let hoursTo = +dataSchedulesLine[j].time_period.to.split(':')[0]
                        let minutsTo = +dataSchedulesLine[j].time_period.to.split(':')[1]
                        let formLine = hoursFrom * 12 + minutsFrom
                        let toLine = hoursTo * 12 + minutsTo
                        for (var t = formLine; t < toLine; t++) {
                            arrayTemp[t] = dataSchedulesLine[j].id
                        }
                    }
                    else {
                        arrayTemp.fill(dataSchedulesLine[j].id)
                    }


                    // arrayTemp.fill(dataSchedulesLine[j].id)
                    // console.log('>>>>>>>>>>.',hoursFrom * 12 + minutsFrom,hoursTo * 12 + minutsTo)
                }
            }
        }
        let arrayTemp2 = []
        for (var i = 0; i < arrayTemp.length; i++) {
            if (i === 0) {
                arrayTemp2.push({
                    data: objTemp[arrayTemp[i]],
                    timeTo: '',
                    timeFrom: '00:00'
                })
            }
            else if (arrayTemp[i] === arrayTemp[i - 1]) {
                let time = this.getTime(i);

                arrayTemp2[arrayTemp2.length - 1].timeTo = this.correctDayTimeToStr(time.hours,time.minuts + 5)
            }
            else if (arrayTemp[i] !== arrayTemp[i - 1]) {
                let time = this.getTime(i);

                arrayTemp2.push({
                    data: objTemp[arrayTemp[i]],
                    timeTo: this.correctDayTimeToStr(time.hours,time.minuts + 5),
                    timeFrom: this.correctDayTimeToStr(time.hours, time.minuts)
                })
            }
        }
        // console.log('>>>>>>>>>>.', arrayTemp2)
        return arrayTemp2;
    }
    onEnter(e) {
        this.setState({
            x:e.pageX,
            y:e.pageY,
            teachingBubbleVisible: true
        });
    }
    hideTooltip(){
        this.setState({
            teachingBubbleVisible: false
        })
    }
    render() {
        let {
            range,
            getNow,
            getters,
            date: currentDate,
            components: { dateCellWrapper: Wrapper },
        } = this.props
        let { selecting, startIdx, endIdx } = this.state
        let current = getNow()


        return (
            <div className="rbc-row-bg">
                {range.map((date, index) => {
                    let selected = selecting && index >= startIdx && index <= endIdx
                    const { className, style } = getters.dayProp(date)
                    let dataSchedulesLine = this.processDate(date)
                    let dataTultip = this.createTultip(store.getState().defaultTemplate,store.getState().schedulesList, dataSchedulesLine)
                    // console.log('>>>>>>>>>>>>>>>>', dataTultip.length)
                    let componentTultip = dataTultip.map((data2, index2) => {

                        if (data2.data.preview !== undefined) {
                            data2.data['page_preview'] = data2.data.preview

                            data2.data['page_title'] = data2.data.title
                        }
                        return(
                            <div key={`ItemTultip${index2}`} className="itemTultip">
                                <span style={{
                                    background: data2.data.color,
                                    fontSize:'15px'
                                }} className="nameTultip">
                                    {data2.data.page_title !== undefined ? data2.data.page_title.length > 10 ? data2.data.page_title.substring(0,5) + '...' + data2.data.page_title.substring(data2.data.page_title.length - 4,data2.data.page_title.length)  : data2.data.page_title : null}
                                </span>
                                <img src={data2.data.page_preview} alt=""/>

                                <span className="time">
                                    {`${data2.timeFrom}  ${data2.timeTo}`}
                                </span>
                            </div>
                        )
                    })
                    let componentLine = dataSchedulesLine.map((data2, index2) => {
                        // console.log('>>>>>>>>>>.',data)

                        return (<div key={`lineCalendar${index2}`} className={'lineCalendar'} style={{
                            height: dataSchedulesLine.length >= 5 ? '2px': dataSchedulesLine.length <= 3 ? '3px' : '2px',
                            background:data2.color
                        }}></div>)
                    })
                    return (

                            <Wrapper key={`global1221${index}`} value={date} range={range}>
                                <DivTemp22
                                    style={style}
                                    className={cn(
                                        'rbc-day-bg',
                                        className,
                                        selected && 'rbc-selected-cell',
                                        dates.eq(date, current, 'day') && 'rbc-today',
                                        currentDate &&
                                        dates.month(currentDate) !== dates.month(date) &&
                                        'rbc-off-range-bg'
                                    )}

                                >






                                    {/*<DivTemp className={'tempDiv'}>*/}
                                    {/*    <DivBoxTultip*/}
                                    {/*        className="boxTultip">*/}
                                    {/*        {componentTultip}*/}
                                    {/*    </DivBoxTultip>*/}
                                    {/*</DivTemp>*/}


                                    <div className="boxLine">
                                        <a  data-tip data-for={`global${index}${this.props.weekIdx}`}
                                        >
                                            {componentLine}

                                        </a>


                                    </div>
                                    {/*<DivTemp>*/}
                                    {/*    <ReactTooltip*/}
                                    {/*        id={`global${index}${this.props.weekIdx}`}*/}
                                    {/*        aria-haspopup="true"*/}
                                    {/*        className="reacttooltip"*/}
                                    {/*        // role="example"*/}
                                    {/*        // effect="solid"*/}
                                    {/*        place="left"*/}
                                    {/*    >*/}
                                    {/*        <DivBoxTultip*/}
                                    {/*            className="boxTultip">*/}
                                    {/*            {componentTultip}*/}
                                    {/*        </DivBoxTultip>*/}
                                    {/*    </ReactTooltip>*/}
                                    {/*</DivTemp>*/}

                                </DivTemp22>
                            </Wrapper>


                    )
                })}
            </div>
        )
    }

    _selectable() {
        let node = findDOMNode(this)
        let selector = (this._selector = new Selection(this.props.container, {
            longPressThreshold: this.props.longPressThreshold,
        }))

        let selectorClicksHandler = (point, actionType) => {
            if (!isEvent(findDOMNode(this), point)) {
                let rowBox = getBoundsForNode(node)
                let { range, rtl } = this.props

                if (pointInBox(rowBox, point)) {
                    let currentCell = getSlotAtX(rowBox, point.x, rtl, range.length)

                    this._selectSlot({
                        startIdx: currentCell,
                        endIdx: currentCell,
                        action: actionType,
                        box: point,
                    })
                }
            }

            this._initial = {}
            this.setState({ selecting: false })
        }

        selector.on('selecting', box => {
            let { range, rtl } = this.props

            let startIdx = -1
            let endIdx = -1

            if (!this.state.selecting) {
                notify(this.props.onSelectStart, [box])
                this._initial = { x: box.x, y: box.y }
            }
            if (selector.isSelected(node)) {
                let nodeBox = getBoundsForNode(node)
                ;({ startIdx, endIdx } = dateCellSelection(
                    this._initial,
                    nodeBox,
                    box,
                    range.length,
                    rtl
                ))
            }

            this.setState({
                selecting: true,
                startIdx,
                endIdx,
            })
        })

        selector.on('beforeSelect', box => {
            if (this.props.selectable !== 'ignoreEvents') return

            return !isEvent(findDOMNode(this), box)
        })

        selector.on('click', point => selectorClicksHandler(point, 'click'))

        selector.on('doubleClick', point =>
            selectorClicksHandler(point, 'doubleClick')
        )

        selector.on('select', bounds => {
            this._selectSlot({ ...this.state, action: 'select', bounds })
            this._initial = {}
            this.setState({ selecting: false })
            notify(this.props.onSelectEnd, [this.state])
        })
    }

    _teardownSelectable() {
        if (!this._selector) return
        this._selector.teardown()
        this._selector = null
    }

    _selectSlot({ endIdx, startIdx, action, bounds, box }) {
        if (endIdx !== -1 && startIdx !== -1)
            this.props.onSelectSlot &&
            this.props.onSelectSlot({
                start: startIdx,
                end: endIdx,
                action,
                bounds,
                box,
            })
    }
}

BackgroundCells.propTypes = {
    date: PropTypes.instanceOf(Date),
    getNow: PropTypes.func.isRequired,

    getters: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,

    container: PropTypes.func,
    dayPropGetter: PropTypes.func,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    longPressThreshold: PropTypes.number,

    onSelectSlot: PropTypes.func.isRequired,
    onSelectEnd: PropTypes.func,
    onSelectStart: PropTypes.func,

    range: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    rtl: PropTypes.bool,
    type: PropTypes.string,
}

export default BackgroundCells

const DivTemp = styled.div`
    //display: none;
`;

const DivTemp22 = styled.div`
    :hover {
      .tempDiv {
        display: block;
      }
    }
  
`