import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import { DiagramsContext } from '../index';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import ContextMenu from '../../context_menu/context_menu';

export default React.memo(({
                               title,
                               group,
                               editable = false,
                               margin = '6px 16px 0 16px',
                               statusAlone = false,
                                modeCabinet = 'notPdf',
                               ...props
}) => {
    const [theme] = useState(getTheme());
    const { t } = useTranslation();
    const {
        currentSection,
        currentSubSection,
        sectionsByKeyName,
        lang,
        dataViewsByGroupID,
        toggleModal,
        permanentCloudData,
        groupIDsBySubsectionKeyName,
        onEditGroupClick,
        onMoveGroupClick,
    } = useContext(DiagramsContext);

    const subsection = sectionsByKeyName[currentSection].subsections.filter((item) => item.key_name === currentSubSection)[0];

    const menuItems = [
        {
            key: 'rename',
            text: t('Переименовать группу'),
            onClick: () => {
                toggleModal({
                    show: true,
                    modalType: 'rename_group',
                    modalTitle: t('Переименовать группу'),
                    modalText: '',
                    height: 'auto',
                    width: '50%',
                    modalArgs: {
                        name: '',
                        id: '',
                        props: { group, subsection },
                    },
                });
            },
        },
        {
            key: 'add',
            text: t('Добавить новую группу'),
            onClick: () => {
                toggleModal({
                    show: true,
                    modalType: 'new_group',
                    modalTitle: t('Добавить новую группу'),
                    modalText: '',
                    height: 'auto',
                    width: '50%',
                    modalArgs: {
                        name: '',
                        id: '',
                        props: { subsection },
                    },
                });
            },
        },
    ];

    if (subsection.data_view_groups.length > 1) {
        menuItems.push({
            key: 'delete',
            text: t('Удалить группу'),
            onClick: () => {
                toggleModal({
                    show: true,
                    modalType: 'delete_group',
                    modalTitle: t('Удалить группу?'),
                    modalText: '',
                    height: 'auto',
                    width: '50%',
                    modalArgs: {
                        name: '',
                        id: '',
                        props: { groupID: group.id, subsectionID: subsection.id },
                    },
                });
            },
        });
    }

    const _onEditGroupClick = (e) => {
        onEditGroupClick({ groupID: group.id, subsectionID: group.subsection_id });
    };

    const _onAcceptGroupClick = (e) => {
        onEditGroupClick();
    };

    const _onAddClick = (e) => {
        toggleModal({
            show: true,
            modalType: 'add_diagram',
            modalTitle: t('Добавить диаграмму в My dashboard'),
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: {
                    sectionsByKeyName,
                    dataViewsByGroupID,
                    permanentCloudData,
                    currentSubSection,
                    groupIDsBySubsectionKeyName,
                    group,
                    lang,
                },
            },
        });
    };

    const _onMoveGroupClick = (direction) => (e) => {
        onMoveGroupClick({ direction, groupID: group.id, subsectionID: group.subsection_id });
    };

    const colors = { primary: theme.palette.themePrimary, dark: theme.palette.themeDark };

    return currentSection === 'custom_section' ? (
        <ContextMenu rightClick={true} menuItems={menuItems} margin={'0'} cursor={true}>
            <Container margin={margin} showFinger={true}
                       modeCabinet={modeCabinet}
            >
                <h2>{title}</h2>
                {!statusAlone ? (editable ? (
                    <IconWrapper colors={colors}>
                        {/* <ArrowsWrapper>
                            <StIcon className="UpArrow" iconName="FlickDown" onClick={_onMoveGroupClick('up')} />
                            <StIcon className="DownArrow" iconName="FlickUp" onClick={_onMoveGroupClick('down')} />
                        </ArrowsWrapper>
                        <StIcon className="AcceptGroup" iconName="SkypeCheck" onClick={_onAcceptGroupClick} /> */}
                        <StIcon className="Arrow" iconName="ChevronDown" onClick={_onMoveGroupClick('down')} />
                        <StIcon className="Arrow" iconName="ChevronUp" onClick={_onMoveGroupClick('up')} />
                        <StIcon className="Add" iconName="CalculatorAddition" onClick={_onAddClick} />
                        <StIcon className="AcceptGroup" iconName="Accept" onClick={_onAcceptGroupClick} />
                    </IconWrapper>
                ) : (
                    <StIcon className="EditGroup" iconName="EditSolid12" onClick={_onEditGroupClick} />
                )) : null}
            </Container>
        </ContextMenu>
    ) : (
        <Container margin={margin}>
            <h2>{title}</h2>
        </Container>
    );
});

const Container = styled.div`
    display: flex;
    cursor: ${(p) => (p.showFinger ? 'pointer' : 'auto')};
    box-sizing: border-box;
    width: ${(p) => ( p.modeCabinet === 'notPdf' ? 'calc(100% - 30px)' : 'calc(100% - 9px)') };
    border-bottom: 1px solid #99ccae;
    padding-bottom: 5px;
    margin: ${(p) => p.margin};
    h2 {
        box-sizing: border-box;
        font-family: ProximaNova;
        font-size: 17px;
        color: rgb(36, 49, 56);
        padding: 0;
        margin: 0;
    }
    .AcceptGroup {
        /* font-size: 18px; */
        margin-left: 3px;
    }
    .EditGroup {
        opacity: 0.2;
        pointer-events: none;
        transition: opacity 0.25s ease-in-out;
        margin-left: 5px;
    }
    :hover {
        .EditGroup {
            opacity: 0.6;
            pointer-events: auto;
            transition: opacity 0.25s ease-in-out;
            :active {
                opacity: 0.8;
            }
        }
    }
`;

const StIcon = styled(Icon)`
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    :active {
        font-size: 12px;
    }
`;

const IconWrapper = styled.div`
    margin-left: 5px;
    display: flex;
    i {
        font-weight: 700;
        color: ${(p) => p.colors.primary};
        :hover {
            color: ${(p) => p.colors.dark};
        }
    }
`;

const ArrowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 20px;
    height: 20px;

    .UpArrow {
        top: -4px;
    }
    .DownArrow {
        top: 12px;
    }
    i {
        left: 0;
        position: absolute;
    }
`;
