import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo } from 'react';
import Tools, { calculateAverage } from '../../tools/diagramTools';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

import AvgLineLabel from '../average_line_label';
import CustomLegend from '../custom_legend';
import CustomTooltip from '../custom_tooltip';

export default React.memo(({ ...props }) => {
    const { t } = useTranslation();
    const [lines, setLines] = useState([]);
    const [data, setData] = useState([]);
    const [avgLines, setAvgLines] = useState([]);
    const [showAverages, setShowAverages] = useState(false);
    const [hideComparison, setHideComparison] = useState(false);

    const isAggregation = props.data.data.length !== 0 && props.data.data[0].data_aspects.object.relation === 'aggregation';

    useEffect(() => {
        try {
            const data =
                props.dataView.data_report.data_type.key_name !== 'distribution_over_objects'
                    ? props.data.x.values.map((x, i) => {
                          const point = {
                              x: x,
                          };
                          props.data.data.forEach((item) => {
                              if (item.data_aspects.object.relation === 'aggregation') {
                                  const isMainRange = item.data_aspects.time.date_range_type === 'main_date_range';
                                  const key = isMainRange ? 'Отчетный период' : 'Период сравнения';
                                  const avgKey = isMainRange ? 'avgMain' : 'avgCompare';
                                  point[key] = Number(item.values[i].toFixed(2));
                                  point[avgKey] = Number(calculateAverage(item.values));
                              } else {
                                  point[item.data_aspects.object.associations[0].name] = Number(item.values[i].toFixed(2));
                              }
                          });
                          return point;
                      })
                    : null;
            setData((prev) => data);

            const avgLines = !isAggregation
                ? undefined
                : props.data.data.map((item) => {
                      const rangeType = item.data_aspects.time.date_range_type;
                      const value = calculateAverage(item.values.map((item) => ({ value: item })));
                      const attributes =
                          rangeType === 'main_date_range'
                              ? {
                                    text: `avgMain`,
                                    color: 'rgb(60, 100, 153)',
                                    position: 'end',
                                }
                              : {
                                    text: `avgCompare`,
                                    color: 'rgb(244, 155, 16)',
                                    position: 'start',
                                };

                      return { value, ...attributes };
                  });

            setAvgLines((prev) => avgLines);

            const showAverages = props.indicators.filter((item) => item.name === 'Средняя линия')[0].show;
            setShowAverages(showAverages);

            const hideComparison = props.dataView.data_report.meta.data ? props.dataView.data_report.meta.data.hide_comparison : true;

            setHideComparison(hideComparison);

            const lines = Object.keys(data[0])
                .filter((key) => key !== 'x' && key !== 'avgMain' && key !== 'avgCompare')
                .map((line) => {
                    return {
                        color:
                            line === 'Отчетный период'
                                ? 'rgb(60, 100, 153)'
                                : line === 'Период сравнения'
                                ? 'rgb(244, 155, 16)'
                                : Tools.generateColorForObjects(line),
                        name: line,
                        show: line === 'Период сравнения' ? !hideComparison : true,
                        enlarge: false,
                        dim: false,
                    };
                });
            setLines([...lines]);
        } catch (error) {}
    }, [props.data, props.dataView, props.indicators, props.lang]);

    const axisStyle = {
        fontSize: 10,
    };

    const margin = { top: 0, right: 10, bottom: 0, left: -20 };

    const onLegendItemClick = (line) => {
        const l = lines.map((item) => {
            return item.name !== line.name ? item : { ...item, show: !item.show };
        });
        setLines((prev) => l);
    };

    const generateTooltip = ({ payload, label }) => {
        const tooltip = payload
            ? payload.map((item) => {
                  return {
                      ...item,
                      x: label,
                      color: item.fill,
                  };
              })
            : {};
        return <CustomTooltip tooltip={tooltip} chartId={props.dataView.data_view_id} />;
    };

    return useMemo(() => {
        return (
            <ResponsiveContainer width={'100%'} height={props.size.height - 45}>
                <BarChart margin={margin} barGap={0} barCategoryGap="20%" data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" style={axisStyle} />
                    <YAxis style={axisStyle} />
                    <Tooltip content={generateTooltip} />
                    {/* <Tooltip content={<VerticalBarTooltip/>} /> */}
                    <Legend
                        height={20}
                        content={
                            <CustomLegend
                                isAggregation={isAggregation}
                                onLegendItemClick={onLegendItemClick}
                                onLegendItemEnter={() => {}}
                                onLegendItemOut={() => {}}
                                lines={lines}
                                showAverages={showAverages}
                                avgLines={avgLines}
                                chartId={props.dataView.data_view_id}
                                data={data}
                            />
                        }
                        wrapperStyle={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 5 }}
                        style={axisStyle}
                    />

                    {lines
                        .filter((item) => item.show)
                        .map((line) => {
                            return <Bar key={`Bar-${line.name}-${props.dataView.data_view_id}`} dataKey={line.name} fill={line.color} />;
                        })}

                    {avgLines &&
                        showAverages &&
                        avgLines.map((item) => {
                            return (
                                <ReferenceLine
                                    key={`ReferenceLine-${item.color}-${props.dataView.data_view_id}`}
                                    stroke={item.color}
                                    y={Number(item.value)}
                                    label={<AvgLineLabel color={item.color} position={item.position} value={item.value} />}
                                />
                            );
                        })}
                </BarChart>
            </ResponsiveContainer>
        );
    }, [props.size.height, data, showAverages, lines]);
});
