const initState = {
    tree: {
        mainExpand: false,
        ownersExpand: null,
    },
    projectSearchValue: '',
    isAdCampShown: false,
    showAdSpacesMapPanel: false,
    singleSelection: null,
    multiSelection: [],
    staticAdCampSelectedSpaces: [],
    campaignsToShow: 15,
    addCampaignsNumber: 10,
};

const toggleAdCampaigns_AdCampaigns = (state, action) => {
    return { ...state, isAdCampShown: !state.isAdCampShown };
};

const onShowMoreClick_AdCampaigns = (state, action) => {
    const campaignsToShow = state.campaignsToShow + state.addCampaignsNumber;
    return { ...state, campaignsToShow };
};

const onShowReset_AdCampaigns = (state, action) => {
    const campaignsToShow = initState.campaignsToShow;
    return { ...state, campaignsToShow };
};

const feelTree_AdCampaigns = (state, action) => {
    const { ownersExpand } = action.payload;
    return { ...state, tree: { ...state.tree, ownersExpand } };
};

const toggleExpandArrow_AdCampaigns = (state, action) => {
    const { id, show } = action.payload;
    const ownersExpand = {
        ...state.tree.ownersExpand,
        [id]: { ...state.tree.ownersExpand[id], expand: show === undefined ? !state.tree.ownersExpand[id].expand : show },
    };
    return { ...state, tree: { ...state.tree, ownersExpand } };
};

const onSelectClick_AdCampaigns = (state, action) => {
    const { args, paramsSet } = action.payload;
    if (!paramsSet) return state;
    if (paramsSet.custom.includes('ad_campaign_id')) {
        return { ...state, singleSelection: args };
    } else if (paramsSet.custom.includes('ad_campaign_ids')) {
        return { ...state };
    } else {
        return { ...state };
    }
};

const onChangeProjectSearchValue_AdCampaigns = (state, action) => {
    const { projectSearchValue } = action.payload;
    return { ...state, projectSearchValue };
};

const toggleAdSpacesMapPanel_AdCampaigns = (state, action) => {
    const { show } = action.payload;
    return { ...state, showAdSpacesMapPanel: show };
};

const selectAdSpace_AdCampaigns = (state, action) => {
    const { spaceId } = action.payload;
    const { staticAdCampSelectedSpaces } = state;
    if (staticAdCampSelectedSpaces.indexOf(spaceId) === -1) {
        staticAdCampSelectedSpaces.push(spaceId);
    } else {
        staticAdCampSelectedSpaces.splice(staticAdCampSelectedSpaces.indexOf(spaceId), 1);
    }
    return { ...state, staticAdCampSelectedSpaces: [...staticAdCampSelectedSpaces] };
};

const resetStaticAdCampSelectedSpaces_AdCampaigns = (state, action) => {
    return { ...state, staticAdCampSelectedSpaces: [] };
};

const fillStaticAdCampSelectedSpaces_AdCampaigns = (state, action) => {
    if (!action.payload.spaces) return state;
    const staticAdCampSelectedSpaces = action.payload.spaces.map((item) => item.id);
    return { ...state, staticAdCampSelectedSpaces };
};

const AdCampaigns = (state = initState, action) => {
    switch (action.type) {
        case 'toggleAdCampaigns_AdCampaigns':
            return toggleAdCampaigns_AdCampaigns(state, action);
        case 'onSelectClick_AdCampaigns':
            return onSelectClick_AdCampaigns(state, action);
        case 'onShowMoreClick_AdCampaigns':
            return onShowMoreClick_AdCampaigns(state, action);
        case 'onShowReset_AdCampaigns':
            return onShowReset_AdCampaigns(state, action);
        case 'feelTree_AdCampaigns':
            return feelTree_AdCampaigns(state, action);
        case 'toggleExpandArrow_AdCampaigns':
            return toggleExpandArrow_AdCampaigns(state, action);
        case 'onChangeProjectSearchValue_AdCampaigns':
            return onChangeProjectSearchValue_AdCampaigns(state, action);
        case 'toggleAdSpacesMapPanel_AdCampaigns':
            return toggleAdSpacesMapPanel_AdCampaigns(state, action);
        case 'selectAdSpace_AdCampaigns':
            return selectAdSpace_AdCampaigns(state, action);
        case 'resetStaticAdCampSelectedSpaces_AdCampaigns':
            return resetStaticAdCampSelectedSpaces_AdCampaigns(state, action);
        case 'fillStaticAdCampSelectedSpaces_AdCampaigns':
            return fillStaticAdCampSelectedSpaces_AdCampaigns(state, action);
        default:
            return state;
    }
};

export default AdCampaigns;
