import React from 'react';
import cn from 'classnames';

import '../styles/complete_tables.scss';

class TreeGroupItem extends React.PureComponent {
  render() {
    const treeGroupItemClass = cn({
      TreeGroupItem: true
    });
    return (
      <div className={treeGroupItemClass}>
        <h4>{this.props.groupName}</h4>
        {this.props.children}
      </div>
    );
  }
}

export default TreeGroupItem;
