import React, { Component, useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import '../style/LeftPanelStyle.scss';
import LeftPanelButtons from './buttonsListLeftPanel';
import { connect } from 'react-redux';
import { runAction } from '../../../actions';
import PlPanel from '../pl_panel/pl_panel';
// import BoxInputParameters from '../../global_options/components/box_input_parameters';
import Tools from '../../../tools/tools';
let dateCompareTemp = null;

const Container = styled.div`
    background-color: ${(p) => p.bgColor};
    position: relative;
    flex-grow: 3;
    width: 238px;
    height: calc(100vh - 50px);
    flex-grow: 3;
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
`;

const LeftPanel = ({
    status,
    onClickButton,
    statusShowPanelCalendar,
    _t,
    statusSelectDashAllObject,
    lang,
    statusLeftPanel,
    statusTopPanel,
    selected,
    selected2,
    getDateGlobal,
    groupIDsBySubsectionKeyName,
    dataViewsByGroupID,
    inputParameters,
    onInputParameterChange,
    globalInputParamsValues,
    arrayLocationSelectMulti,
    arrayLocationSelectNotMulti,
    locations,
    input_parameter_list,
    reportCatalog,
    showLocationsSelectionPanel,
    whiteLabelSettings,
    pl_id,
    pl_ids,
    obj_id,
    obj_ids,
    ...props
}) => {
    getDateGlobal(selected, selected2, statusShowPanelCalendar);

    // reqInput.indexOf('pl_ids') !== -1 ||
    // reqInput.indexOf('pl_id') !== -1 ||
    // reqInput.indexOf('indoor_ad_space_ids') !== -1 ||
    // reqInput.indexOf('outdoor_ad_space_ids') !== -1;

    const bgColor = whiteLabelSettings ? whiteLabelSettings.left_panel_color : '#fff';

    return (
        <Container bgColor={bgColor}>
            <LeftPanelButtons
                isMapExpanded={props.isMapExpanded}
                isAdCampShown={props.isAdCampShown}
                toggleModal={props.toggleModal}
                showFavoritesOnly={props.showFavoritesOnly}
                onShowFavoritesOnlyClick={props.onShowFavoritesOnlyClick}
                disabled={showLocationsSelectionPanel}
                statusLeftPanel={statusLeftPanel}
                statusTopPanel={statusTopPanel}
                lang={lang}
                _t={_t}
                sectionsByKeyName={props.sectionsByKeyName}
                status={status}
                statusSelectDashAllObject={statusSelectDashAllObject}
                onClick={onClickButton}
                selected2={selected2}
                arrayLocationSelectMulti={arrayLocationSelectMulti}
                arrayLocationSelectNotMulti={arrayLocationSelectNotMulti}
                locations={locations}
                input_parameter_list={input_parameter_list}
                selected={selected}
                reportCatalog={reportCatalog}
                whiteLabelSettings={whiteLabelSettings}
                pl_id={pl_id}
                pl_ids={pl_ids}
                obj_id={obj_id}
                obj_ids={obj_ids}
                onFavoritesStarClick={props.onFavoritesStarClick}
            />

            <PlPanel lang={lang} _t={_t} />
        </Container>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        isAdCampShown: state.AdCampaigns.isAdCampShown,
        isMapExpanded: state.OutdoorSpaces.isMapExpanded,
        dataObjSets: state.ReportParametersManager.Source.src.dataobjsets,
        status: state.TopPanel.statusLeftPanel,
        statusShowPanelCalendar: state.CalendarApp.statusShowCalendar,
        statusSelectDashAllObject: state.PanelLocationApp.statusSelectDashAllObject,
        sectionsByKeyName: state.TopPanel.sectionsByKeyName,
        showFavoritesOnly: state.TopPanel.showFavoritesOnly,
        reqInput: state.TopPanel.reqInput,
        statusLeftPanel: state.TopPanel.statusLeftPanel,
        statusTopPanel: state.TopPanel.status,
        arrayLocationSelectMulti: state.PanelLocationApp.arrayLocationSelectMulti,
        arrayLocationSelectNotMulti: state.PanelLocationApp.arrayLocationSelectNotMulti,
        locations: state.PanelLocationApp.locations,
        selected: state.CalendarApp.selected,
        selected2: state.CalendarApp.selected2,
        input_parameter_list: state.ReportParametersManager.input_parameter_list,
        groupIDsBySubsectionKeyName: state.ReportParametersManager.groupIDsBySubsectionKeyName,
        dataViewsByGroupID: state.ReportParametersManager.dataViewsByGroupID,
        inputParameters: state.ReportParametersManager.inputParameters,
        globalInputParamsValues: state.ReportParametersManager.globalInputParamsValues,
        reportCatalog: state.ReportParametersManager.reportCatalog,
        showLocationsSelectionPanel: state.LocationsPanel.showLocationsSelectionPanel,
        whiteLabelSettings: state.LoginApp.white_label_settings,
        pl_id: state.ReportParametersManager.customInputParamsValues.pl_id,
        pl_ids: state.ReportParametersManager.customInputParamsValues.pl_ids,
        obj_id: state.ReportParametersManager.customInputParamsValues.obj_id,
        obj_ids: state.ReportParametersManager.customInputParamsValues.obj_ids,
        outdoor_ad_space_ids: state.ReportParametersManager.customInputParamsValues.outdoor_ad_space_ids,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onShowFavoritesOnlyClick: (showFavoritesOnly) => {
            dispatch(runAction('onShowFavoritesOnlyClick', { showFavoritesOnly }));
        },
        changeCustomParams(params) {
            dispatch(runAction('changeCustomParams_RPM', { params }));
            dispatch(runAction('toggleModal', { args: { show: false } }));
        },
        toggleModal(args) {
            dispatch(runAction('toggleModal', { args }));
        },
        onFavoritesStarClick: (item, currentSection) => {
            dispatch(runAction('onFavoritesStarClick', { id: item.id, currentSection }));
        },

        onClickButton: (data) => {
            dispatch(runAction('onSelectLeftPanel', { data: data }));
            dispatch(runAction('changeSectionHistory_TopPanelHotspotApp', { ...data }));
            dispatch(runAction('hideSettingsPanel'));
            dispatch(runAction('onShowTablesButtonClick', { isShown: false }));

            dispatch(
                runAction('selectLeftPanelButtonList', {
                    status: data.key_name,
                    reqInput: data.required_input_parameters,
                    data: data,
                })
            );
            dispatch(runAction('fullScreenExit'));
        },

        getDateGlobal: (date, dateCompare, statusShowPanelCalendar) => {
            let nextDateCompareTemp = [date, dateCompare];
            if (statusShowPanelCalendar === false) {
                if (Tools.compare2(nextDateCompareTemp, dateCompareTemp) === false) {
                    dateCompareTemp = nextDateCompareTemp;

                    setTimeout(() => {
                        dispatch(runAction('getDateGlobal', { selected: date, selected2: dateCompare }));
                    }, 10);
                }
            }
        },
    };
};

LeftPanel.propTypes = {
    onClickButton: PropTypes.func.isRequired,
    statusShowPanelCalendar: PropTypes.bool.isRequired,
};

const ConnectLeftPanel = connect(mapStateToProps, mapDispatchToProps)(LeftPanel);

export default ConnectLeftPanel;
