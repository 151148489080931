import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class PlItem extends PureComponent {
    render() {

        return (
            <div className={'plItem'}>
                <span>{this.props.data.name}</span>
            </div>
        );
    }
}

PlItem.propTypes = {};

export default PlItem;