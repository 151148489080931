import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import createReportParams from '../../../xlsx/tools/create_report_params';
import { DiagramsContext } from '../../index';
import styled from 'styled-components/macro';
import PdfPage from './pdf_page';
import PdfContent from './pdf_content';
import { getIterations } from '../diagram';
import Group from "../group";
import CustomControl from "../custom_controls/CustomControl";
import GroupTitle from "../group_title";
import PdfContentTemp from "./pdf_content_temp";




const PagesList = React.memo(() => {
    // const pdfExportComponent = React.createRef(null);
    const props = useContext(DiagramsContext);
    const { t } = useTranslation();
    const [platePositions, setPlatePositions] = useState({});
    const [reportPositions, setReportPositions] = useState({});
    const [report, setReport] = useState([]);
    const [multiplePages, setMultiplePages] = useState(false);
    const [counter, setCounter] = useState(0);
    const [pages, setPages] = useState([]);
    const {
        groups,
        lang,
        cabinetMode,
        dataViewsByGroupID,
        fullScreenId,
        currentSubSection,
        diagramOptionsByID,
        currentSection,
        sectionsByKeyName,
    } = useContext(DiagramsContext);

    useEffect(() => {
        if (Object.keys(props.reportParams).length !== 0) {
            const report = createReportParams('', props.reportParams, props.input_parameter_list, props.lang, props.locationsСatalog, {
                currentSection: props.currentSection,
                currentSubSection: props.currentSubSection,
                sectionsByKeyName: props.sectionsByKeyName,
                projectLocationsById: props.projectLocationsById,
                outdoorAdSpacesById: props.outdoorAdSpacesById,
                customParamsSet: props.customParamsSet,
            });

            setReport(report);
        }
    }, [
        props.reportParams,
        props.input_parameter_list,
        props.locationsСatalog,
        props.projectLocationsById,
        props.outdoorAdSpacesById,
        props.lang,
    ]);

    const getReportItemEndPosition = (data) => {
        const pos = reportPositions;
        pos[data.id] = data;
        setReportPositions({ ...pos });
    };

    const getPlateEndPosition = (data) => {
        if (!data) return;
        const pos = platePositions;
        if (!pos[data.groupId]) {
            pos[data.groupId] = {};
        }
        pos[data.groupId][data.plateId] = data;

        setPlatePositions({ ...pos });
    };

    const getFlatStruct = () => {
        if (props.groups === undefined || !props.reportData) return [];

        const flatStruct = [];
        let index = -1;
        let selected = '';

        report.forEach((item, i) => {
            if (item[0] === t('Выбранные локации') || item[0] === t('Выбранные рекламные поверхности')) {
                index = i;
                selected = item[0];
            }
        });

        if (index !== -1) {
            const reportParams = report.filter((item, i) => i < index).filter((item) => item.length !== 0);
            const reportLocations = report.filter((item, i) => i > index);

            flatStruct.push({
                groupId: 'Report Params',
                type: 'reportTitle',
                title: t('Параметры отчета'),
            });

            reportParams.forEach((item, i) => {
                flatStruct.push({
                    type: 'reportString',
                    item,
                    id: `${item[0]} - ${i}`,
                });
            });

            flatStruct.push({
                groupId: 'Report Locations',
                type: 'reportTitle',
                title: selected,
            });

            reportLocations.forEach((item, i) => {
                flatStruct.push({
                    type: 'reportString',
                    item,
                    id: `${item[0]} - ${i}`,
                });
            });
        }
        // console.log('>>>>><<>>>>>22', props.groups.groups)
        props.groups.groups.forEach((item) => {
            const diagramsGroup = props.dataViewsByGroupID[item.id];
            const groupId = item.id;
            let title = item.translations[props.lang].name;
            flatStruct.push(
                item.custom_key_name === null || item.custom_key_name === ''
                    ? {
                          groupId,
                          type: 'groupTitle',
                          title,
                      }
                    : null
            );
            diagramsGroup &&
                diagramsGroup.forEach((dataView, indx, arr) => {
                    const report = props.reportData[dataView.data_view_id];
                    let dataRecieved = false;

                    if (report && report?.response && report?.isSuccess) {
                        dataRecieved = true;
                    } else if (props.reportData && report && report?.response === undefined) {
                        dataRecieved = true;
                    } else if (report && !report.isSuccess && report.error) {
                        dataRecieved = true;
                    } else {
                        dataRecieved = false;
                    }

                    const { iterationsNumber, padding } = getIterations(dataView, report);
                    for (let i = 0; i < iterationsNumber; i++) {
                        flatStruct.push({
                            i,
                            dataRecieved,
                            iterationsNumber,
                            groupId,
                            dataView,
                            padding,
                            type: 'diagram',
                            id: `pdfReportDataView${dataView.data_view_id}-${i}`,
                        });
                    }
                    const isLastInLine = arr[indx + 1] && arr[indx + 1].on_new_line ? true : false;
                    if (isLastInLine) {
                        flatStruct.push({ type: 'lineBreak' });
                    }
                });
        });

        if (!multiplePages && flatStruct.filter((item) => item.type === 'diagram').filter((item) => !item.dataRecieved).length === 0) {
            // console.log('>>>>>>>>', flatStruct);
            setTimeout(() => {
                setMultiplePages(true);
            }, 3000);
        }

        return flatStruct;
    };

    const flatStruct = useMemo(() => {
        return getFlatStruct();
    }, [props.groups]);

    useEffect(() => {
        // console.log(flatStruct);

        if (!multiplePages || (pages.length > 1 && counter > 10)) return;
        const pagesArr = [[]];
        let count = 0;
        const groupsHeights = {};
        const pos = platePositions;
        if (Object.keys(pos).length !== 0) {
            Object.keys(pos).forEach((groupId) => {
                const sorted = Object.keys(pos[groupId])
                    .map((id) => pos[groupId][id])
                    .sort((a, b) => a.position - b.position);
                const groupHeight = sorted[sorted.length - 1].position - sorted[0].position + sorted[0].height;
                groupsHeights[groupId] = groupHeight;
            });
        }
        flatStruct.forEach((element, i, arr) => {
            if (element.type === 'diagram') {
                const verticalMargin = element.iterationsNumber === 1 ? 16 : 0;
                const position = (element) => {
                    return platePositions[element.groupId] && platePositions[element.groupId][element.id]
                        ? platePositions[element.groupId][element.id]
                        : null;
                };
                const isNextInRow =
                    arr[i - 1].type === 'diagram' &&
                    position(element) &&
                    position(arr[i - 1]) &&
                    position(element).position === position(arr[i - 1]).position;

                if ((position(element) && count + position(element).height < props.WORK_HEIGHT) || isNextInRow) {
                    pagesArr[pagesArr.length - 1].push(element);
                    if (!isNextInRow) {
                        count = count + position(element).height + verticalMargin;
                    }
                } else if (position(element)) {
                    pagesArr.push([element]);
                    count = position(element).height;
                }
            } else if (element.type === 'groupTitle') {
                if (
                    pagesArr[pagesArr.length - 1].length === 0 ||
                    // (
                    count < props.WORK_HEIGHT / 2
                    // groupsHeights[element.groupId] > props.WORK_HEIGHT - count)
                    // (count < props.WORK_HEIGHT / 3 && groupsHeights[element.groupId] < props.WORK_HEIGHT / 2)
                ) {
                    pagesArr[pagesArr.length - 1].push(element);
                    count = count + 50;
                } else {
                    pagesArr.push([element]);
                    count = 50;
                }
            } else if (element.type === 'reportTitle') {
                pagesArr[pagesArr.length - 1].push(element);
                count = count + 50;
            } else if (element.type === 'reportString') {
                if (count < props.WORK_HEIGHT && reportPositions[element.id]) {
                    pagesArr[pagesArr.length - 1].push(element);
                    // console.log('>>>>> ', reportPositions[element.id], element);
                    count = count + reportPositions[element.id].height;
                } else if (count >= props.WORK_HEIGHT && reportPositions[element.id]) {
                    pagesArr.push([element]);
                    count = reportPositions[element.id].height;
                }
            }
            else if (element.type === 'lineBreak') {
                pagesArr[pagesArr.length - 1].push(element);
            }

        });
        setPages([...pagesArr]);
        setCounter(counter + 1);
    }, [platePositions, flatStruct, multiplePages, reportPositions]);

    const infoText = t('Для добавления диаграммы из любого раздела в My Dashboard, вызовите контекстное меню необходимой диаграммы.');
    const single = useMemo(() => {

        return (
            <>

                <Wrapper>
                    <button onClick={() => setMultiplePages(true)}>Paginate</button>

                    {flatStruct.map((item, i) => {
                        return (
                            <PdfContent

                                key={`${i} <>++- ${item.type}`}
                                getPlateEndPosition={getPlateEndPosition}
                                getReportItemEndPosition={getReportItemEndPosition}
                                {...props}
                                {...item}
                            />
                        );
                    })}

                </Wrapper>

            </>

        );
    }, [flatStruct.length, reportPositions]);
    console.log('>>>>>>><>>>>>', pages)
    const multi = useMemo(() => {
        return pages.map((page, i) => {
            return (
                <PdfPage
                    currentSubSection={props.currentSubSection}
                    currentSection={props.currentSection}
                    sectionsByKeyName={props.sectionsByKeyName}
                    logo={props.white_label_settings.small_logo_url}
                    lang={props.lang}
                    key={`${i} - ${page[0].type}`}
                    id={`pdf-page-${i}`}
                >
                    <Wrapper>
                        {page.map((item, j) => {
                            return (
                                <PdfContent
                                    key={`${j} == ${i}-++ ${item.type}`}
                                    getPlateEndPosition={getPlateEndPosition}
                                    getReportItemEndPosition={getReportItemEndPosition}
                                    {...props}
                                    {...item}
                                />
                            );
                        })}
                    </Wrapper>

                </PdfPage>
            );
        });
    }, [multiplePages, pages]);
    // }, [platePositions, multiplePages, pages, reportPositions]);

    if (multiplePages) {
        // console.log('>>>>> pdf_created ');
        return (
            <>
                <SignalElement id={'pdf_created'} />
                {multi}
            </>
        );
    } else {
        return single;
    }
});

export default PagesList;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding-left: 1px;
`;

const SignalElement = styled.div`
    width: 0px;
    height: 0px;
    position: absolute;
`;

const Container = styled.section`
    box-sizing: border-box;
    height: ${(p) => (p.fullScreenId === null ? 'auto' : '100%')};
`;

const Info = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;