import React from 'react';
import styled from "styled-components/macro";
import WrapperColumnModal from "./WrapperColumnModal";

const ModalContent = (props) => {
    const {
        statusLeftPanel,
        reportParams,
        setTitleNewReport,
        titleNewReport,
        setTypeCreate,
        reportParamsScheduler,
        setTypePeriodicity,
        typePeriodicity,
        setSelectedDay,
        selectedDay,
        setSelectedDate,
        selectedDate,
        setTitleNewScheduler,
        titleNewScheduler
    } = props;

    return (
        <SCModalBoxContent>
            <SCModalLeftSide>
                {/*<SCModalTitleItem>Отчет</SCModalTitleItem>*/}
                <WrapperColumnModal
                    statusLeftPanel={statusLeftPanel}
                    reportParams={reportParams}
                    setTitleNewReport={setTitleNewReport}
                    titleNewReport={titleNewReport}
                    setTypeCreate={setTypeCreate}
                    reportParamsScheduler={reportParamsScheduler}
                    setTypePeriodicity={setTypePeriodicity}
                    typePeriodicity={typePeriodicity}
                    setSelectedDay={setSelectedDay}
                    selectedDay={selectedDay}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                    setTitleNewScheduler={setTitleNewScheduler}
                    titleNewScheduler={titleNewScheduler}
                />
            </SCModalLeftSide>

            {/*<SCModalRightSide>*/}
            {/*    <SCModalTitleItem>Планировщик</SCModalTitleItem>*/}
            {/*</SCModalRightSide>*/}

        </SCModalBoxContent>
    );
};

export default ModalContent;


const SCModalBoxContent = styled.div`
    //padding-left: 10px;
    display: grid;
    grid-template-rows: 100%;
    //grid-template-columns: 50% 50%;
    grid-template-columns: 100%;
    height: 450px;
    width: 500px;
`;

const SCModalLeftSide = styled.div`
    //padding-left: 10px;
    min-height: 100px;
    min-width: 100%;
`;

const SCModalRightSide = styled.div`
    //padding-left: 10px;
    height: 100px;
    min-width: 300px;
`;

const SCModalTitleItem = styled.h6`
    //padding-left: 10px;
    text-align: center;
`;
