import React, { useRef, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { runAction } from '../../actions';
import Diagrams from '../Diagrams';
import App from '../maps/js/main';
import Tools from '../../tools/tools';
import AppRadius from '../radiusAnalitics/main';
import GlobalOptions from '../global_options/global_options';

import styled, { keyframes } from 'styled-components/macro';
import PreCampaign from "../pre_campaign";
import ModalPre from "../pre_campaign/components/modal";

const SCROLL_THRESHOLD = 50;

const MainContent = React.memo((props) => {
    const globalOptionsRef = React.createRef();
    const dispatch = useDispatch();
    const { statusLeftPanel, status } = useSelector((state) => state.TopPanel);
    const { white_label_settings } = useSelector((state) => state.LoginApp);
    const { fullScreenId } = useSelector((state) => state.Diagrams);
    const [scrollTop, set_scrollTop] = useState(0);
    const [scrollChange, set_scrollChange] = useState(0);
    const [scrollDirection, set_scrollDirection] = useState('UP');
    const [trueScrollDirection, set_trueScrollDirection] = useState('UP');
    const [globalOptionsHeight, set_globalOptionsHeight] = useState(88);
    const [showGlobalOptions, set_showGlobalOptions] = useState(true);

    const contentBoxRef = useRef(null);
    const [bgColor, set_bgColor] = useState('whitesmoke');

    useEffect(() => {
        if (fullScreenId === null) {
            set_trueScrollDirection('UP');
        }
    }, [fullScreenId]);

    useEffect(() => {
        resetScrollEffect({ element: contentBoxRef });
    }, [status, statusLeftPanel]);

    const resetScrollEffect = ({ element }) => {
        element.current.scrollTop = 0;
    };

    const onScroll = (e) => {
        const scroll = scrollTop;

        if (scroll > e.currentTarget.scrollTop && scrollDirection === 'DOWN') {
            set_scrollChange(e.currentTarget.scrollTop);
            set_scrollDirection('UP');
        } else if (scroll < e.currentTarget.scrollTop && scrollDirection === 'UP') {
            set_scrollChange(e.currentTarget.scrollTop);
            set_scrollDirection('DOWN');
        }

        const scrollTopNew = e.currentTarget.scrollTop;
        set_scrollTop(scrollTopNew);
    };

    useEffect(() => {
        if (Math.abs(scrollChange - scrollTop) > SCROLL_THRESHOLD) {
            set_trueScrollDirection(scrollDirection);
        }
    }, [scrollChange, scrollTop, scrollDirection]);

    useEffect(() => {
        set_trueScrollDirection(() => 'UP');
    }, [statusLeftPanel, status]);

    const setScrollWidth = (scrollWidth) => {
        dispatch(runAction('setScrollWidth_TopPanelHotspotApp', { scrollWidth }));
    };

    useEffect(() => {
        if (contentBoxRef.current) {
            setScrollWidth(contentBoxRef.current.offsetWidth - contentBoxRef.current.clientWidth);
        }
    }, [contentBoxRef.current]);

    useEffect(() => {
        if (globalOptionsRef.current && globalOptionsRef.current.offsetHeight >= 88) {
            set_globalOptionsHeight(globalOptionsRef.current.clientHeight);
        }
    }, [globalOptionsRef]);

    useEffect(() => {
        const bgColor = white_label_settings ? white_label_settings.content_background_color : 'red';
        set_bgColor(() => bgColor);
    }, []);

    const paramsSet = Tools.useGetParamSet(null, null, fullScreenId);

    useEffect(() => {
        if (!showGlobalOptions) {
            set_globalOptionsHeight(0);
        } else {
            set_globalOptionsHeight(88);
        }
    }, [showGlobalOptions]);

    useEffect(() => {
        const showGlobalOptions =
            paramsSet.global.length !== 0 ||
            (paramsSet.custom.length !== 0 &&
                (paramsSet.custom.includes('comparison_date_range') || paramsSet.custom.includes('main_date_range')));
        set_showGlobalOptions(showGlobalOptions);
    }, [paramsSet]);


    return (
        <ContentWrapper cabinetMode={props.cabinetMode} bgColor={bgColor}>
            {showGlobalOptions ? (
                <GlobalOptions
                    scrollDirection={trueScrollDirection}
                    ref={globalOptionsRef}
                    bgColor={bgColor}
                    key={trueScrollDirection}
                    statusLeftPanel={statusLeftPanel}
                />
            ) : null}
            <BoxContentDiv onScroll={onScroll} ref={contentBoxRef} globalOptionsHeight={globalOptionsHeight}>
                <ContentDiv>
                    {statusLeftPanel === 'indoor_maps' ? (
                        <App lang={props.lang} _t={props._t} />
                    ) : statusLeftPanel === 'hotspot_page_settings' ? (
                        <AppRadius _t={props._t} />
                    ) : statusLeftPanel === 'pre_campaign_static' ? <PreCampaign/> : (
                        statusLeftPanel === 'pre_campaign_dynamic' ? <ModalPre/> :
                        <Diagrams />
                    )}
                </ContentDiv>
            </BoxContentDiv>
        </ContentWrapper>
    );
});

export default MainContent;

const ContentWrapper = styled.section`
    position: relative;
    top: 50px;
    left: ${(props) => (props.cabinetMode === '-pdf' ? '0px' : '238px')};
    background-color: ${(props) => (props.cabinetMode === '-pdf' ? '#fff' : props.bgColor)};
`;

const BoxContentDiv = styled.div`
    padding: 0;
    padding-top: ${(p) => p.globalOptionsHeight + 'px'};
    box-sizing: border-box;
    overflow: auto;
    height: calc(100vh - 50px);
    width: calc(100% - 238px);
`;

const ContentDiv = styled.div`
    box-sizing: border-box;
    padding: 0;
    height: 100%;
    width: 100%;
`;
