var $ = require('jquery');


var Api = {
	saveUrl: {
		root: 'https://cms.wifizone.me/content/save_page/'
	},
	getFilesUrl: {
		root: 'https://cms.wifizone.me/content/getfiles/?access_token='
	},
    previewUrl: {
	    root: 'https://cms.wifizone.me/content/save_temp_page/'
    },
	headers: {

	},
	getRequestHeaders: function (status ,state, url, success, error) {
		var headers = this.headers;
		$.ajax({
			type: 'POST',
			dataType: 'JSON',
			url: url,
            async: status,
			data: state,
			success: function(dataa) {
                success(dataa);
	        }.bind(this),
	        error: function(xhr, status, err) {
	        	var errorr = {
	        		xhr: xhr,
	        		status: status,
	        		err: err
	        	};
	            error(errorr);
	            // console.error(err);
	        }.bind(this)
		});
	},
    getRequest: function (url, success, error) {
        $.ajax({
            type: 'GET',
            url: url,
            success: function(dataa) {
                success(dataa);
            }.bind(this),
            error: function(xhr, status, err) {
                var errorr = {
                    xhr: xhr,
                    status: status,
                    err: err
                };
                error(errorr);
                // console.error(err);
            }.bind(this)
        });
    },
    getRequestDeleteImage: function (url, success, error) {
        $.ajax({
            type: 'GET',
            url: url,
            success: function(dataa) {
                success(dataa);
            }.bind(this),
            error: function(xhr, status, err) {
                var errorr = {
                    xhr: xhr,
                    status: status,
                    err: err
                };
                error(errorr);
                // console.error(err);
            }.bind(this)
        });
    },
    getRequestFiles: function (state, success, error) {
        function uploadMediaItem(params, success, failure) {
            var files = params.files;
            var formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                formData.append('file', file, file.name);
            }
            // console.log('api',files)
            formData.append('access_token', params.access_token);
            formData.append('title', params.mediaTitle);
            formData.append('tags', params.mediaTags)

            var xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://cms.wifizone.me/content/upload/', true);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    success(null);
                } else {
                    failure(xhr.status);
                }
            };
            console.log('apionload',formData)
            xhr.send(formData);
        }
        uploadMediaItem(state, success, error);
    },
	submit: function (state, success, error) {
		var url = this.saveUrl.root;
		this.headers = state.headers;
        // console.log(state.dataReq)
		this.getRequestHeaders(true, state.dataReq, url, success, error);
	},
    getFiles: function (state, success, error) {
	    var url = this.getFilesUrl.root + state;
        // console.log(url);
        this.getRequest(url, success, error);
    },
    setFiles: function (state, success, error) {
        this.getRequestFiles(state, success, error);
    },
    deleteItemImage: function (state, success, error) {
	    var url = 'https://cms.wifizone.me/content/deletefile/?access_token=' + state.access_token + '&media_id=' + state.id;
        this.getRequestDeleteImage(url, success, error);
    },
    requestPreview: function (state, success, error) {
        var url = this.previewUrl.root;
        this.headers = state.headers;
        // console.log(state.dataReq)
        this.getRequestHeaders(true, state.dataReq, url, success, error);
    }
};

module.exports = Api;
