import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import '../style/buttonList.scss';
import ContextMenu from '../../context_menu/context_menu';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';

import Tools from '../tools/tools';
import { Label } from 'office-ui-fabric-react/lib/Label';
import styled from 'styled-components/macro';
import { getTheme, mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

const Container = styled.div`
    position: relative;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    i {
        transition: opacity 0.3s ease-in;
        opacity: 0;
          display: grid;
          align-items: center;
    }
    :hover {
        i {
            opacity: 1;
        }
    }
`;

const StLabel = styled(Label)`
    color: ${(p) => p.color};
    cursor: pointer;
    margin: 10px 0 5px 10px;
    padding-top: 0;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    :focus {
        outline: none;
    }
`;

const StyledIcon = styled(Icon)`
    display: flex;
    align-items: center;
`;

const LeftPanelButton = styled.div`
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    opacity: ${(p) => (p.disabled ? '0.5' : '1')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 8px auto;
    grid-template-rows: 100%;
    /* border-bottom: 1px solid rgb(243, 243, 243); */
    background: ${(p) => (p.active ? 'rgb(225,227,225)' : 'transparent')};

    .dividerVertical {
        width: 7px;
        height: 100%;
        float: left;
        background: ${(p) => (p.active ? p.dividerColor : 'transparent')};
    }
`;

const TextBox = styled.div`
    box-sizing: border-box;
    padding: 0px 10px;
    min-height: 35px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        padding: 5px 0px;
        box-sizing: border-box;
        /* position: absolute; */
        /* top: 0;
        left: 0;
        bottom: 0; */
        width: 90%;
        padding-left: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 17px;
        font-family: ProximaNova;
        color: ${(p) => p.spanColor};
    }
    :hover {
        div {
            color: ${(p) => p.hoverColor};
        }
    }
`;

const LeftPanelButtons = ({
    isAdCampShown,
    isMapExpanded,
    showFavoritesOnly,
    lang,
    statusLeftPanel,
    statusTopPanel,
    sectionsByKeyName,
    input_parameter_list,
    reportCatalog,
    disabled,
    whiteLabelSettings,
    pl_id,
    pl_ids,
    obj_ids,
    ...props
}) => {
    const [theme] = useState(getTheme());
    const { t } = useTranslation();
    if (!sectionsByKeyName) return null;
    const iconClass = mergeStyles({
        fontSize: 15,
        height: '100%',
        // width: '10%',
        margin: '0 0 0 10px',
    });
    const classNames = mergeStyleSets({
        primary: [{ color: `${theme.palette.themePrimary}` }, iconClass],
        greenYellow: [{ color: 'greenyellow' }, iconClass],
        salmon: [{ color: 'salmon' }, iconClass],
    });

    var componentButtons = null;
    let arrObjSelect = pl_ids;
    let firstIdObj = pl_id;
    let arrObjId = obj_ids;

    let objInputParam = {};
    for (var r = 0; r < input_parameter_list.length; r++) {
        if (input_parameter_list[r].input_parameter_type.key_name === 'global') {
            objInputParam[input_parameter_list[r].key_name] = input_parameter_list[r].details.default_value;
        }
    }
    let StringObjId = arrObjId.join();
    let stringObj = arrObjSelect.join();
    // if (arrayLocationSelectNotMulti[0] !== undefined) {
    //   firstIdObj = arrayLocationSelectNotMulti[0].id;
    // }
    const onClick = (data) => (e) => {
        ReactGA.event({ category: 'Subsection Choice', action: `Subsection: ${data.key_name} Section: ${props.status}` });
        props.onClick({ ...data, currentSection: statusTopPanel });
    };

    const onFavoritesStarClick = (item) => (e) => {
        props.onFavoritesStarClick(item, statusTopPanel);
    };

    const currentSection = sectionsByKeyName[statusTopPanel];

    if (currentSection && currentSection.subsections !== undefined) {
        let subsectionsToShow = [];
        const favoritesSubsections = currentSection.subsections.filter((item) => item.favorite);
        if (showFavoritesOnly && favoritesSubsections.length !== 0) {
            subsectionsToShow = favoritesSubsections;
        } else {
            subsectionsToShow = currentSection.subsections;
        }

        componentButtons = subsectionsToShow.map((item, i) => {
            let active = false;
            if (item.key_name === statusLeftPanel) {
                active = true;
            } else {
                active = false;
            }

            const disabled = isMapExpanded || isAdCampShown;

            // const dividerColor = whiteLabelSettings ? whiteLabelSettings.header_color : '#328856';
            const dividerColor = 'rgb(114, 115, 114)';
            const hoverColor = whiteLabelSettings ? whiteLabelSettings.location_label_color : '#328856';
            const spanColor = item.key_name === statusLeftPanel ? hoverColor : '#827f7d';

            let statusUrlAddparam = false;
            for (let t = 0; t < reportCatalog.length; t++) {
                if (item.key_name === reportCatalog[t].group.subsection_key_name) {
                    statusUrlAddparam = true;
                }
            }
            let idsObj = '';
            if (item.required_input_parameters.indexOf('pl_ids') > -1) {
                idsObj = stringObj;
            } else if (item.required_input_parameters.indexOf('pl_id') > -1) {
                idsObj = firstIdObj;
            }

            const button = (
                <LeftPanelButton
                    key={`ItemLeftPanel${i}`}
                    active={active}
                    disabled={disabled}
                    hoverColor={hoverColor}
                    spanColor={spanColor}
                    dividerColor={dividerColor}
                >
                    <div className="dividerVertical" />
                    <TextBox spanColor={spanColor} hoverColor={hoverColor}>
                        <div onClick={onClick(item)}>{item.translations[lang].name}</div>
                        {item.favorite ? (
                            <StyledIcon iconName="FavoriteStarFill" className={classNames.primary} onClick={onFavoritesStarClick(item)} />
                        ) : (
                            <StyledIcon iconName="FavoriteStar" className={classNames.primary} onClick={onFavoritesStarClick(item)} />
                        )}
                    </TextBox>
                </LeftPanelButton>
            );

            const menuItems = [
                {
                    key: 'rename',
                    text: t('Переименовать раздел'),
                    onClick: () => {
                        props.toggleModal({
                            show: true,
                            modalType: 'rename_subsection',
                            modalTitle: t('Переименовать раздел'),
                            modalText: '',
                            height: 'auto',
                            width: '50%',
                            modalArgs: {
                                name: '',
                                id: '',
                                props: { item },
                            },
                        });
                    },
                },
                {
                    key: 'info',
                    text: t('Добавить новый раздел'),
                    onClick: () => {
                        props.toggleModal({
                            show: true,
                            modalType: 'new_subsection',
                            modalTitle: t('Добавить новый раздел'),
                            modalText: '',
                            height: 'auto',
                            width: '50%',
                            modalArgs: {
                                name: '',
                                id: '',
                                props: { lang },
                            },
                        });
                    },
                },
            ];

            if (item.key_name.toLowerCase() !== 'default') {
                menuItems.push({
                    key: 'delete',
                    text: t('Удалить раздел'),
                    onClick: () => {
                        props.toggleModal({
                            show: true,
                            modalType: 'delete_subsection',
                            modalTitle: t('Удалить раздел?'),
                            modalText: '',
                            height: 'auto',
                            width: '50%',
                            modalArgs: {
                                name: '',
                                id: '',
                                props: { id: item.id },
                            },
                        });
                    },
                });
            }

            menuItems.push(
                ...[
                    {
                        key: 'divider_1',
                        itemType: ContextualMenuItemType.Divider,
                    },
                    {
                        key: 'reset',
                        text: t('Сбросить My Dashboard'),
                        onClick: () => {
                            props.toggleModal({
                                show: true,
                                modalType: 'reset_my_dashboard',
                                modalTitle: t('Сбросить My Dashboard'),
                                modalText: '',
                                height: 'auto',
                                width: '50%',
                                modalArgs: {
                                    name: '',
                                    id: '',
                                    props: {},
                                },
                            });
                        },
                    },
                ]
            );

            return currentSection.key_name === 'custom_section' ? (
                <ContextMenu key={`ItemLeftPanel${i}`} rightClick={true} menuItems={menuItems} margin={'0'} cursor={true}>
                    {button}
                </ContextMenu>
            ) : (
                button
            );
        });
    }

    const onShowFavoritesOnlyClick = (showFavoritesOnly) => (e) => {
        props.onShowFavoritesOnlyClick(!showFavoritesOnly);
    };

    // console.log('LeftPanelButtons', _t)
    return (
        <Container disabled={disabled}>
            {componentButtons}
            <StLabel disabled={false} color={`${theme.palette.themeDark}`} onClick={onShowFavoritesOnlyClick(showFavoritesOnly)}>
                {showFavoritesOnly ? t('Показать все') : t('Показать только избранные')}
            </StLabel>
        </Container>
    );
};

LeftPanelButtons.propTypes = {};

export default LeftPanelButtons;
