import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";
import {TextField, MaskedTextField} from '@fluentui/react/lib/TextField';
import {HotTable} from '@handsontable/react';
import {useTranslation} from "react-i18next";
import {Label, Pivot, PivotItem} from '@fluentui/react';
import {Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption} from '@fluentui/react/lib/Dropdown';
import {DatePicker, IDatePicker, mergeStyleSets} from '@fluentui/react';
import { DefaultPalette, Stack, IStackStyles, IStackTokens } from '@fluentui/react';
import moment from "moment";
import {useSelector} from "react-redux";
import DaysOfWeek from "./DaysOfWeek";
import { DateTime } from 'luxon';

const WrapperColumnModal = (props) => {
    const {
        statusLeftPanel,
        reportParams,
        reportParamsScheduler,
        setTitleNewReport,
        titleNewReport,
        setTypeCreate,
        setTypePeriodicity,
        typePeriodicity,
        setSelectedDay,
        selectedDay,
        setSelectedDate,
        selectedDate,
        setTitleNewScheduler,
        titleNewScheduler
    } = props;
    const {t, i18n} = useTranslation();
    const datePickerRef = React.useRef(null);
    const [value, setValue] = useState('');
    const { lang } = useSelector((state) => state.TopPanel);

    moment.locale(lang);
    // const buttonContainerRef = React.useRef(null);

    const styles = mergeStyleSets({
        root: {selectors: {'> *': {marginBottom: 15}}},
        control: {
            width: 250,
            marginBottom: 15
        },
    });

    // console.log('>>>>>>>>>>>>>>', reportParamsScheduler, reportParams);

    const selectDate = (date) => {
        // console.log('>>>>>>>>>>>>>>', date);
        setSelectedDate(date)
    }

    const onChangeTitle = (e) => {
        // console.log('>>>>>>>>>>>>>>', e.target.value);
        setTitleNewReport(e.target.value)
    }

    const onChangeTitleScheduler = (e) => {
        // console.log('>>>>>>>>>>>>>>', e.target.value);
        setTitleNewScheduler(e.target.value)
    }

    const onLinkClick = (item, e) => {
        // console.log('>>>>>>>>>>>>>>', item.key);
        setTypeCreate(item.key);
        // setTitleNewReport(e.target.value)
    };

    const dropdownStyles = {
        dropdown: {width: 250},
    };

    const options = [
        {key: 'Weekly', text: t('Недельный')},
        {key: 'Monthly', text: t('Месяный')}
    ];

    const DayPickerStrings = {
        months: [
            t('Январь'),
            t('Февраль'),
            t('Март'),
            t('Апрель'),
            t('Май'),
            t('Июнь'),
            t('Июль'),
            t('Август'),
            t('Сентябрь'),
            t('Октябрь'),
            t('Ноябрь'),
            t('Декабрь')
        ],

        shortMonths: [t('Янв'), t('Фев'), t('Мар'), t('Апр'), t('Май'), t('Июн'), t('Июл'), t('Авг'), t('Сен'), t('Окт'), t('Ноя'), t('Дек')],

        days: [t('Воскресенье'), t('Понедельник'), t('Вторник'), t('Среда'), t('Четверг'), t('Пятница'), t('Суббота')],

        shortDays: [t('Вс'), t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб')],

        goToToday: t('Перейти к сегодня'),
        prevMonthAriaLabel: t('Предыдущий месяц'),
        nextMonthAriaLabel: t('Следующий месяц'),
        prevYearAriaLabel: t('Предыдущий год'),
        nextYearAriaLabel: t('Следующий год')
    };

    const numericalSpacingStackTokens = {
        childrenGap: 10,
        // padding: 10,
    };

    const onFormatDate = (date) => {
        return moment(date).format('DD MMM YYYY');
    };

    const onChangeDropDown = (e, option, inddex) => {
        // console.log('>>>>>>>>>>>>>>', option.key);
        setTypePeriodicity(option.key);
    }


    return (
        <SCWrapperColumn>
            <Pivot onLinkClick={onLinkClick} aria-label="Large Link Size Pivot Example" linkSize="large">
                <PivotItem key={'Report'} headerText={t('Отчет')}>
                    <TextField required label={t("Название")} onChange={onChangeTitle} value={titleNewReport}/>

                    <SCWrapperSubSection>
                        <span>{`/${statusLeftPanel}/`}</span>
                    </SCWrapperSubSection>

                    <SCWrapperHotTable>
                        <HotTable
                            // width={800}
                            height={reportParams.length > 10 ? 250 : reportParams.length * 27}
                            // ref={this.TableEl}
                            data={reportParams}
                            // colHeaders={this.state.columnNames}
                            stretchH={'all'}
                            // rowHeaders={' '}
                            // rowHeaderWidth={30}
                            autoColumnSize={true}
                            wordWrap={true}
                            readOnly={true}
                        />
                    </SCWrapperHotTable>
                </PivotItem>
                <PivotItem key={'Scheduler'} headerText={t('Планировщик')}>
                    <TextField required label={t("Название")} onChange={onChangeTitleScheduler} value={titleNewScheduler}/>
                    <Stack horizontal horizontalAlign="center" tokens={numericalSpacingStackTokens}>
                        <Dropdown
                            placeholder="Вабрать периодичность"
                            label={t("Периодичность")}
                            options={options}
                            // selectedKey={typePeriodicity}
                            styles={dropdownStyles}
                            required
                            defaultSelectedKey={typePeriodicity}
                            onChange={onChangeDropDown}
                        />


                        {typePeriodicity === 'Weekly' ?
                            <div>
                                <Label required>{t('Дни недели')}</Label>
                                <DaysOfWeek
                                    stringsDays={DayPickerStrings.shortDays}
                                    setSelectedDay={setSelectedDay}
                                    selectedDay={selectedDay}
                                />
                            </div>
                            :
                            <DatePicker
                            componentRef={datePickerRef}
                            label={t('Начальная дата')}
                            allowTextInput
                            ariaLabel="Select a date"
                            value={new Date(selectedDate)}
                            onSelectDate={selectDate}
                            className={styles.control}
                            firstDayOfWeek={1}
                            minDate={new Date(DateTime.now())}
                            formatDate={onFormatDate}
                            strings={DayPickerStrings}
                        />}
                    </Stack>



                    <SCWrapperSubSection>
                        <span>{`/${statusLeftPanel}/`}</span>
                    </SCWrapperSubSection>

                    <SCWrapperHotTable>
                        <HotTable
                            // width={800}
                            height={reportParamsScheduler.length > 10 ? 250 : reportParamsScheduler.length * 27}
                            // ref={this.TableEl}
                            data={reportParamsScheduler}
                            // colHeaders={this.state.columnNames}
                            stretchH={'all'}
                            // rowHeaders={' '}
                            // rowHeaderWidth={30}
                            autoColumnSize={true}
                            wordWrap={true}
                            readOnly={true}
                        />
                    </SCWrapperHotTable>
                </PivotItem>

            </Pivot>


        </SCWrapperColumn>
    );
};

export default WrapperColumnModal;


const SCWrapperColumn = styled.div`
  //padding-left: 10px;
  height: 100px;
  min-width: 300px;
`;

const SCWrapperSubSection = styled.div`
  //padding-left: 10px;
  padding: 10px;

  span {
    font-size: 16px;
    font-weight: 900;
  }
`;

const SCWrapperHotTable = styled.div`
  //padding-left: 10px;
  //padding: 10px;
  height: 300px;
`;

const SCWrapperBackCalendar = styled.div`
  position: absolute;
  background: #1c7430;
`;

