import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import styled from 'styled-components/macro';
import Tools from '../../../tools/tools';
import { useLongPress } from '../tools/tools';
import {connect, useDispatch, useSelector} from 'react-redux';
import { runAction } from '../../../actions';
import PlList from './components/pl_list';
import hostForLocation from '../../../hostnames/hostname';
import ReportingObjectsPanel from '../ro_panel/ro_panel';
import PlSelectPanel from '../pl_select_panel/pl_select_panel';
import ObjSetsButtons from '../obj_sets_panel/components/main_buttons';
import ObjSetsPanel from '../obj_sets_panel/obj_sets_panel';
import Overlay from '../overlay/overlay';
import Accordion from '../../accordion';
// import ShowManualCustomParamsModalButton from './components/show_manual_custom_params_modal_button';
import OutdoorSelectedSpacesList from '../../outdoor-spaces/components/outdoorSelectedSpacesList';
import ObjSetsContext from './obj_sets_context';
import AdCampaignList from '../../ad_campaigns/components/ad_camp_list';
import InfoIcon from './components/info_icon';
import Contra from './contra';
import img from '../img/placeholder.svg';
import { getAdCampaigns } from '../../../api/api';
import dayjs from "dayjs";
import Cookies from "universal-cookie";

// import Test from '../../../tools/useDataTree';
const cookie = new Cookies();
const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: ${(props) => props.bgColor};
  overflow: hidden;
  flex-grow: 1;
  /* flex-basis: 100%; */
  /* height: 100vh; */
  padding-bottom: 15px;
`;

const Button = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 1;
  position: sticky;
  top: 0;
  height: 35px;
  /* border-bottom: 1px solid #c4c1bf; */
  /* margin-bottom: 6px; */
  font-family: ProximaNova;
  font-size: 12pt;
  color: #243138;
    /* cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')}; */
    /* pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')}; */
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.locationsBgColor};
  height: 35px;
  flex-shrink: 0;
  /* border-bottom: 1px solid #c4c1bf; */
`;

const TitleButton = styled.div`
  /* width: 100%; */
  height: 35px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 1;
  top: 0;
    /* background: ${(props) => props.locationsBgColor}; */
    /* pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')}; */
  /* margin-bottom: 6px; */
  cursor: pointer;
  span.iconPL {
    display: flex;
    height: 15px;
    width: 15px;
    background: url(${img}) no-repeat center;
    background-size: 12px;
    margin-right: 5px;
    padding-bottom: 3px;
    /* margin-left: 50px;
    margin-top: 10px; */
    /* float: left; */
  }
  span.titleCountPL {
    display: flex;
    font-family: ProximaNova;
    /* float: left; */
    /* margin-left: 11px;
    margin-top: 8px; */
    font-size: 12pt;
    color: #243138;
    span.titleCountPLone {
      color: rgb(25, 58, 107);
    }
    span.titleCountPLsecond {
      color: rgb(25, 58, 107);
      /* color: #8c9790; */
    }
  }
`;

const PlPanel = ({
                     reportingObjectsById_ctg,
                     reportingObjectsByPlId_ctg,
                     projectLocationsById_ctg,
                     selectedPlIds,
                     selectedPlId,
                     selectedObjIds,
                     changeCustomInputParams,
                     currentSection,
                     currentSubSection,
                     sectionsByKeyName_ctg,
                     history,
                     setHistory,
                     searchLocationsValue,
                     searchObjectsValue,
                     showLocationsSelectionPanel,
                     showReportingObjectsPanel,
                     lang,
                     colors,
                     isAdCampShown,
                     userOptions,
                     dataObjSetsByPlId,
                     whiteLabelSettings,
                     showObjSetsPanel,
                     dataObjSetsById,
                     searchObjSetsValue,
                     customInputParamsValues,
                     dataobjsets,
                     catalog,
                     isShowPrecampModal,
                     selectionItemReport,
                     ...props
                 }) => {
    const [outerSections, set_outerSections] = useState({});
    const {
        selectedSpaces
    } = useSelector(
        (state) => state.OutdoorSpaces
    );
    const { t } = useTranslation();
    const [activePlId, setActivePlId] = useState(undefined);
    const [mainIdClicked, setMainIdClicked] = useState({});
    const [mode, setMode] = useState('');
    const [updater, set_updater] = useState(0);
    const [title, setTitle] = useState('Локации');
    const [singleAdCampaignSelection, setSingleAdCampaignSelection] = useState(null);
    const {
        outdoor_ad_spaces
    } = useSelector(
        (state) => state.ReportParametersManager.Source.src
    );

    const {
        outdoorAdSpacesById
    } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );


    const customParamsSet = Tools.useGetParamSet().custom;
    const globalParamsSet = Tools.useGetParamSet().global;
    const paramsSet = Tools.useGetParamSet();
    const dispatch = useDispatch();

    const objSets = useMemo(() => {
        if (dataobjsets) {
            const sets =
                searchObjSetsValue === ''
                    ? dataobjsets
                    : dataobjsets.filter((set) => set.name.toLowerCase().includes(searchObjSetsValue.toLowerCase()));
            const objSets = sets.map((item) => {
                let parent = '';
                if (item.owner !== null) {
                    parent = `${item.owner}:owner`;
                } else if (item.project !== null) {
                    parent = `${item.project}:project`;
                } else if (item.project_location !== null) {
                    parent = `${item.project_location}:pl`;
                }
                return {
                    ...item,
                    id: `${item.id}:dataobjset`,
                    type: 'dataobjset',
                    parent,
                };
            });
            return objSets;
        }
    }, [dataobjsets, searchObjSetsValue]);

    const commonTree = Tools.useDataTree(objSets);
    const memoTree = useMemo(() => {
        return commonTree;
    }, [commonTree]);

    useEffect(() => {
        let mode;
        try {
            mode = sectionsByKeyName_ctg[currentSection].subsections.filter((item) => item.key_name === currentSubSection)[0].mode;
        } catch (error) {
            mode = undefined;
        }

        sectionsByKeyName_ctg && Object.keys(sectionsByKeyName_ctg).length !== 0 && setMode(() => mode);
    }, [currentSection, currentSubSection, sectionsByKeyName_ctg]);

    // useEffect(() => {
    //     set_updater((prev) => prev++);
    // }, [sectionsByKeyName_ctg]);

    useEffect(() => {
        if (reportingObjectsByPlId_ctg && Object.keys(reportingObjectsByPlId_ctg).length !== 0) {
            const his = { ...history };
            his.locations = { ...reportingObjectsByPlId_ctg };
            Object.keys(reportingObjectsByPlId_ctg).forEach((plId) => {
                const active = selectedPlIds.includes(Number(plId));
                const isSearch =
                    history.locations[plId] && history.locations[plId].isSearch !== undefined ? history.locations[plId].isSearch : true;
                const objects = selectedObjIds.filter((objId) => reportingObjectsById_ctg[objId].pl_id === Number(plId));
                const lists = {};
                Object.keys(his.locations[plId].objects).forEach((key) => (lists[key] = false));
                his.locations[plId] = { active, objects, lists, isSearch };

                if (his.lastLocationsStack.length === 0 || his.lastLocationsStack[0] !== selectedPlId) {
                    his.lastLocationsStack.unshift(selectedPlId);
                }

                // his.lastLocationsStack = [...selectedPlIds];
            });
            if (selectedPlIds.length === 0 && selectedObjIds.length === 0 && Object.keys(reportingObjectsByPlId_ctg).length !== 0) {
                const id = Object.keys(reportingObjectsByPlId_ctg)[0];
                his.locations[id].active = true;
                his.locations[id].objects.push(reportingObjectsByPlId_ctg[id].main_servicepoint.id);
                his.lastLocationsStack.unshift(Number(id));
                // Object.keys(reportingObjectsByPlId_ctg).forEach(id => (his.locations[id].isSearch = true));
                changeCustomInputParams(his, mode);
            }

            if (dataObjSetsByPlId && Object.keys(dataObjSetsByPlId).length !== 0) {
                his.objSets.noPl = dataObjSetsByPlId.noPl.map((set) => {
                    return { ...set, selected: false };
                });
                his.objSets.hasPl = {};
                Object.keys(dataObjSetsByPlId.hasPl).forEach((plId) => {
                    his.objSets.hasPl[plId] = {};
                    his.objSets.hasPl[plId].sets = dataObjSetsByPlId.hasPl[plId].map((set) => {
                        return { ...set, selected: false };
                    });
                    his.objSets.hasPl[plId].expanded = false;
                });
            }

            setHistory(his);
        }
    }, [reportingObjectsByPlId_ctg, dataObjSetsByPlId, selectedPlIds, selectedPlId, selectedObjIds]);



    const bgColor = whiteLabelSettings ? whiteLabelSettings.left_panel_color : 'white';
    const locationsBgColor = whiteLabelSettings ? whiteLabelSettings.location_button_color : 'rgb(172,223,193)';
    const locationLabelColor = whiteLabelSettings ? whiteLabelSettings.location_label_color : '#328856';

    const handleDashClick = (id, plId, isMainSP = false) => {
        const his = { ...history };
        const objects = [...his.locations[plId].objects];
        if (objects.includes(id)) {
            objects.splice(objects.indexOf(id), 1);
        } else {
            isMainSP ? objects.unshift(id) : objects.push(id);
        }
        his.locations[plId].objects = [...objects];
        setHistory(his);
    };

    const onDeleteDashButtonClick = (id, plId) => {
        const his = { ...history };
        const objects = [...his.locations[plId].objects];
        objects.splice(objects.indexOf(id), 1);
        his.locations[plId].objects = [...objects];
        setHistory(his);
    };

    const handleLocationDeleteClick = (plId) => {
        const his = { ...history };
        his.locations[plId].active = false;
        setHistory(his);
    };

    const onLocationClick = (id) => {
        const his = { ...history };
        switch (mode) {
            case 'multiLocation_multiObject':
            case 'main_2_contra_set':
            case 'main_set_2_contra_set':
            case 'multiLocation':
            case 'multiAll':
                if (!his.locations[id].active && his.locations[id].objects.length === 0) {
                    his.locations[id].objects.push(reportingObjectsByPlId_ctg[id].main_servicepoint.id);
                }
                if (!his.locations[id].active) {
                    his.lastLocationsStack.unshift(id);
                } else if (his.locations[id].active && his.lastLocationsStack[0] === id) {
                    his.lastLocationsStack.shift();
                }
                his.locations[id].active = !his.locations[id].active;
                break;

            case 'singleLocation':
                if (!his.locations[id].active && his.locations[id].objects.length === 0) {
                    his.locations[id].objects.push(reportingObjectsByPlId_ctg[id].main_servicepoint.id);
                }
                if (!his.locations[id].active) {
                    his.locations[id].active = true;
                }
                his.lastLocationsStack.unshift(id);
                break;
            default:
                break;
        }

        setHistory(his);
    };

    const onSelectAllLocationsClick = (filteredList) => {
        const his = { ...history };
        // Object.keys(his.locations).forEach(id => {
        filteredList.forEach((id) => {
            if (!his.locations[id].active && his.locations[id].objects.length === 0) {
                his.locations[id].objects.push(reportingObjectsByPlId_ctg[id].main_servicepoint.id);
            }
            his.locations[id].active = true;
        });
        setHistory(his);
    };

    const onClearAllLocationsClick = () => {
        const his = { ...history };
        Object.keys(his.locations).forEach((id) => {
            his.locations[id].active = false;
        });
        setHistory(his);
    };

    const hideSelectionPanel = () => {
        props.hideSelectionPanel();
        props.setAllowedAccordionSection(null);
        changeCustomInputParams(history, mode);
    };

    useEffect(() => {
        // console.log('showReportingObjectsPanel', showReportingObjectsPanel);
        // console.log('showLocationsSelectionPanel', showLocationsSelectionPanel);

        if (showReportingObjectsPanel || (!showReportingObjectsPanel && !showLocationsSelectionPanel)) {
            setTitle('Локации');
        } else if (showLocationsSelectionPanel) {
            setTitle('Подтвердить выбор');
        }
    }, [showReportingObjectsPanel, showLocationsSelectionPanel]);

    const handleShowLocationsSelectionPanel = () => {
        ReactGA.event({
            category: 'LeftPanel',
            action: 'Show Locations Click',
            label: `currentSection: ${currentSection} currentSubSection: ${currentSubSection}`,
        });

        if (!showLocationsSelectionPanel && showReportingObjectsPanel) {
            props.hideSelectionPanel();
            props.handleShowLocationsSelectionPanel();
        } else if (!showLocationsSelectionPanel) {
            props.handleShowLocationsSelectionPanel();
            props.setAllowedAccordionSection('obj_ids');
            set_outerSections({ obj_ids: true });
        } else if (showLocationsSelectionPanel) {
            hideSelectionPanel();
            props.setAllowedAccordionSection(null);
        }
    };

    const handleShowReportingObjectsPanel = (id) => {
        showLocationsSelectionPanel && props.hideSelectionPanel();
        showObjSetsPanel && toggleObjSetsPanel(false);
        // changeCustomInputParams(history);

        if (id && id != null) {
            const his = { ...history };
            his.lastLocationsStack.unshift(Number(id));
            setHistory(his);
        }
        setActivePlId((prev) => id);
        props.handleShowReportingObjectsPanel();
    };

    const toggleDropDownMenu = (plId, name) => {
        const his = { ...history };
        his.locations[plId].lists[name] = !his.locations[plId].lists[name];
        setHistory(his);
    };

    const onSelectAllObjSetsClick = (plId) => {
        const his = { ...history };
        his.objSets.hasPl[plId].sets = his.objSets.hasPl[plId].sets.map((set) => ({
            ...set,
            selected: true,
        }));
        setHistory(his);
    };

    const onClearAllObjSetsClick = (plId) => {
        const his = { ...history };
        his.objSets.hasPl[plId].sets = his.objSets.hasPl[plId].sets.map((set) => ({
            ...set,
            selected: false,
        }));
        setHistory(his);
    };

    const toggleObjSetsDropDownMenu = (plId) => {
        const his = { ...history };
        his.objSets.hasPl[plId].expanded = !his.objSets.hasPl[plId].expanded;
        setHistory(his);
    };

    const onSelectAllObjectsClick = (plId, name, filteredGroup) => {
        const his = { ...history };
        // const group = reportingObjectsByPlId_ctg[plId].objects[name].map(item => item.id);
        // console.log(group, filteredGroup);
        // const mergedUniqArray = uniq([...his.locations[plId].objects, ...group]);

        const mergedUniqArray = uniq([...his.locations[plId].objects, ...filteredGroup.map((item) => item.id)]);
        his.locations[plId].objects = [...mergedUniqArray];
        setHistory(his);
    };

    const onClearAllObjectsClick = (plId, name) => {
        const his = { ...history };
        const group = reportingObjectsByPlId_ctg[plId].objects[name].map((item) => item.id);
        const objects = [...his.locations[plId].objects];
        group.forEach((id) => {
            if (objects.includes(id)) {
                objects.splice(objects.indexOf(id), 1);
            }
        });
        his.locations[plId].objects = [...objects];
        setHistory(his);
    };

    const onObjSetDashClick = (setId, plId) => {
        const his = { ...history };
        const toggleSelected = (arr) => {
            return arr.map((set) => {
                if (set.id !== setId) {
                    return set;
                } else {
                    return { ...set, selected: !set.selected };
                }
            });
        };

        if (!plId) {
            his.objSets.noPl = toggleSelected([...his.objSets.noPl]);
        } else {
            his.objSets.hasPl[plId].sets = toggleSelected([...his.objSets.hasPl[plId].sets]);
        }

        setHistory(his);
    };

    const uniq = (array) => {
        return Array.from(new Set(array));
    }
    console.log('>>>>>>><<<<<', selectionItemReport)
    const handleLocationsSearch = (text) => {
        props.handleLocationsSearch(text, projectLocationsById_ctg);
    };

    const handleObjectsSearch = (text) => {
        props.handleObjectsSearch(text, activePlId);
    };

    const handleObjSetsSearch = (text) => {
        props.handleObjSetsSearch(text);
    };

    const toggleObjSetsPanel = (show) => {
        props.toggleObjSetsPanel(show);
    };

    const hideObjSetsPanel = () => {
        toggleObjSetsPanel(false);
        props.setAllowedAccordionSection(null);
        changeCustomInputParams(history, mode);
    };

    const amountOfSelectedLocations =
        mode === 'multiLocation_multiObject' ||
        mode === 'multiLocation' ||
        mode === 'main_set_2_contra_set' ||
        mode === 'main_2_contra_set' ||
        mode === 'multiAll'
            ? Object.keys(history.locations).filter((id) => history.locations[id].active).length
            : mode === 'singleLocation'
                ? 1
                : 0;

    const showDeleteButtons = showLocationsSelectionPanel || showReportingObjectsPanel;

    let showObjectsColorDots = false;
    try {
        showObjectsColorDots =
            sectionsByKeyName_ctg && currentSection && sectionsByKeyName_ctg[currentSection]
                ? sectionsByKeyName_ctg[currentSection].subsections.filter((sub) => sub.key_name === currentSubSection)[0].show_item_colors
                : false;
    } catch (error) {}

    const adSurfacesButtonText = props.isMapExpanded ? t('Подтвердить выбор') : t('Рекламные поверхности');
    const adCampButtonText = isAdCampShown ? t('Подтвердить выбор') : t('Рекламные кампании');

    const onExpandButtonClick = () => {

        ReactGA.event({
            category: 'LeftPanel',
            action: 'Ad spaces Click',
            label: props.isMapExpanded ? 'Done' : 'Ad spaces',
        });
        props.toggleMap();
        if (props.isMapExpanded) {
            props.changeOutdoorAdSpaceIds(props.selectedSpaces);
            props.setAllowedAccordionSection(null);
        } else {
            props.setAllowedAccordionSection('outdoor_ad_spaces');
            set_outerSections({ outdoor_ad_spaces: true });
        }
    };

    const onAdCampButtonClick = () => {
        ReactGA.event({
            category: 'LeftPanel',
            action: 'Ad campaigns Click',
            label: isAdCampShown ? 'Done' : 'Ad campaigns',
        });
        props.toggleAdCampaigns();
        if (isAdCampShown) {
            props.changeAdCampaignIds(props.singleAdCampaignSelection, props.multiAdCampaignSelection);
            props.setAllowedAccordionSection(null);
        } else {
            props._getAdCampaigns(props.token, props.campaignsToShow, props.project_id);
            props.setAllowedAccordionSection('ad_campaigns');
            set_outerSections({ ad_campaigns: true });
        }
    };

    const onPreCampButtonClick = () => {
        ReactGA.event({
            category: 'LeftPanel',
            action: 'Pre campaigns Click',
            label: isShowPrecampModal ? 'Done' : 'Ad campaigns',
        });
        props.togglePreCampaigns(true);

    };

    const onPreCampButtonHideClick = () => {

        props.togglePreCampaigns(false);
        if (selectionItemReport) {
            let outdoorAdSpaces = []
            let outdoorAdSpacesByKey = {}

            selectedSpaces.forEach((item) => {
                const space = outdoor_ad_spaces.filter((adSpace) => adSpace.id === item)[0];
                outdoorAdSpacesByKey[space.id] = space
                outdoorAdSpaces.push(space)
            })
            props.getLoadReport(selectionItemReport, selectedSpaces, outdoorAdSpaces, outdoorAdSpacesByKey, outdoorAdSpacesById)
        }

    };

    const onExpandObjSet = (setId) => {
        const his = { ...history };

        his.objSets.noPl = dataObjSetsByPlId.noPl.map((set) => {
            return { ...set, selected: false };
        });

        Object.keys(dataObjSetsByPlId.hasPl).forEach((plId) => {
            his.objSets.hasPl[plId].sets = dataObjSetsByPlId.hasPl[plId].map((set) => {
                return { ...set, selected: false };
            });
        });

        Object.keys(his.locations).forEach((plId) => {
            his.locations[plId].active = false;
            his.locations[plId].objects = [];
        });

        dataObjSetsById[setId].bound_dataobjs.forEach((objId) => {
            const plId = reportingObjectsById_ctg[objId].pl_id;
            his.locations[plId].active = true;
            his.locations[plId].objects.push(objId);
        });

        setHistory(his);
    };

    const onChangeObjSet = (setId, name, objects) => {
        props.onChangeObjSet(setId, name, objects, props.token);
    };

    const onDeleteObjSet = (setId) => {
        props.onDeleteObjSet(setId, props.token);
    };

    const _toggleModal = (args) => {
        props.toggleModal(args);
    };

    const onMainObjectClick = (id) => {
        setMainIdClicked({ id });
    };

    const allowLongPress = () => {
        if (customParamsSet.includes('ad_campaign_id') || customParamsSet.includes('ad_campaign_ids')) {
            return false;
        } else if (
            (customParamsSet.indexOf('outdoor_ad_space_ids') !== -1 && !props.isMapExpanded) ||
            (customParamsSet.indexOf('outdoor_ad_space_ids') === -1 &&
                !showLocationsSelectionPanel &&
                !showReportingObjectsPanel &&
                !showObjSetsPanel)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const toggleManualInputModal = () => {
        props.toggleModal({
            show: true,
            modalType: 'manual_edit',
            modalTitle: t('Ручное редактирование'),
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: { customInputParamsValues, catalog, sectionsByKeyName: sectionsByKeyName_ctg, currentSection, currentSubSection },
            },
        });
    };

    const longPress = useLongPress(allowLongPress, toggleManualInputModal, 2000);

    // console.log(customParamsSet);
    // console.log(globalParamsSet);

    const accordionArr = () => {
        const result = [];
        if (
            customParamsSet.includes('pl_ids') ||
            customParamsSet.includes('pl_id') ||
            customParamsSet.includes('obj_ids') ||
            customParamsSet.includes('obj_id') ||
            customParamsSet.includes('main_obj_id') ||
            customParamsSet.includes('contra_obj_ids')
        ) {
            let independentObjSetsList = [];
            let ObjSetsHasPlList = [];
            let isEmptyPL = false;
            let infoText = '';

            try {
                independentObjSetsList = history.objSets.noPl.filter((item) => item.selected);
                Object.keys(history.objSets.hasPl).forEach((key) => {
                    history.objSets.hasPl[key].sets.forEach((item) => {
                        if (item.selected) {
                            ObjSetsHasPlList.push(item);
                        }
                    });
                });
            } catch (error) {}

            const PlArray = Object.keys(history.locations).filter(
                (item) =>
                    history.locations[item].active ||
                    (history.objSets.hasPl[item] && history.objSets.hasPl[item].sets.filter((set) => set.selected).length !== 0)
            );

            PlArray.forEach((plId) => {
                const { objects } = history.locations[plId];
                const idList = history.objSets.hasPl[plId] ? history.objSets.hasPl[plId].sets.filter((item) => item.selected) : [];
                if (objects.length === 0 && idList.length === 0) {
                    isEmptyPL = true;
                }
            });

            if (
                (mode === 'main_2_contra_set' && customInputParamsValues.main_obj_id === null) ||
                (mode === 'main_set_2_contra_set' && customInputParamsValues.main_obj_ids.length === 0)
            ) {
                infoText = t('Не выбрано ни одного ключевого объекта');
            } else if (
                (mode === 'main_2_contra_set' || mode === 'main_set_2_contra_set') &&
                customInputParamsValues.contra_obj_ids.length === 0
            ) {
                infoText = t('Не выбрано ни одного объекта для сравнения');
            } else if (amountOfSelectedLocations === 0 && independentObjSetsList.length === 0 && ObjSetsHasPlList.length === 0) {
                infoText = t('Не выбрано ни одной локации');
            } else if (isEmptyPL) {
                infoText = t('Есть пустые локации');
            } else {
                infoText = '';
            }

            const label = 'obj_ids';
            const disabled = props.allowedAccordionSection && props.allowedAccordionSection !== label;
            result.push(
                <div
                    label={label}
                    key={label}
                    disabled={disabled}
                    title_сomponent={
                        <TitleButton
                            locationsBgColor={locationsBgColor}
                            onClick={handleShowLocationsSelectionPanel}
                            // id={'itemInBoxPL'}
                            {...longPress}
                        >
                            {/* <span className={'iconPL'} /> */}
                            <span className={'titleCountPL'}>
                                {t(title)}
                                {title === 'Локации' ? (
                                    <React.Fragment>
                                        <span className={'titleCountPLone'}>&nbsp;{amountOfSelectedLocations}&nbsp;</span> /
                                        <span className={'titleCountPLsecond'}>&nbsp;{Object.keys(reportingObjectsByPlId_ctg).length}</span>
                                    </React.Fragment>
                                ) : null}
                            </span>
                            <InfoIcon infoText={infoText} />
                        </TitleButton>
                    }
                >
                    <PlList
                        colors={colors}
                        showObjectsColorDots={showObjectsColorDots}
                        lang={lang}
                        PlArray={PlArray}
                        ObjSetsHasPlList={ObjSetsHasPlList}
                        independentObjSetsList={independentObjSetsList}
                        onMainObjectClick={onMainObjectClick}
                        customInputParamsValues={customInputParamsValues}
                        showDeleteButtons={showDeleteButtons}
                        showDeleteObjectsButtons={showDeleteButtons}
                        handleLocationDeleteClick={handleLocationDeleteClick}
                        dataobjsets={dataobjsets}
                        mode={mode}
                        reportingObjectsByPlId_ctg={reportingObjectsByPlId_ctg}
                        reportingObjectsById_ctg={reportingObjectsById_ctg}
                        history={history}
                        locationLabelColor={locationLabelColor}
                        handlePlButtonClick={handleShowReportingObjectsPanel}
                        amountOfSelectedLocations={amountOfSelectedLocations}
                        onDeleteDashButtonClick={onDeleteDashButtonClick}
                        showDeleteObjSetButtons={showObjSetsPanel}
                        onDeleteObjSetDashButtonClick={onObjSetDashClick}
                    />
                </div>
            );
        }

        if (customParamsSet.includes('outdoor_ad_space_ids')) {
            const label = 'outdoor_ad_spaces';
            const disabled = props.allowedAccordionSection && props.allowedAccordionSection !== label;

            // if (currentSubSection === 'pre_campaign_static') {
            //     result.push(
            //         <div
            //             label={label}
            //             key={label}
            //             disabled={disabled}
            //             title_сomponent={
            //                 <Button onClick={!isShowPrecampModal ? onPreCampButtonClick : onPreCampButtonHideClick} {...longPress}>
            //                     {isShowPrecampModal ? t('Выбрать') : t('Цифровые кампании')}
            //                 </Button>
            //             }
            //         >
            //
            //         </div>
            //     );
            // }

            result.push(
                <div
                    label={label}
                    key={label}
                    disabled={disabled}
                    title_сomponent={
                        <Button onClick={onExpandButtonClick} {...longPress}>
                            {adSurfacesButtonText}
                        </Button>
                    }
                >
                    <OutdoorSelectedSpacesList
                        colors={colors}
                        showObjectsColorDots={showObjectsColorDots}
                        toggleModal={props.toggleModal}
                        catalog={props.catalog}
                        sectionsByKeyName={props.sectionsByKeyName_ctg}
                        currentSection={currentSection}
                        currentSubSection={currentSubSection}
                        customInputParamsValues={customInputParamsValues}
                        selectedSpaces={props.selectedSpaces}
                        outdoor_ad_space_ids={props.outdoor_ad_space_ids}
                        region_id={props.region_id}
                        outdoor_ad_spaces={props.outdoor_ad_spaces}
                        handleTargetClick={props.handleTargetClick}
                        onDeselectAdSpaceClick={props.onDeselectAdSpaceClick}
                        isMapExpanded={props.isMapExpanded}
                        toggleMap={props.toggleMap}
                        changeOutdoorAdSpaceIds={props.changeOutdoorAdSpaceIds}
                        searchValue={props.searchValue}
                        selectedAdSpaceTypes={props.selectedAdSpaceTypes}
                        whiteLabelSettings={whiteLabelSettings}
                    />
                </div>
            );
        }

        if (customParamsSet.includes('ad_campaign_id') || customParamsSet.includes('ad_campaign_ids')) {
            const label = 'ad_campaigns';
            const disabled = props.allowedAccordionSection && props.allowedAccordionSection !== label;
            result.push(
                <div
                    label={label}
                    key={label}
                    disabled={disabled}
                    title_сomponent={
                        <Button allowedAccordionSection={props.allowedAccordionSection} onClick={onAdCampButtonClick}>
                            {adCampButtonText}
                        </Button>
                    }
                >
                    <AdCampaignList
                        singleAdCampaignSelection={props.singleAdCampaignSelection}
                        multiAdCampaignSelection={props.multiAdCampaignSelection}
                        customParamsSet={customParamsSet}
                        adCampaignsById={props.adCampaignsById}
                    />
                </div>
            );
        }

        return result;
    };

    return (
        <Container bgColor={bgColor}>
            <Accordion outerSections={outerSections} locationsBgColor={locationsBgColor} currentSubSection={currentSubSection}>
                {accordionArr()}
            </Accordion>
            <Contra mainIdClicked={mainIdClicked} mode={mode} />

            {/* {showManualButton ? (
                    <ShowManualCustomParamsModalButton
                        catalog={props.catalog}
                        toggleModal={props.toggleModal}
                        sectionsByKeyName={sectionsByKeyName_ctg}
                        currentSection={currentSection}
                        currentSubSection={currentSubSection}
                        customInputParamsValues={customInputParamsValues}
                    />
                ) : null} */}

            {showLocationsSelectionPanel && (
                <Overlay left={false} handleOverlayClick={hideSelectionPanel}>
                    <PlSelectPanel
                        onSelectAllClick={onSelectAllLocationsClick}
                        onClearAllClick={onClearAllLocationsClick}
                        mode={mode}
                        handleSearch={handleLocationsSearch}
                        searchLocationsValue={searchLocationsValue}
                        onLocationClick={onLocationClick}
                        history={history}
                        reportingObjectsByPlId_ctg={reportingObjectsByPlId_ctg}
                        lang={lang}
                    />
                </Overlay>
            )}
            {showReportingObjectsPanel && (
                <Overlay left={false} handleOverlayClick={hideSelectionPanel}>
                    <ReportingObjectsPanel
                        toggleDropDownMenu={toggleDropDownMenu}
                        searchObjectsValue={searchObjectsValue}
                        handleDashClick={handleDashClick}
                        history={history}
                        lang={lang}
                        handleSearch={handleObjectsSearch}
                        activePlId={activePlId}
                        onSelectAllObjectsClick={onSelectAllObjectsClick}
                        onClearAllObjectsClick={onClearAllObjectsClick}
                    />
                </Overlay>
            )}

            {showObjSetsPanel ? (
                <ObjSetsContext.Provider
                    value={{
                        history,
                        onExpandObjSet,
                        reportingObjectsById_ctg,
                        dataObjSetsById,
                        onChangeObjSet,
                        onDeleteObjSet,
                        _toggleModal,
                    }}
                >
                    <Overlay left={false} handleOverlayClick={hideObjSetsPanel}>
                        <ObjSetsPanel
                            lang={lang}
                            dataobjsets={dataobjsets}
                            userOptions={userOptions}
                            dataObjSetsById={dataObjSetsById}
                            dataObjSetsByPlId={dataObjSetsByPlId}
                            toggleDropDownMenu={toggleObjSetsDropDownMenu}
                            onObjSetDashClick={onObjSetDashClick}
                            reportingObjectsById={reportingObjectsById_ctg}
                            projectLocationsById={projectLocationsById_ctg}
                            history={history}
                            toggleModal={props.toggleModal}
                            onSelectAllClick={onSelectAllObjSetsClick}
                            onClearAllClick={onClearAllObjSetsClick}
                            searchObjSetsValue={searchObjSetsValue}
                            handleSearch={handleObjSetsSearch}
                            toggleExpandArrow={props.toggleExpandArrow}
                            memoTree={memoTree}
                            // onExpandObjSet={_onExpandObjSet}
                        />
                    </Overlay>
                </ObjSetsContext.Provider>
            ) : null}
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        reportingObjectsByPlId_ctg: state.ReportParametersManager.Source.ctg.reportingObjectsByPlId,
        dataObjSetsByPlId: state.ReportParametersManager.Source.ctg.dataObjSetsByPlId,
        outdoor_ad_space_ids: state.ReportParametersManager.customInputParamsValues.outdoor_ad_space_ids,
        region_id: state.ReportParametersManager.customInputParamsValues.region_id,
        outdoor_ad_spaces: state.ReportParametersManager.Source.src.outdoor_ad_spaces,
        searchValue: state.OutdoorSpaces.searchValue,
        selectedAdSpaceTypes: state.OutdoorSpaces.selectedAdSpaceTypes,
        isMapExpanded: state.OutdoorSpaces.isMapExpanded,
        reportingObjectsById_ctg: state.ReportParametersManager.Source.ctg.reportingObjectsById,
        projectLocationsById_ctg: state.ReportParametersManager.Source.ctg.projectLocationsById,
        dataObjSetsById: state.ReportParametersManager.Source.ctg.dataObjSetsById,
        colors: state.ReportParametersManager.Source.ctg.colors,
        dataobjsets: state.ReportParametersManager.Source.src.dataobjsets,
        customInputParamsValues: state.ReportParametersManager.customInputParamsValues,
        project_id: state.ReportParametersManager.customInputParamsValues.project_id,
        selectedPlIds: state.ReportParametersManager.customInputParamsValues.pl_ids,
        selectedPlId: state.ReportParametersManager.customInputParamsValues.pl_id,
        selectedObjIds: state.ReportParametersManager.customInputParamsValues.obj_ids,
        searchLocationsValue: state.LocationsPanel.searchLocationsValue,
        allowedAccordionSection: state.LocationsPanel.allowedAccordionSection,
        searchObjectsValue: state.LocationsPanel.searchObjectsValue,
        searchObjSetsValue: state.LocationsPanel.searchObjSetsValue,
        showLocationsSelectionPanel: state.LocationsPanel.showLocationsSelectionPanel,
        showReportingObjectsPanel: state.LocationsPanel.showReportingObjectsPanel,
        showObjSetsPanel: state.LocationsPanel.showObjSetsPanel,
        history: state.LocationsPanel.history,
        currentSection: state.TopPanel.status,
        currentSubSection: state.TopPanel.statusLeftPanel,
        sectionsByKeyName_ctg: state.TopPanel.sectionsByKeyName,
        catalog: state.ReportParametersManager.Source.ctg,
        status: state.ProjectLocationApp.status,
        locations: state.PanelLocationApp.locations,
        userOptions: state.LoginApp.options,
        token: state.LoginApp.token,
        selectedSpaces: state.OutdoorSpaces.selectedSpaces,
        isAdCampShown: state.AdCampaigns.isAdCampShown,
        campaignsToShow: state.AdCampaigns.campaignsToShow,
        singleAdCampaignSelection: state.AdCampaigns.singleSelection,
        multiAdCampaignSelection: state.AdCampaigns.multiSelection,
        adCampaignsById: state.ReportParametersManager.Source.ctg.adCampaignsById,
        whiteLabelSettings: state.LoginApp.white_label_settings,
        isShowPrecampModal: state.PreCampaigns.isShowPrecampModal,
        selectionItemReport: state.PreCampaigns.selectionItemReport
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteObjSet: (id, token) => {
            var url = `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/${id}/`;
            var headers = {
                'x-token': token,
            };
            const options = {
                method: 'DELETE',
                headers: { 'x-token': token },
            };

            fetch(url, options).then((response) =>
                dispatch(
                    runAction('getDataObjSets', {
                        url: `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/`,
                        headers,
                    })
                )
            );
        },
        onChangeObjSet: (id, name, objects, token) => {
            var url = `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/${id}/`;
            var headers = {
                'x-token': token,
            };
            const data = {
                name,
                read_access_type: 'creator_only',
                bound_dataobjs: objects,
            };

            const options = {
                method: 'PATCH',
                headers: { 'Content-type': 'application/json', 'x-token': token },
                body: JSON.stringify(data),
                credentials: 'include',
            };

            fetch(url, options).then((response) =>
                dispatch(
                    runAction('getDataObjSets', {
                        url: `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/`,
                        headers,
                    })
                )
            );
        },
        changeOutdoorAdSpaceIds: (selectedSpaces) => {
            dispatch(runAction('changeOutdoorAdSpaceIds', { selectedSpaces }));
        },
        _getAdCampaigns: (token, campaignsToShow, project_id) => {
            getAdCampaigns(token, dispatch, campaignsToShow, project_id);
        },
        changeAdCampaignIds: (single, multi) => {
            if (!single) return;
            const ad_campaign_id = single.id;
            const ad_campaign_ids = multi.map((item) => item.id);

            dispatch(runAction('changeAdCampaignIds_RPM', { ad_campaign_id, ad_campaign_ids }));
        },
        toggleMap: () => {
            dispatch(runAction('toggleMap_OutdoorSpaces', {}));
        },
        toggleAdCampaigns: () => {
            dispatch(runAction('toggleAdCampaigns_AdCampaigns', {}));
        },
        togglePreCampaigns: (status) => {
            dispatch(runAction('getStatusShowModal_PreCampaigns', {status}));
        },
        // onMainObjectClick: (id, mode) => {
        //     dispatch(runAction('onMainObjectClick_RPM', { id, mode }));
        // },
        onDeselectAdSpaceClick: (spaceId) => {
            dispatch(runAction('onSectorClick', { payload: { spaceId, firstRun: false } }));
        },
        handleTargetClick: (item) => (e) => {
            const center = { lat: item.latitude, lng: item.longitude };
            const initialZoom = 15;
            dispatch(runAction('aimOnPoint', { center, initialZoom }));
        },
        handleShowLocationsSelectionPanel: () => {
            dispatch(runAction('showLocationsSelectionPanel_LocationsPanel'));
        },
        setAllowedAccordionSection: (label) => {
            dispatch(runAction('setAllowedAccordionSection_LocationsPanel', { label }));
        },
        toggleModal(args) {
            dispatch(runAction('toggleModal', { args }));
        },
        handleShowReportingObjectsPanel: () => {
            dispatch(runAction('showReportingObjectsPanel_LocationsPanel'));
        },
        toggleExpandArrow: (id) => {
            dispatch(runAction('toggleExpandArrow_LocationsPanel', { id }));
        },

        hideSelectionPanel: () => {
            dispatch(runAction('hideSelectionPanel_LocationsPanel'));
        },
        hideObjSetsPanel: () => {
            dispatch(runAction('toggleMakeNewObjSetModal_LocationsPanel', { show: false }));
        },
        handleLocationsSearch: (text, projectLocationsById_ctg) => {
            dispatch(
                runAction('handleLocationsSearch_LocationsPanel', {
                    text,
                    projectLocationsById_ctg,
                })
            );
        },
        handleObjectsSearch: (text, plId) => {
            dispatch(runAction('handleObjectsSearch_LocationsPanel', { text, plId }));
        },
        handleObjSetsSearch: (text) => {
            dispatch(runAction('handleObjSetsSearch_LocationsPanel', { text }));
        },
        setHistory: (history) => {
            dispatch(runAction('setHistory_LocationsPanel', { history }));
        },
        changeCustomInputParams: (history, mode) => {
            dispatch(runAction('changeCustomInputParams_ReportParametersManager', { history, mode }));
        },
        toggleObjSetsPanel: (show) => {
            dispatch(runAction('toggleObjSetsPanel', { show }));
        },
        getLoadReport: async (data, selectedSpaces, outdoorAdSpaces, outdoorAdSpacesByKey, outdoorAdSpacesById) => {

            const url = `${hostForLocation.getHostName().precampaign}/precampaign_reports/get_digital_report_detailed`
            const _data = {
                "report_id": data.id
            }
            const token = cookie.get('xtoken');
            try {
                const response3 = await fetch(url, {
                    method: 'POST', // или 'PUT'
                    body: JSON.stringify(_data),
                    mode: 'cors',
                    redirect: 'follow',
                    cache: 'no-cache',
                    timeout: 600000,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token': token

                    }
                });

                dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
                const json3 = await response3.json();
                setTimeout(() => {
                    console.log('><>><><><>', json3);
                }, 3000)
                if (json3.data) {
                    console.log('Успех:', json3);
                    dispatch(runAction('getSotsDetailedDynamics_PreCampaigns', { json: {
                            data: {
                                ...json3.data.sots_preds,
                                ...json3.data.metadata
                            }
                        },  selectedSpaces, outdoorAdSpaces, outdoorAdSpacesByKey, outdoorAdSpacesById}));
                    dispatch(runAction('getAccumulativeFrequencyHist_PreCampaigns', { json2: {data: {
                                ...json3.data.frequency_preds,
                                ...json3.data.metadata
                            }}, selectedSpaces }));
                    dispatch(runAction('getUniqueMac_PreCampaigns', { json3: {data: {
                                ...json3.data.unique_preds,
                                ...json3.data.metadata,
                                unique_count_pred: [json3.data.unique_preds.unique_count_pred]
                            }}, selectedSpaces }));
                }
                else {
                    dispatch(runAction('clearData_PreCampaigns', {}));
                }

                // dispatch(runAction('getUniqueMac_PreCampaigns', {json3, selectedSpaces}));
            } catch (error) {
                console.error('Ошибка:', error);
                dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
                alert(error)
            }
            // dispatch(runAction('toggleObjSetsPanel', { show }));
        },
    };
};

const ConnectPlPanel = connect(mapStateToProps, mapDispatchToProps)(PlPanel);

export default ConnectPlPanel;
