import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Map, TileLayer, LayersControl, AttributionControl, Pane } from 'react-leaflet';
import useParentSize from '../../tools/use_parent_size';
import { prepareOutdoorMapData, get_heat_color } from '../../tools/diagramTools';

import '../../../outdoor-spaces/leaflet/leaflet.css';
import '../../../outdoor-spaces/style/markercluster-styles.css';

import Rainbow from '../../../rainbow';

const DataViewOutdoorMap = React.memo(({ ...props }) => {
    const targetRef = useRef();
    const mapRef = useRef(null);
    const [mapCenter, setMapCenter] = useState({ lat: 55.765158, lng: 37.544809 });
    const [shapes, setShapes] = useState([]);
    const [maxValue, setMaxValue] = useState(100);
    const [mapKey, setMapKey] = useState(Math.random());
    const [minValue, setMinValue] = useState(0);
    const [currentZoom, setCurrentZoom] = useState(4);
    const parentDimensions = useParentSize(targetRef, props.fullScreenShow);

    const logSizes = props.indicators.filter(item => item.name === 'Размерное представление')[0]
        .show;

    useEffect(() => {
        setMapKey(Math.random());
    }, [props.fullScreenShow]);

    useEffect(() => {
        const result = prepareOutdoorMapData(
            props.data,
            props.dataView.data_report.data_type.key_name,
            {
                reportingObjectsById: props.reportingObjectsById,
                mapRef,
                currentZoom,
                logSizes
            }
        );

        result && result.mapCenter && setMapCenter(() => result.mapCenter);
        result && result.shapes && setShapes(() => result.shapes);
        if (result && result.params) {
            const maxValue = Math.max(...result.params.map(item => Number(item.value)));
            const minValue = Math.min(...result.params.map(item => Number(item.value)));
            maxValue &&
                !isNaN(maxValue) &&
                Math.abs(maxValue) !== Infinity &&
                setMaxValue(maxValue);
            minValue &&
                !isNaN(minValue) &&
                Math.abs(minValue) !== Infinity &&
                setMinValue(minValue);
        }
    }, [currentZoom, logSizes]);

    const onZoom = () => {
        const currentZoom = mapRef.current.leafletElement._zoom;
        setCurrentZoom(currentZoom);
    };

    const isShapes = shapes && shapes.length !== 0;

    return props.data ? (
        <MapContainer ref={targetRef}>
            <Map
                key={mapKey}
                center={mapCenter}
                zoom={currentZoom}
                maxZoom={16}
                ref={mapRef}
                onZoom={onZoom}
                // attributionControl={false}
                // onMouseMove={onMapMouseMove}
                // onClick={onMapClick}
            >
                {!isShapes ? null : (
                    <Pane name={'Circles'} style={{ zIndex: 601 }}>
                        {shapes}
                    </Pane>
                )}
                {/* <AttributionControl position="bottomright" prefix="" /> */}
                <LayersControl position="topright" onchange={e => console.log(e.target)}>
                    <LayersControl.BaseLayer
                        name="Black and White"
                        checked={true}
                        key={'LayersControl.BaseLayer-1'}
                    >
                        <TileLayer
                            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                            attributionPrefix={false}
                            subdomains={'abcd'}
                            ext={'png'}
                            minZoom={0}
                            maxZoom={20}
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Color" key={'LayersControl.BaseLayer-2'}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            attributionPrefix={false}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>

                {/* <LayersControl position="topright">{renderMaps}</LayersControl> */}

                {/* <Portal position="topleft">
                <ZoomWorldControl onZoomWorldClick={onZoomWorldClick}>2</ZoomWorldControl>
            </Portal> */}
                {/* <Portal position="bottomleft">
                <CursorCoords>{`Cursor coordinates: lat: ${cursorCoords.lat}  lng: ${cursorCoords.lng}`}</CursorCoords>
            </Portal> */}
            </Map>
            {isShapes ? (
                <Rainbow maxValue={maxValue} minValue={minValue} colorFoo={get_heat_color} />
            ) : null}
        </MapContainer>
    ) : null;
});

export default DataViewOutdoorMap;

const MapContainer = styled.div`
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: calc(100% - 32px);
    box-sizing: border-box;
    .leaflet-container {
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }
`;
