import React, { useRef, useContext, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DiagramsContext } from '../index';
import { generateSize } from '../tools/diagramTools';

const Plate = React.memo(({ dataView, groupId, id, iteration = 0, iterationsNumber = 1, padding = '0px', ...props }) => {
    const {
        defaultSize,
        reportData,
        diagramOptionsByID,
        fullScreenId,
        showDiagramParamsPanel,
        DiagramParamsPanelId,
        cabinetMode,
    } = useContext(DiagramsContext);

    const plateRef = useRef();
    console.log('!>!>!>!>!>!')
    useLayoutEffect(() => {

        if (plateRef.current && cabinetMode === '-pdf') {
            const position = plateRef.current.offsetTop + plateRef.current.offsetHeight;
            const height = plateRef.current.offsetHeight;
            const plateId = id;
            // const plateId = dataView.data_view_id;

            props.getPlateEndPosition({ groupId, plateId, position, height , dataView});
        }
    }, [plateRef, plateRef.current && plateRef.current.offsetTop, plateRef.current && plateRef.current.offsetHeight]);

    const stopPropagationClick = (e) => {
        e.stopPropagation();
    };

    const getFreeHeight = (dataView, data) => {
        switch (dataView.data_view_type.key_name) {
            case 'dynamics_diagram':
                return data && data.data && data.data[0] && data.data[0].data_aspects.object.relation === 'detalization' ? true : false;
            case 'funnel_diagram':
                return dataView.data_report.data_type.key_name === 'funnel_set' ? true : false;
            case 'table_data_view':
            case 'markdown_data_view':
            case 'mutual_intersections_chord_diagram':
            case 'mutual_intersections_heatmap_diagram':
            case 'dynamics_calendar_diagram':
                return true;
            default:
                return false;
        }
    };
    const getMinHeight = (dataView, data) => {
        switch (dataView.data_view_type.key_name) {
            case 'table_data_view':
            case 'mutual_intersections_chord_diagram':
            case 'mutual_intersections_heatmap_diagram':
            case 'dynamics_calendar_diagram':
                return false;
            default:
                return true;
        }
    };

    let isSpinner, isSuccess;

    if (defaultSize && reportData && reportData[dataView.data_view_id]?.response && reportData[dataView.data_view_id].isSuccess) {
        isSuccess = reportData[dataView.data_view_id].isSuccess;
        isSpinner = false;
    } else if (defaultSize && reportData && reportData[dataView.data_view_id]?.response === undefined) {
        isSuccess = false;
        isSpinner = false;
    } else if (!reportData[dataView.data_view_id]?.isSuccess && reportData[dataView.data_view_id]?.error) {
        isSpinner = false;
    } else {
        isSpinner = true;
    }

    const keepRatio = diagramOptionsByID[dataView.data_view_id].fullScreen.keepRatio;
    const fullScreenShow = dataView.data_view_id === fullScreenId;
    const showOutline = showDiagramParamsPanel && DiagramParamsPanelId === dataView.data_view_id;
    const dimPlate = showDiagramParamsPanel && DiagramParamsPanelId !== dataView.data_view_id;
    const isFreeHeight = !isSpinner && isSuccess ? getFreeHeight(dataView, reportData[dataView.data_view_id].response) : false;
    const isMinHeight = !isSpinner && isSuccess ? getMinHeight(dataView, reportData[dataView.data_view_id].response) : true;
    const size = generateSize(dataView, defaultSize, cabinetMode);

    return (
        <Container
            iteration={iteration}
            iterationsNumber={iterationsNumber}
            ref={plateRef}
            showOutline={showOutline}
            dimPlate={dimPlate}
            onClick={stopPropagationClick}
            keepRatio={keepRatio}
            fullScreenShow={fullScreenShow}
            isFreeHeight={isFreeHeight}
            isMinHeight={isMinHeight}
            fullScreenId={fullScreenId}
            size={size}
            padding={padding}
        >
            {props.children}
        </Container>
    );
});

export default Plate;

const Container = styled.div`
    overflow: hidden;
    transition: opacity 300ms ease-in-out;
    outline: ${(p) => (p.showOutline ? '2px solid rgb(75, 218, 75)' : 'none')};
    opacity: ${(p) => {
        if (p.fullScreenShow && p.fullScreenId !== null) {
            return '1';
        } else if (!p.fullScreenShow && p.fullScreenId !== null) {
            return '0';
        } else if (p.fullScreenId === null) {
            return p.dimPlate ? '0.3' : '1';
        }
    }};
    position: ${(p) => (p.fullScreenId !== null ? 'absolute' : 'relative')};
    z-index: ${(p) => (p.fullScreenShow ? '302' : 'none')};
    left: 10px;
    top: 10px;
    width: ${(p) => {
        if (p.size === null) {
            return '1100px';
        } else {
            return p.fullScreenShow ? 'calc(100% - 20px)' : p.size.width + 'px';
        }
    }};
    height: ${(p) => {
        if (p.size === null) {
            return 'auto';
        }
        if (p.fullScreenShow && !p.keepRatio) {
            return 'calc(100% - 40px)';
        }
        if (p.fullScreenShow && p.keepRatio) {
            return 'auto';
        }
        if (!p.isFreeHeight) {
            // console.log(p.dataView.data_view_type.key_name, p.size.height);

            return p.size.height + 'px';
        } else {
            return 'auto';
        }
    }};
    min-height: ${(p) => {
        if (p.size === null) {
            return 'auto';
        }
        if (p.isMinHeight) {
            return p.size.height + 'px';
        } else {
            return 'auto';
        }
    }};
    max-height: calc(100% - 40px);
    background: #fff;
    padding: ${(p) => p.padding};
    box-sizing: border-box;
    /* box-shadow: 8px 0 4px -4px rgba(0, 0, 0, 0.1), -8px 0 4px -4px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); */

    flex-shrink: 0;
    margin-right: 16px;

    &:first-child {
        box-shadow: ${(p) => {
            if (p.iterationsNumber === 1) {
                return '0px 0px 12px rgba(0, 0, 0, 0.1)';
            } else {
                return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)';
            }
        }};
    }

    &:last-child {
        box-shadow: ${(p) => {
            if (p.iterationsNumber === 1) {
                return '0px 0px 12px rgba(0, 0, 0, 0.1)';
            } else {
                return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)';
            }
        }};
    }

    &:only-child {
        box-shadow: ${(p) => {
            return '0px 0px 12px rgba(0, 0, 0, 0.1)';
        }};
    }

    box-shadow: ${(p) => {
        if (p.iterationsNumber === 1) {
            return '0px 0px 12px rgba(0, 0, 0, 0.1)';
        } else if (p.iteration === p.iterationsNumber - 1) {
            return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)';
        } else if (p.iteration === 0) {
            return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)';
        } else {
            return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06)';
        }
    }};
    margin-bottom: ${(p) => {
        if (p.iterationsNumber === 1 || p.iteration === p.iterationsNumber - 1) {
            return '16px';
        } else {
            return '0px';
        }
    }};
    display: flex;
    flex-direction: column;
`;
