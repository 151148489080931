import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import './style/styleReportingObjects.scss';
import { connect } from 'react-redux';
import { runAction } from '../../../actions';
import Dash from '../pl_panel/components/dash';
import RoTitle from '../pl_panel/components/panel_title';
import ObjectsList from './components/objects_list';
import Search from '../pl_panel/components/search';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 243px;
    height: calc(100% - 50px);
    background: #fff;
    position: fixed;
    overflow: auto;
    left: 238px;
    padding: 10px;
    top: 50px;
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 301;
`;

const ReportingObjectsPanel = ({
    projectLocationsById,
    activePlId,
    reportingObjectsByPlId,

    lang,
    handleDashClick,
    history,
    toggleDropDownMenu,
    onSelectAllObjectsClick,
    onClearAllObjectsClick,
    searchObjectsValue,
    ...props
}) => {
    const { t } = useTranslation();
    const title = projectLocationsById[activePlId].name;
    const mainServicepointId = reportingObjectsByPlId[activePlId].main_servicepoint.id;
    const statusSelect = history.locations[activePlId].objects.includes(mainServicepointId);

    const onMainSPClick = (...args) => {
        handleDashClick(...args, true);
    };

    return (
        <Container>
            <RoTitle title={title} />
            <Search searchValue={searchObjectsValue} handleSearch={props.handleSearch} lang={lang} />
            <div className="headerDash">
                <Dash
                    showDeleteButtons={false}
                    statusSelect={statusSelect}
                    id={mainServicepointId}
                    plId={activePlId}
                    text={t('Весь объект')}
                    onClick={onMainSPClick}
                />
            </div>
            <ObjectsList
                searchValue={searchObjectsValue}
                onObjectDashClick={handleDashClick}
                toggleDropDownMenu={toggleDropDownMenu}
                reportingObjectsByPlId={reportingObjectsByPlId}
                history={history}
                plId={activePlId}
                onSelectAllObjectsClick={onSelectAllObjectsClick}
                onClearAllObjectsClick={onClearAllObjectsClick}
            />
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        projectLocationsById: state.ReportParametersManager.Source.ctg.projectLocationsById,
        reportingObjectsByPlId: state.ReportParametersManager.Source.ctg.reportingObjectsByPlId,
        customInputParamsValues: state.ReportParametersManager.customInputParamsValues,

        statusDropDownMenuOne: state.PanelReportingsApp.statusDropDownMenuOne,
        statusDropDownMenuTwo: state.PanelReportingsApp.statusDropDownMenuTwo,
        statusDropDownMenuThree: state.PanelReportingsApp.statusDropDownMenuThree,
        titlePanelReporting: state.PanelLocationApp.titlePanelReporting,
        statusSelectDashAllObject: state.PanelLocationApp.statusSelectDashAllObject,
        objZonesGroupMarker: state.PanelLocationApp.objZonesGroupMarker,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // handleDashClick: (id) => {
        //   dispatch(runAction('handleDashClick', { id }));
        // },

        onClickDropDown: (status) => {
            dispatch(runAction('getStatusPanelDropDownOne', { status: status }));
        },
        onClickDropDownTwo: (status) => {
            dispatch(runAction('getStatusPanelDropDownTwo', { status: status }));
        },
        onClickDropDownThree: (status) => {
            dispatch(runAction('getStatusPanelDropDownThree', { status: status }));
        },
        onClickDropDownZones: (marker, status) => {
            dispatch(runAction('onClickDropDownZones', { marker: marker, status: status }));
        },
        onClickDash: (prop) => {
            dispatch(runAction('onClickDash', { data: prop }));
        },
        onClickDashAllObject: () => {
            dispatch(runAction('onClickDashAllObject'));
        },
        onSelectDeselectAllClick: (data, isSelect) => {
            dispatch(runAction('onSelectDeselectAllClick', { data, isSelect }));
        },
    };
};

const ConnectReportingObjectsPanel = connect(mapStateToProps, mapDispatchToProps)(ReportingObjectsPanel);

export default ConnectReportingObjectsPanel;
