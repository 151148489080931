import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

const ColorDot = React.memo(({ showDeleteObjectsButtons, dotColor, margin = '0 5px 0 auto', ...props }) => {
    const show = dotColor || showDeleteObjectsButtons;
    if (!show) return null;
    const onClick = (e) => {
        e.stopPropagation();
        if (showDeleteObjectsButtons) {
            props.onClick();
        }
    };
    return useMemo(() => {
        return (
            <MainWrapper margin={margin} dotColor={dotColor} showDeleteObjectsButtons={showDeleteObjectsButtons} onClick={onClick}>
                <CrossLine showDeleteObjectsButtons={showDeleteObjectsButtons} rotate={'45deg'} />
                <CrossLine showDeleteObjectsButtons={showDeleteObjectsButtons} rotate={'-45deg'} />
            </MainWrapper>
        );
    }, [dotColor, showDeleteObjectsButtons]);
});

export default ColorDot;

const MainWrapper = styled.div.attrs((p) => ({
    style: {
        backgroundColor: p.dotColor ? p.dotColor : '#bbb',
        margin: p.margin,
        cursor: p.showDeleteObjectsButtons ? 'pointer' : 'auto',
    },
}))`
    flex-shrink: 0;
    position: relative;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    /* background-color: ${(p) => (p.dotColor ? p.dotColor : '#bbb')}; */
    :hover {
        background-color: ${(p) => {
            if (p.showDeleteObjectsButtons) return 'red';
        }};
    }
`;

const CrossLine = styled.div`
    display: ${(p) => (p.showDeleteObjectsButtons ? 'block' : 'none')};
    border-radius: 1px;
    width: 2px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    top: 1px;
    left: 5px;
    transform: ${(p) => `rotate(${p.rotate})`};
`;
