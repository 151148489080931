import { runAction } from "../../../../actions";

// var AppActions = require('../actions/actions.js');
// var AppStore = require('../stores/stores.js');


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect} from 'react-redux';
import GeometryVSDataManager from "../../../../tools/GeometryVSDataManager";


class Tabs extends Component {
    static defaultProps = {

    };

    static propTypes = {};

    state = {
        statusShowAnalitics: 'maps'
	};

    internalState = {
        isMounted: false
    }

    // onClickMaps() {
    //     if (this.state.statusShowAnalitics === 'route') {
    //         AppActions.showAnalitiscMethod('maps');
    //     }
	// }

    // onClickRoute() {
    //     if (this.state.statusShowAnalitics === 'maps') {
    //         AppActions.showAnalitiscMethod('route');
    //     }
	// }

    // _onChange() {
    //     if (this.internalState.isMounted === true) {
    //         this.setState({
    //             statusShowAnalitics: AppStore.getState().statusAnalitics
    //         });
    //     }
	// }

    componentDidMount() {
        this.internalState.isMounted = true;
	}

    componentWillUnmount() {
        this.internalState.isMounted = false;
	}

    render() {
        console.log('tabs', this.props.statusShowAnalitics)
        var classNameRouteButton = 'activeButton', classNameMapsButton = 'activeButton';
        if (this.props.statusShowAnalitics === 'route') {
            classNameMapsButton = 'notActiveButton';
        }
        else if (this.props.statusShowAnalitics === 'maps') {
            classNameRouteButton = 'notActiveButton';
        }
        return(
            <div className='tabMaps'>



                <button className={classNameMapsButton} onClick={(e) => this.props.onClickTab('maps')} type='button'>{this.props._t.t("Пересечения")}</button>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        statusShowAnalitics: state.MapsApp.statusAnalitics,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickTab: (status) => {
            dispatch(runAction('onClickTab', {status: status}));
        }

    }
};

const ConnectTabs = connect(
    mapStateToProps,
    mapDispatchToProps
)(Tabs);

export default ConnectTabs;

