import React, { useState } from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import useHistory from './useHistory';

const MakeNewObjSetModal = React.memo(props => {
    const [name, setName] = useState('');
    const { objects, canSaveSet } = useHistory(props.reportingObjectsById, props.history);

    const onContainerClick = e => {
        e.stopPropagation();
    };

    const handleNameChange = e => {
        const name = e.target.value;
        setName(name);
    };

    const createNewObjSet = () => {
        props.onModalButtonClick({ name: 'makeNewObjSet' }, { name, objects });
    };

    return (
        <Container onClick={onContainerClick}>
            <Main>
                {canSaveSet ? (
                    <TextInput
                        autoFocus={'autofocus'}
                        type={'text'}
                        placeholder={_t.t('Название набора')}
                        value={name}
                        onChange={handleNameChange}
                    />
                ) : (
                    <div>{_t.t('Невозможно сохранить в один сет!')}</div>
                )}
            </Main>
            <Footer>
                <PrimaryButton disabled={!canSaveSet} onClick={createNewObjSet}>
                    {_t.t('Создать')}
                </PrimaryButton>
            </Footer>
        </Container>
    );
});

export default MakeNewObjSetModal;

const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const TextInput = styled.input`
    box-sizing: border-box;
    background-color: whitesmoke;
    width: 100%;
    min-height: 30px;
    padding: 10px;
    font-size: 16px;
    outline: none;
    border: none;
    border-bottom: 1px solid #1e90ff;
    margin-bottom: 10px;
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`;

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
`;
