const fillBackend_EngineeringMenu = (action, state) => {
    const { backend } = action.payload;
    return { ...state, backend };
};

let stateInit = {
    backend: {},
};

const EngineeringMenu = (state = stateInit, action) => {
    switch (action.type) {
        case 'fillBackend_EngineeringMenu':
            return fillBackend_EngineeringMenu(action, state);
        default:
            return state;
    }
};
export default EngineeringMenu;
