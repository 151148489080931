import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlItem from './pl_item'
import styled from 'styled-components/macro'
import ObjReportItem from './obj_report_item'
import GlobalParameterItem from './global_parameter_item'
var moment = require('moment');


const ClearBoth = styled.div`
clear: both;
display:block;
height:0px;
width: 100%;
`

class BodyPanel extends PureComponent {

    renderForGlobalParameter(getCountGlobalParams) {

        const {
            statusTopPanel,
            groupIDsBySubsectionKeyName,
            dataViewsByGroupID,
            inputParameters,
            // _t,
            // lang,
            // onInputParameterChange,
            globalInputParamsValues
        } = this.props;
        let arrayGlobalParams = []
        if (groupIDsBySubsectionKeyName[statusTopPanel] !== undefined) {
            const globalParams = [];
            groupIDsBySubsectionKeyName[statusTopPanel].forEach((item, i) => {
                dataViewsByGroupID[item].forEach(dataView => {
                    globalParams.push(dataView.data_report.input_parameters.filter(item => item.input_parameter_type === 'global'));
                });
            });
            const globalParamsSet = Array.from(
                new Set(
                    globalParams.reduce((acc, item) => {
                        return item.length !== 0 ? [...acc, ...item.map(param => param.key_name)] : acc;
                    }, [])
                )
            );
            let componentsGlobalParams = globalParamsSet.map((data, index) => {
                arrayGlobalParams.push(`pdfGlobalParam${index}`)
                return(
                    <GlobalParameterItem
                        parameter={inputParameters[data]}
                        lang={this.props.lang}
                        key={`pdfGlobalParams${index}`}
                        id={`pdfGlobalParam${index}`}
                        // onInputParameterChange={onInputParameterChange}
                        globalInputParamsValues={globalInputParamsValues}
                    />
                )
            })
            return componentsGlobalParams
        }
        getCountGlobalParams(arrayGlobalParams)
        return null;
    }

    render() {
        let componentsPl =Object.values(this.props.objLocationSelect).map((data, index) => {
            return (<PlItem data={data} key={`plPdf${index}`}/>)
        })
        const {
            statusTopPanel,
            groupIDsBySubsectionKeyName,
            dataViewsByGroupID,
            inputParameters,
            // _t,
            // lang,
            // onInputParameterChange,
            globalInputParamsValues
        } = this.props;
        let arrayGlobalParams = []
        let componentsGlobalParams = null
        if (groupIDsBySubsectionKeyName[statusTopPanel] !== undefined) {
            const globalParams = [];
            groupIDsBySubsectionKeyName[statusTopPanel].forEach((item, i) => {
                dataViewsByGroupID[item].forEach(dataView => {
                    globalParams.push(dataView.data_report.input_parameters.filter(item => item.input_parameter_type === 'global'));
                });
            });
            const globalParamsSet = Array.from(
                new Set(
                    globalParams.reduce((acc, item) => {
                        return item.length !== 0 ? [...acc, ...item.map(param => param.key_name)] : acc;
                    }, [])
                )
            );
            componentsGlobalParams = globalParamsSet.map((data, index) => {
                arrayGlobalParams.push(`pdfGlobalParam${index}`)
                return(
                    <GlobalParameterItem
                        parameter={inputParameters[data]}
                        lang={this.props.lang}
                        key={`pdfGlobalParams${index}`}
                        id={`pdfGlobalParam${index}`}
                        // onInputParameterChange={onInputParameterChange}
                        globalInputParamsValues={globalInputParamsValues}
                    />
                )
            })
            // return componentsGlobalParams
        }
        let locations = this.props.locations
        let arrReportIds = []
        for (var i = 0; i < locations.length; i++) {

            if (this.props.objLocationSelect[locations[i].id] !== undefined) {

                if (locations[i].allObject.id !== null) {
                    arrReportIds.push(locations[i].allObject.id)
                }
                if (locations[i].detail !== undefined) {
                    for (var key in locations[i].detail) {
                        if (key === 'floors' || key === 'servicepoints' || key === 'zones') {
                            for (var j = 0; j < locations[i].detail[key].length; j++) {

                                if (locations[i].detail[key][j].select === true) {
                                    arrReportIds.push(locations[i].detail[key][j].id)
                                }
                            }
                        }
                    }
                }
            }
        }





        let arrayLocationsForpdf = []
        let componentsObjReports = arrReportIds.map((data, index) => {
            arrayLocationsForpdf.push(`pdfReportLocations${index}`)
            return (<ObjReportItem id={`pdfReportLocations${index}`} data={this.props.objDataByObjId[data]} key={`objREportPdf${index}`}/>)
        })
        let objParamsAndLocations = {
            globalParams: arrayGlobalParams,
            pdf_locations: arrayLocationsForpdf
        }
        return (
            <div className={'bodyPanelPdf'}>
                <input type={'hidden'} id={'DataArrayParamsForPdf'} className="dataCountPdf" value={JSON.stringify(objParamsAndLocations)}/>
                <div className="dateBox bodyPanelPdfitem">
                    <h2>{this.props._t.t('Параметры отчета')}</h2>
                    <div className="itmeRow">
                        <div className="itmeRowCount">
                            <div className="leftItemRow">
                                <span className={'itemRowChiled '}>{this.props._t.t('Отчетный период')}: </span>
                            </div>
                            <div className="rightItemRow">
                                <div className="itemRowChiled dateMainFrom">{moment(this.props.selected.start).format("YYYY-MM-YY")}</div>
                                <div className={'itemRowChiled '}>&nbsp; - &nbsp;</div>
                                <div className="itemRowChiled dateMainTo">{moment(this.props.selected.end).format("YYYY-MM-YY")}</div>
                                <ClearBoth/>
                            </div>
                            <ClearBoth/>
                        </div>
                    </div>
                    <div className="itmeRow">
                        <div className="itmeRowCount">
                            <div className="leftItemRow">
                                <span className={'itemRowChiled '}>{this.props._t.t('Период сравнения')}: </span>
                            </div>
                            <div className="rightItemRow">
                                <div className="itemRowChiled dateCompareFrom">{moment(this.props.selected2.start).format("YYYY-MM-YY")}</div>
                                <div className={'itemRowChiled '}>&nbsp; - &nbsp;</div>
                                <div className="itemRowChiled dateCompareTo">{moment(this.props.selected2.end).format("YYYY-MM-YY")}</div>
                                <ClearBoth/>
                            </div>
                            <ClearBoth/>
                        </div>
                    </div>
                    {componentsGlobalParams}
                </div>
                <div className="dateBox bodyPanelPdfitem padding">
                    <h2>{this.props._t.t('Локации')}</h2>
                    <div className="itmeRow locations">
                        {componentsObjReports}
                    </div>

                    <ClearBoth/>
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        objLocationSelect: state.PanelLocationApp.objLocationSelect,
        objLocationControlSelect:state.PanelLocationApp.objLocationControlSelect,
        selected:{
            start:state.ReportParametersManager.customInputParamsValues.main_date_range[0],
            end:state.ReportParametersManager.customInputParamsValues.main_date_range[1]
        },
        selected2:{
            start:state.ReportParametersManager.customInputParamsValues.comparison_date_range[0],
            end:state.ReportParametersManager.customInputParamsValues.comparison_date_range[1]
        },
        objDataByObjId:state.PanelLocationApp.objDataByObjId,
        locations: state.PanelLocationApp.locations,

        groupIDsBySubsectionKeyName: state.ReportParametersManager.groupIDsBySubsectionKeyName,
        dataViewsByGroupID: state.ReportParametersManager.dataViewsByGroupID,
        inputParameters: state.ReportParametersManager.inputParameters,
        globalInputParamsValues: state.ReportParametersManager.globalInputParamsValues,
        statusTopPanel: state.TopPanel.status,
        // lang:state.TopPanel.lang
        lang:state.TopPanel.lang,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // loadOutdoorSpaces: data => {
        //     var headers = {
        //         // "x-token":token
        //     };
        //     const url = '';
        //
        // }

    };
};

const ConnectBodyPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(BodyPanel);
BodyPanel.propTypes = {};

export default ConnectBodyPanel;
