import Tools from '../tools/tools';
var moment = require('moment');
Date.prototype.yyyymmdd = function() {
    var yyyy = this.getFullYear().toString();
    var mm = (this.getMonth() + 1).toString();
    var dd = this.getDate().toString();
    return yyyy + ':' + (mm[1] ? mm : "0" + mm[0]) + ':' + (dd[1] ? dd : "0" + dd[0]);
};

let initState = {
    dataPlan: {
        id: -1,
        page_id: 0,
        objetct_ids: [],
        time_period: {
            from: "12:00",
            to: "16:00",
            always: true
        },
        week_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        date_period: {
            from: moment().format('YYYY:MM:DD'),
            to: moment().add(7, 'days').format('YYYY:MM:DD'),
            always: true
        }
    },
    weekDaysObj: {Monday: 'Monday', Tuesday: 'Tuesday', Wednesday: 'Wednesday', Thursday: 'Thursday', Friday: 'Friday', Saturday: 'Saturday', Sunday: 'Sunday'},
    statusShowMinCalendar: false,
    statusShowMaxCalendar: false,
    titleTemplateOfPlan: null,
    time: {
        max: "16:00",
        min: "12:00"
    },
    statusShowPlanPanel: false,
    dataMallId: [],
    dataMallsId: [],
    xToken: null,
    statusLoadPlan: false,
    statusEdit: false,
    browserLangs: {
        ru:false,
        en:false,
        zh:false,
        es:false
    },
    browserLangsForAnyButton:null,
};

const openPanel = (state, action) => {
    let statusEdit = Tools.copy(state.statusEdit);
    let dataPlan = Tools.copy(state.dataPlan);
    let weekDaysObj = Tools.copy(state.weekDaysObj);
    let browserLangs = Tools.copy(state.browserLangs);
    if (statusEdit === true) {
        statusEdit = false
    }
    else if (statusEdit === false) {
        dataPlan = {
            id: -1,
            page_id: action.id,
            objetct_ids: [],
            time_period: {
                from: "12:00",
                to: "16:00",
                always: true
            },
            week_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            date_period: {
                from: moment().format('YYYY:MM:DD'),
                to: moment().add(7, 'days').format('YYYY:MM:DD'),
                always: true
            }
        };
        weekDaysObj = {Monday: 'Monday', Tuesday: 'Tuesday', Wednesday: 'Wednesday', Thursday: 'Thursday', Friday: 'Friday', Saturday: 'Saturday', Sunday: 'Sunday'};
    }
    
    dataPlan.objetct_ids = state.dataMallId;
    browserLangs = {
        ru:false,
        en:false,
        zh:false,
        es:false
    }
    let browserLangsForAnyButton = null;
    return Object.assign({}, state, {
        browserLangsForAnyButton:browserLangsForAnyButton,
        statusEdit:statusEdit,
        dataPlan:dataPlan,
        weekDaysObj:weekDaysObj,
        browserLangs:browserLangs,
        statusShowPlanPanel:true,
        titleTemplateOfPlan:action.title
    })
}

const getTimeMax = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    dataPlan.time_period.to = action.data.timeString;
    dataPlan.time_period.always = false;
    return Object.assign({}, state, {
        dataPlan:dataPlan
    })
}

const getTimeMin = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    dataPlan.time_period.from = action.data.timeString;
    dataPlan.time_period.always = false;
    return Object.assign({}, state, {
        dataPlan:dataPlan
    })
}

const selectAllDay = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    dataPlan.week_days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return Object.assign({}, state, {
        dataPlan:dataPlan
    })
}

const onClickButtonAllLang = (state, action) => {
    let browserLangs = {
        ru:false,
        en:false,
        zh:false,
        es:false
    };
    state.browserLangsForAnyButton = null
    return Object.assign({}, state, {
        browserLangsForAnyButton:null,
        browserLangs:browserLangs
    })
}

const selectAllTime = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    if (dataPlan.time_period.always === true) {
        dataPlan.time_period.always = false;
    }
    else if (dataPlan.time_period.always === false) {
        dataPlan.time_period.always = true;
    }
    return Object.assign({}, state, {
        dataPlan:dataPlan
    })
}

const selectAllDate = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    if (dataPlan.date_period.always === true) {
        dataPlan.date_period.always = false;
    }
    else if (dataPlan.date_period.always === false) {
        dataPlan.date_period.always = true;
    }
    return Object.assign({}, state, {
        dataPlan:dataPlan
    })
}

const resetData = (state, action) => {
    let dataPlan = {
        id: -1,
        page_id: 0,
        objetct_ids: [],
        time_period: {
            from: "12:00",
            to: "16:00",
            always: true
        },
        week_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        date_period: {
            from: moment().format('YYYY:MM:DD'),
            to: moment().add(7, 'days').format('YYYY:MM:DD'),
            always: true
        }
    };

    return Object.assign({}, state, {
        dataPlan:dataPlan,
        statusEdit:false
    })
}

const setStatusPlanPanel = (state, action) => {


    return Object.assign({}, state, {
        statusShowPlanPanel: action.status
    })
}

const getDay = (state, action) => {
    var index = null;
    let  dataPlan = Tools.copy(state.dataPlan)
    let weekDaysObj = Tools.copy(state.weekDaysObj)
    if (dataPlan.week_days.length > 1) {
        for (var i = 0; i < dataPlan.week_days.length; i++) {
            // console.log('store.js');
            if (dataPlan.week_days[i] === action.day) {
                index = i;
                dataPlan.week_days.splice(i, 1);
                delete weekDaysObj[action.day];
            }

        }
    }
    // console.log('store.js>>?', index);
    if (index === null) {
        if (action.day === 'Monday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Monday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Tuesday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Tuesday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Wednesday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Wednesday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Thursday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Thursday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Friday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Friday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Saturday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Saturday');
                weekDaysObj[action.day] = action.day;
            }
        }
        else if (action.day === 'Sunday') {
            if (weekDaysObj[action.day] === undefined) {
                dataPlan.week_days.push('Sunday');
                weekDaysObj[action.day] = action.day;
            }
        }
    }
    return Object.assign({}, state, {
        dataPlan: dataPlan,
        weekDaysObj:weekDaysObj
    })
}

const showMaxCalendar = (state, action) => {
    let statusShowMaxCalendar = Tools.copy(state.statusShowMaxCalendar);
    let statusShowMinCalendar = Tools.copy(state.statusShowMinCalendar);
    let dataPlan = Tools.copy(state.dataPlan)
    if (statusShowMaxCalendar === true) {
        statusShowMaxCalendar = false;
        statusShowMinCalendar = false;
    }
    else if (statusShowMaxCalendar === false) {
        statusShowMaxCalendar = true;
        statusShowMinCalendar = false;
    }
    dataPlan.date_period.always = false;
    return Object.assign({}, state, {
        dataPlan: dataPlan,
        statusShowMaxCalendar:statusShowMaxCalendar,
        statusShowMinCalendar:statusShowMinCalendar
    })
}

const getDate = (state, action) => {
    let dataPlan = Tools.copy(state.dataPlan)
    dataPlan.date_period.from = action.data.min;
    dataPlan.date_period.always = false;
    dataPlan.date_period.to = action.data.max;
    return Object.assign({}, state, {
        dataPlan: dataPlan,

    })
}

const getBrowserLang = (state, action) => {
    let browserLangsForAnyButton = Tools.copy(state.browserLangsForAnyButton)
    let browserLangs = Tools.copy(state.browserLangs)
    if (browserLangsForAnyButton !== action.lang) {
        browserLangsForAnyButton = action.lang;
    }
    else if (browserLangsForAnyButton !== action.lang) {
    }
    browserLangs = {
        ru:false,
        en:false,
        zh:false,
        es:false
    }
    browserLangs[action.lang] = !browserLangs[action.lang];
    return Object.assign({}, state, {
        browserLangsForAnyButton: browserLangsForAnyButton,

        browserLangs:browserLangs
    })
}

const onSave = (state, action) => {
    // console.log('>>>>>>>>>', action.t)
    if (action.status === 'ok') {
        return Object.assign({}, state, {
            statusShowPlanPanel: false
        })
    }
    else {
        alert(action.t.t('не сохранено'));
        return Object.assign({}, state, {
            statusShowPlanPanel: true
        })
    }

}

const editPlan = (state,action) => {
    let statusEdit = Tools.copy(state.statusEdit);
    let dataPlan;
    let weekDaysObj = Tools.copy(state.weekDaysObj)
    let browserLangs;
    let browserLangsForAnyButton;
    statusEdit = true;
    if (action.data.date_period.from === null && action.data.date_period.to === null) {
        action.data.date_period.from = moment().format('YYYY:MM:DD')
        action.data.date_period.to = moment().add(7, 'days').format('YYYY:MM:DD')
    }
    dataPlan = {
        id: action.data.id,
        page_id: action.data.page_id,
        objetct_ids: action.data.object_ids,
        time_period: action.data.time_period,
        week_days: action.data.week_days,
        date_period: action.data.date_period
    };
    weekDaysObj = {};
    for (var i = 0; i < action.data.week_days.length; i++) {
        weekDaysObj[action.data.week_days[i]] = action.data.week_days[i];
    }
    if (action.data.browser_lang_code !== undefined) {
        browserLangs = {
            ru:false,
            en:false,
            zh:false,
            es:false
        }
        browserLangsForAnyButton = action.data.browser_lang_code;
        browserLangs[action.data.browser_lang_code] = true;
    }
    else {
        browserLangs = {
            ru:false,
            en:false,
            zh:false,
            es:false
        }
        browserLangsForAnyButton = null;
    }

    return Object.assign({}, state, {
        statusEdit:statusEdit,
        dataPlan:dataPlan,
        weekDaysObj:weekDaysObj,
        browserLangs:browserLangs,
        browserLangsForAnyButton:browserLangsForAnyButton,
        statusShowPlanPanel:true,
        titleTemplateOfPlan: action.data.page_title
    })
}



const PlanApp = (state=initState, action) => {
    switch (action.type) {
        case 'openPanel':
            return openPanel(state, action);
        case 'getTimeMax':
            return getTimeMax(state,action);
        case 'getTimeMin':
            return getTimeMin(state, action);
        case 'selectAllDay':
            return selectAllDay(state, action);
        case 'onClickButtonAllLang':
            return onClickButtonAllLang(state, action);
        case 'selectAllTime':
            return selectAllTime(state, action);
        case 'selectAllDate':
            return selectAllDate(state, action);
        case 'resetData':
            return resetData(state, action);
        case 'setStatusPlanPanel':
            return setStatusPlanPanel(state, action);
        case 'getDay':
            return getDay(state, action);
        case 'showMaxCalendar':
            return showMaxCalendar(state, action);
        case 'getDate':
            return getDate(state, action);
        case 'getBrowserLang':
            return getBrowserLang(state, action);
        // case 'selectMaxCalendar':
        //     return selectMaxCalendar(state, action);
        case 'onSave':
            return onSave(state, action);
        case 'editPlan':
            return editPlan(state, action);

        default:
            return state;
    }
};

export default PlanApp;
