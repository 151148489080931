import React, {useEffect, useState} from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Announced } from '@fluentui/react/lib/Announced';
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipHost } from '@fluentui/react';
import { ScrollablePane, IScrollablePaneStyles } from '@fluentui/react/lib/ScrollablePane';
import moment from 'moment';
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";

const classNames = mergeStyleSets({
    header: {
        margin: 0,
    },
    row: {
        flex: '0 0 auto',
    },
    // focusZone: {
    //     height: '80vh',
    //     overflowY: 'auto',
    //     overflowX: 'hidden',
    // },
    selectionZone: {
        height: '85vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});
const DetailList = ({listReport, onSelectReportItem}) => {
    const [
        items,
        setItems
    ] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>', listReport)


        let _items = []
        listReport.forEach((item, i) => {
            _items.push({
                key: i,
                dateTask: moment(item.created).format('YYYY-MM-DD'),
                status: item.status,
                startRK: moment(item.adv_start).format('YYYY-MM-DD'),
                endRK: moment(item.adv_finish).format('YYYY-MM-DD'),
                lengthAd: item.adv_duration,
                lengthAdBlock: item.adv_block_duration,
                id: item.id
            });
        })

        setItems(_items)
    },  [listReport])


    const columns = [
        {
            key: 'column1',
            name: t('Дата заказа отчета'),
            // className: classNames.fileIconCell,
            // iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            // iconName: 'Page',
            // isIconOnly: true,
            fieldName: 'dateTask',
            minWidth: 150,
            maxWidth: 150,
            // onColumnClick: this._onColumnClick,
            // onRender: (item) => (
            //     <TooltipHost content={`${item.fileType} file`}>
            //         <img src={item.iconName} alt={`${item.fileType} file icon`} />
            //     </TooltipHost>
            // ),
        },
        {
            key: 'column2',
            name: t('Статус'),
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            onRender: (item) => {
                return <span>{item.status === 'Pending' ? 'In progress' : item.status}</span>;
            },
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column3',
            name: t('Начало РК'),
            fieldName: 'startRK',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            // onColumnClick: this._onColumnClick,
            data: 'number',
            // onRender: (item) => {
            //     return <span>{item.dateModified}</span>;
            // },
            isPadded: true,
        },
        {
            key: 'column4',
            name: t('Конец РК'),
            fieldName: 'endRK',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            // onColumnClick: this._onColumnClick,
            // onRender: (item) => {
            //     return <span>{item.modifiedBy}</span>;
            // },
            isPadded: true,
        },
        {
            key: 'column5',
            name: t('Длина рекламы'),
            fieldName: 'lengthAd',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            // onColumnClick: this._onColumnClick,
            // onRender: (item) => {
            //     return <span>{item.fileSize}</span>;
            // },
        },
        {
            key: 'column6',
            name: t('Длина рекламного блока'),
            fieldName: 'lengthAdBlock',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            // onColumnClick: this._onColumnClick,
            // onRender: (item) => {
            //     return <span>{item.fileSize}</span>;
            // },
        },
        // {
        //     key: 'column7',
        //     name: t('Поверхность '),
        //     fieldName: 'lengthAdBlock',
        //     minWidth: 70,
        //     maxWidth: 90,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     // onColumnClick: this._onColumnClick,
        //     // onRender: (item) => {
        //     //     return <span>{item.fileSize}</span>;
        //     // },
        // },
    ];

    const focusZoneProps = {
        className: classNames.focusZone,
        'data-is-scrollable': 'true',
    }

    const onTemp = (e) => {
        console.log('>>>>>>><<<<<')
    }

    const _selection = new Selection({
        onSelectionChanged: (e) => {

            onSelectReportItem(_selection.getSelection())
        },
    });

    return (
        <DivBoxListSC count={items.length}>
            <DetailsList
                items={items}
                // compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.single}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={_selection}
                selectionPreservedOnEmptyClick={true}
                // onItemInvoked={onTemp}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                constrainMode={ConstrainMode.unconstrained}
                focusZoneProps={focusZoneProps}
                selectionZoneProps={{
                    className: classNames.selectionZone,
                }}
            />

        </DivBoxListSC>
    );
};

export default DetailList;

const DivBoxListSC = styled.div`
  display: grid;
  width: 100%;
  height: ${({count}) => (count === 0 ? '200' : count < 8 ? 70 + 42 * count : 400 )}px;
  min-height: ${({count}) => (count === 0 ? '200' : count < 8 ? 70 + 42 * count : 400 )}px;
  max-height: 400px;
  overflow: hidden auto;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
`