import React from 'react';
import styled from "styled-components/macro";
import { Text, ITextProps } from '@fluentui/react/lib/Text';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import {useTranslation} from "react-i18next";


const SchedulersItem = (props) => {
    const {
        prop,
        stringDayOfWeek,
        deleteSchedulers
    } = props;
    const {t, i18n} = useTranslation();
    // console.log('>>>>>>>>>>>>', prop)

    const _deleteSchedulers = () => {

        deleteSchedulers(prop.id);
    }

    const cancelIcon = {
        iconName: 'Cancel',
    };

    const buttonStyle = {
        root: {
            height: 30,
            width: 30
        }
    }
    return (
        <SCSchedulersItem>
            <SCSchedulersItemTextWrapper>
                <TooltipHost
                    content={prop.name}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    // id={tooltipId}
                    // calloutProps={calloutProps}
                    // styles={hostStyles}
                >
                    <SCSchedulersItemTextWrapperBackground>
                        <Text  variant={'medium'} nowrap block>
                            {prop.name}
                        </Text>
                    </SCSchedulersItemTextWrapperBackground>

                </TooltipHost>


            </SCSchedulersItemTextWrapper>

            <SCSchedulersItemTextWrapper>
                <Text variant={'small'} nowrap block>
                    {`${t(`Тип планирования`)}: ${prop.schedule_type === 'weekly' ? t('Недельный') : t('Месячный')}`}
                </Text>
            </SCSchedulersItemTextWrapper>

            <SCSchedulersItemTextWrapper>
                <Text  variant={'small'} nowrap block>
                    {`${t('Тип периода')}: ${t(prop.period_type === 'week' ? 'Недельный' : 'Месячный')}`}
                </Text>
            </SCSchedulersItemTextWrapper>
            {prop.period_type === 'week' ? <SCSchedulersItemTextWrapper>
                <Text variant={'small'} nowrap block>
                    {`${t('День недели')}: ${t(stringDayOfWeek[prop.weekly_day_from])}`}
                </Text>
            </SCSchedulersItemTextWrapper> : <SCSchedulersItemTextWrapper>
                <Text variant={'small'} nowrap block>
                    {`${t('День месяца')}: ${prop.monthly_day_from}`}
                </Text>
            </SCSchedulersItemTextWrapper>}
            <SCSchedulersWrapperCancelButton>
                <IconButton
                    iconProps={cancelIcon}
                    title="Emoji"
                    ariaLabel="Emoji"
                    onClick={_deleteSchedulers}
                    styles={buttonStyle}
                    // disabled={disabled}
                    // checked={checked}
                />
            </SCSchedulersWrapperCancelButton>


        </SCSchedulersItem>
    );
};

export default SchedulersItem;

const SCSchedulersItem = styled.div`
    width: 100%;
    height: 90px;
    //background: #1c7430;
    border-radius: 2px;
    position: relative;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid rgb(153, 153, 153);
    padding-left: 5px;
`;

const SCSchedulersItemTextWrapper = styled.div`
  padding: 5px 5px 0 5px;
  width: 100%;
  font-size: 10px;
`;

const SCSchedulersItemTextWrapperBackground = styled.div`
  //background: #1e7e34;
  width: 170px;
`;

const SCSchedulersWrapperCancelButton = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
`;