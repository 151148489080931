import React from 'react';
import i18next from 'i18next';
var moment = require('moment');

function Select(props) {



    const onChangeSelect = (d) => {
        let data = {};
        props.options.forEach((item, index) => {
            // console.log('<><><><>>>>>>>>>>>>', d.target.value, item.value);
            if (d.target.value === item.value) {
                data = item;
            }

        });
        props.onChange(data);
    };
    let componentOptionsNode = props.options.map((item, index) => {

        // console.log('<><><><>>>>>>>>>>>>', item)
        if (props.statusOptionsComponent === 'dataUuidComponent') {
            return (<option key={`optionsReactSelect${index}`} value={item.value}>
                {`${props._t.t('Версия')}: ${moment(item.label).format('dddd, MMMM Do YYYY, h:mm:ss a')}`}
            </option>);
        }
        return (<option key={`optionsReactSelect${index}`} value={item.value}>
            {item.label === 'Помещения' ? props._t.t('Арендаторы') : item.label}
        </option>);
    });
    // console.log('<><><><>>>>>>>>>>>>', props.options, props.value);
    return (
        <select
            style={{
                width: `${props.widthSelect}px`
            }}
            onChange={onChangeSelect}
            value={props.value.value}
        >
            {componentOptionsNode}
        </select>
    );
}

export default Select;
