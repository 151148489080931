import { runAction } from '../../../actions';
import AppStore from '../stores/store';
var Api = require('../api/api.js');
// var HotspotActions = require('../../radius_analytics/actions/actions.js')

var tokenX = 0;

var AppActions = {
    selectStatus: function (data) {
        AppStore.selectStatus(data);
    },
    update: function (data) {
        AppStore.update(data);
    },
    onChangeSMS: function (e) {
        AppStore.onChangeSMS(e);
    },
    onChangeButton: function (e) {
        AppStore.onChangeButton(e);
    },
    onChangeVK: function (e) {
        AppStore.onChangeVK(e);
    },
    onChangeFb: function (e) {
        AppStore.onChangeFb(e);
    },
    onChangeOk: function (e) {
        AppStore.onChangeOk(e);
    },
    onChangePass: function (e) {
        AppStore.onChangePass(e);
    },
    submit: function (state, callback) {
        AppStore.getLoad(true);
        callback(true)
        Api.submit(
            state,
            function (data) {
                // console.log('fetchPosts!!!!!actions.js!!!!!', runAction('updateDataControlPages'));
                // runAction('updateDataControlPages')
                AppStore.getLoad(false);
                // HotspotrunAction('reloadData');
                // HotspotrunAction('hideSettingsPanel');
                callback(false)
                AppStore.onReset();
                AppStore.getStatusSave(data);
            },
            function (err) {
                console.error(err);
            }
        );
    },
    onChangeInputPass: function (e) {
        AppStore.onChangeInputPass(e);
    },
    onChangeSessionPeriod: function (e) {
        AppStore.onChangeSessionPeriod(e);
    },
    onChangeAuthPeriod: function (e) {
        AppStore.onChangeAuthPeriod(e);
    },
    onChangeTraficVolume: function (e) {
        AppStore.onChangeTraficVolume(e);
    },
    onChangeRedirectUrl: function (e) {
        AppStore.onChangeRedirectUrl(e);
    },
    onReset: function () {
        AppStore.onReset();
    },
    onChangeAutoPostVk: function (status) {
        AppStore.onChangeAutoPostVk(status);
    },
    onChangeRadioVKName: function () {
        AppStore.onChangeRadioVKName();
    },
    onChangeRadioVKRepost: function () {
        AppStore.onChangeRadioVKRepost();
    },
    onChangeVkInputReport: function (value) {
        AppStore.onChangeVkInputReport(value);
    },
    onChangeVkInputNameApp: function (value) {
        AppStore.onChangeVkInputNameApp(value);
    },
    onChangeVkInputNameMessage: function (value) {
        AppStore.onChangeVkInputNameMessage(value);
    },
    onChangeAutoPostFb: function (status) {
        AppStore.onChangeAutoPostFb(status);
    },
    onChangeFBLInk: function (e) {
        AppStore.onChangeFBLInk(e);
    },
    onChangeFBTitle: function (e) {
        AppStore.onChangeFBTitle(e);
    },
    onChangeFBDiscrip: function (e) {
        AppStore.onChangeFBDiscrip(e);
    },
    getFiles: function (token) {
        // console.log('actions.js>>>>>>>',token);
        tokenX = token;
        AppStore.loadFiles();
        Api.getFiles(
            token,
            function (data) {
                // console.log('actions.js>>>>>>>', data);
                AppStore.getFiles(data);
            },
            function (err) {
                console.error(err);
            }
        );
    },
    openModalImage: function (status) {
        AppStore.openModalImage(status);
    },
    hideModalBoxImage: function () {
        AppStore.hideModalBoxImage();
    },
    selectImage: function (e) {
        AppStore.selectImage(e);
    },
    selectImagePerformBackground: function (e) {
        AppStore.selectImagePerformBackground(e);
    },
    selectImagePerformLogo: function (e) {
        AppStore.selectImagePerformLogo(e);
    },
    selectImagePerformGraphic: function (e) {
        AppStore.selectImagePerformGraphic(e);
    },
    showPanelUpload: function () {
        AppStore.showPanelUpload();
    },
    hidePanelUpload: function () {
        AppStore.hidePanelUpload();
    },
    deleteImage: function () {
        AppStore.deleteImage();
    },
    onChangeUploadInput: function (e) {
        AppStore.onChangeUploadInput(e);
    },
    onChangeTitleUploadImage: function (e) {
        AppStore.onChangeTitleUploadImage(e);
    },
    sibmitUploadFile: function (state) {
        AppStore.uploadFiles();
        Api.setFiles(
            state,
            function (data) {
                AppActions.getFiles(tokenX);
                AppStore.getStopUpload();
                console.log('actions.js>>>>>>>', data);
            },
            function (err) {
                console.error(err);
                AppStore.errorUploadFiles();
            }
        );
    },
    onChangeColorAuthBackground: function (color) {
        AppStore.onChangeColorAuthBackground(color);
    },
    handleChangeColorBackgroundButton: function (color) {
        AppStore.handleChangeColorBackgroundButton(color);
    },
    onChangeTextButtonAuth: function (e) {
        AppStore.onChangeTextButtonAuth(e);
    },
    onCheckMode: function (e) {
        AppStore.onCheckMode(e);
    },
    handelChangeBackgroundWelcome: function (e) {
        AppStore.handelChangeBackgroundWelcome(e);
    },
    deleteImageBackgroundWelcom: function () {
        AppStore.deleteImageBackgroundWelcom();
    },
    deleteColorBackgroundWelcom: function () {
        AppStore.deleteColorBackgroundWelcom();
    },
    deleteLogo: function () {
        AppStore.deleteLogo();
    },
    deleteGraphic: function () {
        AppStore.deleteGraphic();
    },
    onChangeText: function (e) {
        AppStore.onChangeText(e);
    },
    onClickColorText: function (e) {
        AppStore.onClickColorText(e);
    },
    deleteItemImage: function (state) {
        AppStore.uploadFiles();
        Api.deleteItemImage(
            state,
            function (data) {
                AppActions.getFiles(tokenX);
                AppStore.getStopUpload();
                console.log('actions.js>>>>>>>', data);
            },
            function (err) {
                console.error(err);
                AppStore.errorUploadFiles();
            }
        );
    },
    showPreview: function (state) {
        AppStore.getStatusLoadTemPage(true);
        Api.requestPreview(
            state,
            function (data) {
                AppStore.getPreviewsPageUrl('https://cms.wifizone.me/previews/' + data.page_uuid + '/');
                // console.log('actions.js', data);
                AppStore.getStatusLoadTemPage(false);
            },
            function (err) {
                console.error(err);
            }
        );
    },
    onCheckCall: function (e) {
        AppStore.onCheckCall(e);
    },
    onChangeCall: function (e) {
        AppStore.onChangeCall(e);
    },
};

export default AppActions;
