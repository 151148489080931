import React from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
// import {Holst, Drawer} from './holst';
import DrowerHolst from '../lib/shopster-lib-js-maps/lib/drawerHolst';
// import MapAndSvg from "./mapAndSvg";

import colors from '../../../../tools/colors.js';
import GeometryVSDataManager from '../../../../tools/GeometryVSDataManager';

var JSONData = 0;
var JSONSvgElements = 0;
var JSONScale = 0;
var nameMarker = '';
class DrawerPeriemetr extends DrowerHolst {
    static initShapes(shapes) {
        //поправить
        this['shapes'] = {
            polygons: [],
            retailName: [],
            namePolygons: [],
            checkLis: [],
            objPolygon: {
                polygons: [],
                retailName: [],
                namePolygons: [],
                checkLis: [],
            },
            roomsTransition: {},
            rooms: {},
            dataUneqUsers: {},
            showCounts: false,
            statusColorCount: false,
            selectedShape: null,
            image: null,
            index: 0,
            indexOfFloor: 0,
        };
    }
    static initProps(props, func1, func2, onClickPoint) {
        this['props'] = props;
        this['onClickPoint'] = func1;
    }
    static setStatusEdit(status) {
        this.shapes.statusEdit = status;
    }
    static UNSAFE_componentWillReceiveProps(nextProps, func1, func2, onClickPoint) {
        // console.log('fasdasd', nextProps.data.arrayIsOpened)
        // console.log('!!!!!!!!!!!!>>>', nextProps.data.perimeters);
        this['props'] = {};
        if (nextProps.data.perimeters !== undefined) {
            this.shapes.polygons = nextProps.data.perimeters;
        }
        if (nextProps.data.markers !== undefined) {
            this.shapes.retailName = nextProps.data.markers;
        }
        if (nextProps.statusColorCount !== undefined) {
            this.shapes.statusColorCount = nextProps.statusColorCount;
        }
        if (nextProps.data.markers !== undefined) {
            this.shapes.namePolygons = nextProps.data.markers;
        }
        if (nextProps.data.arrayIsOpened !== undefined) {
            this.shapes.checkLis = nextProps.data.arrayIsOpened;
        }
        this.props.onSelectPolygons = nextProps.onSelectPolygons;
        this.shapes.rooms = nextProps.rooms;
        this.shapes.image = nextProps.image;
        this.shapes.index = nextProps.index;
        if (Object.keys(nextProps.roomsTransition).length === 0) {
            if (JSON.stringify(nextProps.roomsTransition) !== JSON.stringify(this.shapes.roomsTransition)) {
                this.shapes.selectedShape = null;
            }
        }
        this.shapes.indexOfFloor = nextProps.indexOfFloor;
        this.shapes.showCounts = nextProps.showCounts;
        this.shapes.dataUneqUsers = nextProps.dataUneqUsers;
        this.shapes.roomsTransition = nextProps.roomsTransition;
        this.shapes.objPolygon = {
            polygons: this.shapes.polygons,
            retailName: this.shapes.retailName,
            namePolygons: this.shapes.namePolygons,
            checkLis: this.shapes.checkLis,
        };
        // debugger
    }
    static onClickPointPoly(e, data, indexArr) {
        e.preventDefault();
        this.shapes.arrIndexPoly = indexArr;
        this.shapes.statusContextPoly = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.onClickPoint(true, e, data, indexArr);
    }
    static holstOnClick(x, y, scale, event) {}
    static holstMouseMove(x, y, scale) {
        // console.log(x, y)
        this.shapes.coordArr = [Math.round(x / scale), Math.round(y / scale)];

        var mode = this.props.getMode;
        var p = [x / scale, y / scale];
        this.shapes.cursorPoint = p;

        if (this.shapes.selectedPoint) {
            this.shapes.selectedPointMoved = true;
        }

        this.shapes.cursorMoved = true;

        if (this.shapes.selectedPoint && this.shapes.selectedPointMoved) {
            var i = this.shapes.selectedPoint.is[0];
            var j = this.shapes.selectedPoint.is[1];
            this.shapes.polygons[i][j] = p;
            this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
        }

        var polygons = this.shapes.polygons;
        this.shapes.polygonCloseStatus = false;
        this.shapes.polyLineCloseStatus = false;
        var lastPolygon = polygons[polygons.length - 1];
        if (lastPolygon !== undefined) {
            var p0 = lastPolygon[0];
            var d = Math.max(Math.abs(p[0] - p0[0]), Math.abs(p[1] - p0[1]));
            if (d < this.state.height * 0.013) {
                if (polygons[polygons.length - 1].length > 2) {
                    this.shapes.polygonCloseStatus = true;
                }
            }
        }
    }
    static selectShape(shapeType, i, indexOfFloor) {
        this.shapes.selectedShape = {
            shapeType: shapeType,
            i: i,
        };
        if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
            this.props.onSelectPolygons(i, true, indexOfFloor);
        }
        this.shapes.cursorMoved = false;
    }
    static holstMouseUp(x, y, scale, e) {
        if (e.nativeEvent.which !== 3) {
            this.shapes.selectedPoint = null;
            this.shapes.selectedPointMoved = false;
            if (this.shapes.selectedShape && this.shapes.cursorMoved) {
                if (this.shapes.selectedPointMoved === false) {
                    if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                        this.props.onSelectPolygons(0, false, 0);
                    }
                    this.shapes.selectedShape = null;
                }
            }
            this.shapes.selectedPoint = null;
            this.shapes.selectedPointMoved = false;
            if (this.props.updateShapes) {
                this.shapes = this.props.updateShapes(this.shapes);
            }
        }
    }
    static trashClick() {
        this.shapes.statusDelete = true;
        if (this.shapes.selectedShape) {
            var shape = this.shapes.selectedShape;
            this.shapes.polygons.splice(shape.i, 1);
            this.props.getRouters(this.shapes.polygons, [], true, shape.i);
            if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                this.props.onSelectPolygons(0, false, 0);
            }
            this.emitChangeToModuleListeners();
            this.shapes.selectedShape = null;
            if (this.props.updateShapes) {
                this.shapes = this.props.updateShapes(this.shapes);
            }
        }
    }
    static keyDown(event) {
        console.log(event.which);
        if (event.which === 8) {
            if (this.shapes.polygonIsOpened === true) {
                if (this.shapes.polygons[this.shapes.polygons.length - 1].length === 1) {
                    this.shapes.polygons.splice(this.shapes.polygons.length - 1, 1);
                    this.shapes.polygonIsOpened = false;
                    this.props.getRouters(this.shapes.polygons, [], false, null);
                    return;
                }
                this.shapes.polygons[this.shapes.polygons.length - 1].splice(
                    this.shapes.polygons[this.shapes.polygons.length - 1].length - 1,
                    1
                );
                this.props.getRouters(this.shapes.polygons, [], false, null);
                // this.setState({});
            }
        }
        if (event.which === 27) {
            var shape = this.shapes.selectedShape;
            this.shapes.polygons.splice(shape.i, 1);
            this.props.getRouters(this.shapes.polygons, [], false, null);
        }
    }
    static getSvgElementsForDraw(scale, globalScale, planScale) {
        var styleLine = {
            cursor: 'pointer',
        };
        //
        var elements = [];
        var elementsDraw = [];
        // var cp = this.shapes.cursorPoint;
        // var mode = this.props.getMode;

        var fshape = function (type, i) {
            console.log('fshape1');
            return function (e) {
                this.selectShape(type, i);
            };
        };

        var polygons = this.shapes.polygons;

        for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
            // console.log('!!!!!!!!!!!!>>>');
            if (polygons[i][0] !== undefined) {
                var path = 'M ';
                var p0 = polygons[i][0];
                var minCoorX = p0[0];
                var minCoorY = p0[1];
                var maxCoorX = p0[0];
                var maxCoorY = p0[1];
                path += (p0[0] / planScale) * globalScale * scale + ',' + (p0[1] / planScale) * globalScale * scale + ' L ';
                for (var j = 1; j < polygons[i].length; j++) {
                    var p = polygons[i][j];
                    if (minCoorX > p[0]) {
                        minCoorX = p[0];
                    }
                    if (maxCoorX < p[0]) {
                        maxCoorX = p[0];
                    }
                    if (minCoorY > p[1]) {
                        minCoorY = p[1];
                    }
                    if (maxCoorY < p[1]) {
                        maxCoorY = p[1];
                    }
                    var middleX = (maxCoorX + minCoorX) / 2;
                    var middleY = (minCoorY + maxCoorY) / 2;
                    path += ' ' + (p[0] / planScale) * globalScale * scale + ',' + (p[1] / planScale) * globalScale * scale;
                }
                path += ' Z';

                var componentName = null;
                var componentRetailName = null;

                var alpha = 0.4;
                if (
                    this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType === 'POLY' &&
                    this.shapes.selectedShape.i === String(i)
                ) {
                    alpha = 0.6;
                }
                var fill2 = 'rgba(51,71,80,' + alpha + ')';
                var retailName = '';
                if (this.shapes.rooms[this.shapes.namePolygons[i]] !== undefined) {
                    var widthMarkerName = (this.shapes.namePolygons[i].length + 1) * 6.8 + 4;
                    var coorX2 = (middleX / planScale) * globalScale * scale - widthMarkerName / 2;
                    componentName = (
                        <g style={{ pointerEvents: 'none' }}>
                            <rect
                                x={coorX2}
                                y={(middleY / planScale) * globalScale * scale - 1}
                                width={widthMarkerName}
                                height="15"
                                fill={'rgba(255,255,255,.5)'}
                            />
                            <text
                                style={{ pointerEvents: 'none' }}
                                fill="#000"
                                fontWeight={200}
                                fontSize={12}
                                textAnchor="middle"
                                x={(middleX / planScale) * globalScale * scale}
                                y={(middleY / planScale) * globalScale * scale + 10}
                            >{`${this.shapes.namePolygons[i]}`}</text>
                        </g>
                    );
                }
                fill2 = 'rgba(0,0,0,0.6)';

                if (
                    this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLY' &&
                    this.shapes.selectedShape.i == String(i)
                ) {
                    fill2 = 'rgba(255,255,255,.3)';
                }
                elementsDraw.push(
                    <path
                        stroke="rgba(150,0,0,0.6)"
                        strokeWidth="1"
                        fill={fill2}
                        d={path}
                        key={elementsDraw.length + 'ffdfdf' + this.shapes.index}
                        onMouseDown={fshape('POLY', i).bind(this)}
                        style={styleLine}
                    />
                );

                elementsDraw.push(componentRetailName);
            }
        }

        this.shapes.svgElementsDraw = elementsDraw;
        return { elem: elements, elemDraw: elementsDraw };
    }
    static getSvgElements(scale, globalScale, planScale) {
        var obj = {
            polygons: this.shapes.polygons,
            polyLIne: this.shapes.polyLIne,
            gridData: this.shapes.gridData,
            polygonCloseStatus: this.shapes.polygonCloseStatus,
            polyLineCloseStatus: this.shapes.polyLineCloseStatus,
            stepItems: this.shapes.stepItems,
            polygonsObj: this.shapes.polygonsObj,
            points: this.shapes.points,
            lines: this.shapes.lines,
        };
        if (JSONSvgElements !== JSON.stringify(obj)) {
            this.shapes.statusSvgCloseElem = true;
            setTimeout(() => {
                this.shapes.statusSvgCloseElem = true;
                setTimeout(() => {
                    this.shapes.statusSvgCloseElem = true;
                }, 100);
            }, 100);
            JSONSvgElements = JSON.stringify(obj);
        }

        var objPolygon = {
            objPolygon: this.shapes.objPolygon,
            scale: scale,
            dataUneqUsers: this.shapes.dataUneqUsers,
            showCounts: this.shapes.showCounts,
        };
        setTimeout(() => {
            JSONScale = 0;
            setTimeout(() => {
                JSONScale = 0;
            }, 100);
        }, 10);
        // console.log('fasdasd')
        this.shapes.statusSvgCloseElem = false;
        // if (this.props.changeShapes) {
        //     this.shapes = this.props.changeShapes(this.shapes);
        // }

        var stylePoint = {
            cursor: 'move',
        };
        var styleLine = {
            cursor: 'pointer',
            filter: 'url(#dropshadow)',
        };

        var elements = [];
        var elementsText = [];
        var elementsDraw = [];
        var cp = this.shapes.cursorPoint;
        // var mode = this.props.getMode;

        if (this.shapes.polygonCloseStatus === true) {
            elementsDraw.push(
                <circle cx={cp[0] * scale} cy={cp[1] * scale} r="6" fill="rgba(255,0,0,1)" key={elements.length} style={stylePoint} />
            );
        }
        if (this.shapes.polyLineCloseStatus === true) {
            elementsDraw.push(
                <circle cx={cp[0] * scale} cy={cp[1] * scale} r="6" fill="rgba(255,0,0,1)" key={elements.length} style={stylePoint} />
            );
        }

        var fx = function (type, is) {
            return function (e) {
                if (e.nativeEvent.which === 1) {
                    this.selectPoint(type, is);
                    if (type === 'POINT') {
                        this.selectShape(type, is[0]);
                    }
                    e.stopPropagation();
                }
            };
        };
        var fx2 = function (type, is) {
            return function (e) {
                if (e.nativeEvent.which === 1) {
                    this.selectPoint(type, is);
                    if (type === 'POINT') {
                        this.selectShape(type, is[0]);
                    }
                    e.stopPropagation();
                }
            };
        };

        var fg = function (p) {
            return function (e) {
                this.contextMenu(e, p);
            };
        };

        var funcPoint = function (p, arr) {
            return function (e) {
                this.onClickPointPoly(e, p, arr);
            };
        };

        var fshape = function (type, i, indexOfFloor) {
            // console.log('fshape2')

            return function (e) {
                this.selectShape(type, i, indexOfFloor);
            };
        };

        var polygons = this.shapes.polygons;
        if (this.shapes.polygonIsOpened) {
            var lastPolygon = polygons[polygons.length - 1];
            for (var j = 0; j < lastPolygon.length - 1; j++) {
                var p1 = lastPolygon[j];
                var p2 = lastPolygon[j + 1];
                elementsDraw.push(
                    <line
                        x1={(p1[0] / planScale) * globalScale * scale}
                        y1={(p1[1] / planScale) * globalScale * scale}
                        x2={(p2[0] / planScale) * globalScale * scale}
                        y2={(p2[1] / planScale) * globalScale * scale}
                        stroke="rgb(255,0,0)"
                        key={Math.random().toString(36).substr(2, 10)}
                    />
                );
                elementsDraw.push(
                    <circle
                        cx={(p1[0] / planScale) * globalScale * scale}
                        cy={(p1[1] / planScale) * globalScale * scale}
                        r="4"
                        stroke="rgb(255,255,255)"
                        strokeWidth="1"
                        key={Math.random().toString(36).substr(2, 10)}
                    />
                );
            }
            var p1 = lastPolygon[lastPolygon.length - 1];
            var p2 = cp;
            elementsDraw.push(
                <line
                    x1={(p1[0] / planScale) * globalScale * scale}
                    y1={(p1[1] / planScale) * globalScale * scale}
                    x2={(p2[0] / planScale) * globalScale * scale}
                    y2={(p2[1] / planScale) * globalScale * scale}
                    stroke="rgb(255,0,0)"
                    key={Math.random().toString(36).substr(2, 10)}
                />
            );
            elementsDraw.push(
                <circle
                    cx={(p1[0] / planScale) * globalScale * scale}
                    cy={(p1[1] / planScale) * globalScale * scale}
                    r="4"
                    stroke="rgb(255,255,255)"
                    strokeWidth="1"
                    key={Math.random().toString(36).substr(2, 10)}
                />
            );
        }

        function shapeF(type, i) {
            return function (e) {
                this.selectShape(type, i);
            };
        }
        // console.log('MapsHolst', polygons);
        for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
            if (polygons[i][0] !== undefined) {
                var path = 'M ';
                var p0 = polygons[i][0];
                var minCoorX = p0[0];
                var minCoorY = p0[1];
                var maxCoorX = p0[0];
                var maxCoorY = p0[1];
                path += (p0[0] / planScale) * globalScale * scale + ',' + (p0[1] / planScale) * globalScale * scale + ' L ';
                for (var j = 1; j < polygons[i].length; j++) {
                    var p = polygons[i][j];
                    if (minCoorX > p[0]) {
                        minCoorX = p[0];
                    }
                    if (maxCoorX < p[0]) {
                        maxCoorX = p[0];
                    }
                    if (minCoorY > p[1]) {
                        minCoorY = p[1];
                    }
                    if (maxCoorY < p[1]) {
                        maxCoorY = p[1];
                    }
                    var middleX = (maxCoorX + minCoorX) / 2;
                    var middleY = (minCoorY + maxCoorY) / 2;
                    path += ' ' + (p[0] / planScale) * globalScale * scale + ',' + (p[1] / planScale) * globalScale * scale;
                }
                path += ' Z';

                var componentName = null;
                var componentRetailName = null;
                var componentUneq = null;

                var alpha = 0.4;
                if (
                    this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLY' &&
                    this.shapes.selectedShape.i == String(i)
                ) {
                    alpha = 0.6;
                }
                var fill2 = 'rgba(51,71,80,' + alpha + ')';

                fill2 = 'rgba(255,255,255,0.5';
                if (this.shapes.checkLis[i] === true) {
                    var retailName = '';
                    if (this.shapes.rooms[this.shapes.namePolygons[i]] !== undefined) {
                        // console.log('!!!!!!!!!!!!>>>', this.shapes.retailName);
                        // console.log('>>>>>>>>>>>>>>1', this.shapes.dataUneqUsers[this.shapes.namePolygons[i]]);
                        if (this.shapes.dataUneqUsers[this.shapes.namePolygons[i]] !== undefined) {
                            // console.log('!!!!!!!!!!!!>>>', this.shapes.showCounts);
                            if (this.shapes.showCounts === true) {
                                var widthUneq = this.shapes.dataUneqUsers[this.shapes.namePolygons[i]].length * 6.8 + 4;
                                var coorUneq = (middleX / planScale) * globalScale * scale - widthUneq / 2;
                                componentUneq = (
                                    <g style={{ pointerEvents: 'none' }}>
                                        <rect
                                            x={coorUneq}
                                            y={(middleY / planScale) * globalScale * scale - 21}
                                            width={widthUneq}
                                            height="15"
                                            fill={'rgba(255,255,255,.5)'}
                                        />
                                        <text
                                            style={{ pointerEvents: 'none' }}
                                            fill="#000"
                                            fontWeight={200}
                                            fontSize={12}
                                            textAnchor="middle"
                                            x={(middleX / planScale) * globalScale * scale}
                                            y={(middleY / planScale) * globalScale * scale - 10}
                                        >
                                            {`${this.shapes.dataUneqUsers[this.shapes.namePolygons[i]]}`}
                                        </text>
                                    </g>
                                );
                            }
                        }

                        if (GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i]) !== undefined) {
                            var widthMarkerName =
                                (GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i]).length + 1) * 6.8 + 4;
                            var coorX2 = (middleX / planScale) * globalScale * scale - widthMarkerName / 2;
                            componentName = (
                                <g style={{ pointerEvents: 'none' }}>
                                    <rect
                                        x={coorX2}
                                        y={(middleY / planScale) * globalScale * scale - 1}
                                        width={widthMarkerName}
                                        height="15"
                                        fill={'rgba(255,255,255,.5)'}
                                    />
                                    <text
                                        style={{ pointerEvents: 'none' }}
                                        fill="#000"
                                        fontWeight={200}
                                        fontSize={12}
                                        textAnchor="middle"
                                        x={(middleX / planScale) * globalScale * scale}
                                        y={(middleY / planScale) * globalScale * scale + 10}
                                    >{`${GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i])}`}</text>
                                </g>
                            );
                            fill2 = 'rgba(208, 216, 220,0)';
                            var statusSelect = false;
                            // if (this.shapes.roomsTransition[this.shapes.namePolygons[i]] !== undefined) {
                            //     fill2 = colors.getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent / 100)
                            // }
                            //
                            // if (this.shapes.statusColorCount === true) {
                            //
                            //     if (GeometryVSDataManager.reportPersentWithRoom(this.shapes.namePolygons[i]) !== undefined) {
                            //         fill2 = colors.getHeatColor(GeometryVSDataManager.reportPersentWithRoom(this.shapes.namePolygons[i]))
                            //     }
                            // }
                            if (
                                this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType === 'POLY' &&
                                this.shapes.selectedShape.i === String(i)
                            ) {
                                statusSelect = true;
                                fill2 = 'rgba(255,255,255,0)';
                            }

                            elements.push(
                                <path
                                    stroke="rgb(191,192,189)"
                                    strokeWidth="1"
                                    fill={fill2}
                                    d={path}
                                    key={Math.random().toString(36).substr(2, 10)}
                                    onMouseDown={
                                        this.shapes.statusColorCount ? null : fshape('POLY', i, this.shapes.indexOfFloor).bind(this)
                                    }
                                    style={{ filter: 'url(#dropshadow)' }}
                                />
                            );

                            elements.push(
                                <defs key={Math.random().toString(36).substr(2, 10)} xmlns="http://www.w3.org/2000/svg">
                                    <filter id="dropshadow" height="130%">
                                        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                                        <feOffset dx="0" dy="0" result="offsetblur" />
                                        <feComponentTransfer>
                                            <feFuncA type="linear" slope="0.2" />
                                        </feComponentTransfer>
                                        <feMerge>
                                            <feMergeNode />
                                            <feMergeNode in="SourceGraphic" />
                                        </feMerge>
                                    </filter>
                                </defs>
                            );
                        }
                    } else {
                        if (GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i]) !== undefined) {
                            var widthMarkerName =
                                (GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i]).length + 1) * 6.8 + 4;
                            var coorX2 = (middleX / planScale) * globalScale * scale - widthMarkerName / 2;
                            componentName = (
                                <g style={{ pointerEvents: 'none' }}>
                                    <rect
                                        x={coorX2}
                                        y={(middleY / planScale) * globalScale * scale - 1}
                                        width={widthMarkerName}
                                        height="15"
                                        fill={'rgba(255,255,255,.5)'}
                                    />
                                    <text
                                        style={{ pointerEvents: 'none' }}
                                        fill="#000"
                                        fontWeight={200}
                                        fontSize={12}
                                        textAnchor="middle"
                                        x={(middleX / planScale) * globalScale * scale}
                                        y={(middleY / planScale) * globalScale * scale + 10}
                                    >{`${GeometryVSDataManager.reportNameWithPlace(this.shapes.retailName[i])}`}</text>
                                </g>
                            );
                        }
                        fill2 = 'rgba(200,200,200, 0)';
                        var statusSelect = false;

                        if (
                            this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType === 'POLY' &&
                            this.shapes.selectedShape.i === String(i)
                        ) {
                            statusSelect = true;
                        }

                        elements.push(
                            <path
                                stroke="rgb(191,192,189)"
                                strokeWidth="1"
                                fill={fill2}
                                d={path}
                                key={Math.random().toString(36).substr(2, 10)}
                                style={{ filter: 'url(#dropshadow)' }}
                            />
                        );

                        elements.push(
                            <defs key={Math.random().toString(36).substr(2, 10)} xmlns="http://www.w3.org/2000/svg">
                                <filter id="dropshadow" height="130%">
                                    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                                    <feOffset dx="0" dy="0" result="offsetblur" />
                                    <feComponentTransfer>
                                        <feFuncA type="linear" slope="0.2" />
                                    </feComponentTransfer>
                                    <feMerge>
                                        <feMergeNode />
                                        <feMergeNode in="SourceGraphic" />
                                    </feMerge>
                                </filter>
                            </defs>
                        );
                    }
                } else {
                    var statusSelect = false;
                    if (this.shapes.roomsTransition[this.shapes.namePolygons[i]] !== undefined) {
                        fill2 = colors.getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent / 100);
                    }
                    if (
                        this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType === 'POLY' &&
                        this.shapes.selectedShape.i === String(i)
                    ) {
                        // fill2 = 'rgba(0,0,255,0.6)'
                        statusSelect = true;
                    }

                    elements.push(
                        <path
                            stroke="rgb(191,192,189)"
                            strokeWidth="1"
                            fill={fill2}
                            d={path}
                            key={Math.random().toString(36).substr(2, 10)}
                            style={{ filter: 'url(#dropshadow)' }}
                        />
                    );
                    elements.push(
                        <defs key={Math.random().toString(36).substr(2, 10)} xmlns="http://www.w3.org/2000/svg">
                            <filter id="dropshadow" height="130%">
                                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                                <feOffset dx="0" dy="0" result="offsetblur" />
                                <feComponentTransfer>
                                    <feFuncA type="linear" slope="0.2" />
                                </feComponentTransfer>
                                <feMerge>
                                    <feMergeNode />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                    );
                }

                elementsText.push(componentName);
                elementsText.push(componentUneq);
            }
        }
        var elementAfterConcat = elements.concat(elementsText);

        //<<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>

        this.shapes.svgElements = elementAfterConcat;
        return { elem: this.shapes.svgElements, elemDraw: elementsDraw };
    }
}

export default DrawerPeriemetr;
