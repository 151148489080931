import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import _t from 'i18next';
import Title from '../pl_panel/components/panel_title';
import Button from '../pl_panel/components/button';
import Search from '../pl_panel/components/search';
import ObjSetsTreeType_1 from './components/tree_type_1';
import ObjSetsTreeType_2 from './components/tree_type_2';
import useHistory from './components/useHistory';

const Container = styled.div`
    min-width: 250px;
    height: 100%;
    background: #fff;
    position: fixed;
    overflow: auto;
    left: 238px;
    padding: 10px;
    top: 0px;
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 97;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
`;

const Header = styled.section``;
const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    box-sizing: border-box;
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export default React.memo(
    ({
        dataobjsets,
        dataObjSetsById,
        reportingObjectsById,
        projectLocationsById,
        history,
        userOptions,
        dataObjSetsByPlId,
        lang,
        toggleDropDownMenu,
        searchObjSetsValue,
        toggleExpandArrow,
        memoTree,
        ...props
    }) => {
        const { objects, canSaveSet } = useHistory(reportingObjectsById, history);
        const [similarAlert, setSimilarAlert] = useState('');

        useEffect(() => {
            const diff = (a1, a2) => {
                return a1.filter(i => !a2.includes(i)).concat(a2.filter(i => !a1.includes(i)));
            };

            Object.keys(dataObjSetsById).forEach(setId => {
                if (diff(objects, dataObjSetsById[setId].bound_dataobjs).length === 0) {
                    setSimilarAlert(dataObjSetsById[setId].name);
                }
            });
        }, [objects]);

        const treeType = useMemo(() => {
            if (!userOptions.includes('role:admin') && !userOptions.includes('role:superuser') && !userOptions.includes('role:moderator')) {
                return 1;
            } else {
                return 2;
            }
        }, [userOptions]);

        const onCreateNewSetClick = () => {
            if (similarAlert !== '') {
                props.toggleModal({
                    show: true,
                    modalType: 'alert',
                    modalTitle: _t.t('Создать новый набор'),
                    modalText: _t.t('Сет с такими объектами уже есть!') + ': ' + similarAlert,
                    buttons: [
                        {
                            text: 'OK',
                            width: '100px',
                            action: {
                                name: 'toggleMakeNewObjSetModal',
                                params: {
                                    show: true
                                }
                            }
                        },
                        {
                            text: _t.t('Отмена'),
                            width: '100px',
                            action: null
                        }
                    ]
                });
            } else {
                props.toggleModal({
                    show: true,
                    modalType: 'make_new_obj_set',
                    modalTitle: _t.t('Создание нового набора объектов'),
                    modalText: '',
                    height: 'auto',
                    width: '60%',
                    modalArgs: {
                        name: '',
                        id: '',
                        props: { history, reportingObjectsById, dataObjSetsById }
                    }
                });
            }
        };

        return (
            <Container>
                <Header>
                    <Title title={_t.t('Наборы объектов')} />
                </Header>
                <Main>
                    <Search searchValue={searchObjSetsValue} handleSearch={props.handleSearch} lang={lang} />

                    {treeType === 1 ? (
                        <ObjSetsTreeType_1
                            dataobjsets={dataobjsets}
                            projectLocationsById={projectLocationsById}
                            dataObjSetsByPlId={dataObjSetsByPlId}
                            toggleDropDownMenu={toggleDropDownMenu}
                            history={history}
                            onObjSetDashClick={props.onObjSetDashClick}
                            onSelectAllClick={props.onSelectAllClick}
                            onClearAllClick={props.onClearAllClick}
                            searchValue={searchObjSetsValue}
                        />
                    ) : treeType === 2 ? (
                        <ObjSetsTreeType_2
                            history={history}
                            onObjSetDashClick={props.onObjSetDashClick}
                            toggleExpandArrow={toggleExpandArrow}
                            memoTree={memoTree}
                        />
                    ) : null}
                </Main>
                <Footer>
                    <DefaultButton onClick={onCreateNewSetClick}>{_t.t('Создать новый набор')}</DefaultButton>
                </Footer>
            </Container>
        );
    }
);
