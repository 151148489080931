const selectButton = (state, action) => {

    return Object.assign({}, state, {
        status: action.data.key_name,
        dataSelectSections:action.data
    })
}

let initState = {
    status: 'hotspot',
    dataSelectSections:{}
}

const LeftPanelApp = (state=initState, action) => {
    switch (action.type) {
        case 'onSelectLeftPanel':
            return selectButton(state, action);
        default:
            return state;
    }
}
export default LeftPanelApp;