import React, { useMemo, useState } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components/macro';
import { _onChangeName } from './tools';

const NewGroupModal = React.memo(({ ...props }) => {
    const { t } = useTranslation();
    const [groupName, set_groupName] = useState('');

    const MainComponent = useMemo(() => {
        return (
            <Container>
                <StTextField
                    autoFocus
                    label={t('Имя новой группы')}
                    value={groupName}
                    onChange={_onChangeName(set_groupName)}
                    placeholder={t('Минимум 1 символ')}
                    // placeholder={t('Минимум 1 символ, только буквы, пробелы и цифры')}
                    // multiline
                />
            </Container>
        );
    }, [groupName]);

    const stackTokens = { childrenGap: 10 };

    const onModalButtonClick = (action, params) => (e) => {
        props.onModalButtonClick({ name: action }, params);
    };

    const primaryDisabled = groupName.length < 1 || groupName.toLocaleUpperCase() === 'default';

    return (
        <Wrapper>
            {MainComponent}
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton
                        text={'OK'}
                        width={'100px'}
                        disabled={primaryDisabled}
                        onClick={onModalButtonClick('createNewGroup', { subsectionID: props.subsection.id, groupName })}
                    />
                    <DefaultButton text={t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                </Stack>
            </Footer>
        </Wrapper>
    );
});

export default NewGroupModal;

const StTextField = styled(TextField)`
    width: 100%;
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label:not(.FileLabel) {
        margin-top: 10px !important;
    }
`;

const Container = styled.div`
    width: 100%;
`;
