export const getPointParams = (item, typeOfRouter, selectedSpaces) => {
  const result = {};
  switch (typeOfRouter) {
    case 'adSpaces':
      result.polygonColor = selectedSpaces.indexOf(item.id) !== -1 ? 'red' : 'blue';
      result.circleColor = 'red';
      result.radius = 20;
      result.tooltip = `AdSpace ${item.name}`;
      break;
    case 'iPoints':
      result.polygonColor = '#FFA000';
      result.circleColor = 'blue';
      result.radius = 10;
      result.tooltip = `iPoint ${item.id}`;
      break;
    default:
      break;
  }
  return result;
};
