import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const LegendContainer = styled.p`
    display: block;
    /* display: inline-flex;
    justify-content: center;
    flex-wrap: wrap; */
    height: ${(p) => p.height + 'px'};
    word-break: break-all;
    min-height: 16px;
    /* max-height: 90px; */
    width: 100%;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 1.3;
    text-align: ${(p) => (p.isAggregation ? 'center' : 'left')};
    /* :hover {
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    } */
`;

const LegendItem = styled.span`
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    padding-left: 15px;
    margin: 0 5px;
    opacity: ${(props) => (props.show ? 1 : 0.3)};
    font-size: 12px;
    line-height: 1.3;
    color: #777;
    word-break: break-word;

    ::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.color};
        border-radius: 50%;
    }

    :hover {
        color: black;
    }
`;

const CustomLegend = React.memo(({ height, avgLines, chartId, lines, showAverages, isAggregation, ...props }) => {
    const { t } = useTranslation();
    const generateName = (name) => {
        if (!avgLines || !showAverages) {
            return t(name);
        } else {
            let avg = '';
            if (name === 'Отчетный период') {
                try {
                    avg = avgLines.filter((item) => item.text === 'avgMain')[0].value;
                } catch (error) {}
            } else if (name === 'Период сравнения') {
                try {
                    avg = avgLines.filter((item) => item.text === 'avgCompare')[0].value;
                } catch (error) {}
            } else {
                try {
                    avg = avgLines.filter((item) => item.text === name)[0].value;
                } catch (error) {}
            }

            return `${t(name)} (${t('среднее')}: ${avg})`;
        }
    };

    const onLegendItemClick = (line) => (e) => {
        props.onLegendItemClick(line);
    };

    const onLegendItemEnter = (line) => (e) => {
        props.onLegendItemEnter(line);
    };

    const onLegendItemOut = (line) => (e) => {
        props.onLegendItemOut(line);
    };

    return (
        <LegendContainer height={height} isAggregation={isAggregation}>
            {lines.map((line) => {
                return (
                    <LegendItem
                        onMouseEnter={onLegendItemEnter(line)}
                        onMouseOut={onLegendItemOut(line)}
                        onClick={onLegendItemClick(line)}
                        color={line.color}
                        show={line.show}
                        key={`legendsToShow-${line.name}-${chartId}`}
                    >
                        {generateName(line.name)}
                    </LegendItem>
                );
            })}
        </LegendContainer>
    );
});

export default CustomLegend;
