import React, { useState, useContext } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { generatePolygon } from '../tools';

const Sectors = React.memo(
    ({ data, map, typeOfRouter, currentZoom, isInner = false, selectedSpaces, mode, staticAdCampSelectedSpaces, ...props }) => {
        const filteredData = data;
        // state.selectedPL.length === 0
        //     ? data
        //     : mode === 'addNew'
        //     ? [data]
        //     : data.filter(item => item.project_location === state.selectedPL[0]);

        // const onRightClick = (id, typeOfRouter) => e => {
        //     const cursorCoords = {
        //         x: e.originalEvent.pageX,
        //         y: e.originalEvent.pageY
        //     };
        //     dispatch({
        //         type: 'handleRightSectorClick',
        //         payload: { sectorId: id, typeOfRouter, cursorCoords }
        //     });
        // };

        const onSectorClick = (id) => (e) => {
            props.onSectorClick(id, mode);
        };

        const fillOpacity = isInner ? 1 : 0.5;

        const result = filteredData.map((item, i) => {
            let selected = selectedSpaces.includes(item.id);
            if (mode === 'staticAdCamp') {
                selected = staticAdCampSelectedSpaces.indexOf(item.id) !== -1;
            }
            const polygon = generatePolygon(map, item, typeOfRouter, isInner, selected, currentZoom);
            return (
                <Polygon
                    key={`p-${item.id}`}
                    sectorId={item.id}
                    fillColor={polygon.polygonColor}
                    fillOpacity={fillOpacity}
                    // weight={1}
                    color={'transparent'}
                    positions={polygon.polygon}
                    onMouseUp={onSectorClick(item.id)}
                    // onContextMenu={onRightClick(item.id, typeOfRouter)}
                >
                    <Tooltip key={i}>{polygon.tooltip}</Tooltip>
                </Polygon>
            );
        });

        return result;
    }
);

export default Sectors;
