const colors = {
    getHeatColor: function (val) {
        var x = 2.0 / (1 + Math.exp(-val * 5)) - 1;
        var r = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.5))), 1);
        var g = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.25))), 1);
        var b = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * x)), 1);
        return 'rgba(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ',0.5)';
    },
    HSV2RGB: function (h, s, v) {
        var r, g, b, i, f, p, q, t;
        if (arguments.length === 1) {
            s = h.s;
            v = h.v;
            h = h.h;
        }
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
            default:
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
        };
    },
    RGB2HEX: function (r, g, b) {
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);
        if (r.length === 1) r = '0' + r;
        if (g.length === 1) g = '0' + g;
        if (b.length === 1) b = '0' + b;
        return '#' + (r + g + b).toUpperCase();
    },
};

export const convertWhiteLabelColor = (str) => {
    if (str.match(/rgb/i) !== null) {
        return RGBToHSL(str);
    } else if (str.match(/\#/i) !== null) {
        return RGBToHSL(hexToRGB(str));
    }
};

function RGBToHSL(rgb) {
    let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
    rgb = rgb.substr(4).split(')')[0].split(sep);

    for (let R in rgb) {
        let r = rgb[R];
        if (r.indexOf('%') > -1) rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
    }

    // Make r, g, and b fractions of 1
    let r = rgb[0] / 255,
        g = rgb[1] / 255,
        b = rgb[2] / 255;

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    // Calculate hue
    // No difference
    if (delta === 0) h = 0;
    // Red is max
    else if (cmax === r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax === g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100);

    const step = 7;

    return {
        themePrimary: HSLToHex('hsl(' + h + ',' + s + '%,' + l.toFixed(1) + '%)'),
        themeLighterAlt: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 5 * step).toFixed(1) + '%)'),
        themeLighter: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 4 * step).toFixed(1) + '%)'),
        themeLight: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 3 * step).toFixed(1) + '%)'),
        themeTertiary: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 2 * step).toFixed(1) + '%)'),
        themeSecondary: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 1 * step).toFixed(1) + '%)'),
        themeDarkAlt: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 1 * step).toFixed(1) + '%)'),
        themeDark: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 2 * step).toFixed(1) + '%)'),
        themeDarker: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 3 * step).toFixed(1) + '%)'),
    };
    // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
}

function HSLToHex(hsl) {
    let sep = hsl.indexOf(',') > -1 ? ',' : ' ';
    hsl = hsl.substr(4).split(')')[0].split(sep);

    let h = hsl[0],
        s = hsl[1].substr(0, hsl[1].length - 1) / 100,
        l = hsl[2].substr(0, hsl[2].length - 1) / 100;

    // Strip label and convert to degrees (if necessary)
    if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
    else if (h.indexOf('rad') > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
    else if (h.indexOf('turn') > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
    if (h >= 360) h %= 360;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
}

function hexToRGB(h) {
    let r = 0,
        g = 0,
        b = 0;

    // 3 digits
    if (h.length === 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

        // 6 digits
    } else if (h.length === 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }

    return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
}

export default colors;
