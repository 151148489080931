import React from 'react';
import styled from 'styled-components/macro';

const UlBox = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
`;

const Li = styled.li`
    border-left: ${props => (props.selected ? '5px solid #328856' : '5px solid transparent')};
    list-style: none;
    margin: 8px 0;
    padding: 0;
    padding-left: 10px;
    color: ${props => (props.selected ? '#000' : '#aaa')};
    cursor: pointer;
    :hover {
        border-left: ${props => (props.selected ? '5px solid #328856' : '5px solid #ccc')};
        color: #000;
    }
`;

const PlSelectionList = React.memo(({ reportingObjectsByPlId_ctg, filteredList, history, onLocationClick, mode }) => {
    const liClick = id => e => {
        onLocationClick(Number(id), mode);
    };

    return (
        reportingObjectsByPlId_ctg && (
            <UlBox>
                {filteredList.map(id => {
                    const selected =
                        mode === 'multiLocation_multiObject' ||
                        mode === 'multiLocation' ||
                        mode === 'multiAll' ||
                        mode === 'main_set_2_contra_set' ||
                        mode === 'main_2_contra_set'
                            ? history.locations[id].active
                            : mode === 'singleLocation'
                            ? history.lastLocationsStack[0] === Number(id)
                            : false;
                    return (
                        <Li
                            selected={selected}
                            onClick={liClick(id)}
                            key={`reportingObjectsByPlId_ctg-${reportingObjectsByPlId_ctg[id].pl_id}`}
                        >
                            {reportingObjectsByPlId_ctg[id].pl_name}
                        </Li>
                    );
                })}
            </UlBox>
        )
    );
});

export default PlSelectionList;
