import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Dash from '../../pl_panel/components/dash';

import SelectDeselectButtons from '../../pl_panel/components/selectDeselectButtons';

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
  padding: 5px;
  margin: 0;
  max-height: 300px;
  overflow: auto;
  padding-left: 30px;
`;

const Li = styled.li`
  padding: 0;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
`;

const ListContainer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const Container = styled.div`
  border-bottom: 1px solid #cbc7c5;
`;

const DropDownBox = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    float: left;
    background: rgba(0, 0, 0, 0.0);
    border: none;
    outline: none;
    margin-top: 4px;
    margin-right: 0px;
    border-radius: 2px;
    margin-left: 10px;
    cursor: pointer;
    top: 0px;
    right: 0px;
    i {
      font-size: 18px;
    }
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    align-self: flex-end;
    :focus {
      outline: none;
    }
  }
`;

const ObjectsList = React.memo(({ history, plId, reportingObjectsByPlId, onObjectDashClick, searchValue, ...props }) => {
    const { t } = useTranslation();
    const toggleDropDownMenu = (plId, item) => e => {
        props.toggleDropDownMenu(plId, item);
    };

    const onSelectAllClick = (plId, item, filteredGroup) => e => {
        props.onSelectAllObjectsClick(plId, item, filteredGroup);
    };

    const onClearAllClick = (plId, item) => e => {
        props.onClearAllObjectsClick(plId, item);
    };

    // console.log('searchValue.length', searchValue.length);

    return (
        <div>
            {Object.keys(reportingObjectsByPlId[plId].objects).map(item => {
                const isDropDownMenuDeployed = history.locations[plId].lists[item];
                const group = reportingObjectsByPlId[plId].objects[item];
                const filteredGroup =
                    searchValue.length === 0
                        ? group
                        : group.filter(obj => {
                            return obj.name.toLowerCase().includes(searchValue.toLowerCase());
                        });
                const groupName =
                    item === 'servicepoints' ? t('Арендаторы') : item === 'floors' ? t('Этажи') : group[0].group_name || '';
                const selectedObjectsNumber = group.reduce((acc, item) => {
                    return history.locations[plId].objects.includes(item.id) ? acc + 1 : acc;
                }, 0);

                return (
                    <Container key={`${plId} - ${item}`}>
                        <DropDownBox>
                            {`${groupName} ${selectedObjectsNumber} / ${group.length}`}
                            <button onClick={toggleDropDownMenu(plId, item)}>
                                {isDropDownMenuDeployed ? (
                                    <i className="fa fa-angle-up" aria-hidden="true" />
                                ) : (
                                    <i className="fa fa-angle-down" aria-hidden="true" />
                                )}
                            </button>
                        </DropDownBox>
                        <ListContainer show={isDropDownMenuDeployed}>
                            <SelectDeselectButtons
                                onSelectAllClick={onSelectAllClick(plId, item, filteredGroup)}
                                onClearAllClick={onClearAllClick(plId, item)}
                            />
                            {/* <Ul>{generateList(group, item)}</Ul> */}
                            <Ul>
                                {filteredGroup.map(obj => {
                                    const name = obj.name;
                                    const statusSelect = history.locations[plId].objects.includes(obj.id);
                                    return (
                                        <Li key={`obj-${obj.id}`}>
                                            <Dash statusSelect={statusSelect} id={obj.id} plId={plId} text={name} onClick={onObjectDashClick} />
                                        </Li>
                                    );
                                })}
                            </Ul>
                        </ListContainer>
                    </Container>
                );
            })}
        </div>
    );
});

export default ObjectsList;
