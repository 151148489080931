import React from 'react';
import styled from 'styled-components/macro';

const Button = styled.div`
    cursor: pointer;
    box-sizing: border-box;
    width: ${p => (p.width ? p.width + 'px' : '100%')};
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${p => (p.disabled ? '#ddd' : '#1e90ff')};
    color: ${p => (p.disabled ? '#bbb' : 'whitesmoke')};
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    &:hover {
        background-color: #0074e4;
    }
    &:active {
        background-color: #005db6;
    }
`;

export default React.memo(({disabled=false, ...props}) => {
    return (
        <Button width={props.width} disabled={disabled} onClick={props.onClick}>
            {props.children}
        </Button>
    );
});
