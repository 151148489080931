import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';

import { filterData } from '../tools';
import NoSelectedWarning from '../../warnings/no_selected_warning';
import ColorDot from '../../leftPanel/pl_panel/components/color_dot';
// import Button from '../../button/button';

const Div = styled.div`
    overflow: auto;
    /* height: 100%; */
    box-sizing: border-box;
    /* background: rgba(242, 0, 0, 0.5); */
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: ${(props) => props.bgColor};
    /* overflow: auto; */
    flex-grow: 1;
    /* flex-basis: 100%; */
    /* height: 100vh; */
`;

const List = styled.ul`
    list-style: none;
    height: 100%;
    list-style: none;
    margin: 6px 0;
    padding: 0;
    overflow: auto;
`;

const ListItem = styled.li`
    position: relative;
    margin: 0;
    width: 100%;
    min-height: 35px;
    background: rgb(242, 242, 242);
    margin: 0;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    /* padding-right: 25px; */
    box-sizing: border-box;

    span {
        font-family: ProximaNova;
        font-size: 12pt;
        display: flex;
        align-items: center;
        color: #328856;
        margin-right: 5px;
        /* word-wrap: break-word;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto; */
    }
`;

const SVG = styled.div`
    /* position: absolute;
    right: 10px;
    top: 0; */
    cursor: pointer;
    display: ${(p) => (p.show ? 'flex' : 'none')};
    align-items: center;
    width: 16px;
    height: 100%;
    opacity: 0.3;
    margin-top: 5px;
    :hover {
        opacity: 0.9;
    }
    transform: opacity 0.5s;
    flex-shrink: 0;
`;

const I = styled.i`
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
    color: #bbb;
    :hover {
        color: red;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;
`;

const OutdoorSelectedSpacesList = React.memo(
    ({
        selectedSpaces,
        colors,
        outdoor_ad_spaces,
        handleTargetClick,
        searchValue,
        selectedAdSpaceTypes,
        isMapExpanded,
        showObjectsColorDots,
        ...props
    }) => {
        const onDeleteButtonClick = (id) => () => {
            props.onDeselectAdSpaceClick(id);
        };

        const filteredData = useMemo(() => filterData(outdoor_ad_spaces, selectedAdSpaceTypes, searchValue).map((item) => item.id), [
            outdoor_ad_spaces,
            searchValue,
            selectedAdSpaceTypes,
        ]);

        return (
            <Div>
                {selectedSpaces && selectedSpaces.length !== 0 && outdoor_ad_spaces.length !== 0 ? (
                    <List>
                        {selectedSpaces.map((item) => {
                            
                            const dotColor = showObjectsColorDots ? colors[`outdooradspace:${item}`] : false;
                            const space = outdoor_ad_spaces.filter((adSpace) => adSpace.id === item)[0];
                            const showAim = filteredData.includes(item);
                            return (
                                <ListItem key={`ListItem${space.id}`}>
                                    <span>{`${space.project_location_name.replace(/,(?=[^\s])/g, ', ').replace(/\.(?=[^\s,])/g, '. ')}, ${
                                        space.name
                                    }`}</span>
                                    <Wrapper>
                                        <ColorDot
                                            margin={'0'}
                                            dotColor={dotColor}
                                            showDeleteObjectsButtons={isMapExpanded}
                                            onClick={onDeleteButtonClick(space.id)}
                                        />
                                        {isMapExpanded ? (
                                            <SVG show={showAim} onClick={handleTargetClick(space)}>
                                                <svg height="20" viewBox="0 0 50 50" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m23 0v4.0957031c-9.981298.9489961-17.953104 8.9227909-18.9023438 18.9042969h-4.0976562v4h4.0976562c.9492398 9.981506 8.9210458 17.955301 18.9023438 18.904297v4.095703h4v-4.097656c9.981223-.949213 17.953131-8.921121 18.902344-18.902344h4.097656v-4h-4.097656c-.949213-9.981223-8.921121-17.953131-18.902344-18.9023438v-4.0976562zm4 8.1269531c7.805997.9099644 13.963083 7.0670499 14.873047 14.8730469h-2.873047v4h2.873047c-.909964 7.805997-7.06705 13.963083-14.873047 14.873047v-2.873047h-4v2.871094c-7.803628-.911192-13.9631575-7.06574-14.8730469-14.871094h2.8730469v-4h-2.8730469c.9098894-7.805354 7.0694189-13.9599017 14.8730469-14.8710938v2.8710938h4zm-2 9.8730469a7 7 0 0 0 -7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0 -7-7z" />
                                                </svg>
                                            </SVG>
                                        ) : null}
                                    </Wrapper>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    <NoSelectedWarning text={_t.t('Не выбрано ни одной рекламной поверхности')} />
                )}
            </Div>
        );
    }
);

export default OutdoorSelectedSpacesList;
