import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const Label = styled.label`
  cursor: pointer;
  input {
    margin-right: 5px;
  }
`;

const IndicatorsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const DiagramOptions = React.memo(({ indexDataView, diagramOptionsByID, ...props }) => {
  const { t } = useTranslation();

  const toggleIndicator = name => e => {
    props.toggleIndicator(name, indexDataView);
  };
 
  const toggleSettings = settingId => e => {
    props.toggleSettings(settingId, indexDataView);
  };

  return (
    <div className={'ReportInfo'}>
      <h4>{t('Индикаторы')}</h4>
      <IndicatorsList>
        {diagramOptionsByID[indexDataView].indicators.map(item => {
          return (
            <Label key={`${item.name}-${indexDataView}`}>
              <input checked={item.show} onChange={toggleIndicator(item.name)} type="checkbox" />
              {t(item.name)}
            </Label>
          );
        })}
      </IndicatorsList>
      <h4>{t('Настройки')}</h4>
      <IndicatorsList>
        {diagramOptionsByID[indexDataView].settings.map(item => {
          return (
            <Label
                // disabled={true}
                key={`${item.name}-${indexDataView}`}
            >
              <input
                  checked={item.checked}
                  onChange={toggleSettings(item.id)}
                  // disabled={true}
                  type="checkbox"
              />
              {t(item.name)}
            </Label>
          );
        })}
      </IndicatorsList>
    </div>
  );
});

export default DiagramOptions;
