import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const roundUp = (maxValue, minValue, number, precision) => {
    return maxValue - minValue <= 100
        ? number.toFixed(1)
        : Math.ceil(number / precision) * precision;
};

const getNumberLength = number => (Math.log(number) * Math.LOG10E + 1) | 0;

const getPrecision = number => {
    if (getNumberLength(number) < 3) {
        return 1;
    } else {
        return Math.pow(10, getNumberLength(number) - 2);
    }
};

const Rainbow = React.memo(
    ({
        zIndex = 2001,
        width = 100,
        bottom = 20,
        left = 10,
        colorFoo,
        opacity = 0.8,
        minValue = 1,
        maxValue = 100,
        mode = 'black',
        ...props
    }) => {
        const [normalized, setNormalized] = useState([]);
        const [values, setValues] = useState([]);
        const bins = 50;

        const splitRange = (min, max, bins) => {
            const arr = [];
            for (let i = 0; i <= bins; i++) {
                arr.push(((max - min) * i) / bins + min);
            }
            return arr;
        };

        useEffect(() => {
            setNormalized(() => {
                const arr = splitRange(0, 1, bins);
                return arr;
            });
            setValues(() => splitRange(minValue, maxValue, 5));
        }, [bins, minValue, maxValue]);

        const labels = useMemo(() => {
            return values.map((value, i) => {
                const normValue = (value - minValue) / (maxValue - minValue);
                const color = mode === 'black' ? '#000' : colorFoo(normValue);
                return (
                    <Value key={`${value} - ${i} - value`} color={color}>
                        {roundUp(maxValue, minValue, value, getPrecision(value))}
                        <Stroke></Stroke>
                    </Value>
                );
            });
        }, [values, minValue, maxValue]);

        const colorBins = useMemo(() => {
            return normalized.map((value, i) => {
                const bgColor = colorFoo(value);
                return <ColorBin bgColor={bgColor} key={`${value} - ${i} - norm`}></ColorBin>;
            });
        }, [values, minValue, maxValue]);

        return colorFoo ? (
            <Container zIndex={zIndex} width={width} bottom={bottom} left={left}>
                <LabelsContainer>{labels}</LabelsContainer>
                <ColorBinsContainer opacity={opacity}>{colorBins}</ColorBinsContainer>
            </Container>
        ) : null;
    }
);

export default Rainbow;

const Container = styled.div`
    box-sizing: border-box;
    width: calc(${p => p.width + '%'} - ${p => 2 * p.left + 'px'});
    position: absolute;
    z-index: ${p => p.zIndex};
    bottom: ${p => p.bottom + 'px'};
    left: ${p => p.left + 'px'};
`;

const LabelsContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ColorBinsContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-auto-flow: column;
    opacity: ${p => p.opacity};
`;

const Value = styled.div`
    color: ${p => p.color};
    box-sizing: border-box;
    /* border: 1px solid black; */
    /* background-color: rgba(255, 255, 255, 0.9); */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Stroke = styled.div`
    box-sizing: border-box;
    height: 7px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0px;
    z-index: 9;
`;

const ColorBin = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 6px;
    background-color: ${p => p.bgColor};
`;
