import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    background-color: #fff;
    width: 100%;
    min-height: 50px;
    /* margin-top: auto; */
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    box-sizing: border-box;
`;

const Button = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    height: 35px;
    border-bottom: 1px solid #c4c1bf;
    font-family: ProximaNova;
    font-size: 12pt;
    color: #243138;
    background-color: ${p => (p.disabled ? '#c4c1bf' : '#bedfff')};
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
`;

export default React.memo(props => {
    const { t } = useTranslation();
    const toggleObjSetsPanel = show => e => {
        props.toggleObjSetsPanel(show);
    };

    return (
        <Container>
            <Button disabled={props.disabled} onClick={toggleObjSetsPanel(true)}>
                {t('Наборы объектов')}
            </Button>
        </Container>
    );
});
