
import Plan from './components/Plan'
import {connect} from "react-redux";

import React, {Component} from 'react';
import { runAction } from "../../actions";
import $ from "jquery";
import hostForLocation from '../../hostnames/hostname'
import { DateTime } from "luxon";

Date.prototype.yyyymmdd = function() {
    var yyyy = this.getFullYear().toString();
    var mm = (this.getMonth() + 1).toString();
    var dd = this.getDate().toString();
    return yyyy + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + (dd[1] ? dd : "0" + dd[0]);
};

class MainPlan extends Component {
    render() {
        // console.log(this.props.statusShowMaxCalendar);
        return (
            <div>
                <Plan
                    dataPlan={this.props.dataPlan}
                    time={this.props.time}
                    isChecked={this.props.isChecked}
                    statusLoadPlan={this.props.statusLoadPlan}
                    statusShowMaxCalendar={this.props.statusShowMaxCalendar}
                    title={this.props.title}
                    statusEdit={this.props.statusEdit}
                    dataMallsId={this.props.dataMallsId}
                    browserLangsForAnyButton={this.props.browserLangsForAnyButton}
                    browserLangs={this.props.browserLangs}

                    getTimeMax={this.props.getTimeMax}
                    getTimeMin={this.props.getTimeMin}
                    selectAllDay={this.props.selectAllDay}
                    onClickButtonAllLang={this.props.onClickButtonAllLang}
                    selectAllTime={this.props.selectAllTime}
                    selectAllDate={this.props.selectAllDate}
                    resetData={this.props.resetData}
                    save={this.props.save}
                    pl_ids={this.props.pl_id}
                    setStatusPlanPanel={this.props.setStatusPlanPanel}
                    showMaxCalendar={this.props.showMaxCalendar}
                    handleEvent={this.props.handleEvent}
                    onClickDay={this.props.onClickDay}
                    onSelectLang={this.props.onSelectLang}
                    token={this.props.token}
                    arrayLocationSelectNotMulti={this.props.arrayLocationSelectNotMulti}
                    _t={this.props._t}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dataPlan: state.PlanApp.dataPlan,
        time: state.PlanApp.dataPlan.time_period,
        isChecked: false,
        statusLoadPlan: state.PlanApp.statusLoadPlan,
        statusShowMaxCalendar: state.PlanApp.statusShowMaxCalendar,
        title: state.PlanApp.titleTemplateOfPlan,
        statusEdit: state.PlanApp.statusEdit,
        dataMallsId: state.PlanApp.dataMallsId,
        browserLangsForAnyButton: state.PlanApp.browserLangsForAnyButton,
        browserLangs:state.PlanApp.browserLangs,
        token:state.LoginApp.token,
        arrayLocationSelectNotMulti:state.PanelLocationApp.arrayLocationSelectNotMulti,
        pl_ids:state.ReportParametersManager.customInputParamsValues.pl_ids,
        pl_id:state.ReportParametersManager.customInputParamsValues.pl_id
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    // console.log('mapDispatchToProps', dispatch, ownProps);
    return {
        getTimeMax: (data) => {

            dispatch(runAction('getTimeMax', {data:data}));
        },
        getTimeMin: (data) => {
            dispatch(runAction('getTimeMin', {data:data}));
        },
        selectAllDay: () => {
            dispatch(runAction('selectAllDay', {}));
        },
        onClickButtonAllLang: () => {
            dispatch(runAction('onClickButtonAllLang', {}));
        },
        selectAllTime: () => {
            dispatch(runAction('selectAllTime', {}));
        },
        selectAllDate: () => {
            dispatch(runAction('selectAllDate', {}));
        },
        resetData: () => {
            dispatch(runAction('resetData', {}));
        },
        save: (data, t) => {
            // console.log('><><><><><><><><>', data)
            let url;
            if (data.dataPlan.id === -1) {
                
                url = `${hostForLocation.getHostName().cms}/content/add_schedule/`;


                var data2 = {
                    access_token: data.xToken,
                    object_ids: JSON.stringify(data.dataPlan.objetct_ids),
                    page_id: data.dataPlan.page_id,
                    details: JSON.stringify({
                        time_from: data.dataPlan.time_period.from,
                        time_to: data.dataPlan.time_period.to,
                        time_always: data.dataPlan.time_period.always,
                        week_days: data.dataPlan.week_days,
                        date_from: data.dataPlan.date_period.from,
                        date_to: data.dataPlan.date_period.to,
                        date_always: data.dataPlan.date_period.always,
                        browser_lang_code:data.dataPlan.browser_lang_code
                    })
                };
            }
            else if (data.dataPlan.id !== -1) {
                url = `${hostForLocation.getHostName().cms}/content/update_schedule/`;
                var data2 = {
                    access_token: data.xToken,
                    schedule_id: data.dataPlan.id,
                    details: JSON.stringify({
                        time_from: data.dataPlan.time_period.from,
                        time_to: data.dataPlan.time_period.to,
                        time_always: data.dataPlan.time_period.always,
                        week_days: data.dataPlan.week_days,
                        date_from: data.dataPlan.date_period.from,
                        date_to: data.dataPlan.date_period.to,
                        date_always: data.dataPlan.date_period.always,
                        browser_lang_code:data.dataPlan.browser_lang_code
                    })
                };
            }

            dispatch(runAction('isLoadSettingsRequest', {status:true}))
            dispatch(runAction('setStatusSpinerPlan', {status:true}));
            dispatch(runAction('save', {data:data2,url:url, t:t}));
        },
        setStatusPlanPanel: (status) => {
            dispatch(runAction('setStatusPlanPanel', {status:status}));
        },

        showMaxCalendar: function () {
            dispatch(runAction('showMaxCalendar', {}));
        },


        handleEvent: function (ev, picker) {
            // console.log('content???????', picker.endDate);
            if (picker.endDate !== undefined) {
                dispatch(runAction('getDate', {data: {max: picker.endDate.format('YYYY.MM.DD'), min: picker.startDate.format('YYYY.MM.DD')}}));
            }
            
        },
        onClickDay: function (e) {
            if ($(e.target).parents().filter('#Monday').length === 1 || $(e.target).filter('#Monday').length) {
                dispatch(runAction('getDay', {day:'Monday'}));
            }
            else if ($(e.target).parents().filter('#Tuesday').length === 1 || $(e.target).filter('#Tuesday').length) {
                dispatch(runAction('getDay', {day:'Tuesday'}));
            }
            else if ($(e.target).parents().filter('#Wednesday').length === 1 || $(e.target).filter('#Wednesday').length) {
                dispatch(runAction('getDay', {day:'Wednesday'}));
            }
            else if ($(e.target).parents().filter('#Thursday').length === 1 || $(e.target).filter('#Thursday').length) {
                dispatch(runAction('getDay', {day:'Thursday'}));
            }
            else if ($(e.target).parents().filter('#Friday').length === 1 || $(e.target).filter('#Friday').length) {
                dispatch(runAction('getDay', {day:'Friday'}));
            }
            else if ($(e.target).parents().filter('#Saturday').length === 1 || $(e.target).filter('#Saturday').length) {
                dispatch(runAction('getDay', {day:'Saturday'}));
            }
            else if ($(e.target).parents().filter('#Sunday').length === 1 || $(e.target).filter('#Sunday').length) {
                dispatch(runAction('getDay', {day:'Sunday'}));
            }

        },
        onSelectLang: (e) => {
            if ($(e.target).parents().filter('#RU').length === 1 || $(e.target).filter('#RU').length) {
                dispatch(runAction('getBrowserLang', {lang:'ru'}));
            }
            else if ($(e.target).parents().filter('#EN').length === 1 || $(e.target).filter('#EN').length) {
                dispatch(runAction('getBrowserLang', {lang:'en'}));
            }
            else if ($(e.target).parents().filter('#ZH').length === 1 || $(e.target).filter('#ZH').length) {
                dispatch(runAction('getBrowserLang', {lang:'zh'}));
            }
            else if ($(e.target).parents().filter('#ES').length === 1 || $(e.target).filter('#ES').length) {
                dispatch(runAction('getBrowserLang', {lang:'es'}));
            }
        }
    }
};

const ConnectMainPlan = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPlan);

export default ConnectMainPlan;

