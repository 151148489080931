import { combineReducers } from 'redux';
import LeftPanelApp from './listLeftPanel';
import TopPanel from './TopPanel';
import ProjectLocationApp from './ReducerPLButton';
import CalendarApp from './Calendar';
// import CalendarBlockApp from './CalendarBlock';
import PanelLocationApp from './PanelLocation';
import PanelReportingsApp from './PanelReportings';
import LocationsPanel from './LocationsPanel';
import EngineeringMenu from './EngineeringMenu';
import LoginApp from './login';
import ReportParametersManager from './ReportParametersManager';
import MapsApp from './MapsReducer';
import { routerReducer } from 'react-router-redux';
import RadiusApp from './RadiusReducer';
import PlanApp from './PlanReducer';
import OutdoorSpaces from './OutdoorSpaces';
import Diagrams from './Diagrams';
import AdCampaigns from './AdCampaigns';
import CustomControls from './CustomControls';
import XLSX from './xlsx';
import Pdf from "./pdf";
import PreCampaigns from "./Precampaing";

const AppStore = combineReducers({
    routing: routerReducer,
    LeftPanelApp,
    AdCampaigns,
    CustomControls,
    TopPanel,
    ProjectLocationApp,
    CalendarApp,
    LocationsPanel,
    EngineeringMenu,
    // CalendarBlockApp,
    PanelLocationApp,
    PanelReportingsApp,
    LoginApp,
    ReportParametersManager,
    MapsApp,
    RadiusApp,
    PlanApp,
    OutdoorSpaces,
    Diagrams,
    XLSX,
    Pdf,
    PreCampaigns
});

export default AppStore;
