import Tools from '../tools/tools';

Date.prototype.yyyymmdd = function() {
    var yyyy = this.getFullYear().toString();
    var mm = (this.getMonth() + 1).toString();
    var dd = this.getDate().toString();
    return yyyy + ':' + (mm[1] ? mm : "0" + mm[0]) + ':' + (dd[1] ? dd : "0" + dd[0]);
};

const createCardPlan = (state, action) => {
    // console.log('store.js', state.schedulesList);
    let statusShowPlanCard = Tools.copy(state.statusShowPlanCard)
    if (action.id === null) {
        statusShowPlanCard = false;
    } else if (action.id !== null) {
        // console.log('store.js', state.schedulesList);
        statusShowPlanCard = true;
        var dateString, timeString;
        for (var i = 0; i < state.schedulesList.length; i++) {
            if (action.id === state.schedulesList[i].id) {
                if (state.schedulesList[i].date_period.always === true) {
                    dateString = 'всегда';
                } else if (state.schedulesList[i].date_period.always === false) {
                    dateString = 'с' + state.schedulesList[i].date_period.from + 'по' + state.schedulesList[i].date_period.to;
                }
                if (state.schedulesList[i].time_period.always === true) {
                    timeString = 'всегда';
                } else if (state.schedulesList[i].time_period.always === false) {
                    timeString = 'с' + state.schedulesList[i].time_period.from + 'до' + state.schedulesList[i].time_period.to;
                }
                var cardPlan = {
                    img: state.schedulesList[i].page_preview,
                    datePeriod: {
                        text: dateString
                    },
                    weekDays: state.schedulesList[i].week_days.join(', '),
                    timePeriod: {
                        text: timeString
                    }
                };

            }
        }
    }
    return Object.assign({}, state, {
        statusShowPlanCard:statusShowPlanCard,
        cardPlan:cardPlan,

    })
}

const setTemplateControl = (state, action) => {

    let statusRequestTemplate, statusSpinerLoaderTemplate;
    statusRequestTemplate = true;
    let isShowSpinerPlan = true
    let statusRequestSchedulesList = state.statusRequestSchedulesList
    if (state.statusRequestSchedulesList === true) {
        isShowSpinerPlan = false
        statusSpinerLoaderTemplate = false;
    }
    // console.log('>>>>>>>>>>>!!!!1', isShowSpinerPlan)
    let template = Tools.copy(action.json);
    if (template.pages) {
        for (var j = 0; j < template.pages.length; j++) {
            // console.log('>>>>>>>>>', template.pages[j].data !== '[object Object]')
            if (template.pages[j].data !== '[object Object]') {
                var layersSum = 1;
                var detail2 = JSON.parse(template.pages[j].data).authParams
                if (detail2 !== undefined && detail2 !== null){
                    if (detail2.layers !== undefined) {
                        for (var i = 0; i < detail2.layers.length; i++) {
                            layersSum = layersSum * detail2.layers[i].length
                        }
                    }
                }


                var obj2 = JSON.parse(template.pages[j].data);
                if (layersSum === 0) {
                    obj2.authParams = null;
                    template.pages[j].data = JSON.stringify(obj2);

                }
            }
            else {
                var obj2 = {
                    authParams:null
                };
                template.pages[j].data = JSON.stringify(obj2);
            }

        }

        for (var i = 0; i < template.pages.length; i++) {
            template.pages[i].statusEnter = false;
        }
    }


    return Object.assign({}, state, {
        statusRequestTemplate:statusRequestTemplate,
        statusSpinerLoaderTemplate:statusSpinerLoaderTemplate,
        template:template,
        isShowSpinerPlan: isShowSpinerPlan,
        statusRequestSchedulesList: statusRequestSchedulesList

    })
};



const onMouseEnterRadius = (state,action) => {
    return Object.assign({}, state, {
        statusShowSettings: action.status,
        idTemplateShowSettings:action.id
    })
}

const onMouseLeaveRadius = (state,action) => {
    return Object.assign({}, state, {
        statusShowSettings: action.status

    })
}

const setScheduleList = (state,action) => {
    var statusRequestSchedulesList = true;
    let statusSpinerLoaderTemplate
    let isShowSpinerPlan = true
    
    let statusRequestTemplate = state.statusRequestTemplate
    if (state.statusRequestTemplate === true) {
        statusSpinerLoaderTemplate = false;
        isShowSpinerPlan = false
    }
    if (state.isShowSpinerPlan === true) {
        isShowSpinerPlan = false
    }
    let schedulesList = [];
    schedulesList = action.json;
    let objLangforBrowser = {}
    if (Array.isArray(schedulesList) === false) {
        return Object.assign({}, state, null)
    }
    schedulesList.forEach((item) => {
        objLangforBrowser[item.browser_lang_code === null ? 'default' : item.browser_lang_code] = item.browser_lang_code
    })
    return Object.assign({}, state, {
        statusRequestSchedulesList:statusRequestSchedulesList,
        statusSpinerLoaderTemplate:statusSpinerLoaderTemplate,
        isShowSpinerPlan: isShowSpinerPlan,
        objLangforBrowser:objLangforBrowser,
        schedulesList:schedulesList,
        statusRequestTemplate:statusRequestTemplate,
        statusLangBrowser:objLangforBrowser['default'] !== undefined ? 'default' : objLangforBrowser['ru'] !== undefined ?
            'ru' : objLangforBrowser['en'] !== undefined ? 'en' : objLangforBrowser['es'] !== undefined ? 'es' : objLangforBrowser['zh'] !== undefined ?
                'zh' : 'default',

    })

};

const createStatusEnterPlan = (state,action) => {
    let template = Tools.copy(state.template);
    if (action.id === null) {
        for (var i = 0; i < template.pages.length; i++) {
            template.pages[i].statusEnter = false;
        }
    } else if (action.id !== null) {
        for (var i = 0; i < template.pages.length; i++) {
            if (action.id === template.pages[i].id) {
                template.pages[i].statusEnter = true;
            }
        }
    }

    return Object.assign({}, state, {
        template:template
    })
};

const planing = (state,action) => {

    return Object.assign({}, state, {
        idTemplateOfPlan:action.id,
        titleTemplateOfPlan:action.title,
        statusShowPlanPanel:true
    })
}

const openSettingsPanel = (state,action) => {
    return Object.assign({}, state, {
        statusShowSettingsPanel:true,
        itemSettingsTemplate:action.data
    })
}

const editPlan = (state,action) => {
    return Object.assign({}, state, {
        idTemplateOfPlan:action.data.page_id,
        titleTemplateOfPlan:action.data.page_title,
        statusShowPlanPanel: true
    })
}

const hideSettingsPanelAuth = (state,action) => {
    return Object.assign({}, state, {
        statusShowSettingsPanel:false
    })
}

const showPanelNameNewPage = (state,action) => {
    return Object.assign({}, state, {
        statusShowPanelNameNewPage:true
    })
}

const  hidePanelNameNewPage = (state,action) => {
    return Object.assign({}, state, {
        statusShowPanelNameNewPage:false,
        nameNewPage:''
    })
}

const onChangeNamePage = (state,action) => {
    return Object.assign({}, state, {
        nameNewPage:action.data
    })
}

const onClickLangBrowser = (state,action) => {
    return Object.assign({}, state, {
        statusLangBrowser:action.status
    })
}

const setStatusSpinerPlan = (state,action) => {
    const {status} = action;
    return {
        ...state,
        isShowSpinerPlan: status,
        statusRequestTemplate: false,
        statusRequestSchedulesList: false
    }
}

const setErrorRequestControlPage = (state,action) => {
    const {mess} = action;
    console.log('><><><><><><>', mess)
    alert(mess)
    return {
        ...state,
        isShowSpinerPlan: false,
        statusRequestTemplate: false,
        statusRequestSchedulesList: false,
        errorMessage: mess
    }
}

const getStatusLoadSettingsPanel = (state,action) => {
    const {status} = action;

    return {
        ...state,
        isShowSpinerPlan: status
    }
}


let initState = {
    mainState: {},
    stateLeftPanel: {},
    stateStaticContent: {},
    analiticsMethod: 'connect',
    funnels: {},
    dataCharts: [{
        key: 'Запуски подключений',
        values: []
    }, {
        key: 'Пройденные авторизации',
        values: []
    }],
    dataChartsPeople: [{
        key: 'Все пользователи',
        values: []
    }, {
        key: 'Прошедшие авторизацию',
        values: []
    }],
    globalSum: {
        totalSum: 0,
        successSum: 0,
        defeatSum: 0
    },
    globalSumPeople: {
        totalSum: 0,
        successSum: 0,
        defeatSum: 0
    },
    statusChartShow: false,
    statusFunnelsShow: false,
    statusLoadFunnels: false,
    statusChartPeopleShow: false,
    dataSms: {},
    statusShowSms: false,
    statusShowSocial: false,
    uniqueNumbers: {},
    arrayCountNumberOfTrue: [],
    socialData: [],
    statusCreateUnic: false,
    statusCreateSms: false,
    dataTableSms: [],
    dataChartSocial: [],
    sumSms: 0,
    template: [],
    statusSpinerLoaderCharts: false,
    statusSpinerLoaderTemplate: false,
    dataPlan: {
        time_period: {
            from: "12:00",
            to: "16:00",
            always: true
        },
        week_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        date_period: {
            from: +new Date(),
            to: "<текущая дата + неделя>",
            always: true
        }
    },
    idTemplateOfPlan: null,
    titleTemplateOfPlan: null,
    statusShowPlanPanel: false,
    statusRequestTemplate: false,
    statusRequestSchedulesList: false,
    schedulesList: [],
    panelState: {},
    statusShowPlanCard: false,
    cardPlan: {
        img: null,
        datePeriod: {
            text: null
        },
        weekDays: null,
        timePeriod: {
            text: null
        }
    },
    statusShowSettingsPanel: false,
    itemSettingsTemplate: {},
    nameNewPage: '',
    statusShowPanelNameNewPage: false,
    statusSmsPushArr: {},
    uniqNambers: [],
    response: {},
    sumSms2: 0,
    sumCall: 0,
    globalSumSms: 0,
    statusShowSettings:false,
    idTemplateShowSettings: null,
    objLangforBrowser:{},
    statusLangBrowser: 'default',
    isShowSpinerPlan: false,
    errorMessage:""

}

const RadiusApp = (state=initState, action) => {
    switch (action.type) {
        case 'setTemplateControl':
            return setTemplateControl(state, action);
        case 'onMouseEnterRadius':
            return onMouseEnterRadius(state,action);
        case 'onMouseLeaveRadius':
            return onMouseLeaveRadius(state, action);
        case 'setScheduleList':
            return setScheduleList(state, action);
        // case 'deletePlansItem':
        //     return deletePlansItem(state, action);
        case 'onEnterPlan':
            return createStatusEnterPlan(state, action);
        case 'planing':
            return planing(state, action);
        case 'openSettingsPanel':
            return openSettingsPanel(state, action);
        case 'hideSettingsPanelAuth':
            return hideSettingsPanelAuth(state, action);
        case 'showPanelNameNewPage':
            return showPanelNameNewPage(state, action);
        case 'hidePanelNameNewPage':
            return hidePanelNameNewPage(state, action);
        case 'onChangeNamePage':
            return onChangeNamePage(state, action);
        case 'getPlanId':
            return createCardPlan(state, action);
        case 'onClickLangBrowser':
            return onClickLangBrowser(state, action);
        case 'setStatusSpinerPlan':
            return setStatusSpinerPlan(state, action);
        case 'setErrorRequestControlPage':
            return setErrorRequestControlPage(state, action);
        case 'isLoadSettingsRequest':
            return getStatusLoadSettingsPanel(state, action);
        default:
            return state;
    }
};

export default RadiusApp;
