import React, { useEffect, useState } from 'react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { runAction } from '../../../actions';
import XLSX from 'xlsx';
import _t from 'i18next';
import TreePanel from './components/tree_panel';
import Tabs from './components/tabs';
import Table from './components/table';
import ReportParams from './components/report_params';
import Tools from '../../../tools/tools';
import './styles/complete_tables.scss';
import ReportTitle from '../../report_title';
import { prepareTableData } from '../../xlsx/tools/prepareTableData';

const CompleteTablesPanel = React.memo(
    ({
        groupIDsBySubsectionKeyName,
        reportParams,
        mode,
        isCompleteTablesShown,
        completeTables,
        lang,
        projectLocationsById,
        currentSubSection,
        allowedTypes,
        dataViewsByGroupID,
        locationsСatalog,
        currentSection,
        sectionsByKeyName,
        adCampaignsById,
        outdoorAdSpacesById,
        customInputParamsValues,
        ...props
    }) => {
        const customParamsSet = Tools.useGetParamSet().custom;
        const [theme] = useState(getTheme());
        const getGroupsToShow = () => {
            try {
                return groupIDsBySubsectionKeyName[currentSubSection].map((item) => {
                    return dataViewsByGroupID[item];
                });
            } catch (error) {
                console.log(error);
            }
        };

        const getAllowedTables = (arr) => {
            try {
                return arr
                    .filter((arrItem) => {
                        return allowedTypes[arrItem.data_report.data_type.key_name] && props.reportData[arrItem.data_view_id].response;
                    })
                    .map((item) => {
                        const data = props.reportData[item.data_view_id].response;
                        return {
                            index: item.data_view_id,
                            data_report: item.data_report,
                            data,
                        };
                    });
            } catch (error) {}
        };

        const getData = () => {
            try {
                const groupsToShow = getGroupsToShow();
                const result = groupsToShow.map((item) => {
                    return {
                        groupName: item[0].group.translations[lang].name,
                        allowedTables: getAllowedTables(item),
                    };
                });
                return result;
            } catch (error) {}
        };

        const renderTabs = () => {
            const tabs = [
                <div key={'22- Параметры отчета'} label={_t.t('Параметры отчета')}>
                    <ReportParams reportParams={reportParams} />
                </div>,
            ];

            switch (mode) {
                case 1:
                    try {
                        tabs.push(
                            ...completeTables[currentSubSection].map((item) => {
                                return (
                                    <div key={`1-${item.groupName}`} label={item.groupName}>
                                        {item.allowedTables.map((table, i) => {
                                            return (
                                                <div key={`1-table${i}`} className={'TableContainer'}>
                                                    <Table handsData={table} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        );
                    } catch (error) {}
                    break;
                case 2:
                    tabs.push(
                        ...completeTables[currentSubSection].map((item) => {
                            return (
                                <div key={`2-${item.groupName}`} label={item.groupName}>
                                    {item.allowedTables.reverse().map((table, i) => {
                                        return (
                                            <div key={`2-table${i}`} className={'TableContainer'}>
                                                <Table handsData={table.data.data} />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    );
                    break;

                default:
                    break;
            }
            return tabs;
        };

        useEffect(() => {
            if (customParamsSet.length === 0) return;
            try {
                const indexes = [];
                const data = getData();

                data.forEach((item) => item.allowedTables.forEach((item) => indexes.push(item.index)));
                const reportParams = indexes.reduce((acc, item) => {
                    return { ...acc, ...props.reportData[item].obj };
                }, {});

                const reportParameters = {
                    reportParams,
                    paramsList: props.inputParametersList,
                    locationsСatalog,
                    projectLocationsById,
                    customParamsSet,
                    adCampaignsById,
                    outdoorAdSpacesById,
                };
                props.saveTablesDataToStore(1, data, lang, currentSubSection, null, reportParameters);
            } catch (error) {
                props.hideTables();
                alert(_t.t('Пожалуйста, дождитесь загрузки диаграмм!'));
            }
        }, [groupIDsBySubsectionKeyName, customParamsSet]);

        const onCompleteTablesModeChange = (mode) => {
            props.saveTablesDataToStore(mode, getData(), lang, currentSubSection, null);
        };

        const onTableCheck = (index) => {
            props.saveTablesDataToStore(mode, getData(), lang, currentSubSection, index);
        };

        const createBook = (DATA, reportParams, currentSubSection) => {
            const data = DATA.map((item) => {
                const groupName = item.groupName.length <= 31 ? item.groupName : item.groupName.match(/.{28}/)[0] + '...';
                return { ...item, groupName };
            });
            const workBook = XLSX.utils.book_new();

            workBook.Props = {
                Title: 'Shopster Report',
                Subject: 'Report',
                Author: 'Shopster',
                CreatedDate: new Date(),
            };

            const parametersSheetName = _t.t('Параметры отчета');
            const sheetsNames = data.map((item) => item.groupName);
            workBook.SheetNames.push(parametersSheetName, ...sheetsNames);
            const parametersSheet = XLSX.utils.aoa_to_sheet(reportParams);
            workBook.Sheets[parametersSheetName] = parametersSheet;

            switch (mode) {
                case 1:
                    data.forEach((item) => {
                        const aggregatedArr = [];
                        const dataArr = item.allowedTables.reduce((acc, tbl) => {
                            return [...acc, tbl, [[' ']]];
                        }, []);
                        dataArr.forEach((item) => {
                            item.forEach((row) => aggregatedArr.push(row));
                        });
                        const dataSheet = XLSX.utils.aoa_to_sheet(prepareTableData(aggregatedArr));
                        workBook.Sheets[item.groupName] = dataSheet;
                    });
                    break;

                case 2:
                    data.forEach((item) => {
                        const aggregatedArr = [];
                        const dataArr = item.allowedTables.reduce((acc, tbl) => {
                            return [...acc, tbl.data.data, [[' ']]];
                        }, []);
                        dataArr.forEach((item) => {
                            item.forEach((row) => aggregatedArr.push(row));
                        });
                        const dataSheet = XLSX.utils.aoa_to_sheet(prepareTableData(aggregatedArr));
                        workBook.Sheets[item.groupName] = dataSheet;
                    });
                    break;

                default:
                    break;
            }

            return workBook;
        };

        const onSaveXLSXClick = () => {
            const data = completeTables[currentSubSection];
            const { main_date_range } = customInputParamsValues;
            const subsection = sectionsByKeyName[currentSection].subsections.filter((item) => item.key_name === currentSubSection)[0];
            const dates = ` ${main_date_range[0]} - ${main_date_range[1]}`;
            const fileName = subsection.translations[lang].name + dates;
            XLSX.writeFile(createBook(data, reportParams, currentSubSection), `${fileName}.xlsx`);
        };

        const hideTables = () => {
            props.hideTables();
        };

        const sectionName = sectionsByKeyName[currentSection].translations[lang].name;
        const subsectionName = sectionsByKeyName[currentSection].subsections.filter((item) => item.key_name === currentSubSection)[0]
            .translations[lang].name;
        const reportTitle = `Excel Report: ${sectionName}, ${subsectionName}`;

        const closeIcon = { iconName: 'ErrorBadge' };
        return isCompleteTablesShown && completeTables[currentSubSection] ? (
            // return isCompleteTablesShown ? (
            <div className={'CompleteTablesPanel'}>
                <TreePanel
                    _t={_t}
                    data={props.tree[currentSubSection]}
                    lang={lang}
                    shownIndexes={props.shownIndexes}
                    onTableCheck={onTableCheck}
                    onCompleteTablesModeChange={onCompleteTablesModeChange}
                    mode={mode}
                    onSaveXLSXClick={onSaveXLSXClick}
                />
                <div className={'TabsContainer'}>
                    <ReportTitle title={reportTitle} />
                    <StIconButton iconProps={closeIcon} onClick={hideTables} title="Close" ariaLabel="Close" />
                    <Tabs>{renderTabs()}</Tabs>
                </div>
            </div>
        ) : null;
    }
);

const StIconButton = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
    i {
        font-size: 24px !important;
    }
`;

const mapStateToProps = (state) => {
    return {
        isCompleteTablesShown: state.XLSX.isCompleteTablesShown,
        allowedTypes: state.XLSX.allowedTypes,
        shownIndexes: state.XLSX.shownIndexes,
        reportParams: state.XLSX.reportParams,
        mode: state.XLSX.mode,
        completeTables: state.XLSX.completeTables,
        tree: state.XLSX.tree,
        reportData: state.ReportParametersManager.reportData,
        outdoorAdSpacesById: state.ReportParametersManager.Source.ctg.outdoorAdSpacesById,
        groupIDsBySubsectionKeyName: state.ReportParametersManager.groupIDsBySubsectionKeyName,
        dataViewsByGroupID: state.ReportParametersManager.dataViewsByGroupID,
        inputParametersList: state.ReportParametersManager.input_parameter_list,
        customInputParamsValues: state.ReportParametersManager.customInputParamsValues,
        currentSection: state.TopPanel.status,
        lang: state.TopPanel.lang,
        locationsСatalog: state.PanelLocationApp.objDataByObjId,
        projectLocationsById: state.ReportParametersManager.Source.ctg.projectLocationsById,
        adCampaignsById: state.ReportParametersManager.Source.ctg.adCampaignsById,
        currentSubSection: state.TopPanel.statusLeftPanel,
        sectionsByKeyName: state.TopPanel.sectionsByKeyName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveTablesDataToStore: (mode, data, lang, currentSubSection, index, reportParams) => {
            dispatch(runAction('onTableCheck', { index }));
            dispatch(runAction('onCompleteTablesModeChange', { mode: Number(mode) }));
            reportParams && dispatch(runAction('saveReportParams', { ...reportParams, lang }));
            dispatch(
                runAction('saveTablesDataToStore', {
                    data,
                    lang,
                    currentSubSection,
                })
            );
        },
        hideTables: () => {
            dispatch(runAction('onShowTablesButtonClick', { isShown: false }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTablesPanel);
