import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

export default React.memo((props) => {
    const [log, set_log] = useState('');
    const onClickCloseButton = () => {
        props.closeLogOverlay();
    };

    const onClickReloadDiagram = () => {
        props.onClickReloadDiagram(props.dataView, null, props.inputParamsValues);
    };

    useEffect(() => {
        set_log(() => props.log);
    }, [props.log]);

    return (
        <Container onClick={(e) => e.stopPropagation()}>
            <Sticky>
                <i onClick={onClickReloadDiagram} className="fas fa-server" />
                <i onClick={onClickCloseButton} className="fa fa-times-circle" />
            </Sticky>
            <pre>{log}</pre>
        </Container>
    );
});

const Sticky = styled.div`
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    i {
        cursor: pointer;
        font-size: 30px;
        color: whitesmoke;
        margin-right: 10px;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0px;
    left: 238px;
    right: 0;
    bottom: 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    padding: 10px;
    -webkit-user-select: text;

    overflow: auto;
    /* user-select -- это нестандартное свойство */

    -moz-user-select: text;
    /* поэтому нужны префиксы */

    -ms-user-select: text;
    pre {
        width: 100%;
        height: fit-content;
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0;
        overflow: auto;
        color: #fff;
    }
`;
