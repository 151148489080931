import { useState, useEffect, useCallback } from 'react';

const Tools = {
    dictionaryIcons(type) {
        let icon = '';
        if (type === 'hotspot') {
            icon = 'fa-mobile';
        } else if (type === 'analytics') {
            icon = 'fa-flask';
        }
        return icon;
    },
};

export const useLongPress = (allowLongPress = true, callback = () => {}, ms = 300) => {
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() => {
        let timerId;
        if (startLongPress) {
            timerId = setTimeout(callback, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [startLongPress]);

    const start = useCallback(() => {
        setStartLongPress(true);
    }, []);
    const stop = useCallback(() => {
        setStartLongPress(false);
    }, []);

    if (!allowLongPress) return {};
    return {
        onMouseDown: start,
        onMouseUp: stop,
        onMouseLeave: stop,
        onTouchStart: start,
        onTouchEnd: stop,
    };
};

export default Tools;
