import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: fixed;
  overflow: auto;
  left: 238px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const RightBackground = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  left: 482px;
`;

const LeftBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 238px;
`;

const Overlay = ({ children, ...props }) => {

  const handleOverlayClick = id => e => {
    return props.handleOverlayClick(id);
  };
  
  return (
    <Container>
      <RightBackground onClick={handleOverlayClick(undefined)} />
      {props.left && <LeftBackground onClick={handleOverlayClick(undefined)} />}
      {children}
    </Container>
  );
};

export default Overlay;
