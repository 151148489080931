
import MapComponent from './components/mapComponent';
import '../style/main.css';
import '../style/previewMap.css';
import Tabs from './components/tabs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { runAction } from '../../../actions';
import Tools from '../../../tools/tools';
import GeometryVSDataManager from '../../../tools/GeometryVSDataManager';
import hostForLocation from '../../../hostnames/hostname';
var moment = require('moment');
var jsonState = 0;

let props = null;
let prop2 = null;
let start = null;
let end = null;
let detail2 = null;
let uuidPlanSet = null;

class App extends Component {
    static propTypes = {};

    state = {
        show: true,
        showMaps: this.props.statusAnalitics === 'maps',
        showRun: this.props.statusAnalitics === 'route',
        childBox: {},
    };

    internalState = {
        isMounted: false,
    };

    UNSAFE_componentWillMount() {
        this.updateMaps(this.props, this.props.setMaps, this.props.update, this.props.token);
        this.props.getVersionData(this.props.uuidPlanSet, this.props.token);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            showMaps: nextProps.statusAnalitics === 'maps',
            showRun: nextProps.statusAnalitics === 'route',
        });

        let detail = {
            selected: nextProps.selected,
            pl_id: nextProps.pl_id,
        };
        // for (var key in nextProps.objLocationSelect) {
        //     for (var i = 0; i < nextProps.locations.length; i++) {
        //         if (nextProps.objLocationSelect[key].id === nextProps.locations[i].id) {
        //             detail['locations'] = nextProps.locations[i].detail
        //         }
        //     }
        // }

        if (nextProps.uuidPlanSet !== null) {
            if (nextProps.mapsObjectsByPlId[nextProps.pl_id] !== undefined) {
                if (Tools.compare2(detail2, detail) === false || Tools.compare2(nextProps.dataPlanSet, this.props.dataPlanSet) === false) {
                    detail2 = detail;
                    nextProps.setRooms(nextProps.mapsObjectsByPlId[nextProps.pl_id].servicepoints);
                    nextProps.setFloors(nextProps.mapsObjectsByPlId[nextProps.pl_id].floors);
                    nextProps.setZones(nextProps.mapsObjectsByPlId[nextProps.pl_id].zones, nextProps);
                }
                if (nextProps.uuidPlanSet !== uuidPlanSet) {
                    uuidPlanSet = Tools.copy(nextProps.uuidPlanSet);
                    nextProps.createSendPlanSet(nextProps.uuidPlanSet, nextProps.token);
                }
            }
        }

        if (
            Tools.compare2(props, nextProps.pl_id) === false ||
            Tools.compare2(start, moment(nextProps.selected.start).format('YYYY-MM-DD')) === false ||
            Tools.compare2(end, moment(nextProps.selected.end).format('YYYY-MM-DD')) === false ||
            Tools.compare2(this.props.mapsObjectsByPlId, nextProps.mapsObjectsByPlId) === false
        ) {
            this.updateMaps(nextProps, nextProps.setMaps, nextProps.update, nextProps.token);
        }
        if (this.props.uuidPlanSet !== nextProps.uuidPlanSet || this.props.token !== nextProps.token) {
            this.props.getVersionData(nextProps.uuidPlanSet, nextProps.token);
        }
        // console.log('!@@@@@@@@@@@', nextProps.uuidPlanSet, nextProps.selectedUuid, this.props.selectedUuid);
        if (this.props.selectedUuid !== nextProps.selectedUuid && nextProps.uuidPlanSet !== null) {
            // console.log('!@@@@@@@@@@@', nextProps.uuidPlanSet, nextProps.token, nextProps.selectedUuid);
            this.props.getDataByUuid(nextProps.uuidPlanSet, nextProps.token, nextProps.selectedUuid);
        }
    }

    updateMaps(data, func, funcUpdate, token) {
        if (data.statusTopPanel === 'indoor_maps') {
            props = data.pl_id;
            prop2 = data.locations;
            start = moment(data.selected.start).format('YYYY-MM-DD');
            end = moment(data.selected.end).format('YYYY-MM-DD');

            if (data.pl_id !== null) {
                let urlSetMaps = `${hostForLocation.getHostName().api}/v3/floor?mall_id=${data.pl_id}`;

                funcUpdate(data.pl_id, token);

                this.props.getSelectZone({ indexZone: null });
                func(urlSetMaps, data.token);

                // this.props.getStatusLoadTransition();
                // this.props.setTransition(data.obj_id,data,token)
            }
        }
    }

    render() {
        var component;
        var box = {
            test: this.props.test,
            status: this.props.status,
            floor: this.props.floor,
            mapLink: this.props.mapLink,
            statusLoad: this.props.statusLoad,
        };

        // console.log('mainmain', this.props.statusLoad)

        if (document.getElementById('extend-region') !== null) {
            var widthbox = document.getElementById('extend-region').clientWidth;
            var heightbox =
                (window.innerHeight
                    ? window.innerHeight
                    : document.documentElement.clientHeight
                    ? document.documentElement.clientHeight
                    : window.screen.height) - 150;
        }
        if (this.state.show === true) {
            if (this.state.showMaps === true) {
                component = (
                    <div className="main-region">
                        {/*<Tabs _t={this.props._t}/>*/}
                        <MapComponent
                            getMode="PLACES"
                            _t={this.props._t}
                            image={`${hostForLocation.getHostName().maps}` + this.props.image}
                            panelAnalitics={true}
                            prop={box}
                            width={widthbox}
                            height={heightbox}
                            getFloorId={this.props.getFloorId}
                            lang={this.props.lang}
                        />
                    </div>
                );
            } else if (this.state.showRun === true) {
                component = (
                    <div className="main-region">
                        <Tabs _t={this.props._t} />
                        {/* <Route getFloorId={this.props.getFloorId} getMode="PLACES" image={`${hostForLocation.getHostName().maps}` + this.props.image} prop={box} /> */}
                    </div>
                );
            }
        }

        return (
            <div className="extend-region" id="extend-region">
                {component}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // console.log('><><><><><><><><><><>', state)
    return {
        statusAnalitics: state.MapsApp.statusAnalitics,
        image: state.MapsApp.image,
        test: state.MapsApp.test,
        status: state.MapsApp.status,
        floor: state.MapsApp.mallFloors,
        mapLink: state.MapsApp.mapLink,
        statusLoad: state.MapsApp.loadStatusStatisitcs,
        selected: {
            start: state.ReportParametersManager.customInputParamsValues.main_date_range[0],
            end: state.ReportParametersManager.customInputParamsValues.main_date_range[1],
        },
        dataPlanSet: state.MapsApp.dataPlanSet,
        token: state.LoginApp.token,
        selected2: {
            start: state.ReportParametersManager.customInputParamsValues.comparison_date_range[0],
            end: state.ReportParametersManager.customInputParamsValues.comparison_date_range[1],
        },
        locations: state.ReportParametersManager.locations,
        objLocationSelect: state.ReportParametersManager.objLocationSelect,
        pl_id: state.ReportParametersManager.customInputParamsValues.pl_id,
        obj_id: state.ReportParametersManager.customInputParamsValues.obj_id,
        uuidPlanSet: state.MapsApp.uuidPlanSet,
        statusTopPanel: `${state.TopPanel.statusLeftPanel}${state.TopPanel.cabinetMode}`,
        mapsObjectsByPlId: state.ReportParametersManager.Source.ctg.mapsObjectsByPlId,
        uuidDataByUuid: state.ReportParametersManager.mapsData.uuidDataByUuid,
        selectedUuid: state.ReportParametersManager.mapsData.selectedUuid,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFloorId: (id) => {
            dispatch(runAction('getFloorId', { id: id }));
        },
        setFloors: (data) => {
            dispatch(runAction('setFloors', { data: GeometryVSDataManager.setFloors(data) }));
        },
        setMaps: (url, token) => {
            var headers = {
                'x-token': token,
            };

            // dispatch(runAction('setMaps', { url: url, headers: headers }));
        },
        update: (id, token) => {
            dispatch(runAction('getUpdate', { id: id, token: token }));
        },
        setZones: (data1, props) => {
            dispatch(runAction('setZones', { data: GeometryVSDataManager.setZones(data1) }));

            // let data = props.dataPlanSet;
            // var arr = [];
            // var arrZones = [];
            // var idObjOfZones = {};
            // if (data.plans !== undefined) {
            //     for(var i = 0; i < data.plans.length; i++){
            //         for(var j = 0; j < data.plans[i].places.length; j++){
            //             if (GeometryVSDataManager.reportObjWithPlace(data.plans[i].places[j].name) !== undefined) {
            //                 arr.push(GeometryVSDataManager.reportObjWithPlace(data.plans[i].places[j].name))
            //             }
            //         }
            //         for(var h = 0; h < data.plans[i].zone_groups.length; h++){
            //             for (var g = 0; g < data.plans[i].zone_groups[h].zones.length; g++) {
            //                 if (GeometryVSDataManager.reportObjWithZone(data.plans[i].zone_groups[h].zones[g].zone_marker) !== undefined) {
            //                     idObjOfZones[data.plans[i].zone_groups[h].zones[g].zone_marker] = GeometryVSDataManager.reportObjWithZone(data.plans[i].zone_groups[h].zones[g].zone_marker);
            //                 }
            //             }
            //         }
            //     }
            //     for (var key in idObjOfZones) {
            //         arrZones.push(idObjOfZones[key]);
            //     }
            //     var obj = {
            //         secondString:arr.join(','),
            //     }
            //     var objZones = {
            //         secondString:arrZones.join(','),
            //     }
            //
            //     if (arr.length > 0) {
            //         let url = `${hostForLocation.getHostName().api}/v3/reports/unique_clients_agg?from=${moment(props.selected.start).format('YYYY-MM-DD')}&to=${moment(props.selected.end).format('YYYY-MM-DD')}&objects=${obj.secondString}&client_type=visitor`;
            //         var headers = {
            //             "x-token":props.token
            //         };
            //         dispatch(runAction('getUnekUsers', {url:url, headers:headers}));
            //
            //     }
            //     if (arrZones.length > 0) {
            //         let url = `${hostForLocation.getHostName().api}/v3/reports/unique_clients_agg?from=${moment(props.selected.start).format('YYYY-MM-DD')}&to=${moment(props.selected.end).format('YYYY-MM-DD')}&objects=${objZones.secondString}&client_type=visitor`;
            //         var headers = {
            //             "x-token":props.token
            //         };
            //         dispatch(runAction('getUnekUsersOfZones', {url:url, headers:headers}));
            //
            //     }
            // }
        },
        setRooms: (data) => {
            dispatch(runAction('setRooms', { data: GeometryVSDataManager.setRooms(data) }));
        },
        createSendPlanSet: (planSetUuid, token) => {
            let url = `${hostForLocation.getHostName().maps}/geometry/plan_set_info/${planSetUuid}/`;
            var headers = {
                'x-token': token,
            };
            dispatch(runAction('createSendPlanSet', { url: url, headers: headers }));
        },
        getSelectZone: (param) => {
            dispatch(runAction('getSelectZone', param));
        },
        getStatusLoadTransition: () => {
            dispatch(runAction('getStatusLoadTransition'));
        },
        setTransition: (objId, data, token) => {
            if (data.mapsObjectsByPlId[data.pl_id] !== undefined) {
                var url = `${hostForLocation.getHostName().api}/v3/mall/${
                    data.mapsObjectsByPlId[data.pl_id].main_servicepoint.id
                }/inner_intersections?from=${moment(data.selected.start).format('YYYY-MM-DD')}&to=${moment(data.selected.end).format(
                    'YYYY-MM-DD'
                )}`;
                var headers = {
                    'x-token': token,
                };

                if (
                    data.mapsObjectsByPlId[data.pl_id].main_servicepoint.id !== null &&
                    data.mapsObjectsByPlId[data.pl_id].main_servicepoint.id !== undefined
                ) {
                    dispatch(runAction('setTransition', { url: url, headers: headers }));
                }
            }
        },
        getVersionData: (uuid, token) => {
            // console.log('setVersionData_source', uuid)
            dispatch(runAction('clearVersionData_ReportParametersManager', {}));
            if (uuid !== null) {
                var url = `${hostForLocation.getHostName().maps}/geometry/plan_set/${uuid}/version/`;
                var headers = {
                    'x-token': token,
                };
                dispatch(runAction('getVersionData_Fetch', { url: url, headers: headers }));
            }
        },
        getDataByUuid: (uuid, token, uuidSet) => {
            // console.log('1111111/', uuid)
            if (uuid !== null && uuidSet !== null) {
                var url = `${hostForLocation.getHostName().maps}/geometry/plan_set/${uuid}/version/${uuidSet}/`;
                var headers = {
                    'x-token': token,
                };
                // console.log('1111111', url)
                dispatch(runAction('getDataByUuid_Fetch', { url: url, headers: headers }));
            }
        },
    };
};

const ConnectApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectApp;
