import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Search from './search';
import Select from './select';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import SelectDeselectButtons from '../../leftPanel/pl_panel/components/selectDeselectButtons';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';

import _t from 'i18next';

const OutdoorControlPanel = React.memo(
    ({
        filteredAdSpaces,
        selectedSpaces,
        staticAdCampSelectedSpaces,
        filteredTypes,
        selectedAdSpaceTypes,
        searchValue,
        handleTargetClick,
        nonZeroPolygons,
        filterByPolygon,
        toggleFilterByPolygon,
        region_id,
        mode,
        ...props
    }) => {
        const { t } = useTranslation();
        const onItemTitleClick = (id) => (e) => {
            props.onSectorClick(id, mode);
        };

        const closeMap = () => {
            props.closeMap();
        };

        const search = useMemo(() => {
            return <Search searchValue={searchValue} handleSearch={props.handleSearch} />;
        }, [searchValue, filterByPolygon]);

        const innerSVG = (
            <svg height="20" viewBox="0 0 50 50" width="20" xmlns="http://www.w3.org/2000/svg">
                <path d="m23 0v4.0957031c-9.981298.9489961-17.953104 8.9227909-18.9023438 18.9042969h-4.0976562v4h4.0976562c.9492398 9.981506 8.9210458 17.955301 18.9023438 18.904297v4.095703h4v-4.097656c9.981223-.949213 17.953131-8.921121 18.902344-18.902344h4.097656v-4h-4.097656c-.949213-9.981223-8.921121-17.953131-18.902344-18.9023438v-4.0976562zm4 8.1269531c7.805997.9099644 13.963083 7.0670499 14.873047 14.8730469h-2.873047v4h2.873047c-.909964 7.805997-7.06705 13.963083-14.873047 14.873047v-2.873047h-4v2.871094c-7.803628-.911192-13.9631575-7.06574-14.8730469-14.871094h2.8730469v-4h-2.8730469c.9098894-7.805354 7.0694189-13.9599017 14.8730469-14.8710938v2.8710938h4zm-2 9.8730469a7 7 0 0 0 -7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0 -7-7z" />
            </svg>
        );

        const onSelectAllClick = (filteredAdSpaces) => (e) => {
            props.onSelectAllClick(filteredAdSpaces);
        };

        const list = useMemo(() => {
            return (
                <List label="Outdoor Spaces" isOpen={true} show={true}>
                    {filteredAdSpaces &&
                        filteredAdSpaces.map((item) => {
                            let isSelected = selectedSpaces.indexOf(item.id) !== -1;
                            if (mode === 'staticAdCamp') {
                                isSelected = staticAdCampSelectedSpaces.indexOf(item.id) !== -1;
                            }
                            return (
                                <Item selected={isSelected} key={`Outdoor Spaces${item.id}`}>
                                    <ItemTitle selected={isSelected} onClick={onItemTitleClick(item.id)}>
                                        {`${item.project_location_name}, ${item.name}`}
                                    </ItemTitle>
                                    <SVG onClick={handleTargetClick(item)}>{innerSVG}</SVG>
                                </Item>
                            );
                        })}
                </List>
            );
        }, [filteredAdSpaces, selectedSpaces, staticAdCampSelectedSpaces, filterByPolygon]);

        const onFilterByPolygonChange = (ev, checked) => {
            toggleFilterByPolygon(!!checked);
        };

        return (
            <Container>
                {mode === 'staticAdCamp' ? <StyledPrimaryButton text={t('Подтвердить выбор')} width={'100px'} onClick={closeMap} /> : null}
                {nonZeroPolygons && region_id ? (
                    <Select
                        title={_t.t('Регион')}
                        list={nonZeroPolygons}
                        changeSelected={props.changeSelectedPolygon}
                        selectedValues={[region_id]}
                    />
                ) : null}
                <div>
                    <Checkbox label={_t.t('Фильтровать по полигону')} checked={filterByPolygon} onChange={onFilterByPolygonChange} />
                </div>
                <Select
                    title={_t.t('Типы поверхностей')}
                    list={filteredTypes}
                    multi={true}
                    changeSelected={props.changeSelectedTypes}
                    selectedValues={selectedAdSpaceTypes}
                />
                {search}
                <div>
                    <SelectDeselectButtons
                        _t={_t}
                        onSelectAllClick={onSelectAllClick(filteredAdSpaces)}
                        onClearAllClick={props.onDeselectAllClick}
                    />
                </div>
                {list}
            </Container>
        );
    }
);

export default OutdoorControlPanel;

const Container = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 243px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 198;
    flex-shrink: 0;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-bottom: 10px;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Item = styled.li`
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    font-family: ProximaNova;
    padding: 1px 0;
    box-sizing: border-box;
`;

const ItemTitle = styled.span`
    border-left: ${(props) => (props.selected ? '5px solid #328856' : '5px solid transparent')};
    list-style: none;
    margin: 4px 0;
    padding: 0;
    padding-left: 10px;
    color: ${(props) => (props.selected ? '#000' : '#aaa')};
    cursor: pointer;
    :hover {
        border-left: ${(props) => (props.selected ? '5px solid #328856' : '5px solid #ccc')};
        color: #000;
    }
`;

const SVG = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    opacity: 0.3;
    margin-left: 5px;
    :hover {
        opacity: 0.9;
    }
    transform: opacity 0.5s;
`;
