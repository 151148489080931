const toolsForDate = {
    mainIsToday: (moment, mainDateRange) => {
        return (
            moment().format('YYYY-MM-DD') === moment(mainDateRange[0]).format('YYYY-MM-DD') &&
            moment().format('YYYY-MM-DD') === moment(mainDateRange[1]).format('YYYY-MM-DD')
        );
    },
    mainIsYesterday: (moment, mainDateRange) => {
        return (
            moment(moment().add(-1, 'days')).format('YYYY-MM-DD') === moment(mainDateRange[0]).format('YYYY-MM-DD') &&
            moment(moment().add(-1, 'days')).format('YYYY-MM-DD') === moment(mainDateRange[1]).format('YYYY-MM-DD')
        );
    },
    mainIsLast7Days: (moment, mainDateRange) => {
        return (
            moment(moment().subtract(7, 'd').toDate()).format('YYYY-MM-DD') === mainDateRange[0] &&
            moment(moment().subtract(1, 'd').toDate()).format('YYYY-MM-DD') === mainDateRange[1]
        );
    },
    mainIsLast30Days: (moment, mainDateRange) => {
        return (
            moment(moment().subtract(30, 'd').toDate()).format('YYYY-MM-DD') === mainDateRange[0] &&
            moment(moment().subtract(1, 'd').toDate()).format('YYYY-MM-DD') === mainDateRange[1]
        );
    },
    mainIsCurrentMonth: (moment, mainDateRange) => {
        const dateFirst = moment(moment(`${moment().month() + 1}-01-${moment().year()}`, 'MM-DD-YYYY').toDate()).format('YYYY-MM-DD');
        const dateLast = moment(
            moment(`${moment().month() + 1}-${moment().daysInMonth()}-${moment().year()}`, 'MM-DD-YYYY').toDate()
        ).format('YYYY-MM-DD');
        return dateFirst === mainDateRange[0] && dateLast === mainDateRange[1];
    },
    mainIsLastMonth: (moment, mainDateRange) => {
        const dateFirst = new moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        const dateLast = new moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        return dateFirst === mainDateRange[0] && dateLast === mainDateRange[1];
    },
    getStatusMainDateRange: (moment, mainDateRange) => {
        toolsForDate.mainIsLast7Days(moment, mainDateRange);
        if (toolsForDate.mainIsToday(moment, mainDateRange)) {
            return 'Today';
        } else if (toolsForDate.mainIsYesterday(moment, mainDateRange)) {
            return 'Yesterday';
        } else if (toolsForDate.mainIsLast7Days(moment, mainDateRange)) {
            return 'Last7Days';
        } else if (toolsForDate.mainIsLast30Days(moment, mainDateRange)) {
            return 'Last30Days';
        } else if (toolsForDate.mainIsCurrentMonth(moment, mainDateRange)) {
            return 'CurrentMonth';
        } else if (toolsForDate.mainIsLastMonth(moment, mainDateRange)) {
            return 'LastMonth';
        } else {
            return 'CustomPeriod';
        }
    },
    getDateForToday: (moment) => {
        return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    },
    getDateForYesterday: (moment) => {
        return [moment(moment().add(-1, 'days')).format('YYYY-MM-DD'), moment(moment().add(-1, 'days')).format('YYYY-MM-DD')];
    },
    getDateForLast7Days: (moment) => {
        return [
            moment(moment().subtract(7, 'd').toDate()).format('YYYY-MM-DD'),
            moment(moment().subtract(1, 'd').toDate()).format('YYYY-MM-DD'),
        ];
    },
    getDateForLast30Days: (moment) => {
        return [
            moment(moment().subtract(30, 'd').toDate()).format('YYYY-MM-DD'),
            moment(moment().subtract(1, 'd').toDate()).format('YYYY-MM-DD'),
        ];
    },
    getDateForCurrentMonth: (moment) => {
        return [
            moment(moment(`${moment().month() + 1}-01-${moment().year()}`, 'MM-DD-YYYY').toDate()).format('YYYY-MM-DD'),
            moment(moment(`${moment().month() + 1}-${moment().daysInMonth()}-${moment().year()}`, 'MM-DD-YYYY').toDate()).format(
                'YYYY-MM-DD'
            ),
        ];
    },
    getDateForLastMonth: (moment) => {
        const prevMonthFirstDay = new moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        const prevMonthLastDay = new moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        return [prevMonthFirstDay, prevMonthLastDay];
        // return [
        //     moment(moment(`${moment().month()}-01-${moment().year()}`, 'MM-DD-YYYY').toDate()).format('YYYY-MM-DD'),
        //     moment(
        //         moment(
        //             `${moment().month()}-${moment(
        //                 `${moment().month()}-01-${moment().year()}`,
        //                 'MM-DD-YYYY'
        //             ).daysInMonth()}-${moment().year()}`,
        //             'MM-DD-YYYY'
        //         ).toDate()
        //     ).format('YYYY-MM-DD'),
        // ];
    },
    getDateForCustomPeriod: (moment) => {
        return [
            moment(moment().subtract(14, 'd').toDate()).format('YYYY-MM-DD'),
            moment(moment().subtract(6, 'd').toDate()).format('YYYY-MM-DD'),
        ];
    },
    getDateByType: (moment, type) => {
        if (type === 'Today') {
            return toolsForDate.getDateForToday(moment);
        } else if (type === 'Yesterday') {
            return toolsForDate.getDateForYesterday(moment);
        } else if (type === 'Last7Days') {
            return toolsForDate.getDateForLast7Days(moment);
        } else if (type === 'Last30Days') {
            return toolsForDate.getDateForLast30Days(moment);
        } else if (type === 'CurrentMonth') {
            return toolsForDate.getDateForCurrentMonth(moment);
        } else if (type === 'LastMonth') {
            return toolsForDate.getDateForLastMonth(moment);
        } else {
            return toolsForDate.getDateForCustomPeriod(moment);
        }
    },
    getDateCompareRamgeFor_theSamePeriodUpTo: (sumDayMain, moment, firstDateMain, lastDateMain) => {
        return [
            moment(moment(firstDateMain).add(-Math.abs(sumDayMain), 'days')).format('YYYY-MM-DD'),
            moment(moment(firstDateMain).add(-1, 'days')).format('YYYY-MM-DD'),
        ];
    },
    getDateCompareRamgeFor_theSamePeriodLastWeek: (sumDayMain, moment, firstDateMain, lastDateMain) => {
        const a = moment(firstDateMain);
        const b = moment(lastDateMain);

        return [
            moment(moment(firstDateMain).subtract(7, 'd')).format('YYYY-MM-DD'),
            moment(moment(lastDateMain).subtract(7, 'd')).format('YYYY-MM-DD'),
        ];
    },
    getDateCompareRamgeFor_theSamePeriodLastMonth: (sumDay, moment, firstDate, lastDate, statusForMainDateRange) => {
        const a = moment(firstDate);
        const b = moment(lastDate);
        const diff = b.diff(a, 'days') + 1;
        if (
            statusForMainDateRange === 'CurrentMonth' ||
            statusForMainDateRange === 'LastMonth' ||
            isWholeMonthSelected(moment, firstDate, lastDate)
        ) {
            return [
                moment(moment(firstDate).subtract(1, 'months')).format('YYYY-MM-DD'),
                moment(moment(lastDate).subtract(1, 'months').endOf('month')).format('YYYY-MM-DD'),
            ];
        } else {
            return [
                moment(moment(firstDate).subtract(1, 'months')).format('YYYY-MM-DD'),
                moment(moment(lastDate).subtract(1, 'months')).format('YYYY-MM-DD'),
            ];
        }
    },
    getDateCompareRamgeFor_theSamePeriodLastYear: (sumDay, moment, firstDate, lastDate, statusForMainDateRange) => {
        const a = moment(firstDate);
        const b = moment(lastDate);
        const diff = b.diff(a, 'days') + 1;
        if (
            statusForMainDateRange === 'CurrentMonth' ||
            statusForMainDateRange === 'LastMonth' ||
            isWholeMonthSelected(moment, firstDate, lastDate)
        ) {
            return [
                moment(moment(firstDate).subtract(1, 'years')).format('YYYY-MM-DD'),
                moment(moment(lastDate).subtract(1, 'years').endOf('month')).format('YYYY-MM-DD'),
            ];
        } else {
            return [
                moment(moment(firstDate).subtract(1, 'years')).format('YYYY-MM-DD'),
                moment(moment(lastDate).subtract(1, 'years')).format('YYYY-MM-DD'),
            ];
        }
    },
    getDateCompareRamgeFor_CustomPeriod: (sumDayMain, moment, firstDateMain, lastDateMain) => {
        const a = moment(firstDateMain);
        const b = moment(firstDateMain);
        // console.log('>>>>>>>>>',moment(moment(firstDateMain).add(-6, 'day')).format('YYYY-MM-DD'),moment(moment(firstDateMain).add(-7, 'day')).format('YYYY-MM-DD'))
        return [
            moment(moment(firstDateMain).add(-7, 'day')).format('YYYY-MM-DD'),
            moment(moment(firstDateMain).add(-6, 'day')).format('YYYY-MM-DD'),
        ];
    },
    getComparisonRange: (moment, mainDateRange, compareDepType, statusForMainDateRange) => {
        let firstDate = mainDateRange.startDate;
        let lastDate = mainDateRange.endDate;

        let sumDay = lastDate.diff(firstDate, 'days') + 1;

        if (compareDepType === 'theSamePeriodUpTo') {
            return toolsForDate.getDateCompareRamgeFor_theSamePeriodUpTo(sumDay, moment, firstDate, lastDate);
        } else if (compareDepType === 'theSamePeriodLastWeek') {
            // console.log('>>>>>>>>', sumDay)
            if (sumDay <= 7) {
                return toolsForDate.getDateCompareRamgeFor_theSamePeriodLastWeek(sumDay, moment, firstDate, lastDate);
            }
            // console.log('>>>>>>>>>222',toolsForDate.getDateCompareRamgeFor_CustomPeriod(sumDay, moment, firstDate, lastDate))
            return toolsForDate.getDateCompareRamgeFor_theSamePeriodUpTo(sumDay, moment, firstDate, lastDate);
        } else if (compareDepType === 'theSamePeriodLastMonth') {
            return toolsForDate.getDateCompareRamgeFor_theSamePeriodLastMonth(sumDay, moment, firstDate, lastDate, statusForMainDateRange);
        } else if (compareDepType === 'theSamePeriodLastYear') {
            return toolsForDate.getDateCompareRamgeFor_theSamePeriodLastYear(sumDay, moment, firstDate, lastDate, statusForMainDateRange);
        } else if (compareDepType === 'CustomPeriod') {
            // console.log('>>>>>>>>>333',toolsForDate.getDateCompareRamgeFor_CustomPeriod(sumDay, moment, firstDate, lastDate))
            return toolsForDate.getDateCompareRamgeFor_CustomPeriod(sumDay, moment, firstDate, lastDate);
        }
    },
    compareIs_theSamePeriodUpTo: (sumDayMain, moment, firstDateMain, lastDateMain, compareDateRange) => {
        return (
            moment(moment(firstDateMain).add(-Math.abs(sumDayMain), 'days')).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(firstDateMain).add(-1, 'days')).format('YYYY-MM-DD') === compareDateRange[1]
        );
    },
    compareIs_theSamePeriodLastWeek: (sumDayMain, moment, firstDateMain, lastDateMain, compareDateRange) => {
        const a = moment(firstDateMain);
        const b = moment(lastDateMain);

        // console.log('>>>>>>>>>333',
        //     moment(moment(firstDateMain).subtract(7, 'd')).format('YYYY-MM-DD'),
        //     compareDateRange[0],
        //     moment(moment(lastDateMain).subtract(7, 'd')).format('YYYY-MM-DD'),
        //     compareDateRange[1]
        //     )

        return (
            moment(moment(firstDateMain).subtract(7, 'd')).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(lastDateMain).subtract(7, 'd')).format('YYYY-MM-DD') === compareDateRange[1]
        );
    },
    compareIs_theSamePeriodLastMonth: (sumDay, moment, firstDate, lastDate, statusForMainDateRange, compareDateRange) => {
        const a = moment(firstDate);
        const b = moment(lastDate);
        const diff = b.diff(a, 'days') + 1;

        let statusFirst =
            moment(
                moment(firstDate)
                    .subtract(1, 'months')
                    .subtract(diff + 1, 'days')
                    .toDate()
            ).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(lastDate).subtract(1, 'months').toDate()).format('YYYY-MM-DD') === compareDateRange[1];

        let statusSecond =
            moment(moment(firstDate).subtract(1, 'months')).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(lastDate).subtract(1, 'months')).format('YYYY-MM-DD') === compareDateRange[1];
        return statusFirst || statusSecond;
    },
    compareIs_theSamePeriodLastYear: (sumDay, moment, firstDate, lastDate, statusForMainDateRange, compareDateRange) => {
        const a = moment(firstDate);
        const b = moment(lastDate);
        const diff = b.diff(a, 'days') + 1;

        let statusFirst =
            moment(moment(firstDate).subtract(1, 'years')).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(lastDate).subtract(1, 'years').endOf('month')).format('YYYY-MM-DD') === compareDateRange[1];

        let statusSecond =
            moment(moment(firstDate).subtract(1, 'years')).format('YYYY-MM-DD') === compareDateRange[0] &&
            moment(moment(lastDate).subtract(1, 'years')).format('YYYY-MM-DD') === compareDateRange[1];

        return statusFirst || statusSecond;
    },
    getStatusComapreDateRange: (moment, compareDateRange, mainDateRange, statusForMainDateRange) => {
        let firstDate = moment(mainDateRange[0]);
        let lastDate = moment(mainDateRange[1]);

        let sumDay = lastDate.diff(firstDate, 'days') + 1;

        if (toolsForDate.compareIs_theSamePeriodUpTo(sumDay, moment, firstDate, lastDate, compareDateRange)) {
            return 'theSamePeriodUpTo';
        } else if (toolsForDate.compareIs_theSamePeriodLastWeek(sumDay, moment, firstDate, lastDate, compareDateRange)) {
            return 'theSamePeriodLastWeek';
        } else if (
            toolsForDate.compareIs_theSamePeriodLastMonth(sumDay, moment, firstDate, lastDate, statusForMainDateRange, compareDateRange)
        ) {
            return 'theSamePeriodLastMonth';
        } else if (
            toolsForDate.compareIs_theSamePeriodLastYear(sumDay, moment, firstDate, lastDate, statusForMainDateRange, compareDateRange)
        ) {
            return 'theSamePeriodLastYear';
        } else {
            return 'CustomPeriod';
        }
    },
};

const isWholeMonthSelected = (moment, startDate, endDate) => {
    return (
        moment(startDate).format('YYYY-MM-DD') === moment(startDate).startOf('month').format('YYYY-MM-DD') &&
        moment(endDate).format('YYYY-MM-DD') === moment(endDate).endOf('month').format('YYYY-MM-DD')
    );
};

export default toolsForDate;
