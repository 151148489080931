import styled from 'styled-components/macro';
import _t from 'i18next';
import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';

const Container = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    height: ${props => props.height - 50 + 'px'};
    font-family: 'ProximaNova';
    font-size: 10px;
`;

const DecreaseAlert = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'ProximaNova';
    font-size: 18px;
    color: #ccc;
`;

export default class HeatMap extends React.PureComponent {
    prepareData = () => {
        const matrix = this.props.data.intersection_matrix;
        const keys = this.props.data.keys;
        return keys.map((item, i) => {
            return {
                country: item,
                ...keys.reduce((acc, item, j) => {
                    return { ...acc, ...{ [item]: matrix[i][j], [`${item}Color`]: 'red' } };
                }, {})
            };
        });
    };

    getTspanGroups = (value, maxLineLength = 50, maxLines = 2) => {
        const words = value.split(' ');

        //reduces the words into lines of maxLineLength
        const assembleLines = words.reduce(
            (acc, word) => {
                //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
                if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
                    return {
                        lines: acc.lines.concat([acc.currLine]),
                        currLine: word
                    };
                }
                //otherwise add the word to the current line
                return {
                    ...acc,
                    currLine: acc.currLine + ' ' + word
                };
            },
            { lines: [], currLine: '' }
        );

        //add the ending state of current line (the last line) to lines
        const allLines = assembleLines.lines.concat([assembleLines.currLine]);

        //for now, only take first 2 lines due to tick spacing and possible overflow
        const lines = allLines.slice(0, maxLines);
        let children = [];
        let dy = 0;

        lines.forEach((lineText, i) => {
            children.push(
                <tspan x={0} dy={dy} key={i}>
                    {// if on the second line, and that line's length is within 3 of the max length, add ellipsis
                    1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText}
                </tspan>
            );
            //increment dy to render next line text below
            dy += 15;
        });

        return children;
    };

    renderTick = ({ x, y, value, textBaseline, textAnchor, opacity, textX, textY }) => {
        const { keys } = this.props.data;
        return (
            <g key={value} transform={`translate(0,${y})`} style={{ opacity }}>
                <text style={this.theme.axis.ticks.indexText} transform={`translate(${textX + 10},${textY})`}>
                    {keys.indexOf(value) + 1}
                </text>
                <text
                    alignmentBaseline={textBaseline}
                    style={this.theme.axis.ticks.text}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                >
                    {this.getTspanGroups(value)}
                </text>
            </g>
        );
    };

    renderTickVertical = ({ x, y, value, textBaseline, textAnchor, opacity, textX, textY }) => {
        const { keys } = this.props.data;

        return (
            <g key={value} transform={`translate(${x},0)`} style={{ opacity }}>
                <text
                    alignmentBaseline={'central'}
                    // alignmentBaseline={textBaseline}
                    style={this.theme.axis.ticks.indexText}
                    textAnchor={'middle'}
                    // textAnchor={textAnchor}
                    transform={`translate(0,${textY})`}
                >
                    {keys.indexOf(value) + 1}
                </text>
            </g>
        );
    };

    heatMapColorforValue = value => {
        var h = (1.0 - value) * 240;
        return 'hsl(' + h + ', 100%, 50%)';
    };

    theme = {
        axis: {
            tickColor: '#eee',
            ticks: {
                text: {
                    textColor: '#eee',
                    fontSize: 12
                },
                indexText: {
                    textColor: '#ccc',
                    fontSize: 12,
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            stroke: '#888',
            strokeWidth: 1
        }
    };

    render() {
        const keys = this.props.data.keys;
        const { size } = this.props;

        const containerSize = {
            width: size.width
        };
        const MIN_BALL_DIAMETER = 30;
        const LEGEND_WIDTH = 300;
        const heatChartWidth = containerSize.width - LEGEND_WIDTH;
        const ballsAmount = this.props.data.keys.length;
        const ballDiameter = heatChartWidth / ballsAmount;
        containerSize.height = ballsAmount * ballDiameter;

        if (ballDiameter < MIN_BALL_DIAMETER) {
            return (
                <DecreaseAlert>
                    {_t.t('Пожалуйста уменьшите количество объектов')}
                    <br />
                    {_t.t('Табличные данные доступны')}
                </DecreaseAlert>
            );
        }

        return (
            <Container height={containerSize.height}>
                <ResponsiveHeatMap
                    theme={this.theme}
                    data={this.prepareData()}
                    keys={keys}
                    // colors={greenRedGradient}
                    colors={'oranges'}
                    indexBy="country"
                    margin={{
                        top: 30,
                        right: 20,
                        bottom: 20,
                        left: LEGEND_WIDTH + 50
                    }}
                    forceSquare={true}
                    cellShape={'circle'}
                    // cellShape={showAverages ? 'circle' : 'rect'}
                    axisTop={{
                        orient: 'top',
                        tickSize: 5,
                        tickPadding: 15,
                        tickRotation: -90,
                        renderTick: this.renderTickVertical
                    }}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 25,
                        tickRotation: 0,
                        renderTick: this.renderTick
                    }}
                    cellOpacity={1}
                    cellBorderColor="inherit:darker(0.4)"
                    labelTextColor="inherit:darker(1.8)"
                    labelTextSize={10}
                    defs={[
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(0, 0, 0, 0.1)',
                            rotation: -45,
                            lineWidth: 4,
                            spacing: 7
                        }
                    ]}
                    fill={[
                        {
                            id: 'lines'
                        }
                    ]}
                    animate={true}
                    motionStiffness={80}
                    motionDamping={9}
                    hoverTarget="cell"
                    cellHoverOthersOpacity={0.25}
                />
            </Container>
        );
    }
}
