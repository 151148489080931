import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types'
import '../../style/mapsHolst.scss';
import DrawerPlaces from './places';
import DrawerZones from './zones';
import styled from 'styled-components/macro';
import hostForLocation from "../../../../hostnames/hostname";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import DrawerPeriemetr from "./pereimetr";


let DivBoxMap = styled.div`
    ${props => `
    width: ${(props.width / props.scale) * props.scaleMapsGlobal * props.scaleCastom + 'px'};
    height: ${(props.height / props.scale) * props.scaleMapsGlobal * props.scaleCastom + 'px'};
    background: ${props.statusCheckedBackgroundImage ? `url(${props.image}) no-repeat` : null};
    background-position: center;
    background-size: 100%;
    `}
`;
const MapsHolst = (props) => {

    useEffect(() => {

        // console.log('>>>>>>>>>>>', props.plans);
        DrawerPlaces.initShapes();
        DrawerZones.initShapes();
        DrawerPeriemetr.initShapes();
    }, [props.plans]);



    useEffect(() => {
        if (document.getElementById(`componentItemPlans${props.floorIndexSelected}`) !== null) {
            // document.getElementById(`componentItemPlans${props.floorIndexSelected}`).scrollIntoView(true)
            // ScrollToResolver(document.getElementById(`componentItemPlans${props.floorIndexSelected}`), document.getElementById('holstBlock'));
            // document.getElementById(`componentItemPlans${props.floorIndexSelected}`).scrollTo(`componentItemPlans${props.floorIndexSelected}`, {
            //     duration: 1500,
            //     delay: 100,
            //     smooth: "easeInOutQuint",
            //     containerId: 'holstBlock'
            // }
            // );

            // document.getElementById(`componentItemPlans${props.floorIndexSelected}`).scrollTop = -500;
            document.getElementById(`componentItemPlans${props.floorIndexSelected}`).scrollIntoView(true);
        }


    }, [props.floorIndexSelected]);


    let Element = props.Element;

    let componentItemPlans = props.plans.map((item, index) => {

        let Drower;
        if (item.layers[props.typeDrower] === undefined) {
            return (
                <Element
                    name={`componentItemPlans${index}`}
                    className={'boxMap'}
                    key={`componentItemPlans${index}`}
                >
                    <DivBoxMap
                        className={'itemMaps'}
                        widthBox={props.widthBox}
                        scaleMapsGlobal={props.scaleMapsGlobal}
                        scale={item.scale}
                        width={item.width}
                        height={item.height}
                        scaleCastom={props.scaleCastom}
                        statusCheckedBackgroundImage={props.statusCheckedBackgroundImage}
                        image={`${hostForLocation.getHostName().maps}` + item.image}
                        id={`componentItemPlans${index}`}

                    >
                        {/*<svg height="100%" width="100%" onClick={hideAnalitics} >*/}
                        {/*    {*/}
                        {/*        DrawerPeriemetr.getSvgElements(props.scaleCastom, props.scaleMapsGlobal, item.scale).elem*/}
                        {/*    }*/}
                        {/*    {*/}
                        {/*        Drower.getSvgElements(props.scaleCastom, props.scaleMapsGlobal, item.scale).elem*/}
                        {/*    }*/}
                        {/*</svg>*/}
                    </DivBoxMap>
                </Element>
            );
        }

        if (props.typeDrower === 'place_layer') {
            // DrawerPlaces.UNSAFE_componentWillReceiveProps({
            //     data:item.layers[props.typeDrower],
            //     rooms: props.rooms,
            //     roomsTransition:props.roomsTransition,
            //     image:item.image,
            //     index:index,
            //     dataUneqUsers: props.dataUneqUsers,
            //     onSelectPolygons:props.onSelectPolygons,
            //     showCounts:props.showCounts,
            //     statusColorCount: props.statusColorCount,
            //     indexOfFloor:index
            // })
            Drower = DrawerPlaces;
        }
        else {
            // DrawerZones.UNSAFE_componentWillReceiveProps({
            //     data:item.layers[props.typeDrower],
            //     rooms: props.rooms,
            //     roomsTransition:props.roomsTransition,
            //     image:item.image,
            //     index:index,
            //     dataUneqUsers: props.dataUneqUsers,
            //     onSelectPolygons:props.onSelectPolygons,
            //     showCounts:props.showCounts,
            //     statusColorCount: props.statusColorCount,
            //     indexOfFloor:index
            // })
            Drower = DrawerZones;
        }
        // console.log('!!!!!!!!!', props.rooms);
        Drower.UNSAFE_componentWillReceiveProps({
            data:item.layers[props.typeDrower],
            rooms: props.rooms,
            roomsTransition: props.statusSelectPolygons ?  props.roomsTransition : {},
            image:item.image,
            index:index,
            dataUneqUsers: props.dataUneqUsers,
            onSelectPolygons:props.onSelectPolygons,
            showCounts:props.showCounts,
            statusColorCount: props.statusColorCount,
            indexOfFloor:index,
            FloorIndexSelectedHolst:props.FloorIndexSelectedHolst,
            DValueMetricsByMaps:props.DValueMetricsByMaps,
            objDataDataViewForMaps:props.objDataDataViewForMaps
        });
        DrawerPeriemetr.UNSAFE_componentWillReceiveProps({
            data:item.layers.appearance_layer,
            rooms: [],
            roomsTransition:[],
            image:'',
            index:0,
            dataUneqUsers: [],
            onSelectPolygons:[],
            showCounts:[],
            statusColorCount: [],
            indexOfFloor:0,
            FloorIndexSelectedHolst:props.FloorIndexSelectedHolst
        });

        // console.log('MapsHolst', DrawerPeriemetr.getSvgElements(props.scaleCastom, props.scaleMapsGlobal, item.scale).elem);

        const hideAnalitics = () => {
            props.onSelectPolygons(0, false);
        }
        return (
            <Element
                name={`componentItemPlans${index}`}
                className={'boxMap'}
                key={`componentItemPlans${index}`}
            >
                <DivBoxMap
                    className={'itemMaps'}
                    widthBox={props.widthBox}
                    scaleMapsGlobal={props.scaleMapsGlobal}
                    scale={item.scale}
                    width={item.width}
                    height={item.height}
                    scaleCastom={props.scaleCastom}
                    statusCheckedBackgroundImage={props.statusCheckedBackgroundImage}
                    image={`${hostForLocation.getHostName().maps}` + item.image}
                    id={`componentItemPlans${index}`}


                >
                    <svg height="100%" width="100%" onClick={hideAnalitics} >
                        {
                            DrawerPeriemetr.getSvgElements(props.scaleCastom, props.scaleMapsGlobal, item.scale).elem
                        }
                        {
                            Drower.getSvgElements(props.scaleCastom, props.scaleMapsGlobal, item.scale).elem
                        }
                    </svg>
                </DivBoxMap>
            </Element>
        );
    })
    return (
        <div className='boxMapsHolst'>
            <Element className='holstBlock' id={'holstBlock'}>
                {componentItemPlans}
            </Element>
        </div>
    );
}

// MapsHolst.propTypes = {

// }

export default MapsHolst;
