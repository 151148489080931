import React, { useMemo, useEffect, useState, useRef } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, IIconProps } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { runAction } from './actions';
import hostForLocation from './hostnames/hostname';

import Cookies from 'universal-cookie';
const cookie = new Cookies();
const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 800);

const cloudAPI = (token, url, method, data) => {
    const options = {
        method: method,
        headers: {
            'Content-type': 'application/json',
            'x-token': token,
            // 'Access-Control-Allow-Origin': '*'
        },
        mode: 'cors',
        credentials: 'include',
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    return fetch(url, options).then((response) => (response.ok ? response.json() : Promise.reject(response)));
};

export const ModalShareCloudLink = React.memo(({ ...props }) => {
    const { t } = useTranslation();
    const [clipboardText, setClipboardText] = useState('');
    const [error, setError] = useState(false);
    const [copied, setCopied] = useState(false);
    const textAreaRef = useRef(null);
    const dispatch = useDispatch();
    const shared_url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_shared_state/`;
    const token = useSelector((state) => state.LoginApp.token);
    const customInputParamsValues = useSelector((state) => state.ReportParametersManager.customInputParamsValues);
    const globalInputParamsValues = useSelector((state) => state.ReportParametersManager.globalInputParamsValues);


    const data = {
        cloud_state: {
            format_version: 1,
            data: {
                parameters: {
                    customInputParamsValues,
                    globalInputParamsValues,
                },
            },
        },
        meta: {},
    };

    useEffect(() => {
        cloudAPI(token, shared_url, 'POST', data)
            .then((response) => {
                return `${window.location.href}?shared_cloud_hash=${response.meta.cloud_hash}`;
            })
            .then((text) => {
                setClipboardText(text);
            })
            .catch((err) => {
                setError(true);
                console.log('ERROR ', err);
            });
    }, []);

    const copy1 = (text, textAreaRef) => (e) => {
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text);
                setCopied(true);
                props.onModalButtonClick(null);
            } else if (window.clipboardData) {
                // IE
                window.clipboardData.setData('text', text);
                setCopied(true);
                props.onModalButtonClick(null);
            } else {
                // other browsers, iOS, Mac OS
                copyToClipboard(textAreaRef.current);
                props.onModalButtonClick(null);
            }
        } catch (e) {}
    };

    const copyToClipboard = (el) => {
        const oldContentEditable = el.contentEditable;
        const oldReadOnly = el.readOnly;

        try {
            el.contentEditable = 'true'; // specific to iOS
            el.readOnly = false;
            copyNodeContentsToClipboard(el);
        } finally {
            el.contentEditable = oldContentEditable;
            el.readOnly = oldReadOnly;
        }
    };

    const copyNodeContentsToClipboard = (el) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(el);
        selection.removeAllRanges();

        selection.addRange(range);
        el.setSelectionRange(0, 999999);

        document.execCommand('copy');
        setCopied(true);
    };

    const copyNo = { iconName: 'ClipboardList' };
    const copyYes = { iconName: 'ClipboardSolid' };

    const onModalButtonClick = (action) => (e) => {
        props.onModalButtonClick({ name: action });
    };

    const stackTokens = { childrenGap: 10 };

    return !error ? (
        <Container>
            <textarea ref={textAreaRef} value={clipboardText} />
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <DefaultButton
                        toggle
                        // checked={copied || checked}
                        text={copied ? t('Скопировано') : t('Скопировать в буфер и закрыть')}
                        iconProps={copied ? copyYes : copyNo}
                        onClick={copy1(clipboardText, textAreaRef)}
                        allowDisabledFocus
                    />
                    <DefaultButton text={t('Закрыть')} width={'100px'} onClick={onModalButtonClick(null)} />
                </Stack>
            </Footer>
        </Container>
    ) : (
        <Container>
            <span>{t('Не удалось создать ссылку')}</span>
        </Container>
    );
});

const Cloud = React.memo(({ ...props }) => {
    const [allowPost, setAllowPost] = useState(false);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.LoginApp.token);
    const customInputParamsValues = useSelector((state) => state.ReportParametersManager.customInputParamsValues);
    const globalInputParamsValues = useSelector((state) => state.ReportParametersManager.globalInputParamsValues);
    const { permanentCloudData } = useSelector((state) => state.TopPanel);
    const url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_client_state/`;
    // const permanent_url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_client_permanent_state/`;
    const permanent_url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_client_permanent_state_new_format/`;
    const shared_url = `${hostForLocation.getHostName().analytics}/frontend_utils/cloud_shared_state/`;
    const cloud_hash = cookie.get('cloud_hash');
    const shared_cloud_hash = useSelector((state) => state.LoginApp.shared_cloud_hash);
    // const shared_cloud_hash = cookie.get('shared_cloud_hash');
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadAfter, setIsLoadAfter] = useState(false);

    const data = {
        cloud_state: {
            format_version: 1,
            data: {
                parameters: {
                    customInputParamsValues,
                    globalInputParamsValues,
                },
            },
        },
        meta: {},
    };

    const onFocus = () => {
        // console.log('Tab is in focus');
        initialPostRequest();
    };

    const onBlur = () => {
        // console.log('Tab is blurred');
    };

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    useEffect(() => {
        initialPermanentGETRequest();
    }, []);

    const initialPostRequest = () => {
        if (Object.keys(customInputParamsValues).length === 0 || Object.keys(globalInputParamsValues).length === 0 || !allowPost) return;

        if (cloud_hash) {
            cloudAPI(token, url, 'POST', { ...data, meta: { ...data.meta, cloud_hash } })
                .then((response) => {
                    // console.log('>>>>>', response);
                })
                .catch((err) => console.log('ERROR ', err));
        } else {
            cloudAPI(token, url, 'POST', data)
                .then((response) => {
                    cookie.set('cloud_hash', response.meta.cloud_hash, { path: '/', expires });
                })
                .catch((err) => console.log('ERROR ', err));
        }
    };

    useEffect(() => {
        initialPostRequest();
    }, [JSON.stringify({ ...customInputParamsValues, ...globalInputParamsValues })]);

    const initialPermanentGETRequest = () => {
        cloudAPI(token, permanent_url, 'GET')
            .then((response) => {
                dispatch(runAction('fillPermanentParamsFromCloud', { response }));
                setIsLoadAfter(false)
            })
            .catch((err) => console.log('initialPermanentRequest ERROR ', err));
    };

    useEffect(() => {

        if (permanentCloudData) {

            if (!isLoad) {
                setIsLoad(true)

                cloudAPI(token, permanent_url, 'POST', permanentCloudData)
                    .then((response) => {
                        console.log('>>><<><>>!!@', response);
                        // console.log('><>>>>><>>>22', response)
                        setIsLoad(false)
                        setIsLoadAfter(true)
                        // initialPermanentGETRequest()

                    })
                    .catch((err) => console.log('PermanentPOSTRequest ERROR ', err));
            }

        }
    }, [JSON.stringify(permanentCloudData?.custom_dataViews), JSON.stringify(permanentCloudData?.custom_section)]);

    useEffect(() => {
        if (permanentCloudData && permanentCloudData.custom_section) {
            dispatch(runAction('changeCustomSection', { custom_section: permanentCloudData.custom_section }));
        }
    }, [permanentCloudData && permanentCloudData.custom_section]);

    // useEffect(() => {
    //     if (permanentCloudData && permanentCloudData.custom_dataViews) {
    //         dispatch(runAction('changeReportList_RPM', { custom_dataViews: permanentCloudData.custom_dataViews }));
    //     }
    // }, [permanentCloudData && permanentCloudData.custom_dataViews]);

    useEffect(() => {
        if (shared_cloud_hash) {
            // console.log('WE HAVE shared_cloud_hash', shared_cloud_hash);
            cloudAPI(token, `${shared_url}?cloud_hash=${shared_cloud_hash}`, 'GET')
                .then((response) => {
                    dispatch(runAction('fillParamsFromCloud', { response }));
                })
                .catch((err) => {
                    dispatch(runAction('fillParamsFromCloud', { response: null }));
                    console.log('ERROR ', err);
                });

            dispatch(runAction('fillSharedCloudHash', { shared_cloud_hash: null }));

            // cookie.remove('shared_cloud_hash', { path: '/' });
        }
    }, [shared_cloud_hash]);

    useEffect(() => {
        if (cloud_hash && shared_cloud_hash === null) {
            cloudAPI(token, `${url}?cloud_hash=${cloud_hash}`, 'GET')
                .then((response) => {
                    dispatch(runAction('fillParamsFromCloud', { response }));
                })
                .then(() => {
                    setTimeout(() => {
                        setAllowPost(true);
                    }, 2000);
                })
                .catch((err) => {
                    console.log('ERROR RECEIVING CLOUD DATA ', err);
                    dispatch(runAction('fillParamsFromCloud', { response: null }));
                    setTimeout(() => {
                        setAllowPost(true);
                    }, 2000);
                });
        } else if (!cloud_hash) {
            setAllowPost(true);
            dispatch(runAction('fillParamsFromCloud', { response: null }));
        }
    }, []);

    return <React.Fragment></React.Fragment>;
});

export default Cloud;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Buttons = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    textarea {
        width: 99%;
        min-height: 100px;
        font-size: 14px;
        margin-bottom: 10px;
    }
`;

const StTextField = styled(TextField)`
    width: 300px;
    margin-bottom: 10px;
`;
