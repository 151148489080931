import GeometryVSDataManager from '../tools/GeometryVSDataManager';
import Tools from '../tools/tools';
import moment from 'moment';

const update = (state, action) => {
    let obj = {
        mainState: {
            insetName: '',
            selectedObjects: '',
            max_timestamp: '',
            min_timestamp: '',
            token: '',
            username: '',
            commonState: '',
        },
    };
    return Object.assign({}, state, {
        mainState: action.json,
    });
};

const getUpdate = (state, action) => {
    return Object.assign({}, state, {
        objId: action.id,
        token: action.token,
    });
};

const loadMaps = (state, action) => {
    if (action.json === null) {
        return Object.assign({}, state, {});
    }
    console.log('<<<<<', action.json);
    action['data'] = createMapsLink(action.json, urls.maps, state);
    state.mallFloors = action?.data?.data;
    var internal = false;
    let floorIndex = Tools.copy(state.floorIndex);
    // for (var j = 0; j < action?.data?.data?.value.length; j++) {
    //     if (action.data.data.value[j].floor >= 0 && internal === false) {
    //         internal = true;
    //         floorIndex = j;
    //     }
    // }
    internal = false;

    // let mapLink = action.data.links[floorIndex].link;
    // let mapLinks = action.data.links;
    let mapPreviewItem = [];
    let statusCreate = action.status;

    // for (var i = 0; i < action.data.links.length; i++) {
    //     if (i === floorIndex) {
    //         var previewItem = {
    //             link: action.data.links[i].link,
    //             statusActive: true,
    //             index: action.data.links[i].index,
    //             idFloors: action.data.links[i].idLink,
    //         };
    //     } else {
    //         var previewItem = {
    //             link: action.data.links[i].link,
    //             statusActive: false,
    //             index: action.data.links[i].index,
    //             idFloors: action.data.links[i].idLink,
    //         };
    //     }
    //     mapPreviewItem.push(previewItem);
    // }
    if (state.dataPlanSet.plans !== undefined) {
        for (var g = 0; g < state.dataPlanSet.plans.length; g++) {
            if (state.dataPlanSet.plans[g].places.length > 0 || state.dataPlanSet.plans[g].zone_groups.length > 0) {
                if (g === floorIndex) {
                    // floorIndex = g;

                    mapPreviewItem[g].statusActive = true;
                    mapPreviewItem[g]['statusClick'] = true;
                } else {
                    mapPreviewItem[g].statusActive = false;
                    mapPreviewItem[g]['statusClick'] = true;
                }
            } else {
                mapPreviewItem[g].statusActive = false;
                mapPreviewItem[g]['statusClick'] = false;
            }
        }
    }
    return Object.assign({}, state, {
        mainState: action.json,
        // mallFloors: action.data.data,
        floorIndex: floorIndex,
        // mapLink: mapLink,
        // mapLinks: mapLinks,
        mapPreviewItem: mapPreviewItem,
        statusCreate: statusCreate,
    });
};

const urls = {
    maps: {
        root: 'https://api.getshopster.net/v3/floor/map?',
        mallId: 'mall_id=',
        floor: '&floor=',
    },
};

const createMapsLink = (data, urls, state) => {
    var arrLinksMap = [];
    var dataOld = {};
    dataOld.value = data;
    dataOld.status = 'ok';

    var successObj = {
        data: dataOld,
        links: null,
    };
    // console.log('12345', dataOld)
    try {
        if (data !== undefined && dataOld.status === 'ok') {
            // console.log('123456', dataOld);
            dataOld.value = dataOld.value.sort(function (a, b) {
                if (a.floor > b.floor) {
                    return 1;
                }
                if (a.floor < b.floor) {
                    return -1;
                }
                return 0;
            });

            for (var i = 0; i < dataOld.value.length; i++) {
                var objLink = {
                    link: urls.root + urls.mallId + state.objId + urls.floor + dataOld.value[i].floor + '&' + 'token=' + state.token,
                    idLink: dataOld.value[i].floor,
                    index: i,
                    objId: dataOld.value[i].id,
                };
                arrLinksMap.push(objLink);
            }
            successObj.links = arrLinksMap;
            return successObj;
        }
    } catch (error) {}
};

const getSelectRouter = (state, action) => {};

const getRouterInfo = (state, action) => {
    console.log('getRouterInfo', action.json);
    let routerItems = action.json;

    let mapSvg = [];
    let scale = [];

    // let loadStatusStatisitcs = true;

    return Object.assign({}, state, {
        routerItems: routerItems,
        mapSvg: mapSvg,
        scale: scale,
        loadStatusStatisitcs: true,
    });
};

const selectedFloor = (state, action) => {};

const createStatistics = (state, action) => {};

const createScale = (state, action) => {};

function get_heat_color(val) {
    var r = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (val - 0.5))), 1);
    var g = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (val - 0.25))), 1);
    var b = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * val)), 1);
    return 'rgba(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ',0.5)';
}

const getSelectZoneStatus = (state, action) => {
    let selectedAreaIndex, zoneId;
    if (state.selectedAreaIndex == action.indexZone) {
        selectedAreaIndex = null;
        zoneId = null;
    } else {
        selectedAreaIndex = action.indexZone;
        zoneId = action.data.zoneId;
    }
    return Object.assign({}, state, {
        selectedAreaIndex: selectedAreaIndex,
        zoneId: zoneId,
    });
};

const getSelectRouterInRouter = (state, action) => {};

const getSelectMall = (state, action) => {};

const deactivateZone = (state, action) => {};

const setStatusLoad = (state, action) => {
    return Object.assign({}, state, {
        loadStatusStatisitcs: false,
        statusNotData: false,
    });
};

const setAnaliticsMethod = (state, action) => {
    return Object.assign({}, state, {
        statusAnalitics: action.status,
        selectedAreaIndex: null,
        zoneId: null,
        transitions: {},
        roomsTransition: {},
    });
};

const selectedOptions = (state, action) => {};

const selectedOptionsExclusion = (state, action) => {};

const onCheckOptions = (state, action) => {};

const setStatusFilter = (state, action) => {};

const setStepsOptions = (state, action) => {};

const setFilterSelect = (state, action) => {};

const setFilterSelectB = (state, action) => {};

const getTop = (state, action) => {};

const getNumSteps = (state, action) => {};

const setRequestRoute = (state, action) => {};

const getArea = (state, action) => {};

const getLoadStatus = (state, action) => {};

const setTypeSelect = (state, action) => {};

const clearDataRoute = (state, action) => {};

const setStatusInvolvement = (state, action) => {};

const setValueMac = (state, action) => {};

const clearThershold = (state, action) => {};

const getDataThershold = (state, action) => {};

const setSelectTopDetail = (state, action) => {};

const getStatusPoint = (state, action) => {};

const getRouterData = (state, action) => {};

const getUuidPlanSet = (state, action) => {
    if (action.json.plan_set_uuid !== undefined) {
        return Object.assign({}, state, {
            uuidPlanSet: action.json.plan_set_uuid,
            statusLoadPlanSets: false,
        });
    } else {
        return Object.assign({}, state, {
            uuidPlanSet: null,
            statusLoadPlanSets: false,
        });
    }
};

const getPlanSet = (state, action) => {
    let dataPlanSet = action.json;
    var coords = [];
    var is_opened = [];
    var name = [];
    var retail = [];
    let markers = [];
    let floors = [];
    var tempStatus = false;
    let mapPreviewItem = [];
    var obj3 = {};
    let floorIndex = Tools.copy(state.floorIndex);
    for (var g = 0; g < action.json.plans.length; g++) {
        if (action.json.plans[g].places.length > 0 || action.json.plans[g].zone_groups.length > 0) {
            if (tempStatus === false) {
                if (action.json.plans[g].floor > 0) {
                    obj3 = {
                        idFloors: action.json.plans[g].floor,
                        index: g,
                        link: action.json.plans[g].image,
                        statusActive: true,
                        statusClick: true,
                        routers: [],
                    };
                    floorIndex = g;
                    tempStatus = true;
                } else {
                    obj3 = {
                        idFloors: action.json.plans[g].floor,
                        index: g,
                        link: action.json.plans[g].image,
                        statusActive: false,
                        statusClick: true,
                        routers: [],
                    };
                }
            } else {
                obj3 = {
                    idFloors: action.json.plans[g].floor,
                    index: g,
                    link: action.json.plans[g].image,
                    statusActive: false,
                    statusClick: true,
                    routers: [],
                };
            }
        } else {
            obj3 = {
                idFloors: action.json.plans[g].floor,
                index: g,
                link: action.json.plans[g].image,
                statusActive: false,
                statusClick: false,
                routers: [],
            };
        }
        mapPreviewItem.push(obj3);
    }
    for (var i = 0; i < action.json.plans[floorIndex].places.length; i++) {
        coords.push(action.json.plans[floorIndex].places[i].coords);
        is_opened.push(action.json.plans[floorIndex].places[i].is_opened);
        name.push(action.json.plans[floorIndex].places[i].name);
        retail.push(GeometryVSDataManager.reportNameWithPlace(action.json.plans[floorIndex].places[i].name));
    }
    let zonesObjData = Tools.copy(state.zonesObjData);
    for (var f = 0; f < action.json.plans.length; f++) {
        for (var d = 0; d < action.json.plans[f].zone_groups.length; d++) {
            if (zonesObjData[action.json.plans[f].zone_groups[d].group_marker] === undefined) {
                zonesObjData[action.json.plans[f].zone_groups[d].group_marker] = [];
            }
            zonesObjData[action.json.plans[f].zone_groups[d].group_marker] = zonesObjData[
                action.json.plans[f].zone_groups[d].group_marker
            ].concat(action.json.plans[f].zone_groups[d].zones);
        }
    }

    let zonesData = action.json.plans[floorIndex].zone_groups;
    let optionTypeNewMaps = [];
    if (coords.length > 0) {
        optionTypeNewMaps.push({
            value: 'places',
            label: 'Помещения',
        });
    }

    for (var k = 0; k < action.json.plans[floorIndex].zone_groups.length; k++) {
        optionTypeNewMaps.push({
            value: action.json.plans[floorIndex].zone_groups[k].group_marker,
            label: action.json.plans[floorIndex].zone_groups[k].group_marker,
        });
    }
    let typeNewMaps;
    if (coords.length === 0) {
        typeNewMaps = optionTypeNewMaps[0];
    } else {
        typeNewMaps = {
            value: 'places',
            label: 'Помещения',
        };
    }

    for (var j = 0; j < action.json.plans.length; j++) {
        floors.push(action.json.plans[j].floor);
        for (var g = 0; g < action.json.plans[j].places.length; g++) {
            markers.push(action.json.plans[j].places[g].name);
        }
    }
    let image = action.json.plans[floorIndex].image;
    let places = {
        coords: coords,
        is_opened: is_opened,
        name: name,
        retail: retail,
    };
    let objAnalitic = Tools.copy(state.objAnalitic);
    let statusLoadSelectPolygons = Tools.copy(state.statusLoadSelectPolygons);
    if (objAnalitic.load !== undefined) {
        statusLoadSelectPolygons = false;
    } else {
        objAnalitic['load'] = '';
    }

    return Object.assign({}, state, {
        dataPlanSet: dataPlanSet,
        markers: markers,
        floors: floors,
        mapPreviewItem: mapPreviewItem,
        floorIndex: floorIndex,
        zonesObjData: zonesObjData,
        optionTypeNewMaps: optionTypeNewMaps,
        typeNewMaps: typeNewMaps,
        image: image,
        places: places,
        statusLoadSelectPolygons: statusLoadSelectPolygons,
        objAnalitic: objAnalitic,
        zonesData: zonesData,
        dataPlansList: action.json.plans,
    });
};

const setRooms = (state, action) => {
    return Object.assign({}, state, {
        rooms: action.data,
    });
};

const setZones = (state, action) => {
    return Object.assign({}, state, {
        statusNotData: false,
        zones: action.data,
    });
};

const setFloor = (state, action) => {
    let dataFloors = Tools.copy(action.data);
    if (state.dataPlanSet.plans !== undefined) {
        var obj = {};
        var arr = [];
        var obj2 = {};
        var arr2 = [];
        for (var i = 0; i < state.dataPlanSet.plans.length; i++) {
            obj = {
                floor: state.dataPlanSet.plans[i].floor,
                id: GeometryVSDataManager.reportObjWithFloor(state.dataPlanSet.plans[i].floor),
            };
            var status = false;
            if (i === 0) {
                status = true;
            }
            obj2 = {
                idFloors: state.dataPlanSet.plans[i].floor,
                index: i,
                link: state.dataPlanSet.plans[i].image,
                statusActive: status,
                routers: [],
            };
            arr.push(obj);
            arr2.push(obj2);
        }
        let mallFloors = {
            status: 'ok',
            value: arr,
        };

        let mapPreviewItem = arr2;
        var tempStatus = false;
        let floorIndex = Tools.copy(state.floorIndex);
        for (var g = 0; g < state.dataPlanSet.plans.length; g++) {
            if (state.dataPlanSet.plans[g].places.length > 0 || state.dataPlanSet.plans[g].zone_groups.length > 0) {
                if (tempStatus === false) {
                    if (state.dataPlanSet.plans[g].floor > 0) {
                        floorIndex = g;
                        mapPreviewItem[g].statusActive = true;
                        mapPreviewItem[g]['statusClick'] = true;
                        tempStatus = true;
                    } else {
                        mapPreviewItem[g].statusActive = false;
                        mapPreviewItem[g]['statusClick'] = true;
                    }
                } else {
                    mapPreviewItem[g].statusActive = false;
                    mapPreviewItem[g]['statusClick'] = true;
                }
            } else {
                mapPreviewItem[g].statusActive = false;
                mapPreviewItem[g]['statusClick'] = false;
            }
        }
        return Object.assign({}, state, {
            mallFloors: mallFloors,
            floorIndex: floorIndex,
            mapPreviewItem: mapPreviewItem,
            dataFloors: dataFloors,
        });
    } else {
        return Object.assign({}, state, {
            dataFloors: dataFloors,
        });
    }
};

const onChangeTypeNewMaps = (state, action) => {
    state.transitions = {};
    state.roomsTransition = {};
    let typeNewMaps;
    if (action.value.value === 'places') {
        typeNewMaps = {
            value: 'places',
            label: 'Помещения',
        };
    } else {
        typeNewMaps = {
            value: action.value.value,
            label: action.value.label,
        };
    }
    return Object.assign({}, state, {
        transitions: {},
        roomsTransition: {},
        typeNewMaps: typeNewMaps,
    });
};

const getFloorId = (state, action) => {
    let roomsTransition = {};
    let mapId = action.id;
    let idSelectRouter = null;

    var coords = [];
    var is_opened = [];
    var name = [];
    var retail = [];
    let mapPreviewItem = Tools.copy(state.mapPreviewItem);
    let indexMapFloor;
    let floorIndex;
    let selectedAreaIndex;
    for (var j = 0; j < mapPreviewItem.length; j++) {
        indexMapFloor = action.id;
        if (action.id === mapPreviewItem[j].index) {
            floorIndex = j;
            mapPreviewItem[j].statusActive = true;
            selectedAreaIndex = null;
        } else {
            mapPreviewItem[j].statusActive = false;
        }
    }
    for (var i = 0; i < state.dataPlanSet.plans[floorIndex].places.length; i++) {
        coords.push(state.dataPlanSet.plans[floorIndex].places[i].coords);
        is_opened.push(state.dataPlanSet.plans[floorIndex].places[i].is_opened);
        name.push(state.dataPlanSet.plans[floorIndex].places[i].name);
        retail.push(GeometryVSDataManager.reportNameWithPlace(state.dataPlanSet.plans[floorIndex].places[i].name));
    }
    let image = state.dataPlanSet.plans[floorIndex].image;
    let places = {
        coords: coords,
        is_opened: is_opened,
        name: name,
        retail: retail,
    };
    let zonesData = state.dataPlanSet.plans[floorIndex].zone_groups;
    let optionTypeNewMaps = [];

    if (coords.length > 0) {
        optionTypeNewMaps.push({
            value: 'places',
            label: 'Помещения',
        });
    }

    for (var k = 0; k < state.dataPlanSet.plans[floorIndex].zone_groups.length; k++) {
        optionTypeNewMaps.push({
            value: state.dataPlanSet.plans[floorIndex].zone_groups[k].group_marker,
            label: state.dataPlanSet.plans[floorIndex].zone_groups[k].group_marker,
        });
    }
    let typeNewMaps;
    if (coords.length === 0) {
        typeNewMaps = optionTypeNewMaps[0];
    } else {
        typeNewMaps = {
            value: 'places',
            label: 'Помещения',
        };
    }
    return Object.assign({}, state, {
        mapId: action.id,
        roomsTransition: roomsTransition,
        idSelectRouter: idSelectRouter,
        indexMapFloor: indexMapFloor,
        mapPreviewItem: mapPreviewItem,
        image: image,
        places: places,
        zonesData: zonesData,
        optionTypeNewMaps: optionTypeNewMaps,
        typeNewMaps: typeNewMaps,
        floorIndex: floorIndex,
        selectedAreaIndex: selectedAreaIndex,
    });
};

const selectPolygons = (state, action) => {
    return Object.assign({}, state, {
        statusLoadSelectPolygons: action.status,
    });
};

const setDataSelectPolygon = (state, _action) => {
    let action = {
        json: {
            data: [],
        },
    };
    if (_action.json !== null) {
        if (_action.json.response !== undefined) {
            _action.json.response.forEach((item, index) => {
                // {
                //     contra_segment_count_unique: 821,
                //     intersection_count_unique: 153,
                //     main_segment_count_unique: 19759
                // }
                action.json.data.push({
                    count_client: 0,
                    count_intersection: item.intersection_count_unique,
                    first_obj_id: _action.data.obj_id,
                    intersection_percent:
                        item.intersection_count_unique === 0
                            ? 0
                            : +((item.intersection_count_unique / item.main_segment_count_unique) * 100).toFixed(2),
                    reverse_intersection_percent:
                        item.intersection_count_unique === 0
                            ? 0
                            : +((item.intersection_count_unique / item.contra_segment_count_unique) * 100).toFixed(2),
                    second_obj_id: _action.data.obj_ids[index],
                });
                // console.log('>>>>>>>>>>>>>>>>', item.intersection_count_unique ,  item.contra_segment_count_unique, item.main_segment_count_unique );
            });
        } else {
            action.json.data = null;
        }
    } else {
        action.json.data = null;
    }


    let transitions = {};
    var roomsTransition = {};
    var itemRetailName = null;
    if (action.json.data !== null) {
        for (var i = 0; i < action.json.data.length; i++) {
            transitions[action.json.data[i].second_obj_id] = action.json.data[i];
        }
        roomsTransition = {};
        for (var key in state.rooms) {
            if (transitions[state.rooms[key].id] !== undefined) {
                roomsTransition[key] = transitions[state.rooms[key].id];
            }
        }

        for (var keyy in state.zones) {
            // console.log('>>>>>>>>>>>>>>>.322', state.zones[keyy].id, transitions);
            if (transitions[state.zones[keyy].id] !== undefined) {
                roomsTransition[state.zones[keyy].id] = transitions[state.zones[keyy].id];
            }
        }
        if (state.dataFloors !== null) {
            for (var keyy in state.dataFloors.obj) {
                if (transitions[state.dataFloors.obj[keyy]] !== undefined) {
                    roomsTransition[keyy] = transitions[state.dataFloors.obj[keyy]];
                }
            }
        }
        itemRetailName = '';
        if (state.typeNewMaps.value === 'places') {
            if (GeometryVSDataManager.reportObjRoomsById(action.json.data[0].first_obj_id) !== undefined) {
                itemRetailName = GeometryVSDataManager.reportObjRoomsById(action.json.data[0].first_obj_id).name;
            }
        } else {
            itemRetailName = state.zones[GeometryVSDataManager.reportIdWithZoneMarker(action.json.data[0].first_obj_id)].zone_name;
        }
    } else {
        roomsTransition = {};
        itemRetailName = null;
    }
    // console.log('>>>>>>>>>>>>>>>>', transitions, roomsTransition, itemRetailName)

    return Object.assign({}, state, {
        transitions: transitions,
        roomsTransition: roomsTransition,
        itemRetailName: itemRetailName,
        statusLoadSelectPolygons: false,
    });
};

const setUnekUsers = (state, action) => {
    // console.log('>>>>>>>>>>>>>>', action.json, GeometryVSDataManager.setUneqUser(action.json))
    let dataUneqUsers = GeometryVSDataManager.setUneqUser(action.json);
    let objAnalitic = state.objAnalitic;
    let statusLoadSelectPolygons;
    if (objAnalitic.load !== undefined) {
        statusLoadSelectPolygons = false;
    } else {
        objAnalitic['load'] = '';
    }
    return Object.assign({}, state, {
        statusLoadSelectPolygons: statusLoadSelectPolygons,
        objAnalitic: objAnalitic,
        dataUneqUsers: dataUneqUsers,
    });
};

const setUnekUsersOfZones = (state, action) => {
    let dataUneqUsersOfZones = GeometryVSDataManager.setUneqUserZones(action.json);
    let objAnalitic = state.objAnalitic;
    let statusLoadSelectPolygons;
    if (objAnalitic.load !== undefined) {
        statusLoadSelectPolygons = false;
    } else {
        objAnalitic['load'] = '';
    }
    return Object.assign({}, state, {
        statusLoadSelectPolygons: statusLoadSelectPolygons,
        objAnalitic: objAnalitic,
        dataUneqUsersOfZones: dataUneqUsersOfZones,
    });
};

const getStatusShowColotCount = (state, action) => {
    return Object.assign({}, state, {
        transitions: {},
        roomsTransition: {},
        statusColorCount: action.status,
    });
};

const onCheckBackgroundImage = (state, action) => {
    return Object.assign({}, state, {
        statusCheckBackground: !state.statusCheckBackground,
    });
};

const getStatusOpenPanelAnalitics = (state, action) => {
    return {
        ...state,
        statusSelectPolygons: action.payload.status,
        roomsTransition: {},
    };
};

const getStatusShowMetricsPanel_MapsReducer = (state, action) => {
    return {
        ...state,
        statusShovedMetricsPanel: action.payload.status,
        roomsTransition: {},
    };
};

const getDateForMaps_MapsReducer = (state, action) => {
    return {
        ...state,
        selectedDateEnd: action.payload.selected,
    };
};

const getUuidDataListForMaps_MapsReducer = (state, action) => {
    return {
        ...state,
        uuidDataList: action.payload.list,
    };
};

const getDataByUuids_MapsReducer = (state, action) => {
    let itemUuid = null;
    let uuidDataSelected = {};
    // console.log('setVersionData_source',state.ctg.uuidDataByUuid[action.payload.data].dt);
    itemUuid = state.uuidDataByUuid[action.payload.data].uuid;
    uuidDataSelected = {
        value: state.uuidDataByUuid[action.payload.data].uuid,
        label: 'Версия: ' + moment(state.uuidDataByUuid[action.payload.data].dt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
    };

    return {
        ...state,
        // ...state.ctg,
        // uuidDataByUuid: uuidDataByUuid,
        // selectedUuid: itemUuid,
        // uuidDataList:uuidDataList,
        uuidDataSelected: uuidDataSelected,
        // statusLoadVersion:true
    };
};

const getUuidDataForMaps_MapsReducer = (state, action) => {
    return {
        ...state,
        uuidDataByUuid: action.payload.uuidData,
    };
};

const getRequestPlanSets_MapsReducer = (state, action) => {
    return {
        ...state,
        statusLoadPlanSets: true,
    };
};

let stateInit = {
    mainState: { commonState: null },
    malls: null,
    mallFloors: null,
    mapId: null, // странная переменная, она вроде никогда не меняется. Это какая то константа? Она нужна?
    mapPreviewItem: [], // mapPreviewItems - множественное число, полагаю
    statusCreate: false,
    mapLink: null,
    mapLinks: null,
    area: [],
    monitor: [],
    transition: {},
    routerItems: [],
    mapRouter: [],
    mapSvg: [],
    indexMapFloor: 0,
    objRoutersName: {},
    floorIndex: 0,
    loadStatusStatisitcs: false,
    mallId: 0,
    zoneId: null,
    scale: [],
    routerObj: {},

    // >>> added by Alex
    selectedAreaIndex: null,
    // <<<
    statusAnalitics: 'maps',
    optionsSelect: [],
    optionsSelectExclusion: [],
    optionsSelected: [],
    optionsSelectedExclusion: [],
    statusCheck: false,
    statusCheckExclusion: false,
    statusStepsOptions: 'arbitrarily',
    filterObj: {
        fromAToB: true,
        fromA: false,
        toA: false,
        toAFrom: false,
        start: false,
        finish: false,
        mac: false,
        top: false,
    },
    optionsFilterObjectsA: [],
    optionsFilterObjectsB: [],
    optionsFilterValueA: null,
    optionsFilterValueB: null,
    defaultOptions: [],
    statusTop: 5,
    numSteps: '1',
    runData: [],
    statusShowRouterBoard: true,
    routerData: [],
    routerGlobalData: [],
    statusTypeSelect: 'monitor',
    dataMonitor: [],
    dataArea: [],
    dataFloor: [],
    statusInvolvement: '30 second',
    macAdress: '',
    dataThreshold: [],
    dataSelect: 'any_part',
    statusNotData: false,
    statusPointFrom: false,
    statusPointTo: false,
    idSelectRouter: null,
    dataPlaceMaps: 'ca298da4-7765-4ea5-bc93-13192e2983d0',
    dataPlanSet: {},
    places: {},
    image: null,
    transitions: [],
    rooms: {},
    roomsTransition: {},
    statusLoadSelectPolygons: false,
    markers: [],
    floors: [],
    dataFloors: [],
    itemRetailName: null,
    dataUneqUsers: {},
    dataUneqUsersOfZones: {},
    objAnalitic: {},
    uuidPlanSet: null,
    optionTypeNewMaps: [
        {
            value: 'places',
            label: 'Помещения',
        },
    ],
    zonesData: null,
    typeNewMaps: {
        value: 'places',
        label: 'Помещения',
    },
    zones: {},
    zonesObjData: {},
    statusColorCount: false,
    objId: null,
    token: null,
    statusCheckBackground: false,
    dataPlansList: [],
    statusSelectPolygons: false,
    selectedDateEnd: 0,
    uuidDataList: [],
    uuidDataByUuid: {},
    statusShovedMetricsPanel: false,
    statusLoadPlanSets: false,
};

const MapsApp = (state = stateInit, action) => {
    switch (action.type) {
        case 'getDataMaps':
            return loadMaps(state, action);
        case 'getUpdate':
            return getUpdate(state, action);
        case 'getSelectZone':
            return getSelectZoneStatus(state, action);
        case 'getStatusLoadTransition':
            return setStatusLoad(state, action);
        case 'getRouterInfo':
            return getRouterInfo(state, action);
        case 'setPLIdInfo':
            return getUuidPlanSet(state, action);
        case 'getSendPlanSet':
            return getPlanSet(state, action);
        case 'setRooms':
            return setRooms(state, action);
        case 'setZones':
            return setZones(state, action);
        case 'setFloors':
            return setFloor(state, action);
        case 'updateValue':
            return onChangeTypeNewMaps(state, action);
        case 'getFloorId':
            return getFloorId(state, action);
        case 'selectPolygons':
            return selectPolygons(state, action);
        case 'setDataSelectPolygons':
            return setDataSelectPolygon(state, action);
        case 'setUnekUsers':
            return setUnekUsers(state, action);
        case 'setUnekUsersOfZones':
            return setUnekUsersOfZones(state, action);
        case 'showColorCount':
            return getStatusShowColotCount(state, action);
        case 'onClickTab':
            return setAnaliticsMethod(state, action);
        case 'onCheckBackgroundImage':
            return onCheckBackgroundImage(state, action);
        case 'getStatusSelectPolygon_MapsReducer':
            return getStatusOpenPanelAnalitics(state, action);
        case 'getStatusShowMetricsPanel_MapsReducer':
            return getStatusShowMetricsPanel_MapsReducer(state, action);
        case 'getDateForMaps_MapsReducer':
            return getDateForMaps_MapsReducer(state, action);
        case 'getRequestPlanSets_MapsReducer':
            return getRequestPlanSets_MapsReducer(state, action);

        case 'getUuidDataListForMaps_MapsReducer':
            return getUuidDataListForMaps_MapsReducer(state, action);
        // case 'getDataByUuids_Source':
        //     return getDataByUuids_MapsReducer(state, action);
        case 'getUuidDataForMaps_MapsReducer':
            return getUuidDataForMaps_MapsReducer(state, action);
        default:
            return state;
    }
};

export default MapsApp;
