import React from 'react';
import { useTranslation } from 'react-i18next';

const ReportInfo = React.memo(props => {
    const { t } = useTranslation();
    const renderParams = () => {
        const { reportParamsForInfo } = props;
        return (
            <ul>
                {reportParamsForInfo.map((item, i) => {
                    return (
                        <li key={`${item[0]}: ${i}`}>
                            {`${item[0]}: `}
                            <i className={'ReportInfo_value'}>{item[1]}</i>
                        </li>
                    );
                })}
            </ul>
        );
    };
    return (
        <div className={'ReportInfo'}>
            <div className={'ReportInfo_parameters'}>
                <h4>{t('Параметры')}</h4>
                {renderParams()}
            </div>
            <div className={'ReportInfo_description'}>
                <h4>{t('Описание')}</h4>
                <p>{props.description}</p>
            </div>
        </div>
    );
});

export default ReportInfo;
