import React, { useMemo, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { TooltipHost, TooltipDelay, DirectionalHint, ITooltipProps, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import moment from 'moment';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
// import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components/macro';
import Title from '../../../../../modal/components/title';

import TooltipAttachFile from './tooltip_attach_file';
import tools from "../tools/tools";
import {useSelector} from "react-redux";


const NewSegmentModal = React.memo(
    ({ editData = null, customInputParamsValues, reportingObjectsByPlId, reportingObjectsById, ...props }) => {
        const { t } = useTranslation();
        const [segmentParams, setSegmentParams] = useState({
            name: '',
            id: null,
            description: '',
            date_from: moment(customInputParamsValues.main_date_range[0]).toDate(),
            date_to: moment(customInputParamsValues.main_date_range[1]).toDate(),
            content_type: 'mac',
            client_type: 'wifi_visitor',
            mailru_needs_upload: false,
            mailru_accounts: [],
            mailru_is_public: false,
            yandex_needs_upload: false,
            yandex_accounts: [],
            file: null,
            obj_ids: [],
        });
        const { lang } = useSelector((state) => state.TopPanel);
        const [newSegmentMode, set_newSegmentMode] = useState('from_obj_ids');
        const [mailru_is_public, set_mailru_is_public] = useState(false);
        const [valueMailRu, setValueMailRu] = useState('');
        const [valueYandexRu, setValueYandexRu] = useState('');
        const [selectedLocation, set_selectedLocation] = useState(null);
        const [selectedZone, set_selectedZone] = useState(null);
        const [filter, set_filter] = useState({ locations: '', objects: '' });
        const [allChecked, setAllChecked] = useState(false);
        const [showExplorer, set_showExplorer] = useState(false);
        const [showInfo, setShowInfo] = useState(false);
        const [statusDisableButtonOk, setStatusDisable] = useState(false);
        const [statusDisableButtonOkByYandex, setStatusDisableButtonOkByYandex] = useState(false);
        const [isCheckedIdsList, setIsCheckedIdsList] = useState(false);
        const [undefinedListIds, setUndefinedListIds] = useState([]);
        const [valueIdList, setValueIdList] = useState('');
        const [heightTexterea, setHeightTexterea] = useState('0px');
        const [afterTimerStatus, setAfterTimerStatus] = useState(false);
        const [arrayObjectsfilter, setArrayObjectsfilter] = useState( []);
        const [width, height] = tools.useWindowSize();
        const [theme] = useState(getTheme());


        moment.locale(lang);
        useEffect(() => {
            if (editData) {
                const { name, id, date_from, date_to } = editData;
                const { client_type, obj_ids } = editData.location_filter;
                set_newSegmentMode('from_obj_ids');
                setSegmentParams({
                    ...segmentParams,
                    name,
                    id,
                    client_type,
                    obj_ids,
                    date_from: moment(date_from).toDate(),
                    date_to: moment(date_to).toDate(),
                });
            }
        }, [editData]);


        useEffect(() => {
            if (segmentParams.obj_ids) {
                if (segmentParams.obj_ids.length > 0) {
                    setValueIdList(segmentParams.obj_ids.join(', '))
                }
            }

            setUndefinedListIds([])


        }, [showExplorer]);

        useEffect(() => {
            if (document.getElementById('ColWrapper')) {
                const regexp1 = /([0-9][^a-zA-Z,]{1,})/gi
                var elem1 = document.getElementById("ColWrapper");
                var style = window.getComputedStyle(elem1, null).getPropertyValue("height");
                const arrayIsCorrect = style.match(regexp1);
                // console.log('>>>>>>>>>', height - 344, +arrayIsCorrect[0] - 44);
                setHeightTexterea(+arrayIsCorrect[0] - 12);
            }


        }, [width, height, document.getElementById('ColWrapper')]);


        const options = [
            { key: 'from_obj_ids', text: t('Выбрать объекты') },
            { key: 'from_file', text: t('Из файла') },
        ];

        const optionsMailRu = [
            { key: false, text: t('Приватный сегмент'), styles:{field: { marginRight: "15px"}} },
            { key: true, text: t('Публичный сегмент'), styles:{field: { marginRight: "15px"}} },
        ];

        const DayPickerStrings = {
            months: [
                t('Январь'),
                t('Февраль'),
                t('Март'),
                t('Апрель'),
                t('Май'),
                t('Июнь'),
                t('Июль'),
                t('Август'),
                t('Сентябрь'),
                t('Октябрь'),
                t('Ноябрь'),
                t('Декабрь'),
            ],
            shortMonths: [
                t('Янв'),
                t('Фев'),
                t('Мар'),
                t('Апр'),
                t('Май'),
                t('Июн'),
                t('Июл'),
                t('Авг'),
                t('Сен'),
                t('Окт'),
                t('Ноя'),
                t('Дек'),
            ],
            // days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            days: [t('Воскресенье'), t('Понедельник'), t('Вторник'), t('Среда'), t('Четверг'), t('Пятница'), t('Суббота')],
            shortDays: [t('Вс'), t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб')],
            goToToday: 'Go to today',
            prevMonthAriaLabel: 'Go to previous month',
            nextMonthAriaLabel: 'Go to next month',
            prevYearAriaLabel: 'Go to previous year',
            nextYearAriaLabel: 'Go to next year',
            closeButtonAriaLabel: 'Close date picker',
            isRequiredErrorMessage: 'Start date is required.',
            invalidInputErrorMessage: 'Invalid date format.',
        };

        const firstDayOfWeek = DayOfWeek.Monday;

        let stylesChoiceGroupMail = {
            flexContainer: { display: "flex" }
        }


        const onChange = React.useCallback((ev, option) => {
            setSegmentParams({ ...segmentParams, type: option.key });
        }, []);

        const _onChange = (key) => (e, newValue) => {
            setSegmentParams({ ...segmentParams, [key]: newValue });
        };

        const _onChangeFilter = (key) => (e, newValue) => {
            set_filter({ ...filter, [key]: newValue });
        };

        const _onContentTypeChange = (e, item) => {
            setSegmentParams({ ...segmentParams, content_type: item.key });
        };

        const _onClientTypeChange = (e, item) => {
            setSegmentParams({ ...segmentParams, client_type: item.key });
        };

        const _onUploadChange = (key) => (e, checked) => {
            setSegmentParams({ ...segmentParams, [key]: !!checked });
            if (key === 'mailru_needs_upload') {
                setValueMailRu('');
            }
            else {
                setValueYandexRu('')
            }
        };

        const _onChangeAccounts = (key) => (e, newValue) => {
            let arrayText = []
            if (key === 'mailru_accounts') {
                arrayText = tools.regexpCreateNewArrText('mailru', newValue);
                setValueMailRu(newValue)
                setStatusDisable(arrayText.length > 0 ? false : true)
            }
            else {
                arrayText = tools.regexpCreateNewArrText('yandexru', newValue);
                setValueYandexRu(newValue)
                setStatusDisableButtonOkByYandex(arrayText.length > 0 ? false : true)
            }
            setSegmentParams({ ...segmentParams, [key]: arrayText });
        };



        const _onSelectDate = (key) => (date) => {
            setSegmentParams({ ...segmentParams, [key]: date });
        };

        const _onFormatDate = (date) => {

            return moment(date).format('DD MMM YYYY');
        };

        const _onParseDateFromString = (key) => (value) => {
            const date = setSegmentParams[key] || new Date();
            const values = (value || '').trim().split('-');
            let year = values.length > 0 ? parseInt(values[0], 10) : date.getFullYear();
            const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
            const day = values.length > 2 ? Math.max(1, Math.min(31, parseInt(values[2], 10))) : date.getDate();
            if (year < 100) {
                year += date.getFullYear() - (date.getFullYear() % 100);
            }
            return new Date(year, month, day);
        };

        const _onAdSpaceIdsChange = (event, item) => {
            const newSelectedItems = [...setSegmentParams.ad_space_ids];
            if (item.selected) {
                newSelectedItems.push(item.key);
            } else {
                const currIndex = newSelectedItems.indexOf(item.key);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }
            setSegmentParams({ ...segmentParams, ad_space_ids: [...newSelectedItems] });
        };

        const _onFileChange = (e) => {
            const file = [...e.target.files][0];

            if (file.size > 350 * 1024 * 1024) {
                setSegmentParams({ ...segmentParams, file: null });
                alert(t('Превышен максимальный размер файла'));
            }
            // else if (file.type !== 'text/csv') {
            //     setAdCampParams({ ...segmentParams, file: null });
            //     alert(t('Файл не того типа'));
            // }
            else {
                setSegmentParams({ ...segmentParams, file, name: file.name.replace(/.csv/, '') });
            }
        };

        const tooltipProps = {
            onRenderContent: () => <TooltipAttachFile />,
        };

        // const hostStyles = { root: { display: 'flex' } };
        const infoIcon = { iconName: 'Info' };

        const onInfoIconClick = () => {
            setShowInfo(true);
        };

        const contentTypeOptions = [
            { key: 'mac', text: 'mac' },
            { key: 'machash', text: 'machash' },
            { key: 'phone', text: 'phone' },
        ];

        const clientTypeOptions = [
            { key: 'wifi_total', text: t('Все зафиксированные MAC-адреса') },
            { key: 'wifi_employee', text: t('Сотрудники') },
            { key: 'wifi_passer', text: t('Проходящие') },
            { key: 'wifi_visitor', text: t('Посетители') },
            { key: 'wifi_all', text: t('Посетители и проходящие') },
            { key: 'hotspot_authorized', text: t('Авторизованные пользователи хотспота') },
        ];

        const onModeChange = React.useCallback((ev, option) => {
            set_newSegmentMode(() => option.key);
        }, []);


        const onIsPublicChange = (ev, option) => {
            setSegmentParams({ ...segmentParams, mailru_is_public:  option.key });
            set_mailru_is_public( option.key);
        };



        const segmentName = (
            <TextField
                isRequired={true}
                label={t('Имя')}
                value={segmentParams.name}
                onChange={_onChange('name')}
                placeholder={t('Минимум 5 символов')}
            />
        );

        const contentType = (
            <Dropdown
                label={t('Тип контента')}
                selectedKey={segmentParams.content_type ? segmentParams.content_type : undefined}
                onChange={_onContentTypeChange}
                placeholder="Select an option"
                options={contentTypeOptions}
            />
        );

        const clientType = (
            <Dropdown
                label={t('Тип клиентов')}
                selectedKey={segmentParams.client_type ? segmentParams.client_type : undefined}
                onChange={_onClientTypeChange}
                placeholder="Select an option"
                options={clientTypeOptions}
            />
        );

        const dates = (
            <Block>
                <DatePicker
                    // className={controlClass.control}
                    label={t('Дата начала')}
                    // isRequired={true}
                    allowTextInput={true}
                    // ariaLabel={desc}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    value={segmentParams.date_from}
                    onSelectDate={_onSelectDate('date_from')}
                    formatDate={_onFormatDate}
                    parseDateFromString={_onParseDateFromString('date_from')}
                    // maxDate={segmentParams.date_to}
                />
                <DatePicker
                    // className={controlClass.control}
                    label={t('Дата окончания')}
                    // isRequired={true}
                    allowTextInput={true}
                    // ariaLabel={desc}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    value={segmentParams.date_to}
                    onSelectDate={_onSelectDate('date_to')}
                    formatDate={_onFormatDate}
                    parseDateFromString={_onParseDateFromString('date_to')}
                    // minDate={segmentParams.date_from}
                />
            </Block>
        );

        const mailRuYandex = (
            <Block>
                <div>

                    <StCheckbox
                        label={t('Выгружать в Mail.ru')}
                        checked={segmentParams.mailru_needs_upload}
                        onChange={_onUploadChange('mailru_needs_upload')}
                    />
                    <StChoiceGroup selectedKey={mailru_is_public} options={optionsMailRu} label={t('Тип сегмента')} onChange={onIsPublicChange}
                                   styles={stylesChoiceGroupMail}
                                   disabled={!segmentParams.mailru_needs_upload}
                    />
                    <TextField
                        label={t('Аккаунты Mail.ru')}
                        // value={segmentParams.mailru_accounts.join(', ')}
                        value={valueMailRu}
                        onChange={_onChangeAccounts('mailru_accounts')}
                        multiline
                        disabled={mailru_is_public || !segmentParams.mailru_needs_upload}
                    />
                </div>
                <div>
                    <StCheckbox
                        label={t('Выгружать в Yandex')}
                        checked={segmentParams.yandex_needs_upload}
                        onChange={_onUploadChange('yandex_needs_upload')}
                    />
                    <TextField
                        label={t('Аккаунты Yandex')}
                        value={valueYandexRu}
                        onChange={_onChangeAccounts('yandex_accounts')}
                        multiline
                        disabled={!segmentParams.yandex_needs_upload}
                    />
                </div>
            </Block>
        );

        const description = (
            <TextField
                label={t('Описание')}
                value={segmentParams.description}
                onChange={_onChange('description')}
                multiline
                placeholder={t('Для ваших заметок')}
            />
        );

        // console.log(
        //     reportingObjectsByPlId[Object.keys(reportingObjectsByPlId).filter((key) => reportingObjectsByPlId[key].pl_name === 'Demo Mall')[0]]
        // );

        const onLocationClick = (plId) => () => {
            const selectedLocation = { ...reportingObjectsByPlId[plId] };
            set_selectedLocation(selectedLocation);
            set_selectedZone(null);
            set_filter({ ...filter, objects: '' });
        };

        const onZoneClick = (args) => () => {
            const selectedZone =
                args.zone === 'main_sp'
                    ? { zone: args.zone, id: args.selectedLocation.main_servicepoint.id }
                    : { objects: args.selectedLocation.objects[args.zone], zone: args.zone };
            set_selectedZone(selectedZone);
            set_filter({ ...filter, objects: '' });
        };

        const onObjectClick = (id) => () => {
            const selectedIds = [...segmentParams.obj_ids];
            if (selectedIds.includes(id)) {
                const newIds = segmentParams.obj_ids.filter((item) => item !== id);
                setSegmentParams({ ...segmentParams, obj_ids: newIds });
            } else {
                const newIds = cloneDeep(segmentParams.obj_ids);
                newIds.unshift(id);
                setSegmentParams({ ...segmentParams, obj_ids: newIds });
            }
        };

        const onClearAllClick = () => {
            setSegmentParams({ ...segmentParams, obj_ids: [] });
            setValueIdList('')
            setUndefinedListIds([])
            setAfterTimerStatus(true);
            setTimeout(() => {
                setAfterTimerStatus(false);
            }, 10)
        };

        const locations = useMemo(() => {

            let arrayTemp = []

            let componentNode = Object.keys(reportingObjectsByPlId)
                .filter((key) => {
                    if (filter.locations === '') {
                        return true;
                    } else {
                        return reportingObjectsByPlId[key].pl_name.toLowerCase().includes(filter.locations.toLowerCase());
                    }
                })
                .map((key, i) => {
                    const name = reportingObjectsByPlId[key].pl_name;
                    const selected = selectedLocation ? selectedLocation.pl_id === Number(key) : false;
                    if (filter.locations !== '' && filter.locations !== ' ') {
                        arrayTemp.push(reportingObjectsByPlId[key].main_servicepoint.id)
                    }

                    return (
                        <ListItem key={`${key.key_name}+__+${i}`} selected={selected} bgColor={'#ddd'} onClick={onLocationClick(key)}>
                            {name}
                        </ListItem>
                    );
                });

            setArrayObjectsfilter(arrayTemp);


            return componentNode
        }, [filter, selectedLocation]);

        const zones = () => {
            if (!selectedLocation) return null;
            const result = Object.keys(selectedLocation.objects).map((key, i) => {
                const name = key;
                const selected = selectedLocation && selectedZone ? selectedZone.zone === key : false;
                return (
                    <ListItem
                        key={`${key}))+__+${i}`}
                        selected={selected}
                        bgColor={'#ddd'}
                        onClick={onZoneClick({ zone: key, selectedLocation })}
                    >
                        {name}
                    </ListItem>
                );
            });
            const selected = selectedLocation && selectedZone ? selectedZone.zone === 'main_sp' : false;
            const name = t('Весь объект');
            const mainServicePoint = (
                <ListItem
                    key={selectedLocation.main_servicepoint.id}
                    selected={selected}
                    bgColor={'#ddd'}
                    onClick={onZoneClick({ zone: 'main_sp', selectedLocation })}
                >
                    {name}
                </ListItem>
            );
            result.unshift(mainServicePoint);
            return result;
        };

        const objects = () => {
            if (!selectedLocation || !selectedZone) {
                return null;
            } else if (selectedZone.zone === 'main_sp') {
                const selected =
                    selectedLocation && selectedZone && segmentParams.obj_ids ? segmentParams.obj_ids.includes(selectedZone.id) : false;
                return (
                    <ListItem
                        key={selectedZone.id + 'UU'}
                        selected={selected}
                        bgColor={`${theme.palette.themePrimary}`}
                        onClick={onObjectClick(selectedZone.id)}
                    >
                        {t('Весь объект')}
                    </ListItem>
                );
            } else {
                return selectedZone.objects
                    .filter((object) => {
                        if (filter.objects === '') {
                            return true;
                        } else {
                            return object.name.toLowerCase().includes(filter.objects.toLowerCase());
                        }
                    })
                    .map((object, i) => {
                        const name = object.name;
                        const selected =
                            selectedLocation && selectedZone && segmentParams.obj_ids
                                ? segmentParams.obj_ids.includes(Number(object.id))
                                : false;
                        return (
                            <ListItem
                                key={`${object.name}))+__+${i}`}
                                selected={selected}
                                bgColor={`${theme.palette.themePrimary}`}
                                onClick={onObjectClick(object.id)}
                            >
                                {name}
                            </ListItem>
                        );
                    });
            }
        };

        const getIconName = (type) => {
            switch (type) {
                case 'floor':
                    return 'Tiles2';
                case 'main_servicepoint':
                    return 'HomeVerify';
                case 'zone':
                    return 'WebAppBuilderFragment';
                case 'servicepoint':
                    return 'ReminderGroup';
                default:
                    return 'Settings';
            }
        };


        let selectedObjectsList = []
        if (segmentParams.obj_ids) {
            selectedObjectsList = segmentParams.obj_ids.map((id, i) => {
                const { pl_name } = reportingObjectsByPlId[reportingObjectsById[id].pl_id];
                const objName = reportingObjectsById[id].obj_type === 'main_servicepoint' ? t('Весь объект') : reportingObjectsById[id].name;
                const name = `${id}: ${objName}: ${pl_name}`;
                const iconName = getIconName(reportingObjectsById[id].obj_type);
                return (
                    <StTooltipHost
                        key={`${id}))+87__+${i}`}
                        content={t('Клик для удаления из списка')}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <ListItem selected={true} bgColor={`${theme.palette.themePrimary}`} onClick={onObjectClick(id)}>
                            <ChartIcon iconName={iconName} />
                            {name}
                        </ListItem>
                    </StTooltipHost>
                );
            });
        }


        const onShowExplorerClick = () => {
            set_showExplorer(true);
        };

        const onChangeToggle = (ev, checked) => {
            setIsCheckedIdsList(checked)
        }

        const onChangeListIds = (e, newValue) => {
            let valueTemp = '';

            valueTemp = cloneDeep(newValue)
            const regexp1 = /([0-9a-zA-Z]{1,})/g
            // const regexp1 = /[]/g
            const regexp2 = /([0-9][^a-zA-Z,]{1,})/g
            // const regexp2 = /[]/g
            const arrayIsCorrect = valueTemp.match(regexp1);

            // console.log('>>>>>>>>>>>>>>>', newValue)
            const newIds = [];
            // setSegmentParams({ ...segmentParams, obj_ids: newIds });

            let objCompare = {}
            let objUndefined = {}
            let arrayUndefideIds = []
            if (arrayIsCorrect) {
                const stringTemp = arrayIsCorrect.join(',')
                const arrayIds = stringTemp.match(regexp2)
                if (!arrayIds) {
                    return;
                }
                arrayIds.forEach((item, index) => {
                    if (!objCompare[item]) {
                        if (!reportingObjectsById[item]) {
                            if (!objUndefined[item]) {
                                arrayUndefideIds.push(item);
                                objUndefined[item] = item;
                            }

                        }
                        else {
                            newIds.push(item);
                        }
                        objCompare[item] = reportingObjectsById[item];
                    }

                })
            }

            // console.log('>>>>>>>>>>>>>>>', reportingObjectsById)

            setSegmentParams({ ...segmentParams, obj_ids: newIds });
            setValueIdList(newValue);
            setUndefinedListIds(arrayUndefideIds);

        }

        const stackTokensToggle = { childrenGap: 10 };

        const onClickSelectAll = () => {
            setSegmentParams({ ...segmentParams, obj_ids: arrayObjectsfilter });
        }


        const ExplorerComponent = () => (
            <ExplorerContainer>
                <Explorer>
                    <ColWrapperFirst
                        height={heightTexterea}
                    >
                        <ToggleBox>
                            <Stack horizontal={true} tokens={stackTokensToggle} >
                                <Toggle
                                    label={
                                        <div>
                                            {t('По списку ID')}
                                            {/*<TooltipHost content="Info tooltip">*/}
                                            {/*    <Icon iconName="Info" aria-label="Info tooltip" />*/}
                                            {/*</TooltipHost>*/}
                                        </div>
                                    }
                                    inlineLabel
                                    // defaultChecked
                                    onText=" "
                                    offText=" "
                                    checked={isCheckedIdsList}
                                    onChange={onChangeToggle}
                                />
                            </Stack>

                        </ToggleBox>
                        {isCheckedIdsList ?
                            <BoxColWrapper>
                                {!afterTimerStatus ? <StTexterea
                                    height={heightTexterea}
                                    // label={t('Аккаунты Mail.ru')}
                                    // value={valueIdList}
                                    defaultValue={valueIdList}
                                    onChange={onChangeListIds}
                                    multiline
                                    // disabled={mailru_is_public || !segmentParams.mailru_needs_upload}
                                /> : null}
                            </BoxColWrapper>
                            :
                            <BoxColWrappers>
                            <ItemColWrapper>
                                <Label>{t('Локации')} </Label>
                                <StTextField
                                    isRequired={true}
                                    value={filter.locations}
                                    onChange={_onChangeFilter('locations')}
                                    placeholder={t('Поиск по имени локации')}
                                />
                                {arrayObjectsfilter.length > 0 ? <StLabel2 disabled={false} color={`${theme.palette.themeDark}`}
                                           onClick={onClickSelectAll}>
                                    {`${t('Выбрать все')}`}
                                </StLabel2> : null}
                                <ListWrapper>
                                    <List>{locations}</List>
                                </ListWrapper>
                            </ItemColWrapper>
                            <ItemColWrapper>
                                <Label>{t('Группы объектов')} </Label>
                                <Gag />
                                <ListWrapper>
                                    <List>{zones()}</List>
                                </ListWrapper>
                            </ItemColWrapper>
                            <ItemColWrapper>
                                <Label>{t('Объекты')} </Label>
                                <StTextField
                                    isRequired={true}
                                    value={filter.objects}
                                    onChange={_onChangeFilter('objects')}
                                    placeholder={t('Поиск по имени объекта')}
                                />
                                <ListWrapper>
                                    <List>{objects()}</List>
                                </ListWrapper>
                            </ItemColWrapper>
                        </BoxColWrappers>}


                    </ColWrapperFirst>
                    <ColWrapper id={'ColWrapper'} border>
                        <LabelClearWrapper>
                            <Label>{`${t('Выбранные объекты')}(${segmentParams.obj_ids.length})`} </Label>
                            <StLabel disabled={false} color={`${theme.palette.themeDark}`} onClick={onClearAllClick}>
                                {`${t('Очистить')}`}
                            </StLabel>
                        </LabelClearWrapper>
                        <ListWrapper selectedObjectsList>
                            <List bgColor={theme.palette.themePrimary} >
                                {selectedObjectsList}
                            </List>
                        </ListWrapper>
                    </ColWrapper>
                </Explorer>
            </ExplorerContainer>
        );

        const MainComponent = () => {
            const selectedObjNumber = t('Выбрано объектов') + `: ${segmentParams.obj_ids ? segmentParams.obj_ids.length : 0}`;


            return (
                <MainContainer newSegmentMode={newSegmentMode}>
                    <StChoiceGroupBox>
                        {!editData && <StChoiceGroup selectedKey={newSegmentMode} options={options} onChange={onModeChange} />}
                    </StChoiceGroupBox>


                    {newSegmentMode === 'from_file' ? (
                        <FromFile>
                            <React.Fragment>
                                <LabelWrapper>
                                    <Label>{t('Файл сегмента')} </Label>
                                    <StTooltipHost
                                        tooltipProps={tooltipProps}
                                        delay={TooltipDelay.medium}
                                        directionalHint={DirectionalHint.bottomCenter}
                                    >
                                        <StIconButton iconProps={infoIcon} onClick={onInfoIconClick} />
                                    </StTooltipHost>
                                </LabelWrapper>
                                <input
                                    accept="text/txt, .txt"
                                    onChange={_onFileChange}
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <LabelWrapper>
                                    <FileLabel className="FileLabel" htmlFor="raised-button-file">
                                        <ChooseFile
                                            component="span"
                                            bgColor={theme.palette.themePrimary}
                                            hoverBgColor={theme.palette.themeDark}
                                        >
                                            {t('Прикрепить файл')}
                                        </ChooseFile>
                                    </FileLabel>
                                    <FileName>{segmentParams.file && segmentParams.file.name}</FileName>
                                </LabelWrapper>
                            </React.Fragment>

                            <Block>
                                {segmentName}
                                {contentType}
                            </Block>
                            {dates}
                            {mailRuYandex}
                            {description}
                        </FromFile>
                    ) : (
                        <FromObjIds>
                            <React.Fragment>
                                <Label>{selectedObjNumber}</Label>
                                <LabelWrapper>
                                    <PrimaryButton text={t('Выбрать объекты')} width={'100px'} onClick={onShowExplorerClick} />
                                </LabelWrapper>
                            </React.Fragment>
                            <Params>
                                <Block>
                                    {segmentName}
                                    {clientType}
                                </Block>
                                {dates}
                                {mailRuYandex}
                                {description}
                            </Params>
                        </FromObjIds>
                    )}
                </MainContainer>
            );
        };

        const stackTokens = { childrenGap: 10 };

        const onModalButtonClick = (action, params) => (e) => {
            if (!action) {
                props.onModalButtonClick({ name: action }, params);
                return;
            }
            switch (newSegmentMode) {
                case 'from_file':
                    delete params.client_type;
                    delete params.obj_ids;
                    delete params.id;
                    params.mode = newSegmentMode;
                    console.log('><><>>>><><>', params)
                    params.url = '/segment_api/create_or_update_segment_with_content/';
                    props.onModalButtonClick({ name: action }, params);
                    break;
                case 'from_obj_ids':
                    delete params.content_type;
                    delete params.file;
                    if (params.id === null) {
                        delete params.id;
                    }
                    params.mode = newSegmentMode;
                    params.url = '/segment_api/create_or_update_segment_with_location_filter/';
                    props.onModalButtonClick({ name: action }, params);
                    break;

                default:
                    break;
            }
        };

        const primaryDisabled = () => {
            let result = true;
            if (segmentParams.name.length >= 5) {
                if (newSegmentMode === 'from_file') {
                    if (segmentParams.file) {
                        result = false;
                    }
                } else if (newSegmentMode === 'from_obj_ids') {
                    if (segmentParams.obj_ids.length > 0) {
                        result = false;
                    }
                }
            }
            setStatusDisable(result)
        };

        const getStatusText = () => {
            let status1 = false;
            let status2 = false;
            let status3 = false;
            if (segmentParams.mailru_needs_upload) {
                if (!mailru_is_public) {
                    status1 = statusDisableButtonOk || valueMailRu === '';
                }
                else {
                    status1 = false;
                }
            }
            if (segmentParams.yandex_needs_upload) {
                status2 = statusDisableButtonOkByYandex || valueYandexRu === '';
            }


            status3 = +moment(segmentParams.date_from) > +moment(segmentParams.date_to);
            console.log('>>>>>>>>>>>>>', status3)
            return status1 || status2 || status3;

            // return ((!mailru_is_public && adCampParams.mailru_needs_upload) && valueMailRu === '') ||
            //     (adCampParams.yandex_needs_upload && valueYandexRu === '')
        }




        return (
            <Wrapper>
                {showExplorer ? (
                    <Title title={t('Выбор объектов')} />
                ) : editData ? (
                    <Title title={t('Редактирование сегмента')} />
                ) : (
                    <Title title={t('Создание нового сегмента')} />
                )}
                {showExplorer ? ExplorerComponent() : MainComponent()}
                <Footer>
                    {!showExplorer ? (
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                text={'OK'}
                                width={'100px'}
                                disabled={ segmentParams.name === '' || getStatusText()}
                                onClick={onModalButtonClick('createNewSegment', segmentParams)}
                            />
                            <DefaultButton text={t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                        </Stack>
                    ) : (
                        <Stack horizontal tokens={stackTokens}>
                            {undefinedListIds.length > 0 ? <StBoxTextWarning>
                                <StTextWarning>
                                    {`${t('Идентификаторы')} ${undefinedListIds.join(', ')} ${t('не найдены')}.`}
                                </StTextWarning>
                            </StBoxTextWarning> : null}
                            <PrimaryButton text={'OK'} width={'100px'} onClick={() => set_showExplorer(false)} />
                        </Stack>
                    )}
                </Footer>
            </Wrapper>
        );
    }
);

export default NewSegmentModal;

const ChartIcon = styled(Icon)`
    flex-shrink: 0;
    font-size: 14px;
    margin-right: 5px;
`;

const Gag = styled.div`
    height: 51px;
    width: 100%;
    margin-bottom: 10px;
`;

const StLabel = styled(Label)`
    background-color: inherit;
    box-sizing: border-box;
    color: ${(p) => p.color};
    /* padding: 10px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    margin-left: 5px;
    display: flex;
    align-items: flex-end;
    :focus {
        outline: none;
    }
`;

const StLabel2 = styled(Label)`
    background-color: inherit;
    box-sizing: border-box;
    color: ${(p) => p.color};
    /* padding: 10px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    margin-left: 5px;
    position: absolute;
    top: 64px;
    right: 8px;
    //display: flex;
    //align-items: flex-end;
    :focus {
        outline: none;
    }
`;

const LabelClearWrapper = styled.div`
    display: flex;
    label {
    margin-top: 9px !important;
    }
`;
const ExplorerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    margin-bottom: 5px;
`;
const MainContainer = styled.div`
    display: flex;
    width: 100%;
    /* height: ${(p) => (p.newSegmentMode === 'from_file' ? 'auto' : '80vh')}; */
    /* flex-grow: 3; */
    overflow: auto;
    //padding-bottom: 20px;
    box-sizing: border-box;
`;

const StChoiceGroup = styled(ChoiceGroup)`
    padding-top: 10px;
  
`;

const StChoiceGroupBox = styled.div`
    display: block;
    width: 200px;
    padding-left: 10px;
    box-sizing: border-box;
`;

const FromFile = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    padding-right: 10px;
    padding-bottom: 5px;
`;

const FromObjIds = styled.div`
      display: flex;
      width: 100%;
      flex-direction: column;
      overflow: auto;
    padding-right: 10px;
`;

const Explorer = styled.div`
    flex-grow: 1;
    //height: 100%;
    position: relative;
    /* cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')}; */
    /* pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')}; */
    /* padding: 5px; */
    /* background-color: ${(p) => (p.selected ? p.bgColor : 'transparent')}; */
    /* color: ${(p) => (p.disabled ? '#ccc' : p.selected ? '#fff' : '#6a7074')}; */
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-template-rows: 100%;
    grid-gap: 5px;
    overflow: hidden;
      
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
    /* background-color: rgba(200,0,0,0.2); */
`;

const Params = styled.div`
    /* height: 40%; */
    /* flex-grow: 3; */
    flex-shrink: 0;
    margin-top: auto;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5px;
`;

const ColWrapper = styled.div`
    position: relative;
    //overflow: hidden;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: ${(p) => (p.border ? '20px' : '0')};
    padding-top: ${(p) => (p.border ? '5px' : '0')};
    margin-top: ${(p) => (p.border ? '20px' : '0')};
    border: ${(p) => (p.border ? '1px solid rgba(0, 0, 0, 0.3)' : 'none')};
`;

const ColWrapperFirst = styled.div`
    //position: relative;
    //overflow: hidden;
    /* height: 100%; */
    display: grid;
    grid-template-rows: 30px ${p => p.height}px;
    
`;

const BoxColWrappers = styled.div`
    //position: relative;
    //overflow: hidden;
    /* height: 100%; */
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: 100%;
    // padding: ${(p) => (p.border ? '20px' : '0')};
    // padding-top: ${(p) => (p.border ? '5px' : '0')};
    // margin-top: ${(p) => (p.border ? '20px' : '0')};
    // border: ${(p) => (p.border ? '1px solid rgba(0, 0, 0, 0.3)' : 'none')};
`;

const BoxColWrapper = styled.div`
    //position: relative;
    //overflow: hidden;
    /* height: 100%; */
    display: grid;
    grid-template-columns: 5fr;
    grid-template-rows: 100%;
    // padding: ${(p) => (p.border ? '20px' : '0')};
    // padding-top: ${(p) => (p.border ? '5px' : '0')};
    // margin-top: ${(p) => (p.border ? '20px' : '0')};
    // border: ${(p) => (p.border ? '1px solid rgba(0, 0, 0, 0.3)' : 'none')};
`;

const ItemColWrapper = styled.div`
    position: relative;
    //overflow: hidden;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: ${(p) => (p.border ? '20px' : '0')};
    padding-top: ${(p) => (p.border ? '5px' : '0')};
    margin-top: ${(p) => (p.border ? '20px' : '0')};
    border: ${(p) => (p.border ? '1px solid rgba(0, 0, 0, 0.3)' : 'none')};
`;
const ListWrapper = styled.div`
    position: relative;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    border-left: ${(p) => (p.selectedObjectsList ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
`;
const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    /* border-right: 1px solid rgba(0, 0, 0, 0.3); */
    overflow: auto;
    color: #fff;
`;

// const Locations = styled.ul`
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     border-right: 1px solid rgba(0, 0, 0, 0.3);
//     overflow: auto;
// `;

const ListItem = styled.li`
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    padding: 5px;
    margin-bottom: 2px;
    margin-top: 2px;
    background-color: ${(p) => (p.selected ? p.bgColor : 'transparent')};
    color: ${(p) => (p.disabled ? '#ccc' : p.selected && p.bgColor !== '#ddd' ? '#fff' : '#6a7074')};
    display: flex;
    align-items: center;
    :hover {
        background-color: ${(p) => (p.selected ? p.bgColor : '#eee')};
    }
`;

const NameWrapper = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const StIconButton = styled(IconButton)`
    margin-top: 10px;
    margin-left: 5px;
    i {
        font-size: 16px;
    }
`;
const StTooltipHost = styled(TooltipHost)`
    max-width: 80vw !important;
    div {
        max-width: 80vw !important;
    }
`;
const StTextField = styled(TextField)`
    padding: 5px;
    margin-bottom: 10px;
    /* label: {
        margin-top: 0px !important;
        padding-top: 0px !important;
    } */
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;
const FileLabel = styled.label`
    display: flex;
    align-items: center;
    flex-grow: 0;
    /* width: 100%; */
`;

const ToggleBox = styled.div`
  label {
    margin-top: 0px !important;
  }

  .ms-Toggle {
    margin-bottom: 0px;
  }
  /* width: 100%; */
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 3;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label:not(.FileLabel) {
        margin-top: 5px;
    }
`;
const Block = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    /* border-bottom: 1px solid rgb(244, 244, 244);
    padding-bottom: 10px;
    margin-bottom: 10px; */
`;

const FileName = styled.span`
    margin-left: 10px;
`;
const ChooseFile = styled.div`
    //-webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    //background: transparent;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    //-webkit-appearance: button;
    text-overflow: ellipsis;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
    background-color: ${(p) => p.bgColor};
    color: rgb(255, 255, 255);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    font-family: inherit;
    flex-shrink: 1;
    :hover {
        background-color: ${(p) => p.hoverBgColor};
    }
`;
const StCheckbox = styled(Checkbox)`
    margin-top: 5px !important;
`;


const StBoxTextWarning = styled.div`
    overflow: auto;
    height: 32px;

    //border: 1px solid darkorange;
    //border-radius: 5px;
    bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    display: grid;
    align-items: center;
    //background: rgba(255,255,255,.5);
`;

const StTextWarning = styled.span`
    color: red;
`;


const StTexterea = styled(TextField)`
    .ms-TextField-field {
       height: ${p => p.height}px;
      //height: 100%;
    }
    
`;
