import React from 'react';
import DetailInfoBox from "./ditailInfoReport";
import styled from "styled-components/macro";

const RightSideWrapper = (props) => {
    return (
        <SCRightSideWrapper>
            <DetailInfoBox
                pdfReports={props.pdfReports}
                hostExport={props.hostExport}
                status={props.status}
                statusLeftPanel={props.statusLeftPanel}
                sectionsByKeyName={props.sectionsByKeyName}
                onClickButtonDeletePdfReport={props.onClickButtonDeletePdfReport}
            />
        </SCRightSideWrapper>
    );
};

export default RightSideWrapper;

const SCRightSideWrapper = styled.div`
    padding: 10px;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    display: grid;
    //align-items: center;
`;
