import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";
import store from "./store";
import moment from "moment/moment";
import { DateTime } from "luxon";


const BoxTooltip = (props) => {
    const {
        range,
        weekIdx
    } = props

    const [list, setList] = useState([])


    useEffect(() => {
        setList(store.getState().schedulesList)
        ReactTooltip.rebuild()
    }, [store.getState().schedulesList])

    const processDate = (date) => {
        let schedulesList = store.getState().schedulesList;
        let arrayScedules = []

        for (var i = 0; i < schedulesList.length; i++) {
            if (schedulesList[i].week_days.length === 7 && schedulesList[i].date_period.always === true) {
                arrayScedules.push(schedulesList[i])
            }
            else if (schedulesList[i].date_period.always === true && schedulesList[i].week_days.length < 7) {
                let arrayDayOfWeekNumber = []
                let objDayOfWeek = {
                    Monday:1,
                    Tuesday:2,
                    Wednesday:3,
                    Thursday:4,
                    Friday:5,
                    Saturday:6,
                    Sunday:0
                }
                for (var j = 0; j < schedulesList[i].week_days.length; j++) {
                    arrayDayOfWeekNumber.push(objDayOfWeek[schedulesList[i].week_days[j]])
                }
                // console.log('>>>>>>>>>>.', moment(date).day(), date, moment().day(schedulesList[i].week_days[j]), schedulesList[i].week_days[j])
                if (arrayDayOfWeekNumber.indexOf(moment(date).day()) !== -1) {
                    arrayScedules.push(schedulesList[i])
                }
            }
            else if (schedulesList[i].date_period.always === false && schedulesList[i].week_days.length === 7) {

                if (DateTime.fromFormat(schedulesList[i].date_period.to, 'yyyy.MM.dd').toMillis() >= moment(date).valueOf() && moment(date).valueOf() && DateTime.fromFormat(schedulesList[i].date_period.from, 'yyyy.MM.dd').toMillis() <= moment(date).valueOf()) {
                    arrayScedules.push(schedulesList[i])
                }
            }
            else if (schedulesList[i].date_period.always === false && schedulesList[i].week_days.length < 7) {
                let arrayDayOfWeekNumber = []
                let objDayOfWeek = {
                    Monday:1,
                    Tuesday:2,
                    Wednesday:3,
                    Thursday:4,
                    Friday:5,
                    Saturday:6,
                    Sunday:0
                }
                for (var j = 0; j < schedulesList[i].week_days.length; j++) {
                    arrayDayOfWeekNumber.push(objDayOfWeek[schedulesList[i].week_days[j]])
                }
                // console.log('>>>>>>>>>>.', moment(date).day(), date, moment().day(schedulesList[i].week_days[j]), schedulesList[i].week_days[j])
                if (arrayDayOfWeekNumber.indexOf(moment(date).day()) !== -1) {
                    if (DateTime.fromFormat(schedulesList[i].date_period.to, 'yyyy.MM.dd').toMillis() >= moment(date).valueOf() && DateTime.fromFormat(schedulesList[i].date_period.from, 'yyyy.MM.dd').toMillis() <= moment(date).valueOf()) {
                        arrayScedules.push(schedulesList[i])
                    }
                }
            }
        }

        return arrayScedules;


    }

    const createTultip = (defaultPage, scheduleList,dataSchedulesLine) => {
        let arrayTemp = new Array(288);
        arrayTemp.fill(defaultPage.id)
        let objTemp = {}
        objTemp[defaultPage.id] = defaultPage
        // console.log('>>>>>>>>>>.',scheduleList,dataSchedulesLine)
        if (scheduleList.length > 0) {
            for (var j = 0; j < dataSchedulesLine.length; j++) {
                objTemp[dataSchedulesLine[j].id] = dataSchedulesLine[j]
                if (dataSchedulesLine[j].time_period.always === true) {
                    arrayTemp.fill(dataSchedulesLine[j].id)
                }
                else {
                    if (dataSchedulesLine[j].time_period.from !== null && dataSchedulesLine[j].time_period.to !== null) {
                        let hoursFrom = +dataSchedulesLine[j].time_period.from.split(':')[0]
                        let minutsFrom = +dataSchedulesLine[j].time_period.from.split(':')[1]
                        let hoursTo = +dataSchedulesLine[j].time_period.to.split(':')[0]
                        let minutsTo = +dataSchedulesLine[j].time_period.to.split(':')[1]
                        let formLine = hoursFrom * 12 + minutsFrom
                        let toLine = hoursTo * 12 + minutsTo
                        for (var t = formLine; t < toLine; t++) {
                            arrayTemp[t] = dataSchedulesLine[j].id
                        }
                    }
                    else {
                        arrayTemp.fill(dataSchedulesLine[j].id)
                    }


                    // arrayTemp.fill(dataSchedulesLine[j].id)
                    // console.log('>>>>>>>>>>.',hoursFrom * 12 + minutsFrom,hoursTo * 12 + minutsTo)
                }
            }
        }
        let arrayTemp2 = []
        for (var i = 0; i < arrayTemp.length; i++) {
            if (i === 0) {
                arrayTemp2.push({
                    data: objTemp[arrayTemp[i]],
                    timeTo: '',
                    timeFrom: '00:00'
                })
            }
            else if (arrayTemp[i] === arrayTemp[i - 1]) {
                let time = getTime(i);

                arrayTemp2[arrayTemp2.length - 1].timeTo = correctDayTimeToStr(time.hours,time.minuts + 5)
            }
            else if (arrayTemp[i] !== arrayTemp[i - 1]) {
                let time = getTime(i);

                arrayTemp2.push({
                    data: objTemp[arrayTemp[i]],
                    timeTo: correctDayTimeToStr(time.hours,time.minuts + 5),
                    timeFrom: correctDayTimeToStr(time.hours, time.minuts)
                })
            }
        }
        // console.log('>>>>>>>>>>.', arrayTemp2)
        return arrayTemp2;
    }

    const correctDayTimeToStr = (hours, minuts) => {
        let totalMinuts = hours * 60 + minuts;
        let newHours = Math.floor(totalMinuts / 60);
        let newMinuts = totalMinuts - newHours * 60;
        return `${newHours >= 10 ? newHours : `0${newHours}`}:${newMinuts >= 10 ? newMinuts : `0${newMinuts}`}`;
    }

    const getTime = (i) => {
        let time = i * 5;
        let currentTime = {};
        if (time < 60) {
            currentTime['minuts'] = time;
            currentTime['hours'] = 0

        }
        else {
            currentTime['minuts'] = time % 60;
            currentTime['hours'] = Math.floor(time / 60)
        }
        // console.log('>>>>>>>>>>>>>>',currentTime )
        return currentTime;
    }

    let componentNode = range.map((date, index) => {
        let dataSchedulesLine = processDate(date)
        let dataTultip = createTultip(store.getState().defaultTemplate,store.getState().schedulesList, dataSchedulesLine)

        let componentTultip = dataTultip.map((data2, index2) => {

            if (data2.data.preview !== undefined) {
                data2.data['page_preview'] = data2.data.preview

                data2.data['page_title'] = data2.data.title
            }
            return(
                <DivItemTultipSC key={`ItemTultip${index2}`} className="itemTultip">
                                <span style={{
                                    background: data2.data.color,
                                    fontSize:'15px'
                                }} className="nameTultip">
                                    {data2.data.page_title !== undefined ? data2.data.page_title.length > 10 ? data2.data.page_title.substring(0,5) + '...' + data2.data.page_title.substring(data2.data.page_title.length - 4,data2.data.page_title.length)  : data2.data.page_title : null}
                                </span>
                    <img src={data2.data.page_preview} alt=""/>

                    <span className="time">
                                    {`${data2.timeFrom}  ${data2.timeTo}`}
                                </span>
                </DivItemTultipSC>
            )
        })
        // ReactTooltip.rebuild()

        return (
            <>
                <ReactTooltip
                    id={`global${index}${weekIdx}`}
                    aria-haspopup="true"
                    className="reacttooltip"
                    // role="example"
                    // effect="solid"
                    place="left"
                >
                    <DivBoxTultip
                        className="boxTultip">
                        {componentTultip}
                    </DivBoxTultip>
                </ReactTooltip>
            </>
        )
    })

    return (
        <DivBoxTooltipSC>
            {
                componentNode
            }
        </DivBoxTooltipSC>
    );
};

export default BoxTooltip;

const DivBoxTooltipSC = styled.div`
    position: absolute;
    bottom: 0;
  right: 0;
    `

const DivBoxTultip = styled.div`
    background: #000;
  display: flex;
    height: 200px;
  width: fit-content;
  //grid-template-columns: repeat(5, 100px);
  //grid-template-rows: 100%;
`

const DivItemTultipSC = styled.div`
  width: 100px;
  height: 100%;
  padding: 2px;
  background: #000;
  img {
    width: 100%;
  }
  .nameTultip {
    display: flex;
    width: 100%;
    text-align: center;
    color: #fff;
    height: 20px;
    align-items: center;
    justify-content: center;
  }
  .time {
    color: #fff;
    font-size: 12px;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
`