import React from 'react';
import styled from 'styled-components/macro';

export default React.memo(({ title, lang, keyName }) => {
    const height = keyName === 'comparison_score' || keyName === 'score' ? 48 : 34;
    return (
        <Container height={height}>
            <Title>{lang === 'ru' ? title.translations['ru'].name : title.translations['en'].name}</Title>
        </Container>
    );
});

const Container = styled.div`
    height: ${p => p.height + 'px'};
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    /* padding-top: 5px; */
`;

const Title = styled.h4`
    font-family: 'ProximaNova';
    text-align: center;
    color: #6a7074;
    margin: 0px;
    font-size: 15px;
    line-height: 110%;
    font-weight: 400;
`;
