import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { getTheme, mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { TooltipHost, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';

export default React.memo(({ infoText, ...props }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // console.log(infoText);
        if (infoText) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [infoText]);

    const iconClass = mergeStyles({
        fontSize: 17,
        display: 'block',
        cursor: 'pointer'
    });

    const classNames = mergeStyleSets({
        // primary: [{ color: `${theme.palette.themePrimary}` }, iconClass],
        greenYellow: [{ color: 'greenyellow' }, iconClass],
        black: [{ color: 'black' }, iconClass],
        salmon: [{ color: 'salmon' }, iconClass]
    });

    return (
        <InfoIcon show={show}>
            <TooltipHost content={infoText}>
                <Icon iconName="Info" className={classNames.black} />
            </TooltipHost>
        </InfoIcon>
    );
});

const opacity = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const InfoIcon = styled.div`
    position: absolute;
    width: 20px;
    height: inherit;
    display: ${p => (p.show ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    right: 25px;
    top: 0px;
    animation: ${opacity} 1s ease-in-out infinite;
`;
