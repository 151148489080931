import _t from 'i18next';

import { getColorFromCatalog } from './diagramTools';
import moment from 'moment';

export const getInfoWithDataAspects = (data, report, inputParamsValues, shift, colors = undefined) => {
    const hideComparison = report && report.meta && report.meta.data ? report.meta.data.hide_comparison : false;

    const allNames = data.map((item) => {
        if (item.data_aspects) {
            return item.data_aspects.object.relation === 'detalization' ? item.data_aspects.object.associations[0].name : '';
        } else {
            return '';
        }
    });

    const isAggregation = data[0] && data[0].data_aspects.object.relation === 'aggregation';

    let newData = [...data];

    const arrWeekDays = (arr) =>
        arr.map((item, i) => {
            return {
                i,
                date: item,
                dayOfWeek: moment(item).day(),
            };
        });
    const timeScale = {
        daily: { momentString: 'days', multiplier: 7, add: 0 },
        hourly: { momentString: 'hours', multiplier: 168, add: 23 },
        weekly: { momentString: 'weeks', multiplier: 1, add: 0 },
        monthly: { momentString: 'months', multiplier: 1, add: 0 },
        yearly: { momentString: 'years', multiplier: 1, add: 0 },
    };

    const longestTimeseries = [];
    let longestAxes = 'bottom';
    let period = null;
    let shortestLength = 1;
    let maxShiftsNumber = 1;

    let isShiftable =
        isAggregation &&
        data.length > 1 &&
        // data[0].data_aspects.time.timeseries.length !== data[1].data_aspects.time.timeseries.length &&
        inputParamsValues &&
        timeScale.hasOwnProperty(inputParamsValues.time_detalization);

    if (isShiftable) {
        period = timeScale[inputParamsValues.time_detalization];

        const sorted = data.sort((a, b) => {
            return b.data_aspects.time.timeseries.length - a.data_aspects.time.timeseries.length;
        });

        const [longest, shortest] = sorted;

        if (longest.data_aspects.time.date_range_type === 'comparison_date_range') {
            longestAxes = 'top';
        }

        const longestLength = longest.data_aspects.time.timeseries.length;
        if (shortest !== undefined) {
            shortestLength = shortest.data_aspects.time.timeseries.length;
        }

        const weekDaysOfLongest = arrWeekDays(longest.data_aspects.time.timeseries);
        const weekDaysOfShortest = arrWeekDays(shortest.data_aspects.time.timeseries);
        const daysOfLongest = weekDaysOfLongest.map((item) => item.dayOfWeek);
        const daysOfShortest = weekDaysOfShortest.map((item) => item.dayOfWeek);
        const firstDayOfLongest = daysOfLongest[0];
        const lastDayOfLongest = daysOfLongest[longestLength - 1];
        const firstDayOfShortest = daysOfShortest[0];
        const lastDayOfShortest = daysOfShortest[shortestLength - 1];

        const leftDiff =
            firstDayOfLongest !== firstDayOfShortest && daysOfShortest.includes(firstDayOfLongest)
                ? daysOfShortest.indexOf(firstDayOfLongest)
                : -1;
        const rightDiff =
            lastDayOfLongest !== lastDayOfShortest && daysOfShortest.includes(lastDayOfLongest)
                ? shortestLength - 1 - period.add - daysOfShortest.indexOf(lastDayOfLongest)
                : -1;

        longestTimeseries.push(...longest.data_aspects.time.timeseries);

        if (leftDiff > 0) {
            for (let i = 0; i < leftDiff; i++) {
                longestTimeseries.unshift(
                    moment(weekDaysOfLongest[0].date)
                        .subtract(i + 1, period.momentString)
                        .format('YYYY-MM-DD HH:mm:ss')
                );
            }
        }
        if (rightDiff > 0) {
            for (let i = 0; i < rightDiff; i++) {
                longestTimeseries.push(
                    moment(weekDaysOfLongest[longestLength - 1].date)
                        .add(i + 1, period.momentString)
                        .format('YYYY-MM-DD HH:mm:ss')
                );
            }
        }

        const longestValues = longestTimeseries.map((dateStr) => {
            const timeseries = longest.data_aspects.time.timeseries;
            if (timeseries.includes(dateStr)) {
                return longest.values[timeseries.indexOf(dateStr)];
            } else return null;
        });

        let indexes = [];
        longestTimeseries.forEach((item, i, arr) => {
            if (moment(item).day() === lastDayOfShortest && i > arr.length - (1 - shift) * period.multiplier) {
                indexes.push(i);
            }
        });

        let index = indexes[0] + period.add;

        const shortestTimeseries = [];

        if (period.momentString === 'weeks' || period.momentString === 'months' || period.momentString === 'years') {
            index = longestLength - 1;
            isShiftable = false;
        }

        longestTimeseries.forEach((item, i) => {
            if (i < index - shortestLength + 1 || i > index) {
                shortestTimeseries.push(
                    moment(weekDaysOfShortest[0].date)
                        .subtract(index - shortestLength + 1 - i, period.momentString)
                        .format('YYYY-MM-DD HH:mm:ss')
                );
            }
        });

        shortestTimeseries.splice(index - shortestLength + 1, 0, ...shortest.data_aspects.time.timeseries);

        // console.log('----', longestTimeseries);
        // console.log('----', shortestTimeseries);

        const shortestValues = shortestTimeseries.map((dateStr) => {
            const timeseries = shortest.data_aspects.time.timeseries;
            if (timeseries.includes(dateStr)) {
                return shortest.values[timeseries.indexOf(dateStr)];
            } else return null;
        });

        const longestData = {
            ...longest,
            values: longestValues,
            data_aspects: {
                ...longest.data_aspects,
                time: { ...longest.data_aspects.time, timeseries: longestTimeseries },
            },
        };

        const shortestData = {
            ...shortest,
            values: shortestValues,
            data_aspects: {
                ...shortest.data_aspects,
                time: { ...shortest.data_aspects.time, timeseries: shortestTimeseries },
            },
        };
        maxShiftsNumber = Math.floor((longestTimeseries.length - shortestLength) / period.multiplier) + 1;
        // Math.floor(longestTimeseries.length / period.multiplier) - Math.floor(shortestLength / period.multiplier) + 1;
        newData = [longestData, shortestData];
    } else {
        try {
            longestTimeseries.push(...data[0].data_aspects.time.timeseries);
        } catch (error) {}
    }

    const linesData = newData.map((item) => {
        let name = '';
        let color = '#000';
        let xAxisId = 'bottom';
        if (!item.data_aspects) {
            return {
                name: '------',
            };
        }
        const isMainRange = item.data_aspects.time.date_range_type === 'main_date_range';
        if (isAggregation && isMainRange) {
            name = 'Отчетный период';
            color = 'rgb(60, 100, 153)';
        } else if (isAggregation && !isMainRange) {
            name = 'Период сравнения';
            color = 'rgb(244, 155, 16)';
            xAxisId = 'top';
        } else if (colors) {
            name = item.data_aspects.object.associations[0].name;
            if (allNames.filter((item) => item === name).length > 1) {
                name = `${name} (id: ${item.data_aspects.object.associations[0].id})`;
            }
            color = getColorFromCatalog(item, colors);
        }

        const x = item.data_aspects.time.timeseries
            ? item.data_aspects.time.timeseries.map((x, i) => {
                  return {
                      x,
                      value: item.values[i] !== null ? Number(item.values[i].toFixed(5)) : null,
                  };
              })
            : [];

        return {
            name,
            xAxisId,
            show: name === 'Период сравнения' ? !hideComparison : true,
            color,
            dim: false,
            x,
        };
    });
    return {
        linesData,
        longestTimeseries,
        longestAxes,
        maxShiftsNumber,
        isShiftable,
        isAggregation
    };
};

export const getIdWithDataAspects = (data) => {
    let obj = {};
    data.data_aspects.object.associations.forEach((item) => {
        obj[item.source] = item;
    });
    return obj.shopster_analytics.id;
};
