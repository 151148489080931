import React, {useState, useEffect} from 'react';
import styled from "styled-components/macro";
import LineChartCustom from "./components/LineChart";
import BarChartCustom from "./components/BarChart";
import ScatterChartCustom from "./components/ScaterChart";
import BarHorizChartCustom from "./components/BarHorizChart";
import BarHorizCharCustomLable from "./components/BarHorizCharCustomLable";
import hostForLocation from "../../hostnames/hostname";
import {useDispatch, useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import {SpinnerContainer2, SpinnerContainerTemp} from "../../index";
import {useTranslation} from "react-i18next";
import {runAction} from "../../actions";
// import PreCampaigns from "../../reducers/Precampaing";

const PreCampaign = () => {
    const { t } = useTranslation();
    const [width, setWidth] = useState(0)
    const dispatch = useDispatch();
    const {
        sotsDetailedDynamics,
        arrayForsots,
        sumSots,
        arraySumSots,
        ratingsSots,
        frequencyHistList,
        accumulativeFrequencyHistList,
        sumMeanFrequency,
        countUniq,
        aggregatedSGRP,
        reachOverFrequency,
        reach,
        sGRPDetailedDynamicsList,
        aggregatedSSGRPDynamics,
        dateList,
        isLoadData,
        isLoadUniqData,
        aggregatedSSGRPDynamicsLine,
        sGRPDetailedDynamicsListLine,
        selectedOutdoorAdSpaces
    } = useSelector(
        (state) => state.PreCampaigns
    );
    const {
        colors,
        outdoorAdSpacesById
    } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );


    useEffect(() => {
        window.onresize = onResize
        dispatch(runAction('clearData_PreCampaigns', {}))
    }, [])



    const onResize = (e) => {
        console.log('E@!#!@', e.currentTarget.innerWidth)
        setWidth(e.currentTarget.innerWidth)
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const componentDetailSOTS = arrayForsots.map((item, i) => {


        const name = outdoorAdSpacesById[item[0]._id];

        if (i === 0 ) {
            return (<DivBoxTitleDiagramSC
            key={`dasdas${i}`}
            >
                <h4>{t('Детальное sOTS')}</h4>
                {/*<span>Поверхность 1</span>*/}
                <LineChartCustom
                    colors={colors}
                    id={item[0]._id}
                    numberWithCommas={numberWithCommas}
                    title={`${t('Поверхность')} ${name.name}`}
                                 _data={item}
                    label={'sots'}
                />
            </DivBoxTitleDiagramSC>)
        }
        else if (i === 1) {
            return (<DivBoxTitleDiagramSC
                key={`dasdas${i}`}
            >
                <h4></h4>
                {/*<span>Поверхность 1</span>*/}
                <LineChartCustom
                    colors={colors}
                    id={item[0]._id}
                    numberWithCommas={numberWithCommas}
                    title={`${t('Поверхность')} ${name.name}`}
                                 _data={item}
                    label={'sots'}
                />
            </DivBoxTitleDiagramSC>)
        }

        return (<DivBoxTitleDiagramWithoutHSC
            key={`dasdas${i}`}
        >

            {/*<span>{`Поверхность ${i + 1}`}</span>*/}
            <LineChartCustom
                colors={colors}
                id={item[0]._id}
                numberWithCommas={numberWithCommas}
                label={'sots'}
                title={`${t('Поверхность')} ${name.name}`} _data={item}/>
        </DivBoxTitleDiagramWithoutHSC>)

    })

    const componentDetailCGRP = sGRPDetailedDynamicsListLine.map((item, i) => {
        const name = outdoorAdSpacesById[item[0]._id];

        if (i === 0) {
            return (<DivBoxTitleDiagramSC
            key={`dasdasds${i}`}
            >
                <h4>{t('Детальное накопительное sGRP')}</h4>
                {/*<span>Поверхность 1</span>*/}
                <LineChartCustom
                    colors={colors}
                    id={item[0]._id}
                    numberWithCommas={numberWithCommas}
                    label={'sgrp'}
                    title={`${t('Поверхность')} ${name.name}`} _data={item}/>
            </DivBoxTitleDiagramSC>)
        }
        else if (i === 1) {
            return (<DivBoxTitleDiagramSC
                key={`dasdasds${i}`}
            >
                <h4></h4>
                {/*<span>Поверхность 1</span>*/}
                <LineChartCustom
                    colors={colors}
                    id={item[0]._id}
                    label={'sgrp'}
                    numberWithCommas={numberWithCommas}
                    title={`${t('Поверхность')} ${name.name}`} _data={item}/>
            </DivBoxTitleDiagramSC>)
        }

        return (<DivBoxTitleDiagramWithoutHSC
            key={`dasdassds${i}`}
        >

            {/*<span>{`Поверхность ${i + 1}`}</span>*/}
            <LineChartCustom
                colors={colors}
                id={item[0]._id}
                label={'sgrp'}
                numberWithCommas={numberWithCommas}
                title={`${t('Поверхность')} ${name.name}`} _data={item}/>
        </DivBoxTitleDiagramWithoutHSC>)

    })

    // console.log('><>>>>', arraySumSots, aggregatedSSGRPDynamicsLine)



    return isLoadData ? null : (
        <DivBoxPreCampSC>

            <DivBoxMainInfoSC>
                <DivRowSC>
                    <h4>{t('Прогнозы')}</h4>
                </DivRowSC>
                <DivRowSC>
                    <DivItemParamsSC>
                        <DivTitleSC>
                            {`${t('Прогнозное значение sOTS, тыс.')}`}
                        </DivTitleSC>
                        <DivBoxParamsSC>
                            <span>{sumSots === 0 ? sumSots : numberWithCommas((sumSots).toFixed(2))}</span>
                            <SpanTitleBoxSC></SpanTitleBoxSC>

                        </DivBoxParamsSC>
                    </DivItemParamsSC>
                    <DivItemParamsSC>
                        <DivTitleSC>
                            {`${t('Прогнозное значение sGRP')}`}
                        </DivTitleSC>
                        <DivBoxParamsSC>
                            <span>{+aggregatedSGRP.toFixed(2)}</span>
                            <SpanTitleBoxSC></SpanTitleBoxSC>

                        </DivBoxParamsSC>
                    </DivItemParamsSC>
                    <DivItemParamsSC>
                        <DivTitleSC>
                            {`${t('Средняя cкорректированная частота контакта')}`}
                        </DivTitleSC>
                        <DivBoxParamsSC>
                            <span>{sumMeanFrequency > 0 ? `${sumMeanFrequency} ${t('раза')}` : 0}</span>
                            <SpanTitleBoxSC></SpanTitleBoxSC>

                        </DivBoxParamsSC>
                    </DivItemParamsSC>
                    <DivItemParamsSC>
                        { isLoadUniqData ?  <SpinnerContainer22>
                            <SpinnerContainerTemp>
                                <ClipLoader color={'#46b875'} loading={true} size={30}/>
                            </SpinnerContainerTemp>

                        </SpinnerContainer22> : null}
                        <DivTitleSC>
                            {t('Охват')}
                        </DivTitleSC>
                        <DivBoxParamsSC>

                            <span>{`${+reach.toFixed(4)}%`}</span>
                            <SpanTitleBoxSC></SpanTitleBoxSC>

                        </DivBoxParamsSC>
                    </DivItemParamsSC>
                    <DivItemParamsSC>
                        { isLoadUniqData ?  <SpinnerContainer22>
                            <SpinnerContainerTemp>
                                <ClipLoader color={'#46b875'} loading={true} size={30}/>
                            </SpinnerContainerTemp>

                        </SpinnerContainer22> : null}
                        <DivTitleSC>
                            {t('Ожидаемый объем сегмента для ретаргетинга')}
                        </DivTitleSC>
                        <DivBoxParamsSC>

                            <span>{numberWithCommas(countUniq)}</span>
                            <SpanTitleBoxSC></SpanTitleBoxSC>

                        </DivBoxParamsSC>
                    </DivItemParamsSC>

                </DivRowSC>
            </DivBoxMainInfoSC>
            <DivBoxChartsSC>
                <LineChartCustom
                    numberWithCommas={numberWithCommas}
                    _data={arraySumSots}
                    title={t('Совокупная динамика sOTS, тыс.')}
                    label={'sots'}
                />
                <BarChartCustom
                    _data={frequencyHistList} title={t('Прогнозируемая cкорректированная частота контакта с РК')}
                    numberWithCommas={numberWithCommas}
                    label={t('Кол-во контактов')}
                />
                <LineChartCustom
                    numberWithCommas={numberWithCommas}
                    _data={aggregatedSSGRPDynamicsLine}
                    title={t('Совокупный накопительный итог sGRP')}
                    isCustomTultip={true}
                    label={'sgrp'}
                />
                {/*<ScatterChartCustom _data={aggregatedSSGRPDynamics} title={'Совокупный накопительный итог sGRP'} />*/}
                <BarChartCustom
                    _data={accumulativeFrequencyHistList} title={t('Прогнозируемая накопительная cкорректированная частота')}
                    numberWithCommas={numberWithCommas}
                    label={t('Кол-во контактов')}
                />
                <BarHorizChartCustom
                    ratingsSots={ratingsSots}
                    title={t('Прогнозный рейтинг по sOTS, тыс.')}
                    dateList={dateList}
                    selectedOutdoorAdSpaces={outdoorAdSpacesById}
                    colors={colors}
                />
                <BarChartCustom
                    numberWithCommas={numberWithCommas}
                    _data={reachOverFrequency}
                    title={t('Прогнозируемый охват по cкорректированной частоте (по итогам РК)')}
                    label={t('охват')}
                />
            </DivBoxChartsSC>
            <DivBoxChartsHeiSC>
                <DivBoxChartsHeiItemSC>
                    {componentDetailSOTS}
                </DivBoxChartsHeiItemSC>
                <DivBoxChartsHeiItemSC>
                    {
                        componentDetailCGRP
                    }


                </DivBoxChartsHeiItemSC>
            </DivBoxChartsHeiSC>
            <DivTextBoxSC>
                <p>
                    {t('*В силу физической природы отправки probe-пакетов мобильными телефонами, производится вероятностная корректировка частотных метрик. Это нужно для пруденциальной оценки действительной частоты контакта с рекламным сообщением без занижения метрики.')}
                </p>
            </DivTextBoxSC>
        </DivBoxPreCampSC>
    );
}



export default PreCampaign;

const DivBoxPreCampSC = styled.div`
    display: grid;
  grid-template-rows: 196px max-content max-content;
  grid-template-columns: 100%;
  padding: 15px;
  //height: 100%;
  row-gap: 15px;
  min-width: 1150px;
  //overflow: auto;
`;

const DivBoxMainInfoSC = styled.div`
    display: grid;
  //border-top: 1px solid #99ccae;
  padding: 10px 0;
  grid-template-rows: 50px 136px;
`;

const DivRowSC = styled.div`
  display: grid;
  height: 136px;
  
  grid-template-columns: repeat(6, max-content);
  gap: 17px;
  h4 {
    font-size: 17px;
  }
`;

const DivItemParamsSC = styled.div`
    position: relative;
    display: grid;
  //width: max-content;
  grid-template-rows: 35% 50%;
  height: 136px;
  width: 202px;
  background: #fff;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
`;

const DivTitleSC = styled.div`
  font-family: 'ProximaNova';
  text-align: center;
  color: #6a7074;
  margin: 0px;
  font-size: 15px;
  line-height: 110%;
  font-weight: 400;
`;

const DivBoxParamsSC = styled.div`
    display: grid;
  grid-template-rows: 50% 50%;
  //outline: 1px solid rgb(70, 184, 117);
  padding: 0px 10px;
  justify-items: center;
  position: relative;
  font-size: 30px;
  font-family: ProximaNova;
  color: rgb(60, 100, 153);
`;

const SpanTitleBoxSC = styled.span`
    font-size: 10px;
`

const DivBoxChartsSC = styled.div`
    display: grid;
  grid-template-rows: repeat(3, 300px);
  grid-template-columns: calc(50% - 20px) 50%;
  gap: 17px;
 
  
`;

const DivBoxChartsHeiSC = styled.div`
    display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: 100%;
  gap: 17px;
  margin-top: 20px;
 
  
`;
const DivBoxChartsHeiItemSC = styled.div`
    display: grid;
  //grid-template-rows: repeat(auto-fit, max-content);
  grid-template-columns: calc(50% - 20px) 50%;
  gap: 17px;
 
  
`;

const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  display: grid;
  grid-template-rows: 30px 300px;
  grid-template-columns: 100%;
  h4 {
    font-size: 17px;
  }
`

const DivBoxTitleDiagramWithoutHSC = styled.div`
    //padding-left: 10px;
  display: grid;
  grid-template-rows:  300px;
  grid-template-columns: 100%;
  
`

const DivTextBoxSC = styled.div`

      display: grid;
  height: 50px;
  width: 100%;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
`

export const SpinnerContainer22 = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  display: grid;
  justify-content: center;
`;

