const LocationsPanel = (state = initState, action) => {
    switch (action.type) {
        case 'setHistory_LocationsPanel':
            return setHistory_LocationsPanel(state, action);
        case 'handleLocationsSearch_LocationsPanel':
            return handleLocationsSearch_LocationsPanel(state, action);
        case 'handleObjectsSearch_LocationsPanel':
            return handleObjectsSearch_LocationsPanel(state, action);
        case 'handleObjSetsSearch_LocationsPanel':
            return handleObjSetsSearch_LocationsPanel(state, action);
        case 'showLocationsSelectionPanel_LocationsPanel':
            return showLocationsSelectionPanel_LocationsPanel(state, action);
        case 'showReportingObjectsPanel_LocationsPanel':
            return showReportingObjectsPanel_LocationsPanel(state, action);
        case 'hideSelectionPanel_LocationsPanel':
            return hideSelectionPanel_LocationsPanel(state, action);
        case 'toggleObjSetsPanel':
            return toggleObjSetsPanel(state, action);
        case 'toggleExpandArrow_LocationsPanel':
            return toggleExpandArrow_LocationsPanel(state, action);
        case 'setAllowedAccordionSection_LocationsPanel':
            return setAllowedAccordionSection_LocationsPanel(state, action);
        default:
            return state;
    }
};

const initState = {
    allowedAccordionSection: null,
    showReportingObjectsPanel: false,
    showLocationsSelectionPanel: false,
    showObjSetsPanel: false,
    showMakeNewObjSetModal: false,

    activePlId: null,
    searchLocationsValue: '',
    searchObjectsValue: '',
    searchObjSetsValue: '',
    history: {
        ownersTree: { ['1:owner']: { expand: true } },
        objSets: {},
        locations: {},
        lastLocationsStack: [],
    },
};

const setHistory_LocationsPanel = (state, action) => {
    const { history } = action.payload;
    history.lastLocationsStack.length = Math.min(history.lastLocationsStack.length, 5);
    return { ...state, history };
};

const setAllowedAccordionSection_LocationsPanel = (state, action) => {
    const { label } = action.payload;
    return { ...state, allowedAccordionSection: label };
};

const toggleExpandArrow_LocationsPanel = (state, action) => {
    const { id } = action.payload;

    const obj = { ...state.history.ownersTree };
    if (!obj[id]) {
        obj[id] = {};
        obj[id].expand = true;
    } else {
        obj[id].expand = !obj[id].expand;
    }

    return { ...state, history: { ...state.history, ownersTree: obj } };
};

const handleLocationsSearch_LocationsPanel = (state, action) => {
    const { history } = state;
    const { text, projectLocationsById_ctg } = action.payload;
    Object.keys(history.locations).forEach((id) => {
        history.locations[id].isSearch = projectLocationsById_ctg[id].name.toLowerCase().includes(text.toLowerCase());
    });
    return { ...state, searchLocationsValue: text, history };
};

const handleObjectsSearch_LocationsPanel = (state, action) => {
    const { history } = state;
    const { text, plId } = action.payload;
    const isDeployAll = text.length !== 0;
    Object.keys(history.locations).forEach((location) => {
        const lists = history.locations[location].lists;
        Object.keys(lists).forEach((group) => {
            lists[group] = isDeployAll;
        });
    });

    return { ...state, searchObjectsValue: text, history };
};

const handleObjSetsSearch_LocationsPanel = (state, action) => {
    const { history } = state;
    const { text } = action.payload;

    const isDeployAll = text.length !== 0;
    Object.keys(history.objSets.hasPl).forEach((plId) => {
        history.objSets.hasPl[plId].expanded = isDeployAll;
    });

    return { ...state, searchObjSetsValue: text, history };
};

const showLocationsSelectionPanel_LocationsPanel = (state, action) => {
    return { ...state, showLocationsSelectionPanel: true };
};

const toggleObjSetsPanel = (state, action) => {
    return { ...state, showObjSetsPanel: action.payload.show };
};

const showReportingObjectsPanel_LocationsPanel = (state, action) => {
    return { ...state, showReportingObjectsPanel: true };
};

const hideSelectionPanel_LocationsPanel = (state, action) => {
    return { ...state, showReportingObjectsPanel: false, showLocationsSelectionPanel: false };
};

export default LocationsPanel;
