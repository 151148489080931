import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import useParentSize from '../../tools/use_parent_size';
import { DiagramsContext } from '../../index';

// import '../../style/TableHotStyle.css';
import 'handsontable/dist/handsontable.full.css';

export default React.memo(({ ...props }) => {
    const { scrollWidth } = useContext(DiagramsContext);

    const targetRef = useRef();
    const TableEl = useRef();
    const [handsData, setHandsData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [tableHeight, setTableHeight] = useState(0);
    const parentDimensions = useParentSize(targetRef, props.fullScreenShow);
    const { column_keys, column_names, rows } = props.data;
    const { column_properties, translations } = props.dataView.data_report.meta;

    useEffect(() => {
        const columnKeys = column_properties
            ? column_keys.filter((key) => {
                  return column_properties[key].show;
              })
            : column_keys;

        const columnNames =
            translations && column_properties
                ? column_keys
                      .filter((key) => {
                          return column_properties[key].show;
                      })
                      .map((key) => {
                          return translations[key][props.lang];
                      })
                : column_names;

        const handsData = [];
        rows.forEach((item) => {
            handsData.push(parseTableData(columnKeys, item.values, column_properties));
        });
        const columns = generateColumns(columnKeys, column_properties);

        setHandsData(() => handsData);
        setColumns(() => columns);
        setColumnNames(() => columnNames);
        // setTableHeight(props.size.height);

        if (props.fullScreenShow) {
            const tHeight = parentDimensions.height - 48;
            if (tHeight > 0) {
                setTableHeight(tHeight);
            }
        } else {
            if (!column_properties && TableEl.current) {
                const tHeight = Math.min(
                    TableEl.current.hotElementRef.children[0].children[0].children[0].clientHeight,
                    props.size.height - 48
                );
                setTableHeight(tHeight);
            } else {
                const tHeight = props.size.height;
                setTableHeight(tHeight);
            }
        }
    }, [props.data, props.lang, props.dataView, props.size, props.fullScreenShow, scrollWidth, parentDimensions]);

    const parseTableData = (columnKeys = [], rowValue = {}, columnProperties) => {
        return columnKeys.reduce((acc, item) => {
            if (!columnProperties) {
                return { ...acc, [item]: rowValue.hasOwnProperty(item) ? rowValue[item] : '' };
            }
            const generateItem = () => {
                if (rowValue.hasOwnProperty(item)) {
                    if (!columnProperties[item].hasOwnProperty('link_to')) {
                        return rowValue[item];
                    } else {
                        return {
                            value: rowValue[item],
                            link: rowValue[columnProperties[item].link_to],
                        };
                    }
                } else {
                    return '';
                }
            };
            return { ...acc, [item]: generateItem() };
        }, {});
    };

    const imageRenderer = (link_to) => (instance, td, row, col, prop, value, cellProperties) => {
        let img = null;
        let a = null;
        img = document.createElement('IMG');
        a = document.createElement('a');
        if (link_to) {
            a.target = '_blank';
            a.href = value.link;
        }
        a.style.display = 'flex';
        a.style.justifyContent = 'center';
        img.src = link_to ? value.value : value;
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
            event.preventDefault();
        });
        Handsontable.dom.empty(td);
        td.appendChild(a);
        a.appendChild(img);

        return td;
    };

    const dateTimeRenderer = (link_to) => (instance, td, row, col, prop, value, cellProperties) => {
        let a = null;
        a = document.createElement('a');
        const date = link_to ? moment(value.value) : moment(value);
        if (link_to) {
            a.target = '_blank';
            a.href = value.link;
        }
        a.style.display = 'flex';
        a.style.justifyContent = 'center';
        a.innerText = date.format('YYYY-MM-DD HH:MM:SS');
        if (a.innerText === 'Invalid date') {
            a.innerText = '';
        }
        Handsontable.dom.empty(td);
        td.appendChild(a);

        return td;
    };

    const stringRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        let a = null;
        a = document.createElement('a');
        a.href = value.link;
        a.target = '_blank';
        // a.style.display = 'flex';
        // a.style.justifyContent = 'flexEnd';
        a.innerText = value.value;
        Handsontable.dom.empty(td);
        td.appendChild(a);
        return td;
    };

    const dateRenderer = (link_to) => (instance, td, row, col, prop, value, cellProperties) => {
        let a = null;
        a = document.createElement('a');
        const date = link_to ? moment(value.value) : moment(value);
        if (link_to) {
            a.target = '_blank';
            a.href = value.link;
        }
        a.style.display = 'flex';
        a.style.justifyContent = 'center';
        a.innerText = date.format('YYYY-MM-DD');
        if (a.innerText === 'Invalid date') {
            a.innerText = '';
        }
        Handsontable.dom.empty(td);
        td.appendChild(a);

        return td;
    };

    const numberRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        let a = null;
        a = document.createElement('a');
        a.innerText = value.value;
        a.href = value.link;
        a.target = '_blank';
        Handsontable.dom.empty(td);
        td.appendChild(a);
        return td;
    };

    const generateColumns = (columnKeys, columnProperties) => {
        if (!columnProperties) {
            return columnKeys.map((key) => ({ data: key }));
        }

        return columnKeys.map((key, i) => {
            switch (columnProperties[key].presentation_type) {
                case 'int':
                case 'float':
                    if (columnProperties[key].hasOwnProperty('link_to')) {
                        return { data: key, renderer: numberRenderer };
                    } else {
                        return { data: key, type: 'numeric' };
                    }

                case 'string':
                case 'link':
                    if (columnProperties[key].hasOwnProperty('link_to')) {
                        return {
                            data: key,
                            renderer: stringRenderer,
                            columnSorting: {
                                compareFunctionFactory: function compareFunctionFactory(sortOrder, columnMeta) {
                                    return function comparator(a, b) {
                                        var nameA = a.value.toLowerCase(),
                                            nameB = b.value.toLowerCase();
                                        switch (sortOrder) {
                                            case 'asc':
                                                if (nameA < nameB) {
                                                    return -1;
                                                }
                                                if (nameA > nameB) {
                                                    return 1;
                                                }

                                            case 'desc':
                                                if (nameA < nameB) {
                                                    return 1;
                                                }
                                                if (nameA > nameB) {
                                                    return -1;
                                                }

                                            default:
                                                return 0;
                                        }
                                    };
                                },
                            },
                        };
                    } else {
                        return { data: key };
                    }

                case 'image':
                    return { data: key, renderer: imageRenderer(columnProperties[key].link_to) };

                case 'date':
                    return { data: key, renderer: dateRenderer(columnProperties[key].link_to) };

                case 'datetime':
                    return { data: key, renderer: dateTimeRenderer(columnProperties[key].link_to) };

                default:
                    return { data: key };
            }
        });
    };

    const pdfCellRenderer = (data, presentation_type) => {
        switch (presentation_type) {
            case 'image':
                return <img style={{ justifySelf: 'center' }} src={data} />;
            case 'datetime':
                // console.log(moment(data).format('YYYY-MM-DD HH:mm:ss'));
                return moment(data).format('YYYY-MM-DD HH:mm:ss');

            default:
                if (Array.isArray(data)) {
                    return data.join(', ');
                } else {
                    return data;
                }
        }
    };

    const generalTable = useMemo(() => {
        return (
            <TableContainer ref={targetRef} height={tableHeight + scrollWidth}>
                <HotTable
                    width={'100%'}
                    // width={parentDimensions.width - 14}
                    ref={TableEl}
                    data={handsData}
                    colHeaders={columnNames}
                    columns={columns}
                    stretchH={'all'}
                    rowHeaders={' '}
                    rowHeaderWidth={30}
                    autoColumnSize={true}
                    wordWrap={true}
                    readOnly={true}
                    columnSorting={true}
                />
            </TableContainer>
        );
    }, [tableHeight, handsData, columnNames, columns, parentDimensions]);

    if (props.iterationsNumber === 1) {
        return generalTable;
    } else {
        if (handsData[props.iteration]) {
            const row = handsData[props.iteration];
            const lastRow = props.iteration === props.iterationsNumber - 1;

            // console.log('column_keys', column_keys);
            // console.log(column_properties);

            const rowData = Object.keys(row).map((key) => {
                const presentation_type = column_properties[key].presentation_type;
                if (row[key] === null) {
                    return '';
                } else if (typeof row[key] === 'object') {
                    if (Array.isArray(row[key])) {
                        return { presentation_type, value: pdfCellRenderer(row[key], presentation_type) };
                    } else {
                        return { presentation_type, value: pdfCellRenderer(row[key].value, presentation_type) };
                    }
                } else {
                    return { presentation_type, value: pdfCellRenderer(row[key], presentation_type) };
                }
            });
            // console.log(rowData);

            const rowLength = rowData.length;

            const tableRow = (
                <TableRow rowLength={rowLength} lastRow={lastRow}>
                    {rowData.map((item) => {
                        return (
                            <Cell presentation_type={item.presentation_type} rowLength={rowLength}>
                                <p>{item.value}</p>
                            </Cell>
                        );
                    })}
                </TableRow>
            );

            if (props.iteration === 0) {
                const tableHeader = (
                    <TableRow header rowLength={rowLength} lastRow={lastRow}>
                        {columnNames.map((name) => {
                            return (
                                <Cell header rowLength={rowLength}>
                                    <p>{name}</p>
                                </Cell>
                            );
                        })}
                    </TableRow>
                );
                return (
                    <React.Fragment>
                        {tableHeader}
                        {tableRow}
                    </React.Fragment>
                );
            } else {
                return tableRow;
            }
        } else {
            return null;
        }
    }
});

const TableContainer = styled.div`
    border: 1px solid rgb(204, 204, 204);
    display: flex;
    box-sizing: border-box;
    height: ${(p) => p.height + 'px'};
    overflow: hidden;
    /* overflow: auto; */
`;

const TableRow = styled.div`
    background-color: ${(p) => (p.header ? 'rgb(240,240,240)' : 'auto')};
    box-sizing: border-box;
    width: 100%;
    display: flex;
    border-left: 1px solid rgb(200, 200, 200);
    border-top: 1px solid rgb(200, 200, 200);
    border-right: 1px solid rgb(200, 200, 200);
    border-bottom: ${(p) => (p.lastRow ? '1px solid rgb(200,200,200)' : 'none')};
    /* max-height: 50px; */
    /* height: 50px; */

    /* display: grid;
    grid-template-columns: ${(p) => `repeat(${p.rowLength}, 60px)`}; */
`;
const Cell = styled.div`
    color: ${(p) => (p.header ? 'rgb(153,153,153)' : 'auto')};
    font-weight: ${(p) => (p.header ? '700' : '400')};
    text-align: ${(p) => (p.header ? 'center' : 'left')};

    box-sizing: border-box;
    display: flex;
    align-items: center;
    /* overflow: hidden; */
    width: ${(p) => `${1100 / p.rowLength}px`};
    /* width: 70px; */
    flex-shrink: 0;
    padding: 2px 5px;
    border-right: 1px solid rgb(200, 200, 200);
    &:last-child {
        border-right: 1px solid transparent;
    }
    padding: 3px;
    margin: 0;
    font-size: 11px;
    p {
        width: 100%;
        word-wrap: break-word;
        margin: 0;
        padding: 0;
        display: ${(p) => {
            switch (p.presentation_type) {
                case 'image':
                    return 'flex';
                default:
                    return 'auto';
            }
        }};
        justify-content: ${(p) => {
            if (p.header) {
                return 'center';
            } else {
                switch (p.presentation_type) {
                    case 'image':
                        return 'center';
                    default:
                        return 'flex-start';
                }
            }
        }};
    }
`;
