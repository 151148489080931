import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import defaults from 'lodash/defaults';
import cloneDeep from 'lodash/cloneDeep';

const Tools = {
    compare(state) {
        const newState = JSON.parse(JSON.stringify(state));
        return newState;
    },
    copy(state) {
        if (state === undefined) {
            return state;
        }
        // const newState = JSON.parse(JSON.stringify(state));
        // return newState;
        return cloneDeep(state);
    },
    compare2(state, newSatet) {
        return JSON.stringify(state) === JSON.stringify(newSatet);
    },
    argSort(arr, d = 1) {
        var len = arr.length;
        var indices = new Array(len);
        for (var i = 0; i < len; ++i) indices[i] = i;
        indices.sort(function (a, b) {
            return arr[a] < arr[b] ? -1 * d : arr[a] > arr[b] ? d : 0;
        });
        return indices;
    },
    arrPartWithIndeces(arr, indeces) {
        var len = indeces.length;
        if (arr.length < len) {
            len = arr.length;
        }
        var res = new Array(len);
        for (var i = 0; i < len; i++) {
            res[i] = arr[indeces[i]];
        }
        return res;
    },
    arraysCompare(array1, array2) {
        return (
            array1.length === array2.length &&
            array1.sort().every((value, index) => {
                return value === array2.sort()[index];
            })
        );
    },
    useOnOutsideClick(handleOutsideClick) {
        const innerBorderRef = useRef();

        const onClick = (event) => {
            if (innerBorderRef.current && !innerBorderRef.current.contains(event.target)) {
                handleOutsideClick();
            }
        };

        const useMountEffect = (fun) => useEffect(fun, []);

        useMountEffect(() => {
            document.addEventListener('click', onClick, true);
            return () => {
                document.removeEventListener('click', onClick, true);
            };
        });

        return { innerBorderRef };
    },

    isDisabled(permanentCloudData, dataViewsByGroupID, groupIDsBySubsectionKeyName, subsectionKeyName, diagram, subsectionID) {
        let disabled = false;
        const arr = [];

        if (groupIDsBySubsectionKeyName[subsectionKeyName]) {
            groupIDsBySubsectionKeyName[subsectionKeyName].forEach((item, i) => {
                dataViewsByGroupID[item].forEach((dataView) => {
                    dataView.data_report.input_parameters.forEach((item) => {
                        if (item.input_parameter_type === 'custom') {
                            arr.push(item.key_name);
                        }
                    });
                });
            });
        }

        const custom = [...new Set(arr)];
        const dataViewParams = diagram.data_report.input_parameters.map((item) => item.key_name);

        const singleMode = (item) => item === 'obj_id' || item === 'pl_id';
        const multiMode = (item) => item === 'obj_ids' || item === 'pl_ids' || item === 'main_obj_id' || item === 'contra_obj_ids';

        if (custom.length !== 0) {
            const mode = custom.some(singleMode) ? 'single' : 'multi';
            if ((mode === 'multi' && dataViewParams.some(singleMode)) || (mode === 'single' && dataViewParams.some(multiMode))) {
                disabled = true;
            }
        }

        if (permanentCloudData && permanentCloudData.custom_dataViews) {
            const simArr = permanentCloudData.custom_dataViews.filter((dataView) => {
                return (
                    subsectionID === dataView.subsectionID &&
                    dataView.data_report.key_name === diagram.data_report.key_name &&
                    dataView.data_view_type.key_name === diagram.data_view_type.key_name
                );
            });
            if (simArr.length !== 0) {
                disabled = true;
            }
        }
        return disabled;
    },

    useGetParamSet(currentSection = null, currentSubSection = null, fullScreenId = null) {
        const currentSection1 = useSelector((state) => state.TopPanel.status);
        const currentSubSection1 = useSelector((state) => state.TopPanel.statusLeftPanel);
        if (!currentSection) {
            currentSection = currentSection1;
        }
        if (!currentSubSection) {
            currentSubSection = currentSubSection1;
        }
        const sectionsByKeyName = useSelector((state) => state.TopPanel.sectionsByKeyName);
        const groupIDsBySubsectionKeyName = useSelector((state) => state.ReportParametersManager.groupIDsBySubsectionKeyName);
        const inputParametersByName = useSelector((state) => state.ReportParametersManager.Source.ctg.inputParametersByName);
        const dataViewsByGroupID = useSelector((state) => state.ReportParametersManager.dataViewsByGroupID);

        const [result, set_result] = useState({ custom: [], global: [] });

        useEffect(() => {
            if (!sectionsByKeyName || !sectionsByKeyName[currentSection]) return;
            const custom = [];
            const global = [];

            const removeDubles = (arr = [], add = []) => {
                return Array.from(new Set([...arr.map((item) => item.key_name), ...add]));
            };

            const getParamsFromSectionList = (type) => {
                try {
                    const result = sectionsByKeyName[currentSection].subsections
                        .filter((item) => item.key_name === currentSubSection)[0]
                        .required_input_parameters.filter((item) => {
                            return inputParametersByName[item].input_parameter_type.key_name === type;
                        });

                    return result;
                } catch (error) {
                    window.location.reload();
                }
            };

            if (groupIDsBySubsectionKeyName[currentSubSection] && !fullScreenId) {
                groupIDsBySubsectionKeyName[currentSubSection].forEach((item, i) => {
                    dataViewsByGroupID[item].forEach((dataView) => {
                        dataView.data_report.input_parameters.forEach((item) => {
                            if (item.input_parameter_type === 'custom') {
                                custom.push(item);
                            } else if (item.input_parameter_type === 'global') {
                                global.push(item);
                            }
                        });
                    });
                });
            } else if (groupIDsBySubsectionKeyName[currentSubSection] && fullScreenId) {
                groupIDsBySubsectionKeyName[currentSubSection].forEach((item, i) => {
                    dataViewsByGroupID[item]
                        .filter((dataView) => dataView.data_view_id === fullScreenId)
                        .forEach((dataView) => {
                            dataView.data_report.input_parameters.forEach((item) => {
                                if (item.input_parameter_type === 'custom') {
                                    custom.push(item);
                                } else if (item.input_parameter_type === 'global') {
                                    global.push(item);
                                }
                            });
                        });
                });
            }

            const result = fullScreenId
                ? { custom: removeDubles(custom), global: removeDubles(global) }
                : {
                      custom: removeDubles(custom, getParamsFromSectionList('custom')),
                      global: removeDubles(global, getParamsFromSectionList('global')),
                  };

            set_result(result);
        }, [
            dataViewsByGroupID,
            currentSection,
            currentSubSection,
            sectionsByKeyName,
            groupIDsBySubsectionKeyName,
            inputParametersByName,
            fullScreenId,
        ]);
        return result;
    },

    useDataTree(arr) {
        const [commonTree, setCommonTree] = useState([]);
        const available_owners = useSelector((state) => state.ReportParametersManager.Source.src.available_owners);
        const available_projects = useSelector((state) => state.ReportParametersManager.Source.src.available_projects);
        const project_locations = useSelector((state) => state.ReportParametersManager.Source.src.project_locations);
        useEffect(() => {
            if (available_owners && available_projects && project_locations && arr) {
                const _owners = available_owners.map((item) => {
                    return {
                        ...item,
                        id: `${item.id}:owner`,
                        parent: item.parent !== null ? `${item.parent}:owner` : null,
                        type: 'owner',
                    };
                });
                const _projects = available_projects.map((item) => {
                    return {
                        ...item,
                        id: `${item.id}:project`,
                        type: 'project',
                        parent: `${item.owner}:owner`,
                    };
                });
                const _project_locations = project_locations.map((item) => {
                    return {
                        ...item,
                        id: `${item.id}:pl`,
                        type: 'pl',
                        parent: `${item.project}:project`,
                    };
                });

                const commonTree = dataToTree([...arr, ..._owners, ..._projects, ..._project_locations]);
                setCommonTree(() => commonTree);

                // console.log('_owners', _owners);
                // console.log('_projects', _projects);
                // console.log('_project_locations', _project_locations);
                // console.log('_dataobjsets', _dataobjsets);
            }
        }, [available_owners, available_projects, project_locations, arr]);

        return commonTree;
    },
};

export const generateId = (n = 8) => {
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return new Array(n)
        .fill()
        .map(() => chars[getRandomInt(0, chars.length)])
        .join('');
};

export const useAdCampTree = () => {
    const [commonTree, setCommonTree] = useState([]);
    const available_owners = useSelector((state) => state.ReportParametersManager.Source.src.available_owners);
    const available_projects = useSelector((state) => state.ReportParametersManager.Source.src.available_projects);
    const { projectSearchValue } = useSelector((state) => state.AdCampaigns);
    useEffect(() => {
        if (available_owners && available_projects) {
            const _projects = available_projects
                .filter((item) => {
                    if (projectSearchValue === '') {
                        return true;
                    } else {
                        return item.name.toLowerCase().includes(projectSearchValue.toLowerCase());
                    }
                })
                .map((item) => {
                    return {
                        ...item,
                        id: item.id,
                        // id: `${item.id}:project`,
                        type: 'project',
                        parent: `${item.owner}:owner`,
                    };
                });

            const filteredOwners = [...new Set(_projects.map((item) => item.owner))];
            for (let i = 0; i < 10; i++) {
                [...new Set(filteredOwners)]
                    .filter((item) => item)
                    .forEach((item) => {
                        filteredOwners.push(available_owners.filter((owner) => owner.id === item)[0].parent);
                    });
            }

            const _owners = available_owners
                .filter((item) => {
                    return [...new Set(filteredOwners)].filter((item) => item).includes(item.id);
                })
                .map((item) => {
                    return {
                        ...item,
                        id: `${item.id}:owner`,
                        parent: item.parent !== null ? `${item.parent}:owner` : null,
                        type: 'owner',
                    };
                });

            const commonTree = dataToTree([..._owners, ..._projects]);
            setCommonTree(() => commonTree);

            // console.log('_owners', _owners);
            // console.log('_projects', _projects);
            // console.log('_project_locations', _project_locations);
            // console.log('_dataobjsets', _dataobjsets);
        }
    }, [available_owners, available_projects, projectSearchValue]);

    return commonTree;
};

export const treeMap = (tree) => {
    const { children } = tree;
    const counter = calculateTypeCount(tree);
    if (children.length === 0) {
        return tree;
    }
    return { ...tree, children: children.map(treeMap), counter };
};

export const treeReduce = (f, tree, acc) => {
    const { children } = tree;
    const newAcc = f(acc, tree);

    if (!children) {
        return newAcc;
    }
    return children.reduce((iAcc, n) => treeReduce(f, n, iAcc), newAcc);
};

export const calculateTypeCount = (tree) => treeReduce((acc, node) => (node.type === 'dataobjset' ? acc + 1 : acc), tree, 0);

export const treeToPlane = (tree) =>
    treeReduce((acc, node) => (node.counter ? [...acc, { id: node.id, name: node.name, count: node.counter }] : acc), tree, []);

export const dataToTree = (arr) => {
    const nodes = {};
    const newArr = cloneDeep(arr);
    return (
        newArr
            // .sort((a, b) => {
            //     return a.id >= b.id;
            // })
            .filter((item) => {
                const id = item['id'];
                const parentId = item['parent'];
                nodes[id] = defaults(item, nodes[id], { children: [] });
                if (parentId) {
                    (nodes[parentId] = nodes[parentId] || { children: [] })['children'].push(item);
                }
                return !parentId;
            })
    );
};

export function traversalTree(firstLevelElements) {
    const currRoots = [{ children: firstLevelElements, index: 0 }];

    while (currRoots.length) {
        const currRoot = currRoots[currRoots.length - 1];
        if (currRoot.index === currRoot.children.length) {
            currRoots.length--;
        } else {
            const item = currRoot.children[currRoot.index++];
            console.log('-'.repeat(currRoots.length - 1) + ' ' + item.id);

            if (item.children) {
                currRoots.push({ children: item.children, index: 0 });
            }
        }
    }
}

export default Tools;
