import React, { useMemo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import ContextMenu from '../../../../../context_menu/context_menu';
import img_black from '../../../../../../img/check-mark.svg';
import img_white from '../../../../../../img/check-mark-white.svg';
import dots from '../../../../../../img/three_dots.svg';
import tools from "../tools/tools";
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import {useSelector} from "react-redux";

const CustomDetailsList = React.memo(
    ({
        items,
        columns,
        singleSelection,
        customInputParamsValues,
        reportingObjectsByPlId,
        reportingObjectsById,
        multiSelection,
        paramsSet,
        ...props
    }) => {
        const tableRef = useRef();
        const [hoverRow, setHoverRow] = useState(null);
        const [counter, set_counter] = useState(0);
        const [widthArr, setWidthArr] = useState([]);
        const { lang } = useSelector((state) => state.TopPanel);
        const selection = useMemo(() => {
            return paramsSet.custom.includes('ad_campaign_id')
                ? singleSelection
                : paramsSet.custom.includes('ad_campaign_ids')
                ? multiSelection
                : null;
        }, [singleSelection, multiSelection, paramsSet.custom]);

        const [theme] = useState(getTheme());
        const { t } = useTranslation();

        moment.locale(lang);
        useEffect(() => {
            window.addEventListener('resize', () => {
                set_counter((c) => c + 1);
            });
            return () => window.removeEventListener('resize', () => {});
        }, []);

        const onMouseEnterCell = (row) => () => {
            setHoverRow(row);
        };

        const onMouseLeaveCell = () => {
            setHoverRow(null);
        };

        const menuItems = [
            {
                key: 'info',
                name: t('Информация'),
                onClick: () => {
                    props.toggleModal({
                        show: true,
                        modalType: 'segment_info',
                        modalTitle: `${t('Информация о сегменте')} ${hoverRow.name}`,
                        modalText: `${hoverRow.description}`,
                        height: 'auto',
                        width: '60%',
                        modalArgs: {
                            name: '',
                            id: '',
                            props: { info: hoverRow },
                        },
                        buttons: [
                            {
                                text: t('Закрыть'),
                                width: '100px',
                                action: null,
                            },
                        ],
                    });
                },
            },
        ];

        if (hoverRow && hoverRow.location_filter) {
            menuItems.push({
                key: 'edit',
                name: t('Редактировать'),
                onClick: () => {
                    props.toggleModal({
                        show: true,
                        modalType: 'new_segment',
                        modalText: '',
                        height: 'auto',
                        width: '80%',
                        modalArgs: {
                            name: '',
                            id: '',
                            props: { customInputParamsValues, reportingObjectsByPlId, reportingObjectsById, editData: hoverRow },
                        },
                    });
                },
            });
        }

        const onSelectClick = (row) => () => {
            if (selection !== null && row.id === selection.id) {
                props.onSelectClick(null);
            } else {
                props.onSelectClick({ id: row.id });
            }
        };


        const header = useMemo(() => {
            return (
                <Header widthArr={widthArr} columns={columns}>
                    {columns.map((item, j) => {
                        return (
                            <Cell weight={600} key={`header-${j}-${item.key}`} fieldName={item.fieldName}>
                                {item.name}
                            </Cell>
                        );
                    })}
                </Header>
            );
        }, [items, columns, widthArr]);

        const cells = useMemo(() => {

            return items.map((row, i) => {

                return columns.map((item, j) => {
                    let content = null;
                    let fontColor = '#495464';
                    const cellValue = row[item.fieldName];
                    let dataTultipExport = null

                    if (item.fieldName === 'select') {
                        const bgColor = selection && selection.id === row.id ? theme.palette.themePrimary : '#fff';
                        const iconImage = selection && selection.id === row.id ? img_white : img_black;

                        content = (
                            <Selection>
                                <Round
                                    bgColor={bgColor}
                                    id={row.id}
                                    hoverRow={hoverRow}
                                    selection={selection}
                                    onClick={onSelectClick(row, i)}
                                >
                                    <Icon iconImage={iconImage} id={row.id} selection={selection}></Icon>
                                </Round>
                            </Selection>
                        );
                    } else if (item.fieldName === 'delete') {
                        content = (
                            <ContextMenu rightClick={true} menuItems={menuItems} hideOnMouseLeave={true}>
                                <Button3dots />
                            </ContextMenu>
                        );
                    } else if (item.fieldName === 'updated') {
                        content = moment(cellValue).format('YYYY-MM-DD HH:mm:ss');
                    } else if (item.fieldName === 'progress_status') {
                        if (cellValue === 'success') {
                            fontColor = '#46b875';
                        } else if (cellValue === 'failure') {
                            fontColor = '#d50102';
                        } else if (cellValue === 'in-queue') {
                            fontColor = 'rgb(255,203,107)';
                        }
                        content = cellValue;
                    }
                    else if (item.fieldName === 'yandex_status_export') {
                        const cellValueTemp = row.yandex_export.export_status;
                        fontColor = tools.getColorText(cellValueTemp);
                        content = cellValueTemp;
                        dataTultipExport = row.yandex_export.exported;

                        if (!row.yandex_export.yandex_needs_upload) {
                            fontColor = '#e9e7e7'
                            content = '------';
                            dataTultipExport = null;
                        }
                    }
                    else if (item.fieldName === 'mailru_status_export') {
                        const cellValueTemp = row.mailru_export.export_status;
                        fontColor = tools.getColorText(cellValueTemp);
                        content = cellValueTemp;
                        dataTultipExport = row.mailru_export.exported;
                        // console.log('>>>>>>>>>>>>', row.mailru_export)
                        if (!row.mailru_export.mailru_needs_upload) {
                            fontColor = '#e9e7e7'
                            content = '------';
                            dataTultipExport = null;
                        }

                    }
                    else {
                        content = cellValue;
                    }

                    if (dataTultipExport) {
                        return (
                            <ContentWrapper
                                key={`${i}++${item.fieldName}`}
                                onClick={onSelectClick(row, i)}
                                onMouseEnter={onMouseEnterCell(row)}
                                onMouseLeave={onMouseLeaveCell}
                                hoverRow={hoverRow}
                                selection={selection}
                                id={row.id}
                            >
                                <ContextMenu cursor={true} margin="0" rightClick={true} menuItems={menuItems} hideOnMouseLeave={true}>
                                    <TooltipHost content={moment(dataTultipExport).format('DD MMM YYYY')}>
                                        <Cell fontColor={fontColor} weight={400} key={`${i}-${j}-${item.key}`} fieldName={item.fieldName}>
                                            {content}
                                        </Cell>
                                    </TooltipHost>
                                </ContextMenu>
                            </ContentWrapper>
                        );
                    }
                    return (
                        <ContentWrapper
                            key={`${i}++${item.fieldName}`}
                            onClick={onSelectClick(row, i)}
                            onMouseEnter={onMouseEnterCell(row)}
                            onMouseLeave={onMouseLeaveCell}
                            hoverRow={hoverRow}
                            selection={selection}
                            id={row.id}
                        >
                            <ContextMenu cursor={true} margin="0" rightClick={true} menuItems={menuItems} hideOnMouseLeave={true}>
                                <Cell fontColor={fontColor} weight={400} key={`${i}-${j}-${item.key}`} fieldName={item.fieldName}>
                                    {content}
                                </Cell>
                            </ContextMenu>
                        </ContentWrapper>
                    );
                });
            });
        }, [items, columns, hoverRow, selection]);

        useEffect(() => {

            if (tableRef.current) {
                const result = Array.from(tableRef.current.children)
                    .filter((item, i) => i < columns.length)
                    .map((item) => {

                        return (item.offsetWidth)

                    });

                setWidthArr([...result]);
            }
        }, [items, counter]);

        return (
            <MainWrapper>
                {header}
                <Body ref={tableRef} items={items} columns={columns}>
                    {cells}
                </Body>
            </MainWrapper>
        );
    }
);

export default CustomDetailsList;

const MainWrapper = styled.section`
    width: 100%;
    overflow: auto;
    position: relative;
`;

const Header = styled.section`
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: ${(p) => {
        if (!p.widthArr || p.widthArr.length === 0) {
            return `40px repeat(${p.columns.length - 1}, auto)`;
        } else {
            return p.widthArr.map((item, index) => {
                if (index === 0) {
                    return 'auto';
                }
                else {
                    return (`${item}px`);
                }
              
            }).join(' ');
        }
    }};
    background-color: #fff;
    border-bottom: 1px solid #ddd;
`;
const Body = styled.section`
    /* margin: 10px; */
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: ${(p) => `40px repeat(${p.columns.length - 1}, auto)`};
    background-color: #fff;
`;

const Cell = styled.div`
    box-sizing: border-box;
    width: fit-content;
    //min-width: 150px;
    max-width: 300px;
    /* height: 99%; */
    display: flex;
    align-items: center;
    //padding-right: 10px;
    padding: 10px;
    min-height: 20px;
    font-size: 14px;
    font-weight: ${(p) => p.weight};
    color: ${(p) => p.fontColor};
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-shrink: 0;
    border-bottom: 1px solid rgb(244, 244, 244);
    background-color: ${(p) => {
        if (
            (p.hoverRow && p.id !== undefined && p.hoverRow.id === p.id) ||
            (p.selection !== null && p.selection !== undefined && p.selection && p.id === p.selection.id)
        ) {
            return 'rgb(244, 244, 244)';
        } else {
            return '#fff';
        }
    }};
`;
const Selection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const Round = styled.div`
    cursor: pointer;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #777;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.bgColor};
    display: ${(p) => {
        if ((p.hoverRow && p.id !== undefined && p.hoverRow.id === p.id) || (p.selection && p.id === p.selection.id)) {
            return 'flex';
        } else {
            return 'none';
        }
    }};
`;
const Icon = styled.div`
    opacity: ${(p) => {
        if (p.selection && p.id === p.selection.id) {
            return '1';
        } else {
            return '0';
        }
    }};
    width: 16px;
    height: 16px;
    background: ${(p) => `url(${p.iconImage}) no-repeat center`};
    background-size: contain;
    background-size: 60%;
    &:hover {
        opacity: 0.5;
    }
`;

const Button3dots = styled.div`
    opacity: 0.2;
    width: 16px;
    height: 16px;
    background: url(${dots}) no-repeat center;
    background-size: contain;
    &:hover {
        opacity: 0.8;
    }
`;
