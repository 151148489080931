import React from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';

const SearchContainer = styled.div`
    margin: 5px 0;
    width: 100%;
    input {
        box-sizing: border-box !important;
        width: 100% !important;
        margin: 0;
        min-height: 30px !important;
        padding-left: 10px !important;
        outline: none !important;
        &:focus {
            outline: none !important;
        }
    }
`;

const Search = React.memo(({ searchValue, ...props }) => {
    const handleSearch = e => {
        e.preventDefault();
        props.handleSearch(e.target.value);
    };

    return (
        <SearchContainer>
            <input
                value={searchValue}
                onChange={handleSearch}
                type="text"
                placeholder={_t.t('Поиск')}
                autoFocus="autofocus"
            />
        </SearchContainer>
    );
});

export default Search;
