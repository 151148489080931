import React, {useEffect} from 'react';
import styled from "styled-components/macro";
const c3 = require('c3');




const BarHorizChartCustom = (props) => {
    const {
        title,
        ratingsSots,
        dateList,
        selectedOutdoorAdSpaces,
        colors
    } = props;


    useEffect(() => {


        let arrayNames = ['x'];
        let arrayValues = ['value'];

        let _colors = []

        ratingsSots.forEach((item, i) => {
            _colors.push(colors[`outdooradspace:${selectedOutdoorAdSpaces[item.name].id}`])

            arrayNames.push(selectedOutdoorAdSpaces[item.name].name)
            arrayValues.push(item.value.toFixed(2))
        })




        var chart = c3.generate({
            bindto: '#uv-div',
            size: {
                height: 249

            },
            bar: {
                // width: 20
            },
            padding: {
                left: 100
            },
            color: {
                pattern: ['#FABF62', '#ACB6DD']
            },
            data: {
                x: 'x',
                y: 'value',
                columns:
                    [
                        arrayNames,
                        arrayValues
                    ],

                type: 'bar',

                color: function(inColor, data) {
                    var colors = _colors;
                    if(data.index !== undefined) {
                        return colors[data.index];
                    }

                    return inColor;
                }
            },
            axis: {
                rotated: true,
                x: {
                    type: 'category',
                    tick: {
                        rotate: 75,
                        multiline: false
                    },
                },
                y: {
                    type: 'category',
                    tick: {
                        rotate: 75,
                        multiline: false
                    },
                    
                }
            },
            tooltip: {
                grouped: false
            },
            legend: {
                show: false
            }
        });
    }, [ratingsSots])

    return (
        <DivBarHorizChartCustomSC>
            <DivBoxTitleDiagramSC>
                <h4>{title}</h4>
            </DivBoxTitleDiagramSC>

            <DivBoxChartSC id="uv-div" className='rChart uvcharts'>

            </DivBoxChartSC>
        </DivBarHorizChartCustomSC>
    );
}

const DivBarHorizChartCustomSC = styled.div`
  background: #ffffff;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
`

const DivBoxChartSC = styled.div`
    height: calc(100% - 30px);
`
const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  h4 {
    font-size: 17px;
  }
`

export default BarHorizChartCustom;