import React, { useMemo, useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import Tools from '../../tools/tools';

import styled from 'styled-components/macro';

const AddDiagramModal = React.memo(
    ({
        sectionsByKeyName,
        permanentCloudData,
        group,
        currentSubSection,
        groupIDsBySubsectionKeyName,
        dataViewsByGroupID,
        lang,
        ...props
    }) => {
        const { t } = useTranslation();
        const [theme] = useState(getTheme());
        const [selectedSection, set_selectedSection] = useState('');
        const [selectedSubsection, set_selectedSubsection] = useState('');
        const [selectedGroup, set_selectedGroup] = useState('');
        const [selectedDiagram, set_selectedDiagram] = useState(null);
        const [searchValue, set_searchValue] = useState('');

        const onClick =
            (element, id, disabled = false) =>
            () => {
                if (disabled) return;
                switch (element) {
                    case 'section':
                        set_selectedSection(id);
                        set_selectedSubsection('');
                        set_selectedGroup('');
                        set_selectedDiagram(null);
                        break;
                    case 'subsection':
                        set_selectedSubsection(id);
                        set_selectedGroup('');
                        set_selectedDiagram(null);
                        break;
                    case 'group':
                        set_selectedGroup(id);
                        set_selectedDiagram(null);
                        break;
                    case 'diagram':
                        set_selectedDiagram(id);
                        break;
                    default:
                        break;
                }
            };

        const getIconName = (type) => {
            switch (type) {
                case 'dynamics_diagram':
                    return 'StackedLineChart';
                case 'vertical_bar_distribution_diagram':
                    return 'BarChartVertical';
                case 'horizontal_bar_obj_distribution_diagram':
                case 'horizontal_bar_distribution_diagram':
                    return 'BarChartHorizontal';
                case 'score_diagram':
                    return 'NumberSequence';
                case 'funnel_diagram':
                    return 'FunnelChart';
                case 'pie_chart_diagram':
                    return 'PieSingle';
                case 'table_data_view':
                    return 'Table';

                default:
                    return 'StackedLineChart';
            }
        };

        const MainComponent = useMemo(() => {
            const sections = Object.keys(sectionsByKeyName)
                .filter((item) => item !== 'custom_section')
                .map((key, i) => {
                    console.log(sectionsByKeyName[key]);
                    const name = sectionsByKeyName[key].translations[lang].name;
                    const selected = sectionsByKeyName[key].key_name === selectedSection;
                    return (
                        <ListItem
                            key={`${key}++${i}`}
                            selected={selected}
                            bgColor={`${theme.palette.themePrimary}`}
                            onClick={onClick('section', sectionsByKeyName[key].key_name)}
                        >
                            {name}
                        </ListItem>
                    );
                });

            const subsections = selectedSection
                ? sectionsByKeyName[selectedSection].subsections.map((item, i) => {
                      const name = item.translations[lang].name;
                      const selected = item.key_name === selectedSubsection;
                      return (
                          <ListItem
                              key={`${item.key_name}+__+${i}`}
                              selected={selected}
                              bgColor={`${theme.palette.themePrimary}`}
                              onClick={onClick('subsection', item.key_name)}
                          >
                              {name}
                          </ListItem>
                      );
                  })
                : null;

            const groups =
                selectedSection && selectedSubsection
                    ? sectionsByKeyName[selectedSection].subsections
                          .filter((subsection) => subsection.key_name === selectedSubsection)[0]
                          .data_view_groups.map((item, i) => {
                              const name = item.translations[lang].name;
                              const selected = item.id === selectedGroup;
                              return (
                                  <ListItem
                                      key={`${item.id}+_+_+${i}`}
                                      selected={selected}
                                      bgColor={`${theme.palette.themePrimary}`}
                                      onClick={onClick('group', item.id)}
                                  >
                                      {name}
                                  </ListItem>
                              );
                          })
                    : null;

            const diagrams =
                selectedSection && selectedSubsection && selectedGroup && dataViewsByGroupID[selectedGroup]
                    ? dataViewsByGroupID[selectedGroup].map((item, i) => {
                          const iconName = getIconName(item.data_view_type.key_name);
                          const disabled = Tools.isDisabled(
                              permanentCloudData,
                              dataViewsByGroupID,
                              groupIDsBySubsectionKeyName,
                              currentSubSection,
                              item,
                              group.subsection_id
                          );

                          const name = item.data_report.translations[lang].name;
                          const selected = selectedDiagram && item.data_view_id === Number(selectedDiagram.data_view_id);
                          return (
                              <ListItem
                                  key={`${item.data_view_id}+++${i}`}
                                  selected={selected}
                                  disabled={disabled}
                                  bgColor={`${theme.palette.themePrimary}`}
                                  onClick={onClick('diagram', item, disabled)}
                              >
                                  <ChartIcon iconName={iconName} />
                                  {name}
                              </ListItem>
                          );
                      })
                    : null;

            const searchMode = () => {
                const searchDiagrams =
                    searchValue === ''
                        ? null
                        : Object.keys(dataViewsByGroupID)
                              .filter((key) => !key.includes('custom'))
                              .reduce((acc, key) => {
                                  return [...acc, ...dataViewsByGroupID[key]];
                              }, [])
                              .filter((item) => {
                                  return (
                                      item.data_report.translations['ru'].name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                      item.data_report.translations['en'].name.toLowerCase().includes(searchValue.toLowerCase())
                                  );
                              })
                              .map((item, i) => {
                                  const iconName = getIconName(item.data_view_type.key_name);
                                  const name = item.data_report.translations[lang].name;
                                  const groupName = item.group.translations[lang].name;
                                  const subsectionName = item.meta.subsection.translations[lang].name;
                                  const sectionName = item.meta.section.translations[lang].name;
                                  const selected = selectedDiagram && item.data_view_id === Number(selectedDiagram.data_view_id);
                                  const disabled = Tools.isDisabled(
                                      permanentCloudData,
                                      dataViewsByGroupID,
                                      groupIDsBySubsectionKeyName,
                                      currentSubSection,
                                      item,
                                      group.subsection_id
                                  );
                                  return (
                                      <FoundItem
                                          key={`${item.data_view_id}++__${i}`}
                                          selected={selected}
                                          disabled={disabled}
                                          bgColor={`${theme.palette.themePrimary}`}
                                          onClick={onClick('diagram', item, disabled)}
                                      >
                                          <NameWrapper>{sectionName}</NameWrapper>
                                          <NameWrapper>{subsectionName}</NameWrapper>
                                          <NameWrapper>{groupName}</NameWrapper>
                                          <NameWrapper>
                                              <ChartIcon iconName={iconName} />
                                              {name}
                                          </NameWrapper>
                                      </FoundItem>
                                      //   <FoundItem
                                      //       key={`${item.data_view_id}++__${i}`}
                                      //       selected={selected}
                                      //       disabled={disabled}
                                      //       bgColor={`${theme.palette.themePrimary}`}
                                      //       onClick={onClick('diagram', item, disabled)}
                                      //   >
                                      //       <ChartIcon iconName="StackedLineChart" />
                                      //       {name}
                                      //   </FoundItem>
                                  );
                              });
                return (
                    <SearchContainer>
                        <FoundDiagrams>{searchDiagrams}</FoundDiagrams>
                    </SearchContainer>
                );
            };

            return searchValue === '' ? (
                <Container>
                    <List>{sections}</List>
                    <List>{subsections}</List>
                    <List>{groups}</List>
                    <List>{diagrams}</List>
                </Container>
            ) : (
                searchMode()
            );
        }, [sectionsByKeyName, lang, selectedSection, selectedSubsection, selectedGroup, selectedDiagram, searchValue]);

        const description =
            searchValue === '' && selectedSection && selectedSubsection && selectedGroup && selectedDiagram
                ? dataViewsByGroupID[selectedGroup].filter((item) => item.data_view_id === Number(selectedDiagram.data_view_id))[0]
                      .data_report.translations[lang].description
                : null;
        const searchDescription = searchValue !== '' && selectedDiagram ? selectedDiagram.data_report.translations[lang].description : null;

        const stackTokens = { childrenGap: 10 };

        const onModalButtonClick = (action, params) => (e) => {
            props.onModalButtonClick({ name: action }, params);
        };

        const primaryDisabled = false;

        const onChangeSearchValue = (e, searchValue) => {
            set_searchValue(searchValue);
        };

        return (
            <Wrapper>
                <Top>
                    <StTextField
                        // label={t('Поиск по имени проекта')}
                        placeholder={t('Поиск по имени диаграммы')}
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        // styles={{ fieldGroup: { width: 300 } }}
                    />
                </Top>
                <MainContainer>
                    {MainComponent}
                    {searchValue === '' ? (
                        <Description>
                            <span>{description}</span>
                        </Description>
                    ) : (
                        <Description>
                            <span>{searchDescription}</span>
                        </Description>
                    )}
                </MainContainer>
                <Footer>
                    <Stack horizontal tokens={stackTokens}>
                        <PrimaryButton
                            text={'OK'}
                            width={'100px'}
                            disabled={primaryDisabled}
                            onClick={onModalButtonClick('addDiagram', {
                                dataView: selectedDiagram,
                                group,
                                subsection: { id: group.subsection_id },
                            })}
                        />
                        <DefaultButton text={t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                    </Stack>
                </Footer>
            </Wrapper>
        );
    }
);

export default AddDiagramModal;

const StTextField = styled(TextField)`
    flex-shrink: 0;
    height: 32px !important;
    width: 400px !important;
    div {
        height: 32px !important;
    }
`;

const Top = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    padding-bottom: 10px;
    background-color: #fff;
`;

const Container = styled.div`
    margin-top: 20px;
    min-height: 400px;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
`;

const SearchContainer = styled.div`
    min-height: 400px;
    width: 80%;
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
`;

const ListItem = styled.li`
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    padding: 5px;
    background-color: ${(p) => (p.selected ? p.bgColor : 'transparent')};
    color: ${(p) => (p.disabled ? '#ccc' : p.selected ? '#fff' : '#6a7074')};
    display: flex;
    align-items: center;
`;

const FoundDiagrams = styled.div`
    border-right: 1px solid rgba(0, 0, 0, 0.3);
`;

const ChartIcon = styled(Icon)`
    flex-shrink: 0;
    font-size: 24px;
    margin-right: 5px;
`;

const FoundItem = styled.div`
    position: relative;
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    padding: 5px;
    background-color: ${(p) => (p.selected ? p.bgColor : 'transparent')};
    color: ${(p) => (p.disabled ? '#ccc' : p.selected ? '#fff' : '#6a7074')};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-gap: 5px;
`;

const NameWrapper = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
`;

const Description = styled.div`
    padding: 10px;
    padding-top: 0px;
    margin: 0;
    width: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    span {
        position: sticky;
        top: 42px;
        z-index: 100;
    }
`;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    padding-bottom: 1px;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    label:not(.FileLabel) {
        margin-top: 10px !important;
    }
`;
