// import hostName from "../../api/hostName";

class GeometryVSDataManager {
    static setPlanSet(data){
        return data;
    }
    static setRooms(data){
        this['roomObj'] = {}
        this['roomObjInId'] = {};

        for (var i = 0; i < data.length; i++) {
            this.roomObj[data[i].markers[0]] = data[i];
            this.roomObjInId[data[i].id] = data[i];
        }
        return this.roomObj;
    }
    static setFloors(data){
        this['floorObj'] = {};
        this.floorObj['obj'] = {}
        this.floorObj['arr'] = []


        for (var i = 0; i < data.length; i++) {
            this.floorObj['obj'][data[i].floor] = data[i].id
            this.floorObj['arr'].push(data[i])
        }

        return this.floorObj
    }
    static setUneqUser(data){
        var obj = {}
        var maxCount = +data[0].value;
        for(var i = 0; i < data.length; i++) {
            if (maxCount < +data[i].value) {
                maxCount = +data[i].value;
            }
            for(var key in this.roomObj) {
                if (data[i].obj_id === this.roomObj[key].id) {
                    obj[key] = data[i].value
                }
            }
        }
        this['persentsCount'] = {};
        for (var j = 0; j < data.length; j++) {
            this.persentsCount[this.roomObjInId[data[j].obj_id].markers[0]] = +data[j].value /  maxCount;
        }
        return obj;
    }
    static reportPersentWithRoom (marker) {

        return this.persentsCount[marker]
    }
    static reportPersentWithZone (marker) {
        if (this.persentsCountZones === undefined) {
            return 0;
        }
        return this.persentsCountZones[marker]
    }
    static setUneqUserZones(data) {
        if (data === null) {
            return null;
        }
        var obj = {}
        var maxCount = +data[0].value;
        for(var i = 0; i < data.length; i++) {
            if (maxCount < +data[i].value) {
                maxCount = +data[i].value;
            }
            for(var key in this.zonesObj.arr) {

                if (data[i].obj_id === this.zonesObj.arr[key].id) {
                    obj[this.zonesObj.arr[key].zone_marker] = data[i].value
                }
            }
        }
        this['persentsCountZones'] = {};
        for (var j = 0; j < data.length; j++) {
            this.persentsCountZones[this.zoneObjInId[data[j].obj_id].zone_marker] = +data[j].value /  maxCount;
        }
        return obj;
    }
    static setZones(data){
        // console.log('>>>>>>>>>>>>>>>>', data);
        this['zonesObj'] = {};
        this.zonesObj['obj'] = {};
        this.zonesObj['arr'] = [];
        this['zoneObjInId'] = {};
        // console.log('!@!@!@!@!@', data);
        for (var i = 0; i < data.length; i++) {
            this.zonesObj['obj'][data[i].zone_marker] = data[i];
            this.zonesObj['arr'].push(data[i])
            this.zoneObjInId[data[i].id] = data[i];
        }

        return this.zonesObj.obj;
    }
    clear(){

    }
    static reportObjWithPlace(place_marker){
        if (this.roomObj[place_marker] === undefined) {
            return undefined;
        }
        return this.roomObj[place_marker].id
    }

    static reportObjRoomsById(id){
        if (this.roomObjInId[id] === undefined) {
            return undefined;
        }
        return this.roomObjInId[id]
    }

    static reportNameWithPlace(place_marker){
        if (this.roomObj !== undefined) {
            if (this.roomObj[place_marker] === undefined) {
                return undefined;
            }
            return this.roomObj[place_marker].name;
        }
        return undefined;
    }

    static reportObjWithFloor(floor_number){
        if (this.floorObj === undefined) {
            return undefined;
        }
        return this.floorObj.obj[floor_number]
    }
    static reportIdWithZoneMarker (id) {
        for (var i = 0; i < this.zonesObj.arr.length; i++) {

            if (this.zonesObj.arr[i].id === id) {
                // console.log('>>>>>>>>>>>.!!!!!#@#@#@#', this.zonesObj.arr[i]);
                return this.zonesObj.arr[i].zone_marker;
            }
        }
    }
    static reportIdWithZoneName (id) {
        for (var i = 0; i < this.zonesObj.arr.length; i++) {
            // console.log('>>>>>>>>>>>.!!!!!#@#@#@#', this.zonesObj.arr[i]);
            if (this.zonesObj.arr[i].id === id) {
                return this.zonesObj.arr[i].zone_name;
            }
        }
    }
    static reportObjWithZone(zone_name){
        // console.log('@@@@@@@@@@1', zone_name, this.zonesObj.obj);
        if (this.zonesObj === undefined) {
            return null;
        }
        if (this.zonesObj.obj[zone_name] === undefined) {
            return null;
        }
        return this.zonesObj.obj[zone_name].id;
    }
}

export default GeometryVSDataManager;
