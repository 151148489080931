
import Control from './components/control';

import $ from 'jquery';

import RadiusApp from '../../reducers/RadiusReducer';
import styled from 'styled-components/macro';
import { ClipLoader } from 'react-spinners';

import { runAction } from '../../actions';
// import './style/bootstrap.css'

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hostForLocation from '../../hostnames/hostname'
var stateJSON = 0;

class AppRadius extends Component {
    static defaultProps = {};

    static propTypes = {};

    state = {};

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.token !== this.props.token) {
            this.getRequestTemplate(nextProps);
        }

        if (nextProps.pl_id !== undefined) {
            if (this.props.pl_id !== nextProps.pl_id) {
                // console.log('@@@@@@@@@@@@1');
                this.getSchedulesList(nextProps);
            }
        }
    }

    onResize(e) {
        this.setState({});
    }
    UNSAFE_componentWillMount() {
        this.getRequestTemplate(this.props);
        // console.log('@@@@@@@@@@@@2');
        this.getSchedulesList(this.props);
        window.onresize = this.onResize.bind(this);
    }

    getRequestTemplate(props) {
        props.getTemplateControlPage(props.token);
    }

    getSchedulesList(props) {
        // console.log('@@@@@@@@@@@@3');
        props.getSchedulesList(props.token, props.pl_id);
    }

    render() {
        let methodForSchedulesItem = {
            onDeletePlan: this.props.onDeletePlan,
            onMouseEnterSchedulesItem: this.props.onMouseEnterSchedulesItem,
            onMouseLeaveSchedulesItem: this.props.onMouseLeaveSchedulesItem,
            onClickEdit: this.props.onClickEdit,
        };

        return this.props.statusLeftPanel === 'hotspot_page_settings' ? (
            <div className="hotspotBox">
                <Control
                    statusShowPanelNamePage={this.props.statusShowPanelNamePage}
                    nameNewPage={this.props.nameNewPage}
                    schedulesList={this.props.schedulesList}
                    statusSpinerLoader={this.props.statusSpinerLoader}
                    dataTemplate={this.props.dataTemplate}
                    dataObjects={this.props.dataObjects}
                    hidePanelNameNewPage={this.props.hidePanelNameNewPage}
                    onChangeValueName={this.props.onChangeValueName}
                    onSubmitCreateNewPage={this.props.onSubmitCreateNewPage}
                    openModalNameNewPage={this.props.openModalNameNewPage}
                    statusShowSettings={this.props.statusShowSettings}
                    onClickCopy={this.props.onClickCopy}
                    onClickDelete={this.props.onClickDelete}
                    planing={this.props.planing}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseLeave={this.props.onMouseLeave}
                    showPage={this.props.showPage}
                    onClickSettings={this.props.onClickSettings}
                    idTemplateShowSettings={this.props.idTemplateShowSettings}
                    methodForSchedulesItem={methodForSchedulesItem}
                    token={this.props.token}
                    onClickDay={this.props.onClickDay}
                    _t={this.props._t}
                    onMouseEnterPlanOfCalendar={this.props.onMouseEnterPlanOfCalendar}
                    lang={this.props.lang}
                    objLangforBrowser={this.props.objLangforBrowser}
                    onClickLangBrowser={this.props.onClickLangBrowser}
                    statusLangBrowser={this.props.statusLangBrowser}
                />
                
            </div>
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        statusTopPanel: state.TopPanel.status,
        statusLeftPanel: state.TopPanel.statusLeftPanel,
        statusShowPanelNamePage: state.RadiusApp.statusShowPanelNameNewPage,
        nameNewPage: state.RadiusApp.nameNewPage,
        schedulesList: state.RadiusApp.schedulesList,
        statusSpinerLoader: state.RadiusApp.statusSpinerLoaderTemplate,
        dataTemplate: state.RadiusApp.template,
        dataObjects: null,
        token: state.LoginApp.token,
        statusShowSettings: state.RadiusApp.statusShowSettings,
        idTemplateShowSettings: state.RadiusApp.idTemplateShowSettings,
        arrayLocationSelectNotMulti: state.PanelLocationApp.arrayLocationSelectNotMulti,
        lang: state.TopPanel.lang,
        objLangforBrowser: state.RadiusApp.objLangforBrowser,
        statusLangBrowser: state.RadiusApp.statusLangBrowser,
        pl_id: state.ReportParametersManager.customInputParamsValues.pl_id,
        obj_id: state.ReportParametersManager.customInputParamsValues.obj_id,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeSelect: (e, index) => {
            let value = e.target.value + 1 === parseFloat(e.target.value) + 1 ? e.target.value : +e.target.value;
            if (isNaN(value) === true) {
                value = e.target.value;
            }
            dispatch(runAction('onChangeSelect', { value: value, index: index }));
        },
        hidePanelNameNewPage: (e) => {
            if (e.target === document.getElementById('backgroundModal') || e.target === document.getElementById('closePanelNamePage')) {
                dispatch(runAction('hidePanelNameNewPage', {}));
            }
        },
        onChangeValueName: (e) => {
            dispatch(runAction('onChangeNamePage', { data: e.target.value }));
        },
        openModalNameNewPage: () => {
            dispatch(runAction('showPanelNameNewPage', {}));
        },
        onSubmitCreateNewPage: (token, page_title) => {
            let url = `${hostForLocation.getHostName().cms}/content/create_page/`;
            var data = {
                access_token: token,
                page_title: page_title,
                API_HOST: '',
            };
            dispatch(runAction('setStatusSpinerPlan', {status:true}));
            dispatch(runAction('getCreateNewPage', { data: data, url: url }));
            dispatch(runAction('hidePanelNameNewPage', {}));
        },
        getTemplateControlPage: (token) => {
            let url = `${hostForLocation.getHostName().cms}/content/get_pages/?access_token=${token}`;
            dispatch(runAction('setStatusSpinerPlan', {status:true}));
            dispatch(runAction('getTemplateControlPage', { token: token, url: url }));
        },
        getSchedulesList: (token, idPl) => {
            // console.log('@@@@@@@@@@@@', idPl, token);
            if (idPl !== null && token !== null) {
                let url = `${hostForLocation.getHostName().cms}/content/schedules/?access_token=${token}&object_id=${idPl}`;
                dispatch(runAction('setStatusSpinerPlan', {status:true}));
                dispatch(runAction('getSchedulesList', { token: token, url: url }));
            }
        },

        //методы для SchedulesItem
        onDeletePlan: (id, token) => {
            console.log('onDeletePlan', ownProps);
            var statusDelete = window.confirm('Удалить планирование?');
            if (statusDelete === true) {
                var data = {
                    access_token: token,
                    schedule_id: id,
                };
                let url = `${hostForLocation.getHostName().cms}/content/delete_schedule/`;
                dispatch(runAction('setStatusSpinerPlan', {status:true}));
                dispatch(runAction('deletePlansItem', { data: data, url: url }));
            }
        },
        onMouseEnterSchedulesItem: (page_id) => {
            dispatch(runAction('onEnterPlan', { id: page_id }));
        },
        onMouseLeaveSchedulesItem: () => {
            dispatch(runAction('onEnterPlan', { id: null }));
        },
        onClickEdit: (e, prop) => {
            if (e.target.id !== 'deletePlan' && e.target.id !== 'iconDeletePlan') {
                dispatch(runAction('editPlan', { data: prop }));
            }
        },
        //end

        onClickCopy: (id, token) => {
            let url = `${hostForLocation.getHostName().cms}/content/copy_page/`;
            var data = {
                access_token: token,
                page_id: id,
            };
            dispatch(runAction('setStatusSpinerPlan', {status:true}));
            dispatch(runAction('getCopyPageTemplate', { data: data, url: url }));
        },
        onClickDelete: (id, token) => {
            var statusDelete = window.confirm('Удалить страницу?');
            if (statusDelete === true) {
                var data = {
                    access_token: token,
                    page_id: id,
                };
                let url = `${hostForLocation.getHostName().cms}/content/delete_page/`;
                dispatch(runAction('setStatusSpinerPlan', {status:true}));
                dispatch(runAction('getDeletePageTemplate', { data: data, url: url }));
            }
        },
        planing: (id, title) => {
            dispatch(runAction('openPanel', { id: id, title: title }));
            dispatch(runAction('planing', { id: id, title: title }));
        },
        onMouseEnter: (id) => {
            dispatch(runAction('onMouseEnterRadius', { status: true, id: id }));
        },
        onMouseLeave: (id) => {
            dispatch(runAction('onMouseLeaveRadius', { status: false }));
        },
        showPage: (uuid) => {
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

            var width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : window.screen.width;
            var height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : window.screen.height;
            var left = width / 2 - 440 / 2 + dualScreenLeft;
            var top = height / 2 - 700 / 2;
        
            window.open(
                `${hostForLocation.getHostName().cms}/previews/`+ uuid + '?saved_data=true',
                'Ratting',
                'width=440,height=700,center,status=0,scrollbars=1,resizable=true' + ', top=' + top + ', left=' + left
            );
        },
        onClickSettings: (data) => {
            dispatch(runAction('openSettingsPanel', { data: data }));
        },
        onClickDay: (e) => {
            if ($(e.target).parents().filter('#Monday').length === 1 || $(e.target).filter('#Monday').length) {
                dispatch(runAction('getDay', { day: 'Monday' }));
            } else if ($(e.target).parents().filter('#Tuesday').length === 1 || $(e.target).filter('#Tuesday').length) {
                dispatch(runAction('getDay', { day: 'Tuesday' }));
            } else if ($(e.target).parents().filter('#Wednesday').length === 1 || $(e.target).filter('#Wednesday').length) {
                dispatch(runAction('getDay', { day: 'Wednesday' }));
            } else if ($(e.target).parents().filter('#Thursday').length === 1 || $(e.target).filter('#Thursday').length) {
                dispatch(runAction('getDay', { day: 'Thursday' }));
            } else if ($(e.target).parents().filter('#Friday').length === 1 || $(e.target).filter('#Friday').length) {
                dispatch(runAction('getDay', { day: 'Friday' }));
            } else if ($(e.target).parents().filter('#Saturday').length === 1 || $(e.target).filter('#Saturday').length) {
                dispatch(runAction('getDay', { day: 'Saturday' }));
            } else if ($(e.target).parents().filter('#Sunday').length === 1 || $(e.target).filter('#Sunday').length) {
                dispatch(runAction('getDay', { day: 'Sunday' }));
            }
        },
        onMouseEnterPlanOfCalendar: (id) => {
            // console.log('control.jsx', typeof data, data);
            if (typeof data === 'number') {
                // dispatch(ApprunAction('getPlanId', {id:id}));
            } else if (typeof data === 'object') {
                // dispatch(ApprunAction('getPlanId', {id:null}));
            }
        },
        onClickLangBrowser: (status) => {
            dispatch(runAction('onClickLangBrowser', { status: status }));
        },
    };
};

const SpinnerContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
`;

const ConnectApp = connect(mapStateToProps, mapDispatchToProps)(AppRadius);

export default ConnectApp;
