import React, { Component } from 'react';
import TopPanel from './components/TopPanel';
import { connect } from 'react-redux';
import { runAction } from '../../actions';
import hostForLocation from '../../hostnames/hostname';

class BoxTopPanel extends Component {
    UNSAFE_componentWillMount() {
        this.props.getRequestSections(this.props.token);
    }

    render() {
        return (
            // <div id={'boxTopPanel'}>
            <TopPanel
                cookie={this.props.cookie}
                _t={this.props._t}
                lang={this.props.lang}
                selected={this.props.selected}
                history={this.props.history}
            />
            // </div>
        );
    }
}

BoxTopPanel.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.LoginApp.token,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRequestSections: (token) => {
            var url = `${hostForLocation.getHostName().analytics}/content_structure/section_list/`;
            var headers = {
                'x-token': token,
                'Content-type': 'application/json',
            };
            fetch(url, { method: 'GET', headers, mode: 'cors', redirect: 'follow' })
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    dispatch(runAction('setSections', { json }));
                    return json;
                });
            // .then(json => {
            //     dispatch(runAction('getSections_Source', { json }));
            // });
        },
    };
};

const ConnectBoxTopPanel = connect(mapStateToProps, mapDispatchToProps)(BoxTopPanel);

export default ConnectBoxTopPanel;
