import React, { useEffect } from 'react';

export default React.memo(props => {
    const autoUpdate = props.settings.filter(item => item.id === 1)[0].checked;
    const INTERVAL = 600000;

    useEffect(() => {
        let interval;
        if (!autoUpdate) {
            clearInterval(interval);
        } else {
            interval = setInterval(() => {
                props.onClickReloadDiagram(props.dataView, null, props.inputParamsValues);
            }, INTERVAL + (Math.random() * INTERVAL) / 10);
        }
        return () => {
            clearInterval(interval);
        };
    }, [autoUpdate, props.inputParamsValues]);

    return <React.Fragment></React.Fragment>;
});
