import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import ExpandArrow from './expand_arrow';
import Dash from '../../pl_panel/components/dash';

export default React.memo(({ history, item, ...props }) => {
    const symbolClassName =
        item.type === 'owner'
            ? 'fa fa-child'
            : item.type === 'project'
            ? 'fas fa-project-diagram'
            : item.type === 'pl'
            ? 'fas fa-thumbtack'
            : null;
    const expand = history.ownersTree[item.id] ? history.ownersTree[item.id].expand : false;
    const counter = item.counter !== undefined ? `(${item.counter})` : '';
    const titleOpacity = item.counter === undefined || item.counter === 0 ? 0.5 : 1;

    const listItem = useMemo(() => {
        if (item.type !== 'dataobjset') {
            return counter === '' || counter === '(0)' ? null : (
                <Item>
                    <Title titleOpacity={titleOpacity}>
                        <Symbol className={symbolClassName} />
                        {`${item.name} ${counter}`}
                        {props.hasChildren && <ExpandArrow id={item.id} expand={expand} toggleExpandArrow={props.toggleExpandArrow} />}
                    </Title>
                    {expand ? props.children : null}
                </Item>
            );
        } else {
            const dashText = `${item.name} (${item.bound_dataobjs.length})` || '';
            const id = Number(item.id.match(/\d+/)[0]);
            const plId = item.parent.match(/[A-Za-z]+/)[0] === 'pl' ? Number(item.parent.match(/\d+/)[0]) : undefined;
            const statusSelect = plId
                ? history.objSets.hasPl[plId].sets.filter(set => set.id === id)[0].selected
                : history.objSets.noPl.filter(set => set.id === id)[0].selected;
            const сontextMenu = {
                show: true,
                type: 'objSet'
            };

            return (
                <Item>
                    <Dash
                        id={id}
                        name={item.name}
                        plId={plId}
                        сontextMenu={сontextMenu}
                        onClick={props.onObjSetDashClick}
                        backColor={'#bedfff'}
                        text={dashText}
                        statusSelect={statusSelect}
                        showDeleteObjectsButtons={false}
                        onDeleteDashButtonClick={() => {}}
                    />
                </Item>
            );
        }
    }, [item, history]);
    return <React.Fragment>{listItem}</React.Fragment>;
});

const Item = styled.li`
    list-style: none;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 2px 0;
`;

const Title = styled.span`
    opacity: ${p => p.titleOpacity};
    /* background-color: #ddd; */
    padding: 2px 5px;
    /* border: 1px solid #777;
    border-radius: 5px; */
    /* margin: 3px 0; */
    display: flex !important;
    align-items: center;
`;
const Arrow = styled.i`
    cursor: pointer;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #777; */
    &:hover {
        border: 1px solid #777;
    }
`;
const Symbol = styled.i`
    margin-right: 10px;
    display: flex !important;
    color: rgb(19,119,225);
    /* color: ${p => (p.hasChildren ? 'rgb(75,181,119)' : 'rgb(19,119,225)')}; */
`;
