import React from 'react';
import styled from 'styled-components/macro';

const Div = styled.div`
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(244, 244, 244);
  }
  background-image: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);

  /* Box */
  height: 30px;
  width: 30px;

  cursor: pointer;
`;

export default class ZoomWorldControl extends React.Component {
  render() {
    return (
      <Div onClick={this.props.onZoomWorldClick}>
        <svg width="24" height="24" viewBox="0 0 611.55 610.87" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m503.32 71.86c-62.78-52.52-136.42-76.54-218.7-71.11a301 301 0 0 0 -211.92 107.38c-52.52 62.78-77.24 136.6-72.02 218.42a297.88 297.88 0 0 0 32.2 116.84c18.76 37 43.82 69 75.34 95.38 62.88 52.6 137.17 76.62 218.86 71.41a301.19 301.19 0 0 0 211.92-107.38c53-63.15 77.07-137 71.86-218.72a301.9 301.9 0 0 0 -107.54-212.22zm-400.74 57.51q-12.67 62.7 5.69 140l-71.33 36.18c.4-64.35 23.86-127.63 65.64-176.18zm-36.58 297.24a265.22 265.22 0 0 1 -25.87-81.42l78.2-39.62a557.78 557.78 0 0 0 32.44 78.09 551.67 551.67 0 0 0 43.48 71.74l-78.8 39.93a273.16 273.16 0 0 1 -49.45-68.72zm80.12 94.45 71.34-36.15c34.79 41.26 71.6 71 110.16 88.6-65.06 4.83-129.96-13.67-181.49-52.45zm263.08 29.86c-22.27 4.17-47.74-.94-75.95-15.9s-55.42-37.56-81.47-67.5l91.63-46.43zm-82.41-162.67-99.1 50.22c-17.34-24.6-32-48.32-43.78-71.6a477.93 477.93 0 0 1 -31.54-78.55l98.5-49.9 75.92 149.84zm-92.56-182.66-91.63 46.41c-8-38.71-9.88-73.7-5.2-105 4.71-31.59 15-54.85 31.65-70.05l65.18 128.65zm231-117.06-71.34 36.14q-51.5-60.56-109.56-87.4c63.9-4.99 128.8 13.52 180.93 51.26zm-262.45-28.68c22.12-4.46 46.94 1 75.2 15.91 28 14.75 55.12 37 81.61 66.31l-91.59 46.43zm81.82 161.48 98.49-49.91a477.85 477.85 0 0 1 44.68 71.88c11.8 23.29 22.26 49.12 31.84 77.65l-99.09 50.21zm158.34 312.5-65.79-129.83 91.63-46.43c8.45 38.86 10.35 74.13 6 105.77s-14.86 55.14-31.84 70.49zm66.47-52.42c8.6-41.5 6.4-88.78-6.3-141.24l71.34-36.17c.8 64.52-22.67 127.8-65.04 177.41zm62.43-217.32-78.84 39.91a549.33 549.33 0 0 0 -32.14-77.5 555.78 555.78 0 0 0 -43.78-72.34l78.2-39.63a265.15 265.15 0 0 1 50.35 69 273 273 0 0 1 26.21 80.56z"
            fill="#0041d0"
          />
        </svg>
      </Div>
    );
  }
}
