import React from 'react';
import Table from '../components/table';
import '../styles/complete_tables.scss';

export default class ReportParams extends React.PureComponent {
  render() {
    const { reportParams } = this.props;
    
    return (
      <div className={'TableContainer'}>
        <Table handsData={reportParams} />
      </div>
    );
  }
}
