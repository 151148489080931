

import Calendar from './Calendar'
import EventWrapper from './EventWrapper'
import BackgroundWrapper from './BackgroundWrapper'
import momentLocalizer from './localizers/moment'
import globalizeLocalizer from './localizers/globalize'
import move from './utils/move'
import { views, navigate } from './utils/constants'
var store = require('./store.js');

Object.assign(Calendar, {
    globalizeLocalizer,
    momentLocalizer,
    Views: views,
    Navigate: navigate,
    move,
    components: {
        eventWrapper: EventWrapper,
        dayWrapper: BackgroundWrapper,
        dateCellWrapper: BackgroundWrapper,
    },
    setSchedulesList:function (data) {
        // console .log(store);
        store.getTitle(data);
    },
    setDefaultTemplate:function (data) {
        // console .log(store);
        store.setDefaultTemplate(data);
    },
    getLang:function (data) {
        // console .log(store);
        store.getLang(data);
    }
})

export default Calendar

