import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Search from '../pl_panel/components/search';
import PlSelectionList from './components/pl_select_list';
import SelectDeselectButtons from '../pl_panel/components/selectDeselectButtons';

const Sticky = styled.div`
    position: sticky;
    top: 0;
    background-color: inherit;
    padding-bottom: 5px;
`;

const Container = styled.div`
    width: 243px;
    height: calc(100% - 50px);
    background: #fff;
    position: fixed;
    overflow: auto;
    left: 238px;
    padding: 10px;
    padding-top: 0;
    top: 50px;
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 301;
`;

const Header = styled.div`
    height: 60px;
    width: 100%;
    padding-top: 10px;
    border-bottom: 1px solid #777;
    .item {
        height: 100%;
        float: left;
        width: 50%;

        .title {
            margin-top: 9px;
            text-transform: uppercase;
            font-size: 12px;
            color: #8c959e;
            margin-bottom: 6px;
            display: inline-block;
        }
        .countLocations {
            display: block;
            line-height: 12px;
            width: 111px;
            color: #8c959e;
            font-size: 13px;
        }
        button {
            background: #c2c8ce;
            color: #fff;
            border: none;
            margin-top: 14px;
            float: right;
            margin-right: 10px;
            font-size: 13px;
            padding: 2px 7px 3px;
        }
    }
`;

const PlSelectPanel = React.memo(({ reportingObjectsByPlId_ctg, history, onLocationClick, lang, searchLocationsValue, mode, ...props }) => {
    const { t } = useTranslation();
    const selectedNumber = Object.keys(history.locations).filter((id) => history.locations[id].active).length;

    // const handleSearch = e => {
    //   e.preventDefault();
    //   props.handleSearch(e.target.value);
    // };

    let filteredList = [];
    try {
        filteredList = Object.keys(reportingObjectsByPlId_ctg).filter((id) => history.locations[id].isSearch);
    } catch (error) {
        console.warn(error);
    }

    const onSelectAllClick = () => {
        props.onSelectAllClick(filteredList);
    };

    return (
        <Container>
            <Sticky>
                <Header>
                    <div className="item">
                        <h5 className="title">{t('Локации')}</h5>
                        {mode !== 'singleLocation' && <span className="countLocations">{`${t('Выбрано')} (${selectedNumber})`}</span>}
                    </div>
                </Header>
                <Search searchValue={searchLocationsValue} handleSearch={props.handleSearch} lang={lang} />

                {mode !== 'singleLocation' && (
                    <SelectDeselectButtons onSelectAllClick={onSelectAllClick} onClearAllClick={props.onClearAllClick} />
                )}
            </Sticky>
            <PlSelectionList
                mode={mode}
                filteredList={filteredList}
                onLocationClick={onLocationClick}
                history={history}
                reportingObjectsByPlId_ctg={reportingObjectsByPlId_ctg}
            />
        </Container>
    );
});

export default PlSelectPanel;
