var state = {
    title: null,
    schedulesList: [],
    idPlan: null,
    defaultTemplate: {},
    dataTollbar: {}
};

var store = {
    moduleChangeStoreListeners: [],
    addChangeStoreModuleListener: function(callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    },
    removeChangeStoreModuleListener: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    },
    emitChangeToModuleListeners: function () {
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](state);
        }
    },
    moduleChangeStoreListeners2: [],
    addChangeStoreModuleListener2: function(callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    },
    removeChangeStoreModuleListener2: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    },
    emitChangeToModuleListeners2: function () {
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](state);
        }
    },
    getTitle: function (data) {
        // console.log('store.js', data);
        state.schedulesList = data;
        this.emitChangeToModuleListeners();
    },
    getIdPlan: function (id) {
        state.idPlan = id;
        this.emitChangeToModuleListeners2();
        // console.log('store.js', id);
    },
    getLang: function (lang) {
        state.dataTollbar = lang;
        this.emitChangeToModuleListeners2();
    },
    setDefaultTemplate: function (data) {
        state.defaultTemplate = data;
        this.emitChangeToModuleListeners();
    },
    getState: function () {
        return state;
    }
};

module.exports = store;