import connect from "react-redux/es/connect/connect";


import colors from '../../../../tools/colors.js';


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class ImgPreview extends Component {
    static defaultProps = {

    };

    static propTypes = {};

    state = {};
    internalState= {
        styleActive: {
            outline: '2px solid rgba(1, 58, 51, .5)'
        },
        styleDefault: {
            outline: '1px solid rgba(1, 58, 51, .5)'
        }
    }

    setMapId() {
        // console.log('!!!!!!!!!!!', this.props.index)
        this.props.getFloorId(this.props.index)

	}

    render() {
        let Link = this.props.Link;
        if (this.props.statusSelected == false && this.props.selectedAreaIndex != null && this.props.mode !== 'router') {
            var distribution = this.props.routerItems[this.props.floorIndex].intersections[this.props.zoneId];
            var persentColor;
            // this.props.id
            if (document.getElementById('preview' + this.props.id) !== null) {
                var x = document.getElementById('preview' + this.props.id).getBoundingClientRect().width;
                var xcorrect = (x - 40) / 2;
            }
            else if (document.getElementById('preview' + this.props.id) === null) {
                var xcorrect = 0;
            }
            for(var i = 0; i < distribution.floor_distribution.length; i++) {
                if (this.props.id == distribution.floor_distribution[i].floor){
                    persentColor = distribution.floor_distribution[i].percent;
                }
            }
            var svgNode = <g>
                <circle cx='50%' cy='50%' fill={colors.getHeatColor(persentColor / 100)} r={50 + 'px'} />
                <text fill='#000' x={xcorrect} y="70%" >{persentColor + '%'}</text>
            </g>
            var persent = <p className='persent'>100%</p>
        }
        else {
            var svgNode = null;
            var persent = null;
        }


        if (this.props.uuidPlanSet !== null) {
            this.internalState = {
                styleActive: {
                    border: '2px solid rgba(138,144,154, .9)',
                    background: 'rgba(255,255,255,.5)',
                    color: 'rgba(138,144,154, .9)'
                },
                styleDefault: {
                    border: '2px solid rgba(255,255,255, .9)',
                    background: 'rgba(0,0,0,.5)',
                    color: 'rgba(255,255,255, .9)'
                }
            }
            if (this.props.roomsTransition[this.props.id] !== undefined) {
                this.internalState.styleDefault.background = colors.getHeatColor(this.props.roomsTransition[this.props.id].intersection_percent / 100)
            }
            else {
                this.internalState.styleDefault.background = 'rgba(0,0,0,.5)'
            }
            // console.log('$$$$$$$$$', this.props)
            return (
                <Link
                    style={this.props.statusSelected ? this.internalState.styleActive : this.internalState.styleDefault}
                    id={'preview' + this.props.id} onClick={this.setMapId.bind(this)}
                    className="itemPreview"
                    activeClass="active"
                    to={`componentItemPlans${this.props.floorIndexSelected}`}
                    spy={true}
                    smooth={true}
                    duration={250}
                    containerId="holstBlock"
                >

                    <span className={'itemFloor'}>{this.props.id}</span>
                </Link>
            );
        }
        else if (this.props.uuidPlanSet === null) {
            this.internalState = {
                styleActive: {
                    outline: '2px solid rgba(1, 58, 51, .5)'
                },
                styleDefault: {
                    outline: '1px solid rgba(1, 58, 51, .5)'
                }
            }
            return (
                <div id={'preview' + this.props.id} onClick={this.setMapId.bind(this)} className="itemPreviewOld">
                    <img id={'previewImg' + this.props.id} src={this.props.prop.link} style={this.props.statusSelected ? this.internalState.styleActive : this.internalState.styleDefault} />
                    <svg height={100 + '%'} width={100 + '%'}>
                        {svgNode}
                    </svg>
                    <p>{this.props.id + " " + "Этаж"} </p>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        previewItem: state.MapsApp.mapPreviewItem,
        selectedAreaIndex:state.MapsApp.selectedAreaIndex,
        routerItems:state.MapsApp.routerItems,
        floorIndex:state.MapsApp.floorIndex,
        zoneId:state.MapsApp.zoneId
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {


    }
};

const ConnectImgPreview = connect(
    mapStateToProps,
    mapDispatchToProps
)(ImgPreview);

export default ConnectImgPreview;
