import React, {useState} from 'react';
import styled from "styled-components/macro";
import {getTheme} from "office-ui-fabric-react/lib/Styling";

const DaysOfWeek = (props) => {
    const {
        stringsDays,
        setSelectedDay,
        selectedDay
    } = props;
    const [theme] = useState(getTheme());

    let componentNodeDay = []

    const onClickDay = (index) => (e) => {
        // console.log('>>>>>>>>>>>', index)
        setSelectedDay(index);
    }
    stringsDays.forEach((item, index) => {
        if (index > 0) {
            componentNodeDay.push(
                <SCWrapperDay
                    key={`kdsa${index}`}
                    onClick={onClickDay(index - 1)}
                    selected={selectedDay === index - 1}
                    primary={theme.palette.themePrimary}
                >
                    {item}
                </SCWrapperDay>
            )
        }

    });

    componentNodeDay.push(
        <SCWrapperDay
            onClick={onClickDay(6)}
            selected={selectedDay === 6}
            primary={theme.palette.themePrimary}
            statusEnd={true}
            key={`kdsa${7}`}
        >
            {stringsDays[0]}
        </SCWrapperDay>
    )

    return (
        <SCWrapperWeekDays>
            {componentNodeDay}
        </SCWrapperWeekDays>
    );
};

export default DaysOfWeek;

const SCWrapperWeekDays = styled.div`
  height: 32px;
  width: 238px;
  border-radius: 2px;
  border: 1px solid rgb(153,153,153);
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: repeat(7, calc(100% / 7)) ;
`;

const SCWrapperDay = styled.div`
  border-right: ${
    p => p.statusEnd ? null :
    '1px solid rgb(153, 153, 153)'
  };
  display: grid;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: ${p => p.selected ? p.primary : null};
  color: ${p => p.selected ? '#fff' : null};
`;
