const initState = {
    segmentsToShow: 10,
    addSegmentsNumber: 10,
    segments: null,
    foundSegments: null,
    segmentSearchValue: '',
    isShowSpinnerSegments: false
};

const getSegments_CustomControls = (state, action) => {
    const segments = action.payload.json;
    return { ...state, segments, isShowSpinnerSegments: false };
};
const onShowMoreClick_CustomControls = (state, action) => {
    return { ...state, segmentsToShow: state.segmentsToShow + state.addSegmentsNumber };
};
const onChangeSegmentSearchValue_CustomControls = (state, action) => {
    const { segmentSearchValue } = action.payload;
    return { ...state, segmentSearchValue };
};
const getRequestSegments_CustomControls = (state, action) => {
    // const { segmentSearchValue } = action.payload;
    return { ...state, isShowSpinnerSegments: true };
};
const getFoundSegments_CustomControls = (state, action) => {
    const { json } = action.payload;
    return { ...state, foundSegments: json };
};

const CustomControls = (state = initState, action) => {
    switch (action.type) {
        case 'getSegments_CustomControls':
            return getSegments_CustomControls(state, action);
        case 'getRequestSegments_CustomControls':
            return getRequestSegments_CustomControls(state, action);
        case 'onShowMoreClick_CustomControls':
            return onShowMoreClick_CustomControls(state, action);
        case 'onChangeSegmentSearchValue_CustomControls':
            return onChangeSegmentSearchValue_CustomControls(state, action);
        case 'getFoundSegments_CustomControls':
            return getFoundSegments_CustomControls(state, action);

        default:
            return state;
    }
};

export default CustomControls;
