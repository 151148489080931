



import React from 'react';
import PropTypes from 'prop-types';
var stateJSON = 0;

function Template({
                      color,
                      statusSchedul,
                      prop,
                      statusShowSettings,
                      onClickCopy,
                      onClickDelete,
                      planing,
                      onMouseEnter,
                      onMouseLeave,
                      showPage,
                      onClickSettings,
                      idTemplateShowSettings,
                      token,
                      _t
				  }) {
    var statusSchedul, titleTemplate = prop.title, disableComponent = null, styleOutline = {outline: '1px solid ' + color}, componentSattings = null, componentNotAuth = null, componentDefault = null;
    if (prop.title.length > 11) {
        titleTemplate = prop.title.substring(0, 5) + '...' + prop.title.substring(prop.title.length - 4, prop.title.length);
    }
    if (prop.is_default === true) {
        componentDefault = <div className='defaultComponent'>
            <div></div>
            <div></div>
            <div></div>
            <div className='tultipDafault'>{_t.t('Для редактирования шаблона лэндинга создайте копию')}</div>
        </div>
    }
    if (prop.statusEnter === true) {
        styleOutline = {boxShadow: '0 0 10px ' + color, outline: '1px solid ' + color};
    }
    if (JSON.parse(prop.data).authParams === null || JSON.parse(prop.data).authParams === undefined) {
        componentNotAuth = <div className='templateNotAuth'>{_t.t('Что бы запланировать данную страницу необходимо настроить параметры авторизации')}</div>
        disableComponent = <div className='radiusPlanButtonDisabled'></div>
    }
    else {
        if (statusSchedul === true) {
            disableComponent = <div className='radiusPlanButtonDisabled'></div>
        }
    }
    if (statusShowSettings === true && idTemplateShowSettings === prop.id) {
        componentSattings = <div className='radiusTemlateOptionsBox'>
             <span style={{background: color}}>
                            <span
                                style={{
                                    display: 'inline-block',
                                    marginTop: '3px'
                                }}>
                                {titleTemplate}
                            </span>
                        </span>
            <button onClick={(e) => showPage(prop.uuid)}>{_t.t('Посмотреть')}</button>
            <button onClick={(e) => onClickCopy(prop.id,token)}>{_t.t('Копировать')}</button>
            <button onClick={(e) => onClickDelete(prop.id, token)}>{_t.t('Удалить')}</button>
            <button onClick={(e) => onClickSettings(prop)}>{_t.t('Настроить')}</button>
            {disableComponent}
            {componentNotAuth}
            <button onClick={(e) => planing(prop.id,prop.title)}>{_t.t('Запланировать')}</button>
            {componentDefault}
        </div>
    }



    // console.log('><!!!>!!!>>>>2', prop.preview, prop.title)

    return (
        <div onMouseLeave={(e) => onMouseLeave(prop.id)} onMouseEnter={(e) => onMouseEnter(prop.id)} style={styleOutline} className='radiusTemlate'>
            <div style={{
                overflow: 'hidden',
                height: '100%'
            }}>
                <img src={prop.preview}  />
                <div className='radiusTemplateBoxTitle'>
                    <span style={{background: color}}>
                            <span
                            style={{
                                display: 'inline-block',
                                marginTop: '3px'
                            }}>
                                {titleTemplate}
                            </span>
                        </span>
                </div>
                {componentSattings}

            </div>

        </div>
    );
}

Template.propTypes = {};
Template.defaultProps = {};

export default Template;
