import Tools from '../tools/tools';

const setLocationData = (state, action) => {
    if (state.arrayLocationSelectMulti.length === 0 && state.arrayLocationSelectNotMulti.length === 0) {
        for (let i = 0; i < action.json.length; i++) {
            action.json[i]['allObject'] = {
                id: null,
                statusSelect: true,
            };
        }
        var obj = {};
        obj[action.json[0].id] = action.json[0];
        var arr3 = [action.json[0]];
        if (state.urlObjIds.length > 0) {
            arr3 = [];
            obj = {};
            let obj2 = {};
            for (let j = 0; j < action.json.length; j++) {
                for (let g = 0; g < state.urlObjIds.length; g++) {
                    if (action.json[j].id === +state.urlObjIds[g]) {
                        arr3.push(action.json[j]);
                        obj[action.json[j].id] = action.json[j];
                    }
                }
                if (action.json[j].id === +state.urlObjIds[state.urlObjIds.length - 1]) {
                    obj2[state.urlObjIds[state.urlObjIds.length - 1]] = action.json[j];
                }
            }
            return Object.assign({}, state, {
                objLocationSelect: obj,
                objLocationControlNotSelect: obj,
                arrayLocationSelectMulti: arr3,
                arrayLocationSelect: arr3,
                objLocationControlSelect: obj2,
                arrayLocationSelectNotMulti: [arr3[arr3.length - 1]],
                locations: action.json,
            });
        }
        return Object.assign({}, state, {
            objLocationSelect: obj,
            objLocationControlNotSelect: obj,
            arrayLocationSelectMulti: arr3,
            arrayLocationSelect: arr3,
            objLocationControlSelect: obj,
            arrayLocationSelectNotMulti: arr3,
            locations: action.json,
        });
    } else {
        for (let i = 0; i < action.json.length; i++) {
            action.json[i]['allObject'] = {
                id: null,
                statusSelect: true,
            };
        }
        return Object.assign({}, state, {
            locations: action.json,
        });
    }
};

const selectHotspotTopPanel = (state, action) => {
    if (action.reqInput.indexOf('pl_ids') > 0) {
        const array = Tools.copy(state.arrayLocationSelectMulti);
        const obj = Tools.copy(state.objLocationControlNotSelect);
        if (state.arrayLocationSelectNotMulti[0] !== undefined) {
        }
        return Object.assign({}, state, {
            objLocationSelect: obj,
            arrayLocationSelect: array,
            arrayLocationSelectMulti: array,
            objLocationControlNotSelect: obj,
        });
    } else if (action.reqInput.indexOf('pl_id') > 0) {
        const array = Tools.copy(state.arrayLocationSelectMulti);
        let obj = Tools.copy(state.objLocationControlSelect);
        let arrayData = [];
        if (array.length > 0 && Object.keys(state.objLocationControlSelect).length === 0) {
            obj = {};
            obj[array[array.length - 1].id] = array[array.length - 1];
            arrayData = [array[array.length - 1]];
        } else if (Object.keys(state.objLocationControlSelect).length > 0) {
            arrayData = state.arrayLocationSelectNotMulti;
        }
        return Object.assign({}, state, {
            objLocationSelect: obj,
            arrayLocationSelect: arrayData,
            objLocationControlSelect: obj,
            arrayLocationSelectNotMulti: arrayData,
        });
    }
    return Object.assign({}, state, null);
};

const closePanelLocations = (state, action) => {
    return Object.assign({}, state, {
        statusShowPanelLocations: false,
    });
};

const getSelectLocation = (state, action) => {
    if (action.status !== 'control') {
        let array = Tools.copy(state.arrayLocationSelectMulti);
        let statusDeleteItem = false;
        let obj = Tools.compare(state.objLocationSelect);
        let arrReportIds = Tools.copy(state.arrReportIds);
        let objLocationControlSelect = Tools.copy(state.objLocationControlSelect);
        let arrayLocationSelectNotMulti = Tools.copy(state.arrayLocationSelectNotMulti);
        for (let i = 0; i < state.locations.length; i++) {
            if (state.locations[i].id === action.id) {
                if (obj[action.id] === undefined) {
                    obj[action.id] = state.locations[i];
                    array.push(state.locations[i]);
                } else {
                    delete obj[action.id];
                    statusDeleteItem = true;
                }
            }
        }
        if (statusDeleteItem === true) {
            for (let j = 0; j < array.length; j++) {
                if (array[j].id === action.id) {
                    for (let key in array[j].detail) {
                        if (key === 'zones' || key === 'floors' || key === 'servicepoints') {
                            for (let r = 0; r < array[j].detail[key].length; r++) {
                                if (arrReportIds.indexOf(array[j].detail[key][r].id) > -1) {
                                    arrReportIds.splice(arrReportIds.indexOf(array[j].detail[key][r].id), 1);
                                }
                            }
                        }
                    }

                    array.splice(j, 1);
                }
            }
        }
        if (array[array.length - 1] !== undefined) {
            objLocationControlSelect = {};
            objLocationControlSelect[array[array.length - 1].id] = array[array.length - 1];
            arrayLocationSelectNotMulti = [];
            arrayLocationSelectNotMulti = [array[array.length - 1]];
        }
        return Object.assign({}, state, {
            objLocationSelect: obj,
            objLocationControlNotSelect: obj,
            objLocationControlSelect: objLocationControlSelect,
            arrayLocationSelectMulti: array,
            arrayLocationSelect: array,
            arrayLocationSelectNotMulti: arrayLocationSelectNotMulti,
            arrReportIds: arrReportIds,
        });
    } else {
        let obj = {};
        let array = [];
        let obj2 = Tools.copy(state.objLocationControlNotSelect);
        let array2 = Tools.copy(state.arrayLocationSelectMulti);

        for (let i = 0; i < state.locations.length; i++) {
            if (state.locations[i].id === action.id) {
                if (obj[action.id] === undefined) {
                    obj[action.id] = state.locations[i];
                    array = [state.locations[i]];
                }
                if (obj2[action.id] === undefined) {
                    obj2[action.id] = state.locations[i];
                    array2.push(state.locations[i]);
                }
            }
        }
        return Object.assign({}, state, {
            objLocationControlSelect: obj,
            objLocationControlNotSelect: obj2,
            arrayLocationSelectMulti: array2,
            objLocationSelect: obj,
            arrayLocationSelectNotMulti: array,
            arrayLocationSelect: array,
        });
    }
};

const onClickAllLocation = (state, action) => {
    if (action.status === 'offAll') {
        return Object.assign({}, state, {
            objLocationSelect: {},
            objLocationControlNotSelect: {},
            arrayLocationSelect: [],
            arrayLocationSelectMulti: [],
        });
    } else {
        if (state.valueSearch === '') {
            var array = [];
            var obj = Tools.compare(state.objLocationSelect);
            for (var i = 0; i < state.locations.length; i++) {
                obj[state.locations[i].id] = state.locations[i];
                array.push(state.locations[i]);
            }
            return Object.assign({}, state, {
                objLocationSelect: obj,
                objLocationControlNotSelect: obj,
                arrayLocationSelectMulti: array,
            });
        } else {
            let array = [];
            let obj = Tools.compare(state.objLocationSelect);
            for (let i = 0; i < state.searchArr.length; i++) {
                obj[state.searchArr[i].id] = state.searchArr[i];
                array.push(state.searchArr[i]);
            }
            return Object.assign({}, state, {
                objLocationSelect: obj,
                objLocationControlNotSelect: obj,
                arrayLocationSelectMulti: array,
            });
        }
    }
};

const onChancgeInputSearch = (state, action) => {
    if (action.value !== '') {
        var arr = [];
        for (var i = 0; i < state.locations.length; i++) {
            if (state.locations[i].name.toUpperCase().search(action.value.toUpperCase()) !== -1) {
                arr.push(state.locations[i]);
            }
        }
        return Object.assign({}, state, {
            valueSearch: action.value,
            searchArr: arr,
        });
    } else {
        return Object.assign({}, state, {
            valueSearch: action.value,
            searchArr: [],
        });
    }
};

const onClickButtonLocations = (state, action) => {
    return Object.assign({}, state, {
        statusShowPanelLocations: action.status,
    });
};

const getIdPL = (state, action) => {
    var obj = {
        floors: [],
        servicepoints: [],
        zones: [],
        allObject: {
            id: action.id,
            statusSelect: true,
        },
    };
    var titlePanelReporting = '';
    for (var i = 0; i < state.locations.length; i++) {
        if (state.locations[i].id === action.id) {
            titlePanelReporting = state.locations[i].name;

            if (state.locations[i].detail !== undefined) {
                obj = state.locations[i].detail;
            }
            if (state.locations[i].allObject !== undefined) {
                obj.allObject = state.locations[i].allObject;
            }
        }
    }
    let objTemp = {};
    for (var j = 0; j < obj.zones.length; j++) {
        objTemp[obj.zones[j].group_marker] = false;
    }
    return Object.assign({}, state, {
        showDataPL: obj,
        idPL: action.id,
        titlePanelReporting: titlePanelReporting,
        objZonesGroupMarker: objTemp,
    });
};

const getReportingObjects = (state, action) => {
    let data = Tools.copy(action.payload.analytics_report_objects);
    let reportObjects = Tools.copy(action.payload.analytics_report_objects);
    let urlIdsObjReport = Tools.copy(state.urlIdsObjReport);

    let objDataByObjId = {};

    let tempShow = data;
    let allObject = {
        id: reportObjects[0].main_servicepoint.id,
        statusSelect: true,
    };
    tempShow['allObject'] = allObject;

    let locations = Tools.copy(action.payload.locations);
    let ObjReportings = {};
    let objLocationControlNotSelect = Tools.copy(state.objLocationControlNotSelect);
    let objLocationControlSelect = Tools.copy(state.objLocationControlSelect);
    let arrayLocationSelectMulti = Tools.copy(state.arrayLocationSelectMulti);
    let arrayLocationSelectNotMulti = Tools.copy(state.arrayLocationSelectNotMulti);
    let objLocationSelect = Tools.copy(state.objLocationSelect);
    let arrayLocationSelect = Tools.copy(state.arrayLocationSelect);
    let arrReportIds = [];
    for (var j = 0; j < data.length; j++) {
        data[j].floors.sort(function compare(a, b) {
            if (a.number < b.number) {
                return -1;
            }
            if (a.number > b.number) {
                return 1;
            }
            // a должно быть равным b
            return 0;
        });
        ObjReportings[data[j].pl_id] = data[j];
    }
    // console.log('>>>>>>>', ObjReportings)
    for (var i = 0; i < locations.length; i++) {
        let allObject = {
            id: ObjReportings[locations[i].id].main_servicepoint.id,
            statusSelect: true,
        };
        objDataByObjId[ObjReportings[locations[i].id].main_servicepoint.id] = {
            pl_id: locations[i].id,
            pl_name: locations[i].name,
            obj_id: ObjReportings[locations[i].id].main_servicepoint.id,
            obj_type: 'main',
            obj_name: 'Object name',
        };
        if (urlIdsObjReport[ObjReportings[locations[i].id].main_servicepoint.id] !== undefined) {
            allObject = {
                id: ObjReportings[locations[i].id].main_servicepoint.id,
                statusSelect: true,
            };
        }
        if (state.urlIdsPl[locations[i].id] !== undefined) {
            if (urlIdsObjReport[ObjReportings[locations[i].id].main_servicepoint.id] === undefined) {
                allObject = {
                    id: ObjReportings[locations[i].id].main_servicepoint.id,
                    statusSelect: false,
                };
            }
        }
        for (var key in ObjReportings[locations[i].id]) {
            if (key === 'zones' || key === 'floors' || key === 'servicepoints') {
                for (var r = 0; r < ObjReportings[locations[i].id][key].length; r++) {
                    let nameObj = '';
                    if (key === 'zones') {
                        nameObj = ObjReportings[locations[i].id][key][r].zone_name;
                    } else if (key === 'floors') {
                        nameObj = ObjReportings[locations[i].id][key][r].number;
                    } else if (key === 'servicepoints') {
                        nameObj = ObjReportings[locations[i].id][key][r].name;
                    }
                    objDataByObjId[ObjReportings[locations[i].id][key][r].id] = {
                        pl_id: locations[i].id,
                        pl_name: locations[i].name,
                        obj_id: ObjReportings[locations[i].id][key][r].id,
                        obj_type: key,
                        obj_name: nameObj,
                    };
                    if (urlIdsObjReport[ObjReportings[locations[i].id][key][r].id] !== undefined) {
                        ObjReportings[locations[i].id][key][r]['select'] = true;
                        arrReportIds.push(ObjReportings[locations[i].id][key][r].id);
                    }
                }
            }
        }

        locations[i]['detail'] = ObjReportings[locations[i].id];
        locations[i]['allObject'] = allObject;
        for (var g = 0; g < arrayLocationSelectMulti.length; g++) {
            if (arrayLocationSelectMulti[g].id === locations[i].id) {
                arrayLocationSelectMulti[g] = locations[i];
                objLocationControlNotSelect[locations[i].id] = locations[i];
            }
        }
        if (arrayLocationSelectNotMulti[0] !== null) {
            if (arrayLocationSelectNotMulti[0].id === locations[i].id) {
                arrayLocationSelectNotMulti[0] = locations[i];
                objLocationControlSelect[locations[i].id] = locations[i];
            }
        } else {
            arrayLocationSelectNotMulti[0] = locations[0];
            objLocationControlSelect[locations[0].id] = locations[0];
        }
    }
    return Object.assign({}, state, {
        showDataPL: tempShow,
        // locations: locations,
        statusLoadReportings: false,
        objLocationSelect: objLocationSelect,
        objLocationControlNotSelect: objLocationControlNotSelect,
        arrayLocationSelectMulti: arrayLocationSelectMulti,
        arrayLocationSelect: arrayLocationSelect,
        objLocationControlSelect: objLocationControlSelect,
        arrayLocationSelectNotMulti: arrayLocationSelectNotMulti,
        arrReportIds: arrReportIds,
        objDataByObjId: objDataByObjId,
        reportObjects: reportObjects,
    });
};

const onClickDashAllObject = (state, action) => {
    var showDataPL = state.showDataPL;
    let locations = Tools.copy(state.locations);
    for (var i = 0; i < locations.length; i++) {
        if (state.locations[i].id === state.idPL) {
            locations[i].allObject.statusSelect = !showDataPL.allObject.statusSelect;
        }
    }
    showDataPL.allObject.statusSelect = !showDataPL.allObject.statusSelect;
    return Object.assign({}, state, {
        locations: locations,
        showDataPL: showDataPL,
        statusSelectDashAllObject: !showDataPL.allObject.statusSelect,
    });
};
const getLoadReportingObjects = (state, action) => {
    return Object.assign({}, state, {
        statusLoadReportings: true,
    });
};

const onClickDash = (state, action) => {
    // console.log('onClickDash', action.data.id);
    let locations = Tools.copy(state.locations);
    var obj = Tools.copy(state.showDataPL);
    let objLocationControlNotSelect = Tools.copy(state.objLocationControlNotSelect);
    let objLocationControlSelect = Tools.copy(state.objLocationControlSelect);
    let arrayLocationSelectMulti = Tools.copy(state.arrayLocationSelectMulti);
    let arrayLocationSelectNotMulti = Tools.copy(state.arrayLocationSelectNotMulti);
    let objLocationSelect = Tools.copy(state.objLocationSelect);
    let arrayLocationSelect = Tools.copy(state.arrayLocationSelect);
    let arrReportIds = Tools.copy(state.arrReportIds);
    for (var i = 0; i < state.locations.length; i++) {
        // console.log('onClickDash', action.data);
        if (state.locations[i].id === state.idPL) {
            if (action.data.number !== undefined) {
                for (var j = 0; j < state.locations[i].detail.floors.length; j++) {
                    if (action.data.id === state.locations[i].detail.floors[j].id) {
                        // console.log('onClickDash', action.data.id, state.locations[i].detail.floors[j].id);
                        if (locations[i].detail.floors[j].select !== undefined) {
                            if (locations[i].detail.floors[j].select === true) {
                                locations[i].detail.floors[j].select = false;
                                obj.floors[j].select = false;
                                let index = arrReportIds.indexOf(state.locations[i].detail.floors[j].id);
                                arrReportIds.splice(index, 1);
                            } else {
                                locations[i].detail.floors[j].select = true;
                                obj.floors[j].select = true;
                                arrReportIds.push(state.locations[i].detail.floors[j].id);
                            }
                        } else {
                            locations[i].detail.floors[j]['select'] = true;
                            obj.floors[j]['select'] = true;
                            arrReportIds.push(state.locations[i].detail.floors[j].id);
                        }
                    }
                }
            }
            if (action.data.name !== undefined) {
                // console.log('onClickDash', action.data.id, state.locations[i].detail);
                for (let j = 0; j < state.locations[i].detail.servicepoints.length; j++) {
                    if (action.data.id === state.locations[i].detail.servicepoints[j].id) {
                        // console.log('onClickDash', action.data.id, state.locations[i].detail.floors[j].id);
                        if (locations[i].detail.servicepoints[j].select !== undefined) {
                            if (locations[i].detail.servicepoints[j].select === true) {
                                locations[i].detail.servicepoints[j].select = false;
                                obj.servicepoints[j].select = false;
                                let index = arrReportIds.indexOf(state.locations[i].detail.servicepoints[j].id);
                                arrReportIds.splice(index, 1);
                            } else {
                                locations[i].detail.servicepoints[j].select = true;
                                obj.servicepoints[j].select = true;
                                arrReportIds.push(state.locations[i].detail.servicepoints[j].id);
                            }
                        } else {
                            locations[i].detail.servicepoints[j]['select'] = true;
                            obj.servicepoints[j]['select'] = true;
                            arrReportIds.push(state.locations[i].detail.servicepoints[j].id);
                        }
                    }
                }
            }
            if (action.data.zone_name !== undefined) {
                // console.log('onClickDash', action.data.id, state.locations[i].detail);
                for (let j = 0; j < state.locations[i].detail.zones.length; j++) {
                    if (action.data.id === state.locations[i].detail.zones[j].id) {
                        // console.log('onClickDash', action.data.id, state.locations[i].detail.floors[j].id);
                        if (locations[i].detail.zones[j].select !== undefined) {
                            if (locations[i].detail.zones[j].select === true) {
                                locations[i].detail.zones[j].select = false;
                                obj.zones[j].select = false;
                                // console.log('onClickDash', arrReportIds.indexOf(state.locations[i].detail.zones[j].id));
                                let index = arrReportIds.indexOf(state.locations[i].detail.zones[j].id);
                                arrReportIds.splice(index, 1);
                            } else {
                                locations[i].detail.zones[j].select = true;
                                obj.zones[j].select = true;
                                arrReportIds.push(state.locations[i].detail.zones[j].id);
                            }
                        } else {
                            locations[i].detail.zones[j]['select'] = true;
                            obj.zones[j]['select'] = true;
                            arrReportIds.push(state.locations[i].detail.zones[j].id);
                        }
                    }
                }
            }
        }
        for (var g = 0; g < arrayLocationSelectMulti.length; g++) {
            if (arrayLocationSelectMulti[g].id === locations[i].id) {
                arrayLocationSelectMulti[g] = locations[i];
                objLocationControlNotSelect[locations[i].id] = locations[i];
            }
        }
        if (arrayLocationSelectNotMulti[0].id === locations[i].id) {
            arrayLocationSelectNotMulti[0] = locations[i];
            objLocationControlSelect[locations[i].id] = locations[i];
        }
    }
    return Object.assign({}, state, {
        locations: locations,
        showDataPL: obj,
        objLocationSelect: objLocationSelect,
        objLocationControlNotSelect: objLocationControlNotSelect,
        arrayLocationSelectMulti: arrayLocationSelectMulti,
        arrayLocationSelect: arrayLocationSelect,
        objLocationControlSelect: objLocationControlSelect,
        arrayLocationSelectNotMulti: arrayLocationSelectNotMulti,
        arrReportIds: arrReportIds,
    });
};

const getLocationsId = (state, action) => {
    if (action.phathName !== '/') {
        let arr = [];
        let arrIdsPL = [];
        if (action.phathName.split('/')[7] !== undefined) {
            arr = action.phathName.split('/')[7].split(',');
        }
        if (action.phathName.split('/')[6] !== undefined) {
            arrIdsPL = action.phathName.split('/')[6].split(',');
        }
        let objLocationControlNotSelect = {};
        let arrayLocationSelectMulti = [];
        let locations = state.locations;
        let objLocationControlSelect = {};
        let arrayLocationSelectNotMulti = [];
        let arrReportIds = Tools.copy(state.arrReportIds);
        let obj3 = {};
        let objIdPl = [];
        for (var i = 0; i < arr.length; i++) {
            obj3[arr[i]] = arr[i];
        }
        for (var g = 0; g < arrIdsPL.length; g++) {
            objIdPl[arrIdsPL[g]] = +arrIdsPL[g];
        }
        if (locations.length > 0) {
            for (var j = 0; j < locations.length; j++) {
                if (objIdPl[locations[j].id] !== undefined) {
                    if (obj3[locations[j].allObject.id] !== undefined) {
                        locations[j].allObject.statusSelect = true;
                    } else {
                        locations[j].allObject.statusSelect = false;
                    }
                    for (var key in locations[j].detail) {
                        if (key === 'zones' || key === 'floors' || key === 'servicepoints') {
                            for (var r = 0; r < locations[j].detail[key].length; r++) {
                                if (obj3[locations[j].detail[key][r].id] !== undefined) {
                                    locations[j].detail[key][r]['select'] = true;
                                    arrReportIds.push(locations[j].detail[key][r].id);
                                } else {
                                    locations[j].detail[key][r]['select'] = false;
                                }
                            }
                        }
                    }
                }
                for (var f = 0; f < arrIdsPL.length; f++) {
                    if (locations[j].id === +arrIdsPL[f]) {
                        objLocationControlNotSelect[locations[j].id] = locations[j];
                        arrayLocationSelectMulti.push(locations[j]);
                    }
                    if (locations[j].id === +arrIdsPL[arrIdsPL.length - 1]) {
                        objLocationControlSelect[locations[j].id] = locations[j];
                        arrayLocationSelectNotMulti = [locations[j].id];
                    }
                }
            }
        }
        let urlObjIds = [];
        if (action.phathName.split('/')[6] !== undefined) {
            urlObjIds = action.phathName.split('/')[6].split(',');
        }
        return Object.assign({}, state, {
            urlObjIds: urlObjIds,
            urlIdsObjReport: obj3,
            urlIdsPl: objIdPl,
            objLocationControlNotSelect: objLocationControlNotSelect,
            arrayLocationSelectMulti: arrayLocationSelectMulti,
            objLocationControlSelect: objLocationControlSelect,
            arrayLocationSelectNotMulti: arrayLocationSelectNotMulti,
            arrayLocationSelect: arrayLocationSelectMulti,
            objLocationSelect: objLocationControlNotSelect,
            locations: locations,
            arrReportIds: arrReportIds,
        });
    }
    return Object.assign({}, state, {});
};

const onClickDropDownZones = (state, action) => {
    let obj = Tools.copy(state.objZonesGroupMarker);
    obj[action.marker] = action.status;
    return Object.assign({}, state, {
        objZonesGroupMarker: obj,
    });
};

let stateInit = {
    mainState: {},
    locations: [],
    objLocationSelect: {},
    objLocationControlSelect: {},
    objLocationControlNotSelect: {},
    arrayLocationSelectNotMulti: [],
    arrayLocationSelectMulti: [],
    arrayLocationSelect: [],
    valueSearch: '',
    searchArr: [],
    statusShowPanelLocations: false,
    showDataPL: {
        allObject: {
            id: 0,
            statusSelect: true,
        },
        floors: [],
        servicepoints: [],
        zones: [],
    },
    statusLoadReportings: false,
    idPL: 0,
    titlePanelReporting: '',
    objSelectReportingObjects: {
        number: [],
        servicepoints: [],
        zones: [],
    },
    statusSelectDashAllObject: false,
    urlObjIds: [],
    urlIdsObjReport: [],
    urlIdsPl: [],
    arrReportIds: [],
    objZonesGroupMarker: {},
    objDataByObjId: {},
    reportObjects: [],
};

const onSelectDeselectAllClick = (state, action) => {
    const data = action.data;
    const locationsIndex = state.locations.map((item) => item.id).indexOf(data.id);
    const arrayLocationSelectMultiIndex = state.arrayLocationSelectMulti.map((item) => item.id).indexOf(data.id);
    const arrayLocationSelectNotMultiIndex = state.arrayLocationSelectNotMulti.map((item) => item.id).indexOf(data.id);
    const arrayLocationSelectIndex = state.arrayLocationSelect.map((item) => item.id).indexOf(data.id);

    const locations = state.locations;
    const arrayLocationSelectMulti = state.arrayLocationSelectMulti;
    const arrayLocationSelectNotMulti = state.arrayLocationSelectNotMulti;
    const arrayLocationSelect = state.arrayLocationSelect;

    const allSelectedObject = {};
    for (let key in data) {
        if (data.hasOwnProperty(key) && Array.isArray(data[key]) && data[key].length !== 0) {
            allSelectedObject[key] = state.showDataPL[key].map((item) => {
                return { ...item, select: action.isSelect };
            });

            locations[locationsIndex] = {
                ...locations[locationsIndex],
                detail: {
                    ...locations[locationsIndex].detail,
                    ...allSelectedObject,
                },
            };

            arrayLocationSelectMulti[arrayLocationSelectMultiIndex] =
                arrayLocationSelectMultiIndex !== -1
                    ? {
                          ...arrayLocationSelectMulti[arrayLocationSelectMultiIndex],
                          detail: {
                              ...arrayLocationSelectMulti[arrayLocationSelectMultiIndex].detail,
                              ...allSelectedObject,
                          },
                      }
                    : state.arrayLocationSelectMulti;

            arrayLocationSelectNotMulti[arrayLocationSelectNotMultiIndex] =
                arrayLocationSelectNotMultiIndex !== -1
                    ? {
                          ...arrayLocationSelectNotMulti[arrayLocationSelectNotMultiIndex],
                          detail: {
                              ...arrayLocationSelectNotMulti[arrayLocationSelectNotMultiIndex].detail,
                              ...allSelectedObject,
                          },
                      }
                    : state.arrayLocationSelectNotMulti;

            arrayLocationSelect[arrayLocationSelectIndex] =
                arrayLocationSelectIndex !== -1
                    ? {
                          ...arrayLocationSelect[arrayLocationSelectIndex],
                          detail: {
                              ...arrayLocationSelect[arrayLocationSelectIndex].detail,
                              ...allSelectedObject,
                          },
                      }
                    : state.arrayLocationSelect;
        }
    }

    return {
        ...state,
        showDataPL: { ...state.showDataPL, ...allSelectedObject },
        locations,
        arrayLocationSelectMulti,
        arrayLocationSelectNotMulti,
        arrayLocationSelect,
        objLocationSelect: {
            ...state.objLocationSelect,
            [data.id]: {
                ...state.objLocationSelect[data.id],
                detail: { ...state.objLocationSelect[data.id].detail, ...allSelectedObject },
            },
        },

        // objLocationControlSelect: {
        //   ...state.objLocationControlSelect,
        //   [data.id]: {
        //     ...state.objLocationControlSelect[data.id],
        //     detail: { ...state.objLocationControlSelect[data.id].detail, ...allSelectedObject }
        //   }
        // },

        objLocationControlNotSelect: {
            ...state.objLocationControlNotSelect,
            [data.id]: {
                ...state.objLocationControlNotSelect[data.id],
                detail: { ...state.objLocationControlNotSelect[data.id].detail, ...allSelectedObject },
            },
        },
    };
};

const PanelLocationApp = (state = stateInit, action) => {
    switch (action.type) {
        case 'setLocation':
            return setLocationData(state, action);
        case 'getSelectLocation':
            return getSelectLocation(state, action);
        case 'onClickAllLocation':
            return onClickAllLocation(state, action);
        case 'onChancgeInputSearch':
            return onChancgeInputSearch(state, action);
        case 'selectLeftPanelButtonList':
            return selectHotspotTopPanel(state, action);
        case 'closePanelLocations':
            return closePanelLocations(state, action);
        case 'onClickButtonLocations':
            return onClickButtonLocations(state, action);
        case 'getIdPL':
            return getIdPL(state, action);
        case 'getReportingObjects':
            return getReportingObjects(state, action);
        case 'getLoadReportingObjects':
            return getLoadReportingObjects(state, action);
        case 'onClickDash':
            return onClickDash(state, action);
        case 'onClickDashAllObject':
            return onClickDashAllObject(state, action);
        case 'getLocationsId':
            return getLocationsId(state, action);
        case 'onClickDropDownZones':
            return onClickDropDownZones(state, action);
        case 'onSelectDeselectAllClick':
            return onSelectDeselectAllClick(state, action);
        default:
            return state;
    }
};

export default PanelLocationApp;
