import React, { useState, useMemo, useEffect } from 'react';
import _t from 'i18next';
import styled from 'styled-components/macro';

import Tools from '../../../tools/tools';

const Select = React.memo(({ multi, selectedValues, title, ...props }) => {
    const [expanded, setExpanded] = useState(false);
    const [list, setList] = useState([]);
    const { innerBorderRef } = Tools.useOnOutsideClick(() => setExpanded(false));
    useEffect(() => {
        if (props.list) {
            if (multi) {
                const list = [...props.list];
                list.unshift({ id: 0, name: _t.t('Все') });
                setList(() => list);
            } else {
                const list = [...props.list];
                setList(() => list);
            }
        }
    }, [props.list]);

    const arrowClass = expanded ? 'fa fa-angle-up' : 'fa fa-angle-down';

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const listCollapsed = selectedValues
        ? useMemo(() => {
              return list.filter(item => selectedValues.includes(item.id));
          }, [expanded, selectedValues, list])
        : [];

    const listExpanded = selectedValues
        ? useMemo(() => {
              return list;
          }, [expanded, selectedValues, list])
        : [];

    const onLiClick = (id, canDelete) => () => {
        if (!multi) {
            setExpanded(false);
        }
        if (canDelete) {
            props.changeSelected(id);
        }
    };

    return selectedValues ? (
        <div>
            <Title>{title}</Title>
            <Container ref={innerBorderRef}>
                <I className={arrowClass} onClick={toggleExpand} />
                <Ul expanded={false}>
                    {listCollapsed.map((item, i) => {
                        const isSelected = selectedValues.includes(item.id);
                        const canDelete = listCollapsed.length > 1;
                        const amount = item.amount ? ` (${item.amount})` : '';
                        return (
                            <Li
                                multi={multi}
                                canDelete={canDelete}
                                isSelected={isSelected}
                                expanded={false}
                                key={`${item.id}-${item.name}-${i}`}
                            >
                                <i
                                    onClick={onLiClick(item.id, canDelete)}
                                    className="fa fa-times-circle"
                                ></i>
                                <span onClick={toggleExpand}>{`${item.name}${amount}`}</span>
                            </Li>
                        );
                    })}
                </Ul>
                {expanded && (
                    <Ul expanded={true}>
                        {listExpanded.map((item, i) => {
                            const isSelected = selectedValues.includes(item.id);
                            const canDelete = listExpanded.length > 1;
                            const amount = item.amount ? ` (${item.amount})` : '';
                            return (
                                <Li
                                    multi={multi}
                                    canDelete={canDelete}
                                    isSelected={isSelected}
                                    expanded={expanded}
                                    onClick={onLiClick(item.id, canDelete)}
                                    key={`${item.id}-${item.name}-${i}`}
                                >
                                    <i className="fa fa-times-circle"></i>
                                    <span>{`${item.name}${amount}`}</span>
                                </Li>
                            );
                        })}
                    </Ul>
                )}
            </Container>
        </div>
    ) : null;
});

export default Select;

const Container = styled.div`
    position: relative;
    width: 100%;
    /* padding: 5px; */
    box-sizing: border-box;
    margin-bottom: 10px;
`;
const Title = styled.p`
    font-family: ProximaNova;
    font-size: 11pt;
    font-style: italic;
    margin-bottom: 5px;
    color: rgb(36, 49, 56);
`;
const Ul = styled.ul`
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    max-height: calc(100vh - 200px);
    position: ${p => (p.expanded ? 'absolute' : 'auto')};
    box-shadow: ${p => (p.expanded ? '2px 2px 2px 1px rgba(0, 0, 0, 0.1)' : 'none')};
    left: 0;
    top: 0;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    padding-right: 25px;
    overflow: auto;
    z-index: 500;
`;
const Li = styled.li`
    border-left: ${props => (props.isSelected ? '5px solid #1e90ff' : '5px solid transparent')};
    color: ${props => (props.isSelected ? '#000' : '#aaa')};
    :hover {
        border-left: ${props => (props.isSelected ? '5px solid #1e90ff' : '5px solid #ccc')};
        /* border-left: ${props => (props.isSelected ? '5px solid #328856' : '5px solid #ccc')}; */
        color: #000;
    }

    font-family: ProximaNova;
    font-size: 13px;
    height: 20px;
    cursor: pointer;
    box-sizing: border-box;
    list-style: none;
    margin: 1px 0;
    padding: 1px 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    /* background-color: ${p => (p.isSelected && p.expanded ? '#1e90ff' : 'transparent')}; */
    /* color: ${p => (p.isSelected && p.expanded ? '#fff' : '#000')}; */
    /* border-radius: 5px; */
    i {
        display: ${p => (p.multi && !p.expanded && p.canDelete ? 'flex' : 'none')};
        align-items: center;
        color: #bbb;
        margin-right: 5px;
    }
    span {
        width: 100%;
        color: inherit;
    }
`;

const I = styled.i`
    padding: 0 5px;
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1px;
    right: 1px;
    font-size: 22px !important;
    color: rgb(36, 41, 45) !important;
    /* background-color: #eef0f0; */
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:before {
        display: block;
    }
`;
