import React from 'react';
import styled from 'styled-components/macro';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import TreeGroupItem from './tree_group_item';
import CompleteTableSaveButton from './complete_table_save_button';
import Modes from './modes';
import '../styles/complete_tables.scss';

const TreePanel = React.memo(({ data, lang, shownIndexes, mode, onCompleteTablesModeChange, _t, ...props }) => {
    const onTableCheck = (index) => (e) => {
        props.onTableCheck(index);
    };

    const renderTree = () => {
        try {
            return (
                <form>
                    <ul className={'TreePanel_groups-list'}>
                        {data.map((item, i) => {
                            return (
                                <li className={'TreePanel_group'} key={`groupName-${item}-${i}`}>
                                    <p className={'TreePanel_group-title'}>{item.groupName}</p>
                                    <ul className={'TreePanel_tables-list'}>
                                        {item.allowedTables.map((table, i) => {
                                            const checked = shownIndexes[table.index] === undefined ? true : shownIndexes[table.index];
                                            return (
                                                <Li key={`allowedTable-${i}`}>
                                                    <Checkbox
                                                        label={table.data_report.translations[lang].name}
                                                        checked={checked}
                                                        onChange={onTableCheck(table.index)}
                                                    />
                                                </Li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </form>
            );
        } catch (error) {}
    };
    return (
        <div className={'TreePanel'}>
            <CompleteTableSaveButton buttonName={_t.t('Сохранить XLSX')} onClick={props.onSaveXLSXClick} />
            <div className={'TreePanel-container'}>
                <Modes onCompleteTablesModeChange={onCompleteTablesModeChange} _t={_t} mode={mode} />
                {renderTree()}
            </div>
        </div>
    );
});

export default TreePanel;

const Li = styled.li`
    margin-bottom: 5px;
`;
