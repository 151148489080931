import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Item from './tree_type_2_item';

import { treeMap } from '../../../../tools/tools';

const Ul = styled.ul`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: ${p => (p.basic ? 0 : '20px')};
`;

export default React.memo(({  history, toggleExpandArrow, memoTree, ...props }) => {
    const renderList = arr => {
        return (
            <Ul basic={true}>
                {arr.map(item => {
                    const children = item.children.length === 0 ? null : <Ul>{renderList(item.children)}</Ul>;
                    const hasChildren = item.children.length !== 0;
                    return (
                        <Item
                            key={`${item.name}-${item.id}`}
                            hasChildren={hasChildren}
                            item={item}
                            toggleExpandArrow={toggleExpandArrow}
                            history={history}
                            onObjSetDashClick={props.onObjSetDashClick}
                        >
                            {children}
                        </Item>
                    );
                })}
            </Ul>
        );
    };

    const tree = useMemo(() => {
        return memoTree[0] ? renderList([treeMap(memoTree[0])]) : null;
    }, [memoTree, history]);

    return (
        <div style={{ width: '100%' }}>
            <div>{tree}</div>
        </div>
    );
});
