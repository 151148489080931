import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { List } from 'office-ui-fabric-react/lib/List';
import styled from 'styled-components/macro';
import moment from "moment";
import {useSelector} from "react-redux";
import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from '@fluentui/react/lib/Styling';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Stack} from "office-ui-fabric-react/lib/Stack";
import {MessageBar, MessageBarType} from "office-ui-fabric-react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const SegmentInfoModal = React.memo(({ info, ...props }) => {
    const [copyStatus, setCopyStatus] = useState(false);

    const { lang } = useSelector((state) => state.TopPanel);
    const items = useMemo(() => {
        const result = [];
        Object.keys(info).forEach((key) => {
            console.log(Array.isArray(info[key]));
            let description = String(info[key]);
            if (typeof info[key] === 'object' || Array.isArray(info[key])) {
                description = JSON.stringify(info[key]);
            }
            result.push({ name: key, description });
        });
        return result;
    }, [info]);

    const itemsYandex = useMemo(() => {
        const result = [];

        Object.keys(info.yandex_export).forEach((key) => {
            let description = String(info.yandex_export[key]);
            if (key ==='yandex_accounts') {
                description = info.yandex_export[key].join(', ');
            }
            else {
                if (key === 'exported') {
                    description = moment(info.yandex_export[key]).format('DD MMM YYYY');
                }
                else {
                    description = typeof info.yandex_export[key] === 'boolean' ? JSON.stringify(info.yandex_export[key]) : info.yandex_export[key];
                }

            }
            result.push({ name: key, description });

        });
        return result;
    }, [info]);

    const itemsMailru = useMemo(() => {
        const result = [];
        Object.keys(info.mailru_export).forEach((key) => {
            let description = String(info.yandex_export[key]);
            if (key !== 'sharing_keys') {
                if (key ==='mailru_accounts') {
                    description = info.mailru_export[key].join(', ');
                }
                else {
                    // console.log('>>>>>>>>>>>>>>>', info.mailru_export[key], key)
                    if (key === 'exported') {
                        description = moment(info.mailru_export[key]).format('DD MMM YYYY');
                    }
                    else {
                        description = typeof info.mailru_export[key] === 'boolean' || typeof info.mailru_export[key] === 'object' ? JSON.stringify(info.mailru_export[key]) : info.mailru_export[key];
                    }

                }
                result.push({ name: key, description });
            }


        });
        return result;
    }, [info]);

    const itemsMailruSharingKeys = useMemo(() => {
        const result = [];
        // console.log('>>>>>>>>>>>>>>>', info.mailru_export.sharing_keys)
        info.mailru_export.sharing_keys.forEach((item, index) => {
            let objTemp = {};
            Object.keys(item).forEach((key) => {
                let description = String(info.yandex_export[key]);

                if (key ==='mailru_accounts') {
                    // description = item[key].join(', ');
                    objTemp[key] = item[key].join(', ');
                }
                else {
                    // console.log('>>>>>>>>>>>>>>>', info.mailru_export[key], key)

                    // description = typeof item[key] === 'boolean' || typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key];
                    objTemp[key] = typeof item[key] === 'boolean' || typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key];

                }
                // result.push({ name: key, description });

            });
            result.push(objTemp);
        })

        return result;
    }, [info]);

    moment.locale(lang);

    const onCopy = () => {
        setCopyStatus(true);
        setTimeout(() => {
            setCopyStatus(false);
        }, 2000);
    };

    const onRenderCellExports = (item, index) => {
        // <CopyText text={JSON.stringify(item[key])} />
        // console.log('>>>>>>>>>>', item.name)
        if (item.name === 'sharing_key') {
            return (
                <div className={classNames.itemCell} data-is-focusable={true}>
                    <div className={classNames.itemContent}>
                        <div className={classNames.itemName}>{item.name}</div>
                        <CopyToClipboard onCopy={onCopy} text={item.description}>
                            <div>{item.description}</div>
                        </CopyToClipboard>

                        {/*<CopyText text={item.description} />*/}
                    </div>
                </div>
            );
        }
        return (
            <div className={classNames.itemCell} data-is-focusable={true}>
                <div className={classNames.itemContent}>
                    <div className={classNames.itemName}>{item.name}</div>
                    <div>{item.description}</div>
                </div>
            </div>
        );
    }

    const columns = [

        {
            key: 'column2',
            name: 'Sharing url',
            fieldName: 'sharing_url',
            minWidth: 210,
            maxWidth: 210,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: 'string',
            // isPadded: true,
        },
        {
            key: 'column3',
            name: 'Sharing key',
            fieldName: 'sharing_key',
            minWidth: 210,
            maxWidth: 210,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            isSortedDescending: false,
            onRender: (item) => {
                return <CopyToClipboard onCopy={onCopy} text={item.sharing_key}>
                    <div>{item.sharing_key}</div>
                </CopyToClipboard>;
            },
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: 'string',
            // isPadded: true,
        },
        {
            key: 'column4',
            name: 'Mailru accounts',
            fieldName: 'mailru_accounts',
            minWidth: 210,
            maxWidth: 210,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: 'string',
            // isPadded: true,
        },
    ];


    const _onRenderCell = (item, index) => {
        if (item.name === 'yandex_export' || item.name === 'mailru_export') {
            let arrTemp = [];
            arrTemp = item.name === 'yandex_export' ? [itemsYandex] : [itemsMailru, itemsMailruSharingKeys]
            let componentNode = arrTemp.map((item2, index2) => {

                if (index2 === 1) {
                    // console.log('>>>>>>>>>>>>>>>', item2)

                    return (
                        <Wrapper2 key={`sdeqwqasdfas${index2}`}>
                            <DetailsList
                                items={item2}
                                compact={true}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                // getKey={this._getKey}
                                // setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                // isHeaderVisible={true}
                                // onItemInvoked={this._onItemInvoked}
                            />
                            {/*<List items={item2} onRenderCell={onRenderCellExports} />*/}
                        </Wrapper2>
                    )
                }

                return (
                    <Wrapper2 key={`sdeqwqasdfas${index2}`}>

                        <List items={item2} onRenderCell={onRenderCellExports} />
                    </Wrapper2>
                )
            })
            return (
                <Row2 index={index}>
                    <Name>{item.name}</Name>
                    <Desription>
                        <GridBox>
                            {componentNode}
                        </GridBox>

                    </Desription>
                </Row2>
            );

        }

        return (
            <Row index={index}>
                <Name>{item.name}</Name>
                <Desription>{item.description}</Desription>
            </Row>
        );
    };

    const horizontalStackProps = {
        horizontal: true,
        tokens: { childrenGap: 16 }
    };

    const verticalStackProps = {
        styles: { root: { overflow: 'hidden', width: '100%' } },
        tokens: { childrenGap: 20 }
    };

    return (
        <Wrapper>
            <List items={items} onRenderCell={_onRenderCell} />
            {copyStatus ? <BoxNotifyCopy >
                <Stack {...horizontalStackProps}>
                    <Stack {...verticalStackProps}>
                        <MessageBar
                            // dismissButtonAriaLabel="Close"
                            // onDismiss={onChoise}
                            messageBarType={MessageBarType.success}
                            isMultiline={true}

                        >
                            copied
                        </MessageBar>
                    </Stack>
                </Stack>
            </BoxNotifyCopy> : null}
        </Wrapper>
    );
});

export default SegmentInfoModal;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: palette.neutralLight },
            },
        },
    ],
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
    },
    itemName: [
        {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '18px'
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    chevron: {
        alignSelf: 'center',
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
    },
});

const Row = styled.div`
    padding: 10px;
    border-bottom: 1px solid rgb(244, 244, 244);
     background-color: ${(p) => (p.index % 2 === 0 ? 'rgb(244, 244, 244)' : 'transparent')};
    display: flex;
    justify-content: space-between;
`;


const BoxNotifyCopy = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Row2 = styled.div`
    padding: 10px;
    border-bottom: 1px solid rgb(244, 244, 244);
    //  background-color: ${(p) => (p.index % 2 === 0 ? 'rgb(244, 244, 244)' : 'transparent')};
    // display: flex;
    // justify-content: space-between;
`;

const Name = styled.div`
    font-size: 18px;
    font-weight: 700;
`;

const Desription = styled.div`
    user-select: text;
    margin-left: 20px;
    overflow: auto;
    width: 100%;
`;

const GridBox = styled.div`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 225px 700px;
`;

const Wrapper = styled.div`
    overflow-x: hidden;

`;

const Wrapper2 = styled.div`
    //width: 400px;
    border-left: 1px solid rgba(0,0,0,.3);
    border-right: 1px solid rgba(0,0,0,.3);

`;
