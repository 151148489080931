import React, { useRef, useContext, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DiagramsContext } from '../index';
import { generateSize } from '../tools/diagramTools';

const PlateClear = React.memo(({ ...props }) => {
    const {
        defaultSize,
        reportData,
        diagramOptionsByID,
        fullScreenId,
        showDiagramParamsPanel,
        DiagramParamsPanelId,
        cabinetMode,
    } = useContext(DiagramsContext);

    const plateRef = useRef();

    useLayoutEffect(() => {
        if (plateRef.current && cabinetMode === '-pdf') {


            if (plateRef.current.offsetParent.style.OTransform){
                const elem = plateRef.current.offsetParent
                const temp = plateRef.current.offsetParent.getBoundingClientRect()

                const height = plateRef.current.offsetHeight;
                const plateId = props.id;
                const positStr = plateRef.current.offsetParent.style.OTransform.split('(')[1]
                    .split(',')[1].split('p')[0]

                const position = +positStr + elem.offsetHeight;
                props.getPlateEndPosition({groupId: props.groupId, plateId, position, height ,dataView: props.dataView, elem});
            }


        }
    }, [plateRef, plateRef.current && plateRef.current.offsetTop, plateRef.current && plateRef.current.offsetHeight]);



    return (
        <div

            ref={plateRef}
        >
            {props.children}
        </div>
    );
});

export default PlateClear;

const PlatePaginateSC = styled.div`
  cursor: ${(p) => {
    if (p.isDraggable && !p.dragging) {
      return "grab";
    } else if (p.isDraggable && p.dragging) {
      return "grabbing";
    } else {
      return "default";
    }
  }};
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.padding};
  box-sizing: border-box;
  /* > div {
        transform: translateX(-16);
    } */
  /* overflow: auto; */

  opacity: ${(p) => (p.dimPlate ? "0.3" : "1")};
  outline: ${(p) => (p.showOutline ? "2px solid rgb(75, 218, 75)" : "none")};

  width: ${(p) => {
    if (p.size === null) {
      return "1100px";
    } else {
      return "auto";
      // return p.fullScreenShow ? 'auto' : 'auto';
    }
  }};

  height: ${(p) => {
    if (p.size === null) {
      return "auto";
    }
    if (p.fullScreenShow && !p.keepRatio) {
      return "100%";
    }
    if (p.fullScreenShow && p.keepRatio) {
      return "auto";
    }
    if (!p.isFreeHeight) {
      // console.log(p.dataView.data_view_type.key_name, p.size.height);

      return p.size.height + "px";
    } else {
      return "auto";
    }
  }};

  &:first-child {
    box-shadow: ${(p) => {
      if (p.iterationsNumber === 1) {
        return "0px 0px 12px rgba(0, 0, 0, 0.1)";
      } else {
        return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)";
      }
    }};
  }

  &:last-child {
    box-shadow: ${(p) => {
      if (p.iterationsNumber === 1) {
        return "0px 0px 12px rgba(0, 0, 0, 0.1)";
      } else {
        return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)";
      }
    }};
  }

  &:only-child {
    box-shadow: ${(p) => {
      return "0px 0px 12px rgba(0, 0, 0, 0.1)";
    }};
  }

  box-shadow: ${(p) => {
    if (p.iterationsNumber === 1) {
      return "0px 0px 12px rgba(0, 0, 0, 0.1)";
    } else if (p.iteration === p.iterationsNumber - 1) {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)";
    } else if (p.iteration === 0) {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)";
    } else {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06)";
    }
  }};
  margin-bottom: ${(p) => {
    if (p.iterationsNumber === 1 || p.iteration === p.iterationsNumber - 1) {
      return "16px";
    } else {
      return "0px";
    }
  }};
`;