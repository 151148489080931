import React, {useState, useEffect} from 'react';
import styled from "styled-components/macro";
import {TextField} from "office-ui-fabric-react/lib/TextField";
import {FontIcon} from "office-ui-fabric-react/lib/Icon";
import {Label} from "office-ui-fabric-react/lib/Label";
import {DefaultButton} from "office-ui-fabric-react/lib/Button";
import {useTranslation} from "react-i18next";
import {runAction} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import DetailList from "./DItailList";
import hostForLocation from "../../../hostnames/hostname";
import Cookies from "universal-cookie";
import PreCampaign from "../index";
import {PrimaryButton} from "@fluentui/react/lib/Button";
const cookie = new Cookies();

const ModalPre = () => {
    const [showCreateNew, setShowCreateNew] = useState(true);
    const { t, i18n } = useTranslation();
    const { lang } = useSelector((state) => state.TopPanel);
    const { customInputParamsValues } = useSelector((state) => state.ReportParametersManager);
    const { ad_campaigns, outdoor_ad_spaces } = useSelector((state) => state.ReportParametersManager.Source.src);
    const { listReport, selectionItemReport } = useSelector((state) => state.PreCampaigns);
    const {
        selectedSpaces
    } = useSelector((state) => state.OutdoorSpaces);
    const addIcon = { iconName: 'Add' };
    const dispatch = useDispatch();

    const {
        outdoorAdSpacesById
    } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );

    useEffect(() => {
        getReportList()
        const timer = setInterval(() => {
            getReportList()
        }, 10000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    const getReportList = async () => {
        const url = `${hostForLocation.getHostName().precampaign}/precampaign_reports/get_digital_reports`

        const token = cookie.get('xtoken');
        try {
            const response3 = await fetch(url, {
                method: 'get', // или 'PUT'
                // body: JSON.stringify(data),
                mode: 'cors',
                redirect: 'follow',
                cache: 'no-cache',
                timeout: 600000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token

                }
            });

            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            const json3 = await response3.json();
            setTimeout(() => {
                console.log('><>><><><>', json3);
            }, 3000)
            console.log('Успех:', json3);
            dispatch(runAction('getStatusShowModal_GetReportList', {list: json3}));
        } catch (error) {
            console.error('Ошибка:', error);
            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            alert(error)
        }
    }
    const toggleModal = (args) => {
        dispatch(runAction('toggleModal', { args }));
    };
    const onCreateNewClick = () => {
        toggleModal({
            show: true,
            modalType: 'new_pre_campaign',
            modalTitle: t('Создание новой рекламной кампании'),
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: { lang, customInputParamsValues, outdoor_ad_spaces },
            },
        });
    };

    const onSelectReportItem = (data) => {

        dispatch(runAction('onSelectItemList_PreCampaigns', { selection: data[0] }));
    }

    const onProcess = async () => {

        let outdoorAdSpaces = []
        let outdoorAdSpacesByKey = {}

        selectedSpaces.forEach((item) => {
            const space = outdoor_ad_spaces.filter((adSpace) => adSpace.id === item)[0];
            outdoorAdSpacesByKey[space.id] = space
            outdoorAdSpaces.push(space)
        })


        const url = `${hostForLocation.getHostName().precampaign}/precampaign_reports/get_digital_report_detailed`
        const _data = {
            "report_id": selectionItemReport.id
        }
        const token = cookie.get('xtoken');
        try {
            dispatch(runAction('getIsLoad_PreCampaigns', { status: true }));
            const response3 = await fetch(url, {
                method: 'POST', // или 'PUT'
                body: JSON.stringify(_data),
                mode: 'cors',
                redirect: 'follow',
                cache: 'no-cache',
                timeout: 600000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token

                }
            });

            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            const json3 = await response3.json();
            setTimeout(() => {
                console.log('><>><><><>', json3);
            }, 3000)
            if (json3.data) {
                console.log('Успех:', json3);
                dispatch(runAction('getSotsDetailedDynamics_PreCampaigns', {
                    json: {
                        data: {
                            ...json3.data.sots_preds,
                            ...json3.data.metadata
                        }
                    }, selectedSpaces, outdoorAdSpaces, outdoorAdSpacesByKey, outdoorAdSpacesById
                }));
                dispatch(runAction('getAccumulativeFrequencyHist_PreCampaigns', {
                    json2: {
                        data: {
                            ...json3.data.frequency_preds,
                            ...json3.data.metadata
                        }
                    }, selectedSpaces
                }));
                dispatch(runAction('getUniqueMac_PreCampaigns', {
                    json3: {
                        data: {
                            ...json3.data.unique_preds,
                            ...json3.data.metadata,
                            unique_count_pred: [json3.data.unique_preds.unique_count_pred]
                        }
                    }, selectedSpaces
                }));
                dispatch(runAction('getIsLoad_PreCampaigns', { status: false }));
            } else {
                dispatch(runAction('clearData_PreCampaigns', {}));
            }

            // dispatch(runAction('getUniqueMac_PreCampaigns', {json3, selectedSpaces}));
        } catch (error) {
            console.error('Ошибка:', error);
            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            alert(error)
        }
    }

    return (
        <>
            <MainContainer>
                <TitleWrapper>
                    <Title>
                        <h2>{t('Готовые отчеты')}</h2>
                    </Title>
                    {/*{showCreateNew ? (*/}
                    {/*    <StDefaultButton iconProps={addIcon} onClick={onCreateNewClick}>*/}
                    {/*        {t('Создать новый отчет')}*/}
                    {/*    </StDefaultButton>*/}
                    {/*) : null}*/}
                </TitleWrapper>
                <DetailList
                    listReport={listReport}
                    onSelectReportItem={onSelectReportItem}
                />
                { selectionItemReport ?
                    <div>
                        <PrimaryButton
                            text={t("Применить")}
                            onClick={onProcess}
                            allowDisabledFocus
                            // disabled={disabled}
                            // checked={checked}
                        />
                    </div> : null
                }
            </MainContainer>
            <PreCampaign/>
        </>
    );
};

export default ModalPre;

const MainContainer = styled.section`
    padding: 15px;
    /* overflow: auto; */
    //background-color: #eee;
    /* z-index: 201; */
    //position: absolute;
    //left: 238px;
    //top: 0;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    z-index: 400;
    /* align-items: center; */
    flex-shrink: 0;
    width: 100%;
    /* height: calc(100vh - 50px); */
    //height: 100vh;
    box-sizing: border-box;
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    .ms-DetailsRow-cell {
        display: flex !important;
        align-items: center !important;
    }
`;

const Title = styled.div`
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #99ccae;
    padding-bottom: 5px;
    margin: 0;
    h2 {
        box-sizing: border-box;
        font-family: ProximaNova;
        font-size: 17px;
        color: rgb(36, 49, 56);
        padding: 0;
        margin: 0;
    }
`;

const StTextField = styled(TextField)`
    height: 40px !important;
    div {
        height: 40px !important;
    }
`;

const StIcon = styled(FontIcon)`
    font-size: 24px;
    color: red;
    cursor: pointer;
`;

const CurrentProject = styled.div`
    height: 40px !important;
    border: 1px solid rgb(138, 136, 134);
    border-radius: 2px;
    /* width: 100%; */
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const StLabel = styled(Label)`
    width: 100%;
    background-color: inherit;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    color: ${(p) => p.color};
    /* padding: 10px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    :focus {
        outline: none;
    }
`;



const TitleWrapper = styled.div`
    box-sizing: border-box;
    /* padding: 10px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const TreeWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
`;

const Delete = styled.div`
    cursor: pointer;
`;

const StDefaultButton = styled(DefaultButton)`
    flex-shrink: 0;
    margin-left: 10px;
`;