import React from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import CustomTooltip from '../custom_tooltip';

export default React.memo(({ ...props }) => {
    const RADIAN = Math.PI / 180;
    const MAX_NAME_LENGTH = 12;

    const axisStyle = {
        fontSize: 10,
    };

    const pieColors = [
        '#4363d8',
        '#e6194b',
        '#ffe119',
        '#3cb44b',
        '#f58231',
        '#911eb4',
        '#46f0f0',
        '#f032e6',
        '#bcf60c',
        '#fabebe',
        '#008080',
        '#e6beff',
        '#9a6324',
        '#fffac8',
        '#800000',
        '#aaffc3',
        '#808000',
        '#ffd8b1',
        '#000075',
    ];

    const DATA = props.data.x.values.map((x, i) => {
        return {
            name: x,
            value: props.data.data[0].values[i],
            color: pieColors[i] ? pieColors[i] : pieColors[Math.floor(Math.random() * (pieColors.length - 1))],
        };
    });

    const margin = { top: 0, right: 0, bottom: 0, left: 10 };

    const renderCustomLegend = (value, entry) => {
        const style = { fontSize: 12, color: '#666' };
        const cuttedValue = value.length < MAX_NAME_LENGTH ? value : value.slice(0, MAX_NAME_LENGTH) + '...';
        return <span style={style}>{cuttedValue}</span>;
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return percent > 0.06 ? (
            <text
                fontSize={12}
                x={x}
                y={y}
                fill="white"
                //   textAnchor={x > cx ? 'start' : 'end'}
                textAnchor="middle"
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        ) : null;
    };

    const generateTooltip = ({ payload }) => {
        if (!payload[0]) return null;

        const { name, color, value } = payload[0].payload;

        const sumValue = DATA.reduce((acc, item) => {
            return (acc += item.value);
        }, 0);

        // console.log(payload);

        const tooltip = [
            {
                dataKey: name,
                value: `${value} | ${((value / sumValue) * 100).toFixed(0)}%`,
                x: undefined,
                color,
            },
        ];

        return <CustomTooltip tooltip={tooltip} chartId={props.dataView.data_view_id} />;
    };

    return (
        <PieChart margin={margin} width={props.size.width - 30} height={props.size.height - 56}>
            <Tooltip content={generateTooltip} />
            <Legend
                formatter={renderCustomLegend}
                wrapperStyle={{ paddingRight: 10, paddingBottom: 10 }}
                style={axisStyle}
                iconSize={12}
                align="right"
                layout="vertical"
                iconType="circle"
            />
            <Pie
                isAnimationActive={false}
                dataKey="value"
                data={DATA}
                cx={props.size.width / 3}
                cy={(props.size.height - 56) / 2}
                innerRadius="40%"
                outerRadius="90%"
                fill="#82ca9d"
                label={renderCustomizedLabel}
                labelLine={false}
            >
                {DATA.map((item, index) => (
                    <Cell key={`Cell-${item.color}-${props.dataView.data_view_id}`} fill={item.color} />
                ))}
            </Pie>
        </PieChart>
    );
});
