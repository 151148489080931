const initState = {
    logOverlay: { show: false, args: {} },
    diagramsHeights: {},
    diagramsInnerParams: {
        dynamics_diagram: {},
    },
    fullScreenId: null,
    storedInputParamsValues: {},
    defaultSize: {
        defaultHeight: 136,
        defaultWidth: 202,
    },
};

const storeInputParamsValues_Diagrams = (state, action) => {
    const { values, subsection } = action.payload;
    return {
        ...state,
        storedInputParamsValues: { ...state.storedInputParamsValues, [subsection]: values },
    };
};

const onFullScreenClick = (state, action) => {
    const { data_view_id } = action.payload;
    return { ...state, fullScreenId: data_view_id };
};

const fullScreenExit = (state, action) => {
    return { ...state, fullScreenId: null };
};

const changeDiagramHeight_Diagrams = (state, action) => {
    const { groupId, diagramId, height } = action.payload;
    return {
        ...state,
        diagramsHeights: { ...state.diagramsHeights, [groupId]: { ...state.diagramsHeights[groupId], [diagramId]: height } },
    };
};

const onShiftClick = (state, action) => {
    const { newShift, diagramId, diagramType } = action.payload;
    return {
        ...state,
        diagramsInnerParams: {
            ...state.diagramsInnerParams,
            [diagramType]: {
                ...state.diagramsInnerParams[diagramType],
                newShift: {
                    ...state.diagramsInnerParams[diagramType].newShift,
                    [diagramId]: newShift,
                },
            },
        },
    };
};

const resetShifts_Diagrams = (state, action) => {
    return {
        ...state,
        diagramsInnerParams: {
            ...state.diagramsInnerParams,
            ['dynamics_diagram']: {
                ...state.diagramsInnerParams['dynamics_diagram'],
                newShift: {},
            },
        },
    };
};

const setLogOverlayShow_Diagrams = (state, action) => {
    if (action.payload.args) {
        return {
            ...state,
            logOverlay: { show: true, args: action.payload.args },
        };
    } else {
        return { ...state, logOverlay: initState.logOverlay };
    }
};

const refreshLogOverlay_Diagrams = (state, action) => {
    const { log } = action.payload;
    return { ...state, logOverlay: { ...state.logOverlay, args: { ...state.logOverlay.args, log } } };
};

const Diagrams = (state = initState, action) => {
    switch (action.type) {
        case 'storeInputParamsValues_Diagrams':
            return storeInputParamsValues_Diagrams(state, action);
        case 'onShiftClick':
            return onShiftClick(state, action);
        case 'resetShifts_Diagrams':
            return resetShifts_Diagrams(state, action);
        case 'onFullScreenClick':
            return onFullScreenClick(state, action);
        case 'fullScreenExit':
            return fullScreenExit(state, action);
        case 'changeDiagramHeight_Diagrams':
            return changeDiagramHeight_Diagrams(state, action);
        case 'setLogOverlayShow_Diagrams':
            return setLogOverlayShow_Diagrams(state, action);
        case 'refreshLogOverlay_Diagrams':
            return refreshLogOverlay_Diagrams(state, action);
        default:
            return state;
    }
};

export default Diagrams;
