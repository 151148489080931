/* eslint-disable indent */
import React, { useRef, useContext, useMemo, useState, useEffect } from "react";
import styled from "styled-components/macro";
import GridLayout from "react-grid-layout";
import { generateSize, getFreeHeight } from "../tools/diagramTools";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
// import Plate from './plate';
import Diagram from "./diagram";
import { DiagramsContext } from "../index";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";

export default React.memo(
    ({
         group,
         groupID,
         iterationsNumber = 1,
         iteration = 0,
         editable = false,
         padding = "0px",
         ...props
     }) => {
        const {
            permanentCloudData,
            defaultSize,
            sectionsByKeyName,
            currentSection,
            currentSubSection,
            reportData,
            diagramOptionsByID,
            customInputParamsValues,
            globalInputParamsValues,
            fullScreenId,
            showDiagramParamsPanel,
            DiagramParamsPanelId,
            cabinetMode,
            diagramsHeights,
            setLogOverlayShow,
            dataViewsByGroupID,
            changeLayoutC_D,
        } = useContext(DiagramsContext);
        const [layout, set_layout] = useState([]);
        const [dragging, set_dragging] = useState(false);

        const groupRef = useRef();

        if (!group) {
            return null;
        }

        const GAP = 16;
        const COLS = useMemo(() => {
            return (
                sectionsByKeyName[currentSection].subsections.filter(
                    (item) => item.key_name === currentSubSection
                )[0].width || 5
            );
        }, [
            sectionsByKeyName,
            currentSection,
            currentSubSection,
            dataViewsByGroupID,
        ]);

        const FULL_WIDTH = useMemo(() => {
            return COLS * (GAP + defaultSize.defaultWidth) + GAP;
        }, [COLS, defaultSize]);

        // console.log(sectionsByKeyName);
        // console.log(COLS, FULL_WIDTH);

        const setLayout = () => {
            const layout = [];

            let x = 0;
            let y = 0;
            let maxStringHeight = 1;

            group.forEach((dataView, i, arr) => {
                // console.log(dataView);
                const isFreeHeight =
                    defaultSize &&
                    reportData &&
                    reportData[dataView.data_view_id] &&
                    reportData[dataView.data_view_id]?.response &&
                    reportData[dataView.data_view_id].isSuccess
                        ? getFreeHeight(
                            dataView,
                            reportData[dataView.data_view_id].response
                        )
                        : false;

                if (dataView.layout_item && !isFreeHeight) {
                    layout.push(dataView.layout_item);
                } else {
                    const w = dataView.size.width;
                    const h =
                        isFreeHeight &&
                        diagramsHeights[dataView.group.id] &&
                        diagramsHeights[dataView.group.id][dataView.data_view_id]
                            ? // diagramsHeights[dataView.group.id][dataView.data_view_id] > defaultSize.defaultHeight
                            Math.ceil(
                                (diagramsHeights[dataView.group.id][dataView.data_view_id] +
                                    8) /
                                (defaultSize.defaultHeight + GAP)
                            )
                            : dataView.size.height;

                    // const h = isFreeHeight ? Math.ceil(size.height / (defaultSize.defaultHeight + GAP)) + 1 : dataView.size.height;
                    // console.log(diagramsHeights);

                    if (!dataView.layout_item) {
                        maxStringHeight = Math.max(maxStringHeight, h);
                        if (i !== 0) {
                            if (x + arr[i - 1].size.width + w <= COLS) {
                                x += arr[i - 1].size.width;
                            } else {
                                x = 0;
                                y += maxStringHeight;
                                maxStringHeight = 1;
                            }
                        }
                    } else {
                        x = dataView.layout_item.x;
                        y = dataView.layout_item.y;
                    }

                    layout.push({ i: String(dataView.data_view_id), x, y, w, h });
                }
            });

            set_layout([...layout]);
        };

        useEffect(() => {
            setLayout();
        }, [
            currentSection,
            currentSubSection,
            group,
            COLS,
            // reportData,
            diagramsHeights,
            dataViewsByGroupID,
            // permanentCloudData && permanentCloudData.custom_section,
        ]);

        const onShowLogToggle = (show, args) => (e) => {
            show ? setLogOverlayShow(args) : setLogOverlayShow(null);
        };

        const onDragStop = (newLayout) => {
            if (currentSection === "custom_section") {
                changeLayoutC_D({ newLayout, currentSubSection, groupID });
                set_dragging(false);
            }
        };

        const onDragStart = (newLayout) => {
            if (currentSection === "custom_section") {
                console.log("onDragStart");
                set_dragging(true);
            }
        };

        const getArgsToLog = (dataView) => {
            const newInputParamsValues = {};
            dataView.data_report.input_parameters.forEach((parameter) => {
                newInputParamsValues[parameter.key_name] = {
                    ...customInputParamsValues,
                    ...globalInputParamsValues,
                }[parameter.key_name];
            });

            const log = reportData?.[dataView.data_view_id]?.log
                ? reportData[dataView.data_view_id].log
                : "no log";

            return { inputParamsValues: newInputParamsValues, dataView, log };
        };

        const isDraggable = currentSection === "custom_section" && editable;

        const plates = () => {
            const result = [];

            group.forEach((dataView, i, arr) => {
                const size = generateSize(dataView, defaultSize, cabinetMode);

                const isFreeHeight =
                    defaultSize &&
                    reportData &&
                    reportData[dataView.data_view_id] &&
                    reportData[dataView.data_view_id].response &&
                    reportData[dataView.data_view_id].response !== null &&
                    reportData[dataView.data_view_id].isSuccess
                        ? getFreeHeight(
                            dataView,
                            reportData[dataView.data_view_id].response
                        )
                        : false;

                const dimPlate =
                    showDiagramParamsPanel &&
                    DiagramParamsPanelId !== dataView.data_view_id;
                const showOutline =
                    showDiagramParamsPanel &&
                    DiagramParamsPanelId === dataView.data_view_id;

                const element = (
                    <Plate
                        key={String(dataView.data_view_id)}
                        dataView={dataView}
                        dimPlate={dimPlate}
                        showOutline={showOutline}
                        isDraggable={isDraggable}
                        dragging={dragging}
                        iterationsNumber={iterationsNumber}
                        iteration={iteration}
                        padding={padding}
                        fullScreenShow={false}
                        keepRatio={true}
                        size={size}
                        isFreeHeight={isFreeHeight}
                    >
                        <Diagram
                            dataView={dataView}
                            isFreeHeight={isFreeHeight}
                            fullScreenShow={false}
                            keepRatio={true}
                            editable={editable}
                        />
                        <ButtonToShowLog
                            onClick={onShowLogToggle(true, getArgsToLog(dataView))}
                        />
                    </Plate>
                );
                result.push(element);
            });
            return result;
        };

        const fullScreenDiagram = useMemo(() => {
            if (fullScreenId === null) return null;
            const [dataView] = group.filter(
                (item) => item.data_view_id === fullScreenId
            );
            const size = generateSize(dataView, defaultSize, cabinetMode);
            const isFreeHeight =
                defaultSize &&
                reportData &&
                reportData[dataView.data_view_id] &&
                reportData[dataView.data_view_id].response &&
                reportData[dataView.data_view_id].response !== null &&
                reportData[dataView.data_view_id].isSuccess
                    ? getFreeHeight(dataView, reportData[dataView.data_view_id].response)
                    : false;
            const keepRatio =
                diagramOptionsByID[dataView.data_view_id].fullScreen.keepRatio;

            return (
                <Plate
                    key={String(dataView.data_view_id)}
                    dataView={dataView}
                    iterationsNumber={iterationsNumber}
                    iteration={iteration}
                    padding={padding}
                    fullScreenShow={true}
                    keepRatio={keepRatio}
                    size={size}
                    isFreeHeight={isFreeHeight}
                >
                    <Diagram
                        dataView={dataView}
                        isFreeHeight={isFreeHeight}
                        fullScreenShow={true}
                        keepRatio={keepRatio}
                    />
                    <ButtonToShowLog
                        onClick={onShowLogToggle(true, getArgsToLog(dataView))}
                    />
                </Plate>
            );
        }, [fullScreenId, group, reportData, dataViewsByGroupID]);

        useEffect(() => {
            //   console.log(">>>", permanentCloudData.custom_dataViews);
            //   console.log(">>>", layout);
            if (
                currentSection === "custom_section" &&
                layout.length &&
                permanentCloudData.custom_dataViews.length &&
                permanentCloudData.custom_dataViews.some((item) => !item.layout_item)
            ) {
                
                // changeLayoutC_D({ newLayout: layout, currentSubSection, groupID });
            }
        }, [
            JSON.stringify(layout),
            JSON.stringify(permanentCloudData.custom_dataViews),
        ]);

        const grid = useMemo(() => {
            return (
                <MainWrapper width={FULL_WIDTH}>
                    <GridLayout
                        // useCSSTransforms={false}
                        // onLayoutChange={onLayoutChange}
                        onDragStart={onDragStart}
                        onDragStop={onDragStop}
                        isResizable={false}
                        isDraggable={isDraggable}
                        layout={layout}
                        className="layout"
                        cols={COLS}
                        rowHeight={defaultSize.defaultHeight}
                        width={FULL_WIDTH}
                        margin={[GAP, GAP]}
                    >
                        {plates()}
                    </GridLayout>
                </MainWrapper>
            );
        }, [
            layout,
            diagramOptionsByID,
            reportData,
            DiagramParamsPanelId,
            iterationsNumber,
            editable,
            dragging,
        ]);

        return fullScreenId === null ? grid : fullScreenDiagram;
    }
);

const StGridLayout = styled(GridLayout)`
  > div {
    /* transform: none !important; */
  }
`;

const Plate = styled.div`
  cursor: ${(p) => {
    if (p.isDraggable && !p.dragging) {
      return "grab";
    } else if (p.isDraggable && p.dragging) {
      return "grabbing";
    } else {
      return "default";
    }
  }};
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.padding};
  box-sizing: border-box;
  /* > div {
        transform: translateX(-16);
    } */
  /* overflow: auto; */

  opacity: ${(p) => (p.dimPlate ? "0.3" : "1")};
  outline: ${(p) => (p.showOutline ? "2px solid rgb(75, 218, 75)" : "none")};

  width: ${(p) => {
    if (p.size === null) {
      return "1100px";
    } else {
      return "auto";
      // return p.fullScreenShow ? 'auto' : 'auto';
    }
  }};

  height: ${(p) => {
    if (p.size === null) {
      return "auto";
    }
    if (p.fullScreenShow && !p.keepRatio) {
      return "100%";
    }
    if (p.fullScreenShow && p.keepRatio) {
      return "auto";
    }
    if (!p.isFreeHeight) {
      // console.log(p.dataView.data_view_type.key_name, p.size.height);

      return p.size.height + "px";
    } else {
      return "auto";
    }
  }};

  &:first-child {
    box-shadow: ${(p) => {
      if (p.iterationsNumber === 1) {
        return "0px 0px 12px rgba(0, 0, 0, 0.1)";
      } else {
        return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)";
      }
    }};
  }

  &:last-child {
    box-shadow: ${(p) => {
      if (p.iterationsNumber === 1) {
        return "0px 0px 12px rgba(0, 0, 0, 0.1)";
      } else {
        return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)";
      }
    }};
  }

  &:only-child {
    box-shadow: ${(p) => {
      return "0px 0px 12px rgba(0, 0, 0, 0.1)";
    }};
  }

  box-shadow: ${(p) => {
    if (p.iterationsNumber === 1) {
      return "0px 0px 12px rgba(0, 0, 0, 0.1)";
    } else if (p.iteration === p.iterationsNumber - 1) {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)";
    } else if (p.iteration === 0) {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)";
    } else {
      return "8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06)";
    }
  }};
  margin-bottom: ${(p) => {
    if (p.iterationsNumber === 1 || p.iteration === p.iterationsNumber - 1) {
      return "16px";
    } else {
      return "0px";
    }
  }};
`;

const MainWrapper = styled.div`
  width: ${(p) => p.width + "px"};
  /* display: flex; */
`;

const ButtonToShowLog = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  /* cursor: pointer; */
  width: 8px;
  height: 8px;
  /* background-color: red; */
  font-size: 13px;
  z-index: 2000;
`;
