import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { runAction } from '../../actions';
import OutdoorMap from './components/outdoor-map';
import hostForLocation from '../../hostnames/hostname';
import OutdoorControlPanel from './components/outdoor_control_panel';
import { polygonsFilter, filterData, getPolygonCenter } from './tools';

const OutdoorSpaces = ({
    outdoor_ad_spaces,
    outdoor_ad_space_ids,
    mapCenter,
    closeMap,
    staticAdCampSelectedSpaces,
    setInitialZoom,
    onSectorClick,
    spacesTypesCatalog,
    initialZoom,
    handleTargetClick,
    selectedSpaces,
    handleSaveCenter,
    searchValue,
    handleRightSectorClick,
    isMapExpanded,
    handleSearch,
    changeSelectedTypes,
    selectedAdSpaceTypes,
    areaPolygons,
    region_id,
    show,
    mode = '',
    fillSelectedSpaces,
    toggleFilterByPolygon,
    filterByPolygon,
    ...props
}) => {
    const [nonZeroPolygons, setNonZeroPolygons] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [firstRun, setFirstRun] = useState(true);

    // useEffect(() => {
    //     props.getOutdoorAdSpaces(props.token);
    //     props.getAdSpacesTypes(props.token);
    //     props.getPolygonGroups(props.token);
    // }, []);

    useEffect(() => {
        if (areaPolygons && outdoor_ad_spaces) {
            const nonZeroPolygons = areaPolygons.filter((polygon) => {
                const length = polygonsFilter(outdoor_ad_spaces, polygon).length;
                return length > 0;
            });
            setNonZeroPolygons(() => nonZeroPolygons);
        }
    }, [areaPolygons, outdoor_ad_spaces]);

    useEffect(() => {
        if (nonZeroPolygons.length === 0) return;
        if (region_id === null) {
            props.changeSelectedPolygon(nonZeroPolygons[0].id);
        } else {
            const newPolygon = nonZeroPolygons.filter((item) => item.id === region_id)[0];
            const polygonCenter = getPolygonCenter(newPolygon);
            props.centerOnSelectedPolygon(polygonCenter);
        }
    }, [region_id, nonZeroPolygons]);

    const areaPolygon = useMemo(() => {
        return nonZeroPolygons && nonZeroPolygons.length !== 0 && region_id
            ? nonZeroPolygons.filter((item) => item.id === region_id)[0]
            : undefined;
    }, [nonZeroPolygons, region_id]);

    const polygonsFilteredAdSpaces = useMemo(() => {
        if (areaPolygon && outdoor_ad_spaces) {
            if (filterByPolygon) {
                return polygonsFilter(outdoor_ad_spaces, areaPolygon);
            } else {
                return outdoor_ad_spaces;
            }
        } else {
            return [];
        }
    }, [outdoor_ad_spaces, areaPolygon, filterByPolygon]);

    //-------------------------------------------------

    useEffect(() => {
        if (!firstRun) return;
        if (polygonsFilteredAdSpaces.length !== 0) {
            if (outdoor_ad_space_ids.length === 0) {
                const id = polygonsFilteredAdSpaces[0].id;
                onSectorClick(id, mode, true);
                props.changeOutdoorAdSpaceIds([id]);
            }
            setFirstRun(false);
        }
    }, [selectedSpaces]);

    useEffect(() => {
        if (outdoor_ad_space_ids.length !== 0) {
            fillSelectedSpaces(outdoor_ad_space_ids);
        }
    }, [outdoor_ad_space_ids, polygonsFilteredAdSpaces]);

    //-------------------------------------------------

    const filteredAdSpaces = useMemo(() => {
        return filterData(polygonsFilteredAdSpaces, selectedAdSpaceTypes, searchValue);
    }, [polygonsFilteredAdSpaces, selectedAdSpaceTypes, searchValue]);

    useEffect(() => {
        if (polygonsFilteredAdSpaces && spacesTypesCatalog) {
            let filteredTypes = Array.from(new Set(polygonsFilteredAdSpaces.map((item) => item.ad_space_type.id)));
            filteredTypes = [
                ...filteredTypes.map((id) => {
                    const amount = polygonsFilteredAdSpaces.reduce((acc, item) => {
                        return item.ad_space_type.id === id ? acc + 1 : acc;
                    }, 0);
                    return { ...spacesTypesCatalog.filter((item) => item.id === id)[0], amount };
                }),
            ];
            setFilteredTypes(() => filteredTypes);
        }
    }, [polygonsFilteredAdSpaces, spacesTypesCatalog]);

    const changeSelectedPolygon = (id) => {
        props.changeSelectedPolygon(id);
    };

    return (
        <OutdoorSpacesContainer show={show} mode={mode}>
            <OutdoorControlPanel
                filteredAdSpaces={filteredAdSpaces}
                region_id={region_id}
                mode={mode}
                closeMap={closeMap}
                selectedSpaces={selectedSpaces}
                staticAdCampSelectedSpaces={staticAdCampSelectedSpaces}
                showIPoints={false}
                handleTargetClick={handleTargetClick}
                onSectorClick={onSectorClick}
                searchValue={searchValue}
                handleSearch={handleSearch}
                filteredTypes={filteredTypes}
                changeSelectedTypes={changeSelectedTypes}
                selectedAdSpaceTypes={selectedAdSpaceTypes}
                nonZeroPolygons={nonZeroPolygons}
                changeSelectedPolygon={changeSelectedPolygon}
                onSelectAllClick={props.onSelectAllSpacesClick(mode)}
                onDeselectAllClick={props.onDeselectAllSpacesClick(mode)}
                filterByPolygon={filterByPolygon}
                toggleFilterByPolygon={toggleFilterByPolygon}
            />
            {region_id ? (
                <OutdoorMap
                    filteredAdSpaces={filteredAdSpaces}
                    areaPolygon={areaPolygon}
                    region_id={region_id}
                    mode={mode}
                    handleRightSectorClick={handleRightSectorClick}
                    mapCenter={mapCenter}
                    handleSaveCenter={handleSaveCenter}
                    initialZoom={initialZoom}
                    showIPoints={false}
                    selectedSpaces={selectedSpaces}
                    staticAdCampSelectedSpaces={staticAdCampSelectedSpaces}
                    onSectorClick={onSectorClick}
                    setInitialZoom={setInitialZoom}
                    selectedAdSpaceTypes={selectedAdSpaceTypes}
                    searchValue={searchValue}
                    nonZeroPolygons={nonZeroPolygons}
                    isMapExpanded={isMapExpanded}
                />
            ) : (
                <SpinnerContainer>
                    <ClipLoader color={'#46b875'} loading={true} size={30} />
                </SpinnerContainer>
            )}
        </OutdoorSpacesContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        outdoor_ad_spaces: state.ReportParametersManager.Source.src.outdoor_ad_spaces,
        region_id: state.ReportParametersManager.customInputParamsValues.region_id,
        areaPolygons: state.ReportParametersManager.Source.ctg.areaPolygons,
        isMapExpanded: state.OutdoorSpaces.isMapExpanded,
        searchValue: state.OutdoorSpaces.searchValue,
        staticAdCampSelectedSpaces: state.AdCampaigns.staticAdCampSelectedSpaces,
        selectedAdSpaceTypes: state.OutdoorSpaces.selectedAdSpaceTypes,
        filterByPolygon: state.OutdoorSpaces.filterByPolygon,
        allowEditCatalog: state.OutdoorSpaces.allowEditCatalog,
        spacesTypesCatalog: state.OutdoorSpaces.spacesTypesCatalog,
        editModal: state.OutdoorSpaces.editModal,
        selectedSpaces: state.OutdoorSpaces.selectedSpaces,
        showIPoints: state.OutdoorSpaces.showIPoints,
        mapCenter: state.OutdoorSpaces.mapCenter,
        initialZoom: state.OutdoorSpaces.initialZoom,
        token: state.LoginApp.token,
        outdoor_ad_space_ids: state.ReportParametersManager.customInputParamsValues.outdoor_ad_space_ids,
    };
};

const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100vh;
`;

const OutdoorSpacesContainer = styled.section`
    background-color: #eee;
    /* z-index: 201; */
    position: absolute;
    left: ${(p) => (p.mode === 'staticAdCamp' ? '0' : '238px')};
    top: 0px;
    display: flex;
    z-index: ${(p) => (p.show ? '600' : '-200')};
    align-items: center;
    flex-shrink: 0;
    /* width: calc(100% - 238px); */
    width: ${(p) => (p.mode === 'staticAdCamp' ? '100%' : 'calc(100% - 238px)')};
    height: 100vh;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    .leaflet-container {
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }
    .leaflet-control-layers {
        border-width: 1px !important;
    }
    .leaflet-bar {
        border-width: 1px !important;
    }
`;

const mapDispatchToProps = (dispatch) => {
    return {
        fillSelectedSpaces(selectedSpaces) {
            dispatch(runAction('fillSelectedSpaces_OutdoorSpaces', { selectedSpaces }));
        },
        closeMap() {
            dispatch(runAction('toggleAdSpacesMapPanel_AdCampaigns', { show: false }));
        },
        toggleFilterByPolygon(checked) {
            dispatch(runAction('toggleFilterByPolygon_OutdoorSpaces', { checked }));
        },
        handleInputChange: (point, typeOfRouter, field, value) => {
            dispatch(runAction('handleInputChange', { payload: { point, typeOfRouter, field, value } }));
        },
        changeSelectedPolygon: (region_id) => {
            dispatch(runAction('changeSelectedTypes_OutdoorSpaces', { id: 0 }));
            dispatch(runAction('handleSearch_OutdoorSpaces', { text: '' }));
            dispatch(runAction('resetSelectedSectors_OutdoorSpaces', {}));
            dispatch(runAction('changeSelectedPolygon', { region_id }));
        },
        centerOnSelectedPolygon: (center) => {
            dispatch(runAction('aimOnPoint', { center, initialZoom: 11 }));
        },
        onSelectAllSpacesClick: (mode) => (filteredAdSpaces) => {
            if (mode === 'staticAdCamp') {
                dispatch(runAction('fillStaticAdCampSelectedSpaces_AdCampaigns', { spaces: filteredAdSpaces }));
            }
            dispatch(runAction('onSelectAllSpacesClick', { filteredAdSpaces }));
        },
        onDeselectAllSpacesClick: (mode) => () => {
            if (mode === 'staticAdCamp') {
                dispatch(runAction('resetStaticAdCampSelectedSpaces_AdCampaigns', {}));
            }
            dispatch(runAction('onDeselectAllSpacesClick'));
        },
        changeSelectedTypes: (id) => {
            dispatch(runAction('changeSelectedTypes_OutdoorSpaces', { id }));
        },
        handleSaveCenter: (center) => {
            dispatch(runAction('handleSaveCenter_OutdoorSpaces', { center }));
        },
        setInitialZoom: (currentZoom) => {
            dispatch(runAction('setInitialZoom_OutdoorSpaces', { currentZoom }));
        },
        changeOutdoorAdSpaceIds: (selectedSpaces) => {
            dispatch(runAction('changeOutdoorAdSpaceIds', { selectedSpaces }));
        },
        handleSearch: (text) => {
            dispatch(runAction('handleSearch_OutdoorSpaces', { text }));
        },
        // toggleMap: () => {
        //     dispatch(runAction('toggleMap_OutdoorSpaces', {}));
        // },
        // handleSelectChange: (point, typeOfRouter, field, value) => {
        //     dispatch(runAction('handleSelectChange', { payload: { point, typeOfRouter, field, value } }));
        // },
        handleRightSectorClick: (sectorId, typeOfRouter, cursorCoords) => {
            dispatch(
                runAction('handleRightSectorClick', {
                    payload: { sectorId, typeOfRouter, cursorCoords },
                })
            );
        },
        handleCloseEditModal: (token) => (e) => {
            e.preventDefault();
            dispatch(runAction('handleCloseEditModal', { payload: {} }));
        },
        handleEditRouterClick: (data) => {
            dispatch(runAction('handleCloseContextMenu', { payload: {} }));
            dispatch(runAction('handleEditRouterClick', { payload: { data } }));
        },
        handleCloseContextMenu: () => {
            dispatch(runAction('handleCloseContextMenu', { payload: {} }));
        },
        onSectorClick: (spaceId, mode, firstRun = false) => {
            switch (mode) {
                case '':
                    dispatch(runAction('onSectorClick', { payload: { spaceId, firstRun } }));
                    break;
                case 'staticAdCamp':
                    dispatch(runAction('selectAdSpace_AdCampaigns', { spaceId }));
                    break;

                default:
                    break;
            }
        },
        // handleZoomChange: currentZoom => {
        //     dispatch(runAction('handleZoomChange', { payload: { currentZoom } }));
        // },
        // setInitialZoom: currentZoom => {
        //     dispatch(runAction('setInitialZoom', { payload: { currentZoom } }));
        // },
        // handleSaveCenter: center => {
        //     dispatch(runAction('handleSaveCenter', { payload: { center } }));
        // },

        // getOutdoorAdSpaces: token => {
        //     const headers = {
        //         'x-token': token
        //     };
        //     const url = `${hostForLocation.getHostName().admin}/adapp/outdoor_ad_spaces/`;
        //     dispatch(runAction('getOutdoorAdSpaces', { url, headers }));
        // },
        // getAdSpacesTypes: token => {
        //     const headers = {
        //         'x-token': token
        //     };
        //     const url = `${hostForLocation.getHostName().admin}/adapp/ad_space_types/`;
        //     dispatch(runAction('getAdSpacesTypes', { url, headers }));
        // },
        // getPolygonGroups: token => {
        //     const headers = {
        //         'x-token': token
        //     };
        //     const url = 'https://outdoor-maps.getshopster.net/regions/region_groups/';
        //     dispatch(runAction('getPolygonGroups', { url, headers }));
        // },
        handleTargetClick: (item) => (e) => {
            const center = { lat: item.latitude, lng: item.longitude };
            const initialZoom = 15;
            dispatch(runAction('aimOnPoint', { center, initialZoom }));
        },
    };
};

const ConnectOutdoorSpaces = connect(mapStateToProps, mapDispatchToProps)(OutdoorSpaces);

export default ConnectOutdoorSpaces;
