import React from 'react';
import styled from 'styled-components/macro';
import ObjSetsList from './objsets_list';
import SelectDeselectButtons from '../../pl_panel/components/selectDeselectButtons';

const Container = styled.div`
    border-bottom: 1px solid #cbc7c5;
`;

const ListContainer = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
`;

const DropDownBox = styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    button {
        float: left;
        background: rgba(0, 0, 0, 0);
        border: none;
        outline: none;
        margin-top: 4px;
        margin-right: 0px;
        border-radius: 2px;
        margin-left: 10px;
        cursor: pointer;
        top: 0px;
        right: 0px;
        i {
            font-size: 18px;
        }
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        align-self: flex-end;
        :focus {
            outline: none;
        }
    }
`;

export default React.memo(
    ({
        dataobjsets,
        history,
        showDeleteObjSetButtons,
        onDeleteObjSetDashButtonClick,
        projectLocationsById,
        searchValue,
        ...props
    }) => {
        const independentObjSetsList =
            searchValue === ''
                ? history.objSets.noPl
                : history.objSets.noPl.filter(set => set.name.toLowerCase().includes(searchValue.toLowerCase()));
        const toggleDropDownMenu = plId => () => {
            props.toggleDropDownMenu(plId);
        };

        const onSelectAllClick = plId => () => {
            props.onSelectAllClick(plId);
        };

        const onClearAllClick = plId => () => {
            props.onClearAllClick(plId);
        };

        const сontextMenu = {
            show: true,
            type: 'objSet'
        };

        return (
            <div style={{ width: '100%' }}>
                {independentObjSetsList.length !== 0 && (
                    <ObjSetsList
                        idList={independentObjSetsList}
                        dataobjsets={dataobjsets}
                        history={history}
                        showDeleteObjSetButtons={showDeleteObjSetButtons}
                        onDeleteObjSetDashButtonClick={onDeleteObjSetDashButtonClick}
                        onObjSetDashClick={props.onObjSetDashClick}
                        сontextMenu={сontextMenu}
                    />
                )}

                <div>
                    {Object.keys(history.objSets.hasPl).map(plId => {
                        const setsArray =
                            searchValue === ''
                                ? history.objSets.hasPl[plId].sets
                                : history.objSets.hasPl[plId].sets.filter(set =>
                                      set.name.toLowerCase().includes(searchValue.toLowerCase())
                                  );

                        const selectedAmount = setsArray.filter(item => item.selected).length;
                        const listShow = history.objSets.hasPl[plId].expanded;
                        return (
                            <Container key={`sets - ${plId}`}>
                                <DropDownBox>
                                    {`${projectLocationsById[plId].name} ${selectedAmount} / ${setsArray.length}`}
                                    <button onClick={toggleDropDownMenu(plId)}>
                                        {history.objSets.hasPl[plId].expanded ? (
                                            <i className="fa fa-angle-up" aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-angle-down" aria-hidden="true" />
                                        )}
                                    </button>
                                </DropDownBox>
                                <ListContainer show={listShow}>
                                    <SelectDeselectButtons
                                        onSelectAllClick={onSelectAllClick(plId)}
                                        onClearAllClick={onClearAllClick(plId)}
                                    />
                                    <ObjSetsList
                                        idList={setsArray}
                                        plId={plId}
                                        dataobjsets={dataobjsets}
                                        history={history}
                                        leftPadding={30}
                                        showDeleteObjSetButtons={showDeleteObjSetButtons}
                                        onDeleteObjSetDashButtonClick={onDeleteObjSetDashButtonClick}
                                        onObjSetDashClick={props.onObjSetDashClick}
                                        сontextMenu={сontextMenu}
                                    />
                                </ListContainer>
                            </Container>
                        );
                    })}
                </div>
            </div>
        );
    }
);
