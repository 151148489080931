import React from 'react';
import styled from 'styled-components/macro';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useConstCallback } from '@uifabric/react-hooks';
const ContextMenu = React.memo(({ cursor = false, margin = '0 5px', rightClick = false, hideOnMouseLeave = false, ...props }) => {
    const buttonRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const [cursorCoords, setCursorCoords] = React.useState(null);

    const onLeftClick = useConstCallback((e) => {
        if (!rightClick) {
            e.stopPropagation();
            setShowContextualMenu(true);
        }
    });

    const onRClick = (e) => {
        e.preventDefault();
        setCursorCoords({
            x: e.clientX,
            y: e.clientY,
        });
        if (rightClick) {
            setShowContextualMenu(true);
        }
    };

    const onHideContextualMenu = useConstCallback(() => {
        setShowContextualMenu(false);
    });
    const onMouseLeave = () => {
        if (hideOnMouseLeave) {
            setShowContextualMenu(false);
        }
    };

    const menuStyles = {
        root: { minWidth: 'auto' },
        subComponentStyles: {
            menuItem: {
                root: [
                    {
                        selectors: {
                            ':focus': {
                                outline: 'none',
                            },
                        },
                        height: 'auto',
                    },
                ],
                label: { fontSize: 14 },
            },
            callout: {},
        },
    };

    return (
        <Container ref={buttonRef} onClick={onLeftClick} onContextMenu={onRClick} margin={margin} onMouseLeave={onMouseLeave}>
            {props.children}
            <ContextualMenu
                styles={menuStyles}
                items={props.menuItems}
                hidden={!showContextualMenu}
                target={cursor ? cursorCoords : buttonRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </Container>
    );
});

export default ContextMenu;

const Container = styled.div`
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    padding: 0px;
    outline: none;
    border: none;
    /* cursor: pointer; */
    position: relative;
    margin: ${(p) => p.margin};
    span {
        width: 0;
        height: 0;
        padding: 0;
        margin: 0 !important;
    }
`;
