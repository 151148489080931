// var React = require('react');
import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components/macro';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
var Calendar = require('rc-calendar');
var GregorianCalendar = require('gregorian-calendar');
var dateMin = new GregorianCalendar(require('gregorian-calendar/lib/locale/ru_RU'));
var dateMax = new GregorianCalendar(require('gregorian-calendar/lib/locale/ru_RU'));
var DateTimeFormat = require('gregorian-calendar-format');
var dateFormatter = new DateTimeFormat('yyyy-MM-dd');
var moment = require('moment');

Date.prototype.ddmmyyy = function () {
    var yyyy = this.getFullYear().toString();
    var mm = (this.getMonth() + 1).toString();
    var dd = this.getDate().toString();
    return (dd[1] ? dd : '0' + dd[0]) + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + yyyy;
};

const DateBox = ({ datePeriod, prop, showMaxCalendar, statusShowMaxCalendar, handleEvent, _t }) => {
    let startDate = moment(datePeriod.from, 'YYYY:MM:DD').format('YYYY-MM-DD');
    let endDate = moment(datePeriod.to, 'YYYY:MM:DD').format('YYYY-MM-DD');
    let minDate = moment().subtract();
    var titleMinDate = moment(startDate).format('YYYY.MM.DD');
    var titleMaxDate = moment(endDate).format('YYYY.MM.DD');
    var componentCalendar = null;
    let locale = {
        format: 'YYYY-MM-DD',
        separator: ' - ',
        applyLabel: _t.t('Применить'),
        cancelLabel: _t.t('Закрыть'),
        fromLabel: _t.t('С'),
        toLabel: _t.t('По'),
        customRangeLabel: _t.t('Выбрать'),
        weekLabel: _t.t('Неделя'),
        daysOfWeek: [_t.t('Пн'), _t.t('Вт'), _t.t('Ср'), _t.t('Чт'), _t.t('Пт'), _t.t('Сб'), _t.t('Вс')],
        monthNames: [
            _t.t('Январь'),
            _t.t('Февраль'),
            _t.t('Март'),
            _t.t('Апрель'),
            _t.t('Май'),
            _t.t('Июнь'),
            _t.t('Июль'),
            _t.t('Август'),
            _t.t('Сентябрь'),
            _t.t('Октябрь'),
            _t.t('Ноябрь'),
            _t.t('Декабрь'),
        ],
        firstDay: 1,
    };
    componentCalendar = (
        <div className={prop}>
            <div id="radiusCalendarPlanBox" onClick={(e) => showMaxCalendar()} style={{ width: '100%', height: '100%' }}>
                <div id="minDateSelect">
                    <span className={classNameSpanTitleDate}>{titleMinDate}</span>
                </div>
                <div style={{ fontSize: '13px', float: 'left', marginTop: '5px' }}>{_t.t('по')}</div>
                <div id="maxDateSelect">
                    <span className={classNameSpanTitleDate}>{titleMaxDate}</span>
                </div>
            </div>
        </div>
    );
    var componentMinCalendar, componentMaxCalendar, classNameSpanTitleDate;
    if (datePeriod.always === true) {
        classNameSpanTitleDate = 'disabledSpanTitle';
    } else if (datePeriod.always === false) {
        classNameSpanTitleDate = null;
    }

    return <DateRangePicker
                autoApply={true}
                locale={locale}
                opens={"center"}
                startDate={startDate}
                endDate={endDate}
                drops="down"
                style={{width: '248px'}}
                onEvent={(ev, picker) => handleEvent(ev, picker)}>
                {componentCalendar}
            </DateRangePicker>;
    
};

DateBox.propTypes = {};
DateBox.defaultProps = {};

export default DateBox;

const StyledDateRangePicker = styled(DateRangePicker)`
    background-color: red !important;
    .daterangepicker .ltr .show-calendar .opensright {
    }
`;
