import React from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';

const SearchContainer = styled.div`
    /* margin: 20px 0; */
    width: 100%;
    box-sizing: border-box;
    input {
        box-sizing: border-box;
        width: 100% !important;
        margin: 0;
        padding: 0;
        min-height: 24px;
        /* background-color: whitesmoke !important; */
        border: 1px solid rgb(220, 220, 220) !important;
        font-family: ProximaNova;
        color: #000;
        font-size: 13px;
        padding-left: 10px !important;
    }
`;

const Search = React.memo(({ searchValue, ...props }) => {
    const handleSearch = e => {
        e.preventDefault();
        props.handleSearch(e.target.value);
    };

    return (
        <SearchContainer>
            <input
                value={searchValue}
                onChange={handleSearch}
                type="text"
                placeholder={_t.t('Поиск')}
                autoFocus="autofocus"
                className="search form-control"
            />
        </SearchContainer>
    );
});

export default Search;
