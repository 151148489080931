
import Panel from './components/panel'
import {connect} from "react-redux";
import Tools from '../../tools/tools';

import React, {Component} from 'react';
import { runAction } from "../../actions";
import AppActions from './actions/actions';
import AppStore from './stores/store';
import hostForLocation from '../../hostnames/hostname'

class MainSettingsControlPanel extends Component {
    state = {
        statusSave:AppStore.getState().statusSave
    }
    _onChange() {
        this.setState({
            statusSave:AppStore.getState().statusSave
        });
        if (AppStore.getState().statusSave !== null) {
            this.updateData(this.props)
        }
    }
    updateData(props) {
        this.getRequestTemplate(props)
        this.getSchedulesList(props)
    }
    getRequestTemplate(props) {
        props.getTemplateControlPage(props.token)

    }

    getSchedulesList(props) {
        props.getSchedulesList(props.token,props.pl_id)
    }
    componentDidMount() {
        AppStore.addChangeStoreModuleListener(this._onChange.bind(this));
    }
    componentWillUnmount() {
        AppStore.removeChangeStoreModuleListener(this._onChange.bind(this));
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (Tools.compare2(nextProps.dataRadiusStore, this.props.dataRadiusStore) === false) {
            AppActions.update(nextProps.globalStore)
        }
    }

    render() {
        return (
            <div>
                <Panel
                    dataRadiusStore={this.props.dataRadiusStore}
                    hideSettingsPanel={this.props.hideSettingsPanel}
                    token={this.props.token}
                    location={document.location.href}
                    lang={this.props.lang}
                    _t={this.props._t}
                    getStatusSettingsLoad={this.props.getStatusSettingsLoad}
				/>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dataRadiusStore: state.RadiusApp,
        token: state.LoginApp.token,
        globalStore:state,
        statusTopPanel: state.TopPanel.status,
        statusShowPanelNamePage: state.RadiusApp.statusShowPanelNameNewPage,
        nameNewPage: state.RadiusApp.nameNewPage,
        schedulesList: state.RadiusApp.schedulesList,
        statusSpinerLoader: state.RadiusApp.statusSpinerLoaderTemplate,
        dataTemplate: state.RadiusApp.template,
        dataObjects: null,
        // token:state.LoginApp.token,
        statusShowSettings:state.RadiusApp.statusShowSettings,
        idTemplateShowSettings:state.RadiusApp.idTemplateShowSettings,
        arrayLocationSelectNotMulti:state.PanelLocationApp.arrayLocationSelectNotMulti,
        pl_id: state.ReportParametersManager.customInputParamsValues.pl_id,
        lang:state.TopPanel.lang
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hideSettingsPanel: () => {

            dispatch(runAction('hideSettingsPanelAuth', {}));
        },
        updateDataControlPages: () => {
            dispatch(runAction('updateDataControlPages', {}));
        },
        getTemplateControlPage:  (token) => {
            let url = `${hostForLocation.getHostName().cms}/content/get_pages/?access_token=${token}`;
            dispatch(runAction('getTemplateControlPage', {token:token,url:url}));
        },
        getSchedulesList: (token,idPl) => {
            console.log('@@@@@@@@@@@@1', idPl);
            let url = `${hostForLocation.getHostName().cms}/content/schedules/?access_token=${token}&object_id=${idPl}`;
            dispatch(runAction('getSchedulesList', {token:token,url:url}));
        },

    }
};

const ConnectMainSettingsControlPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainSettingsControlPanel);

export default ConnectMainSettingsControlPanel;


