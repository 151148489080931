/* eslint-disable no-case-declarations */
// import tools from '../../tools/tools';
import React from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import isEqual from 'lodash/isEqual';
import L from 'leaflet';

import i18next from 'i18next';
import colors from '../../../tools/colors';
var Crypto = require('crypto-js');

export const getFreeHeight = (dataView, data) => {
    switch (dataView.data_view_type.key_name) {
        // case 'dynamics_diagram':
        //     return data && data.data && data.data[0] && data.data[0].data_aspects.object.relation === 'detalization' ? true : false;
        case 'funnel_diagram':
            return dataView.data_report.data_type.key_name === 'funnel_set' ? true : false;
        case 'table_data_view':
        case 'markdown_data_view':
        case 'mutual_intersections_chord_diagram':
        case 'mutual_intersections_heatmap_diagram':
        case 'dynamics_calendar_diagram':
            return true;
        default:
            return false;
    }
};

export const getColorFromCatalog = (item, colors) => {
    let color = '#000';
    const type = item.data_aspects.object.associations[0].type;

    const id = item.data_aspects.object.associations[0].id;

    if (colors && colors[`${type}:${id}`]) {
        color = colors[`${type}:${id}`];
    }
    return color;
};

export const generateSize = (dataView, defaultSize, cabinetMode = '') => {
    if (cabinetMode === '-pdf' && dataView.data_report.data_type.key_name === 'table') {
        return null;
    }
    return defaultSize
        ? {
              width: defaultSize.defaultWidth * dataView.size.width + 16 * (dataView.size.width - 1),
              height: defaultSize.defaultHeight * dataView.size.height + 16 * (dataView.size.height - 1),
          }
        : null;
};

window.abortControllers = {};

export const getDiagramsDataFromServer = (url, myInit, dataView, input_parameters, afterFunc = () => {}, hideSpinerDataView = () => {}) => {
    if (window.abortControllers[dataView.data_view_id]) {
        window.abortControllers[dataView.data_view_id].abort();
    }

    window.abortControllers[dataView.data_view_id] = new AbortController();
    const { signal } = window.abortControllers[dataView.data_view_id];

    let canFetch = true;
    let message = '';

    Object.keys(input_parameters).forEach((parameter) => {
        if (input_parameters[parameter] === undefined || input_parameters[parameter] === null) {
            canFetch = false;
            message = `Diagram index: ${dataView.data_view_id}, ${parameter} = ${input_parameters[parameter]}`;
        }
    });

    if (canFetch) {
        // console.log('>>>>>>>>>>>>>>1');
        fetch(url, { ...myInit, signal })
            .then((response) => {
                delete window.abortControllers[dataView.data_view_id];
                // if (!response.ok) {
                //     throw Error(response);
                // }
                return response.json();
            })
            .then((json) => {
                if (json.err) {
                    afterFunc(json, dataView, input_parameters, false);
                } else {
                    afterFunc(json, dataView, input_parameters, true);
                }
            })
            .catch((err) => {
                delete window.abortControllers[dataView.data_view_id];
                afterFunc({ message: err.message, name: err.name }, dataView, input_parameters, false);
            });
    } else {
        console.warn(message);
        hideSpinerDataView({ message: message, name: message }, dataView, input_parameters, false)
    }
};

export const get_heat_color = (val) => {
    if (val <= 0) {
        val = 0.001;
    }

    var x = 2.0 / (1 + Math.exp(-val * 3)) - 1;
    // x = val;
    var r = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.5))), 1);
    var g = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.25))), 1);
    var b = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * x)), 1);
    return 'rgba(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ',1)';
};

const getPolygonCenter = (areaPolygon) => {
    try {
        const poly = L.polygon(areaPolygon.map((item) => L.latLng(item[1], item[0])));
        return poly.getBounds().getCenter();
    } catch (error) {
        return undefined;
    }
};

export const prepareOutdoorMapData = (data, dataType, rest) => {
    const { reportingObjectsById, mapRef, currentZoom, logSizes } = rest;
    if (!mapRef || !mapRef.current) return undefined;

    const metresPerPixel =
        (40075016.686 * Math.abs(Math.cos((mapRef.current.leafletElement.getCenter().lat / 180) * Math.PI))) / Math.pow(2, currentZoom + 8);

    switch (dataType) {
        case 'external_intersection_arr':
            const maxValue = Math.max(...data.map((item) => item.intersection_count_unique / item.segment_count_unique));
            const minValue = Math.min(...data.map((item) => item.intersection_count_unique / item.segment_count_unique));
            // let contains = true;
            const params = data.map((item) => {
                const lat = item.geometry.coordinates[0];
                const lng = item.geometry.coordinates[1];
                // const c = mapRef.current.leafletElement.getBounds().contains(L.latLng(lat, lng));
                // contains = contains && c;
                const value = item.intersection_count_unique / item.segment_count_unique;
                const relativeValue = Number(value - minValue) / Number(maxValue - minValue);

                return {
                    radius: 13 * metresPerPixel,
                    value: Number((value * 100).toFixed(2)),
                    // radius: 100 * metresPerPixel * Math.sqrt(radius / maxRadius),
                    name: item.external_obj_name,
                    color: get_heat_color(relativeValue),
                    // color: diagramTools.generateColorForObjects(item.external_obj_name),
                    lat,
                    lng,
                };
            });
            const mapCenter = getPolygonCenter(data.map((item) => [item.geometry.coordinates[1], item.geometry.coordinates[0]]));

            const shapes = params
                .sort((a, b) => a.value - b.value)
                .map((item, i) => {
                    return (
                        <Circle
                            key={`c-${item.name}-${i}`}
                            id={item.name}
                            center={{ lat: Number(item.lat), lng: Number(item.lng) }}
                            fillColor={item.color}
                            fillOpacity={0.5}
                            color={'transparent'}
                            radius={item.radius}
                            // onClick={onCircleClick(item.id, typeOfRouter)}
                        >
                            <Tooltip key={i}>{`${item.name} (${item.value})`}</Tooltip>
                        </Circle>
                    );
                });
            return { shapes, mapCenter, params };

        case 'distribution_over_objects': {
            // console.log(data.data, reportingObjectsById);

            const maxValue = Math.max(...data.data.map((item) => item.values[0]));
            const minValue = Math.min(...data.data.map((item) => item.values[0]));
            if (maxValue === 0 || !maxValue) return undefined;
            // // let contains = true;
            const params = data.data
                .filter((item) => item.values[0] !== undefined)
                .map((item) => {
                    const objId = item.data_aspects.object.associations[0].id;
                    const lat = reportingObjectsById[objId].pl_coordinates.lat;
                    const lng = reportingObjectsById[objId].pl_coordinates.lng;
                    // const c = mapRef.current.leafletElement.getBounds().contains(L.latLng(lat, lng));
                    // contains = contains && c;
                    const value = item.values[0];
                    const relativeValue = Number(value - minValue) / Number(maxValue - minValue);
                    const name = item.data_aspects.object.associations[0].name;
                    return {
                        value: Number(value.toFixed(2)),
                        radius: logSizes ? 7 * metresPerPixel * Math.log2(relativeValue * 5 + 2) : 13 * metresPerPixel,
                        name,
                        color: get_heat_color(relativeValue),
                        // color: diagramTools.generateColorForObjects(name),
                        lat,
                        lng,
                    };
                });
            const mapCenter = getPolygonCenter(params.map((item) => [item.lng, item.lat]));

            const shapes = params
                .sort((a, b) => a.value - b.value)
                .map((item, i) => {
                    return (
                        <Circle
                            key={`c-${item.name}-${i}`}
                            id={item.name}
                            center={{ lat: Number(item.lat), lng: Number(item.lng) }}
                            fillColor={item.color}
                            fillOpacity={0.5}
                            color={'transparent'}
                            radius={item.radius}
                            // onClick={onCircleClick(item.id, typeOfRouter)}
                        >
                            <Tooltip key={i}>{`${item.name} (${item.value})`}</Tooltip>
                        </Circle>
                    );
                });
            return { shapes, mapCenter, params };
        }

        default:
            return undefined;
    }
};

export const prepareHorisontalBarColorData = (data, dataType, colors) => {
    if (Object.keys(data).length === 0) return undefined;
    const result = {};
    switch (dataType) {
        case 'external_intersection_arr':
        case 'external_cross_visits':
            const maxValue = Math.max(...data.map((item) => (item.intersection_count_unique * 100) / item.segment_count_unique));
            const minValue = Math.min(...data.map((item) => (item.intersection_count_unique * 100) / item.segment_count_unique));
            result.data = data
                .map((item) => {
                    const v = Number(((item.intersection_count_unique / item.segment_count_unique) * 100).toFixed(2));
                    return {
                        country: item.external_obj_name,
                        v,
                        vColor: get_heat_color((v - minValue) / (maxValue - minValue)),
                        // vColor: diagramTools.generateColorForObjects(item.external_obj_name)
                    };
                })
                .sort((a, b) => a.v - b.v);
            break;

        case 'distribution':
            result.data = data.x.values
                .map((item, i) => {
                    return {
                        country: item,
                        v: Number(data.data[1].values[i].toFixed(2)),
                        vColor: getColorFromCatalog(item, colors),
                        // vColor: diagramTools.generateColorForObjects(item),
                    };
                })
                .sort((a, b) => a.v - b.v);
            break;

        case 'distribution_over_objects':
            const allNames = data.data.map((item) => item.data_aspects.object.associations[0].name);
            result.data = data.data
                .map((item, i) => {
                    let name = item.data_aspects.object.associations[0].name;
                    if (allNames.filter((item) => item === name).length > 1) {
                        name = `${name} (id: ${item.data_aspects.object.associations[0].id})`;
                    }
                    return {
                        country: name,
                        v: Number(item.values[0].toFixed(2)),
                        vColor: getColorFromCatalog(item, colors),
                        // vColor: diagramTools.generateColorForObjects(name),
                    };
                })
                .sort((a, b) => a.v - b.v);
            break;

        default:
            return undefined;
    }

    result.keys = [`v`];
    return result;
};

export const getFixedLines = (value, maxLineLength = 25, maxLines = 2) => {
    const words = value.split(' ');

    //reduces the words into lines of maxLineLength
    const assembleLines = words.reduce(
        (acc, word) => {
            //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
            if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
                return {
                    lines: acc.lines.concat([acc.currLine + '\r\n']),
                    currLine: word,
                };
            }
            //otherwise add the word to the current line
            return {
                ...acc,
                currLine: acc.currLine + ' ' + word,
            };
        },
        { lines: [], currLine: '' }
    );

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine]);

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines);
    let children = [];

    lines.forEach((lineText, i) => {
        children.push(
            <span key={`${i}-${lineText}`}>
                {
                    // if on the second line, and that line's length is within 3 of the max length, add ellipsis
                    1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
                }
            </span>
        );
        //increment dy to render next line text below
    });

    return children;
};

export const greenRedGradient = [
    'hsl(115, 70%, 30%)',
    'hsl(115, 70%, 40%)',
    'hsl(115, 70%, 50%)',
    'hsl(115, 70%, 60%)',
    'hsl(115, 70%, 70%)',
    'hsl(115, 70%, 80%)',
    'hsl(0, 70%, 80%)',
    'hsl(0, 70%, 70%)',
    'hsl(0, 70%, 60%)',
    'hsl(0, 70%, 50%)',
    'hsl(0, 70%, 40%)',
    'hsl(0, 70%, 30%)',
];

function hashCode(str) {
    var len = str.length;
    var hash = 0;
    for (var i = 1; i <= len; i++) {
        var char = str.charCodeAt(i - 1);
        hash += char * Math.pow(31, len - i);
        hash = hash & hash;
    }
    return hash;
}

export const calculateAverage = (DATA, accuracy = 1) => {
    const arr = DATA.filter((item) => item.value !== null);
    if (!arr || arr.length === 0) return undefined;
    const result = Number(
        (
            arr.reduce((acc, item) => {
                return (acc += Number(item.value));
            }, 0) / arr.length
        ).toFixed(accuracy)
    );

    return result;
};

export const objectsCompare = (obj1, obj2) => {
    return isEqual(obj1, obj2);
};

export const calculateTrend = (DATA) => {
    const data = DATA.filter((item) => item.value !== null);
    if (!data || data.length === 0) return undefined;
    const avgX =
        data.reduce((acc, item, i) => {
            return (acc += i + 1);
        }, 0) / data.length;

    const squareAvgX = avgX * avgX;

    const avgY =
        data.reduce((acc, item, i) => {
            return (acc += Number(item.value));
        }, 0) / data.length;

    const b =
        (data.reduce((acc, item, i) => {
            return (acc = acc + (i + 1) * data[i].value);
        }, 0) -
            data.length * avgX * avgY) /
        (data.reduce((acc, item, i) => {
            return (acc = acc + (i + 1) * (i + 1));
        }, 0) -
            data.length * squareAvgX);

    const a = avgY - b * avgX;

    const result = [
        {
            x: data[0].x,
            value: a + b,
        },
        {
            x: data[data.length - 1].x,
            value: a + b * (data.length - 1),
        },
    ];

    return result;
};

var diagramTools = {
    generateColorForObjects: function (obj_id) {
        var hash = Crypto.MD5(obj_id.toString()).toString();
        var hash_hue = parseInt(hash.slice(0, 4), 16) / 65536;
        var hash_saturation = parseInt(hash.slice(4, 8), 16) / 65536;
        var hash_lightness = parseInt(hash.slice(8, 12), 16) / 65536;

        var hue = hash_hue; //Math.random();
        var saturation = 0.9;
        // var saturation = hash_saturation < 0.5 ? hash_saturation + 0.5 : hash_saturation;
        var lightness = hash_lightness < 0.4 ? hash_lightness + 0.4 : hash_lightness > 0.9 ? hash_lightness - 0.1 : hash_lightness;
        var c = colors.HSV2RGB(hue, saturation, lightness);

        return colors.RGB2HEX(c.r, c.g, c.b);
    },
    DLInfoWithAspects: function (data_aspects) {
        let stringText = '';
        let date_part = '';
        let color;

        if (data_aspects['time']['date_range_type'] === 'main_date_range') {
            date_part = i18next.t('Отчетный период');
            color = '#3c6499';
        } else if (data_aspects['time']['date_range_type'] === 'comparison_date_range') {
            date_part = i18next.t('Период сравнения');
            color = '#f49b10';
        } else {
            color = '#646464';
        }

        let object_name = '';
        if (data_aspects['object']['relation'] === 'detalization') {
            let associations = {};
            var obj_id = null;
            for (var i = 0; i < data_aspects['object']['associations'].length; i++) {
                let a = data_aspects['object']['associations'][i];
                associations[a['source']] = a;
            }
            if (associations['shopster_admin'] !== undefined) {
                obj_id = associations['shopster_admin'].id;
                object_name = associations['shopster_admin'].name;
            } else if (associations['shopster_analytics'] !== undefined) {
                obj_id = associations['shopster_analytics'].id;
                object_name = associations['shopster_analytics'].name;
            } else {
                let a = data_aspects['object']['associations'][0];
                obj_id = a.id;
                object_name = a.name;
            }
            color = this.generateColorForObjects(obj_id);
        }

        let object_part = object_name;
        if (object_part !== '' && date_part !== '') {
            if (data_aspects['time']['date_range_type'] === 'main_date_range') {
                stringText = object_part;
            } else {
                stringText = object_part + ', ' + date_part.toLowerCase();
            }
        } else if (object_part === '' && date_part === '') {
            stringText = i18next.t('(без имени)');
        } else {
            stringText = object_part + date_part;
        }
        return {
            color: color,
            label: stringText,
        };
    },
};

export default diagramTools;
