// var BigCalendar = require('../../react-big-calendar');
import Calendar from '../../react-big-calendar';

import Template from './template';
import SchedulesItem from './schedulesItem';


import '../style/hotSpot.css';
import '../style/settingsControlPanel.css';
import '../style/plan.css';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';

import FullCalendar from 'fullcalendar-reactwrapper';

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
var moment = require('moment');
var stateJSON = 0;

require('moment/min/locales.min');

function Control({
    statusShowPanelNamePage,
    nameNewPage,
    schedulesList,
    statusSpinerLoader,
    dataTemplate,
    dataObjects,
    hidePanelNameNewPage,
    onChangeValueName,
    onSubmitCreateNewPage,
    openModalNameNewPage,
    statusShowSettings,

    onClickCopy,
    onClickDelete,
    planing,
    onMouseEnter,
    onMouseLeave,
    showPage,
    onClickSettings,
    idTemplateShowSettings,
    methodForSchedulesItem,
    token,
    _t,
    onMouseEnterPlanOfCalendar,
    lang,
    objLangforBrowser,
    onClickLangBrowser,
    statusLangBrowser,
}) {
    const onMouseEnterPlanOfCalendarTemp = (data) => {
        onMouseEnterPlanOfCalendar(data);
    };
    const eventMouseover = (event, jsEvent, view) => {
        console.log('!!!!!!!!!!!', event);
    };
    if (lang === 'en') {
        moment.locale('en');
    } else if (lang === 'ru') {
        moment.locale('ru');
    }

    const onClickLangBrowserDefault = () => {
        onClickLangBrowser('default');
    };
    const onClickLangBrowserRu = () => {
        onClickLangBrowser('ru');
    };
    const onClickLangBrowserEn = () => {
        onClickLangBrowser('en');
    };
    const onClickLangBrowserEs = () => {
        onClickLangBrowser('es');
    };
    const onClickLangBrowserZh = () => {
        onClickLangBrowser('zh');
    };

    Calendar.getLang(
        lang === 'ru'
            ? {
                  previous_month: 'Предыдущий месяц',
                  current_month: 'Текущий месяц',
                  next_month: 'Следующий месяц',
              }
            : {
                  previous_month: 'Previous month',
                  current_month: 'Current month',
                  next_month: 'Next month',
              }
    );
    let schedulesListAfterFilter = schedulesList.filter((item) => {
        if (statusLangBrowser === 'default') {
            return item.browser_lang_code === null;
        } else {
            // console.log('>>>>>>>>>>', item.browser_lang_code === 'default')
            return item.browser_lang_code === statusLangBrowser || item.browser_lang_code === null;
        }
    });
    // console.log('>>>>>>>>>>', schedulesListAfterFilter)
    Calendar.setSchedulesList(schedulesListAfterFilter);
    ReactTooltip.rebuild()

    if (dataTemplate.pages !== undefined) {
        for (var i = 0; i < dataTemplate.pages.length; i++) {
            // console.log('control.jsx', AppStore.getState().template.pages[i]);
            if (dataTemplate.pages[i].is_default === true) {
                Calendar.setDefaultTemplate(dataTemplate.pages[i]);
            }
        }
    }

    const localizer = Calendar.momentLocalizer(moment);
    // console.log('!!!!!!!!!!!',localizer)
    var componentCardPlan,
        componentModalPanel = null;
    if (statusShowPanelNamePage === true) {
        componentModalPanel = (
            <div id="backgroundModal" onClick={(e) => hidePanelNameNewPage(e)} className="backgroundModal">
                <div className="boxNameNewPage">
                    <div className="header">
                        <h2>{_t.t('СОЗДАТЬ СТРАНИЦУ')}</h2>
                        <span id="closePanelNamePage" className="closePanel">
                            X
                        </span>
                    </div>
                    <div className="contentNewPage">
                        <span>{_t.t('Название страницы')}</span>
                        <input defaultValue={nameNewPage} onChange={(e) => onChangeValueName(e)} type="text" max={20} />
                    </div>
                    <div className="footer">
                        <button onClick={(e) => onSubmitCreateNewPage(token, nameNewPage)}>{_t.t('Создать')}</button>
                    </div>
                </div>
            </div>
        );
    }
    if (schedulesList.length > 0) {
        // console.log('>>>>>><<<<>>>', schedulesList)
        componentCardPlan = schedulesList.map(function (prop, id) {
            return <SchedulesItem _t={_t} token={token} methodForSchedulesItem={methodForSchedulesItem} prop={prop} key={id} />;
        });
    } else if (schedulesList.length === 0) {
        componentCardPlan = null;
    }
    var templatesNode;
    var spinerLoader;
    if (statusSpinerLoader === true) {
        spinerLoader = (
            <div className="spinetLoaderTemplateBox">
                <i className="fa spinerLoaderPosition"></i>
            </div>
        );
    } else if (statusSpinerLoader === false) {
        spinerLoader = null;
    }
    if (dataTemplate === null) {
        templatesNode = null;
    } else if (dataTemplate !== null) {
        if (dataTemplate.pages !== undefined) {
            var listShedules = schedulesList;
            templatesNode = dataTemplate.pages.map(function (prop, elem) {
                var statusSchedul = false,
                    color = 'rgba(0,0,0,0.5)';
                for (var i = 0; i < listShedules.length; i++) {
                    if (listShedules[i].page_id === prop.id) {
                        color = listShedules[i].color;
                        statusSchedul = true;
                    }
                }
                // console.log('>>>>>>>>>>>>>>', prop.uuid);
                return (
                    <Template
                        statusShowSettings={statusShowSettings}
                        color={color}
                        statusSchedul={statusSchedul}
                        prop={prop}
                        key={'TemplateItem' + elem + prop.uuid}
                        onClickCopy={onClickCopy}
                        onClickDelete={onClickDelete}
                        planing={planing}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        showPage={showPage}
                        onClickSettings={onClickSettings}
                        idTemplateShowSettings={idTemplateShowSettings}
                        token={token}
                        _t={_t}
                    />
                );
            });
        } else if (dataTemplate.pages === undefined) {
            templatesNode = null;
        }
    }

    // console.log('>>>>>>>>>>>>>>', moment().format('x'));
    return (
        <div
            className="controlBox"
            //      style={{
            //     height: window.innerHeight - 82 + 'px'
            // }}
        >
            {spinerLoader}
            <div style={{ position: 'static' }} className="radiusTemplateBox ">
                {templatesNode}
                <div onClick={(e) => openModalNameNewPage(e)} className="radiusTemlate addTemplate">
                    <span>+</span>
                </div>
            </div>
            <div className="radiusCalendarBox">
                <div className="radiusCalendarBoxItem">
                    <div className={`titleSchedules`}>
                        <h2>{_t.t('Запланированные страницы')}</h2>
                    </div>
                    {schedulesList.length > 0 ? (
                        <div className="boxBoxes">
                            <span className={'titleButton'}>{_t.t('Язык браузера')}</span>
                            <div className="boxButtonLang">
                                {
                                    <div
                                        onClick={onClickLangBrowserDefault}
                                        className={statusLangBrowser === 'default' ? 'itemButton active' : 'itemButton'}
                                    >
                                        {Object.keys(objLangforBrowser).length === 1 ? _t.t('Любой') : _t.t('Другой')}
                                    </div>
                                }
                                {objLangforBrowser['ru'] !== undefined ? (
                                    <div
                                        onClick={onClickLangBrowserRu}
                                        className={statusLangBrowser === 'ru' ? 'itemButton active' : 'itemButton'}
                                    >
                                        RU
                                    </div>
                                ) : null}
                                {objLangforBrowser['en'] !== undefined ? (
                                    <div
                                        onClick={onClickLangBrowserEn}
                                        className={statusLangBrowser === 'en' ? 'itemButton active' : 'itemButton'}
                                    >
                                        EN
                                    </div>
                                ) : null}
                                {objLangforBrowser['es'] !== undefined ? (
                                    <div
                                        onClick={onClickLangBrowserEs}
                                        className={statusLangBrowser === 'es' ? 'itemButton active' : 'itemButton'}
                                    >
                                        ES
                                    </div>
                                ) : null}
                                {objLangforBrowser['zh'] !== undefined ? (
                                    <div
                                        onClick={onClickLangBrowserZh}
                                        className={statusLangBrowser === 'zh' ? 'itemButton active' : 'itemButton'}
                                    >
                                        ZH
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                    <Calendar
                        onMouseEnter={onMouseEnterPlanOfCalendarTemp}
                        views={['month']}
                        events={[]}
                        startAccessor="startDate"
                        endAccessor="endDate"
                        localizer={localizer}
                    />
                </div>

                <div className={`titleSchedulesAfter`}>
                    {/*<h2>{_t.t('Запланированные страницы')}</h2>*/}
                    {componentCardPlan === null ? <span>{_t.t('(Нет запланированных страниц)')}</span> : null}
                    {/*<h2>Запланированные страницы</h2>*/}
                </div>
                {componentCardPlan}
            </div>
            <div style={{ clear: 'both' }}></div>
            {componentModalPanel}
            
        </div>
    );
}



Control.propTypes = {};
Control.defaultProps = {};

export default Control;
