import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
/* 
  background: ${(props) => (props.isOpen ? '#ccc' : '#ddd')}; */
  /* border-bottom: 1px solid #777; */
  display: flex;
  flex-direction: column;
  min-height: 20px;
  overflow: hidden;
  max-height: 100%;
  /* height: ${(props) => (props.isOpen ? '100%' : 'auto')}; */
  /* transition: all 0.3s; */
  flex-shrink: ${(props) => (props.isOpen ? '1' : '0')};
`;

const size = keyframes`
  0% { width: 0%; height: 0% }
  100% { width: 100%; height: 100% }
`;

const DimOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    z-index: 100;
    animation: ${size} 0.3s;
`;

const height = keyframes`
  0% { height: 0% }
  100% { height: 100% }
`;

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    animation: ${height} 0.3s;
`;

const Title = styled.div`
    flex-shrink: 0;
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};

    /* cursor: pointer; */
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 30px;
    margin-top: 1px;
    padding: 0 0 0 10px;
    background-color: ${(props) => props.locationsBgColor};
`;

const I = styled.i`
    font-size: 20px !important;
    color: #777;
`;

const IWrapper = styled.div`
    margin-left: auto;
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function AccordionSection({
    disabled,
    showArrows,
    locationsBgColor,
    title_сomponent,
    label,
    show = true,
    isOpen,
    children,
    ...props
}) {
    const onSectionClick = () => {
        props.onSectionClick(label);
    };

    return show ? (
        <Container isOpen={isOpen}>
            <Title locationsBgColor={locationsBgColor} disabled={disabled}>
                {disabled && <DimOverlay />}
                {title_сomponent}
                {showArrows && (
                    <IWrapper onClick={onSectionClick}>
                        {isOpen && <I className="fa fa-angle-up" aria-hidden="true" />}
                        {!isOpen && <I className="fa fa-angle-down" aria-hidden="true" />}
                    </IWrapper>
                )}
            </Title>
            {isOpen && <Content isOpen={isOpen}>{children}</Content>}
        </Container>
    ) : null;
}
