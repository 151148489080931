import { runAction } from '../../../actions';
import hostForLocation from '../../../hostnames/hostname';
import { getDiagramsDataFromServer } from './diagramTools';
import ReactGA from 'react-ga';

export default (dispatch, token, dataView, data, inputParamsValues, customGetDataFromServer, refreshLog = false) => {
    const getLoadDataDataView = (obj, dataView) => {
        
        dispatch(runAction('getLoadDataDataView', { obj, dataView }));
    };

    const getDataItemDataView = (data, dataView, obj, isSuccess) => {
        
        if (refreshLog) {
            dispatch(runAction('refreshLogOverlay_Diagrams', { log: data.log }));
        }
        dispatch(runAction('getDataItemDataView', { data, dataView, obj, isSuccess }));
    };

    const hideSpinerDataView = (data, dataView, obj, isSuccess) => {
        
        dispatch(runAction('hideSpinerDataView', { obj, dataView }));
    };

    let isEmptyParam = false;

    if (isEmptyParam || dataView === undefined) return;

    if (!customGetDataFromServer) {
        let stringUrlItem = '';
        if (dataView?.group?.source_of_data === 'STUB_DATA') {
            stringUrlItem = 'stub';
        } else if (dataView?.group?.source_of_data === 'REAL_DATA' || dataView?.group?.source_of_data === 'PRODUCTION_TYPE') {
            stringUrlItem = 'prod';
        }
        let url = `${hostForLocation.getHostName().analytics}/report_api/query_report/${stringUrlItem}/${dataView?.data_report?.key_name}/`;

        var myInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'x-token': token,
                'Content-type': 'application/json',
                'client-key': '0846ba98-8e67-4245-bcd2-2a948656eefb',
            },
            mode: 'cors',
            redirect: 'follow',
            body: JSON.stringify(inputParamsValues),
        };
        getLoadDataDataView(inputParamsValues, dataView);
        getDiagramsDataFromServer(url, myInit, dataView, inputParamsValues, getDataItemDataView, hideSpinerDataView);
        ReactGA.event({ category: 'DataReport Request', action: `${dataView.data_report.key_name}` });
        // handleReactGAEvent({ category: 'Report Request', action: `${dataView.data_report.key_name}` });
    } else if (customGetDataFromServer) {
        getLoadDataDataView(inputParamsValues, dataView);
        customGetDataFromServer(dataView.data_view_id, inputParamsValues, getDataItemDataView);
    }
};
