import React, { useState, useEffect, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components/macro';
import { prepareHorisontalBarColorData } from '../../tools/diagramTools';

const DistributionLegend = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    padding: 10px;
`;

const InnerContainer = styled.div`
    height: ${(props) => Math.max(40 * props.numberOfBars, props.maxHeight) + 'px'};
    /* overflow-y: scroll;
    overflow-x: hidden; */
`;

const ChartContainer = styled.div`
    height: calc(100% - 34px);
    overflow: auto;
    &:hover {
        overflow-y: auto;
        overflow-y: overlay;
    }
`;

const TooltipContainer = styled.strong`
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: whitesmoke;
    padding: 3px;
    p {
        margin: 0;
        padding: 0;
    }
    b {
        font-weight: bold;
        font-size: 16px;
        color: ${(p) => p.color};
    }
`;

const TooltipColorDot = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${(p) => p.bgColor};
    margin-right: 10px;
    flex-shrink: 0;
`;

const TooltipText = styled.div`
    width: 15px;
    height: 15px;
    background-color: ${(p) => p.bgColor};
    margin-right: 10px;
    flex-shrink: 0;
`;

const Tooltip = ({ data, ...props }) => {
    return (
        <TooltipContainer color={data.vColor}>
            <TooltipColorDot bgColor={data.vColor}></TooltipColorDot>
            <p>
                {data.country}: {data.v}
            </p>
        </TooltipContainer>
    );
};

const NivoResponsiveBar = React.memo(({ ...props }) => {
    if (!props.data || !props.dataView.data_report.data_type.key_name) return null;
    const [realDimensions, setRealDimensions] = useState({});

    useEffect(() => {
        const realDimensions = {
            width: props.size.width,
            height: props.size.height,
        };
        setRealDimensions({ ...realDimensions });
    }, []);

    // componentDidMount = () => {
    //     const data = this.props.data;
    //     const realDimensions = {
    //         width: this.props.size.width,
    //         height: this.props.size.height
    //     };
    //     this.setState({ data, realDimensions });
    //     // window.addEventListener('resize', () => this.setRealDimensions(this.refs.node));
    // };

    // componentWillUnmount() {
    //     // window.removeEventListener('resize', () => {});
    // }

    // setRealDimensions = node => {
    //     if (node) {
    //         const realDimensions = {
    //             width: node.clientWidth,
    //             height: node.clientHeight
    //         };
    //         this.setState({ realDimensions });
    //     }
    // };

    // componentDidUpdate = (prevProps, prevState) => {
    //     const data = this.props.data;
    //     this.setState({ data });
    //     if (this.refs.node) {
    //         if (
    //             prevState.realDimensions.width === this.refs.node.clientWidth &&
    //             prevState.realDimensions.height === this.refs.node.clientHeight
    //         )
    //             return;
    //         this.setRealDimensions(this.refs.node);
    //     }
    // };

    const theme = {
        axis: {
            tickColor: '#eee',
            ticks: {
                text: {
                    textColor: '#eee',
                    fontSize: 12,
                },
            },
        },
        grid: {
            stroke: '#888',
            strokeWidth: 1,
        },
    };

    const result = prepareHorisontalBarColorData(props.data, props.dataView.data_report.data_type.key_name, props.colors);
    if (!result) return null;
    const { data, keys } = result;

    const density = 9;
    const names = data.map((item) => item.country.length);
    const longestName = Math.max(...names);
    const maxValue = Math.max(...data.map((item) => item.v));
    const width = props.size.width;
    const leftMargin = longestName / 2 > width / 2 / density ? width / 2 : width / 3;

    const getTspanGroups = (value, maxLines = 2) => {
        const words = value.split(' ');
        const stringLength = (leftMargin * 2.2) / density;

        //reduces the words into lines of maxLineLength
        const assembleLines = words.reduce(
            (acc, word) => {
                //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
                if ((word + acc.currLine).length > stringLength / maxLines && acc.currLine !== '') {
                    return {
                        lines: acc.lines.concat([acc.currLine]),
                        currLine: word,
                    };
                }
                //otherwise add the word to the current line
                return {
                    ...acc,
                    currLine: acc.currLine + ' ' + word,
                };
            },
            { lines: [], currLine: '' }
        );

        //add the ending state of current line (the last line) to lines
        const allLines = assembleLines.lines.concat([assembleLines.currLine]);

        //for now, only take first 2 lines due to tick spacing and possible overflow
        const lines = allLines.slice(0, maxLines);
        let children = [];
        let dy = 0;

        lines.forEach((lineText, i, arr) => {
            const customShift = arr.length > 1 ? dy : 7;
            children.push(
                <tspan x={0} dy={customShift} key={i}>
                    {
                        // if on the second line, and that line's length is within 3 of the max length, add ellipsis
                        1 === i && allLines.length > 2 ? lineText.slice(0, stringLength / maxLines - 3) + '...' : lineText
                    }
                </tspan>
            );
            //increment dy to render next line text below
            dy += 14;
        });

        return children;
    };

    const renderTick = ({ x, y, value, textBaseline, textAnchor, opacity, textX, textY }) => {
        return (
            <g key={value} transform={`translate(${x},${y - 2})`} style={{ opacity }}>
                <text
                    alignmentBaseline={textBaseline}
                    style={theme.axis.ticks.text}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                >
                    {getTspanGroups(value)}
                </text>
            </g>
        );
    };

    // const onLegendClick = index => {
    //     if (index === 0) {
    //         toggleBar(1);
    //     }
    // };

    // const toggleBar = data => {
    //     const isComparisonShown = !isComparisonShown;
    //     setIsComparisonShown(isComparisonShown);
    // };

    const CustomBarComponent = (props) => {
        const { x, y, height, width, data, color, showTooltip, onMouseLeave, hideTooltip } = props;
        const BAR_HEIGHT = Math.min(height, realDimensions.height / 5);
        const handleTooltip = (e) => showTooltip(<Tooltip data={data.data}></Tooltip>, e);
        const handleMouseLeave = (e) => {
            onMouseLeave(data.data, e);
            hideTooltip(e);
        };

        return (
            <g transform={`translate(${x}, ${y + height / 2 - BAR_HEIGHT / 2})`}>
                <rect width={width} height={BAR_HEIGHT} fill={color} onMouseMove={handleTooltip} onMouseLeave={handleMouseLeave} />
                <text x={width + 15} y={BAR_HEIGHT / 2} alignmentBaseline={'central'}>
                    {data.value}
                </text>
            </g>
        );
    };

    return useMemo(() => {
        return (
            <ChartContainer
            // ref="node"
            // height={realDimensions.height - 55}
            // on
            >
                <InnerContainer numberOfBars={data.length} maxHeight={realDimensions.height}>
                    <ResponsiveBar
                        barComponent={CustomBarComponent}
                        theme={theme}
                        groupMode="grouped"
                        layout="horizontal"
                        data={data}
                        keys={keys}
                        indexBy="country"
                        margin={{
                            top: 25,
                            right: 90,
                            bottom: 40,
                            left: leftMargin,
                        }}
                        padding={0.2}
                        colors="nivo"
                        colorBy={(e) => {
                            var t = e.id;
                            return e.data[''.concat(t, 'Color')];
                        }}
                        borderColor="inherit:darker(1.6)"
                        axisBottom={null}
                        axisRight={null}
                        axisTop={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: 'country',
                            legendPosition: 'middle',
                            legendOffset: 32,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: 'middle',
                            legendOffset: -40,
                            renderTick: renderTick,
                        }}
                        tooltip={({ data }) => {
                            return <Tooltip data={data} />;
                        }}
                        // labelSkipWidth={50}
                        labelSkipHeight={20}
                        labelTextColor="#000"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        enableGridY={false}
                        // label={d => {
                        //     console.log(d);
                        //     return `${d.id}: ${d.value}`;
                        // }}
                        labelFormat={(value) => {
                            return (
                                <tspan x={(value * (realDimensions.width - 310)) / maxValue + 25} style={{ alignmentBaseline: 'central' }}>
                                    {value}
                                </tspan>
                            );
                        }}
                    />
                </InnerContainer>
            </ChartContainer>
        );
    }, [props.data, realDimensions]);
});

export default NivoResponsiveBar;
