import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { runAction } from '../../../actions';
import { Dropdown, DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { TooltipHost, TooltipDelay, DirectionalHint, ITooltipProps, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components/macro';

import TooltipAttachFile from './tooltip_attach_file';
import tools from "../../Diagrams/components/custom_controls/segments_control/tools/tools";

const ManualCustomParamsModal = React.memo(({ lang, customInputParamsValues, outdoor_ad_spaces, ...props }) => {
    const dispatch = useDispatch();
    const { staticAdCampSelectedSpaces } = useSelector((state) => state.AdCampaigns);
    const { t } = useTranslation();
    const [adCampParams, setAdCampParams] = useState({
        name: '',
        description: '',
        date_from: moment(customInputParamsValues.main_date_range[0]).toDate(),
        date_to: moment(customInputParamsValues.main_date_range[1]).toDate(),
        mailru_needs_upload: false,
        mailru_accounts: [],
        mailru_is_public: false,
        yandex_needs_upload: false,
        yandex_accounts: [],
        ad_space_ids: staticAdCampSelectedSpaces,
        file: null,
        type: 'dynamic',
    });

    useEffect(() => {
        setAdCampParams({ ...adCampParams, ad_space_ids: staticAdCampSelectedSpaces });
    }, [staticAdCampSelectedSpaces, adCampParams.type]);

    const [allChecked, setAllChecked] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [filter, setFilter] = useState('');
    const [valueMailRu, setValueMailRu] = useState('');
    const [mailru_is_public, set_mailru_is_public] = useState(false);
    const [valueYandexRu, setValueYandexRu] = useState('');
    const [theme] = useState(getTheme());
    const [statusDisableButtonOk, setStatusDisable] = useState(false);
    const [statusDisableButtonOkByYandex, setStatusDisableButtonOkByYandex] = useState(false);

    const DayPickerStrings = {
        months: [
            t('Январь'),
            t('Февраль'),
            t('Март'),
            t('Апрель'),
            t('Май'),
            t('Июнь'),
            t('Июль'),
            t('Август'),
            t('Сентябрь'),
            t('Октябрь'),
            t('Ноябрь'),
            t('Декабрь'),
        ],
        shortMonths: [
            t('Янв'),
            t('Фев'),
            t('Мар'),
            t('Апр'),
            t('Май'),
            t('Июн'),
            t('Июл'),
            t('Авг'),
            t('Сен'),
            t('Окт'),
            t('Ноя'),
            t('Дек'),
        ],
        // days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        days: [t('Воскресенье'), t('Понедельник'), t('Вторник'), t('Среда'), t('Четверг'), t('Пятница'), t('Суббота')],
        shortDays: [t('Вс'), t('Пн'), t('Вт'), t('Ср'), t('Чт'), t('Пт'), t('Сб')],
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Start date is required.',
        invalidInputErrorMessage: 'Invalid date format.',
    };

    const options = [
        { key: 'dynamic', text: t('Динамическая рекламная кампания') },
        { key: 'static', text: t('Статическая рекламная кампания') },
    ];

    const optionsMailRu = [
        { key: false, text: t('Приватный сегмент'), styles:{field: { marginRight: "15px"}} },
        { key: true, text: t('Публичный сегмент'), styles:{field: { marginRight: "15px"}} },
    ];

    const adSpaceIdsOptions = useMemo(() => {
        if (outdoor_ad_spaces.length === 0) return null;
        // console.log('>>>>>', outdoor_ad_spaces);
        return outdoor_ad_spaces
            .map((item) => ({ key: item.id, text: `${item.id}: ${item.ext_id}` }))
            .filter((item) => item.text.toLowerCase().indexOf(filter.toLowerCase()) + 1);
    }, [outdoor_ad_spaces, filter]);

    const onChange = React.useCallback((ev, option) => {
        setValueMailRu('');
        setValueYandexRu('');
        setAdCampParams({ ...adCampParams, type: option.key });
    }, []);

    const _onChange = (key) => (e, newValue) => {
        setAdCampParams({ ...adCampParams, [key]: newValue });
    };
    const onIsPublicChange = (ev, option) => {
        setAdCampParams({ ...adCampParams, mailru_is_public: option.key });
        set_mailru_is_public( option.key);
    };

    // const _onFilterChange = (e, newValue) => {
    //     setAdCampParams({ ...adCampParams, ad_space_ids: [] });
    //     setFilter(newValue);
    // };

    let stylesChoiceGroupMail = {
        flexContainer: { display: "flex" }
    }

    const _onUploadChange = (key) => (e, checked) => {
        setAdCampParams({ ...adCampParams, [key]: !!checked });
        if (key === 'mailru_needs_upload') {
            setValueMailRu('');
        }
        else {
            setValueYandexRu('')
        }
    };

    const _onChangeAccounts = (key) => (e, newValue) => {
        let arrayText = []

        if (key === 'mailru_accounts') {
            arrayText = tools.regexpCreateNewArrText('mailru', newValue);
            setValueMailRu(newValue)
            setStatusDisable(arrayText.length > 0 ? false : true)
        }
        else {
            arrayText = tools.regexpCreateNewArrText('yandexru', newValue);
            setValueYandexRu(newValue)
            setStatusDisableButtonOkByYandex(arrayText.length > 0 ? false : true)
        }

        setAdCampParams({ ...adCampParams, [key]: arrayText });
    };

    const _onSelectDate = (key) => (date) => {
        setAdCampParams({ ...adCampParams, [key]: date });
    };

    const _onFormatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    };

    const _onParseDateFromString = (key) => (value) => {
        const date = adCampParams[key] || new Date();
        const values = (value || '').trim().split('-');
        let year = values.length > 0 ? parseInt(values[0], 10) : date.getFullYear();
        const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
        const day = values.length > 2 ? Math.max(1, Math.min(31, parseInt(values[2], 10))) : date.getDate();
        if (year < 100) {
            year += date.getFullYear() - (date.getFullYear() % 100);
        }
        return new Date(year, month, day);
    };

    // const _onAdSpaceIdsChange = (event, item) => {
    //     const newSelectedItems = [...adCampParams.ad_space_ids];
    //     if (item.selected) {
    //         newSelectedItems.push(item.key);
    //     } else {
    //         const currIndex = newSelectedItems.indexOf(item.key);
    //         if (currIndex > -1) {
    //             newSelectedItems.splice(currIndex, 1);
    //         }
    //     }
    //     setAdCampParams({ ...adCampParams, ad_space_ids: [...newSelectedItems] });
    // };

    const _onFileChange = (e) => {
        const file = [...e.target.files][0];

        if (file.size > 350 * 1024 * 1024) {
            setAdCampParams({ ...adCampParams, file: null });
            alert(t('Превышен максимальный размер файла'));
        }
        // else if (file.type !== 'text/csv') {
        //     setAdCampParams({ ...adCampParams, file: null });
        //     alert(t('Файл не того типа'));
        // }
        else {
            setAdCampParams({ ...adCampParams, file, name: file.name.replace(/.csv/, '') });
        }
    };

    const tooltipProps = {
        onRenderContent: () => <TooltipAttachFile />,
    };

    // const hostStyles = { root: { display: 'flex' } };
    const infoIcon = { iconName: 'Info' };

    const onInfoIconClick = () => {
        setShowInfo(true);
    };

    const onModalButtonClick = (action, params) => (e) => {
        if (action === null || action === 'createNewAdCampaign') {
            dispatch(runAction('resetStaticAdCampSelectedSpaces_AdCampaigns', {}));
        }

        props.onModalButtonClick({ name: action }, params);
    };

    console.log('>>>>>>>>>>>>>>>>>>>>>>', window.location.origin)

    const MainComponent = useMemo(() => {
        const firstDayOfWeek = DayOfWeek.Monday;
        return (
            <Container>
                <ChoiceGroup selectedKey={adCampParams.type} options={options} onChange={onChange} />
                <MainWrapper>
                    {adCampParams.type === 'dynamic' ? (
                        <React.Fragment>
                            <LabelWrapper>
                                <Label>{t('Файл эфирной справки')} </Label>
                                <StTooltipHost
                                    tooltipProps={tooltipProps}
                                    delay={TooltipDelay.long}
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    <StIconButton iconProps={infoIcon} onClick={onInfoIconClick} />
                                </StTooltipHost>
                            </LabelWrapper>
                            <input
                                accept="text/csv, .csv"
                                onChange={_onFileChange}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                            />
                            <LabelWrapper>
                                <FileLabel className="FileLabel" htmlFor="raised-button-file">
                                    <ChooseFile
                                        component="span"
                                        bgColor={theme.palette.themePrimary}
                                        hoverBgColor={theme.palette.themeDark}
                                    >
                                        {t('Прикрепить файл')}
                                    </ChooseFile>
                                </FileLabel>
                                <FileName>{adCampParams.file && adCampParams.file.name}</FileName>
                            </LabelWrapper>
                        </React.Fragment>
                    ) : null}
                    <TextField
                        isRequired={true}
                        label={t('Имя')}
                        value={adCampParams.name}
                        onChange={_onChange('name')}
                        placeholder={t('Минимум 1 символ')}
                        // styles={{ fieldGroup: { width: 150 } }}
                    />
                    { window.location.origin === 'https://ooh.marketer.lv' ? null :
                        <Block>
                            <div>

                                <StCheckbox
                                    label={t('Выгружать в Mail.ru')}
                                    checked={adCampParams.mailru_needs_upload}
                                    onChange={_onUploadChange('mailru_needs_upload')}
                                />
                                <StChoiceGroup selectedKey={mailru_is_public} options={optionsMailRu} label={t('Тип сегмента')} onChange={onIsPublicChange}
                                               styles={stylesChoiceGroupMail}
                                               disabled={!adCampParams.mailru_needs_upload}
                                />
                                <TextField
                                    label={t('Аккаунты Mail.ru')}
                                    value={valueMailRu}
                                    onChange={_onChangeAccounts('mailru_accounts')}
                                    multiline
                                    disabled={mailru_is_public || !adCampParams.mailru_needs_upload}
                                />
                            </div>
                            <div>
                                <StCheckbox
                                    label={t('Выгружать в Yandex')}
                                    checked={adCampParams.yandex_needs_upload}
                                    onChange={_onUploadChange('yandex_needs_upload')}
                                />
                                <TextField
                                    label={t('Аккаунты Yandex')}
                                    value={valueYandexRu}
                                    onChange={_onChangeAccounts('yandex_accounts')}
                                    multiline
                                    disabled={!adCampParams.yandex_needs_upload}
                                />
                            </div>
                        </Block>
                    }

                    {adCampParams.type === 'static' && adSpaceIdsOptions ? (
                        <>
                            {/* <Dropdown
                                placeholder={t('Выберите рекламные поверхности')}
                                label={t('Рекламные поверхности')}
                                selectedKeys={adCampParams.ad_space_ids}
                                onChange={_onAdSpaceIdsChange}
                                multiSelect
                                options={adSpaceIdsOptions}
                                styles={{ dropdown: { width: 400 } }}
                            /> */}
                            <StyledPrimaryButton
                                text={t('Выберите рекламные поверхности')}
                                width={200}
                                onClick={onModalButtonClick('showAdSpacesMapPanel', {})}
                            />
                            {/* <TextField
                                label={t('Фильтр')}
                                value={filter}
                                onChange={_onFilterChange}
                            /> */}
                            <TextField label={t('Выбранные рекламные поверхности')} value={adCampParams.ad_space_ids.join(', ')} multiline />
                        </>
                    ) : null}
                    <TextField
                        label={t('Описание')}
                        value={adCampParams.description}
                        onChange={_onChange('description')}
                        multiline
                        // styles={{ fieldGroup: { width: 150 } }}
                    />
                    {adCampParams.type === 'static' ? (
                        <Block>
                            <DatePicker
                                // className={controlClass.control}
                                label={`${t('Дата начала')} (${t('не может быть позднее даты окончания')})`}
                                // isRequired={true}
                                allowTextInput={true}
                                // ariaLabel={desc}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                value={adCampParams.date_from}
                                onSelectDate={_onSelectDate('date_from')}
                                formatDate={_onFormatDate}
                                parseDateFromString={_onParseDateFromString('date_from')}
                                maxDate={adCampParams.date_to}
                            />
                            <DatePicker
                                // className={controlClass.control}
                                label={t('Дата окончания')}
                                // isRequired={true}
                                allowTextInput={true}
                                // ariaLabel={desc}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                value={adCampParams.date_to}
                                onSelectDate={_onSelectDate('date_to')}
                                formatDate={_onFormatDate}
                                parseDateFromString={_onParseDateFromString('date_to')}
                                minDate={adCampParams.date_from}
                            />
                        </Block>
                    ) : null}
                </MainWrapper>
            </Container>
        );
    }, [adCampParams, allChecked, filter, mailru_is_public, valueMailRu, valueYandexRu]);


    const stackTokens = { childrenGap: 10 };

    const primaryDisabled = adCampParams.name.length < 1;

    const getStatusText = () => {
        let status1 = false;
        let status2 = false;
        if (adCampParams.mailru_needs_upload) {
            if (!mailru_is_public) {
                status1 = statusDisableButtonOk || valueMailRu === '';
            }
            else {
                status1 = false;
            }
        }
        if (adCampParams.yandex_needs_upload) {
            status2 = statusDisableButtonOkByYandex || valueYandexRu === '';
        }

        return status1 || status2;

        // return ((!mailru_is_public && adCampParams.mailru_needs_upload) && valueMailRu === '') ||
        //     (adCampParams.yandex_needs_upload && valueYandexRu === '')
    }

    return (
        <Wrapper>
            {MainComponent}
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    {
                       ( adCampParams.ad_space_ids.length > 2 || !((+adCampParams.date_to -  +adCampParams.date_from) <= 15552000000)) && adCampParams.type === 'static' ?
                            <SpanWarningSC>
                                {t('Для выбора доступно максимум 2 рекламные поверхности и максимум за 180 дней.')}
                            </SpanWarningSC> :
                            null
                    }

                    {
                        adCampParams.type === 'static'
                            ?
                        <PrimaryButton
                        text={'OK'}
                        width={'100px'}
                        disabled={primaryDisabled || getStatusText() || adCampParams.ad_space_ids.length > 2 || !((+adCampParams.date_to -  +adCampParams.date_from) <= 15552000000)}
                        onClick={onModalButtonClick('createNewAdCampaign', adCampParams)}
                        />
                            :
                            <PrimaryButton
                                text={'OK'}
                                width={'100px'}
                                disabled={primaryDisabled || getStatusText()}
                                onClick={onModalButtonClick('createNewAdCampaign', adCampParams)}
                            />
                    }


                    <DefaultButton text={t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                </Stack>
            </Footer>
        </Wrapper>
    );
});

export default ManualCustomParamsModal;

const SpanWarningSC = styled.span`
    color:red;
  align-items: center;
  justify-items: center;
  display: grid;
`

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const StIconButton = styled(IconButton)`
    margin-top: 10px;
    margin-left: 5px;
    i {
        font-size: 16px;
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 15px;
    width: 300px !important;
`;

const StTooltipHost = styled(TooltipHost)`
    max-width: 80vw !important;
    div {
        max-width: 80vw !important;
    }
`;
const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;
const FileLabel = styled.label`
    display: flex;
    align-items: center;
    flex-grow: 0;
    /* width: 100%; */
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label:not(.FileLabel) {
        margin-top: 10px;
    }
`;
const Block = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    /* border-bottom: 1px solid rgb(244, 244, 244);
    padding-bottom: 10px;
    margin-bottom: 10px; */
`;

const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-between; */
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 20px;
    /* overflow: auto; */
    width: 100%;
`;
const FileName = styled.span`
    margin-left: 10px;
`;
const ChooseFile = styled.section`
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    background: transparent;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    text-overflow: ellipsis;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
    background-color: ${(p) => p.bgColor};
    color: rgb(255, 255, 255);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    font-family: inherit;
    flex-shrink: 1;
    :hover {
        background-color: ${(p) => p.hoverBgColor};
    }
`;
const StCheckbox = styled(Checkbox)`
    margin-top: 5px !important;
`;

const StChoiceGroup = styled(ChoiceGroup)`
    padding-top: 10px;
`;
