import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { List } from 'office-ui-fabric-react/lib/List';
import styled from 'styled-components/macro';

const AdCampaignInfoModal = React.memo(({ info, ...props }) => {
    const items = useMemo(() => {
        return Object.keys(info).map(key => {
            return { name: key, description: String(info[key]) };
        });
    }, [info]);

    const _onRenderCell = (item, index) => {
        return (
            <Row index={index}>
                <Name>{item.name}</Name>
                <Desription>{item.description}</Desription>
            </Row>
        );
    };

    return (
        <Wrapper>
            <List items={items} onRenderCell={_onRenderCell} />
        </Wrapper>
    );
});

export default AdCampaignInfoModal;

const Row = styled.div`
    padding: 10px;
    border-bottom: 1px solid rgb(244, 244, 244);
    background-color: ${p => (p.index % 2 === 0 ? 'rgb(244, 244, 244)' : 'transparent')};
    display: flex;
    justify-content: space-between;
`;

const Name = styled.div`
    font-size: 18px;
    font-weight: 700;
`;

const Desription = styled.div`
    user-select: text;
    margin-left: 20px;
`;

const Wrapper = styled.div``;
