import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro'

const ClearBoth = styled.div`
clear: both;
display:block;
height:0px;
width: 100%;
`

class GlobalParameterItem extends PureComponent {
    render() {
        // console.log('>>>>>>>>', this.props.parameter.details.choices)
        let titlename = ''
        for (var i = 0; i < this.props.parameter.details.choices.length; i++) {
            if (this.props.parameter.details.choices[i].value === this.props.globalInputParamsValues[this.props.parameter.key_name]) {
                titlename = this.props.lang === 'ru' ? this.props.parameter.details.choices[i].name.default : this.props.parameter.details.choices[i].name.en
            }
        }
        return (

        <div id={this.props.id} className="itmeRow">
            <div className="itmeRowCount">
                <div className="leftItemRow">
                    <span className={'itemRowChiled '}>{this.props.parameter.translations[this.props.lang].name + ': '}</span>
                </div>
                <div className="rightItemRow">
                    <div className="itemRowChiled dateCompareFrom">{titlename}</div>
                    <ClearBoth/>
                </div>
                <ClearBoth/>
            </div>
        </div>


        );
    }
}

GlobalParameterItem.propTypes = {};

export default GlobalParameterItem;
