import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { getTheme, mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import Dash from '../../pl_panel/components/dash';

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`;

const Li = styled.li`
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 0 7px 0;
    width: 100%;
    display: flex;
    align-items: center;
`;

const MainObjectWrapper = styled.div`
    cursor: pointer;
    border-radius: 50%;
    border: ${(p) => '1px solid ' + p.color};
    opacity: ${(p) => (p.showMainObjectWrapper ? 1 : 0)};
    pointer-events: ${(p) => (p.showMainObjectWrapper ? 'auto' : 'none')};
    width: 14px;
    height: 14px;
    margin-right: 10px;
    transition: opacity 0.2s ease-in;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainDot = styled.div`
    cursor: pointer;
    border-radius: 50%;
    background-color: ${(p) => p.color};
    opacity: ${(p) => (p.showMainDot ? 1 : 0)};
    pointer-events: ${(p) => (p.showMainDot ? 'auto' : 'none')};
    width: 10px;
    height: 10px;
    /* transition: opacity 0.2s ease-in; */
    flex-shrink: 0;
`;

const ObjectsList = React.memo(
    ({
        showObjectsColorDots,
        colors,
        reportingObjectsByPlId_ctg,
        reportingObjectsById_ctg,
        allObjects,
        objects,
        plId,
        showDeleteObjectsButtons,
        onDeleteDashButtonClick,
        customInputParamsValues,
        mouseOver,
        mode,
        ...props
    }) => {
        const [theme] = useState(getTheme());
        const { main_obj_id, main_obj_ids, contra_obj_ids } = customInputParamsValues;
        const { t } = useTranslation();
        const arrangedObjects = () => {
            const list = [];
            const location = { ...reportingObjectsByPlId_ctg[plId] };
            if (objects.includes(location.main_servicepoint.id)) {
                const color = colors[`${location.main_servicepoint.obj_type}:${location.main_servicepoint.id}`];
                list.push({ id: location.main_servicepoint.id, color });
            }
            Object.keys(location.objects).forEach((group) => {
                location.objects[group].forEach((object) => {
                    if (objects.includes(object.id)) {
                        const color = colors[`${object.obj_type}:${object.id}`];
                        list.push({ id: object.id, color });
                    }
                });
            });
            return list;
        };

        const allNames = allObjects.map((item) => reportingObjectsById_ctg[item].name);

        const onMainObjectClick = (id) => (e) => {
            if (mode === 'main_set_2_contra_set' || (mode === 'main_2_contra_set' && main_obj_id !== id)) {
                props.onMainObjectClick(id);
            }
        };

        return (
            <React.Fragment>
                <Ul>
                    {arrangedObjects().map((item) => {
                        let name = reportingObjectsById_ctg[item.id].name;

                        if (allNames.filter((n) => n === name).length > 1) {
                            name = `${name} (id: ${item.id})`;
                        }

                        const showMainObjectWrapper =
                            mouseOver && (mode === 'main_2_contra_set' || mode === 'main_set_2_contra_set')
                                ? true
                                : mode === 'main_2_contra_set' && main_obj_id === item.id
                                ? true
                                : mode === 'main_set_2_contra_set' && main_obj_ids.includes(item.id)
                                ? true
                                : false;

                        const showMainDot =
                            mode === 'main_2_contra_set' && main_obj_id === item.id
                                ? true
                                : mode === 'main_set_2_contra_set' && main_obj_ids.includes(item.id)
                                ? true
                                : false;

                        const dotColor = showObjectsColorDots ? item.color : false;

                        // console.log(colors);

                        return (
                            <Li key={`objects - ${item.id}`}>
                                <MainObjectWrapper
                                    onClick={onMainObjectClick(item.id)}
                                    showMainObjectWrapper={showMainObjectWrapper}
                                    color={`${theme.palette.themePrimary}`}
                                >
                                    <MainDot showMainDot={showMainDot} color={`${theme.palette.themePrimary}`} />
                                </MainObjectWrapper>
                                <Dash
                                    dotColor={dotColor}
                                    plId={plId}
                                    id={item.id}
                                    showDeleteObjectsButtons={showDeleteObjectsButtons}
                                    onDeleteDashButtonClick={onDeleteDashButtonClick}
                                    statusSelect={true}
                                    onClick={() => {}}
                                    text={item.id === reportingObjectsByPlId_ctg[plId].main_servicepoint.id ? t('Весь объект') : name}
                                />
                            </Li>
                        );
                    })}
                </Ul>
            </React.Fragment>
        );
    }
);

export default ObjectsList;
