var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
// var compare = require('../../../funcModule/func.js');
var stringify = require('json-stable-stringify');
// var lang = require('../../../lang/func.js');

var compare = {
    compareGraphicBlock: function (template, original) {
        // console.log('func', template, original);
        if (JSON.stringify(template) === JSON.stringify(original)) {
            return true;
        } else if (JSON.stringify(template) !== JSON.stringify(original)) {
            return false;
        }
    },
    compareLogoBlock: function (template, original) {
        if (JSON.stringify(template) === JSON.stringify(original)) {
            return true;
        } else if (JSON.stringify(template) !== JSON.stringify(original)) {
            return false;
        }
    },
    compareTextBlock: function (template, original) {
        if (JSON.stringify(template) === JSON.stringify(original)) {
            return true;
        } else if (JSON.stringify(template) !== JSON.stringify(original)) {
            return false;
        }
    },
    copyJson: function (elem) {
        var JsonData = JSON.stringify(elem);
        var beforeJson = JSON.parse(JsonData);
        return beforeJson;
    },
};

var state = {
    statusSettings: 'performance',
    paramSatingsItem: {},
    statusSettingsAuth: true,
    authParams: {
        layersCount: 0,
        layers: [],
        sessionPeriod: 30,
        authPeriod: 30,
        nextConnectionPeriod: 0,
        traficVolume: 500,
        redirectUrl: 'http://google.com',
    },
    statusSms: false,
    statusButton: false,
    statusVK: false,
    statusFb: false,
    statusOk: false,
    statusPass: false,
    dataPage: {},
    title_layers: [],
    inputPass: '',
    headers: '',
    statusLoad: false,
    statusSocial: false,
    VKAutoPost: {
        status: false,
        statusOfNameUser: true,
        statusRepost: false,
        valueOfNameUser: {
            message: '',
            app: '',
        },
        valueRepost: {
            id: '',
        },
    },
    FBAutoPost: {
        status: false,
        link: '',
        title: '',
        discriptions: '',
        image: '',
    },
    dataFiles: {},
    statusShowModalBoxImage: false,
    statusModeModalImageBox: '',
    statusShowUploadPanel: false,
    dataFilesUpload: [],
    valueTitleImageUpload: '',
    statusUploadFiles: false,
    statusLoadFiles: false,
    authBackgroundColor: 'rgba(51, 71, 80, 0.9)',
    authButtonBackgroundColor: 'rgba(51,71,80,0.9)',
    authButtonText: 'ВОЙТИ В ИНТЕРНЕТ',
    authDisplayMode: false,
    urlImageBackground: '',
    backgroundColorWelcomGlobalPage: 'rgba(255,255,255,.5)',
    statusSelectBackgroundColorWelcomGlobalPage: false,
    urlImageLogo: '',
    valueText: '',
    colorValueText: 'rgba(0,0,0,1)',
    urlImageGraphic: '',
    templateLogoBlock: [
        {
            prototype: {
                name: null,
                rel_width: 0.5,
                ratio: 0.5,
                details: null,
                size: null,
            },
            params: {},
            subBlocks: [],
            media: null,
            style: null,
        },
    ],
    templateTextBlock: [
        {
            prototype: {
                name: null,
                rel_width: 1,
                ratio: 0.4,
                details: null,
                size: null,
            },
            params: {},
            subBlocks: [],
            textParams: {
                font: "'Roboto', sans-serif",
                fontSize: null,
                textDecoration: 'initial',
                fontWeight: 'normal',
                fontStyle: 'normal',
                horizontalAlign: 'center',
                verticalAlign: 'middle',
                textValue: null,
                color: null,
            },
            backgroundColor: null,
        },
    ],
    templateGriphicBlock: [
        {
            prototype: {
                name: null,
                rel_width: 1,
                ratio: 0.6,
                details: null,
                size: null,
            },
            params: {},
            subBlocks: [],
            media: null,
            style: null,
        },
    ],
    fontSize: 0,
    statusPerformens: false,
    indexText: null,
    indexGraphicBlock: null,
    statusLoadLogo: false,
    statusLoadTempPage: false,
    previewsPageUrl: '',
    statusTextStyle: null,
    statusCall: false,
    inputCall: 15552000,
    blocksData: null,
    statusSave: null,
};

var CHANGE_EVENT = 'change';

var AppStore = assign({}, EventEmitter.prototype, {
    moduleChangeStoreListeners: [],
    addChangeStoreModuleListener: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    },
    removeChangeStoreModuleListener: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    },
    emitChangeToModuleListeners: function () {
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](state);
        }
    },
    selectStatus: function (data) {
        state.statusSettings = data;
        // console.log('store', e.target);
        this.emitChangeToModuleListeners();
    },
    workInBlocks: function (dataParam) {
        if (dataParam.blocks.length < 4) {
            // console.log('store!@#!!!!', dataParam.blocks);
            if (dataParam.blocks.length === 0) {
                state.statusPerformens = true;
            } else if (dataParam.blocks.length > 0) {
                var obj = compare.copyJson(dataParam.blocks);
                for (var j = 0; j < obj.length; j++) {
                    obj[j].prototype.name = null;
                    obj[j].prototype.details = null;
                    obj[j].prototype.size = null;
                    if (obj[j].media !== undefined) {
                        obj[j].media = null;
                        obj[j]['style'] = null;
                    }
                    if (obj[j].prototype.ratio !== undefined) {
                        // obj[j].prototype.ratio = null;
                    }
                    if (obj[j].textParams !== undefined) {
                        obj[j].textParams.textValue = null;
                        obj[j].textParams.color = null;
                        state.fontSize = obj[j].textParams.fontSize;
                        // obj[j]['style'] = null;
                        obj[j].textParams.fontSize = null;
                        if (obj[j].style !== undefined) {
                            state.statusTextStyle = dataParam.blocks[j].style;
                            delete obj[j].style;
                        }
                        obj[j]['backgroundColor'] = null;
                    }
                }
                // console.log('store!@#', state.statusTextStyle);
                this.compareBlocks(dataParam, obj);
            }
        } else if (dataParam.blocks.length >= 4) {
            state.statusPerformens = false;
        }
    },
    compareBlocks: function (dataParam, obj) {
        state.indexText = null;
        if (obj.length === 1) {
            // console.log('store>>>>>>>>>>>>', state.templateTextBlock[0],obj[0], this.compare(state.templateTextBlock[0], obj[0]));
            if (this.compare(state.templateLogoBlock[0], obj[0]) === true) {
                // console.log('store>>>>>>>>>>>>!!!!!!', dataParam.blocks);
                state.urlImageLogo = dataParam.blocks[0].media[0].url;
                state.statusPerformens = true;
                state.statusLoadLogo = true;
            } else if (this.compare(state.templateTextBlock[0], obj[0]) === true) {
                // console.log('store>>>>>>>>>>>>11111', dataParam.blocks);
                state.valueText = dataParam.blocks[0].textParams.textValue;
                if (dataParam.blocks[0].textParams.color !== '') {
                    state.colorValueText = dataParam.blocks[0].textParams.color;
                }
                state.statusPerformens = true;
                state.indexText = 0;
            } else if (this.compare(state.templateGriphicBlock[0], obj[0]) === true) {
                // console.log('store>>>>>>>>>>>>2222', dataParam.blocks);
                state.urlImageGraphic = dataParam.blocks[0].media[0].url;
                state.statusPerformens = true;
                state.indexGraphicBlock = 0;
            }
        } else if (obj.length === 2) {
            // console.log('store>>>>>>>>>>>>', state.templateTextBlock[0], obj[0]);
            if (this.compare(state.templateLogoBlock[0], obj[0]) === true && this.compare(state.templateTextBlock[0], obj[1]) === true) {
                state.urlImageLogo = dataParam.blocks[0].media[0].url;
                state.valueText = dataParam.blocks[1].textParams.textValue;
                if (dataParam.blocks[1].textParams.color !== '') {
                    state.colorValueText = dataParam.blocks[1].textParams.color;
                }
                state.statusPerformens = true;
                state.statusLoadLogo = true;
                state.indexText = 1;
            } else if (
                this.compare(state.templateLogoBlock[0], obj[0]) === true &&
                this.compare(state.templateGriphicBlock[0], obj[1]) === true
            ) {
                state.urlImageLogo = dataParam.blocks[0].media[0].url;
                state.urlImageGraphic = dataParam.blocks[1].media[0].url;
                state.statusPerformens = true;
                state.statusLoadLogo = true;
                state.indexGraphicBlock = 1;
            } else if (
                this.compare(state.templateTextBlock[0], obj[0]) === true &&
                this.compare(state.templateGriphicBlock[0], obj[1]) === true
            ) {
                state.valueText = dataParam.blocks[0].textParams.textValue;
                state.urlImageGraphic = dataParam.blocks[1].media[0].url;
                if (dataParam.blocks[0].textParams.color !== '') {
                    state.colorValueText = dataParam.blocks[0].textParams.color;
                }
                state.statusPerformens = true;
                state.indexText = 0;
                state.indexGraphicBlock = 1;
            }
        } else if (obj.length === 3) {
            // console.log('store>>>>>>>>>>>>', state.templateGriphicBlock[0], obj[2]);
            if (
                this.compare(state.templateLogoBlock[0], obj[0]) === true &&
                this.compare(state.templateTextBlock[0], obj[1]) === true &&
                this.compare(state.templateGriphicBlock[0], obj[2]) === true
            ) {
                // console.log('store@##@@##@@##@@##@@#', dataParam.blocks);
                state.statusLoadLogo = true;
                state.urlImageLogo = dataParam.blocks[0].media[0].url;
                state.valueText = dataParam.blocks[1].textParams.textValue;
                state.urlImageGraphic = dataParam.blocks[2].media[0].url;
                if (dataParam.blocks[1].textParams.color !== '') {
                    state.colorValueText = dataParam.blocks[1].textParams.color;
                }
                state.statusPerformens = true;
                state.indexText = 1;
                state.indexGraphicBlock = 2;
            }
        }
    },
    worksInAuthParams: function (dataParam) {
        // console.log('store>>>>>>>>>>>>', dataParam.authParams);

        if (dataParam.authParams !== undefined && dataParam.authParams !== null && dataParam.authParams.layers !== undefined) {
            // console.log('store>>>>>>>>>>>><<<<<<<<', dataParam);
            var dataAuthParams = dataParam.authParams;
            var layers = dataAuthParams.layers;
            var title_layers = [];
            var titles = {};
            for (var layer in layers) {
                var l = [];
                for (var auth_item in layers[layer]) {
                    var auth_title = layers[layer][auth_item]['auth_title'];
                    // if (layers[layer][auth_item].autopost === true) {
                    //     auth_title += 'autopost';
                    // }
                    if (layers[layer][auth_item].auth_title === 'password') {
                        state.inputPass = layers[layer][auth_item].password;
                    }
                    if (layers[layer][auth_item].auth_title === 'call') {
                        state.inputCall = layers[layer][auth_item].expiration_call_period;
                    }
                    l.push(auth_title);
                    // titles.push(auth_title);
                    titles[auth_title] = auth_title;
                }
                if (l.length > 0) {
                    title_layers.push(l);
                }
            }
            // console.log('store', dataAuthParams.layers);
            // console.log('store', dataAuthParams.layers);
            if (state.statusSocial === true) {
                var total_possible = [
                    ['sms', 'call'],
                    ['button', 'vkontakte', 'facebook', 'odnoklassniki', 'password'],
                ];
            } else if (state.statusSocial === false) {
                var total_possible = [
                    ['sms', 'call'],
                    ['button', 'password'],
                ];
            }

            var possible_title_layers = [];

            for (var layer2 in total_possible) {
                var l2 = [];
                for (var auth_title2 in total_possible[layer2]) {
                    if (titles[total_possible[layer2][auth_title2]] !== undefined) {
                        l2.push(total_possible[layer2][auth_title2]);
                    }
                }
                if (l2.length > 0) {
                    possible_title_layers.push(l2);
                }
            }
            // console.log('store', title_layers, possible_title_layers);
            if (JSON.stringify(title_layers) === JSON.stringify(possible_title_layers)) {
                // console.log('store', title_layers, possible_title_layers);
                this.processingDataAuth(dataParam, dataAuthParams, title_layers, titles);
            } else if (JSON.stringify(title_layers) !== JSON.stringify(possible_title_layers)) {
                state.statusSettingsAuth = false;
            }
        } else if (dataParam.authParams === undefined || dataParam.authParams === null) {
            state.authParams = {
                layersCount: 0,
                layers: [],
                sessionPeriod: 30,
                authPeriod: 30,
                nextConnectionPeriod: 0,
                traficVolume: 500,
                redirectUrl: 'http://google.com',
            };
            state.statusSms = false;
            state.statusButton = false;
            state.statusVK = false;
            state.statusFb = false;
            state.statusOk = false;
            state.statusPass = false;
            state.statusSettingsAuth = true;
        }
    },
    processingDataAuth: function (dataParam, dataAuthParams, title_layers, titles) {
        if (
            dataParam.authBackgroundColor === undefined ||
            dataParam.authButtonBackgroundColor === undefined ||
            dataParam.authButtonText === undefined
        ) {
            state.authBackgroundColor = 'rgba(51, 71, 80, 0.9)';
            state.authButtonBackgroundColor = 'rgba(51,71,80,0.9)';
            state.authButtonText = 'ВОЙТИ В ИНТЕРНЕТ';
        }
        if (dataParam.authBackgroundColor !== undefined) {
            state.authBackgroundColor = dataParam.authBackgroundColor;
        }
        if (dataParam.authButtonBackgroundColor !== undefined) {
            state.authButtonBackgroundColor = dataParam.authButtonBackgroundColor;
        }
        if (dataParam.authButtonText !== undefined) {
            state.authButtonText = dataParam.authButtonText;
        }
        if (dataParam.authDisplayMode === undefined) {
            state.authDisplayMode = false;
        } else if (dataParam.authDisplayMode !== undefined) {
            state.authDisplayMode = dataParam.authDisplayMode;
        }
        state.title_layers = title_layers;
        // console.log('store', dataAuthParams, dataAuthParams.layers[dataAuthParams.layers.length - 1]);
        if (dataAuthParams.layers[dataAuthParams.layers.length - 1] !== undefined) {
            if (dataAuthParams.layers[dataAuthParams.layers.length - 1][0] !== undefined) {
                if (dataAuthParams.layers[dataAuthParams.layers.length - 1][0].auth_title === 'vkontakte') {
                    if (dataAuthParams.layers[dataAuthParams.layers.length - 1][0].autopost === true) {
                        state.VKAutoPost.app = dataAuthParams.layers[dataAuthParams.layers.length - 1][0].autopost_details;
                        state.VKAutoPost.message = dataAuthParams.layers[dataAuthParams.layers.length - 1][0].autopost_message;
                        state.VKAutoPost.valueRepost.id = dataAuthParams.layers[dataAuthParams.layers.length - 1][0].autopost_details;
                        if (dataAuthParams.layers[dataAuthParams.layers.length - 1][0].repost === true) {
                            state.VKAutoPost.statusOfNameUser = false;
                            state.VKAutoPost.statusRepost = true;
                        }
                        state.VKAutoPost.status = true;
                    }
                }
            }
        }
        if (dataAuthParams.layers[dataAuthParams.layers.length - 1] !== undefined) {
            for (var j = 0; j < dataAuthParams.layers[dataAuthParams.layers.length - 1].length; j++) {
                // console.log('store>>>>>>>>>>>');
                if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].auth_title === 'facebook') {
                    if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost === true) {
                        state.FBAutoPost = {
                            status: true,
                            link: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_url,
                            title: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_title,
                            discriptions: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_description,
                            image: '',
                        };
                        if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_img !== undefined) {
                            state.FBAutoPost = {
                                status: true,
                                link: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_url,
                                title: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_title,
                                discriptions: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_description,
                                image: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_img,
                            };
                        }
                    }
                }
            }
        }
        // for (var j = 0; j < dataAuthParams.layers[dataAuthParams.layers.length - 1].length; j++) {
        //     // console.log('store>>>>>>>>>>>');
        //     if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].auth_title === 'facebook') {
        //         if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost === true) {
        //             state.FBAutoPost = {
        //                 status: true,
        //                 link: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_url,
        //                 title: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_title,
        //                 discriptions: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_description,
        //                 image: ''
        //             };
        //             if (dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_img !== undefined) {
        //                 state.FBAutoPost = {
        //                     status: true,
        //                     link: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_url,
        //                     title: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_title,
        //                     discriptions: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_description,
        //                     image: dataAuthParams.layers[dataAuthParams.layers.length - 1][j].autopost_img
        //                 };
        //             }
        //         }
        //     }
        // }
        // console.log('store', titles);
        state.statusSettingsAuth = true;
        if (titles.sms !== undefined) {
            state.statusSms = true;
        }
        if (titles.button !== undefined) {
            state.statusButton = true;
        }
        if (titles.vkontakte !== undefined) {
            state.statusVK = true;
        }
        if (titles.facebook !== undefined) {
            state.statusFb = true;
        }
        if (titles.odnoklassniki !== undefined) {
            state.statusOk = true;
        }
        if (titles.password !== undefined) {
            state.statusPass = true;
        }
        if (titles.call !== undefined) {
            state.statusCall = true;
        }
        state.authParams.sessionPeriod = dataAuthParams.sessionPeriod;
        state.authParams.authPeriod = dataAuthParams.sessionPeriod;
        if (dataAuthParams.authPeriod !== undefined) {
            state.authParams.authPeriod = dataAuthParams.authPeriod;
        }
        state.authParams.nextConnectionPeriod = dataAuthParams.nextConnectionPeriod;
        state.authParams.traficVolume = dataAuthParams.traficVolume;
        state.authParams.redirectUrl = dataAuthParams.redirectUrl;
    },
    update: function (data) {
        // state.dataPage = {};
        // state.statusSettingsAuth = true;
        state.VKAutoPost = {
            status: false,
            statusOfNameUser: true,
            statusRepost: false,
            valueOfNameUser: {
                message: '',
                app: '',
            },
            valueRepost: {
                id: '',
            },
        };
        state.FBAutoPost = {
            status: false,
            link: '',
            title: '',
            discriptions: '',
            image: '',
        };
        state.authBackgroundColor = 'rgba(51, 71, 80, 0.9)';
        state.authButtonBackgroundColor = 'rgba(51,71,80,0.9)';
        state.authButtonText = 'ВОЙТИ В ИНТЕРНЕТ';
        state.authDisplayMode = false;
        state.urlImageBackground = '';
        state.backgroundColorWelcomGlobalPage = 'rgba(255,255,255,.5)';
        state.statusSelectBackgroundColorWelcomGlobalPage = false;
        state.urlImageLogo = '';
        state.valueText = '';
        state.colorValueText = 'rgba(0,0,0,1)';
        state.indexText = 0;
        state.indexGraphicBlock = 0;
        state.statusLoadLogo = false;
        state.urlImageGraphic = '';
        state.statusPerformens = false;
        state.statusTextStyle = null;
        state.inputCall = 15552000;
        state.statusCall = false;
        state.inputPass = '';

        state.statusSms = false;
        state.statusButton = false;
        state.statusVK = false;
        state.statusFb = false;
        state.statusOk = false;
        state.statusPass = false;
        // console.log('store!!!!!!!', data.RadiusApp.itemSettingsTemplate);
        if (data.RadiusApp.itemSettingsTemplate.data !== undefined) {
            // console.log('store>>>>>>>>>>>>', data.itemSettingsTemplate);
            var jsonString = JSON.stringify(data.RadiusApp.itemSettingsTemplate);
            state.dataPage = JSON.parse(jsonString);

            // console.log('store>>>>>>>>>>>>', state.dataPage);
            // var detailData = JSON.parse(state.dataPage.data);
            // console.log('store>>>>>>>>>>>>', detailData.authParams.layers[0][0]);
            // detailData.authParams.layers[0][0]['value'] = 123;
            // state.dataPage.data = JSON.stringify(detailData);
            state.statusSocial = false;
            // console.log('store!!!!!!!!', detailData.authParams.layers[0][0]);
            for (var i = 0; i < data.LoginApp.products.length; i++) {
                if (data.LoginApp.products[i] === 'radius_social') {
                    state.statusSocial = true;
                }
            }
            state.headers = {};
            state.headers['x-token'] = data.LoginApp.token;
            state.headers['username'] = data.LoginApp.userName;
            var dataParam = JSON.parse(data.RadiusApp.itemSettingsTemplate.data);
            // console.log('store>>>>>>>>>>>>', state.dataPage);
            state.blocksData = dataParam.blocks;

            // var layersSum = 1;
            // var detail2 = dataParam.authParams
            // console.log('sdsd>>>>>>>', detail2);
            // if (detail2 !== undefined && detail2 !== null){
            //     if (detail2.layers !== undefined) {
            //         for (var i = 0; i < detail2.layers.length; i++) {
            //             layersSum = layersSum * detail2.layers[i].length
            //         }
            //     }
            // }
            // if (layersSum === 0) {
            //     dataParam.authParams = null;
            //     // state.dataPage
            // }
            // console.log('store>>>>>>>>>>>>', dataParam);
            // console.log('store>>>>>>>>>>>>', dataParam);
            if (dataParam.authParams === null) {
                var datasotr = {
                    authParams: {},
                    blocks: data.RadiusApp.itemSettingsTemplate.data.blocks,
                    scrollBlockIndex: null,
                };
                state.dataPage.data = JSON.stringify(datasotr);
            }
            if (dataParam.media !== undefined) {
                // console.log('store>>>>>>>>>>>>', dataParam.media);
                if (dataParam.media.length > 0) {
                    state.urlImageBackground = dataParam.media[0].url;
                }
            }
            if (dataParam.backgroundColor !== undefined) {
                state.backgroundColorWelcomGlobalPage = dataParam.backgroundColor;
            }
            // console.log('store>>>>>>>>>>>>', dataParam.blocks);

            if (Object.keys(dataParam).length === 0) {
                state.statusPerformens = true;
            }
            if (dataParam.blocks !== undefined) {
                this.workInBlocks(dataParam);
            }
            // console.log('store>>>>>>>>>>>>', dataParam);
            this.worksInAuthParams(dataParam);
            state.paramSatingsItem = data.RadiusApp.itemSettingsTemplate;
            this.emitChangeToModuleListeners();
        }
        // console.log('store>>>>>>>>>>>>', dataParam);
        if (data.RadiusApp.statusShowSettingsPanel === false) {
            state.statusSms = false;
            state.statusButton = false;
            state.statusVK = false;
            state.statusFb = false;
            state.statusOk = false;
            state.statusPass = false;
            state.statusCall = false;
            state.inputCall = 15552000;
            state.statusSettings = 'performance';
            state.authParams.sessionPeriod = 30;
            state.authParams.authPeriod = 30;
            state.authParams.nextConnectionPeriod = 0;
            state.authParams.traficVolume = 500;
            state.authParams.redirectUrl = 'http://google.com';
            state.inputPass = '';
        }
        // var dataParam = JSON.parse(data.itemSettingsTemplate);
        // console.log('store', dataParam);
    },
    onChangeSMS: function (e) {
        // console.log('sms', e);
        state.statusSms = e;
        if (e === true) {
            state.statusCall = false;
        }
        this.emitChangeToModuleListeners();
    },
    getStatusSave: function (data) {
        state.statusSave = data;
        setTimeout(() => {
            state.statusSave = null;
            this.emitChangeToModuleListeners();
        }, 10);
        this.emitChangeToModuleListeners();
    },
    compare: function (template, original) {
        // console.log('store!!!!!!!!!!!', JSON.stringify(template), JSON.stringify(original));
        return (
            stringify(template, function (a, b) {
                return a.key < b.key ? 1 : -1;
            }) ===
            stringify(original, function (a, b) {
                return a.key < b.key ? 1 : -1;
            })
        );
    },
    onChangeButton: function (e) {
        state.statusButton = e;
        // console.log('sms', e);
        this.emitChangeToModuleListeners();
    },
    onChangeVK: function (e) {
        state.statusVK = e;
        if (e === false) {
            state.VKAutoPost.status = false;
        }
        // console.log('sms', e);
        this.emitChangeToModuleListeners();
    },
    onChangeFb: function (e) {
        state.statusFb = e;
        if (e === false) {
            state.FBAutoPost.status = false;
        }
        // console.log('sms', e);
        this.emitChangeToModuleListeners();
    },
    onChangeOk: function (e) {
        state.statusOk = e;
        // console.log('sms', e);
        this.emitChangeToModuleListeners();
    },
    onChangePass: function (e) {
        state.statusPass = e;
        // console.log('sms', e);
        this.emitChangeToModuleListeners();
    },
    onChangeInputPass: function (e) {
        state.inputPass = e;
        this.emitChangeToModuleListeners();
    },
    onChangeSessionPeriod: function (e) {
        console.log(e);
        state.authParams.sessionPeriod = e;
        this.emitChangeToModuleListeners();
    },
    onChangeAuthPeriod: function (e) {
        state.authParams.authPeriod = e;
        this.emitChangeToModuleListeners();
    },
    onChangeTraficVolume: function (e) {
        state.authParams.traficVolume = e;
        this.emitChangeToModuleListeners();
    },
    onChangeRedirectUrl: function (e) {
        state.authParams.redirectUrl = e;
        // console.log('store!!!');
        this.emitChangeToModuleListeners();
    },
    onReset: function () {
        var JSONparse = JSON.parse(state.dataPage.data);
        delete JSONparse.authParams;
        state.dataPage.data = JSON.stringify(JSONparse);
        state.dataPage = {};
        state.statusSettingsAuth = true;
        state.VKAutoPost = {
            status: false,
            statusOfNameUser: true,
            statusRepost: false,
            valueOfNameUser: {
                message: '',
                app: '',
            },
            valueRepost: {
                id: '',
            },
        };
        state.FBAutoPost = {
            status: false,
            link: '',
            title: '',
            discriptions: '',
            image: '',
        };
        state.authBackgroundColor = 'rgba(51, 71, 80, 0.9)';
        state.authButtonBackgroundColor = 'rgba(51,71,80,0.9)';
        state.authButtonText = 'ВОЙТИ В ИНТЕРНЕТ';
        state.authDisplayMode = false;
        state.urlImageBackground = '';
        state.backgroundColorWelcomGlobalPage = 'rgba(255,255,255,.5)';
        state.statusSelectBackgroundColorWelcomGlobalPage = false;
        state.urlImageLogo = '';
        state.valueText = '';
        state.colorValueText = 'rgba(0,0,0,1)';
        state.indexText = 0;
        state.indexGraphicBlock = 0;
        state.statusLoadLogo = false;
        state.urlImageGraphic = '';
        state.statusPerformens = false;
        state.statusTextStyle = null;
        state.inputCall = 15552000;
        state.statusCall = false;
        // console.log('storeeeee');
        this.emitChangeToModuleListeners();
    },
    getLoad: function (status) {
        state.statusLoad = status;
        this.emitChangeToModuleListeners();
    },
    onChangeAutoPostVk: function (status) {
        state.VKAutoPost.status = status;
        this.emitChangeToModuleListeners();
    },
    onChangeRadioVKName: function () {
        state.VKAutoPost.statusOfNameUser = true;
        state.VKAutoPost.statusRepost = false;
        this.emitChangeToModuleListeners();
    },
    onChangeRadioVKRepost: function () {
        state.VKAutoPost.statusOfNameUser = false;
        state.VKAutoPost.statusRepost = true;
        this.emitChangeToModuleListeners();
    },
    onChangeVkInputReport: function (value) {
        // console.log('!!!!',value);
        state.VKAutoPost.valueRepost.id = value;
        this.emitChangeToModuleListeners();
    },
    onChangeVkInputNameApp: function (value) {
        // console.log('<<<<<<',value);
        state.VKAutoPost.app = value;
        this.emitChangeToModuleListeners();
    },
    onChangeVkInputNameMessage: function (value) {
        // console.log('>>>>>',value);
        state.VKAutoPost.message = value;
        this.emitChangeToModuleListeners();
    },
    onChangeAutoPostFb: function (status) {
        state.FBAutoPost.status = status;
        console.log('store', status);
        this.emitChangeToModuleListeners();
    },
    onChangeFBLInk: function (e) {
        state.FBAutoPost.link = e;
        this.emitChangeToModuleListeners();
    },
    onChangeFBTitle: function (e) {
        state.FBAutoPost.title = e;
        this.emitChangeToModuleListeners();
    },
    onChangeFBDiscrip: function (e) {
        state.FBAutoPost.discriptions = e;
        this.emitChangeToModuleListeners();
    },
    getFiles: function (data) {
        state.dataFiles = data;
        state.statusLoadFiles = false;
        this.emitChangeToModuleListeners();
    },
    openModalImage: function (status) {
        state.statusModeModalImageBox = status;
        state.statusShowModalBoxImage = true;
        this.emitChangeToModuleListeners();
    },
    hideModalBoxImage: function () {
        state.statusShowModalBoxImage = false;
        state.statusModeModalImageBox = '';
        this.emitChangeToModuleListeners();
    },
    selectImage: function (e) {
        state.FBAutoPost.image = e;
        this.emitChangeToModuleListeners();
    },
    deleteImage: function () {
        state.FBAutoPost.image = '';
        this.emitChangeToModuleListeners();
    },
    showPanelUpload: function () {
        state.statusShowUploadPanel = true;
        this.emitChangeToModuleListeners();
    },
    hidePanelUpload: function () {
        state.statusShowUploadPanel = false;
        state.dataFilesUpload = [];
        state.valueTitleImageUpload = '';
        this.emitChangeToModuleListeners();
    },
    onChangeUploadInput: function (e) {
        state.dataFilesUpload = e;
        console.log('store', e);
        state.valueTitleImageUpload = e[0].name;
        if (state.valueTitleImageUpload === '') {
            state.valueTitleImageUpload = e[0].name;
        }
        this.emitChangeToModuleListeners();
    },
    onChangeTitleUploadImage: function (e) {
        state.valueTitleImageUpload = e;
        this.emitChangeToModuleListeners();
    },
    uploadFiles: function () {
        state.statusUploadFiles = true;
        this.emitChangeToModuleListeners();
    },
    loadFiles: function () {
        state.statusLoadFiles = true;
        this.emitChangeToModuleListeners();
    },
    getStopUpload: function () {
        state.statusUploadFiles = false;
        state.statusShowUploadPanel = false;
        this.emitChangeToModuleListeners();
    },
    errorUploadFiles: function () {
        state.statusUploadFiles = false;
        alert('Не удается загрузить файл');
        this.emitChangeToModuleListeners();
    },
    onChangeColorAuthBackground: function (color) {
        state.authBackgroundColor = color;
        this.emitChangeToModuleListeners();
    },
    handleChangeColorBackgroundButton: function (color) {
        state.authButtonBackgroundColor = color;
        this.emitChangeToModuleListeners();
    },
    onChangeTextButtonAuth: function (e) {
        state.authButtonText = e;
        this.emitChangeToModuleListeners();
    },
    onCheckMode: function (e) {
        state.authDisplayMode = e;
        console.log('onCheck', e);
        this.emitChangeToModuleListeners();
    },
    selectImagePerformBackground: function (e) {
        state.urlImageBackground = e;
        this.emitChangeToModuleListeners();
    },
    selectImagePerformLogo: function (e) {
        state.urlImageLogo = e;
        this.emitChangeToModuleListeners();
    },
    handelChangeBackgroundWelcome: function (e) {
        state.backgroundColorWelcomGlobalPage = e;
        state.statusSelectBackgroundColorWelcomGlobalPage = true;
        this.emitChangeToModuleListeners();
    },
    deleteImageBackgroundWelcom: function () {
        state.urlImageBackground = '';
        this.emitChangeToModuleListeners();
    },
    deleteColorBackgroundWelcom: function () {
        state.backgroundColorWelcomGlobalPage = 'rgba(255,255,255,.5)';
        state.statusSelectBackgroundColorWelcomGlobalPage = false;
        this.emitChangeToModuleListeners();
    },
    deleteLogo: function () {
        state.urlImageLogo = '';
        this.emitChangeToModuleListeners();
    },
    onChangeText: function (e) {
        state.valueText = e;
        this.emitChangeToModuleListeners();
    },
    onClickColorText: function (e) {
        state.colorValueText = e;
        this.emitChangeToModuleListeners();
    },
    selectImagePerformGraphic: function (e) {
        state.urlImageGraphic = e;
        this.emitChangeToModuleListeners();
    },
    deleteGraphic: function () {
        state.urlImageGraphic = '';
        this.emitChangeToModuleListeners();
    },
    getPreviewsPageUrl: function (url) {
        state.previewsPageUrl = url;
        this.emitChangeToModuleListeners();
    },
    getStatusLoadTemPage: function (status) {
        state.statusLoadTempPage = status;
        this.emitChangeToModuleListeners();
    },
    onCheckCall: function (e) {
        // console.log('aut', e);
        state.statusCall = e;
        if (e === true) {
            state.statusSms = false;
        }
        this.emitChangeToModuleListeners();
    },
    onChangeCall: function (e) {
        state.inputCall = e;
        this.emitChangeToModuleListeners();
    },
    getState: function () {
        return state;
    },
});

export default AppStore;
