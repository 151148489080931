import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

const DiagramDescriptionModal = React.memo(({ info, ...props }) => {
    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Container onClick={onContainerClick}>
            <Main>{info}</Main>
        </Container>
    );
});

export default DiagramDescriptionModal;

const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    overflow: auto;
    box-sizing: border-box;
`;

const Container = styled.div`
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
`;
