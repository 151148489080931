import { withFauxDOM } from 'react-faux-dom';

import _t from 'i18next';
import { getInfoWithDataAspects } from '../../tools/dataAspectsTools';
import React from 'react';
import * as d3 from 'd3';
import '../../style/FunnelStyle.scss';

class FunnelD3Chart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finalChangesArray: []
        };
        this.expander = 100;
        this.thinThreshold = 0.5; //min value to draw thin funnels
        this.dataTypeKeyName = this.props.dataView.data_report.data_type.key_name;
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log(nextProps);
    // }

    componentDidMount() {
        this.initializeFunnels();
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        const funnelData = data.funnels.filter(item => item.data_aspects.time.date_range_type === 'main_date_range')[0].funnel_layers;
        if (this.dataTypeKeyName === 'funnel_pair') {
            this.drawChart(funnelData, 0);
        }
    }

    initializeFunnels = () => {
        const { data, MAX_FUNNEL_SET } = this.props;
        const funnelData = data.funnels.filter(item => item.data_aspects.time.date_range_type === 'main_date_range')[0].funnel_layers;

        if (this.dataTypeKeyName === 'funnel_pair') {
            const [upToDateFunnel] = data.funnels.filter(item => item.data_aspects.time.date_range_type === 'main_date_range');
            const [comparisonFunnel] = data.funnels.filter(item => item.data_aspects.time.date_range_type === 'comparison_date_range');

            const makeChangesArray = arr => {
                const funnelData = arr.map(item => item.input_value);
                const maxY = Math.max(...funnelData);

                return arr.map(item => {
                    const { LayerTopData_percent_point } = this.getPercents(item.input_value, item.output_value, maxY);
                    return LayerTopData_percent_point;
                });
            };

            const compareChangesArray = makeChangesArray(comparisonFunnel.funnel_layers);
            const nowChangesArray = makeChangesArray(upToDateFunnel.funnel_layers);
            const finalChangesArray = compareChangesArray.map((item, i) =>
                Number((Math.abs(item) - Math.abs(nowChangesArray[i])).toFixed(1))
            );

            this.setState({ finalChangesArray });

            // LayerBottomData_pair_compare = finalChangesArray[i].toFixed(1);
        }

        if (this.dataTypeKeyName !== 'funnel_set') {
            this.drawChart(funnelData, 0);
        } else {
            const cuttedFunnels = data.funnels.filter((item, i) => i < MAX_FUNNEL_SET);
            const maxValue = Math.max(
                ...cuttedFunnels.reduce((acc, item) => {
                    return [...acc, ...item.funnel_layers.map(item => item.input_value)];
                }, [])
            );

            const maxSectionsNumber = Math.max(
                ...cuttedFunnels.reduce((acc, item) => {
                    return [...acc, item.funnel_layers.length];
                }, [])
            );
            cuttedFunnels.forEach((item, i) => {
                this.drawChart(item.funnel_layers, i, maxValue, maxSectionsNumber);
            });
        }
    };

    protectSmallHeight = expander => {
        const { size, dataView } = this.props;
        return dataView.size.height === 1 ? size.height - 50 + expander : size.height - 50;
    };

    scale = (domain, range) => {
        return d3
            .scaleLinear()
            .domain(domain)
            .range(range);
    };

    // dataTypeKeyName = this.props.data.data_report.data_type.key_name;

    drawChart = (funnelData, chartIndex, maxValue, maxSectionsNumber) => {
        const { data, height, width, size, sizeHeight } = this.props;
        const faux = this.props.connectFauxDOM('g', `chart-${chartIndex}`);
        const maxY = maxValue ? maxValue : Math.max(...funnelData.map(item => item.input_value));

        const maxSections = maxSectionsNumber ? maxSectionsNumber : funnelData.length;

        const colors = {
            mainGradient: {
                startColor: 'rgb(116, 177, 214)',
                finishColor: 'rgb(96, 75, 214)'
            },
            backSectorColor: 'rgb(232, 237, 249)',
            separatorLinesColor: 'rgb(216, 216, 216)',
            increaseColor: 'rgb(205, 238, 197)',
            decreaseColor: 'rgb(245, 210, 213)',
            backStrokeColor: 'rgb(107, 112, 116)'
        };

        const funnelHeaderSpace = this.dataTypeKeyName === 'funnel_set' ? 70 : 75;
        const funnelFooterSpace = this.dataTypeKeyName === 'funnel_set' ? 40 : 45;
        const offset = this.dataTypeKeyName === 'funnel_set' ? 13 : 20;
        const graph = d3.select(faux);

        const h = this.protectSmallHeight(this.expander);
        const w = size.width;
        const funnelHeight = h - funnelHeaderSpace - funnelFooterSpace;
        // const iterations = 10;

        const intervals = funnelData.map((item, i, arr) => {
            // function accepts funnel data
            // it returns an array of objects
            // each object corresponds to one funnel section and contains the coordinates of points to draw
            const width = (w * funnelData.length) / maxSections;
            const middleY = (h - funnelFooterSpace - funnelHeaderSpace) / 2 + funnelHeaderSpace;
            const x0 = this.scale([0, funnelData.length], [0, width])(i);
            let y0 = this.scale([-maxY, maxY], [h - funnelFooterSpace, funnelHeaderSpace])(item.input_value);
            const x1 = arr[i + 1] ? this.scale([0, funnelData.length], [0, width])(i + 1) : width;
            let y1 = this.scale([-maxY, maxY], [h - funnelFooterSpace, funnelHeaderSpace])(item.output_value);
            const x2 = arr[i + 1] ? this.scale([0, funnelData.length], [0, width])(i + 1) : width;
            let y2 = this.scale([-maxY, maxY], [h - funnelFooterSpace, funnelHeaderSpace])(-item.output_value);
            const x3 = this.scale([0, funnelData.length], [0, width])(i);
            let y3 = this.scale([-maxY, maxY], [h - funnelFooterSpace, funnelHeaderSpace])(-item.input_value);

            if (Math.abs(y0 - middleY) < this.thinThreshold) {
                y0 = middleY - this.thinThreshold;
            }
            if (Math.abs(y1 - middleY) < this.thinThreshold) {
                y1 = middleY - this.thinThreshold;
            }
            if (Math.abs(y2 - middleY) < this.thinThreshold) {
                y2 = middleY + this.thinThreshold;
            }
            if (Math.abs(y3 - middleY) < this.thinThreshold) {
                y3 = middleY + this.thinThreshold;
            }

            return [
                {
                    x0,
                    y0,
                    x1,
                    y1,
                    x2,
                    y2,
                    x3,
                    y3
                }
            ];
        });

        const bezierPath = (sector, offset, isStroke) => {
            // function creates a path by points

            const path = d3.path();
            let { x0, y0, x1, y1, x2, y2, x3, y3 } = sector;
            y0 = y0 - offset;
            y1 = y1 - offset;
            y2 = y2 + offset;
            y3 = y3 + offset;
            const bezier = (x1 - x0) / 2;
            path.moveTo(x0, y0);
            path.bezierCurveTo(x0 + bezier, y0, x1 - bezier, y1, x1, y1);
            !isStroke ? path.lineTo(x2, y2) : path.moveTo(x2, y2);
            path.bezierCurveTo(x2 - bezier, y2, x3 + bezier, y3, x3, y3);
            !isStroke && path.closePath();
            return path;
        };

        const breakGradient = (startColor, finishColor, length) => {
            // function breaks the gradient into sections
            // takes the starting and ending colors and the number of slices
            // returns an array of objects
            // each object contains the initial and final color of the sector gradient
            const colorToArr = str => str.match(/\d{1,3}/g);
            const startColorArray = colorToArr(startColor);
            const finishColorArray = colorToArr(finishColor);
            const D = finishColorArray.map((item, i) => Number(item) - Number(startColorArray[i]));
            let d = D.reduce((acc, item) => {
                return acc + item * item;
            }, 0);
            d = Math.sqrt(d);
            const CE = D.map(item => item / d);
            const step = d / length;
            const result = [];
            for (let i = 0; i < length; i++) {
                const startLayerColor = `rgb(${startColorArray
                    .map((item, index) => Math.floor(Number(item) + CE[index] * step * i))
                    .join(',')})`;
                const finishLayerColor =
                    i + 1 < length
                        ? `rgb(${startColorArray.map((item, index) => Math.floor(Number(item) + CE[index] * step * (i + 1))).join(',')})`
                        : finishColor;
                result.push({ startLayerColor, finishLayerColor });
            }
            return result;
        };

        intervals.forEach((item, i) => {
            const mainGradients = breakGradient(colors.mainGradient.startColor, colors.mainGradient.finishColor, intervals.length);
            // create backGradient
            let backGradientColor;
            if (this.dataTypeKeyName === 'funnel_pair') {
                colors.backSectorColor = `url(#gradient-${i})`;

                const diff = this.state.finalChangesArray[i];
                if (diff === undefined) return;
                // data.data.funnels[0].funnel_layers[i].output_value - data.data.funnels[1].funnel_layers[i].output_value;
                // console.log(diff);
                if (diff >= 0) {
                    backGradientColor = colors.increaseColor;
                } else {
                    backGradientColor = colors.decreaseColor;
                }

                const defs = graph.append('defs');
                const gradient = defs
                    .append('linearGradient')
                    .attr('id', `gradient-${i}`)
                    .attr('gradientUnits', 'objectBoundingBox')
                    .attr('x1', '0%')
                    .attr('x2', '100%')
                    .attr('y1', h / 2)
                    .attr('y2', h / 2)
                    .selectAll('stop')
                    .data([
                        { offset: '0%', color: 'white' },
                        { offset: '50%', color: backGradientColor },
                        { offset: '100%', color: 'white' }
                    ])
                    .enter()
                    .append('stop')
                    .attr('offset', d => d.offset)
                    .attr('stop-color', d => d.color);
            }
            // draw back shape with fill
            graph
                .append('path')
                .attr('d', bezierPath(item[0], offset, false).toString())
                .attr('fill', colors.backSectorColor);
            // draw back strokes
            graph
                .append('path')
                .attr('d', bezierPath(item[0], offset, true).toString())
                .attr('fill', 'none')
                .attr('stroke', colors.backStrokeColor)
                .attr('stroke-width', 0.25);

            //draw main funnel
            //create main gradient
            const defsMain = graph.append('defs');
            const gradientMain = defsMain
                .append('linearGradient')
                .attr('id', `gradientMain-${i}`)
                .attr('gradientUnits', 'objectBoundingBox')
                .attr('x1', '0%')
                .attr('x2', '100%')
                .attr('y1', h / 2)
                .attr('y2', h / 2)
                .selectAll('stop')
                .data([
                    { offset: '0%', color: mainGradients[i].startLayerColor },
                    { offset: '100%', color: mainGradients[i].finishLayerColor }
                ])
                .enter()
                .append('stop')
                .attr('offset', d => d.offset)
                .attr('stop-color', d => d.color);

            // draw main funnel
            graph
                .append('path')
                .attr('d', bezierPath(item[0], 0, false).toString())
                .attr('fill', `url(#gradientMain-${i})`);
        });
        //draw vertical separation lines
        for (let i = 1; i < maxSections; i++) {
            graph
                .append('rect')
                .attr('x', () => this.scale([0, maxSections], [0, w])(i))
                .attr('width', 2)
                .attr('height', h)
                .attr('fill', colors.separatorLinesColor);
        }
    };

    getPercents = (input, output, maxY) => {
        let LayerTopDataInputPercentNumber = Number(this.scale([0, maxY], [0, 100])(input).toFixed(1));
        let LayerTopDataOutputPercentNumber = Number(this.scale([0, maxY], [0, 100])(output).toFixed(1));

        let LayerTopDataInputPercent = `${LayerTopDataInputPercentNumber}%`;
        let LayerTopDataOutputPercent = `${LayerTopDataOutputPercentNumber}%`;

        if (input === 0 && output === 0) {
            LayerTopDataInputPercent = '--';
            LayerTopDataOutputPercent = '--';
        }

        const LayerTopData_percent_point = -(LayerTopDataInputPercentNumber - LayerTopDataOutputPercentNumber).toFixed(1);

        return {
            LayerTopData_percent_point,
            LayerTopDataInputPercent,
            LayerTopDataOutputPercent
        };
    };

    renderLayersText = (inputData, translations = undefined, maxSectionsNumber) => {
        const maxSections = maxSectionsNumber ? maxSectionsNumber : inputData.funnel_layers.length;
        const { lang } = this.props;
        const width = `${(100 * inputData.funnel_layers.length) / maxSections}%`;
        const funnelData = inputData.funnel_layers.map(item => item.input_value);
        const maxY = Math.max(...funnelData);

        return (
            <ul className={'FunnelTextDataList'} style={{ width, height: this.protectSmallHeight(this.expander) }}>
                {inputData.funnel_layers.map((item, i, arr) => {
                    const { LayerTopData_percent_point, LayerTopDataInputPercent, LayerTopDataOutputPercent } = this.getPercents(
                        item.input_value,
                        item.output_value,
                        maxY
                    );

                    const layerName = translations ? translations[item.name][lang] : item.name;

                    return (
                        <li key={i} className={'FunnelTextDataItem'}>
                            <div className={'LayerHeader'}>
                                <h4 className={'LayerTitle'}>{layerName}</h4>
                                <div className={'LayerTopData'}>
                                    {i === 0 ? (
                                        <p className={'LayerTopData_input_percent'}>{`${LayerTopDataInputPercent}`}</p>
                                    ) : (
                                        <p className={'LayerTopData_input_percent'}>{`  `}</p>
                                    )}

                                    {LayerTopData_percent_point ? (
                                        <p className={'LayerTopData_percent_point'}>{`${LayerTopData_percent_point} ${_t.t('п.п.')}`}</p>
                                    ) : (
                                        <p />
                                    )}

                                    <p className={'LayerTopData_output_percent'}>{`${LayerTopDataOutputPercent}`}</p>
                                </div>
                            </div>
                            <div className={'LayerFooter'}>
                                <div className={'LayerBottomData'}>
                                    <p className={'LayerBottomData_data'}>
                                        {i === 0 ? item.input_value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : ''}
                                    </p>
                                    {this.dataTypeKeyName === 'funnel_pair' ? (
                                        <div className={'LayerBottomData_pair_compare_container'}>
                                            <i
                                                style={{
                                                    color: this.state.finalChangesArray[i] >= 0 ? 'rgb(48,213,103)' : 'rgb(241,74,80)'
                                                }}
                                                className={
                                                    this.state.finalChangesArray[i] >= 0
                                                        ? 'fa fa-play fa-rotate-270'
                                                        : 'fa fa-play fa-rotate-90'
                                                }
                                                aria-hidden="true"
                                            />
                                            <p className={'LayerBottomData_pair_compare'}>{`${this.state.finalChangesArray[i]}%`}</p>
                                        </div>
                                    ) : (
                                        <p />
                                    )}

                                    <p className={'LayerBottomData_data'}>
                                        {item.output_value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}
                                    </p>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    renderFunnelSet = () => {
        const { data, dataView, iterationsNumber, iteration, MAX_FUNNEL_SET } = this.props;

        const funnels = data.funnels;
        const translations = dataView.data_report.meta.translations;

        const maxValue = Math.max(
            ...funnels.reduce((acc, item) => {
                return [...acc, ...item.funnel_layers.map(item => item.input_value)];
            }, [])
        );

        const maxSectionsNumber = Math.max(
            ...funnels.reduce((acc, item) => {
                return [...acc, item.funnel_layers.length];
            }, [])
        );

        // const objectNames = getInfoWithDataAspects(funnels).linesData;

        const objectNames = funnels.map((item) => {
            if (item.data_aspects) {
                return item.data_aspects.object.relation === 'detalization' ? item.data_aspects.object.associations[0].name : '';
            } else {
                return '';
            }
        });

        

        if (iterationsNumber === 1) {
            const result = funnels
                .filter((item, i) => i < MAX_FUNNEL_SET)
                .map((item, i, arr) => {
                    return (
                        <div key={i} className={'DataViewFunnelContainer'}>
                            <h4 className={'FunnelTextDataList_title'}>{objectNames[i]}</h4>
                            {this.renderLayersText(item, translations, maxSectionsNumber)}
                            <svg
                                className={'FunnelSVG FunnelSetSvg'}
                                style={{
                                    height: this.protectSmallHeight(this.expander)
                                }}
                            >
                                {this.props[`chart-${i}`]}
                            </svg>
                        </div>
                    );
                });

            if (funnels.length <= MAX_FUNNEL_SET) {
                return (
                    <React.Fragment>
                        {result}
                        <div className={'MoreObjects'}>{' '}</div>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {result}
                        <div className={'MoreObjects'}>{`+ ${funnels.length - MAX_FUNNEL_SET} ${_t.t('объекта(ов)')}`}</div>
                    </React.Fragment>
                );
            }
        } else {
            
            return (
                <React.Fragment>
                    <div key={iteration} className={'DataViewFunnelContainer'}>
                        <h4 className={'FunnelTextDataList_title'}>{objectNames[iteration]}</h4>
                        {this.renderLayersText(funnels[iteration], translations, maxSectionsNumber)}
                        <svg
                            className={'FunnelSVG FunnelSetSvg'}
                            style={{
                                height: this.protectSmallHeight(this.expander)
                            }}
                        >
                            {this.props[`chart-${iteration}`]}
                        </svg>
                    </div>
                    {iteration === MAX_FUNNEL_SET - 1 && (
                        <div className={'MoreObjects'}>{`+ ${funnels.length - MAX_FUNNEL_SET} ${_t.t('объекта(ов)')}`}</div>
                    )}
                </React.Fragment>
            );
        }
    };

    renderChoice = () => {
        const { size, data, dataView } = this.props;
        const translations = dataView.data_report.meta.translations;

        if (dataView.data_report.data_type.key_name !== 'funnel_set') {
            try {
                return (
                    <div className={'DataViewFunnelContainer'}>
                        {this.renderLayersText(
                            data.funnels.filter(item => item.data_aspects.time.date_range_type === 'main_date_range')[0],
                            translations
                        )}
                        <svg
                            id={dataView.data_view_id}
                            className={'FunnelSVG'}
                            style={{
                                height: size.height
                            }}
                        >
                            {this.props['chart-0']}
                        </svg>
                    </div>
                );
            } catch (error) {
                console.warn('Funnels ->>>> ', error);
                return null;
            }
        } else {
            return <div className={'SetContainer'}>{this.renderFunnelSet()}</div>;
        }
    };

    render() {
        return this.renderChoice();
    }
}

export default withFauxDOM(FunnelD3Chart);
