import Tools from '../tools/tools';

const getStatusPanelDropDownOne = (state, action) => {
    // console.log('getStatusPanelDropDownOne', action.status)
    return Object.assign({}, state, {
        statusDropDownMenuOne: action.status
    })
};

const getStatusPanelDropDownTwo = (state, action) => {
    return Object.assign({}, state, {
        statusDropDownMenuTwo: action.status
    })
};

const getStatusPanelDropDownThree = (state, action) => {
    return Object.assign({}, state, {
        statusDropDownMenuThree: action.status
    })
};

const hideDropdownMenus = (state, action) => {
    return Object.assign({}, state, {
        statusDropDownMenuOne: false,
        statusDropDownMenuTwo: false,
        statusDropDownMenuThree: false
    })
};

let stateInit = {
    statusDropDownMenuOne: false,
    statusDropDownMenuTwo: false,
    statusDropDownMenuThree: false

};

const PanelReportingsApp = (state=stateInit, action) => {
    switch (action.type) {
        case 'getStatusPanelDropDownOne':
            return getStatusPanelDropDownOne(state, action);
        case 'getStatusPanelDropDownTwo':
            return getStatusPanelDropDownTwo(state,action);
        case 'getStatusPanelDropDownThree':
            return getStatusPanelDropDownThree(state, action);
        case 'hideDropdownMenus':
            return hideDropdownMenus(state,action);
        default:
            return state;
    }
}
export default PanelReportingsApp;