import React, { useState } from 'react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import styled from 'styled-components/macro';

const ReportTitle = ({ title }) => {
    const [theme] = useState(getTheme());
    const reportTitleColor = theme.palette.themePrimary;
    return <Title color={reportTitleColor}>{title}</Title>;
};

export default ReportTitle;

const Title = styled.span`
    color: ${(p) => p.color};
    font-size: large;
`;
