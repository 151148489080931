import React, { Component } from 'react';
import Authorization from './authorization';
import Performance from './performance';
import AppActions from '../actions/actions';
import AppStore from '../stores/store';

var statusTab = 0;

class Panel extends Component {
    state = {
        statusSettings: AppStore.getState().statusSettings,
        paramsSettingsSelect: AppStore.getState().paramSatingsItem,
        statusSettingsAuth: AppStore.getState().statusSettingsAuth,
        statusPass: AppStore.getState().statusPass,
        sessionPeriod: AppStore.getState().authParams.sessionPeriod,
        authPeriod: AppStore.getState().authParams.authPeriod,
        traficVolume: AppStore.getState().authParams.traficVolume,
        redirectUrl: AppStore.getState().authParams.redirectUrl,
        inputPass: AppStore.getState().inputPass,
        statusLoad: AppStore.getState().statusLoad,
        statusLoadTempPage: AppStore.getState().statusLoadTempPage,
        urlPagePreviews: AppStore.getState().previewsPageUrl,
        statusCall: AppStore.getState().statusCall,
        inputCall: AppStore.getState().inputCall,
    };
    onHide(e) {
        // console.log('panel', e.target);
        if (
            e.target === document.getElementById('close') ||
            e.target === document.getElementById('background') ||
            e.target === document.getElementById('closeButton')
        ) {
            // console.log('panel', e.target);
            this.props.hideSettingsPanel();
            AppActions.onReset();
        }
    }
    onSelect(e) {
        if (e.target === document.getElementById('performance')) {
            AppActions.selectStatus('performance');
        } else if (e.target === document.getElementById('authorization') || e.target === document.getElementById('buttonAuthSetting')) {
            // console.log('sdsd>>>>>>>', e.target);
            AppActions.selectStatus('authorization');
        }
        // console.log('sdsd', e.target);
        // AppActions.selectStatus();
    }
    _onChange() {
        this.setState({
            statusSettings: AppStore.getState().statusSettings,
            paramsSettingsSelect: AppStore.getState().paramSatingsItem,
            statusSettingsAuth: AppStore.getState().statusSettingsAuth,
            statusPass: AppStore.getState().statusPass,
            sessionPeriod: AppStore.getState().authParams.sessionPeriod,
            authPeriod: AppStore.getState().authParams.authPeriod,
            traficVolume: AppStore.getState().authParams.traficVolume,
            redirectUrl: AppStore.getState().authParams.redirectUrl,
            inputPass: AppStore.getState().inputPass,
            statusLoad: AppStore.getState().statusLoad,
            statusLoadTempPage: AppStore.getState().statusLoadTempPage,
            urlPagePreviews: AppStore.getState().previewsPageUrl,
            statusCall: AppStore.getState().statusCall,
            inputCall: AppStore.getState().inputCall,
        });
    }
    componentDidMount() {
        AppStore.addChangeStoreModuleListener(this._onChange.bind(this));
    }
    componentWillUnmount() {
        AppStore.removeChangeStoreModuleListener(this._onChange.bind(this));
    }
    componentDidUpdate() {
        var elem = document.getElementById('performanceBox');
        var elem2 = document.getElementById('authorizationBox');
        if (this.state.statusSettings !== statusTab) {
            statusTab = this.state.statusSettings;
            if (elem !== null) {
                elem.scrollIntoView(true);
            }
            if (elem2 !== null) {
                elem2.scrollIntoView(true);
            }
        }
    }
    onClickShowPagePreviews() {
        var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;

        var width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width;
        var height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;
        var left = width / 2 - 440 / 2 + dualScreenLeft;
        var top = height / 2 - 700 / 2;
        window.open(
            this.state.urlPagePreviews,
            'Ratting',
            'width=440,height=700,center,status=0,scrollbars=1,resizable=true, top=' + top + ', left=' + left
        );
    }
    onSubmit() {
        var fontSize;
        var ratioLogo;
        var objLogo;
        var dataReq;
        var dataGlobal;
        var objText;
        var ratioGraphic;
        var styleGraphic;
        var objGraphic;
        var ratioText;
        var styleTextBox;
        var graphicBGSize;
        // console.log('sdsd>>>>>>>', AppStore.getState().dataPage);
        var data = {};
        if (AppStore.getState().dataPage.data !== undefined) {
            data = JSON.parse(AppStore.getState().dataPage.data);
        }
        // console.log('sdsd>>>>>>>', data.authParams);
        if (data.authParams === undefined) {
            data['authParams'] = {};
        }
        data.authParams['layers'] = [];
        var layersSum = 1;
        var detail2 = JSON.parse(AppStore.getState().dataPage.data).authParams;
        // console.log('sdsd>>>>>>>', detail2);
        if (detail2 !== undefined) {
            if (detail2.layers !== undefined) {
                for (let i = 0; i < detail2.layers.length; i++) {
                    layersSum = layersSum * detail2.layers[i].length;
                }
            }
        }
        // data.authParams.layers.length = AppStore.getState().title_layers.length;
        if (AppStore.getState().statusSms === true) {
            let obj = {};
            data.authParams.layers.push([]);
            // data.authParams.layers[0].push()
            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
            // console.log('!@!@!@', layersSum);
            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                for (let i = 0; i < detail.layers.length; i++) {
                    for (let j = 0; j < detail.layers[i].length; j++) {
                        if (detail.layers[i][j].auth_title !== 'sms') {
                            obj = {
                                auth_title: 'sms',
                                expiration_sms_period: 15552000,
                                title: 'SMS авторизация',
                            };
                        } else {
                            obj = detail.layers[i][j];
                            j = detail.layers[i].length - 1;
                            i = detail.layers.length - 1;
                        }
                    }
                }
            } else if (detail === undefined) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            } else if (detail.layers === undefined) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            } else if (layersSum === 0) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            }
            data.authParams.layers[0].push(obj);
            if (
                AppStore.getState().statusButton === true ||
                AppStore.getState().statusVK === true ||
                AppStore.getState().statusOk === true ||
                AppStore.getState().statusPass === true
            ) {
                data.authParams.layers.push([]);
            }
            if (AppStore.getState().statusButton === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'button') {
                                    obj = {
                                        auth_title: 'button',
                                        title: "Кнопка 'Войти в интернет'",
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                }

                // console.log('sdsd!!!!!',JSON.parse(AppStore.getState().dataPage.data).authParams.layers);
                data.authParams.layers[1].push(obj);
            }
            if (AppStore.getState().statusVK === true) {
                if (AppStore.getState().VKAutoPost.status === true) {
                    // console.log('!!>!>!>!>!>!>!>!>',AppStore.getState().VKAutoPost.statusOfNameUser, AppStore.getState().VKAutoPost.statusRepost);
                    if (AppStore.getState().VKAutoPost.statusOfNameUser === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.app,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: false,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.app;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = false;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    } else if (AppStore.getState().VKAutoPost.statusRepost === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: true,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.valueRepost.id;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = true;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    }
                } else if (AppStore.getState().VKAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                        obj = {
                                            auth_title: 'vkontakte',
                                            title: 'Вход через Vkontakte',
                                        };
                                    } else {
                                        // console.log('!!<!<!<!<!<!<!<',detail.layers[i][j]);
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        delete objTemp['repost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    }
                    data.authParams.layers[1].push(obj);
                }
            }
            if (AppStore.getState().statusFb === true) {
                // console.log('sdsd>>>>>>>', data.authParams);
                if (data.authParams.layers[1] === undefined) {
                    data.authParams.layers.push([]);
                }
                if (AppStore.getState().FBAutoPost.status === true) {
                    if (AppStore.getState().FBAutoPost.image === '') {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                autopost: true,
                                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                autopost_title: AppStore.getState().FBAutoPost.title,
                                                autopost_url: AppStore.getState().FBAutoPost.link,
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj['autopost'] = true;
                                            obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                            obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                            obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    } else if (AppStore.getState().FBAutoPost.image !== '') {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                autopost: true,
                                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                autopost_img: AppStore.getState().FBAutoPost.image,
                                                autopost_title: AppStore.getState().FBAutoPost.title,
                                                autopost_url: AppStore.getState().FBAutoPost.link,
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj['autopost'] = true;
                                            obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                            obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                            obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                            obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    }
                } else if (AppStore.getState().FBAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'facebook') {
                                        obj = {
                                            auth_title: 'facebook',
                                            title: 'Вход через Facebook',
                                        };
                                    } else {
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    }
                    data.authParams.layers[1].push(obj);
                }
            }
            if (AppStore.getState().statusOk === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'odnoklassniki') {
                                    obj = {
                                        auth_title: 'odnoklassniki',
                                        title: 'Вход через Odnoklassniki',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                }
                data.authParams.layers[1].push(obj);
            }
            if (AppStore.getState().statusPass === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'password') {
                                    obj = {
                                        auth_title: 'password',
                                        title: 'Вход с паролем',
                                        password: AppStore.getState().inputPass,
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['password'] = AppStore.getState().inputPass;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                }
                data.authParams.layers[1].push(obj);
            }
        } else if (AppStore.getState().statusSms === false) {
            let obj = {};
            if (AppStore.getState().statusCall === true) {
                if (data.authParams.layers.length === 0) {
                    data.authParams.layers.push([]);
                }
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'call') {
                                    obj = {
                                        auth_title: 'call',
                                        expiration_call_period: this.state.inputCall,
                                        title: 'Авторизация по звонку',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['expiration_call_period'] = this.state.inputCall;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    // console.log('submit1');
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                } else if (layersSum === 0) {
                    // console.log('submit1');
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                }
                data.authParams.layers[0].push(obj);
            }
            if (
                AppStore.getState().statusButton === true ||
                AppStore.getState().statusVK === true ||
                AppStore.getState().statusOk === true ||
                AppStore.getState().statusPass === true
            ) {
                data.authParams.layers.push([]);
            }
            if (AppStore.getState().statusButton === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'button') {
                                    obj = {
                                        auth_title: 'button',
                                        title: "Кнопка 'Войти в интернет'",
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
            if (AppStore.getState().statusVK === true) {
                if (AppStore.getState().VKAutoPost.status === true) {
                    if (AppStore.getState().VKAutoPost.statusOfNameUser === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.app,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: false,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.app;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = false;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    } else if (AppStore.getState().VKAutoPost.statusRepost === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        // console.log('!!>!>!>!>!>!>!>!>',AppStore.getState().VKAutoPost.valueRepost.id, AppStore.getState().VKAutoPost.app);
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: true,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.valueRepost.id;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = true;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        // console.log('!!>!>!>!>!>!>!>!>end',AppStore.getState().VKAutoPost.valueRepost.id, AppStore.getState().VKAutoPost.app);
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                } else if (AppStore.getState().VKAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                        obj = {
                                            auth_title: 'vkontakte',
                                            title: 'Вход через Vkontakte',
                                        };
                                    } else {
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        delete objTemp['repost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    }
                    data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                }
            }
            if (AppStore.getState().statusFb === true) {
                // console.log('???????????');
                if (data.authParams !== undefined) {
                    if (data.authParams.layers[data.authParams.layers.length - 1] === undefined) {
                        data.authParams.layers.push([]);
                    }
                    if (AppStore.getState().FBAutoPost.status === true) {
                        if (AppStore.getState().FBAutoPost.image === '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        } else if (AppStore.getState().FBAutoPost.image !== '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        }
                    } else if (AppStore.getState().FBAutoPost.status === false) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                            delete objTemp['autopost'];
                                            obj = objTemp;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                } else {
                    data['authParams']['layers'].push([]);
                    if (AppStore.getState().FBAutoPost.status === true) {
                        if (AppStore.getState().FBAutoPost.image === '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        } else if (AppStore.getState().FBAutoPost.image !== '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        }
                    } else if (AppStore.getState().FBAutoPost.status === false) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                }
            }
            if (AppStore.getState().statusOk === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'odnoklassniki') {
                                    obj = {
                                        auth_title: 'odnoklassniki',
                                        title: 'Вход через Odnoklassniki',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
            if (AppStore.getState().statusPass === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'password') {
                                    obj = {
                                        auth_title: 'password',
                                        title: 'Вход с паролем',
                                        password: AppStore.getState().inputPass,
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['password'] = AppStore.getState().inputPass;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
        }
        let count;
        if (data.authParams.layers === undefined) {
            count = 0;
        } else if (data.authParams.layers !== undefined) {
            count = data.authParams.layers.length;
        }
        if (
            AppStore.getState().statusSms === true ||
            AppStore.getState().statusButton === true ||
            AppStore.getState().statusVK === true ||
            AppStore.getState().statusOk === true ||
            AppStore.getState().statusPass === true ||
            AppStore.getState().statusFb === true
        ) {
            // data['authBackgroundColor'] = AppStore.getState().authBackgroundColor
            data['authButtonBackgroundColor'] = AppStore.getState().authButtonBackgroundColor;
            // data['authButtonText'] = AppStore.getState().authButtonText;
            // delete data.authDisplayMode;
            // if (AppStore.getState().authDisplayMode === true) {
            //     data['authDisplayMode'] = AppStore.getState().authDisplayMode
            // }
        } else if (
            AppStore.getState().statusSms === false &&
            AppStore.getState().statusButton === false &&
            AppStore.getState().statusVK === false &&
            AppStore.getState().statusOk === false &&
            AppStore.getState().statusPass === false &&
            AppStore.getState().statusFb === false &&
            AppStore.getState().statusCall === false
        ) {
            data['authParams'] = null;
            // console.log('@@@@@@@@');
        }
        // console.log('>>>>>>', AppStore.getState().authParams.redirectUrl)
        var redirectUrl;
        if (
            AppStore.getState().authParams.redirectUrl.length > 7 &&
            (AppStore.getState().authParams.redirectUrl.substr(0, 8) === 'https://' ||
                AppStore.getState().authParams.redirectUrl.substr(0, 7) === 'http://')
        ) {
            redirectUrl = AppStore.getState().authParams.redirectUrl;
        } else if (
            AppStore.getState().authParams.redirectUrl.length === 0 ||
            AppStore.getState().authParams.redirectUrl.substr(0, 1) === ' '
        ) {
            redirectUrl = '';
        } else {
            redirectUrl = 'http://' + AppStore.getState().authParams.redirectUrl;
        }
        if (data.authParams !== null) {
            data.authParams['layersCount'] = count;
            data.authParams['redirectUrl'] = redirectUrl;
            data.authParams['sessionPeriod'] = AppStore.getState().authParams.sessionPeriod;
            data.authParams['traficVolume'] = AppStore.getState().authParams.traficVolume;
            data.authParams['authPeriod'] = AppStore.getState().authParams.authPeriod;
            data.authParams['nextConnectionPeriod'] = AppStore.getState().authParams.nextConnectionPeriod;
        }
        // if (AppStore.getState().statusSms === false && AppStore.getState().statusButton === false && AppStore.getState().statusVK === false &&
        //     AppStore.getState().statusOk === false && AppStore.getState().statusPass === false && AppStore.getState().statusFb === false) {
        //     delete data.authParams;
        // }
        // console.log('!@#!@#!@#!@#', AppStore.getState().statusPerformens, data.blocks, AppStore.getState().dataPage)
        if (AppStore.getState().statusPerformens === true) {
            // console.log('panel!!!', data);
            if (AppStore.getState().urlImageBackground !== '') {
                data['media'] = [];
                // data.media[0]['url'] = AppStore.getState().urlImageBackground;
                data.media.push({ url: AppStore.getState().urlImageBackground });
            } else if (AppStore.getState().urlImageBackground === '') {
                data['media'] = [];
            }
            data['backgroundColor'] = AppStore.getState().backgroundColorWelcomGlobalPage;
            var blockPrelud = [];
            if (AppStore.getState().urlImageLogo !== '') {
                var styleLogo;
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().statusLoadLogo === false) {
                            ratioLogo = 0.5;
                            styleLogo = {
                                paddingTop: '10',
                                paddingBottom: '8',
                                paddingLeft: 0,
                                paddingRight: 0,
                                borderRadius: 0,
                                pageWidth: 260,
                            };
                        } else if (AppStore.getState().statusLoadLogo === true) {
                            ratioLogo = data.blocks[0].prototype.ratio;
                            styleLogo = data.blocks[0].style;
                        }
                    } else if (data.blocks.length === 0) {
                        ratioLogo = 0.5;
                        styleLogo = {
                            paddingTop: '10',
                            paddingBottom: '8',
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderRadius: 0,
                            pageWidth: 260,
                        };
                    }
                } else {
                    ratioLogo = 0.5;
                    styleLogo = {
                        paddingTop: '10',
                        paddingBottom: '8',
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                }
                objLogo = {
                    prototype: {
                        name: 'Маленький прямоугольный блок',
                        rel_width: 0.5,
                        ratio: ratioLogo,
                        details: {
                            discription: 'Хорошо подходит для кнопок, иконок и небольших виджетов.',
                            elements: [],
                        },
                        size: '50%',
                    },
                    params: {},
                    subBlocks: [],
                    media: [
                        {
                            url: AppStore.getState().urlImageLogo,
                            BGSize: 'contain',
                        },
                    ],
                    style: styleLogo,
                };
                blockPrelud.push(objLogo);
            }
            if (AppStore.getState().valueText !== '') {
                var bgColor;
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().indexText === null) {
                            ratioText = 0.4;
                            bgColor = 'rgba(230,75,75,0)';
                        } else if (AppStore.getState().indexText !== null) {
                            ratioText = data.blocks[AppStore.getState().indexText].prototype.ratio;
                            bgColor = data.blocks[AppStore.getState().indexText].backgroundColor;
                            console.log('panel', data.blocks[AppStore.getState().indexText].prototype.ratio);
                        }
                    } else if (data.blocks.length === 0) {
                        ratioText = 0.4;
                        bgColor = 'rgba(230,75,75,0)';
                    }
                } else {
                    ratioText = 0.4;
                    bgColor = 'rgba(230,75,75,0)';
                }
                if (AppStore.getState().fontSize === 0) {
                    fontSize = 15;
                } else if (AppStore.getState().fontSize !== 0) {
                    fontSize = AppStore.getState().fontSize;
                }
                if (AppStore.getState().statusTextStyle !== null) {
                    styleTextBox = AppStore.getState().statusTextStyle;
                } else {
                    styleTextBox = {
                        paddingTop: 0,
                        paddingBottom: 8,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                }
                objText = {
                    prototype: {
                        name: 'Прямоугольный блок',
                        rel_width: 1,
                        ratio: ratioText,
                        details: {
                            discription: 'Хорошо подходит для небольших картинок и текста.',
                            elements: [],
                        },
                        size: '100%',
                    },
                    params: {},
                    subBlocks: [],
                    textParams: {
                        font: "'Roboto', sans-serif",
                        fontSize: fontSize,
                        textDecoration: 'initial',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        textValue: AppStore.getState().valueText,
                        color: AppStore.getState().colorValueText,
                    },
                    backgroundColor: bgColor,
                    style: styleTextBox,
                };
                blockPrelud.push(objText);
            }
            if (AppStore.getState().urlImageGraphic !== '') {
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().indexGraphicBlock === null) {
                            ratioGraphic = 0.6;
                            styleGraphic = {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                borderRadius: 0,
                                pageWidth: 260,
                            };
                            graphicBGSize = 'cover';
                        } else if (AppStore.getState().indexGraphicBlock !== null) {
                            ratioGraphic = data.blocks[AppStore.getState().indexGraphicBlock].prototype.ratio;
                            styleGraphic = data.blocks[AppStore.getState().indexGraphicBlock].style;
                            graphicBGSize = data.blocks[AppStore.getState().indexGraphicBlock].media[0].BGSize;
                        }
                    } else if (data.blocks.length === 0) {
                        ratioGraphic = 0.6;
                        styleGraphic = {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderRadius: 0,
                            pageWidth: 260,
                        };
                        graphicBGSize = 'cover';
                    }
                } else {
                    ratioGraphic = 0.6;
                    styleGraphic = {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                    graphicBGSize = 'cover';
                }
                objGraphic = {
                    prototype: {
                        name: 'Горизонтальный прямоугольный блок',
                        rel_width: 1,
                        ratio: ratioGraphic,
                        details: {
                            discription: 'Хорошо подходит для прямоугольных баннеров и некоторых виджетов.',
                            elements: [],
                        },
                        size: '100%',
                    },
                    params: {},
                    subBlocks: [],
                    media: [
                        {
                            url: AppStore.getState().urlImageGraphic,
                            BGSize: graphicBGSize,
                        },
                    ],
                    style: styleGraphic,
                };
                blockPrelud.push(objGraphic);
            }
            data.blocks = blockPrelud;
            // console.log('panel!!!>>>>>>>>>', blockPrelud);
        } else if (AppStore.getState().statusPerformens === false) {
            data['blocks'] = AppStore.getState().blocksData;
        }

        dataReq = {
            access_token: AppStore.getState().headers['x-token'],
            page_content: JSON.stringify(data, null, 4),
            page_id: AppStore.getState().dataPage.id,
            page_title: AppStore.getState().dataPage.title,
            API_HOST: '',
        };
        // console.log('sdsd!!!!!>>>>>>>>>', data);
        dataGlobal = {
            dataReq: dataReq,
            headers: AppStore.getState().headers,
        };
        this.props.hideSettingsPanel();
        AppActions.submit(dataGlobal, this.props.getStatusSettingsLoad);
    }
    showPreview() {
        var fontSize;
        var ratioLogo;
        var objLogo;
        var dataReq;
        var dataGlobal;
        var objText;
        var ratioGraphic;
        var styleGraphic;
        var objGraphic;
        var styleTextBox;
        var graphicBGSize;
        var data = {};
        if (AppStore.getState().dataPage.data !== undefined) {
            data = JSON.parse(AppStore.getState().dataPage.data);
        }
        // console.log('sdsd>>>>>>>', data.authParams);
        if (data.authParams === undefined) {
            data['authParams'] = {};
        }
        data.authParams['layers'] = [];
        var layersSum = 1;
        var detail2 = JSON.parse(AppStore.getState().dataPage.data).authParams;
        console.log('sdsd>>>>>>>', detail2);
        if (detail2 !== undefined) {
            if (detail2.layers !== undefined) {
                for (let i = 0; i < detail2.layers.length; i++) {
                    layersSum = layersSum * detail2.layers[i].length;
                }
            }
        }
        // data.authParams.layers.length = AppStore.getState().title_layers.length;
        if (AppStore.getState().statusSms === true) {
            let obj = {};
            data.authParams.layers.push([]);
            // data.authParams.layers[0].push()
            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
            console.log('!@!@!@', layersSum);
            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                for (let i = 0; i < detail.layers.length; i++) {
                    for (let j = 0; j < detail.layers[i].length; j++) {
                        if (detail.layers[i][j].auth_title !== 'sms') {
                            obj = {
                                auth_title: 'sms',
                                expiration_sms_period: 15552000,
                                title: 'SMS авторизация',
                            };
                        } else {
                            obj = detail.layers[i][j];
                            j = detail.layers[i].length - 1;
                            i = detail.layers.length - 1;
                        }
                    }
                }
            } else if (detail === undefined) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            } else if (detail.layers === undefined) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            } else if (layersSum === 0) {
                obj = {
                    auth_title: 'sms',
                    expiration_sms_period: 15552000,
                    title: 'SMS авторизация',
                };
            }
            data.authParams.layers[0].push(obj);
            if (
                AppStore.getState().statusButton === true ||
                AppStore.getState().statusVK === true ||
                AppStore.getState().statusOk === true ||
                AppStore.getState().statusPass === true
            ) {
                data.authParams.layers.push([]);
            }
            if (AppStore.getState().statusButton === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'button') {
                                    obj = {
                                        auth_title: 'button',
                                        title: "Кнопка 'Войти в интернет'",
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                }

                // console.log('sdsd!!!!!',JSON.parse(AppStore.getState().dataPage.data).authParams.layers);
                data.authParams.layers[1].push(obj);
            }
            if (AppStore.getState().statusVK === true) {
                if (AppStore.getState().VKAutoPost.status === true) {
                    // console.log('!!>!>!>!>!>!>!>!>',AppStore.getState().VKAutoPost.statusOfNameUser, AppStore.getState().VKAutoPost.statusRepost);
                    if (AppStore.getState().VKAutoPost.statusOfNameUser === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.app,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: false,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.app;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = false;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    } else if (AppStore.getState().VKAutoPost.statusRepost === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: true,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.valueRepost.id;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = true;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    }
                } else if (AppStore.getState().VKAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                        obj = {
                                            auth_title: 'vkontakte',
                                            title: 'Вход через Vkontakte',
                                        };
                                    } else {
                                        // console.log('!!<!<!<!<!<!<!<',detail.layers[i][j]);
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        delete objTemp['repost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    }
                    data.authParams.layers[1].push(obj);
                }
            }
            if (AppStore.getState().statusFb === true) {
                // console.log('sdsd>>>>>>>', data.authParams);
                if (data.authParams.layers[1] === undefined) {
                    data.authParams.layers.push([]);
                }
                if (AppStore.getState().FBAutoPost.status === true) {
                    if (AppStore.getState().FBAutoPost.image === '') {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                autopost: true,
                                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                autopost_title: AppStore.getState().FBAutoPost.title,
                                                autopost_url: AppStore.getState().FBAutoPost.link,
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj['autopost'] = true;
                                            obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                            obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                            obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    } else if (AppStore.getState().FBAutoPost.image !== '') {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                autopost: true,
                                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                autopost_img: AppStore.getState().FBAutoPost.image,
                                                autopost_title: AppStore.getState().FBAutoPost.title,
                                                autopost_url: AppStore.getState().FBAutoPost.link,
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj['autopost'] = true;
                                            obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                            obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                            obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                            obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                autopost: true,
                                autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                autopost_img: AppStore.getState().FBAutoPost.image,
                                autopost_title: AppStore.getState().FBAutoPost.title,
                                autopost_url: AppStore.getState().FBAutoPost.link,
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[1].push(obj);
                    }
                } else if (AppStore.getState().FBAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'facebook') {
                                        obj = {
                                            auth_title: 'facebook',
                                            title: 'Вход через Facebook',
                                        };
                                    } else {
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'facebook',
                            title: 'Вход через Facebook',
                        };
                    }
                    data.authParams.layers[1].push(obj);
                }
            }
            if (AppStore.getState().statusOk === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'odnoklassniki') {
                                    obj = {
                                        auth_title: 'odnoklassniki',
                                        title: 'Вход через Odnoklassniki',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                }
                data.authParams.layers[1].push(obj);
            }
            if (AppStore.getState().statusPass === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'password') {
                                    obj = {
                                        auth_title: 'password',
                                        title: 'Вход с паролем',
                                        password: AppStore.getState().inputPass,
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['password'] = AppStore.getState().inputPass;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                }
                data.authParams.layers[1].push(obj);
            }
        } else if (AppStore.getState().statusSms === false) {
            let obj = {};
            if (AppStore.getState().statusCall === true) {
                if (data.authParams.layers.length === 0) {
                    data.authParams.layers.push([]);
                }
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'call') {
                                    obj = {
                                        auth_title: 'call',
                                        expiration_call_period: this.state.inputCall,
                                        title: 'Авторизация по звонку',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['expiration_call_period'] = this.state.inputCall;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    // console.log('submit1');
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                } else if (layersSum === 0) {
                    // console.log('submit1');
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'call',
                        expiration_call_period: this.state.inputCall,
                        title: 'Авторизация по звонку',
                    };
                }
                data.authParams.layers[0].push(obj);
            }
            if (
                AppStore.getState().statusButton === true ||
                AppStore.getState().statusVK === true ||
                AppStore.getState().statusOk === true ||
                AppStore.getState().statusPass === true
            ) {
                data.authParams.layers.push([]);
            }
            if (AppStore.getState().statusButton === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'button') {
                                    obj = {
                                        auth_title: 'button',
                                        title: "Кнопка 'Войти в интернет'",
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'button',
                        title: "Кнопка 'Войти в интернет'",
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
            if (AppStore.getState().statusVK === true) {
                if (AppStore.getState().VKAutoPost.status === true) {
                    if (AppStore.getState().VKAutoPost.statusOfNameUser === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.app,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: false,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.app;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = false;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.app,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: false,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    } else if (AppStore.getState().VKAutoPost.statusRepost === true) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        // console.log('!!>!>!>!>!>!>!>!>',AppStore.getState().VKAutoPost.valueRepost.id, AppStore.getState().VKAutoPost.app);
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                            obj = {
                                                auth_title: 'vkontakte',
                                                autopost: true,
                                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                                autopost_message: AppStore.getState().VKAutoPost.message,
                                                repost: true,
                                                title: 'Вход через Vkontakte',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            obj.autopost_details = AppStore.getState().VKAutoPost.valueRepost.id;
                                            obj.autopost_message = AppStore.getState().VKAutoPost.message;
                                            obj.autopost = true;
                                            obj.repost = true;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'vkontakte',
                                autopost: true,
                                autopost_details: AppStore.getState().VKAutoPost.valueRepost.id,
                                autopost_message: AppStore.getState().VKAutoPost.message,
                                repost: true,
                                title: 'Вход через Vkontakte',
                            };
                        }
                        // console.log('!!>!>!>!>!>!>!>!>end',AppStore.getState().VKAutoPost.valueRepost.id, AppStore.getState().VKAutoPost.app);
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                } else if (AppStore.getState().VKAutoPost.status === false) {
                    let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                    if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                        if (detail.layers !== undefined) {
                            for (let i = 0; i < detail.layers.length; i++) {
                                for (let j = 0; j < detail.layers[i].length; j++) {
                                    if (detail.layers[i][j].auth_title !== 'vkontakte') {
                                        obj = {
                                            auth_title: 'vkontakte',
                                            title: 'Вход через Vkontakte',
                                        };
                                    } else {
                                        let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                        delete objTemp['autopost'];
                                        delete objTemp['repost'];
                                        obj = objTemp;
                                        j = detail.layers[i].length - 1;
                                        i = detail.layers.length - 1;
                                    }
                                }
                            }
                        }
                    } else if (detail === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (layersSum === 0) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    } else if (detail.layers === undefined) {
                        obj = {
                            auth_title: 'vkontakte',
                            title: 'Вход через Vkontakte',
                        };
                    }
                    data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                }
            }
            if (AppStore.getState().statusFb === true) {
                // console.log('???????????');
                if (data.authParams !== undefined) {
                    if (data.authParams.layers[data.authParams.layers.length - 1] === undefined) {
                        data.authParams.layers.push([]);
                    }
                    if (AppStore.getState().FBAutoPost.status === true) {
                        if (AppStore.getState().FBAutoPost.image === '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        } else if (AppStore.getState().FBAutoPost.image !== '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        }
                    } else if (AppStore.getState().FBAutoPost.status === false) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            let objTemp = JSON.parse(JSON.stringify(detail.layers[i][j]));
                                            delete objTemp['autopost'];
                                            obj = objTemp;
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                } else {
                    data['authParams']['layers'].push([]);
                    if (AppStore.getState().FBAutoPost.status === true) {
                        if (AppStore.getState().FBAutoPost.image === '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        } else if (AppStore.getState().FBAutoPost.image !== '') {
                            let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                            if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                                if (detail.layers !== undefined) {
                                    for (let i = 0; i < detail.layers.length; i++) {
                                        for (let j = 0; j < detail.layers[i].length; j++) {
                                            if (detail.layers[i][j].auth_title !== 'facebook') {
                                                obj = {
                                                    auth_title: 'facebook',
                                                    autopost: true,
                                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                                    title: 'Вход через Facebook',
                                                };
                                            } else {
                                                obj = detail.layers[i][j];
                                                obj['autopost'] = true;
                                                obj['autopost_description'] = AppStore.getState().FBAutoPost.discriptions;
                                                obj['autopost_title'] = AppStore.getState().FBAutoPost.title;
                                                obj['autopost_url'] = AppStore.getState().FBAutoPost.link;
                                                obj['autopost_img'] = AppStore.getState().FBAutoPost.image;
                                                j = detail.layers[i].length - 1;
                                                i = detail.layers.length - 1;
                                            }
                                        }
                                    }
                                }
                            } else if (detail === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (layersSum === 0) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            } else if (detail.layers === undefined) {
                                obj = {
                                    auth_title: 'facebook',
                                    autopost: true,
                                    autopost_description: AppStore.getState().FBAutoPost.discriptions,
                                    autopost_img: AppStore.getState().FBAutoPost.image,
                                    autopost_title: AppStore.getState().FBAutoPost.title,
                                    autopost_url: AppStore.getState().FBAutoPost.link,
                                    title: 'Вход через Facebook',
                                };
                            }
                            data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                        }
                    } else if (AppStore.getState().FBAutoPost.status === false) {
                        let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                        if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                            if (detail.layers !== undefined) {
                                for (let i = 0; i < detail.layers.length; i++) {
                                    for (let j = 0; j < detail.layers[i].length; j++) {
                                        if (detail.layers[i][j].auth_title !== 'facebook') {
                                            obj = {
                                                auth_title: 'facebook',
                                                title: 'Вход через Facebook',
                                            };
                                        } else {
                                            obj = detail.layers[i][j];
                                            j = detail.layers[i].length - 1;
                                            i = detail.layers.length - 1;
                                        }
                                    }
                                }
                            }
                        } else if (detail === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (layersSum === 0) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        } else if (detail.layers === undefined) {
                            obj = {
                                auth_title: 'facebook',
                                title: 'Вход через Facebook',
                            };
                        }
                        data.authParams.layers[data.authParams.layers.length - 1].push(obj);
                    }
                }
            }
            if (AppStore.getState().statusOk === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'odnoklassniki') {
                                    obj = {
                                        auth_title: 'odnoklassniki',
                                        title: 'Вход через Odnoklassniki',
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'odnoklassniki',
                        title: 'Вход через Odnoklassniki',
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
            if (AppStore.getState().statusPass === true) {
                let detail = JSON.parse(AppStore.getState().dataPage.data).authParams;
                if (detail !== undefined && detail.layers !== undefined && layersSum > 0) {
                    if (detail.layers !== undefined) {
                        for (let i = 0; i < detail.layers.length; i++) {
                            for (let j = 0; j < detail.layers[i].length; j++) {
                                if (detail.layers[i][j].auth_title !== 'password') {
                                    obj = {
                                        auth_title: 'password',
                                        title: 'Вход с паролем',
                                        password: AppStore.getState().inputPass,
                                    };
                                } else {
                                    obj = detail.layers[i][j];
                                    obj['password'] = AppStore.getState().inputPass;
                                    j = detail.layers[i].length - 1;
                                    i = detail.layers.length - 1;
                                }
                            }
                        }
                    }
                } else if (detail === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (layersSum === 0) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                } else if (detail.layers === undefined) {
                    obj = {
                        auth_title: 'password',
                        title: 'Вход с паролем',
                        password: AppStore.getState().inputPass,
                    };
                }
                data.authParams.layers[data.authParams.layers.length - 1].push(obj);
            }
        }
        let count;
        if (data.authParams.layers === undefined) {
            count = 0;
        } else if (data.authParams.layers !== undefined) {
            count = data.authParams.layers.length;
        }
        if (
            AppStore.getState().statusSms === true ||
            AppStore.getState().statusButton === true ||
            AppStore.getState().statusVK === true ||
            AppStore.getState().statusOk === true ||
            AppStore.getState().statusPass === true ||
            AppStore.getState().statusFb === true
        ) {
            // data['authBackgroundColor'] = AppStore.getState().authBackgroundColor
            data['authButtonBackgroundColor'] = AppStore.getState().authButtonBackgroundColor;
            // data['authButtonText'] = AppStore.getState().authButtonText;
            // delete data.authDisplayMode;
            // if (AppStore.getState().authDisplayMode === true) {
            //     data['authDisplayMode'] = AppStore.getState().authDisplayMode
            // }
        } else if (
            AppStore.getState().statusSms === false &&
            AppStore.getState().statusButton === false &&
            AppStore.getState().statusVK === false &&
            AppStore.getState().statusOk === false &&
            AppStore.getState().statusPass === false &&
            AppStore.getState().statusFb === false &&
            AppStore.getState().statusCall === false
        ) {
            data['authParams'] = null;
            // console.log('@@@@@@@@');
        }
        //
        var redirectUrl;
        if (
            AppStore.getState().authParams.redirectUrl.length > 7 &&
            (AppStore.getState().authParams.redirectUrl.substr(0, 8) === 'https://' ||
                AppStore.getState().authParams.redirectUrl.substr(0, 7) === 'http://')
        ) {
            redirectUrl = AppStore.getState().authParams.redirectUrl;
        } else if (
            AppStore.getState().authParams.redirectUrl.length === 0 ||
            AppStore.getState().authParams.redirectUrl.substr(0, 1) === ' '
        ) {
            redirectUrl = '';
        } else {
            redirectUrl = 'http://' + AppStore.getState().authParams.redirectUrl;
        }
        if (data.authParams !== null) {
            data.authParams['layersCount'] = count;
            data.authParams['redirectUrl'] = redirectUrl;
            data.authParams['sessionPeriod'] = AppStore.getState().authParams.sessionPeriod;
            data.authParams['traficVolume'] = AppStore.getState().authParams.traficVolume;
            data.authParams['authPeriod'] = AppStore.getState().authParams.authPeriod;
            data.authParams['nextConnectionPeriod'] = AppStore.getState().authParams.nextConnectionPeriod;
        }
        // if (AppStore.getState().statusSms === false && AppStore.getState().statusButton === false && AppStore.getState().statusVK === false &&
        //     AppStore.getState().statusOk === false && AppStore.getState().statusPass === false && AppStore.getState().statusFb === false) {
        //     delete data.authParams;
        // }
        // console.log('!@#!@#!@#!@#', AppStore.getState().statusPerformens, data.blocks, AppStore.getState().dataPage)
        if (AppStore.getState().statusPerformens === true) {
            // console.log('panel!!!', data);
            if (AppStore.getState().urlImageBackground !== '') {
                data['media'] = [];
                // data.media[0]['url'] = AppStore.getState().urlImageBackground;
                data.media.push({ url: AppStore.getState().urlImageBackground });
            } else if (AppStore.getState().urlImageBackground === '') {
                data['media'] = [];
            }
            data['backgroundColor'] = AppStore.getState().backgroundColorWelcomGlobalPage;
            var blockPrelud = [];
            if (AppStore.getState().urlImageLogo !== '') {
                var styleLogo;
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().statusLoadLogo === false) {
                            ratioLogo = 0.5;
                            styleLogo = {
                                paddingTop: '10',
                                paddingBottom: '8',
                                paddingLeft: 0,
                                paddingRight: 0,
                                borderRadius: 0,
                                pageWidth: 260,
                            };
                        } else if (AppStore.getState().statusLoadLogo === true) {
                            ratioLogo = data.blocks[0].prototype.ratio;
                            styleLogo = data.blocks[0].style;
                        }
                    } else if (data.blocks.length === 0) {
                        ratioLogo = 0.5;
                        styleLogo = {
                            paddingTop: '10',
                            paddingBottom: '8',
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderRadius: 0,
                            pageWidth: 260,
                        };
                    }
                } else {
                    ratioLogo = 0.5;
                    styleLogo = {
                        paddingTop: '10',
                        paddingBottom: '8',
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                }
                objLogo = {
                    prototype: {
                        name: 'Маленький прямоугольный блок',
                        rel_width: 0.5,
                        ratio: ratioLogo,
                        details: {
                            discription: 'Хорошо подходит для кнопок, иконок и небольших виджетов.',
                            elements: [],
                        },
                        size: '50%',
                    },
                    params: {},
                    subBlocks: [],
                    media: [
                        {
                            url: AppStore.getState().urlImageLogo,
                            BGSize: 'contain',
                        },
                    ],
                    style: styleLogo,
                };
                blockPrelud.push(objLogo);
            }
            if (AppStore.getState().valueText !== '') {
                var ratioText;
                var bgColor;
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().indexText === null) {
                            ratioText = 0.4;
                            bgColor = 'rgba(230,75,75,0)';
                        } else if (AppStore.getState().indexText !== null) {
                            ratioText = data.blocks[AppStore.getState().indexText].prototype.ratio;
                            bgColor = data.blocks[AppStore.getState().indexText].backgroundColor;
                            console.log('panel', data.blocks[AppStore.getState().indexText].prototype.ratio);
                        }
                    } else if (data.blocks.length === 0) {
                        ratioText = 0.4;
                        bgColor = 'rgba(230,75,75,0)';
                    }
                } else {
                    ratioText = 0.4;
                    bgColor = 'rgba(230,75,75,0)';
                }
                if (AppStore.getState().fontSize === 0) {
                    fontSize = 15;
                } else if (AppStore.getState().fontSize !== 0) {
                    fontSize = AppStore.getState().fontSize;
                }
                if (AppStore.getState().statusTextStyle !== null) {
                    styleTextBox = AppStore.getState().statusTextStyle;
                } else {
                    styleTextBox = {
                        paddingTop: 0,
                        paddingBottom: 8,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                }
                objText = {
                    prototype: {
                        name: 'Прямоугольный блок',
                        rel_width: 1,
                        ratio: ratioText,
                        details: {
                            discription: 'Хорошо подходит для небольших картинок и текста.',
                            elements: [],
                        },
                        size: '100%',
                    },
                    params: {},
                    subBlocks: [],
                    textParams: {
                        font: "'Roboto', sans-serif",
                        fontSize: fontSize,
                        textDecoration: 'initial',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        textValue: AppStore.getState().valueText,
                        color: AppStore.getState().colorValueText,
                    },
                    backgroundColor: bgColor,
                    style: styleTextBox,
                };
                blockPrelud.push(objText);
            }
            if (AppStore.getState().urlImageGraphic !== '') {
                if (data.blocks !== undefined) {
                    if (data.blocks.length > 0) {
                        if (AppStore.getState().indexGraphicBlock === null) {
                            ratioGraphic = 0.6;
                            styleGraphic = {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                borderRadius: 0,
                                pageWidth: 260,
                            };
                            graphicBGSize = 'cover';
                        } else if (AppStore.getState().indexGraphicBlock !== null) {
                            ratioGraphic = data.blocks[AppStore.getState().indexGraphicBlock].prototype.ratio;
                            styleGraphic = data.blocks[AppStore.getState().indexGraphicBlock].style;
                            graphicBGSize = data.blocks[AppStore.getState().indexGraphicBlock].media[0].BGSize;
                        }
                    } else if (data.blocks.length === 0) {
                        ratioGraphic = 0.6;
                        styleGraphic = {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderRadius: 0,
                            pageWidth: 260,
                        };
                        graphicBGSize = 'cover';
                    }
                } else {
                    ratioGraphic = 0.6;
                    styleGraphic = {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        pageWidth: 260,
                    };
                    graphicBGSize = 'cover';
                }
                objGraphic = {
                    prototype: {
                        name: 'Горизонтальный прямоугольный блок',
                        rel_width: 1,
                        ratio: ratioGraphic,
                        details: {
                            discription: 'Хорошо подходит для прямоугольных баннеров и некоторых виджетов.',
                            elements: [],
                        },
                        size: '100%',
                    },
                    params: {},
                    subBlocks: [],
                    media: [
                        {
                            url: AppStore.getState().urlImageGraphic,
                            BGSize: graphicBGSize,
                        },
                    ],
                    style: styleGraphic,
                };
                blockPrelud.push(objGraphic);
            }
            data.blocks = blockPrelud;
            // console.log('panel!!!>>>>>>>>>', blockPrelud);
        } else if (AppStore.getState().statusPerformens === false) {
            data['blocks'] = AppStore.getState().blocksData;
        }
        dataReq = {
            access_token: AppStore.getState().headers['x-token'],
            page_content: JSON.stringify(data, null, 4),
            page_id: AppStore.getState().dataPage.id,
            page_title: AppStore.getState().dataPage.title,
            API_HOST: '',
        };
        console.log('sdsd!!!!!', data, JSON.parse(AppStore.getState().dataPage.data));
        dataGlobal = {
            dataReq: dataReq,
            headers: AppStore.getState().headers,
        };
        AppActions.showPreview(dataGlobal);
    }
    render() {
        var statusShedule = false;
        for (let i = 0; i < this.props.dataRadiusStore.schedulesList.length; i++) {
            if (AppStore.getState().dataPage.id === this.props.dataRadiusStore.schedulesList[i].page_id) {
                statusShedule = true;
            }
        }
        var componentNotSave = null;
        if (
            AppStore.getState().statusSms === false &&
            AppStore.getState().statusButton === false &&
            AppStore.getState().statusVK === false &&
            AppStore.getState().statusOk === false &&
            AppStore.getState().statusPass === false &&
            AppStore.getState().statusFb === false &&
            AppStore.getState().statusCall === false &&
            statusShedule === true
        ) {
            componentNotSave = <div className="notSave"></div>;
        }
        // console.log('sdsd>>>>>>>', lang.getState().pages.settings);
        var heigth,
            classNameSaveBatton = 'save',
            funcButtonSave = this.onSubmit.bind(this),
            componentSpinerLoader = null,
            componentSpinerLoaderTempPage = null;
        // if (AppStore.getState().statusSms === false &&
        //     AppStore.getState().statusButton === false &&
        //     AppStore.getState().statusVK === false &&
        //     AppStore.getState().statusOk === false &&
        //     AppStore.getState().statusPass === false &&
        //     AppStore.getState().statusFb === false &&
        //     this.state.statusSettings === 'performance') {
        //     componentButtins = <button onClick={this.onSelect} id="buttonAuthSetting" className='buttonInAuth'>Настройки авторизации</button>
        // }
        if (this.state.statusLoadTempPage === true) {
            componentSpinerLoaderTempPage = (
                <div className="boxSpinerLoaderTemPage">
                    <span>{this.props._t.t('Подготовка...')}</span>
                    {/*<i className="fa fa-circle-o-notch fa-spin fa-lg"></i>*/}
                </div>
            );
        }
        if (this.state.statusSettingsAuth === false) {
            classNameSaveBatton = 'save disabledButton';
            funcButtonSave = null;
        }

        if (
            AppStore.getState().statusSms === false &&
            AppStore.getState().statusButton === false &&
            AppStore.getState().statusVK === false &&
            AppStore.getState().statusOk === false &&
            AppStore.getState().statusPass === false &&
            AppStore.getState().statusFb === false &&
            this.state.statusSettings === 'performance'
        ) {
            componentButtins = (
                <button onClick={(e) => this.onSelect(e)} id="buttonAuthSetting" className="buttonInAuth">
                    {'Продолжить'}
                </button>
            );
        }
        if (
            this.state.sessionPeriod === '' ||
            this.state.authPeriod === '' ||
            this.state.traficVolume === '' ||
            this.state.redirectUrl === '' ||
            this.state.redirectUrl.length < 8
        ) {
            classNameSaveBatton = 'save disabledButton';
            funcButtonSave = null;
        }
        if (this.state.statusLoad === true) {
            classNameSaveBatton = 'save disabledButton';
            funcButtonSave = null;
            componentSpinerLoader = (
                <div className="boxSpinerLoader">
                    <div style={{ height: '100%', width: '100%', margin: 'auto' }}>
                        <div className="globalSpinnerLoaderSvg"></div>
                    </div>
                </div>
            );
        }
        if (this.state.statusPass === true) {
            if (this.state.inputPass === '') {
                classNameSaveBatton = 'save disabledButton';
                funcButtonSave = null;
            }
        }
        if (window.innerHeight - 297 >= 365) {
            heigth = 365;
        } else if (window.innerHeight - 297 < 365) {
            heigth = window.innerHeight - 297;
        }

        var classNamePerf = 'itemTabsElem',
            classNameAuth = 'itemTabsElem',
            classNameAuthBox = 'authorizationBox',
            classNamePerfBox = 'performanceBox';
        if (this.state.statusSettings === 'performance') {
            classNamePerf = 'itemTabsElem activeTabs';
            classNameAuth = 'itemTabsElem notActiveTabs';
            classNameAuthBox = 'authorizationBox notActiveBox';
            classNamePerfBox = 'performanceBox activeBox';
        } else if (this.state.statusSettings === 'authorization') {
            classNamePerf = 'itemTabsElem notActiveTabs';
            classNameAuth = 'itemTabsElem activeTabs';
            classNameAuthBox = 'authorizationBox activeBox';
            classNamePerfBox = 'performanceBox notActiveBox';
        }
        var componentButtins = (
            <button onClick={(e) => funcButtonSave(e)} className={classNameSaveBatton}>
                {this.props._t.t('Сохранить изменения')}
            </button>
        );
        return (
            <div id="background" className="panelBox" onClick={(e) => this.onHide(e)}>
                <div className="panelSettingsControlPanel">
                    <div className="panelHeaders">
                        <h2>{this.props._t.t('НАСТРОЙКИ')}</h2>
                        <span id="close" className="closePanel">
                            X
                        </span>
                    </div>
                    <div className="tabsPanel">
                        <div className={classNamePerf}>
                            <span onClick={(e) => this.onSelect(e)} id="performance">
                                {this.props._t.t('Настройки представления')}
                            </span>
                        </div>
                        <div className={classNameAuth}>
                            <span onClick={(e) => this.onSelect(e)} id="authorization">
                                {this.props._t.t('Выбор авторизации')}
                            </span>
                        </div>
                        <div className="itemTabsElem">
                            <span></span>
                        </div>
                    </div>
                    <div className="boxParams" style={{ height: heigth }}>
                        <Performance
                            location={this.props.location}
                            token={this.props.token}
                            className={classNamePerfBox}
                            prop={this.state.paramsSettingsSelect}
                            lang={this.props.lang}
                            _t={this.props._t}
                        />
                        <Authorization
                            className={classNameAuthBox}
                            location={this.props.location}
                            token={this.props.token}
                            prop={this.state.paramsSettingsSelect}
                            lang={this.props.lang}
                            _t={this.props._t}
                        />
                    </div>
                    <div className="footer">
                        {/*<button className="review desabledButton">Предварительный просмотр</button>*/}
                        <button
                            onClick={(e) => this.onClickShowPagePreviews(e)}
                            onMouseEnter={(e) => this.showPreview(e)}
                            className="review"
                        >
                            {this.props._t.t('Предварительный просмотр')}
                        </button>
                        {componentSpinerLoaderTempPage}
                        {componentNotSave}
                        {componentButtins}
                        <button id="closeButton" className="closeButton">
                            {this.props._t.t('Закрыть')}
                        </button>
                        {componentSpinerLoader}
                    </div>
                </div>
            </div>
        );
    }
}

export default Panel;
