import React, { useEffect, useState } from 'react';

export default (targetRef, fullScreenShow) => {
    const [parentDimensions, setParentDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (targetRef.current) {
            setParentSize(targetRef.current);
        }
    }, [fullScreenShow, targetRef]);

    const setParentSize = (node) => {
        if (node && node.parentNode) {
            setParentDimensions({
                width: node.parentNode.clientWidth,
                height: node.parentNode.clientHeight,
            });
        }
    };

    // useEffect(() => {
    //     window.addEventListener('resize', setParentSize(targetRef.current));
    //     return () => window.removeEventListener('resize', setParentSize());
    // }, []);

    return parentDimensions;
};
