import React from 'react';
import styled from "styled-components/macro";
import SchedulersItem from "./schedulersItem";
import {useTranslation} from "react-i18next";

const SchedulersWrapper = (props) => {
    const {schedulers, deleteSchedulers} = props;
    const {t, i18n} = useTranslation();

    const DayPickerStrings = {

        days: [t('Понедельник'), t('Вторник'), t('Среда'), t('Четверг'), t('Пятница'), t('Суббота'), t('Воскресенье')],

    };

    const nodeSchedulers = schedulers.map((item, index) => {
        return(<SchedulersItem
            key={`schedulerItem${index}`}
            prop={item}
            deleteSchedulers={deleteSchedulers}
            stringDayOfWeek={DayPickerStrings.days}
        />)
    })


    return (
        <SCSchedulersWrapper>
            {nodeSchedulers}
        </SCSchedulersWrapper>
    );
};

export default SchedulersWrapper;

const SCSchedulersWrapper = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: calc(100% - 58px);
  overflow: auto;
`;
