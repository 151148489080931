import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './style/pdf.scss'

import BodyPanel from './components/body_panel'

class MainPdf extends PureComponent {
    render() {
        return (
            <div className={'pdfBox'}>
                <BodyPanel _t={this.props._t}/>
            </div>
        );
    }
}

MainPdf.propTypes = {};

export default MainPdf;
