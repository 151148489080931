import React, { useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { runAction } from '../../actions';
import Tools from '../../tools/tools';

import TopCalendar from './components/top_calendar';
import BoxInputParameters from './components/box_input_parameters';
import BoxParamsPreCamp from "../pre_campaign/components/BoxParamsPreCamp";




const GlobalOptions = React.forwardRef(({ scrollDirection, bgColor }, ref) => {
    const dispatch = useDispatch();
    const { lang, sectionsByKeyName, status, statusLeftPanel } = useSelector((state) => state.TopPanel);
    const { fullScreenId } = useSelector((state) => state.Diagrams);
    const { white_label_settings } = useSelector((state) => state.LoginApp);
    const { showLocationsSelectionPanel } = useSelector((state) => state.LocationsPanel);
    const { main_date_range, comparison_date_range } = useSelector((state) => state.ReportParametersManager.customInputParamsValues);
    const { groupIDsBySubsectionKeyName, dataViewsByGroupID, inputParameters, globalInputParamsValues } = useSelector(
        (state) => state.ReportParametersManager
    );

    const selectDate = (mainRange, comparisonRange) => {
        dispatch(runAction('resetShifts_Diagrams', {}));
        dispatch(runAction('selectDate', { mainRange, comparisonRange }));
    };

    const onInputParameterChange = (key_name, value) => {
        dispatch(runAction('onInputParameterChange', { key_name, value }));
    };

    const showGlobalParams = Tools.useGetParamSet(null, null, fullScreenId).global.length !== 0;

    const inputParams = useMemo(() => {
        return (
            <BoxInputParameters
                disabled={showLocationsSelectionPanel}
                currentSubSection={statusLeftPanel}
                fullScreenId={fullScreenId}
                groupIDsBySubsectionKeyName={groupIDsBySubsectionKeyName}
                dataViewsByGroupID={dataViewsByGroupID}
                inputParameters={inputParameters}
                lang={lang}
                onInputParameterChange={onInputParameterChange}
                globalInputParamsValues={globalInputParamsValues}
            />
        );
    }, [
        showLocationsSelectionPanel,
        statusLeftPanel,
        groupIDsBySubsectionKeyName,
        dataViewsByGroupID,
        inputParameters,
        lang,
        globalInputParamsValues,
        onInputParameterChange,
        fullScreenId,
    ]);


    return (
        <GlobalOptionsOuterWrapper scrollDirection={scrollDirection} fullScreenId={fullScreenId} ref={ref} bgColor={bgColor}>
            <Container bgColor={bgColor}>
                <TopCalendar
                    fullScreenId={fullScreenId}
                    selectDate={selectDate}
                    lang={lang}
                    sectionsByKeyName={sectionsByKeyName}
                    currentSection={status}
                    statusLeftPanel={statusLeftPanel}
                    main_date_range={main_date_range}
                    comparison_date_range={comparison_date_range}
                />
                {showGlobalParams ? inputParams : null}
                {statusLeftPanel === 'pre_campaign_static' || statusLeftPanel === 'pre_campaign_dynamic' ?
                    <BoxParamsPreCamp
                        statusLeftPanel={statusLeftPanel}
                    />
                    : null}
            </Container>
        </GlobalOptionsOuterWrapper>
    );
});

export default GlobalOptions;

const animation = (p) => {
    const appearence = keyframes`
  0% { top: -300px; }
  100% { top: 0; }
`;
    const disappearence = keyframes`
  0% { top: 0px; }
  100% { top: -300px; }
`;
    return p.scrollDirection === 'UP'
        ? css`
              ${appearence} 0.4s ease-in-out;
          `
        : css`
              ${disappearence} 0.4s ease-in-out;
          `;
};

const GlobalOptionsOuterWrapper = styled.section`
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
    background-color: rgba(255, 255, 255, 0.5);
    /* background-color: ${(props) => props.bgColor}; */
    /* animation: ${(p) => p.fullScreenId && animation}; */
    animation: ${animation};
    /* overflow: hidden; */
    padding: 16px;
    padding-bottom: 5px;
    width: calc(100% - 238px - 30px);
    position: absolute;
    top: ${(p) => {
        if (p.scrollDirection === 'UP' || p.fullScreenId) {
            return '0px';
        } else {
            return '-300px';
        }
    }};
    left: 15px;
    z-index: 200;
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* padding: 16px; */
    /* border: ${(p) => '1px solid ' + p.bgColor}; */
    border-radius: 10px;
    /* background-color: ${(p) => p.bgColor}; */
`;
