import React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import styled from 'styled-components/macro';

import '../styles/complete_tables.scss';

const StChoiceGroup = styled(ChoiceGroup)`
    label {
        display: flex;
        align-items: center;
    }
`;

const Modes = React.memo(({ mode, _t, ...props }) => {
    const options = [
        { key: 1, text: _t.t('Объединенные таблицы') },
        { key: 2, text: _t.t('Отдельные таблицы') }
    ];

    const onCompleteTablesModeChange = React.useCallback((e, option) => {
        props.onCompleteTablesModeChange(option.key);
    }, []);

    return <StChoiceGroup selectedKey={mode} options={options} onChange={onCompleteTablesModeChange} />;
});

export default Modes;
