import React, { useRef, useEffect, useContext, useMemo, useState } from 'react';
import { Map, TileLayer, Marker, Popup, LayerGroup, LayersControl, AttributionControl, Pane, Polygon } from 'react-leaflet';
import MarkerClusterGroup from './react-leaflet-markercluster';
import L from 'leaflet';
import { Portal } from 'react-leaflet-portal';

import '../leaflet/leaflet.css';
import '../style/markercluster-styles.css';
import { filterData, makeLeafletPolygon } from '../tools';
import Circles from './circles';
import Sectors from './sectors';
import styled from 'styled-components/macro';
import ZoomWorldControl from './zoom-world-control';

const adSpaceIcon = new L.Icon({
    iconUrl: require('../img/pin.svg'),
    iconRetinaUrl: require('../img/pin.svg'),
    iconAnchor: [10, 30],
    popupAnchor: [0, 0],
    // shadowUrl: require('../img/pin.png'),
    // shadowSize: new L.Point(50, 20),
    // shadowAnchor: new L.Point(10, 15),
    iconSize: new L.Point(20, 30),
});

const OutdoorMap = React.memo(
    ({
        filteredAdSpaces,
        areaPolygon,
        mapCenter,
        initialZoom,
        onSectorClick,
        selectedSpaces,
        staticAdCampSelectedSpaces,
        nonZeroPolygons,
        isMapExpanded,
        mode,
        ...props
    }) => {
        const mapRef = useRef(null);
        const [currentZoom, setCurrentZoom] = useState(initialZoom);
        const thresholdZoomLevel = 13;
        const cityThresholdZoomLevel = 15;

        useEffect(() => {
            return () => {
                const center = mapRef.current.leafletElement.getCenter();
                const currentZoom = mapRef.current.leafletElement._zoom;
                // const currentMap = mapRef.current.leafletElement;
                props.handleSaveCenter(center);
                props.setInitialZoom(currentZoom);
                // props.setCurrentMap(currentMap);
            };
        }, [mapRef.current]);

        const onZoom = () => {
            const currentZoom = mapRef.current.leafletElement._zoom;
            setCurrentZoom(currentZoom);
            // props.handleZoomChange(currentZoom);
        };

        const renderMarkers = (spaces, icon) => {
            if (spaces) {
                const markersCoord = new Set(
                    spaces.map((item, i) => {
                        return { lat: item.latitude, lng: item.longitude };
                    })
                );

                return Array.from(markersCoord).map((item, i) => {
                    return (
                        <Marker position={{ lat: item.lat, lng: item.lng }} key={`marker-${i}`} icon={icon}>
                            <Popup>{`${item.lat} ${item.lng}`}</Popup>
                        </Marker>
                    );
                });
            }
        };

        const createClusterCustomIcon = (cluster) => {
            const childCount = cluster.getChildCount();
            let c = ' custom-marker-cluster-';
            if (childCount < 10) {
                c += 'small';
            } else if (childCount < 100) {
                c += 'medium';
            } else {
                c += 'large';
            }

            return new L.divIcon({
                html: '<div style="display:flex;align-items:center;justify-content:center;">' + childCount + '</div>',
                className: 'custom-marker-cluster' + c,
                iconSize: new L.Point(40, 40),
            });
        };

        // const createSquareClusterCustomIcon = cluster => {
        //     const childCount = cluster.getChildCount();
        //     let c = ' custom-marker-cluster-';
        //     if (childCount < 10) {
        //         c += 'small';
        //     } else if (childCount < 100) {
        //         c += 'medium';
        //     } else {
        //         c += 'large';
        //     }

        //     return new L.divIcon({
        //         html: '<div><span>' + childCount + '</span></div>',
        //         className: 'custom-marker-cluster-square' + c,
        //         iconSize: new L.Point(40, 40)
        //     });
        // };

        const onZoomWorldClick = () => {
            const map = mapRef.current.leafletElement;
            map.setZoom(2);
        };

        const center = [mapCenter.lat, mapCenter.lng];
        const map = useMemo(() => {
            return mapRef;
        }, [isMapExpanded]);

        const fieldPoly = useMemo(() => {
            if (areaPolygon === undefined) return undefined;
            const positions = makeLeafletPolygon(areaPolygon);
            return areaPolygon ? (
                <Polygon
                    // positions={areaPolygon.geometry.coordinates[0].map(point => L.latLng(point[1], point[0]))} // forPolygons
                    // positions={areaPolygon.geometry.coordinates.map(item =>
                    //     item[0].map(point => L.latLng(point[1], point[0]))
                    // )} // forMultiPolygons
                    positions={positions}
                    // fillColor={polygon.polygonColor}
                    // fillOpacity={fillOpacity}
                    // weight={1}
                    // color={'transparent'}
                    // onClick={onSectorClick(item.id, typeOfRouter)}
                    // onContextMenu={onRightClick(item.id, typeOfRouter)}
                >
                    {/* <Tooltip key={i}>{polygon.tooltip}</Tooltip> */}
                </Polygon>
            ) : undefined;
        }, [areaPolygon]);

        const adSpacesByDistance = useMemo(() => {
            return {
                over100: filteredAdSpaces.filter((item) => item.ad_space_type.view_max_distance > 100),
                under100: filteredAdSpaces.filter((item) => item.ad_space_type.view_max_distance <= 100),
            };
        }, [filteredAdSpaces]);

        const renderSectors = (data, isInner) => {
            return (
                <LayerGroup key={'AdSpacesInnerClusters'}>
                    <Sectors
                        data={data}
                        mode={mode}
                        typeOfRouter={'adSpaces'}
                        map={map}
                        isInner={isInner}
                        onSectorClick={onSectorClick}
                        selectedSpaces={selectedSpaces}
                        staticAdCampSelectedSpaces={staticAdCampSelectedSpaces}
                        currentZoom={currentZoom}
                    />
                </LayerGroup>
            );
        };

        const renderPins = (data) => (
            <MarkerClusterGroup
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={12}
                iconCreateFunction={createClusterCustomIcon}
                polygonOptions={{
                    weight: 1,
                }}
            >
                {renderMarkers(data, adSpaceIcon)}
            </MarkerClusterGroup>
        );

        const over100AdSpacesSectors = renderSectors(adSpacesByDistance.over100, false);
        const over100InnerSectors = renderSectors(adSpacesByDistance.over100, true);
        const over100Markers = renderPins(adSpacesByDistance.over100, true);

        const under100AdSpacesSectors = renderSectors(adSpacesByDistance.under100, false);
        const under100InnerSectors = renderSectors(adSpacesByDistance.under100, true);
        const under100Markers = renderPins(adSpacesByDistance.under100, true);

        console.log('assadasdasd');

        try {
            return (
                center && (
                    <Map
                        center={mapCenter}
                        zoom={initialZoom}
                        maxZoom={16}
                        ref={mapRef}
                        onZoom={onZoom}
                        // attributionControl={false}
                        // onMouseMove={onMapMouseMove}
                        // onClick={onMapClick}
                    >
                        {nonZeroPolygons ? (
                            <Pane name={'fieldPoly'} style={{ zIndex: 500 }}>
                                {fieldPoly}
                            </Pane>
                        ) : null}

                        {currentZoom < thresholdZoomLevel ? null : (
                            <Pane name={'over100InnerSectors'} style={{ zIndex: 603 }}>
                                {over100InnerSectors}
                            </Pane>
                        )}

                        {currentZoom < thresholdZoomLevel ? null : (
                            <Pane name={'over100AdSpacesSectors'} style={{ zIndex: 601 }}>
                                {over100AdSpacesSectors}
                            </Pane>
                        )}

                        {currentZoom < thresholdZoomLevel ? (
                            <Pane name={'over100Markers'} style={{ zIndex: 601 }}>
                                {over100Markers}
                            </Pane>
                        ) : null}

                        {currentZoom < cityThresholdZoomLevel ? null : (
                            <Pane name={'under100InnerSectors'} style={{ zIndex: 603 }}>
                                {under100InnerSectors}
                            </Pane>
                        )}

                        {currentZoom < cityThresholdZoomLevel ? null : (
                            <Pane name={'under100AdSpacesSectors'} style={{ zIndex: 601 }}>
                                {under100AdSpacesSectors}
                            </Pane>
                        )}

                        {currentZoom < cityThresholdZoomLevel ? (
                            <Pane name={'under100Markers'} style={{ zIndex: 601 }}>
                                {under100Markers}
                            </Pane>
                        ) : null}

                        {/* <AttributionControl position="bottomright" prefix="" /> */}
                        <LayersControl position="topright" onchange={(e) => console.log(e.target)}>
                            <LayersControl.BaseLayer name="Black and White" checked={true} key={`LayersControl.BaseLayer-1`}>
                                <TileLayer
                                    attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors'
                                    attributionPrefix={false}
                                    subdomains={'abcd'}
                                    ext={'png'}
                                    minZoom={0}
                                    maxZoom={17}
                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Color" key={`LayersControl.BaseLayer-2`}>
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    attributionPrefix={false}
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                        </LayersControl>

                        {/* <LayersControl position="topright">{renderMaps}</LayersControl> */}

                        {/* <Portal position="topleft">
                            <ZoomWorldControl onZoomWorldClick={onZoomWorldClick}>2</ZoomWorldControl>
                        </Portal>
                        <Portal position="bottomleft">
                            <CursorCoords>
                                {`Cursor coordinates: lat: ${cursorCoords.lat}  lng: ${cursorCoords.lng}`}
                            </CursorCoords>
                        </Portal> */}
                    </Map>
                )
            );
        } catch (error) {
            console.log(error);

            return null;
        }
    }
);

export default OutdoorMap;
