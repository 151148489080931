import React from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';

const Container = styled.div`
  display: flex;
  margin: 5px 0;
  /* border-top: 1px solid #cbc7c5; */
`;

const SelectButton = styled.div`
  cursor: pointer;
  color: rgb(50, 136, 86);
  border-bottom: 1px solid transparent;
  /* font-family: ProximaNova; */
  font-size: 11px;
  margin-right: 10px;

  &:hover {
    border-bottom: 1px solid rgb(50, 136, 86);
  }
`;

export default ({ onSelectAllClick, onClearAllClick }) => {
  return (
    <Container>
      <SelectButton onClick={onSelectAllClick}>{_t.t('выделить все')}</SelectButton>
      <SelectButton onClick={onClearAllClick}>{_t.t('очистить')}</SelectButton>
    </Container>
  );
};
