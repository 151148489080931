import React from 'react';
import styled from "styled-components/macro";

const Reports = () => {
    return (
        <div>
            <SCReportsTitle>
                Все отчеты
            </SCReportsTitle>
            <SCReportsPlanWrapper>
                Планировщик:
                <span>
                   - название планировщика
                </span>
                <span>
                    - длительность
                </span>
                <span>
                    - регулярность
                </span>
            </SCReportsPlanWrapper>
        </div>
    );
};

export default Reports;

const SCReportsTitle = styled.div`
    padding-left: 10px;
    height: 50px;
    display: grid;
    align-items: center;
`;

const SCReportsPlanWrapper = styled.div`
    padding-left: 10px;
    //height: 50px;
    display: grid;
    margin-top: 10px;
    //align-items: center;
`;