import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import moment from 'moment';
import { greenRedGradient } from '../../tools/diagramTools';
import { getInfoWithDataAspects } from '../../tools/dataAspectsTools';

const CommonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: ${p => p.size.height + 'px'}; */
    `;

const CalendarContainer = styled.div`
    font-family: 'ProximaNova';
    width: 100%;
    font-size: 10px;
    height: ${p => p.size.height + 'px'};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h4`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    width: 100%;
    font-family: 'ProximaNova';
    font-size: 12px;
    height: ${props => props.titleHeight + 'px'};
`;

const Calendar = React.memo(props => {
    const { t } = useTranslation();
    const { data } = props;
    const from = moment(data.x.values[0]).format('YYYY-MM-DD');
    const to = moment(data.x.values[data.x.values.length - 1]).format('YYYY-MM-DD');
    const containerSize = {
        width: props.size.width
    };
    const years = moment(data.x.values[data.x.values.length - 1]).year() - moment(data.x.values[0]).year() + 1;
    const CALENDAR_PROPORTIONS_FACTOR = 0.19;
    const TITLE_HEIGHT = 20;
    containerSize.height = 10 + containerSize.width * CALENDAR_PROPORTIONS_FACTOR * years - TITLE_HEIGHT * (years - 1);

    const prepareData = () => {
        const result = {};
        result.colors = greenRedGradient;
        result.titles = getInfoWithDataAspects(data.data).linesData.map(item => t(item.name));
        result.calendarData = data.data.map((item, i) => {
            const arr = [];
            item.values.forEach((value, j) => {
                if (value === 0) {
                    return;
                }
                arr.push({ day: moment(data.x.values[j]).format('YYYY-MM-DD'), value });
            });
            return arr;
        });
        return result;
    };

    const { calendarData, colors, titles } = prepareData();

    return (
        <CommonContainer size={containerSize}>
            {calendarData.map((item, i) => {
                return (
                    <Wrapper key={`ResponsiveCalendar-${i}`}>
                        <Title titleHeight={20}>{titles[i]}</Title>
                        <CalendarContainer size={containerSize}>
                            <ResponsiveCalendar
                                data={calendarData[i]}
                                from={from}
                                to={to}
                                emptyColor="#eeeeee"
                                colors={colors}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 40
                                }}
                                yearSpacing={20}
                                monthBorderColor="#ffffff"
                                monthBorderWidth={5}
                                monthLegendOffset={5}
                                dayBorderWidth={2}
                                dayBorderColor="#ffffff"
                                // legends={[
                                //   {
                                //     anchor: 'right',
                                //     direction: 'column',
                                //     translateY: 36,
                                //     itemCount: 4,
                                //     itemWidth: 34,
                                //     itemHeight: 36,
                                //     itemDirection: 'top-to-bottom'
                                //   }
                                // ]}
                            />
                        </CalendarContainer>
                    </Wrapper>
                );
            })}
        </CommonContainer>
    );
});

export default Calendar;
