import React from 'react';
import { HotTable } from '@handsontable/react';
import '../styles/handsontable.full.css';


class Table extends React.Component {
  render() {
    return (
      <HotTable
        // width={800}
        height={this.props.handsData.length > 10 ? 300 : this.props.handsData.length * 30 }
        // ref={this.TableEl}
        data={this.props.handsData}
        // colHeaders={this.state.columnNames}
        stretchH={'all'}
        // rowHeaders={' '}
        // rowHeaderWidth={30}
        autoColumnSize={true}
        wordWrap={true}
        readOnly={true}
      />
    );
  }
}

export default Table;
