const selectButton = (state, action) => {
    if (state.status === action.status) {
        return Object.assign({}, state, {
            status: state.statusShowPanelReporting ? 0 : action.status,
            statusShowPanelReporting: !state.statusShowPanelReporting
        })
    }
    else if (state.status !== action.status) {
        return Object.assign({}, state, {
            status: action.status,
            statusShowPanelReporting: true
        })
    }

};
const hidePanelRepotringObjects = (state,action) => {
    return Object.assign({}, state, {
        statusShowPanelReporting:false,
        status: 0
    })
}

let stateInit = {
    status: 0,
    statusShowPanelReporting:false
};

const ProjectLocationApp = (state=stateInit, action) => {
    switch (action.type) {
        case 'selectPL':
            return selectButton(state, action);
        case 'hidePanelRepotringObjects':
            return hidePanelRepotringObjects(state,action);
        default:
            return state;
    }
};
export default ProjectLocationApp;