import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const TooltipContainer = styled.div`
    background-color: whitesmoke;
    opacity: 0.9;
`;

const TooltipHeader = styled.div`
    background-color: #777;
    color: whitesmoke;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
`;

const Value = styled.b`
    font-family: 'ProximaNova';
    /* font-size: 16px; */
`;

const TooltipData = styled.p`
    margin: 0;
    padding: 3px 10px;
    padding-left: 18px;
    font-size: 12px;
    border-top: 1px solid #777;
    position: relative;
    ::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: 5px;
        left: 5px;
        background-color: ${(p) => p.color};
        border-radius: 50%;
    }
`;

const NUMBER_TO_CHANGE_TOOLTIP = 10;

const CustomTooltip = React.memo(({ tooltip, chartId, payload, label, dataType, lines, isAggregation, ...props }) => {
    const { t } = useTranslation();
    if (!tooltip) return null;
    if (isAggregation && tooltip) {
        return (
            <TooltipContainer>
                {tooltip
                    .filter((item) => item.value !== undefined && item.value !== null)
                    .map((item) => (
                        <TooltipData color={item.color} key={`tooltip-${item.dataKey}-${chartId}`}>
                            {`${t(item.dataKey)}: `}
                            <Value>{`${item.x} | ${item.value}`}</Value>
                        </TooltipData>
                    ))}
            </TooltipContainer>
        );
    } else if (payload && payload.length <= NUMBER_TO_CHANGE_TOOLTIP) {
        const names = lines.filter((item) => item.show).map((item) => item.name);
        return (
            <TooltipContainer>
                {label && <TooltipHeader>{label}</TooltipHeader>}
                {payload
                    .filter((item) => names.includes(item.name))
                    .map((item) => (
                        <TooltipData color={item.color} key={`payload-${item.name}-${chartId}`}>
                            {`${item.name}: `}
                            <Value>{item.payload.value}</Value>
                        </TooltipData>
                    ))}
            </TooltipContainer>
        );
    } else if (tooltip && tooltip[0]) {
        return (
            <TooltipContainer>
                {tooltip[0].x && <TooltipHeader>{tooltip[0].x}</TooltipHeader>}
                {tooltip.map((item) => (
                    <TooltipData color={item.color} key={`tooltip-${item.dataKey}-${chartId}`}>
                        {`${t(item.dataKey)}: `}
                        <Value>{item.value}</Value>
                    </TooltipData>
                ))}
            </TooltipContainer>
        );
    } else {
        return null;
    }
});

export default CustomTooltip;
