import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import cloneDeep from 'lodash/cloneDeep';
import { runAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import Tree from './components/tree';
import CustomDetailsList from './components/custom_details_list';
import { getAdCampaigns } from '../../api/api';
import Tools from '../../tools/tools';

const AdCampaigns = React.memo((props) => {
    const paramsSet = Tools.useGetParamSet();
    const [showTree, setShowTree] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(true);
    const [theme] = useState(getTheme());
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { isAdCampShown, singleSelection, projectSearchValue, tree, multiSelection, campaignsToShow, addCampaignsNumber } = useSelector(
        (state) => state.AdCampaigns
    );
    const { lang } = useSelector((state) => state.TopPanel);
    const { options } = useSelector((state) => state.LoginApp);
    // const { adCampaignsById, outdoorAdSpacesById } = useSelector(state => state.ReportParametersManager.Source.ctg);
    const { ad_campaigns, outdoor_ad_spaces } = useSelector((state) => state.ReportParametersManager.Source.src);
    const { availableOwnersTree, availableProjectsById, availableOwnersById } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );
    const { customInputParamsValues } = useSelector((state) => state.ReportParametersManager);
    const { owner_id, project_id } = customInputParamsValues;

    // useEffect(() => {
    //     let adCampInterval;
    //     if (cabinetMode !== '-pdf') {
    //         adCampInterval = setInterval(() => getAdCampaigns(token, dispatch, campaignsToShow), CHECK_AD_CAMPAIGNS_INTERVAL);
    //     }
    //     return () => {
    //         clearInterval(adCampInterval);
    //     };
    // }, [token, cabinetMode, campaignsToShow]);

    useEffect(() => {
        if (options.includes('role:superuser')) {
            setShowTree(true);
            // setShowCreateNew(false);
            if (tree.ownersExpand === null) {
                feelTree(cloneDeep(availableOwnersById));
            }
        } else {
            if (ad_campaigns === undefined) {
                // getAdCampaigns(token, dispatch, campaignsToShow);
            }
        }
    }, [availableOwnersById, options]);

    const catalog = [
        // { id: 'ID' },
        { select: '' },
        { name: 'Name' },
        { updated: 'Updated' },
        { progress_status: 'Status' },
        { campaign_type: 'Type' },
        { date_from: 'From' },
        { date_to: 'To' },
        // { delete: '' }
    ];

    const columns = useMemo(() => {
        return catalog.map((item, i) => {
            return {
                key: Object.keys(item)[0],
                name: item[Object.keys(item)[0]],
                fieldName: Object.keys(item)[0],
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
            };
        });
    }, [ad_campaigns]);

    const onCreateNewClick = () => {
        toggleModal({
            show: true,
            modalType: 'new_ad_campaign',
            modalTitle: t('Создание новой рекламной кампании'),
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: { lang, customInputParamsValues, outdoor_ad_spaces },
            },
        });
    };

    const addIcon = { iconName: 'Add' };

    // ------------ DISPATCH ------------

    const feelTree = (ownersExpand) => {
        dispatch(runAction('feelTree_AdCampaigns', { ownersExpand }));
    };

    const toggleExpandArrow = (id) => {
        dispatch(runAction('toggleExpandArrow_AdCampaigns', { id }));
    };

    const onSelectClick = (args) => {
        dispatch(runAction('onSelectClick_AdCampaigns', { args, paramsSet }));
    };

    const toggleModal = (args) => {
        dispatch(runAction('toggleModal', { args }));
    };

    const onShowMoreClick = () => {
        dispatch(runAction('onShowMoreClick_AdCampaigns', {}));
    };
    const onChangeProjectSearchValue = (e, projectSearchValue) => {
        dispatch(runAction('onChangeProjectSearchValue_AdCampaigns', { projectSearchValue }));
    };

    const changeProjectId = (project_id) => {
        dispatch(runAction('changeProjectId_RPM', { project_id }));
        onSelectClick({ id: null });
    };

    const resetProjectId = () => {
        dispatch(runAction('changeProjectId_RPM', { project_id: undefined }));
        dispatch(runAction('toggleExpandArrow_AdCampaigns', { id: 1, show: false }));
        onSelectClick({ id: null });
    };

    // ------------ DISPATCH ------------

    const selectedProjectName = () => {
        if (!showTree) return;
        if (!project_id) {
            return t('Нет выбранного проекта');
        } else {
            return availableProjectsById[project_id].name;
        }
    };

    const delIcon = { iconName: 'Cancel' };

    return ad_campaigns ? (
        <MainContainer isAdCampShown={isAdCampShown}>
            <TitleWrapper>
                <Title>
                    <h2>{t('Рекламные кампании')}</h2>
                </Title>
                {showCreateNew ? (
                    <StDefaultButton iconProps={addIcon} onClick={onCreateNewClick}>
                        {t('Создать новую рекламную кампанию')}
                    </StDefaultButton>
                ) : null}
            </TitleWrapper>

            {showTree && tree.ownersExpand ? (
                <TreeWrapper>
                    <Tree ownersExpand={tree.ownersExpand} toggleExpandArrow={toggleExpandArrow} changeProjectId={changeProjectId} />
                    <StTextField
                        // label={t('Поиск по имени проекта')}
                        placeholder={t('Поиск по имени проекта')}
                        value={projectSearchValue}
                        onChange={onChangeProjectSearchValue}
                        // styles={{ fieldGroup: { width: 300 } }}
                    />
                    <CurrentProject>
                        <Label>{selectedProjectName()}</Label>
                        <IconButton iconProps={delIcon} onClick={resetProjectId} />
                        {/* <StIcon iconName="Cancel" onClick={changeProjectId(undefined)} /> */}
                    </CurrentProject>
                </TreeWrapper>
            ) : null}

            <CustomDetailsList
                items={ad_campaigns}
                columns={columns}
                toggleModal={toggleModal}
                onSelectClick={onSelectClick}
                singleSelection={singleSelection}
                multiSelection={multiSelection}
                paramsSet={paramsSet}
            />
            {campaignsToShow <= ad_campaigns.length ? (
                <StLabel disabled={false} color={`${theme.palette.themeDark}`} onClick={onShowMoreClick}>
                    {`${t('Показать еще')} ${addCampaignsNumber}`}
                </StLabel>
            ) : null}
        </MainContainer>
    ) : null;
});

export default AdCampaigns;

const Title = styled.div`
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #99ccae;
    padding-bottom: 5px;
    margin: 0;
    h2 {
        box-sizing: border-box;
        font-family: ProximaNova;
        font-size: 17px;
        color: rgb(36, 49, 56);
        padding: 0;
        margin: 0;
    }
`;

const StTextField = styled(TextField)`
    height: 40px !important;
    div {
        height: 40px !important;
    }
`;

const StIcon = styled(FontIcon)`
    font-size: 24px;
    color: red;
    cursor: pointer;
`;

const CurrentProject = styled.div`
    height: 40px !important;
    border: 1px solid rgb(138, 136, 134);
    border-radius: 2px;
    /* width: 100%; */
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const StLabel = styled(Label)`
    width: 100%;
    background-color: inherit;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    color: ${(p) => p.color};
    /* padding: 10px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    :focus {
        outline: none;
    }
`;

const MainContainer = styled.section`
    padding: 10px;
    /* overflow: auto; */
    background-color: #eee;
    /* z-index: 201; */
    position: absolute;
    left: 238px;
    top: 0;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    z-index: ${(p) => (p.isAdCampShown ? '400' : '-200')};
    /* align-items: center; */
    flex-shrink: 0;
    width: calc(100% - 238px);
    /* height: calc(100vh - 50px); */
    height: 100vh;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    .ms-DetailsRow-cell {
        display: flex !important;
        align-items: center !important;
    }
`;

const TitleWrapper = styled.div`
    box-sizing: border-box;
    /* padding: 10px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const TreeWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
`;

const Delete = styled.div`
    cursor: pointer;
`;

const StDefaultButton = styled(DefaultButton)`
    flex-shrink: 0;
    margin-left: 10px;
`;
