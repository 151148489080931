import React, {useEffect, useState} from 'react';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import styled from "styled-components/macro";
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import {PrimaryButton} from "@fluentui/react/lib/Button";
import hostForLocation from "../../../hostnames/hostname";
import {useDispatch, useSelector} from "react-redux";
import {runAction} from "../../../actions";
import Cookies from 'universal-cookie';
import moment from 'moment';
import {useTranslation} from "react-i18next";

const cookie = new Cookies();





const BoxParamsPreCamp = ({statusLeftPanel}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        selectedSpaces
    } = useSelector(
        (state) => state.OutdoorSpaces
    );

    const {
        outdoor_ad_spaces
    } = useSelector(
        (state) => state.ReportParametersManager.Source.src
    );

    const {
        outdoorAdSpacesById
    } = useSelector(
        (state) => state.ReportParametersManager.Source.ctg
    );




    const {
        datePeriod
    } = useSelector(
        (state) => state.PreCampaigns
    );

    const {
        region_id
    } = useSelector(
        (state) => state.ReportParametersManager.customInputParamsValues
    );

    const [value, setValue] = useState(
        dayjs(moment().toISOString()),
    );
    const [value2, setValue2] = useState(
        dayjs(moment().toISOString()),
    );
    const [advDuration, satAdvDuration] = useState(0)
    const [advBlockDuration, satAdvBlockDuration] = useState(0)
    const [isShowDigitalPanel, setIsShowDigitalPanel] = useState(false)



    const options = [
        { key: 'fruitsHeader', text: 'Fruits', itemType: DropdownMenuItemType.Header },
        { key: 'apple', text: 'Apple' },
        { key: 'banana', text: 'Banana' },
        { key: 'orange', text: 'Orange', disabled: true },
        { key: 'grape', text: 'Grape' },
        { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
        { key: 'vegetablesHeader', text: 'Vegetables', itemType: DropdownMenuItemType.Header },
        { key: 'broccoli', text: 'Broccoli' },
        { key: 'carrot', text: 'Carrot' },
        { key: 'lettuce', text: 'Lettuce' },
    ];



    

    const getProcess = async () => {
        
        const url = `${hostForLocation.getHostName().precampaign}/precampaign_reports/sots_detailed_dynamics`
        const url2 = `${hostForLocation.getHostName().precampaign}/precampaign_reports/frequency_hist`
        const url3 = `${hostForLocation.getHostName().precampaign}/precampaign_reports/unique_mac`
        const data = {
            "predict_to": moment(datePeriod.endDate).format(
                'YYYY-MM-DD'
            ),
            "predict_from": moment(datePeriod.startDate).format(
                'YYYY-MM-DD'
            ),
            // "ad_space_id_array": selectedSpaces,
            "ad_space_id_array": selectedSpaces,
            "is_digital": false,
            "region_id": region_id
        }
        const token = cookie.get('xtoken');

        let outdoorAdSpaces = []
        let outdoorAdSpacesByKey = {}

        selectedSpaces.forEach((item) => {
            const space = outdoor_ad_spaces.filter((adSpace) => adSpace.id === item)[0];
            outdoorAdSpacesByKey[space.id] = space
            outdoorAdSpaces.push(space)
        })


        // console.log('><>>>><><<<<', region_id);
        dispatch(runAction('getIsLoad_PreCampaigns', { status: true }));
        dispatch(runAction('getIsLoadUniq_PreCampaigns', { status: true }));
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                body: JSON.stringify(data),
                // mode: 'cors',
                redirect: 'follow',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token

                }
            });
            const json = await response.json();
            console.log('Успех:',json);

            if(json.non_field_errors) {
                alert(json.non_field_errors[0])
            }
            dispatch(runAction('getSotsDetailedDynamics_PreCampaigns', { json,  selectedSpaces, outdoorAdSpaces, outdoorAdSpacesByKey, outdoorAdSpacesById}));

            
            try {
                
                const response2 = await fetch(url2, {
                    method: 'POST', // или 'PUT'
                    body: JSON.stringify(data),
                    // mode: 'cors',
                    redirect: 'follow',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token': token
    
                    }
                });
                const json2 = await response2.json();
                console.log('Успех:',json2);
                if(json2.non_field_errors) {
                    alert(json2.non_field_errors[0])
                }
                dispatch(runAction('getAccumulativeFrequencyHist_PreCampaigns', { json2, selectedSpaces }));
                dispatch(runAction('getIsLoad_PreCampaigns', { status: false }));
                try {
                    const response3 = await fetch(url3, {
                        method: 'POST', // или 'PUT'
                        body: JSON.stringify(data),
                        mode: 'cors',
                        redirect: 'follow',
                        cache: 'no-cache',
                        timeout: 600000,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-token': token
        
                        }
                    });
                    
                    dispatch(runAction('getIsLoadUniq_PreCampaigns', { status: false }));
                    const json3 = await response3.json();
                    setTimeout(() => {
                        console.log('><>><><><>',json3);
                    }, 3000)
                    if(json3.non_field_errors) {
                        alert(json3.non_field_errors[0])
                    }
                    console.log('Успех:',json3);
                    dispatch(runAction('getUniqueMac_PreCampaigns', { json3, selectedSpaces }));
                } catch (error) {
                    console.error('Ошибка:', error);
                    dispatch(runAction('getIsLoadUniq_PreCampaigns', { status: false }));
                    alert(error)
                }

            } catch (error) {
                console.error('Ошибка:', error);
                dispatch(runAction('getIsLoadUniq_PreCampaigns', { status: false }));
                alert(error)
            }

        } catch (error) {
            console.error('Ошибка:', error);
            dispatch(runAction('getIsLoadUniq_PreCampaigns', { status: false }));
            alert(error)
        }
    }

    const saveReport = async () => {
        const url = `${hostForLocation.getHostName().precampaign}/precampaign_reports/digital_report`
        const data = {
            "predict_to":dayjs(value2).format('YYYY-MM-DD'),
            "predict_from": dayjs(value).format('YYYY-MM-DD'),
            "ad_space_id_array": selectedSpaces,
            "is_digital": true,
            "region_id": region_id,
            "adv_duration":advDuration,
            "block_duration":advBlockDuration,
            "adv_start":dayjs(value).format('YYYY-MM-DD HH:mm:ss') ,
            "adv_finish":dayjs(value2).format('YYYY-MM-DD HH:mm:ss') ,
        }
        const token = cookie.get('xtoken');
        try {
            const response3 = await fetch(url, {
                method: 'POST', // или 'PUT'
                body: JSON.stringify(data),
                mode: 'cors',
                redirect: 'follow',
                cache: 'no-cache',
                timeout: 600000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token

                }
            });

            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            const json3 = await response3.json();
            setTimeout(() => {
                console.log('><>><><><>', json3);
            }, 3000)
            if (json3.non_field_errors) {
                alert(json3.non_field_errors[0])
            }
            else {
                alert(t('Отчет начал формироваться. Следите за статусом отчета.'))
            }

            console.log('Успех:', json3);
            

            // dispatch(runAction('getUniqueMac_PreCampaigns', {json3, selectedSpaces}));
        } catch (error) {
            console.error('Ошибка:', error);
            dispatch(runAction('getIsLoadUniq_PreCampaigns', {status: false}));
            alert(error)
        }
    }


    const onChangeAdvDur = (e) => {
        satAdvDuration(e.target.value)
    }
    const onChangeAdvBlockDur = (e) => {
        satAdvBlockDuration(e.target.value)
    }

    const onClickDigit = () => {
        setIsShowDigitalPanel(!isShowDigitalPanel)
    }


    return (
        <DivBoxDropdownSC>

            { statusLeftPanel === 'pre_campaign_static' ?
                <DivBoxButtonSC>
                    <PrimaryButton
                        text={t("Прогноз")}
                        onClick={getProcess}
                        allowDisabledFocus
                        // disabled={disabled}
                        // checked={checked}
                    />
                </DivBoxButtonSC> : null
            }

            {/*<DivBoxButtonSC>*/}
            {/*    <PrimaryButton*/}
            {/*        text={t(isShowDigitalPanel ? 'Закрыть цифровые параметры' : "Открыть цифровые параметры")}*/}
            {/*        onClick={onClickDigit}*/}
            {/*        allowDisabledFocus*/}
            {/*        // disabled={disabled}*/}
            {/*        // checked={checked}*/}
            {/*    />*/}
            {/*</DivBoxButtonSC>*/}
            {
                statusLeftPanel === 'pre_campaign_dynamic' ?
                    <DivBoxTimeSC>
                        <TextField
                            id="standard-number"
                            label={t("Длина рекламного ролика, сек")}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            onChange={onChangeAdvDur}
                            value={advDuration}
                        />
                        <TextField
                            id="standard-number"
                            label={t("Длина рекламного блока, сек")}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            onChange={onChangeAdvBlockDur}
                            value={advBlockDuration}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                label={t("Начало РК")}
                                value={value}
                                ampm={false}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                onAccept={(newValue) => {
                                    setValue(newValue);
                                }}
                            />
                            <MobileDateTimePicker
                                label={t("Конец РК")}
                                value={value2}
                                ampm={false}
                                onChange={(newValue) => {
                                    setValue2(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                onAccept={(newValue) => {
                                    setValue2(newValue);
                                }}
                            />
                        </LocalizationProvider>
                        <PrimaryButton
                            text={t("Заказать отчет")}
                            onClick={saveReport}
                            allowDisabledFocus
                            // disabled={disabled}
                            // checked={checked}
                        />
                    </DivBoxTimeSC> :
                    null
            }



        </DivBoxDropdownSC>
    );
};

export default BoxParamsPreCamp;

const LabelCustomFileUpload = styled.label`
  border: 1px solid #ccc;
  display: grid;
  padding: 6px 12px;
  cursor: pointer;
  height: 87%;
  border-radius: 4px;
  grid-template-columns: 100%;

  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
`;

const DivBoxTimeSC = styled.div`
  display: grid;
  width: max-content;
  height: max-content;
  grid-template-columns: repeat(5, minmax(180px, max-content));
  grid-template-rows: max-content;
  gap: 10px;
  //background: rgba(255,255,255,1);
  
  //padding: 16px;
  box-sizing: border-box;
  //box-shadow: rgba(0,0,0,0.1) 0px 0px 5px;
`

const DivFilePreview = styled.div`
  margin: 0 10px;
`;

const InputTypeFileCustom = styled.input`
  display: none;
`

const StyledDropdown = styled(Dropdown)`
    .ms-Dropdown-label {
        padding-top: 0;
        color: #827f7d;
    }
    .ms-Dropdown-title {
        border-color: rgba(138, 136, 134, 0.5) !important;
    }
`;

const DivBoxDropdownSC = styled.div`
    display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
  gap: 10px;
  padding-bottom: 11px;
  position: relative;
`;

const DivBoxFilePreview = styled.div`
  position: absolute;
  //outline: #0c5460 1px solid;
  top: 1px;
  left: 100%;
`

const DivBoxFileInput = styled.div`
    position: relative;
`

const DivBoxButtonSC = styled.div`
  //padding: 16px;
  display: grid;
  //justify-items: center;
  align-items: center;
  width: 206px;
`