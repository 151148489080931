import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { calculateAverage, calculateTrend } from '../../tools/diagramTools';
import useParentSize from '../../tools/use_parent_size';
import { getInfoWithDataAspects } from '../../tools/dataAspectsTools';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer } from 'recharts';

import AvgLineLabel from '../average_line_label';
import CustomLegend from '../custom_legend';
import CustomTooltip from '../custom_tooltip';


const DataViewDynamics = React.memo((props) => {
    const { t } = useTranslation();
    const targetRef = useRef();
    const [lines, setLines] = useState([]);
    const [longestTimeseries, setLongestTimeseries] = useState([]);
    const [tooltip, setTooltip] = useState([]);
    const [shift, setShift] = useState(0);
    const [maxShiftsNumber, setMaxShiftsNumber] = useState(null);
    const [isShiftable, setIsShiftable] = useState(false);
    const [isAggregation, setIsAggregation] = useState(false);
    const [longestAxes, setLongestAxes] = useState('bottom');

    const parentDimensions = useParentSize(targetRef, props.fullScreenShow);

    useEffect(() => {
        const shift =
            props.diagramInnerParams.newShift && props.diagramInnerParams.newShift[props.dataView.data_view_id]
                ? props.diagramInnerParams.newShift[props.dataView.data_view_id]
                : 0;
        setShift(shift);
        const data = getInfoWithDataAspects(props.data.data, props.dataView.data_report, props.inputParamsValues, shift, props.colors);

        const lines = [...data.linesData];

        const maxShiftsNumber = data.maxShiftsNumber || 1;
        const isShiftable = data.isShiftable || false;
        const isAggregation = data.isAggregation || false;
        const longestAxes = data.longestAxes || 'bottom';
        const longestTimeseries = [...data.longestTimeseries];
        setLines(() => lines);
        setMaxShiftsNumber(maxShiftsNumber);
        setIsShiftable(isShiftable);
        setIsAggregation(isAggregation);
        setLongestAxes(longestAxes);
        setLongestTimeseries(() => longestTimeseries);
    }, [props.data, props.dataView, props.lang, props.diagramInnerParams.newShift]);

    //------------------------------

    let color = {};
    var weekends = [];
    var arrDate = [];

    for (
        var g = new Date(longestTimeseries[0]).getTime();
        g < new Date(longestTimeseries[longestTimeseries.length - 1]).getTime();
        g = new Date(moment(g).set('hour', 24)).getTime()
    ) {
        arrDate.push(g);
    }

    var obj = {};
    const startWeekend = props.inputParamsValues.time_detalization === 'hourly' ? 6 : 5;
    for (var j = 0; j < longestTimeseries.length; j++) {
        for (var h = 0; h < arrDate.length; h++) {
            if (moment(arrDate[h]).day() === startWeekend) {
                if (obj[new Date(arrDate[h]).getTime()] === undefined) {
                    obj[new Date(arrDate[h]).getTime()] = longestTimeseries[j];
                    weekends.push({
                        start: moment(arrDate[h]).format('YYYY-MM-DD') + ' 00:00:00',
                        end: moment(arrDate[h]).set('hour', 24).format('YYYY-MM-DD') + ' 00:00:00',
                    });
                    weekends.push({
                        start: moment(arrDate[h]).set('hour', 24).format('YYYY-MM-DD') + ' 00:00:00',
                        end:
                            props.inputParamsValues.time_detalization === 'hourly'
                                ? moment(arrDate[h]).set('hour', 24).format('YYYY-MM-DD') + ' 23:00:00'
                                : moment(arrDate[h]).set('hour', 48).format('YYYY-MM-DD') + ' 00:00:00',
                    });
                }
            }
        }
    }

    const avgLines =
        lines.length === 0
            ? undefined
            : lines
                  .filter((item) => item.show)
                  .map((item) => {
                      const rangeName = item.name;
                      const value = calculateAverage(item.x);
                      const attributes =
                          rangeName === 'Отчетный период'
                              ? {
                                    text: 'avgMain',
                                    color: 'rgb(60, 100, 153)',
                                    position: 'end',
                                    xAxisId: 'bottom',
                                }
                              : rangeName === 'Период сравнения'
                              ? {
                                    text: 'avgCompare',
                                    color: 'rgb(244, 155, 16)',
                                    position: 'start',
                                    xAxisId: 'top',
                                }
                              : {
                                    text: item.name,
                                    color: item.color,
                                    position: 'start',
                                    xAxisId: 'top',
                                };

                      return { value, ...attributes };
                  });

    const trendLines =
        lines.length === 0
            ? undefined
            : lines
                  .filter((item) => item.show)
                  .map((item) => {
                      const rangeName = item.name;
                      const lines = calculateTrend(item.x);
                      const attributes =
                          rangeName === 'Отчетный период'
                              ? {
                                    text: 'trendMain',
                                    color: 'rgb(60, 100, 153)',
                                    position: 'end',
                                    xAxisId: 'bottom',
                                }
                              : rangeName === 'Период сравнения'
                              ? {
                                    text: 'terndCompare',
                                    color: 'rgb(244, 155, 16)',
                                    position: 'start',
                                    xAxisId: 'top',
                                }
                              : {
                                    text: item.name,
                                    color: item.color,
                                    position: 'start',
                                    xAxisId: 'top',
                                };

                      return { lines, ...attributes };
                  });

    const showAverages = props.indicators.filter((item) => item.name === 'Средняя линия')[0].show;
    const showTrend = props.indicators.filter((item) => item.name === 'Тренд')[0].show;

    const locationsNamesLength = lines.reduce((acc, item) => {
        return acc + item.name.length;
    }, 0);
    const widthInPx = parentDimensions.width;
    const density = 0.14;
    const MAX_NUMBER_OF_STRINGS = 5;
    const numberOfStrings = Math.ceil(locationsNamesLength / (widthInPx * density));

    const pixelsToHeightIncrease =
        numberOfStrings > 1 && numberOfStrings < MAX_NUMBER_OF_STRINGS
            ? numberOfStrings * 15.6
            : numberOfStrings >= MAX_NUMBER_OF_STRINGS
            ? MAX_NUMBER_OF_STRINGS * 15.6 - 20
            : 32;

    const ratio = props.size.height / props.size.width;
    const dataViewSize = props.fullScreenShow
        ? {
              width: parentDimensions.width,
              height: props.keepRatio ? parentDimensions.width * ratio + pixelsToHeightIncrease : parentDimensions.height - 46,
          }
        : {
              width: props.size.width,
              height: props.size.height - pixelsToHeightIncrease,
          };

    const chartId = props.dataView.data_view_id;

    //------------------------------

    const axisXMainStyle = {
        fontSize: 10,
        fill: isAggregation ? 'rgb(60, 100, 153)' : '#000',
    };
    const axisXComparisonStyle = {
        fontSize: 10,
        fill: isAggregation ? 'rgb(244, 155, 16)' : '#000',
    };
    const axisYStyle = {
        fontSize: 10,
    };

    const XAxisInterval = lines[0] && lines[0].x.length > 10 ? 'preserveEnd' : 0;
    const margin = { top: -10, right: 20, bottom: 0, left: -20 };

    const onLegendItemClick = (line) => {
        const l = lines.map((item) => {
            return item.name !== line.name ? item : { ...item, show: !item.show };
        });
        setLines(() => l);
    };

    const onLegendItemEnter = (line) => {
        const l = lines.map((item) => {
            return item.name === line.name ? { ...item, dim: false } : { ...item, dim: true };
        });
        setLines(() => l);
    };

    const onLegendItemOut = () => {
        const l = lines.map((item) => {
            return { ...item, dim: false };
        });
        setLines(() => l);
    };

    const customMouseOver = (lines, line) => (e) => {
        const { payload, value, fill } = e;

        const tooltip = [];
        if (!isAggregation) {
            tooltip.push({
                dataKey: line.name,
                value,
                x: payload.x,
                color: fill,
            });
        } else {
            const index = e.index;
            lines
                .filter((item) => item.show)
                .forEach((item) => {
                    tooltip.push({
                        dataKey: item.name,
                        value: item.x[index].value,
                        x: item.x[index].x,
                        color: item.color,
                    });
                });
        }

        setTooltip(() => tooltip);
    };

    const customMouseOut = () => (e) => {
        setTooltip(() => null);
    };

    const onShiftClick = (newShift) => (e) => {
        // console.log(newShift, maxShiftsNumber);

        if (newShift <= 0 && newShift > -maxShiftsNumber) {
            props.diagramInnerAction('onShiftClick', newShift, props.dataView.data_view_id, props.dataViewType);
        }
    };

    const legend = useMemo(() => {
        return (
            <Legend
                height={pixelsToHeightIncrease + 10}
                content={
                    <CustomLegend
                        height={pixelsToHeightIncrease}
                        isAggregation={isAggregation}
                        onLegendItemClick={onLegendItemClick}
                        onLegendItemEnter={onLegendItemEnter}
                        onLegendItemOut={onLegendItemOut}
                        lines={lines}
                        showAverages={showAverages}
                        avgLines={avgLines}
                        chartId={chartId}
                        // data={data}
                    />
                }
                wrapperStyle={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 10,
                    boxSizing: 'border-box',
                    left: 0,
                }}
                style={axisYStyle}
            />
        );
    }, [pixelsToHeightIncrease, lines, showAverages]);
    return useMemo(() => {
        return (
            <Container ref={targetRef}>
                <ResponsiveContainer width={'100%'} height={dataViewSize.height - 7}>
                    <LineChart
                        margin={margin}
                        // width={'100%'}
                        height={dataViewSize.height - 7}
                    >
                        {weekends.map((item) => {
                            // console.log(longestAxes);

                            return (
                                <ReferenceArea
                                    xAxisId={longestAxes}
                                    key={`ReferenceArea-${item.start}-${chartId}`}
                                    x1={item.start}
                                    x2={item.end}
                                    fill="red"
                                    fillOpacity={0.1}
                                    stroke="red"
                                    strokeOpacity={0}
                                />
                            );
                        })}

                        <Tooltip
                            filterNull={false}
                            content={
                                <CustomTooltip
                                    tooltip={tooltip}
                                    chartId={chartId}
                                    dataViewType={props.dataViewType}
                                    isAggregation={isAggregation}
                                    lines={lines}
                                />
                            }
                        />
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            xAxisId="bottom"
                            orientation="bottom"
                            padding={{ right: 10 }}
                            dataKey="x"
                            type="category"
                            // allowDuplicatedCategory={false}
                            allowDuplicatedCategory={isAggregation}
                            interval={XAxisInterval}
                            tickFormatter={(timeStr) => moment(timeStr).format('YYYY-MM-DD')}
                            style={axisXMainStyle}
                            // tick={<CustomizedAxisTick />}
                        />
                        <XAxis
                            xAxisId="top"
                            orientation="top"
                            padding={{ right: 10 }}
                            dataKey="x"
                            type="category"
                            // allowDuplicatedCategory={false}
                            allowDuplicatedCategory={isAggregation}
                            interval={XAxisInterval}
                            tickFormatter={(timeStr) => moment(timeStr).format('YYYY-MM-DD')}
                            style={axisXComparisonStyle}
                            // tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                            dataKey={'value'}
                            scale="linear"
                            padding={{ top: 10, bottom: 10 }}
                            type="number"
                            interval={0}
                            domain={['dataMin', 'dataMax']}
                            style={axisYStyle}
                            tickSize={10}
                        />

                        {legend}

                        {lines
                            // .filter((item) => item.show)
                            .map((line) => {
                                const activeDot = {
                                    r: line.show ? 4 : 0,
                                    onMouseOver: customMouseOver(lines, line),
                                    onMouseOut: customMouseOut(),
                                };
                                return (
                                    <Line
                                        xAxisId={line.xAxisId}
                                        dot={
                                            line.x.length > 200
                                                ? false
                                                : {
                                                      strokeWidth: 0,
                                                      r: 2,
                                                      fill: line.color,
                                                      fillOpacity: line.dim ? 0.2 : 1,
                                                  }
                                        }
                                        key={`Line-${line.name}-${chartId}`}
                                        isAnimationActive={false}
                                        // animationDuration={300}
                                        type="monotone"
                                        dataKey={'value'}
                                        name={line.name}
                                        data={line.x}
                                        stroke={line.color}
                                        opacity={line.show ? 1 : 0}
                                        strokeWidth={1}
                                        activeDot={activeDot}
                                        strokeOpacity={line.dim ? 0.2 : 1}
                                    />
                                );
                            })}

                        {trendLines &&
                            showTrend &&
                            trendLines.map((line) => {
                                return (
                                    <Line
                                        xAxisId={`trend-${line.xAxisId}`}
                                        strokeDasharray="5 5"
                                        dataKey={'value'}
                                        data={line.lines}
                                        name={line.text}
                                        dot={false}
                                        key={`TrendLine-${line.text}-${chartId}`}
                                        animationDuration={300}
                                        type="linear"
                                        stroke={line.color}
                                        strokeWidth={1.5}
                                        // activeDot={{ r: 6, onMouseOver: customMouseOver(lines) }}
                                        // strokeOpacity={line.dim ? 0.2 : 1}
                                    />
                                );
                            })}
                        {trendLines &&
                            showTrend &&
                            trendLines.map((line) => {
                                return (
                                    <XAxis
                                        hide={true}
                                        xAxisId={`trend-${line.xAxisId}`}
                                        orientation="top"
                                        padding={{ right: 10 }}
                                        dataKey="x"
                                        type="category"
                                        key={`TrendLineAxis-${line.text}-${chartId}`}
                                        // allowDuplicatedCategory={false}
                                        allowDuplicatedCategory={isAggregation}
                                        interval={XAxisInterval}
                                        tickFormatter={(timeStr) => moment(timeStr).format('YYYY-MM-DD')}
                                        style={axisXComparisonStyle}
                                        // tick={<CustomizedAxisTick />}
                                    />
                                );
                            })}
                        {avgLines &&
                            showAverages &&
                            avgLines.map((item) => {
                                return (
                                    <ReferenceLine
                                        xAxisId={item.xAxisId}
                                        key={`ReferenceLine-${item.color}-${chartId}`}
                                        stroke={item.color}
                                        y={Number(item.value)}
                                        label={<AvgLineLabel color={item.color} position={item.position} value={item.value} />}
                                    />
                                );
                            })}
                    </LineChart>
                </ResponsiveContainer>
                {isShiftable ? (
                    <ArrowsContainer>
                        {/* {shift} */}
                        <I className="fas fa-arrow-circle-left" onClick={onShiftClick(shift - 1)} active={shift > -maxShiftsNumber + 1} />
                        <I className="fas fa-arrow-circle-right" onClick={onShiftClick(shift + 1)} active={shift < 0} />
                    </ArrowsContainer>
                ) : null}
            </Container>
        );
    }, [dataViewSize.height, lines, showTrend, showAverages, tooltip]);
});

export default DataViewDynamics;

const Container = styled.div`
    position: relative;
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
`;

const ArrowsContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
`;

const I = styled.i`
    cursor: pointer;
    margin: 0 2px;
    color: ${(p) => (p.active ? '#999' : '#ddd')};
    pointer-events: ${(p) => (p.active ? 'auto' : 'none')};
`;
