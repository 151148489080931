import L from 'leaflet';

function isMarkerInsidePolygon(marker, poly) {
    var inside = false;
    var x = marker.getLatLng().lat,
        y = marker.getLatLng().lng;
    for (var ii = 0; ii < poly.getLatLngs().length; ii++) {
        var polyPoints = poly.getLatLngs()[ii];
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat,
                yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat,
                yj = polyPoints[j].lng;

            var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
        }
    }

    return inside;
}

export const getPolygonCenter = areaPolygon => {
    const poly = L.polygon(makeLeafletPolygon(areaPolygon));
    return poly.getBounds().getCenter();
};

export const makeLeafletPolygon = areaPolygon => {
    return areaPolygon.geometry.type === 'Polygon'
        ? areaPolygon.geometry.coordinates[0].map(point => L.latLng(point[1], point[0]))
        : areaPolygon.geometry.coordinates.map(arr =>
              arr[0].map(point => L.latLng(point[1], point[0]))
          );
};

export const polygonsFilter = (outdoor_ad_spaces, areaPolygon) => {
    if (!outdoor_ad_spaces) return undefined;
    let polygonsFilteredAdSpaces = [...outdoor_ad_spaces];

    if (areaPolygon) {
        polygonsFilteredAdSpaces = [];

        var polygon = L.polygon(makeLeafletPolygon(areaPolygon));

        const markers = outdoor_ad_spaces.map(item => {
            var latlng = L.latLng(item.latitude, item.longitude);
            return L.marker(latlng);
        });

        markers.forEach((marker, i) => {
            if (isMarkerInsidePolygon(marker, polygon)) {
                polygonsFilteredAdSpaces.push(outdoor_ad_spaces[i]);
            }
        });
    }
    return polygonsFilteredAdSpaces;
};

export const filterData = (polygonsFilteredAdSpaces, selectedAdSpaceTypes, searchValue) => {
    if (!polygonsFilteredAdSpaces) return undefined;

    const typeFilteredAdSpaces =
        selectedAdSpaceTypes.length === 1 && selectedAdSpaceTypes.includes(0)
            ? polygonsFilteredAdSpaces
            : polygonsFilteredAdSpaces.filter(obj =>
                  selectedAdSpaceTypes.includes(obj.ad_space_type.id)
              );

    return searchValue.length === 0
        ? typeFilteredAdSpaces
        : typeFilteredAdSpaces.filter(obj => {
              return `${obj.project_location_name.toLowerCase()}, ${obj.name.toLowerCase()}`.includes(
                  searchValue.toLowerCase()
              );
          });
};

export const arrayToCSV = objArray => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
        `${Object.keys(array[0])
            .map(value => `${value}`)
            .join(' ')}` + '\r\n';

    return array.reduce((str, next) => {
        str +=
            `${Object.values(next)
                .map(value => `${value}`)
                .join(' ')}` + '\r\n';
        return str;
    }, str);
};

export const getPointParams = (item, typeOfRouter, isInner, selected) => {
    const result = {};
    switch (typeOfRouter) {
        case 'adSpaces':
            result.polygonColor = isInner || selected ? 'red' : 'blue';
            result.circleColor = 'blue';
            result.radius = 30;
            result.tooltip = `AdSpace | ${item.name} | ${item.ad_space_type.name}`;
            break;
        case 'iPoints':
            result.polygonColor = '#FFA000';
            result.circleColor = '#FFA000';
            result.radius = 15;
            result.tooltip = `iPoint | ${item.marker} | ${item.id}`;
            break;
        default:
            break;
    }
    return result;
};

export const generatePolygon = (map, item, typeOfRouter, isInner, selected, currentZoom) => {
    const defaultTheta = 30;
    const defaultIPointMaxDistance = 250;

    const currentMap = map.current.leafletElement;
    const metresPerPixel =
        (40075016.686 * Math.abs(Math.cos((currentMap.getCenter().lat / 180) * Math.PI))) /
        Math.pow(2, currentZoom + 8);
    // (40075016.686 * Math.abs(Math.cos((currentMap.getCenter().lat / 180) * Math.PI))) / Math.pow(2, currentMap.getZoom() + 8);

    const makeSectorPoint = (point, distance, index) => {
        return {
            x: point.x + distance * Math.cos((index * Math.PI) / 180),
            y: point.y + distance * Math.sin((index * Math.PI) / 180)
        };
    };

    const { polygonColor, tooltip, radius } = getPointParams(item, typeOfRouter, isInner, selected);
    let theta = 30;
    if (item.view_angle) {
        theta = item.view_angle;
    } else if (!item.is_directed || item.orientation_azimuth === null) {
        theta = 360;
    } else {
        theta = defaultTheta;
    }

    const azimuth = item.orientation_azimuth !== null ? (item.orientation_azimuth % 360) - 90 : 90;
    // const azimuth = item.orientation_azimuth ? (item.orientation_azimuth % 360) - 90 : (item.direction % 360) - 90;
    const iterationAngle = 3;
    let maxDistance = 0;
    if (isInner) {
        maxDistance = radius / metresPerPixel;
    } else {
        maxDistance =
            item.view_max_distance && typeOfRouter === 'iPoints'
                ? item.view_max_distance / metresPerPixel
                : typeOfRouter === 'adSpaces'
                ? item.ad_space_type.view_max_distance / metresPerPixel
                : !item.is_directed || item.orientation_azimuth === null
                ? defaultIPointMaxDistance / 4 / metresPerPixel
                : defaultIPointMaxDistance / metresPerPixel;
    }
    // console.log(maxDistance);

    const center = [Number(item.latitude), Number(item.longitude)];
    const point = currentMap.latLngToContainerPoint(center);
    let a1 = [];
    let a2 = [];

    for (let index = azimuth + theta / 2; index > azimuth; index = index - iterationAngle) {
        const a = makeSectorPoint(point, maxDistance, index);
        a1.push(Object.values(currentMap.containerPointToLatLng(a)));
    }
    for (let index = azimuth - theta / 2; index < azimuth; index = index + iterationAngle) {
        const a = makeSectorPoint(point, maxDistance, index);
        a2.push(Object.values(currentMap.containerPointToLatLng(a)));
    }
    const polygon = [center, ...a1, ...a2.reverse()];

    return {
        id: item.id,
        name: item.name,
        orientation_azimuth: item.orientation_azimuth,
        polygon,
        polygonColor,
        tooltip
    };
};
