import _t from 'i18next';
import xlsx from 'xlsx';
import serializeData from '../components/xlsx/tools/serialize_data';
import createReportParams from '../components/xlsx/tools/create_report_params';
import mergeTables from '../components/xlsx/tools/merge_tables';
import { prepareTableData } from '../components/xlsx/tools/prepareTableData';

const generateAtomicTableData = (state, action) => {
    if (
        !action.diagram.dataView
        // || !state.allowedTypes[action.diagram.dataView.data_report.data_type.key_name]
    ) {
        return { ...state, atomicTable: {} };
    }
    const { diagram, locationsСatalog, customParamsSet, projectLocationsById, input_parameter_list, outdoorAdSpacesById, adCampaignsById } =
        action;

    const data = diagram.data;
    // console.log('+++++', data);

    const additionalParams = {
        customParamsSet,
        projectLocationsById,
        adCampaignsById,
        outdoorAdSpacesById,
    };

    const reportName = diagram.dataView.data_report.translations[diagram.lang].name;
    const atomicTable = serializeData(data, diagram.dataView.data_report, reportName);
    atomicTable.dates = diagram.inputParamsValues.main_date_range
        ? ` ${diagram.inputParamsValues.main_date_range[0]} - ${diagram.inputParamsValues.main_date_range[1]}`
        : '';

    atomicTable.reportParams = createReportParams(
        reportName,
        diagram.inputParamsValues,
        input_parameter_list,
        diagram.lang,
        locationsСatalog,
        additionalParams
    );

    const paramsForInfo = createReportParams(
        reportName,
        diagram.inputParamsValues,
        input_parameter_list,
        diagram.lang,
        locationsСatalog,
        additionalParams,
        true
    );

    const description = diagram.dataView.data_report[`description_${diagram.lang}`];

    return { ...state, atomicTable, reportParamsForInfo: paramsForInfo, description };
};

const onShowTablesButtonClick = (state, action) => {
    const { isCompleteTablesShown } = state;
    if (action.isShown === undefined) {
        return { ...state, isCompleteTablesShown: !isCompleteTablesShown };
    } else {
        return { ...state, isCompleteTablesShown: action.isShown };
    }
};

const saveReportParams = (state, action) => {
    const {
        lang,
        reportParams,
        locationsСatalog,
        paramsList,
        projectLocationsById,
        customParamsSet,
        adCampaignsById,
        outdoorAdSpacesById,
    } = action;

    const additionalParams = {
        adCampaignsById,
        customParamsSet,
        projectLocationsById,
        outdoorAdSpacesById,
    };

    const params = createReportParams(_t.t('Параметры отчета'), reportParams, paramsList, lang, locationsСatalog, additionalParams);
    const completedParams = params.map((item) => {
        const maxLength = [...params].sort((a, b) => b.length - a.length)[0].length;
        const emptyElementToAdd = maxLength - item.length;
        for (let i = 0; i < emptyElementToAdd; i++) {
            item.push(' ');
        }
        return item;
    });
    return { ...state, reportParams: [...completedParams] };
};

const saveTablesDataToStore = (state, action) => {
    const { currentSubSection, data, lang } = action;
    const { shownIndexes, mode, allowedTypes } = state;
    const completeTables = {};

    try {
        const serialisedData = data.map((item) => {
            if (!item.allowedTables) {
                return {
                    groupName: item.groupName,
                    allowedTables: [],
                };
            }
            const allowedTables = item.allowedTables
                .map((table) => {
                    const obj = {
                        index: table.index,
                        data_report: table.data_report,
                        data: serializeData(table.data, table.data_report, table.data_report.translations[lang].name),
                    };

                    return obj;
                })
                .filter((item) => (shownIndexes[item.index] === undefined ? true : shownIndexes[item.index]));

            return {
                groupName: item.groupName,
                allowedTables,
            };
        });

        completeTables[currentSubSection] = serialisedData;
    } catch (error) {
        console.warn(error);
    }

    switch (mode) {
        case 2:
            break;
        case 1:
            Object.keys(completeTables).forEach((subsection) => {
                const arr2 = completeTables[subsection].map((group) => {
                    const unMergeableTables = group.allowedTables.filter((item) => !item.data.isMergeable).map((item) => item.data.data);

                    const allowedTables = Object.keys(allowedTypes)
                        .map((allowedType) => {
                            return group.allowedTables.filter((item) => item.data.isMergeable && item.data.type === allowedType);
                        })
                        .filter((item) => item.length !== 0)
                        .map((tables) => mergeTables(tables));

                    return {
                        groupName: group.groupName,
                        allowedTables: [...allowedTables, ...unMergeableTables],
                    };
                });
                completeTables[subsection] = arr2;
            });
            break;

        default:
            break;
    }

    let tablesTree;
    try {
        tablesTree = data.map((item) => {
            return {
                groupName: item.groupName,
                allowedTables: item.allowedTables.map((table) => {
                    return {
                        index: table.index,
                        data_report: table.data_report,
                    };
                }),
            };
        });
    } catch (error) {}
    // console.log('>>>>>>>>>>>>>>>>>>!!!111', completeTables, tablesTree)
    return {
        ...state,
        completeTables: { ...completeTables },
        tree: {
            ...state.tree,
            [currentSubSection]: tablesTree,
        },
    };
};

const onCompleteTablesModeChange = (state, action) => {
    const { mode } = action;
    return { ...state, mode };
};

const onTableCheck = (state, action) => {
    if (action.index === null) {
        return state;
    }
    const changingIndexValue = state.shownIndexes[action.index] === undefined ? true : state.shownIndexes[action.index];
    return { ...state, shownIndexes: { ...state.shownIndexes, [action.index]: !changingIndexValue } };
};

const saveAtomicTableFile_XLSX = (state, action) => {
    const { atomicTable } = state;
    const { lang } = action.payload;

    const createBook = () => {
        const workBook = xlsx.utils.book_new();

        workBook.Props = {
            Title: 'Shopster Report',
            Subject: 'Report',
            Author: 'Shopster',
            CreatedDate: new Date(),
        };

        const parametersSheetName = _t.t('Параметры отчета');
        const dataSheetName = 'data';

        workBook.SheetNames.push(parametersSheetName, dataSheetName);
        const parametersSheet = xlsx.utils.aoa_to_sheet(atomicTable.reportParams);
        const dataSheet = xlsx.utils.aoa_to_sheet(prepareTableData(atomicTable.data));

        workBook.Sheets[parametersSheetName] = parametersSheet;
        workBook.Sheets[dataSheetName] = dataSheet;
        return workBook;
    };

    if (!atomicTable.data) {
        return { ...state };
    } else {
        const dates = atomicTable.dates;
        const fileName = atomicTable.name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') + dates;
        xlsx.writeFile(createBook(), `${fileName}.xlsx`);
    }

    return { ...state };
};

const makeInitialShownIndexes = (n) => {
    const obj = {};
    for (let i = 0; i < n; i++) {
        obj[i] = true;
    }
    return obj;
};

const stateInit = {
    atomicTable: {},
    completeTables: {},
    reportParams: [],
    reportParamsForInfo: [],
    description: '',
    mode: 1,
    tree: {},
    isCompleteTablesShown: false,
    allowedTypes: {
        external_intersection_arr: true,
        comparison_score: true,
        score: true,
        table: true,
        distribution_over_days_of_week: true,
        distribution_over_hours_of_day: true,
        distribution: true,
        distribution_one_line: true,
        distribution_over_objects: true,
        dynamics: true,
        funnel: true,
        funnel_pair: true,
        funnel_set: true,
    },
    shownIndexes: {},
    // shownIndexes: makeInitialShownIndexes(1000),
};

const XLSX = (state = stateInit, action) => {
    switch (action.type) {
        case 'generateAtomicTableData':
            return generateAtomicTableData(state, action);
        case 'onShowTablesButtonClick':
            return onShowTablesButtonClick(state, action);
        case 'saveTablesDataToStore':
            return saveTablesDataToStore(state, action);
        case 'onTableCheck':
            return onTableCheck(state, action);
        case 'onCompleteTablesModeChange':
            return onCompleteTablesModeChange(state, action);
        case 'saveReportParams':
            return saveReportParams(state, action);
        case 'saveAtomicTableFile_XLSX':
            return saveAtomicTableFile_XLSX(state, action);

        default:
            return state;
    }
};
export default XLSX;
