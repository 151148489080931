import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';
import Item from './tree_item';

import { treeMap, useAdCampTree } from '../../../tools/tools';

const Ul = styled.ul`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: ${(p) => (p.basic ? 0 : '20px')};
    margin: 0;
`;

const TreeContainer = styled.section`
    border: 1px solid rgb(138, 136, 134);
    border-radius: 2px;
    /* width: 100%; */
    background-color: #fff;
    /* margin: 10px; */
    padding: 5px 10px;
    box-sizing: border-box;
    max-height: 50vh;
    overflow: auto;
    margin-bottom: 10px;
`;

export default React.memo(({ ownersExpand, ...props }) => {
    const commonTree = useAdCampTree();
    // const memoTree = useMemo(() => {
    //     return commonTree;
    // }, [commonTree, ownersExpand]);

    const renderList = (arr) => {
        return (
            <Ul basic={true}>
                {arr
                    // .filter((item) => {
                    //     if (item.type === 'project' || (item.type === 'owner' && item.children.length !== 0)) {
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // })
                    .map((item) => {
                        const itemId = Number(String(item.id).replace(/:owner/, ''));
                        const expand = item.type === 'owner' && ownersExpand[itemId] ? ownersExpand[itemId].expand : false;
                        const children = item.children.length === 0 ? null : <Ul>{renderList(item.children)}</Ul>;
                        const hasChildren = item.children.length !== 0;
                        return (
                            <Item
                                key={`${item.name}-${item.id}`}
                                hasChildren={hasChildren}
                                item={item}
                                itemId={itemId}
                                toggleExpandArrow={props.toggleExpandArrow}
                                expand={expand}
                                onObjSetDashClick={props.onObjSetDashClick}
                                changeProjectId={props.changeProjectId}
                            >
                                {children}
                            </Item>
                        );
                    })}
            </Ul>
        );
    };

    const tree = useMemo(() => {
        return commonTree[0] ? renderList([treeMap(commonTree[0])]) : null;
    }, [commonTree, ownersExpand]);

    return (
        <TreeContainer>
            <div>{tree}</div>
        </TreeContainer>
    );
});
