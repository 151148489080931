import React, { useState, useEffect, useMemo, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';

const Item = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #777;
    padding-top: 5px;
`;

const PdfReportItem = React.memo(({ item, id, ...props }) => {
    const reportItemRef = useRef();

    useLayoutEffect(() => {
        if (reportItemRef.current) {
            const position = reportItemRef.current.offsetTop + reportItemRef.current.offsetHeight;
            const height = reportItemRef.current.offsetHeight;
            props.getReportItemEndPosition({ id, position, height });
        }
    }, [reportItemRef.current]);

    return (
        <Item ref={reportItemRef}>
            <span>
                <b>{item[0]}</b>
            </span>
            <span>{item[1]}</span>
        </Item>
    );
});

export default PdfReportItem;
