import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import BodyCompanent from './components/body';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import './style/diagram_params_panel.scss';
import $ from 'jquery';
import { runAction } from '../../actions';

// import AtomicTablesSaveButtons from '../xlsx/atomic_tables_save_buttons/';
import ReportInfo from './components/report_info';
import DiagramOptions from './components/diagram_options';

const Container = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  overflow: hidden;
  top: 50px;
  z-index: 301;
`;
class DiagramParamsPanel extends Component {
  static defaultProps = {};

  static propTypes = {};

  state = {};

  hideSettingsPanel = e => {
    this.props.hideSettingsPanel(e);
  };

  render() {
    const {
      atomicTable,
      reportParamsForInfo,
      description,
      diagramOptionsByID,
      toggleIndicator,
      toggleSettings,
      indexDataView
    } = this.props;
    return this.props.statusShowRightPanel ? (
      <Container>
        <div id={'boxRightPanel'} className={'boxRightPanel'}>
          <div className="boxRightPanelContent">
            <ReportInfo reportParamsForInfo={reportParamsForInfo} description={description} />
            <DiagramOptions
              indexDataView={indexDataView}
              diagramOptionsByID={diagramOptionsByID}
              toggleIndicator={toggleIndicator}
              toggleSettings={toggleSettings}
            />
            {/* <AtomicTablesSaveButtons atomicTable={atomicTable} _t={_t} /> */}
            {/* <Header _t={this.props._t} lang={this.props.lang} />
            <BodyCompanent
              lang={this.props.lang}
              inputParamsSelectDataView={this.props.inputParamsSelectDataView}
              onChangeSelect={this.props.onChangeSelect}
              inputParameters={this.props.inputParameters}
            /> */}
          </div>
        </div>
        <div onClick={this.hideSettingsPanel} className={'boxRightPanel_overlay'} />
      </Container>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inputParameters: state.ReportParametersManager.inputParameters,
    diagramOptionsByID: state.ReportParametersManager.diagramOptionsByID,
    statusShowRightPanel: state.ReportParametersManager.statusShowRightPanel,
    inputParamsSelectDataView: state.ReportParametersManager.inputParamsSelectDataView,
    indexDataView: state.ReportParametersManager.indexDataView,
    atomicTable: state.XLSX.atomicTable,
    reportParamsForInfo: state.XLSX.reportParamsForInfo,
    description: state.XLSX.description
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeSelect: (e, key_name) => {
      let value = e.target.value + 1 === parseFloat(e.target.value) + 1 ? e.target.value : +e.target.value;
      if (isNaN(value) === true) {
        value = e.target.value;
      }
      dispatch(runAction('onChangeSelect', { value: value, key_name: key_name }));
    },
    toggleIndicator: (indicatorName, diagramIndex) => {
      dispatch(runAction('toggleIndicator_ReportParametersManager', { indicatorName, diagramIndex }));
    },
    toggleSettings: (settingId, diagramIndex) => {
      dispatch(runAction('toggleSettings_ReportParametersManager', { settingId, diagramIndex }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagramParamsPanel);
