import moment from "moment/moment";

const initState = {
    sotsDetailedDynamics: [],
    arrayForsots:[],
    sumSots: 0,
    arraySumSots: [],
    ratingsSots: [],
    frequencyHistList: [],
    accumulativeFrequencyHistList: [],
    sumMeanFrequency: 0,
    countUniq: 0,
    aggregatedSGRP: 0,
    reachOverFrequency:[],
    reach: 0,
    sGRPDetailedDynamicsList: [],
    aggregatedSSGRPDynamics: [],
    datePeriod: {
        startDate: new Date(moment().add(0, 'days')),
        endDate: new Date(moment().add(7, 'days'))
    },
    dateList: [],
    isLoadData: false,
    isLoadUniqData: false,
    aggregatedSSGRPDynamicsLine: [],
    sGRPDetailedDynamicsListLine: [],
    selectedOutdoorAdSpaces: [],
    outdoorAdSpacesByKey: null,
    isShowPrecampModal: false,
    listReport: [],
    selectionItemReport: null
};


function compare(a, b) {
    if (a.value < b.value ) {
        return 1;
    }
    if (a.value > b.value) {
        return -1;
    }
    // a должно быть равным b
    return 0;
}

const objByName = {
    '1_freq': '1',
    '2_freq': '2',
    '3_freq': '3',
    '4_freq': '4',
    '5_freq': '5',
    '6_freq': '6',
    '7_freq': '7',
    '8_freq': '8',
    '9_freq': '9',
    '10_freq': '10',
    '11_freq': '11',
    '12_freq': '12',
    '13_freq': '13',
    '14_freq': '14',
    '15_freq': '15',
    '16_freq': '16',
    '17_freq': '17',
    '18_freq': '18',
    '19_freq': '19',
    '20_freq_and_more': '+20'
};

const objByName2 = {
    '1_freq': 1,
    '2_freq': 2,
    '3_freq': 3,
    '4_freq': 4,
    '5_freq': 5,
    '6_freq': 6,
    '7_freq': 7,
    '8_freq': 8,
    '9_freq': 9,
    '10_and_more_freq': 10
};

const getSotsDetailedDynamics_PreCampaigns = (state, action) => {
    const {
        payload
    } = action;
    let _array = []
    let _array2 = []
    let arrayForsots = []
    let sumSots = 0
    let obj = {}
    let obj2 = {}
    let arraySumSots = []
    let objRatingTemp = []

    let sGRPDetailedDynamicsList = []
    let sGRPDetailedDynamics = 0
    let aggregatedSSGRPDynamics = []
    let aggregatedSSGRPDynamicsLine = []
    let sGRPDetailedDynamicsListLine = []
    //тут надо менять если схема данных поменяется
    let arraySelected = []
    Object.keys(payload.json.data).forEach((item, i) => {
        if (!isNaN(+item)) {
            arraySelected.push(+item)
        }
    })
    arraySelected.forEach((item, i) => {
        _array.push([])
        _array2.push([])
        sGRPDetailedDynamicsList.push([])
        sGRPDetailedDynamicsListLine.push([])
        sGRPDetailedDynamics = 0
        if (payload.json.data[item]) {
            payload.json.data[item].predictions.forEach((_item2, j) => {
                let item2 = _item2

                _array[_array.length - 1].push({
                    ad_space_id: item,
                    date:  payload.json.data.x_axis_dates[j],
                    value: item2
                })
                _array2[_array2.length - 1].push({
                    ad_space_id: item,
                    date:  payload.json.data.x_axis_dates[j],
                    value: item2 / payload.json.data.default_population * j + 1
                })

                if (j === 0) {
                    sGRPDetailedDynamics = (item2 * 1000  / payload.json.data.default_population * 100)

                    // console.log('><><>>>>>>>>', sGRPDetailedDynamics,">>>>>", item2, payload.json.data.default_population, j, i)

                    sGRPDetailedDynamicsList[sGRPDetailedDynamicsList.length - 1].push({
                        y: +sGRPDetailedDynamics.toFixed(3),
                        x: payload.json.data.x_axis_dates[j],
                        z: 200,
                        id: item
                    })
                    sGRPDetailedDynamicsListLine[sGRPDetailedDynamicsListLine.length - 1].push({
                        [payload.outdoorAdSpacesById[item].name]: +sGRPDetailedDynamics.toFixed(3),
                        name: payload.json.data.x_axis_dates[j],
                        z: 200,
                        _id: item,
                        _name: payload.outdoorAdSpacesById[item].name
                    })
                }
                else {
                    sGRPDetailedDynamics += (item2 * 1000  / payload.json.data.default_population * 100)

                    // console.log('><><>>>>>>>>', sGRPDetailedDynamics,">>>>>", item2, payload.json.data.default_population, j)

                    sGRPDetailedDynamicsList[sGRPDetailedDynamicsList.length - 1].push({
                        y: +sGRPDetailedDynamics.toFixed(3),
                        x: payload.json.data.x_axis_dates[j],
                        z: 200,
                        id: item
                    })
                    sGRPDetailedDynamicsListLine[sGRPDetailedDynamicsListLine.length - 1].push({
                        [payload.outdoorAdSpacesById[item].name]: +sGRPDetailedDynamics.toFixed(3),
                        name: payload.json.data.x_axis_dates[j],
                        z: 200,
                        _id: item,
                        _name: payload.outdoorAdSpacesById[item].name
                    })
                }

            })
        }

    })
    _array.forEach((item, i) => {
        arrayForsots.push([])
        objRatingTemp.push({
            name: '',
            value: 0
        })

        item.forEach((item2, j) => {
            objRatingTemp[objRatingTemp.length - 1].name = item2.ad_space_id
            objRatingTemp[objRatingTemp.length - 1].value += item2.value
            sumSots += item2.value
            arrayForsots[arrayForsots.length - 1].push({
                name: item2.date,
                [payload.outdoorAdSpacesById[item2.ad_space_id].name]: item2.value,
                _id: item2.ad_space_id,
                _name: payload.outdoorAdSpacesById[item2.ad_space_id].name
            });

            if (!obj[item2.date]) {
                obj[item2.date] = 0
            }
            obj[item2.date] += item2.value
        })
    })

    sGRPDetailedDynamicsList.forEach((item, i) => {
        
        item.forEach((item2, j) => {
            

            if (!obj2[item2.x]) {
                obj2[item2.x] = 0
            }
            obj2[item2.x] += item2.y
            // console.log('><><><>>>>>',item2.y, _array2);
        })
    })

    Object.keys(obj).forEach((item, i) => {
        arraySumSots.push({
            name: item,
            sOts: obj[item],
            _id:"sOts",
            _name:"sOts"
        })
    })
    Object.keys(obj2).forEach((item, i) => {
        aggregatedSSGRPDynamics.push({
            x: item,
            y: obj2[item],
            z: 0
        })
        aggregatedSSGRPDynamicsLine.push({
            name: item,
            'Накопительный sGRP': obj2[item],
            z: 0,
            _id:"Накопительный sGRP",
            _name:"Накопительный sGRP"
        })
    })
    objRatingTemp.sort(compare)

    console.log('><><><>>>>>',sumSots,  payload.json.data.default_population );
    return {
        ...state,
        sotsDetailedDynamics: _array,
        arrayForsots,
        sumSots,
        arraySumSots,
        ratingsSots: objRatingTemp,
        aggregatedSGRP: sumSots * 1000 / payload.json.data.default_population * 100,
        sGRPDetailedDynamicsList,
        aggregatedSSGRPDynamics,
        dateList: payload.json.data.x_axis_dates,
        aggregatedSSGRPDynamicsLine,
        sGRPDetailedDynamicsListLine,
        selectedOutdoorAdSpaces: payload.outdoorAdSpaces,
        outdoorAdSpacesByKey: payload.outdoorAdSpacesById
    };
};

const getAccumulativeFrequencyHist_PreCampaigns = (state, action) => {
    const {
        json2,
        selectedSpaces
    } = action.payload;

    let array = [];
    let firstAccumulative = 0
    let firstAccumulativeGlobal = 0
    let meanFrequencyGlobal = 0
    let arrayAccomulative = [];
    let reachOverFrequency = []

    console.log('>>>>>>>>>>>>>>>>>>>>>', json2.data.hist)

    const _hist = json2.data.hist

    // json2.data.hist.forEach((item, i) => {
    //     // console.log(item, json2.data.hist[item]);
    //     firstAccumulative += item[1]
    //
    //     firstAccumulativeGlobal += item[1]
    //     array.push({
    //         name: objByName[item[0]],
    //         uv: 3490,
    //         'Прогнозируемая частота': item[1],
    //         // amt: 2100,
    //     })
    // })

    Object.keys(_hist).forEach((item, i) => {
        // console.log(item, json2.data.hist[item]);
        firstAccumulative += _hist[item]

        firstAccumulativeGlobal += _hist[item]
        array.push({
            name: objByName[item],
            uv: 3490,
            'Прогнозируемая частота': _hist[item],
            // amt: 2100,
        })
    })


    // json2.data.hist.forEach((item, i) => {
    //     meanFrequencyGlobal += (item[1] / firstAccumulativeGlobal) * item[1]
    //
    //     if ( i === 0) {
    //         arrayAccomulative.push({
    //             name: objByName[item[0]] + '>',
    //             uv: 3490,
    //             'Прогнозируемая частота': firstAccumulative,
    //         })
    //         reachOverFrequency.push({
    //             name: objByName[item[0]] + '>',
    //             uv: 3490,
    //             'Прогнозируемая частота': firstAccumulative / json2.data.default_population * 100,
    //         })
    //     }
    //     else {
    //         console.log('>><><>>>><>><', firstAccumulative,'|-|', json2.data.hist[i - 1][1], firstAccumulative - (+json2.data.hist[i - 1][1]))
    //         firstAccumulative = (firstAccumulative - (+json2.data.hist[i - 1][1]))
    //         < 0.0001 ? 0 :
    //             (firstAccumulative - (+json2.data.hist[i - 1][1].toFixed(2)))
    //
    //
    //         // firstAccumulative -= json2.data.hist[i - 1][1]
    //         // console.log('>><><>>>><>><333', firstAccumulative)
    //         arrayAccomulative.push({
    //             name: objByName[item[0]] + '>',
    //             uv: 3490,
    //             'Прогнозируемая частота': firstAccumulative,
    //         })
    //         reachOverFrequency.push({
    //             name: objByName[item[0]] + '>',
    //             uv: 3490,
    //             'Прогнозируемая частота': firstAccumulative / json2.data.default_population * 100,
    //         })
    //     }
    //
    // })

    Object.keys(_hist).forEach((item, i) => {
        meanFrequencyGlobal += (_hist[item] / firstAccumulativeGlobal) * (i + 1)

        if ( i === 0) {
            arrayAccomulative.push({
                name: objByName[item] + '>',
                uv: 3490,
                'Прогнозируемая частота': firstAccumulative,
            })
            reachOverFrequency.push({
                name: objByName[item] + '>',
                uv: 3490,
                'Прогнозируемая частота': firstAccumulative / json2.data.default_population * 100,
            })
        }
        else {
            console.log('>><><>>>><>><', firstAccumulative,'|-|', Object.keys(_hist)[i - 1], firstAccumulative - (+Object.keys(_hist)[i - 1]))
            firstAccumulative = (firstAccumulative - (+_hist[Object.keys(_hist)[i - 1]]))
            < 0.0001 ? 0 :
                (firstAccumulative - (+_hist[Object.keys(_hist)[i - 1]].toFixed(2)))


            // firstAccumulative -= json2.data.hist[i - 1][1]
            // console.log('>><><>>>><>><333', firstAccumulative)
            arrayAccomulative.push({
                name: objByName[item] + '>',
                uv: 3490,
                'Прогнозируемая частота': firstAccumulative,
            })
            reachOverFrequency.push({
                name: objByName[item] + '>',
                uv: 3490,
                'Прогнозируемая частота': firstAccumulative / json2.data.default_population * 100,
            })
        }

    })



    console.log('>><><>>>><>><22', arrayAccomulative)
    
    return {
        ...state,
        frequencyHistList: array,
        accumulativeFrequencyHistList: arrayAccomulative,
        sumMeanFrequency: + meanFrequencyGlobal.toFixed(2),
        reachOverFrequency
    };
};

const getUniqueMac_PreCampaigns = (state, action) => {
    const {
        json3,
        selectedSpaces
    } = action.payload;

    const {
        aggregatedSGRP,
        sumMeanFrequency
    } = state

    
    //
    // console.log('><>>>><><<<<', aggregatedSGRP / sumMeanFrequency);

    return {
        ...state,
        countUniq: json3.data.unique_count_pred[0],
        reach: +aggregatedSGRP / sumMeanFrequency
    };
};


const onShowMoreClick_AdCampaigns = (state, action) => {
    const campaignsToShow = state.campaignsToShow + state.addCampaignsNumber;
    return { ...state, campaignsToShow };
};

const getDate_PreCampaigns = (state, action) => {
    const {
        date
    } = action.payload;
    return { 
        ...state,
        datePeriod: date
         };
};

const getIsLoad_PreCampaigns = (state, action) => {
    const {
        status
    } = action.payload;
    return { 
        ...state,
        isLoadData: status
         };
};

const getIsLoadUniq_PreCampaigns = (state, action) => {
    const {
        status
    } = action.payload;

    let reach = state.reach
    let countUniq = state.countUniq

    if (status) {
        reach = 0
        countUniq = 0
    }
    return {
        ...state,
        isLoadUniqData: status,
        reach,
        countUniq
    };
};


const getStatusShowModal = (state, action) => {
    const {
        status
    } = action.payload

    return {
        ...state,
        isShowPrecampModal: status
    };
}

const getReportList = (state, action) => {
    const {
        list
    } = action.payload

    console.log('>>>>>>>>>>>>>>>>>>>>>>>>', list)
    return {
        ...state,
        listReport: list
    };
}

const onSelectItemList = (state, action) => {
    const {
        selection
    } = action.payload

    return {
        ...state,
        selectionItemReport: selection === undefined ? null : selection
    };
};

const clearData = (state, action) => {
    return{
        ...state,
        sotsDetailedDynamics: [],
        arrayForsots:[],
        sumSots: 0,
        arraySumSots: [],
        ratingsSots: [],
        frequencyHistList: [],
        accumulativeFrequencyHistList: [],
        sumMeanFrequency: 0,
        countUniq: 0,
        aggregatedSGRP: 0,
        reachOverFrequency:[],
        reach: 0,
        sGRPDetailedDynamicsList: [],
        aggregatedSSGRPDynamics: [],

        dateList: [],
        isLoadData: false,
        isLoadUniqData: false,
        aggregatedSSGRPDynamicsLine: [],
        sGRPDetailedDynamicsListLine: [],
        selectedOutdoorAdSpaces: [],
        outdoorAdSpacesByKey: null,
        isShowPrecampModal: false,
        listReport: [],
        selectionItemReport: null
    }
}


const PreCampaigns = (state = initState, action) => {
    switch (action.type) {
        case 'getSotsDetailedDynamics_PreCampaigns':
            return getSotsDetailedDynamics_PreCampaigns(state, action);
        case 'onShowMoreClick_AdCampaigns':
            return onShowMoreClick_AdCampaigns(state, action);
        case 'getAccumulativeFrequencyHist_PreCampaigns':
            return getAccumulativeFrequencyHist_PreCampaigns(state, action);
        case 'getUniqueMac_PreCampaigns':
            return getUniqueMac_PreCampaigns(state, action);
        case 'getDate_PreCampaigns':
            return getDate_PreCampaigns(state, action);
        case 'getIsLoad_PreCampaigns':
            return getIsLoad_PreCampaigns(state, action);
        case 'getIsLoadUniq_PreCampaigns':
            return getIsLoadUniq_PreCampaigns(state, action);
        case 'getStatusShowModal_PreCampaigns':
            return getStatusShowModal(state, action);
        case 'getStatusShowModal_GetReportList':
            return getReportList(state, action);
        case 'onSelectItemList_PreCampaigns':
            return onSelectItemList(state, action);
        case 'clearData_PreCampaigns':
            return clearData(state, action);

        default:
            return state;
    }
};

export default PreCampaigns;
