import _t from 'i18next';
// import { prepareHorisontalBarColorData, prepareDynamicsData } from '../../Diagram/diagramTools';

const parseTableData = (columnKeys = [], rowValue = {}) => {
    return columnKeys.reduce((acc, item) => {
        return [...acc, rowValue.hasOwnProperty(item) ? rowValue[item] : ''];
    }, []);
};

const dateRangeToString = (rangeType) => {
    switch (rangeType) {
        case 'comparison_date_range':
            return _t.t('Период сравнения');
        case 'main_date_range':
            return _t.t('Отчетный период');
        default:
            return ' ';
    }
};

const serializeTable = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    const dataArr = [];
    atomicTable.isMergeable = false;
    dataArr.push([reportName, ...data.column_names.map((item) => ' ').slice(0, -1)]);
    dataArr.push(data.column_names);
    data.rows.forEach((item) => {
        dataArr.push(parseTableData(data.column_keys, item.values));
    });
    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeComparisonScore = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    const dataArr = [];
    atomicTable.isMergeable = true;
    dataArr.push([_t.t('Название'), _t.t('Значение для сравнения'), _t.t('Значение'), _t.t('Дельта')]);

    const compareValue = data.data
        .filter((item) => item.data_aspects.time.date_range_type === 'comparison_date_range')[0]
        .values[0].toFixed(2);
    const value = data.data.filter((item) => item.data_aspects.time.date_range_type === 'main_date_range')[0].values[0].toFixed(2);
    dataArr.push([reportName, compareValue, value, `${(((value - compareValue) / compareValue) * 100).toFixed(1)}%`]);
    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeScore = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    const dataArr = [];
    atomicTable.isMergeable = true;
    dataArr.push([_t.t('Название'), _t.t('Значение')]);

    const value = data.data[0].values[0];
    dataArr.push([reportName, value]);
    atomicTable.data = dataArr;
    return atomicTable;
};

// const getIdType = (id, LocationsById) => {
//     return LocationsById.hasOwnProperty(id) ? 'pl_id:' : 'obj_id:';
// };

const serializeDynamics = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = true;
    const dataArr = [];
    let dataSeries = [];
    let rows = [];

    data.data.forEach((item) => {
        if (item.data_aspects.object.relation === 'detalization') {
            atomicTable.isMergeable = false;
        }
    });
    dataSeries = data.data.filter((item) => item.data_aspects.time.date_range_type === 'main_date_range');

    rows = dataSeries[0].data_aspects.time.timeseries;
    if (data.data[0].data_aspects.object.relation === 'aggregation') {
        dataArr.push([
            `${_t.t('Дата')}, ${_t.t('Время')}`,
            `${reportName}, ${dateRangeToString(dataSeries[0].data_aspects.time.date_range_type)}`,
        ]);
    } else if (data.data[0].data_aspects.object.relation === 'detalization') {
        dataArr.push([reportName, ...dataSeries.map((item) => ' ')]);
        dataArr.push([
            `${_t.t('Дата')}, ${_t.t('Время')}`,
            ...dataSeries.map((item, i) => {
                const object = item.data_aspects.object.associations[0];
                return `${object.name} (obj_id: ${object.id})`;
            }),
        ]);
    }
    dataArr.push(
        ...rows.map((item, i) => {
            const values = dataSeries.map((value) => (value.values[i] ? value.values[i].toFixed(2) : null));
            return [item, ...values];
        })
    );

    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeFunnel = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = false;
    const dataArr = [];
    dataArr.push([
        reportName,
        `${_t.t('Входное значение')}`,
        `${_t.t('Выходное значение')}`,
        `${_t.t('Входное значение, %')}`,
        `${_t.t('Выходное значение, %')}`,
        `${_t.t('Изменение п.п.')}`,
    ]);

    if (report.data_type.key_name === 'funnel_pair') {
        dataArr[0].push(`${_t.t('Изменение от периода сравнения, %')}`);
    }

    const rows = data.funnels[0].funnel_layers.map((item, i) => {
        const section = data.funnels[0].funnel_layers[i];
        const maxValue = data.funnels[0].funnel_layers[0].input_value;
        const inputPercent = percent(section.input_value, maxValue);
        const outputPercent = percent(section.output_value, maxValue);
        const pointsChange = (Number(outputPercent) - Number(inputPercent)).toFixed(1);
        const values = [section.input_value, section.output_value, inputPercent, outputPercent, pointsChange];
        if (report.data_type.key_name === 'funnel_pair') {
            const sectionComparison = data.funnels[1].funnel_layers[i];
            const comparisonChange = ((section.output_value / sectionComparison.output_value - 1) * 100).toFixed(1);
            values.push(comparisonChange);
        }
        return [item.name, ...values];
    });

    dataArr.push(...rows);

    atomicTable.data = dataArr;

    return atomicTable;
};

const percent = (value, max) => {
    return ((100 * value) / max).toFixed(1);
};

const serializeFunnelSet = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = false;
    const dataArr = [];
    dataArr.push([
        reportName,
        `${_t.t('Входное значение')}`,
        `${_t.t('Выходное значение')}`,
        `${_t.t('Входное значение, %')}`,
        `${_t.t('Выходное значение, %')}`,
        `${_t.t('Изменение п.п.')}`,
    ]);
    try {
        data.funnels.forEach((funnel) => {
            dataArr.push([funnel.funnel_name]);
            funnel.funnel_layers.forEach((layer) => {
                const maxValue = funnel.funnel_layers[0].input_value;
                const inputPercent = percent(layer.input_value, maxValue);
                const outputPercent = percent(layer.output_value, maxValue);
                const pointsChange = (Number(outputPercent) - Number(inputPercent)).toFixed(1);
                const values = [layer.input_value, layer.output_value, inputPercent, outputPercent, pointsChange];
                dataArr.push([layer.name, ...values]);
            });
            dataArr.push([]);
        });
    } catch (error) {
        dataArr.push([]);
    }

    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeDistributionOverDaysOfWeek = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = true;
    const dataArr = [];
    let dataSeries = [];
    let rows = [];

    data.data.forEach((item) => {
        if (item.data_aspects.object.relation === 'detalization') {
            atomicTable.isMergeable = false;
        }
    });
    dataSeries = data.data.filter((item) => item.data_aspects.time.date_range_type === 'main_date_range');
    rows = data.x.values;
    if (data.data[0].data_aspects.object.relation === 'aggregation') {
        dataArr.push([`${_t.t('День недели')}`, `${reportName}, ${dateRangeToString(dataSeries[0].data_aspects.time.date_range_type)}`]);
    } else if (data.data[0].data_aspects.object.relation === 'detalization') {
        dataArr.push([reportName, ...dataSeries.map((item) => ' ')]);
        dataArr.push([
            `${_t.t('День недели')}`,
            ...dataSeries.map((item, i) => {
                const object = item.data_aspects.object.associations[0];
                return `${object.name} (obj_id: ${object.id})`;
            }),
        ]);
    }
    dataArr.push(
        ...rows.map((item, i) => {
            const values = dataSeries.map((value) => value.values[i].toFixed(2));
            return [item, ...values];
        })
    );

    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeDistributionOverHoursOfDay = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = true;
    const dataArr = [];
    let dataSeries = [];
    let rows = [];

    data.data.forEach((item) => {
        if (item.data_aspects.object.relation === 'detalization') {
            atomicTable.isMergeable = false;
        }
    });
    dataSeries = data.data.filter((item) => item.data_aspects.time.date_range_type === 'main_date_range');
    rows = data.x.values;
    if (data.data[0].data_aspects.object.relation === 'aggregation') {
        dataArr.push([`${_t.t('Время')}`, `${reportName}, ${dateRangeToString(dataSeries[0].data_aspects.time.date_range_type)}`]);
    } else if (data.data[0].data_aspects.object.relation === 'detalization') {
        dataArr.push([reportName, ...dataSeries.map((item) => ' ')]);
        dataArr.push([
            `${_t.t('Время')}`,
            ...dataSeries.map((item, i) => {
                const object = item.data_aspects.object.associations[0];
                return `${object.name} (obj_id: ${object.id})`;
            }),
        ]);
    }
    dataArr.push(
        ...rows.map((item, i) => {
            const values = dataSeries.map((value) => value.values[i].toFixed(2));
            return [`${item.toString().length < 2 ? '0' + item.toString() : item.toString()}:00`, ...values];
        })
    );

    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeDistributionOverObjects = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = false;

    atomicTable.data = data.data
        .sort((a, b) => {
            return a.values[0] - b.values[0];
        })
        .map((item, i) => {
            const object = item.data_aspects.object.associations[0];
            return [`${object.name} (obj_id: ${object.id})`, item.values[0]];
        })
        .reverse();

    atomicTable.data.unshift([reportName, ' ']);

    return atomicTable;
};

const serializeExternalIntersectionArr = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = false;

    atomicTable.data = data.map((item, i) => {
        const v = Number(((item.intersection_count_unique / item.segment_count_unique) * 100).toFixed(2));
        const objectName = item.external_obj_name;
        return [`${objectName}`, v];
    });

    atomicTable.data.unshift([reportName, ' ']);

    return atomicTable;
};

const serializeDistribution = (data, report, reportName) => {
    const atomicTable = {};
    atomicTable.type = report.data_type.key_name;
    atomicTable.name = reportName;
    atomicTable.isMergeable = false;
    const dataArr = [];
    let dataSeries = [];
    let rows = [];

    dataSeries = data.data;
    rows = data.x.values;
    // console.log(dataSeries, rows);

    if (data.data[0].data_aspects.object.relation === 'aggregation') {
        dataArr.push(['X', `${reportName}, ${dateRangeToString(dataSeries[0].data_aspects.time.date_range_type)}`]);
    } else if (data.data[0].data_aspects.object.relation === 'detalization') {
        dataArr.push([reportName, ...dataSeries.map((item) => ' ')]);
        dataArr.push([
            'X',
            ...dataSeries.map((item, i) => {
                const object = item.data_aspects.object.associations[0];
                return `${object.name} (obj_id: ${object.id})`;
            }),
        ]);
    }
    dataArr.push(
        ...rows.map((item, i) => {
            const values = dataSeries.map((value) => value.values[i].toFixed(2));
            return [item, ...values];
        })
    );

    atomicTable.data = dataArr;
    return atomicTable;
};

const serializeData = (data, report, reportName) => {
    switch (report.data_type.key_name) {
        case 'table':
            return serializeTable(data, report, reportName);

        case 'comparison_score':
            return serializeComparisonScore(data, report, reportName);

        case 'score':
            return serializeScore(data, report, reportName);

        case 'dynamics':
            return serializeDynamics(data, report, reportName);

        case 'distribution_over_days_of_week':
            return serializeDistributionOverDaysOfWeek(data, report, reportName);

        case 'distribution_over_hours_of_day':
            return serializeDistributionOverHoursOfDay(data, report, reportName);

        case 'distribution_over_objects':
            return serializeDistributionOverObjects(data, report, reportName);

        case 'external_intersection_arr':
            return serializeExternalIntersectionArr(data, report, reportName);

        case 'distribution':
        case 'distribution_one_line':
            return serializeDistribution(data, report, reportName);

        case 'funnel':
        case 'funnel_pair':
            return serializeFunnel(data, report, reportName);

        case 'funnel_set':
            return serializeFunnelSet(data, report, reportName);

        default:
            return {};
    }
};

export default serializeData;
