import React, {useState} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer, ReferenceArea,
    Label
} from 'recharts';
import styled from "styled-components/macro";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const data = [
    {
        name: 'Page A',
        // uv: 40000,
        pv: 24000,
        amt: 2400,
    },
    {
        name: 'Page B',
        // uv: 30000,
        pv: 13980,
        amt: 2210,
    },
    {
        name: 'Page C',
        // uv: 20000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 27800,
        pv: 39080,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 18900,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 23900,
        // pv: 38000,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 34900,
        // pv: 4300,
        amt: 2100,
    },
];

const LineChartCustom = (props) => {
    const [isAggregation, setIsAggregation] = useState(false);

    const {
        title,
        _data,
        colors = null,
        id,
        numberWithCommas,
        isCustomTultip = false,
        label = ''
    } = props;

    const { t } = useTranslation();

    const axisXMainStyle = {
        fontSize: 10,
        fill: 'black',
    };

    const axisXComparisonStyle = {
        fontSize: 10,
        fill: 'rgb(244, 155, 16)',
    };

    var weekends = [];
    var arrDate = [];
    if (_data.length > 0){
        for (
            var g = new Date(_data[0].name).getTime();
            g < new Date(_data[_data.length - 1].name).getTime();
            g = new Date(moment(g).set('hour', 24)).getTime()
        ) {
            arrDate.push(g);
        }

        var obj = {};
        const startWeekend =  5;

        for (var j = 0; j < _data.length; j++) {
            for (var h = 0; h < arrDate.length; h++) {
                if (moment(arrDate[h]).day() === startWeekend) {
                    if (obj[new Date(arrDate[h]).getTime()] === undefined) {
                        obj[new Date(arrDate[h]).getTime()] = _data[j].name;
                        weekends.push({
                            start: moment(arrDate[h]).format('YYYY-MM-DD'),
                            end: moment(arrDate[h]).set('hour', 24).format('YYYY-MM-DD'),
                        });
                        weekends.push({
                            start: moment(arrDate[h]).set('hour', 24).format('YYYY-MM-DD'),
                            end: moment(arrDate[h]).set('hour', 48).format('YYYY-MM-DD'),
                        });
                    }
                }
            }
        }
    }




    function formatYAxis(value) {
        // if(value === 0) return "No"
        // if(value === 1) return "Yes"
        return numberWithCommas(value);
    }

    const CustomTooltip22 = ({ active, payload, label }) => {


        if (active && payload && payload.length) {
            const {
                color
            } = payload[0]


            console.log('><><>>>>>>>>>>>>><<', payload[0].payload.name)
            return (
                <DivBoxTultipSC color={color} className="custom-tooltip">
                    <span>{payload[0].payload.name}</span>
                    <p >{`${t(payload[0].name)} : ${payload[0].value.toFixed(2)}`}</p>
                </DivBoxTultipSC>
            );
        }

        return null;
    };


    if (isCustomTultip) {
        const renderColorfulLegendText = (value, entry) => {
            const { color } = entry;
            return <span style={{ color }}>{t(value)}</span>;
        };

        const CustomTooltip = ({ active, payload, label }) => {


            if (active && payload && payload.length) {
                const {
                    color
                } = payload[0]


                return (
                    <DivBoxTultipSC color={color} className="custom-tooltip">
                        <p >{`${t(payload[0].name)} : ${payload[0].value.toFixed(2)}`}</p>
                    </DivBoxTultipSC>
                );
            }

            return null;
        };
        return (
            <DivLineChartCustomSC>
                <DivBoxTitleDiagramSC>
                    <h4>{title}</h4>
                </DivBoxTitleDiagramSC>
                <ResponsiveContainer width="100%" height={249}>
                    <LineChart width='100%' height={249} data={_data}

                    >
                        {weekends.map((item) => {
                            // console.log(longestAxes);

                            return (
                                <ReferenceArea
                                    // xAxisId={'bottom'}
                                    key={`ReferenceArea-${item.start}-sdassd`}
                                    x1={item.start}
                                    x2={item.end}
                                    fill="red"
                                    fillOpacity={0.1}
                                    stroke="red"
                                    strokeOpacity={0}
                                />
                            );
                        })}
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            // xAxisId="bottom"
                            // orientation="bottom"
                            dataKey="name"
                            padding={{ left: 30, right: 30 }}
                            type="category"
                            // allowDuplicatedCategory={isAggregation}
                            style={axisXMainStyle}

                        />

                        <YAxis
                            tickSize={10}
                            tickFormatter={formatYAxis}
                            label={{ value: label, angle: -90, position: 'insideBottomLeft', offset: 3 }}
                            allowDecimals={false}
                            scale="linear"
                        >

                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend
                            iconType={'rect'}
                            iconSize={10}
                            height={20}
                            formatter={renderColorfulLegendText}
                            // wrapperStyle={{
                            //     paddingLeft: 20,
                            //     paddingRight: 20,
                            //     paddingBottom: 10,
                            //     boxSizing: 'border-box',
                            //     left: 0
                            //
                            // }}
                        />
                        <Line
                            type="monotone"
                            dataKey={_data.length > 0 ? _data[0]._name : 'pv'}
                            stroke={colors ? colors[`outdooradspace:${id}`] : "#8884d8"}
                            activeDot={{ r: 4 }}
                            dot={
                                {
                                    strokeWidth: 0,
                                    r: 2,
                                    fill: colors ? colors[`outdooradspace:${id}`] : "#8884d8",
                                    fillOpacity: 1,
                                }
                            }
                            strokeWidth={1}
                        />
                        {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                    </LineChart>
                </ResponsiveContainer>
            </DivLineChartCustomSC>
        );
    }

    return (
        <DivLineChartCustomSC>
            <DivBoxTitleDiagramSC>
                <h4>{title}</h4>
            </DivBoxTitleDiagramSC>
            <ResponsiveContainer width="100%" height={249}>
                <LineChart width='100%' height={249} data={_data}>
                    {weekends.map((item) => {
                        // console.log(longestAxes);

                        return (
                            <ReferenceArea
                                // xAxisId={'bottom'}
                                key={`ReferenceArea-${item.start}-sdassd`}
                                x1={item.start}
                                x2={item.end}
                                fill="red"
                                fillOpacity={0.1}
                                stroke="red"
                                strokeOpacity={0}
                            />
                        );
                    })}
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        // xAxisId="bottom"
                        // orientation="bottom"
                        dataKey="name"
                        padding={{ left: 30, right: 30 }}
                        type="category"
                        // allowDuplicatedCategory={isAggregation}
                        style={axisXMainStyle}

                    />

                    <YAxis
                        tickSize={10}
                        tickFormatter={formatYAxis}
                        label={{ value: label, angle: -90, position: 'insideBottomLeft', offset: 3 }}
                        allowDecimals={false}

                    >
                    </YAxis>
                    <Tooltip content={<CustomTooltip22/>} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend
                        iconType={'rect'}
                        iconSize={10}
                        height={20}
                        // wrapperStyle={{
                        //     paddingLeft: 20,
                        //     paddingRight: 20,
                        //     paddingBottom: 10,
                        //     boxSizing: 'border-box',
                        //     left: 0
                        //
                        // }}
                    />
                    <Line
                        type="monotone"
                        dataKey={_data.length > 0 ? _data[0]._name : 'pv'}
                        stroke={colors ? colors[`outdooradspace:${id}`] : "#8884d8"}
                        activeDot={{ r: 4 }}
                        dot={
                            {
                                strokeWidth: 0,
                                r: 2,
                                fill: colors ? colors[`outdooradspace:${id}`] : "#8884d8",
                                fillOpacity: 1,
                            }
                        }
                        strokeWidth={1}
                    />
                    {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                </LineChart>
            </ResponsiveContainer>
        </DivLineChartCustomSC>
    );
}

const DivLineChartCustomSC = styled.div`
    background: #ffffff;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  display: grid;
  height: 300px;
  grid-template-columns: 100%;
`

const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  h4 {
    font-size: 17px;
  }
  
  
`
const DivBoxTultipSC = styled.div`
  background: white;
  padding: 10px;
  border: 1px solid grey;
  color: ${({color}) => (color)};
`

export default LineChartCustom;