import React from 'react';
import PropTypes from 'prop-types';

const BrowserLangs = ({
                          browserLangs,
                          onSelectLang
                      }) => {
    var classNameMonday, classNameTuesday, classNameWednesday, classNameThursday, classNameFriday, classNameSaturday, classNameSunday;
    for (var key in browserLangs) {
        if (key === 'ru' && browserLangs[key] === true) {
            classNameMonday = 'activeDay';
        }
        else if (key === 'en' && browserLangs[key] === true) {
            classNameTuesday = 'activeDay';
        }
        else if (key === 'zh' && browserLangs[key] === true) {
            classNameWednesday = 'activeDay';
        }
        else if (key === 'es' && browserLangs[key] === true) {
            classNameThursday = 'activeDay';
        }

    }
    return (
        <div className='radiusBrowserLangsBox' >
            <div id='RU' onClick={(e) => onSelectLang(e)} className={classNameMonday}>
                <span>{'RU'}</span>
            </div>
            <div id='EN' onClick={(e) => onSelectLang(e)} className={classNameTuesday}>
                <span>{'EN'}</span>
            </div>
            <div id='ZH' onClick={(e) => onSelectLang(e)} className={classNameWednesday}>
                <span>{'ZH'}</span>
            </div>
            <div id='ES' onClick={(e) => onSelectLang(e)} className={classNameThursday}>
                <span>{'ES'}</span>
            </div>

        </div>
    );
};

BrowserLangs.propTypes = {};
BrowserLangs.defaultProps = {};

export default BrowserLangs;