import cloneDeep from "lodash/cloneDeep";
import {useLayoutEffect, useState} from "react";


const tools = {
    regexpCreateNewArrText: (type, value) => {
        let arrayText = []
        let regMailru = /^([\w@\-_.]{4,})([, \n]+([\w@\-_.]{4,}))*$/g
        let reg2MailRu = /[\w@\-_.]{4,}/gi
        let regyandexru1 = /^([-.\w]+@([\w-]+\.)+[\w-]+)([, \n]+([-.\w]+@([\w-]+\.)+[\w-]+))*$/g
        let regyandexru = /[-.\w]+@([\w-]+\.)+[\w-]+/g
        let valueTemp = cloneDeep(value)
        if (type === 'mailru') {
            const arrayIsCorrect = valueTemp.match(regMailru);

            if (arrayIsCorrect) {
                arrayText = arrayIsCorrect[0].match(reg2MailRu);
            }
        }
        else if (type === 'yandexru') {
            const arrayIsCorrect = valueTemp.match(regyandexru1);
            // console.log('>>>>>>>>>>>>>>>>>', arrayIsCorrect)
            if (arrayIsCorrect) {
                arrayText = arrayIsCorrect[0].match(regyandexru);
            }
        }

        return arrayText
    },
    getColorText: (value) => {
        let fontColor;
        if (value === 'success' || value === 'in-progress,') {
            fontColor = '#46b875';
        } else if (value === 'failure') {
            fontColor = '#d50102';
        } else if (value === 'initial') {
            fontColor = 'rgb(255,203,107)';
        }

        return fontColor;
    },
    useWindowSize: () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
}

export default tools;