// import cookie from 'react-cookies';
import Cookies from "universal-cookie";
import Tools, { generateId } from "../tools/tools";
import {
    template_0,
    template_1,
    template_2,
    template_3,
    template_4,
} from "./InitialPermanentDataNew";
// import { template_0, template_1, template_2, template_3, template_4 } from './InitialPermanentData';

const cookie = new Cookies();

const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 800);

const initState = {
    initialPermanentData: null,
    section_list: [],
    isPermanentCloudDataReceived: false,
    permanentCloudData: null,
    sectionsByKeyName: undefined,
    scrollWidth: 0,
    sectionHistory: {},
    shareButtonPressed: false,
    status: "",
    cabinetMode: "",
    dataSections: [],
    showFavoritesOnly: false,
    statusLeftPanel: "",
    dataSelectSections: {},
    reqInput: [],
    locationPhathName: null,
    localeLanguage: "",
    lang: "en",
    statusDropDownLogout: false,
    isShowDashboard: false,
};

const onTopMenuButtonClick = (state, action) => {
    return Object.assign({}, state, {
        status: action.status,
        dataSelectSections: action.section,
        reqInput: action.reqInput,
        statusLeftPanel: state.sectionHistory[action.status].key_name,
    });
};

const changeSectionHistory_TopPanelHotspotApp = (state, action) => {
    const { key_name, currentSection } = action.payload;

    const { sectionsByKeyName } = state;

    const sectionHistory = {
        ...state.sectionHistory,
        [currentSection]: sectionsByKeyName[currentSection].subsections.filter(
            (sub) => sub.key_name === key_name
        )[0],
    };

    return { ...state, sectionHistory };
};

const selectButton = (state, action) => {
    return Object.assign({}, state, {
        statusLeftPanel: action.data.key_name,
        cabinetMode: "",
        // dataSelectSections:action.data,
        // statusLeftPanel: action.data.subsections.length > 0 ? action.data.subsections[0].key_name : null,
        reqInput: action.data.required_input_parameters,
    });
};

const generateMode = (arr) => {
    if (arr.includes("pl_id") && !arr.includes("pl_ids")) {
        return "singleLocation";
    } else if (
        arr.includes("pl_ids") &&
        arr.includes("obj_ids") &&
        arr.includes("contra_obj_ids") &&
        arr.includes("main_obj_ids")
    ) {
        return "main_set_2_contra_set";
    } else if (
        arr.includes("pl_ids") &&
        arr.includes("obj_ids") &&
        arr.includes("contra_obj_ids") &&
        arr.includes("main_obj_id")
    ) {
        return "main_2_contra_set";
    } else if (arr.includes("pl_ids") && arr.includes("obj_ids")) {
        return "multiLocation_multiObject";
    } else if (arr.includes("pl_ids")) {
        return "multiLocation";
    } else {
        return "multiAll";
    }
};

const buildSections = (state, data, firstRun = true) => {
    const sectionsByKeyName = {};

    const sectionHistory = {};
    let favoritesArr = [];
    const favorites = cookie.get("favorites");
    if (!favorites) {
        cookie.set("favorites", "", { path: "/" }, expires);
    } else {
        const favoritesSet = new Set([...favorites.split(",")]);
        favoritesArr = Array.from(favoritesSet);
    }

    const dataSections = injectFavorites(data, favoritesArr);

    dataSections.unshift(state.permanentCloudData.custom_section);

    dataSections.forEach((item) => {
        const subsections = item.subsections.map((item) => {
            return { ...item, mode: generateMode(item.required_input_parameters) };
        });
        sectionsByKeyName[item.key_name] = item;
        sectionsByKeyName[item.key_name].subsections = subsections;
    });

    let showFavoritesOnly = cookie.get("showFavoritesOnly");
    if (showFavoritesOnly === "true") {
        showFavoritesOnly = true;
    } else {
        showFavoritesOnly = false;
    }

    let locationPhathName = null;
    let locationPhathNameSecond = null;
    if (state.locationPhathName !== null) {
        locationPhathName = state.locationPhathName.split("/")[2];
        locationPhathNameSecond = state.locationPhathName.split("/")[3];
    }

    let dataSelectSections = dataSections[0];
    let statusLeftPanel = dataSections[0].subsections[0].key_name;
    let reqInput = dataSections[0].subsections[0].required_input_parameters;
    const favoritesSubsections = dataSections[0].subsections.filter(
        (item) => item.favorite
    );
    if (showFavoritesOnly && favoritesSubsections.length !== 0) {
        statusLeftPanel = favoritesSubsections[0].key_name;
        reqInput = favoritesSubsections[0].required_input_parameters;
    }
    let status = dataSections[0].key_name;
    let cabinetMode = "";
    for (var i = 0; i < dataSections.length; i++) {
        if (dataSections[i].key_name === locationPhathName) {
            dataSelectSections = dataSections[i];
            status = dataSections[i].key_name;
            if (dataSections[i].subsections.length > 0) {
                statusLeftPanel = dataSections[i].subsections[0].key_name;
                cabinetMode = "";
                reqInput = dataSections[i].subsections[0].required_input_parameters;
            } else {
                statusLeftPanel = "";
                reqInput = [];
            }
        }
        for (var j = 0; j < dataSections[i].subsections.length; j++) {
            if (dataSections[i].subsections[j].key_name === locationPhathNameSecond) {
                statusLeftPanel = dataSections[i].subsections[j].key_name;
                cabinetMode = "";
                reqInput = dataSections[i].subsections[j].required_input_parameters;
            }
            if (
                locationPhathNameSecond ===
                `${dataSections[i].subsections[j].key_name}-pdf` ||
                locationPhathNameSecond ===
                `${dataSections[i].subsections[j].key_name}-xlsx`
            ) {
                statusLeftPanel = dataSections[i].subsections[j].key_name;
                cabinetMode = locationPhathNameSecond.match(/-\w{3,4}/i)[0];
                reqInput = dataSections[i].subsections[j].required_input_parameters;
            }
        }
    }

    dataSections.forEach((section) => {
        sectionHistory[section.key_name] = section.subsections[0];
    });

    const _dataSections = dataSections.filter((item) => {
        if (item.key_name === "custom_section") {
            return initState.isShowDashboard;
        }
        return true;
    });
    if (!initState.isShowDashboard) {
        if (status === "custom_section") {
            status = _dataSections[0]?.key_name;
            statusLeftPanel = _dataSections[0]?.subsections[0].key_name;
        }
    }
    return firstRun
        ? {
            section_list: data,
            sectionsByKeyName,
            sectionHistory,
            showFavoritesOnly,
            dataSections: _dataSections,
            dataSelectSections,
            statusLeftPanel,
            status,
            cabinetMode,
            reqInput,
        }
        : {
            //   section_list: data,
            sectionsByKeyName,
            //   sectionHistory,
            //   showFavoritesOnly,
            //   dataSections,
            dataSelectSections,
            //   statusLeftPanel,
            //   status,
            //   cabinetMode,
            //   reqInput,
        };
};

const setSections = (state, action) => {
    const data = action.json;

    return Object.assign({}, state, buildSections(state, data));
};

const getLocation = (state, action) => {
    if (state.dataSections !== null && state.dataSections.length > 0) {
        action["json"] = state.dataSections;

        let locationPhathName = action.phathName.split("/")[2];
        let locationPhathNameSecond = action.phathName.split("/")[3];

        let dataSelectSections = action.json[0],
            status = action.json[0].key_name,
            statusLeftPanel = action.json[0].subsections[0].key_name,
            reqInput = action.json[0].subsections[0].required_input_parameters;
        for (var i = 0; i < action.json.length; i++) {
            if (action.json[i].key_name === locationPhathName) {
                dataSelectSections = action.json[i];
                status = action.json[i].key_name;
                if (action.json[i].subsections.length > 0) {
                    statusLeftPanel = action.json[i].subsections[0].key_name;
                    reqInput = action.json[i].subsections[0].required_input_parameters;
                } else {
                    statusLeftPanel = "";
                    reqInput = [];
                }
            }
            for (var j = 0; j < action.json[i].subsections.length; j++) {
                if (
                    action.json[i].subsections[j].key_name === locationPhathNameSecond
                ) {
                    statusLeftPanel = action.json[i].subsections[j].key_name;
                    reqInput = action.json[i].subsections[j].required_input_parameters;
                }
            }
        }
        return Object.assign({}, state, {
            dataSections: action.json,
            dataSelectSections: dataSelectSections,
            statusLeftPanel: statusLeftPanel,
            status: status,
            reqInput: reqInput,
            locationPhathName: action.phathName,
            lang: action.phathName.split("/")[1],
        });
    }
    return Object.assign({}, state, {
        locationPhathName: action.phathName,
        lang: action.phathName.split("/")[1],
    });
};

const getLocaleLanguage = (state, action) => {
    return Object.assign({}, state, {
        localeLanguage: action.lang,
        lang: action.lang === "ru-RU" ? "ru" : "en",
    });
};

const onDropDownLogout = (state, action) => {
    return Object.assign({}, state, {
        statusDropDownLogout: action.status,
    });
};

const onShowFavoritesOnlyClick = (state, action) => {
    const { showFavoritesOnly } = action.payload;
    cookie.set("showFavoritesOnly", String(showFavoritesOnly), {
        path: "/",
        expires,
    });
    return { ...state, showFavoritesOnly };
};

const onShareButtonClick = (state, action) => {
    const { shareButtonPressed } = action.payload;
    return { ...state, shareButtonPressed };
};

const fillAddressFromNav_TopPanelHotspotApp = (state, action) => {
    const { addressArr } = action.payload;
    const lang = addressArr[0];
    const status = addressArr[1];
    const statusLeftPanel = addressArr[2];
    return { ...state, lang, status, statusLeftPanel };
};

const setScrollWidth_TopPanelHotspotApp = (state, action) => {
    const { scrollWidth } = action.payload;
    // console.log(scrollWidth);

    return { ...state, scrollWidth };
};

const changeCustomSection = (state, action) => {
    return { ...state, ...buildSections(state, state.section_list, false) };
};

const createNewSubsection_TopPanel = (state, action) => {
    const { subsectionName } = action.payload;
    const { subsections } = state.permanentCloudData.custom_section;
    subsections.push(generateNewSubsection(subsectionName));

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const createNewGroup_TopPanel = (state, action) => {
    const { groupName, subsectionID } = action.payload;
    const subsections = state.permanentCloudData.custom_section.subsections.map(
        (item) => {
            if (item.id !== subsectionID) {
                return item;
            } else {
                const { data_view_groups } = item;
                data_view_groups.push(
                    generateNewGroup(groupName, subsectionID, item.key_name)
                );
                return { ...item, data_view_groups };
            }
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const renameGroup_TopPanel = (state, action) => {
    const { newGroupName, subsectionID, groupID } = action.payload;
    const subsections = state.permanentCloudData.custom_section.subsections.map(
        (item) => {
            if (item.id !== subsectionID) {
                return item;
            } else {
                const data_view_groups = item.data_view_groups.map((group) => {
                    if (group.id !== groupID) {
                        return group;
                    } else {
                        return {
                            ...group,
                            translations: {
                                en: { name: newGroupName },
                                ru: { name: newGroupName },
                                es: { name: newGroupName },
                            },
                        };
                    }
                });
                return { ...item, data_view_groups };
            }
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const deleteSubsection_TopPanel = (state, action) => {
    const { subsectionID } = action.payload;
    const subsections =
        state.permanentCloudData.custom_section.subsections.filter(
            (subsection) => subsection.id !== subsectionID
        );

    const custom_dataViews = state.permanentCloudData.custom_dataViews.filter(
        (dataView) => {
            return dataView.subsectionID !== subsectionID;
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_dataViews,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const deleteGroup_TopPanel = (state, action) => {
    const { groupID, subsectionID } = action.payload;

    const subsections = state.permanentCloudData.custom_section.subsections.map(
        (item) => {
            if (item.id !== subsectionID) {
                return item;
            } else {
                const data_view_groups = item.data_view_groups.filter(
                    (item) => item.id !== groupID
                );
                return { ...item, data_view_groups };
            }
        }
    );

    const custom_dataViews = state.permanentCloudData.custom_dataViews.filter(
        (dataView) => {
            return dataView.group.id !== groupID;
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_dataViews,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const onEditGroupClick_TopPanel = (state, action) => {
    const { groupID, subsectionID } = action.payload;

    const subsections = state.permanentCloudData.custom_section.subsections.map(
        (item) => {
            const data_view_groups = item.data_view_groups.map((item) => {
                if (item.id !== groupID || !groupID) {
                    return { ...item, editable: false };
                } else {
                    return { ...item, editable: true };
                }
            });
            return { ...item, data_view_groups };
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const onMoveGroupClick_TopPanel = (state, action) => {
    const { groupID, subsectionID, direction } = action.payload;

    const arrayMove = (arr, fromIndex, toIndex) => {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    };

    const subsections = state.permanentCloudData.custom_section.subsections.map(
        (item) => {
            if (item.id !== subsectionID) {
                return item;
            } else {
                const data_view_groups = [...item.data_view_groups];
                const currentIndex = data_view_groups.findIndex(
                    (item) => item.id === groupID
                );
                arrayMove(
                    data_view_groups,
                    currentIndex,
                    direction === "up" ? currentIndex - 1 : currentIndex + 1
                );
                return { ...item, data_view_groups };
            }
        }
    );

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections,
            },
        },
    };
};

const renameSubsection_TopPanel = (state, action) => {
    const { subsectionID, newSubsectionName } = action.payload;
    const { subsections } = state.permanentCloudData.custom_section;
    const renamedSubsection = {
        ...subsections.filter((item) => item.id === subsectionID)[0],
        translations: {
            en: { name: newSubsectionName },
            ru: { name: newSubsectionName },
            es: { name: newSubsectionName },
        },
    };

    const newSubsections = subsections.map((item) => {
        if (item.id === subsectionID) {
            return renamedSubsection;
        } else {
            return item;
        }
    });

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_section: {
                ...state.permanentCloudData.custom_section,
                subsections: newSubsections,
            },
        },
    };
};

const setInitialPermanentCloudData_TopPanel = (state, action) => {
    const { options } = action.payload;
    initState.isShowDashboard = options.includes("product:my_dashboard");
    if (
        options.includes("product:radius_basic") ||
        options.includes("product:radius_social")
    ) {
        initState.initialPermanentData = template_1;
    } else if (
        options.includes("product:analytics") &&
        !options.includes("product:radius_basic") &&
        !options.includes("product:outdoor_ad") &&
        !options.includes("product:radius_social")
    ) {
        initState.initialPermanentData = template_2;
    } else if (
        !options.includes("product:analytics") &&
        (options.includes("product:radius_basic") ||
            options.includes("product:radius_social"))
    ) {
        initState.initialPermanentData = template_3;
    } else if (options.includes("product:outdoor_ad")) {
        initState.initialPermanentData = template_4;
    } else {
        initState.initialPermanentData = template_0;
    }

    return state;
};

const injectFavorites = (dataSections, favoritesArr) => {
    return dataSections.map((item) => {
        return {
            ...item,
            subsections: item.subsections.map((item) => {
                return { ...item, favorite: favoritesArr.includes(String(item.id)) };
            }),
        };
    });
};

const injectFavoritesNew = (
    sectionsByKeyName,
    currentSection,
    favoritesArr
) => {
    const result = { ...sectionsByKeyName };
    const subsections = result[currentSection].subsections.map((item) => {
        return { ...item, favorite: favoritesArr.includes(String(item.id)) };
    });

    result[currentSection] = { ...result[currentSection], subsections };
    return result;
};

const onFavoritesStarClick = (state, action) => {
    // console.log(state.sectionsByKeyName);
    // console.log(state.dataSections);

    const { id, currentSection } = action.payload;
    // console.log(id);
    let favoritesArr = [];
    const favorites = cookie.get("favorites");
    if (!favorites || favorites.length === 0) {
        favoritesArr.push(String(id));
        cookie.set("favorites", id, { path: "/", expires });
    } else {
        const favoritesSet = new Set([...favorites.split(",")]);
        favoritesArr = Array.from(favoritesSet);
        if (favoritesArr.includes(String(id))) {
            favoritesArr.splice(favoritesArr.indexOf(String(id)), 1);
        } else {
            favoritesArr.push(String(id));
        }
        cookie.set("favorites", favoritesArr.join(","), { path: "/", expires });
    }
    const dataSections = injectFavorites(state.dataSections, favoritesArr);
    const sectionsByKeyName = injectFavoritesNew(
        state.sectionsByKeyName,
        currentSection,
        favoritesArr
    );
    // console.log('favoritesArr', favoritesArr);
    // console.log('dataSections', dataSections);

    return { ...state, dataSections, sectionsByKeyName };
};

const generateNewSubsection = (subsectionName = "default") => {
    const subsection_id = `Subsection@custom:${generateId()}`;
    const group_id = `Group@custom:${generateId()}`;
    let key_name = subsectionName;
    if (key_name.toLowerCase() !== "default") {
        key_name = `${generateId(6)}`;
    }
    return {
        id: subsection_id,
        data_view_groups: [
            {
                editable: false,
                id: group_id,
                custom_key_name: null,
                translations: {
                    en: { name: "Group 1" },
                    ru: { name: "Group 1" },
                    es: { name: "Group 1" },
                },
                order_index: 0,
                source_of_data: "REAL_DATA",
                subsection_id,
                subsection_key_name: key_name,
            },
        ],
        favorite: false,
        key_name,
        translations: {
            en: { name: subsectionName },
            ru: { name: subsectionName },
            es: { name: subsectionName },
        },
        order_index: 0,
        required_input_parameters: [],
        section: null,
        show_item_colors: true,
        source_of_data: "REAL_DATA",
        width: 5,
    };
};

const generateNewGroup = (groupName, subsection_id, key_name) => {
    const group_id = `Group@custom:${generateId()}`;
    return {
        editable: false,
        id: group_id,
        custom_key_name: null,
        translations: {
            en: { name: groupName },
            ru: { name: groupName },
            es: { name: groupName },
        },
        order_index: 0,
        source_of_data: "REAL_DATA",
        subsection_id,
        subsection_key_name: key_name,
    };
};

const addDiagramToC_D_TopPanel = (state, action) => {
    const { dataView, group, subsection } = action.payload;
    const { permanentCloudData } = state;

    if (!permanentCloudData.custom_dataViews) {
        permanentCloudData.custom_dataViews = [];
    }

    const custom_dataViews = state.permanentCloudData.custom_dataViews || [];
    custom_dataViews.push({
        ...dataView,
        group,
        layout_item: null,
        data_view_id: `dataview@custom:${generateId(6)}`,
        subsectionID: subsection.id,
    });

    return {
        ...state,
        permanentCloudData: {
            ...state.permanentCloudData,
            custom_dataViews,
        },
    };
};

const deleteDiagramFromC_D_TopPanel = (state, action) => {
    const { dataView } = action.payload;
    const custom_dataViews = state.permanentCloudData.custom_dataViews.filter(
        (item) => item.data_view_id !== dataView.data_view_id
    );

    return {
        ...state,
        permanentCloudData: { ...state.permanentCloudData, custom_dataViews },
    };
};

const changeLayoutC_D_TopPanel = (state, action) => {
    const { newLayout, currentSubSection, groupID } = action.payload;
    // console.log(newLayout);
    // console.log(state.permanentCloudData.custom_dataViews);

    const custom_dataViews = state.permanentCloudData.custom_dataViews.map(
        (item) => {
            if (item.group.id !== groupID) {
                return item;
            } else {
                let layout_item = newLayout.filter(
                    (lay) => lay.i === item.data_view_id
                )[0];
                return { ...item, layout_item };
            }
        }
    );

    return {
        ...state,
        permanentCloudData: { ...state.permanentCloudData, custom_dataViews },
    };
};

const fillPermanentParamsFromCloud = (state, action) => {
    if (!action.payload.response) {
        console.log("PermanentCloudData are not Received");
        return {
            ...state,
            isPermanentCloudDataReceived: true,
        };
    }

    let permanentCloudData = { ...action.payload.response };

    if (permanentCloudData.custom_dataViews) {
        const custom_dataViews = permanentCloudData.custom_dataViews.filter(
            (item) => {
                return (
                    item.hasOwnProperty("data_report") &&
                    item.hasOwnProperty("data_view_type")
                );
            }
        );
        permanentCloudData = { ...action.payload.response, custom_dataViews };
    }

    if (permanentCloudData.custom_section === undefined) {
        permanentCloudData = Tools.copy(initState.initialPermanentData);
    }

    return {
        ...state,
        permanentCloudData,
        isPermanentCloudDataReceived: true,
    };
};

const resetMyDashboard_TopPanel = (state, action) => {
    return {
        ...state,
        permanentCloudData: Tools.copy(initState.initialPermanentData),
        // isPermanentCloudDataReceived: true,
    };
};

const TopPanel = (state = initState, action) => {
    switch (action.type) {
        case "onTopMenuButtonClick":
            return onTopMenuButtonClick(state, action);
        case "setSections":
            return setSections(state, action);
        case "onShowFavoritesOnlyClick":
            return onShowFavoritesOnlyClick(state, action);
        case "onFavoritesStarClick":
            return onFavoritesStarClick(state, action);
        case "onSelectLeftPanel":
            return selectButton(state, action);
        case "getLocation":
            return getLocation(state, action);
        case "onShareButtonClick":
            return onShareButtonClick(state, action);
        case "getLocaleLanguage":
            return getLocaleLanguage(state, action);
        case "onDropDownLogout":
            return onDropDownLogout(state, action);
        case "fillAddressFromNav_TopPanelHotspotApp":
            return fillAddressFromNav_TopPanelHotspotApp(state, action);
        case "changeSectionHistory_TopPanelHotspotApp":
            return changeSectionHistory_TopPanelHotspotApp(state, action);
        case "setScrollWidth_TopPanelHotspotApp":
            return setScrollWidth_TopPanelHotspotApp(state, action);
        case "onLanguageChange":
            return { ...state, lang: action.value };
        case "fillPermanentParamsFromCloud":
            return fillPermanentParamsFromCloud(state, action);
        case "changeCustomSection":
            return changeCustomSection(state, action);
        case "createNewSubsection_TopPanel":
            return createNewSubsection_TopPanel(state, action);
        case "deleteSubsection_TopPanel":
            return deleteSubsection_TopPanel(state, action);
        case "renameSubsection_TopPanel":
            return renameSubsection_TopPanel(state, action);
        case "createNewGroup_TopPanel":
            return createNewGroup_TopPanel(state, action);
        case "renameGroup_TopPanel":
            return renameGroup_TopPanel(state, action);
        case "deleteGroup_TopPanel":
            return deleteGroup_TopPanel(state, action);
        case "addDiagramToC_D_TopPanel":
            return addDiagramToC_D_TopPanel(state, action);
        case "deleteDiagramFromC_D_TopPanel":
            return deleteDiagramFromC_D_TopPanel(state, action);
        case "changeLayoutC_D_TopPanel":
            return changeLayoutC_D_TopPanel(state, action);
        case "resetMyDashboard_TopPanel":
            return resetMyDashboard_TopPanel(state, action);
        case "onEditGroupClick_TopPanel":
            return onEditGroupClick_TopPanel(state, action);
        case "onMoveGroupClick_TopPanel":
            return onMoveGroupClick_TopPanel(state, action);
        case "setInitialPermanentCloudData_TopPanel":
            return setInitialPermanentCloudData_TopPanel(state, action);

        default:
            return state;
    }
};

export default TopPanel;
