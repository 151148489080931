import Plan from './Plan';
import styled from 'styled-components/macro';

// var $ = require('jquery');
// var TimePicker = require('material-ui').TimePicker;
// var baseTheme = require('material-ui/styles/baseThemes/lightBaseTheme');
// var getMuiTheme = require('material-ui/styles/getMuiTheme');
import WeekBox from './weekBox';
import DateBox from './dateBox';
import BrowserLangs from './browserLangs';

import React, { Component } from 'react';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    padding-top: 15px;
`;

const Wrapper = styled.section`
    display: flex;
    align-items: center;
    & > p {
        margin: 5px;
    }
`;

const Or = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RadisBox = styled.section`
    display: grid;
    grid-template-columns: 3fr 6fr 2fr 3fr;
    grid-row-gap: 10px;
    min-height: 40px;
    margin: 5px;
    & > span {
        display: flex;
        align-items: center;
    }
`;

class TimesOfDay extends Component {
    state = {};

    internalState = {
        isChecked: false,
        isMounted: false,
        timeFrom: {
            hours: null,
            minuts: null
        },
        timeto: {
            hours: null,
            minuts: null
        }
    };

    onChangeTimeMax(e) {
        if (e.target.name === 'hoursTo') {
            this.internalState.timeto.hours = e.target.value;
        } else if (e.target.name === 'minutsTo') {
            this.internalState.timeto.minuts = e.target.value;
        }
        if (this.internalState.timeto.minuts !== null) {
            if (this.internalState.timeto.hours !== null) {
                this.props.getTimeMax({
                    timeString: this.internalState.timeto.hours + ':' + this.internalState.timeto.minuts
                });
            } else if (this.internalState.timeto.hours === null) {
                this.props.getTimeMax({
                    timeString: this.props.time.to.substring(0, 2) + ':' + this.internalState.timeto.minuts
                });
            }
        } else if (this.internalState.timeto.minuts === null) {
            if (this.internalState.timeto.hours !== null) {
                this.props.getTimeMax({
                    timeString: this.internalState.timeto.hours + ':' + this.props.time.to.substring(3, 5)
                });
            }
        }
    }
    onChangeTimeMin(e) {
        // substring(0, 19)
        if (e.target.name === 'hoursFrom') {
            this.internalState.timeFrom.hours = e.target.value;
        } else if (e.target.name === 'minutesFrom') {
            this.internalState.timeFrom.minuts = e.target.value;
        }
        if (this.internalState.timeFrom.minuts !== null) {
            if (this.internalState.timeFrom.hours !== null) {
                this.props.getTimeMin({
                    timeString: this.internalState.timeFrom.hours + ':' + this.internalState.timeFrom.minuts
                });
            } else if (this.internalState.timeFrom.hours === null) {
                this.props.getTimeMin({
                    timeString: this.props.time.from.substring(0, 2) + ':' + this.internalState.timeFrom.minuts
                });
            }
        } else if (this.internalState.timeFrom.minuts === null) {
            if (this.internalState.timeFrom.hours !== null) {
                this.props.getTimeMin({
                    timeString: this.internalState.timeFrom.hours + ':' + this.props.time.from.substring(3, 5)
                });
            }
        }
    }
    onClickTimeMax() {
        this.props.getTimeMax({
            timeString:
                this.props.time.to === null ? '16:00' : this.props.time.to.substring(0, 2) + ':' + this.props.time.to.substring(3, 5)
        });
    }
    onClickTimeMin() {
        this.props.getTimeMin({
            timeString:
                this.props.time.from === null ? '12:00' : this.props.time.from.substring(0, 2) + ':' + this.props.time.from.substring(3, 5)
        });
    }

    componentDidMount() {
        this.internalState.isChecked = false;
        this.internalState.isMounted = true;
    }

    componentWillUnmount() {
        this.internalState.isMounted = false;
    }
    onClickButtonAllDay() {
        if (this.props.dataPlan.week_days.length < 7) {
            this.props.selectAllDay();
        }
    }
    onClickButtonAllLang() {
        if (this.props.browserLangsForAnyButton !== null) {
            this.props.onClickButtonAllLang();
        }
    }
    onClickAllTime() {
        this.props.selectAllTime();
    }
    onClickAllDate() {
        this.props.selectAllDate();
    }

    hidePanelOfDateTimeBox() {
        this.props.setStatusPlanPanel(false);
        this.props.resetData();
    }
    save() {
        // console.log('>>>>>>>>>>>>>>111',this.props.pl_ids);
        var dataPlan = {
            dataPlan: {
                id: this.props.dataPlan.id,
                page_id: this.props.dataPlan.page_id,
                objetct_ids: [this.props.pl_ids],
                time_period: this.props.dataPlan.time_period,
                week_days: this.props.dataPlan.week_days,
                date_period: this.props.dataPlan.date_period,
                browser_lang_code: this.props.browserLangsForAnyButton
            },
            xToken: this.props.token
        };

        // console.log('timesOfDay.jsx', dataPlan.dataPlan.time_period);
        this.props.save(dataPlan, this.props._t);
    }

    render() {
        // console.log('>>>>>>>>>>>>>>/**/1112',this.props.pl_ids);
        var styleButtonOfWeek,
            styleButtonOfTime,
            statusDisabledTime = this.props.dataPlan.time_period.always,
            styleNameBoxTimeMin,
            styleNameBoxTimeMax,
            styleButtonOfDate,
            styleDateBox,
            componentSpinerLoader,
            dateToHour,
            dateToMin,
            dateFromHour,
            dateFromMin;
        if (this.props.statusEdit === false) {
        }
        if (this.props.time.to === null || this.props.time.from === null) {
            dateToHour = 12;
            dateToMin = '00';
            dateFromHour = 16;
            dateFromMin = '00';
        } else if (this.props.time.to !== null && this.props.time.from !== null) {
            dateToHour = this.props.time.to.substring(0, 2);
            dateToMin = this.props.time.to.substring(3, 5);
            dateFromHour = this.props.time.from.substring(0, 2);
            dateFromMin = this.props.time.from.substring(3, 5);
        }
        // console.log('Plan.jsx',this.state.time.to, dateToHour);
        if (this.props.dataPlan.week_days.length === 7) {
            styleButtonOfWeek = 'activeAllDay';
        } else if (this.props.dataPlan.week_days.length < 7) {
            styleButtonOfWeek = 'notActiveAllDay';
        }
        if (this.props.dataPlan.date_period.always === true) {
            styleButtonOfDate = 'activeAllDay';
            styleDateBox = 'radiusDateBox disabledDate';
        } else if (this.props.dataPlan.date_period.always === false) {
            styleButtonOfDate = 'notActiveAllDay';
            styleDateBox = 'radiusDateBox';
        }
        if (this.props.dataPlan.time_period.always === true) {
            styleButtonOfTime = 'activeAllDay';
            styleNameBoxTimeMin = 'radiusTimeDayFrom disapledTime';
            styleNameBoxTimeMax = 'radiusTimeDayTo disapledTime';
        } else if (this.props.dataPlan.time_period.always === false) {
            styleButtonOfTime = 'notActiveAllDay';
            styleNameBoxTimeMin = 'radiusTimeDayFrom';
            styleNameBoxTimeMax = 'radiusTimeDayTo';
        }
        if (this.props.statusLoadPlan === true) {
            componentSpinerLoader = (
                <div className="spinerLoaderRadiusLoadPlan">
                    <div style={{ height: '40px', width: '40px', margin: '149px auto' }}>
                        <div className="globalSpinnerLoaderSvgWhite"></div>
                    </div>
                </div>
            );
        } else if (this.props.statusLoadPlan === false) {
            componentSpinerLoader = null;
        }

        return (
            <div className="radiusTimesOfDayBox">
                <div className="radiusTimesOfDayBoxTitle">
                    <h2>{this.props._t.t('ПЛАНИРОВАНИЕ') + ': ' + this.props.title}</h2>
                    <span onClick={e => this.hidePanelOfDateTimeBox()}>X</span>
                </div>
                <Container>
                    <RadisBox>
                        <span>{this.props._t.t('Время суток') + ':'}</span>
                        <Wrapper>
                            <div className={styleNameBoxTimeMin}>
                                <div className="radiusTimePicker">
                                    <select
                                        name="hoursFrom"
                                        onClick={e => this.onClickTimeMin()}
                                        onChange={e => this.onChangeTimeMin(e)}
                                        defaultValue={dateFromHour}
                                    >
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="00">00</option>
                                    </select>
                                </div>

                                <p>:</p>
                                <div className="radiusTimePicker">
                                    <select
                                        name="minutesFrom"
                                        onClick={e => this.onClickTimeMin()}
                                        onChange={e => this.onChangeTimeMin(e)}
                                        defaultValue={dateFromMin}
                                    >
                                        <option value="05">05</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="00">00</option>
                                    </select>
                                </div>
                            </div>
                            <p>-</p>
                            <div className={styleNameBoxTimeMax}>
                                <div className="radiusTimePicker">
                                    <select
                                        name="hoursTo"
                                        onClick={e => this.onClickTimeMax()}
                                        onChange={e => this.onChangeTimeMax(e)}
                                        defaultValue={dateToHour}
                                    >
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="00">00</option>
                                    </select>
                                </div>

                                <p>:</p>
                                <div className="radiusTimePicker">
                                    <select
                                        name="minutsTo"
                                        onClick={e => this.onClickTimeMax()}
                                        onChange={e => this.onChangeTimeMax(e)}
                                        defaultValue={dateToMin}
                                    >
                                        <option value="05">05</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="00">00</option>
                                    </select>
                                </div>
                            </div>
                        </Wrapper>
                        <Or>{this.props._t.t('или')}</Or>
                        <button onClick={e => this.onClickAllTime()} className={styleButtonOfTime}>
                            {this.props._t.t('Всегда')}
                        </button>
                    </RadisBox>
                    <RadisBox>
                        <span>{this.props._t.t('Дни недели') + ':'}</span>

                        <WeekBox _t={this.props._t} dataPlan={this.props.dataPlan} onClickDay={this.props.onClickDay} />
                        <Or>{this.props._t.t('или')}</Or>
                        <button onClick={e => this.onClickButtonAllDay()} className={styleButtonOfWeek}>
                            {this.props._t.t('Всегда')}
                        </button>
                    </RadisBox>
                    <RadisBox>
                        <span>{this.props._t.t('Период') + ':'}</span>
                        <DateBox
                            prop={styleDateBox}
                            _t={this.props._t}
                            datePeriod={this.props.dataPlan.date_period}
                            showMaxCalendar={this.props.showMaxCalendar}
                            handleEvent={this.props.handleEvent}
                            statusShowMaxCalendar={this.props.statusShowMaxCalendar}
                        />
                        <Or>{this.props._t.t('или')}</Or>
                        <button onClick={e => this.onClickAllDate()} className={styleButtonOfDate}>
                            {this.props._t.t('Всегда')}
                        </button>
                    </RadisBox>
                    <RadisBox>
                        <span>{this.props._t.t('Язык браузера') + ':'}</span>

                        <BrowserLangs _t={this.props._t} browserLangs={this.props.browserLangs} onSelectLang={this.props.onSelectLang} />
                        <Or>{this.props._t.t('или')}</Or>
                        <button
                            onClick={e => this.onClickButtonAllLang()}
                            className={this.props.browserLangsForAnyButton === null ? 'activeAllDay' : 'notActiveAllDay'}
                        >
                            {this.props._t.t('Любой')}
                        </button>
                    </RadisBox>
                    <Footer>
                        <div className="radiusTimeBoxFooter">
                            <button onClick={e => this.save()}>{this.props._t.t('Сохранить изменения')}</button>
                            <button onClick={e => this.hidePanelOfDateTimeBox()}>{this.props._t.t('Закрыть')}</button>
                        </div>
                    </Footer>
                </Container>
                {componentSpinerLoader}
            </div>
        );
    }
}

export default TimesOfDay;
