import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

class SpinerLoader extends Component {
    static defaultProps = {};

    static propTypes = {};

    state = {};

    render() {
        return (
            <div className="spainerBox">
                <div className="containerSpiner">
                    <div className="helper">
                        <div className="content">
                            <div>
                                <ClipLoader color={'#46b875'} loading={true} size={30} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpinerLoader;
