import React from 'react';
import PropTypes from 'prop-types';

const BodyCompanent = ({ inputParameters, onChangeSelect, inputParamsSelectDataView, lang }) => {
    let componentsBodyItem = null;
    if (Object.keys(inputParameters).length > 0) {
        componentsBodyItem = Object.values(inputParameters).map((prop, id) => {
            // console.log('BodyCompanent>>>>3', inputParamsSelectDataView[prop.key_name],inputParamsSelectDataView)
            if (inputParamsSelectDataView[prop.key_name] !== undefined) {
                var componentOptions = prop.details.choices.map((prop, id1) => {
                    return <option value={id1}>{lang === 'ru' ? prop.name.default : prop.name.en}</option>;
                });

                var indexByValue = {};
                for (var i = prop.details.choices.length - 1; i >= 0; i--) {
                    var c = prop.details.choices[i];
                    indexByValue[c.value] = i;
                }

                var currentValue = indexByValue[prop.details.current_value];

                return (
                    <div className="bodyItem">
                        <span className="titleSelect">{lang === 'ru' ? prop.translations['ru'].name : prop.translations['en'].name}</span>
                        <div className="styled-select slate">
                            <select onChange={(e) => onChangeSelect(e, prop.key_name)} value={currentValue}>
                                {componentOptions}
                            </select>
                            <div className="arrow">
                                <i className="fa fa-angle-down fa-1.5x" aria-hidden="true" />
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    }
    return <div className={'bodyRightPanel'}>{componentsBodyItem}</div>;
};

BodyCompanent.propTypes = {};
BodyCompanent.defaultProps = {};

export default BodyCompanent;
