import styled from 'styled-components/macro';
import React, { useEffect, useState } from 'react';
import Tools from '../../tools/diagramTools';
import { ResponsiveChord } from '@nivo/chord';

import Legend from '../legend';

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: ${props => props.height - 66 + 'px'};
`;

export default React.memo(({ ...props }) => {
    const [colors, setColors] = useState([]);
    const [keys, setKeys] = useState([]);
    const LEGEND_WIDTH_PERCENT = 30;

    useEffect(() => {
        const { keys } = props.data;
        const colors = keys.map(item => Tools.generateColorForObjects(item));
        setKeys(() => keys);
        setColors(() => colors);
    }, [props.data.keys]);

    const containerHeight = props.size.width * (1 - LEGEND_WIDTH_PERCENT / 100);

    return (
        <Container height={containerHeight}>
            <ResponsiveChord
                matrix={props.data.intersection_matrix}
                keys={keys}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                }}
                padAngle={0.02}
                innerRadiusRatio={0.88}
                innerRadiusOffset={0.02}
                arcOpacity={1}
                arcBorderWidth={1}
                arcBorderColor="inherit:darker(0.4)"
                ribbonOpacity={0.5}
                ribbonBorderWidth={1}
                ribbonBorderColor="inherit:darker(0.4)"
                enableLabel={true}
                label=" "
                labelOffset={2}
                labelRotation={0}
                labelTextColor="inherit:darker(1)"
                colors={colors}
                isInteractive={true}
                arcHoverOpacity={1}
                arcHoverOthersOpacity={0.25}
                ribbonHoverOpacity={0.75}
                ribbonHoverOthersOpacity={0.25}
                animate={true}
                motionStiffness={90}
                motionDamping={7}
            />
            <Legend colors={colors} labels={keys} dotSize={10} widthPercent={LEGEND_WIDTH_PERCENT} />
        </Container>
    );
});
