import React from 'react';
import styled from 'styled-components/macro';

export default React.memo(({ expand, ...props }) => {
    const arrowClassName = !expand ? 'fa fa-angle-down' : 'fa fa-angle-up';

    return <Arrow className={arrowClassName} />;
});

const Arrow = styled.i`
    font-size: 16px !important;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: #777;
    /* border: 1px solid #777; */
    &:hover {
        color: #000;
        /* border: 1px solid #777; */
    }
`;
