// import DATA from '../components/maps/outdoor/data.json';

const addSelected = (data) => {
    return data.map((item) => ({
        ...item,
        outdoor_details: { ...item.outdoor_details, selected: false },
    }));
};

const initState = {
    filterByPolygon: true,
    isMapExpanded: false,
    selectedAdSpaceTypes: [0],
    searchValue: '',
    allowEditCatalog: {
        adSpaces: {
            ad_space_type: {
                field: 'select',
            },
            latitude: {
                field: 'input',
                type: 'number',
            },
            longitude: {
                field: 'input',
                type: 'number',
            },
            orientation_azimuth: {
                field: 'input',
                type: 'number',
            },
        },
        iPoints: {
            latitude: {
                field: 'input',
                type: 'number',
            },
            longitude: {
                field: 'input',
                type: 'number',
            },
            orientation_azimuth: {
                field: 'input',
                type: 'number',
            },
        },
    },
    outdoorIPointsData: [],
    innerData: {},
    spacesTypesCatalog: [],
    showIPoints: true,
    selectedSpaces: [],
    initialZoom: 11,
    // thresholdZoomLevel: 13,
    // cityThresholdZoomLevel: 15,
    mapCenter: {
        lat: 55.765158,
        lng: 37.544809,
    },

    editModal: {
        show: false,
        id: null,
        typeOfRouter: '',
    },
};

const handleInputChange = (state, action) => {
    const { point, typeOfRouter, field, value } = action.payload;
    const { innerData } = state;
    const data = innerData[typeOfRouter].map((item) => {
        return item.id !== point.id ? item : { ...item, [field]: value };
    });
    return { ...state, innerData: { ...state.innerData, [typeOfRouter]: [...data] } };
};

const handleSelectChange = (state, action) => {
    const { point, typeOfRouter, field, value } = action.payload;
    const { innerData, spacesTypesCatalog } = state;
    const option = spacesTypesCatalog.filter((item) => {
        return item.id === Number(value);
    })[0];
    const data = innerData[typeOfRouter].map((item) => {
        return item.id !== point.id ? item : { ...item, [field]: option };
    });
    return { ...state, innerData: { ...state.innerData, [typeOfRouter]: [...data] } };
};

const aimOnPoint = (state, action) => {
    const mapCenter = action.center;
    const initialZoom = action.initialZoom;
    if (initialZoom) {
        return { ...state, mapCenter, initialZoom };
    } else {
        return { ...state, mapCenter };
    }
};

const setInitialZoom = (state, action) => {
    const initialZoom = action.payload.currentZoom;
    return { ...state, initialZoom };
};

const handleSaveCenter = (state, action) => {
    const mapCenter = action.payload.center;
    return { ...state, mapCenter };
};

// const getOutdoorSpaces = (state, action) => {

//     const outdoorSpacesData = action.json;
//     console.log(outdoorSpacesData);
//     const innerData = { ...state.innerData, adSpaces: outdoorSpacesData };
//     return { ...state, outdoorSpacesData, innerData };
// };

// const getIPoints = (state, action) => {
//     const outdoorIPointsData = action.json;
//     const innerData = { ...state.innerData, iPoints: outdoorIPointsData };
//     return { ...state, outdoorIPointsData, innerData };
// };

const getSpacesTypes = (state, action) => {
    const spacesTypesCatalog = action.json;
    return { ...state, spacesTypesCatalog };
};

const onSectorClick = (state, action) => {
    const { spaceId, firstRun } = action.payload;

    const { selectedSpaces } = state;

    if (selectedSpaces.indexOf(spaceId) === -1) {
        selectedSpaces.push(spaceId);
    } else {
        if (!firstRun) {
            selectedSpaces.splice(selectedSpaces.indexOf(spaceId), 1);
        }
    }
    return { ...state, selectedSpaces: [...selectedSpaces] };
};

const resetSelectedSectors_OutdoorSpaces = (state, action) => {
    return { ...state, selectedSpaces: [] };
};

const handleSaveCenter_OutdoorSpaces = (state, action) => {
    const mapCenter = action.payload.center;
    return { ...state, mapCenter };
};

const setInitialZoom_OutdoorSpaces = (state, action) => {
    const initialZoom = action.payload.currentZoom;
    return { ...state, initialZoom };
};

const handleSearch_OutdoorSpaces = (state, action) => {
    const { text } = action.payload;
    return { ...state, searchValue: text };
};

const toggleMap_OutdoorSpaces = (state, action) => {
    return { ...state, isMapExpanded: !state.isMapExpanded };
};

const fillSelectedSpaces_OutdoorSpaces = (state, action) => {
    return { ...state, selectedSpaces: [...action.payload.selectedSpaces] };
};

const toggleFilterByPolygon_OutdoorSpaces = (state, action) => {
    const { checked } = action.payload;
    return { ...state, filterByPolygon: checked };
};

const onSelectAllSpacesClick = (state, action) => {
    const { selectedSpaces } = state;
    const filteredAdSpacesIds = action.payload.filteredAdSpaces.map((item) => item.id);
    filteredAdSpacesIds.forEach((spaceId) => {
        if (selectedSpaces.indexOf(spaceId) === -1) {
            selectedSpaces.push(spaceId);
        }
    });
    return { ...state, selectedSpaces: [...selectedSpaces] };
};

const onDeselectAllSpacesClick = (state, action) => {
    return { ...state, selectedSpaces: [] };
};

const changeSelectedTypes_OutdoorSpaces = (state, action) => {
    const s = [...state.selectedAdSpaceTypes];
    const { id } = action.payload;
    if (id === 0 || (s.length === 1 && s[0] === id)) {
        return { ...state, selectedAdSpaceTypes: [0] };
    } else if (s.includes(id)) {
        s.splice(s.indexOf(id), 1);
        return { ...state, selectedAdSpaceTypes: s };
    } else {
        s.push(id);
        if (s.includes(0)) {
            s.splice(s.indexOf(0), 1);
        }
        return { ...state, selectedAdSpaceTypes: s };
    }
};

const OutdoorSpaces = (state = initState, action) => {
    switch (action.type) {
        case 'getSpacesTypes':
            return getSpacesTypes(state, action);
        case 'onSectorClick':
            return onSectorClick(state, action);
        case 'aimOnPoint':
            return aimOnPoint(state, action);
        case 'handleSaveCenter':
            return handleSaveCenter(state, action);
        case 'setInitialZoom':
            return setInitialZoom(state, action);
        case 'handleInputChange':
            return handleInputChange(state, action);
        case 'handleSelectChange':
            return handleSelectChange(state, action);
        case 'handleSaveCenter_OutdoorSpaces':
            return handleSaveCenter_OutdoorSpaces(state, action);
        case 'setInitialZoom_OutdoorSpaces':
            return setInitialZoom_OutdoorSpaces(state, action);
        case 'handleSearch_OutdoorSpaces':
            return handleSearch_OutdoorSpaces(state, action);
        case 'toggleMap_OutdoorSpaces':
            return toggleMap_OutdoorSpaces(state, action);
        case 'changeSelectedTypes_OutdoorSpaces':
            return changeSelectedTypes_OutdoorSpaces(state, action);
        case 'resetSelectedSectors_OutdoorSpaces':
            return resetSelectedSectors_OutdoorSpaces(state, action);
        case 'onSelectAllSpacesClick':
            return onSelectAllSpacesClick(state, action);
        case 'onDeselectAllSpacesClick':
            return onDeselectAllSpacesClick(state, action);
        case 'fillSelectedSpaces_OutdoorSpaces':
            return fillSelectedSpaces_OutdoorSpaces(state, action);
        case 'toggleFilterByPolygon_OutdoorSpaces':
            return toggleFilterByPolygon_OutdoorSpaces(state, action);
        default:
            return state;
    }
};

export default OutdoorSpaces;
