import hostForLocation from '../../../../hostnames/hostname';

import PreviewMap from './previewMap';

import Panel from './statisticPanel';
import { connect } from 'react-redux';
import MapsHolst from './maps_holst';

import { runAction } from '../../../../actions';

import GeometryVSDataManager from '../../../../tools/GeometryVSDataManager';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import React, { useState, useEffect } from 'react';
import MainSelect from '../../../react_select/main';
import { getDiagramsDataFromServer } from '../../../Diagrams/tools/diagramTools';
import { getIdWithDataAspects } from '../../../Diagrams/tools/dataAspectsTools';
import Tools from '../../../../tools/tools';
import SpinerLoader from './spinerLoader';

var moment = require('moment');

const MapComponent = (props) => {
    const [typeDrower, setTipeDrower] = useState('place_layer');
    const [scaleMapsGlobal, setScaleMapsGlobal] = useState(1);
    const [scaleCastom, getCastomScale] = useState(1);
    const [widthBox, getWidthBox] = useState(0);
    const [heightBox, getHeightBox] = useState(0);
    const [statusSelectPolygons, getStatusSelectPolygons] = useState(false);
    const [showCounts, onShowCounts] = useState(false);
    const [statusColorCount, setStatusColorCount] = useState(false);
    const [typeMaps, setTypeMaps] = useState({
        value: 'places',
        label: 'Помещения',
    });
    const [zoneNameBySelected, setZoneNameBySelected] = useState('');
    const [statusCheckedBackgroundImage, onCheck] = useState(false);
    const [floorIndexSelected, getFloorIndexSelected] = useState(0);
    const [FloorIndexSelectedHolst, getFloorIndexSelectHolst] = useState(0);
    const [listReportObjects, getListReportObjects] = useState([]);
    const [selectedReportObjects, getSelectedReportObjects] = useState({});
    const [DValueMetricsByMaps, getDValueMetricsByMaps] = useState({
        maxValue: 0,
        minValue: 0,
    });
    const [objDataDataViewForMaps, getObjDataDataViewForMaps] = useState(null);
    const [statusLoadMetrics, getStatusLoadMetrics] = useState(false);
    const [listDataDataViewForMaps, getListDataDataViewForMaps] = useState([]);

    useEffect(() => {
        if (props.widthMapMax !== 0) {
            // console.log('useEffect', (window.innerWidth - 239 - 40), props.widthMapMax, (window.innerHeight - 50), props.heightMapMax);

            if (props.widthMapMax / props.heightMapMax > (window.innerWidth - 239 - 40) / (window.innerHeight - 100)) {
                setScaleMapsGlobal((window.innerWidth - 239 - 40) / props.widthMapMax);
            } else if (props.widthMapMax / props.heightMapMax <= (window.innerWidth - 239 - 40) / (window.innerHeight - 100)) {
                setScaleMapsGlobal((window.innerHeight - 100) / props.heightMapMax);
            }
            getWidthBox(window.innerWidth - 239);
            getHeightBox(window.innerHeight - 50);
            // setScaleMapsGlobal((window.innerWidth - 239 - 40) / props.widthMapMax);
        }
    }, [props.heightMapMax, props.widthMapMax]);

    useEffect(() => {
        if (props.optionTypeNewMapsObj['places'] !== undefined) {
            setTipeDrower('place_layer');
            setTypeMaps({
                value: 'places',
                label: 'Помещения',
            });
        } else {
            if (props.selectForMaps[0] !== undefined) {
                setTipeDrower(props.selectForMaps[0].value);
                setTypeMaps(props.selectForMaps[0]);
            }
        }
    }, [props.optionTypeNewMapsObj, props.selectForMaps]);
    useEffect(() => {
        // console.log('>>>>>>>>>>>>', props.statusSelectPolygons)
        getStatusSelectPolygons(props.statusSelectPolygons);
    }, [props.statusSelectPolygons]);

    // useEffect(() => {
    //
    //     props.getDateForMaps(props.selected.end);
    //
    // }, [props.selected.end]);

    useEffect(() => {
        props.getUuidDataListForMaps(props.uuidDataList);
    }, [props.uuidDataList]);

    // useEffect(() => {
    //     if (typeMaps.value !== 'places') {
    //         // console.log('>>>>>>>>>>>>>>>>>', props.uuidDataList)
    //         let maxObj = {
    //             sortData: props.uuidDataList[0].sortData,
    //             uuid: props.uuidDataList[0].value
    //         }
    //         for (var i = 0; i < props.uuidDataList.length; i++) {
    //             // console.log('>>>>>>>>>>>>>>>>>', props.uuidDataList[i].value)
    //             if (maxObj.sortData < props.uuidDataList[i].sortData) {
    //                 maxObj = {
    //                     sortData: props.uuidDataList[i].sortData,
    //                     uuid: props.uuidDataList[i].value
    //                 }
    //             }
    //         }
    //         console.log('>>>>>>>>>>>>>>>>>', maxObj.uuid, props.uuidDataSelected.value)
    //         if (maxObj.uuid !== props.uuidDataSelected.value) {
    //             props.getDataByUuid(props.uuidDataByUuid[maxObj.uuid].plan_set_uuid, props.token,props.uuidDataByUuid[maxObj.uuid].uuid)
    //         }
    //
    //
    //     }
    //     // props.getDataByUuid(props.uuidDataByUuid[value.value].plan_set_uuid, props.token,props.uuidDataByUuid[value.value].uuid)
    //     // props.uuidDataSelectedTempPlaces
    //
    // }, [ typeMaps.value, props.uuidDataList ]);

    useEffect(() => {
        props.getUuidDataForMaps(props.uuidDataByUuid);
    }, [props.uuidDataByUuid]);

    useEffect(() => {
        if (props.statusShovedMetricsPanel === false) {
            getSelectedReportObjects({
                value: 'not_selected',
                label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
                name_ru: 'не выбрано',
                name_en: 'not selected',
            });
            getStatusLoadMetrics(false);
            getObjDataDataViewForMaps(null);
        }
    }, [props.statusShovedMetricsPanel]);

    useEffect(() => {
        props.plans.forEach((item, index) => {
            // console.log('@@@@@@@@@@@@@@@@@@', item.floor);
            if (item.floor === 1) {
                getFloorIndexSelected(index);
            }
        });
    }, [props.plans]);

    useEffect(() => {
        let listReportObjectsTemp = [];
        let objReportObjectsTemp = {};

        objReportObjectsTemp['not_selected'] = {
            value: 'not_selected',
            label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
            name_ru: 'не выбрано',
            name_en: 'not selected',
            index: 0,
        };

        let objNameReport = {
            objs_count_unique_visitor_distrubution_obj: 1,
            objs_dwell_full_time_visitor_distrubution_obj: 3,
            objs_count_visit_visitor_distrubution_obj: 2,
            objs_avg_engagement_rate_visitor_distrubution_obj: 6,
            objs_avg_engagement_rate_opposite_visitor_distrubution_obj: 7,
            objs_avg_returning_rate_visitor_distrubution_obj: 8,
            objs_avg_returning_rate_opposite_visitor_distrubution_obj: 9,
            objs_entry_rate_visitor_distrubution_obj: 5,
            objs_dwell_full_time_engaged_visitor_distrubution_obj: 4,
        };
        props.reportCatalog.forEach((item, index) => {
            if (item.data_report.data_type.key_name === 'distribution_over_objects') {
                // listReportObjectsTemp.push(item);
                // console.log('<><><><><><><><><>', item.data_report.production, props.lang);
                if (objNameReport[item.data_report.key_name] !== undefined) {
                    objReportObjectsTemp[item.data_report.key_name] = {
                        ...item,
                        value: item.data_report.key_name,
                        label: item.data_report.translations[props.lang].name,
                        index: objNameReport[item.data_report.key_name],
                    };
                }
            }
        });

        if (Object.values(selectedReportObjects).length === 0 || selectedReportObjects.value === 'not_selected') {
            getSelectedReportObjects({
                value: 'not_selected',
                label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
                name_ru: 'не выбрано',
                name_en: 'not selected',
                index: 0,
            });
        } else {
            // console.log('>>>>>>>>>>>>>>.', selectedReportObjects)
            getSelectedReportObjects({
                ...selectedReportObjects,
                label: selectedReportObjects.data_report.translations[props.lang].name,
            });
        }

        listReportObjectsTemp = Object.values(objReportObjectsTemp);

        listReportObjectsTemp.sort(function compareNumbers(a, b) {
            return a.index - b.index;
        });

        getListReportObjects(listReportObjectsTemp);
    }, [props.reportCatalog, props.lang]);

    useEffect(() => {
        var arr = [];
        // console.log('@@@@@@@@@@@@@@@@@@', props.selected);
        let inputParamsValues = {};
        if (selectedReportObjects.value !== 'not_selected' && selectedReportObjects.value !== undefined) {
            selectedReportObjects.data_report.input_parameters.forEach((item, index) => {
                inputParamsValues[item.key_name] = props.unitedParamsValues[item.key_name];
            });
            if (typeMaps.value === 'places') {
                // var firstId = GeometryVSDataManager.reportObjWithPlace(props.plans[indexOfFloor === undefined ? 0: indexOfFloor].layers.place_layer.markers[index]);

                for (var i = 0; i < props.plans.length; i++) {
                    for (var j = 0; j < props.plans[i].layers.place_layer.markers.length; j++) {
                        if (GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]) !== undefined) {
                            arr.push(GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]));
                        }
                    }
                }
                // for(var j = 0; j < props.floors.length; j++) {
                //
                //     if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                //         arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]))
                //     }
                //
                // }
            } else {
                // if (props.floors.length > 1) {
                //     for(var j = 0; j < props.floors.length; j++) {
                //         if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                //             arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]))
                //         }
                //     }
                // }

                // console.log('@@@@@@@@@@', props.plans, indexOfFloor);

                // var firstId = GeometryVSDataManager.reportObjWithZone(props.plans[indexOfFloor === undefined ? 0: indexOfFloor].layers[typeMaps.value].zoneMarkers[index]);
                // console.log('!!!!!!!!>>>>>>>@@@@@@@@@@', firstId);
                for (var i1 = 0; i1 < props.plans.length; i1++) {
                    // console.log('@@@@@@@@@@', GeometryVSDataManager.reportObjWithZone(props.plans[indexOfFloor].layers[typeMaps.value].zoneMarkers[index]), index, indexOfFloor);
                    if (props.plans[i1].layers[typeMaps.value] !== undefined) {
                        for (var i2 = 0; i2 < props.plans[i1].layers[typeMaps.value].zoneMarkers.length; i2++) {
                            // console.log('onSelectPolygons', i2, index)
                            if (GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]) !== null) {
                                // console.log('@@@@@@@@@@', GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]), props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]);
                                arr.push(GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]));
                            }
                        }
                    }
                }
            }

            inputParamsValues['obj_ids'] = arr;
            if (arr.length > 0) {
                getStatusLoadMetrics(true);
                stateController = new AbortController();
                let stringUrlItem = '';
                if (selectedReportObjects.group.source_of_data === 'STUB_DATA') {
                    stringUrlItem = 'stub';
                } else if (
                    selectedReportObjects.group.source_of_data === 'REAL_DATA' ||
                    selectedReportObjects.group.source_of_data === 'PRODUCTION_TYPE'
                ) {
                    stringUrlItem = 'prod';
                }
                let url = `${hostForLocation.getHostName().analytics}/report_api/query_report/${stringUrlItem}/${
                    selectedReportObjects.data_report.key_name
                }/`;

                const headers = {
                    'x-token': props.token,
                    'Content-type': 'application/json',
                    'client-key': '0846ba98-8e67-4245-bcd2-2a948656eefb',
                };

                var myInit = {
                    method: 'POST',
                    signal: stateController.signal,
                    headers,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify(inputParamsValues),
                };
                getDiagramsDataFromServer(url, myInit, selectedReportObjects.index, inputParamsValues, getDataViewInfo);
            } else {
                getObjDataDataViewForMaps(null);
                alert('Data by report objects not found');
                getSelectedReportObjects({
                    value: 'not_selected',
                    label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
                    name_ru: 'не выбрано',
                    name_en: 'not selected',
                });
                return;
            }
        } else {
            getObjDataDataViewForMaps(null);
        }
    }, [props.selected.start, props.selected.end]);

    // console.log('<><><><><><><><><>', listReportObjects);

    // let showCounts = false;
    let wifthMapsBox;
    let showLabels = false;

    const updateValue = (value) => {
        if (value.value === 'places') {
            setTipeDrower('place_layer');
        } else {
            setTipeDrower(value.value);
        }
        setTypeMaps(value);
        getObjDataDataViewForMaps(null);
        getSelectedReportObjects({
            value: 'not_selected',
            label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
            name_ru: 'не выбрано',
            name_en: 'not selected',
        });
    };

    const updateUuid = (value) => {
        // console.log('>>>>>>>>>>>>.', value.value);
        props.getDataByUuid(props.uuidDataByUuid[value.value].plan_set_uuid, props.token, props.uuidDataByUuid[value.value].uuid);
    };

    const onSelectPolygons = (index, status, indexOfFloor) => {
        // console.log('onSelectPolygons')
        // console.log('setDataByUuid_source', props.plans);
        getObjDataDataViewForMaps(null);
        // getStatusLoadMetrics(false);
        getSelectedReportObjects({
            value: 'not_selected',
            label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
            name_ru: 'не выбрано',
            name_en: 'not selected',
        });

        getFloorIndexSelectHolst(indexOfFloor === undefined ? 0 : indexOfFloor);
        if (typeMaps.value === 'places') {
            var arr = [];
            var firstId = GeometryVSDataManager.reportObjWithPlace(
                props.plans[indexOfFloor === undefined ? 0 : indexOfFloor].layers.place_layer.markers[index]
            );

            for (var i = 0; i < props.plans.length; i++) {
                for (var j = 0; j < props.plans[i].layers.place_layer.markers.length; j++) {
                    if (GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]) !== undefined) {
                        if (GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]) !== firstId) {
                            arr.push(GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]));
                        }
                    }
                }
            }
            for (var j = 0; j < props.floors.length; j++) {
                if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                    arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]));
                }
            }
            if (firstId !== null) {
                if (statusSelectPolygons === true) {
                    getStatusSelectPolygons(false);
                    props.getStatusSelectPolygon(false);
                    props.selectPolygons(firstId, arr, false, 'places', props);
                } else {
                    getStatusSelectPolygons(status);
                    props.getStatusSelectPolygon(status);
                    props.selectPolygons(firstId, arr, status, 'places', props);
                }
            } else {
                getStatusSelectPolygons(false);
                props.getStatusSelectPolygon(false);
                getFloorIndexSelectHolst(null);
            }
            // console.log('@@@@@@@@@@', firstId, arr, status);
        } else {
            var arr = [];

            if (props.floors.length > 1) {
                for (var j = 0; j < props.floors.length; j++) {
                    if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                        arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]));
                    }
                }
            }
            // console.log('@@@@@@@@@@', props.plans, indexOfFloor);

            var firstId = GeometryVSDataManager.reportObjWithZone(
                props.plans[indexOfFloor === undefined ? 0 : indexOfFloor].layers[typeMaps.value].zoneMarkers[index]
            );
            // console.log('!!!!!!!!>>>>>>>@@@@@@@@@@', firstId);
            // console.log('>>>>>>>>>>>>>>>>', props.plans)
            for (var i1 = 0; i1 < props.plans.length; i1++) {
                // console.log('@@@@@@@@@@', GeometryVSDataManager.reportObjWithZone(props.plans[indexOfFloor].layers[typeMaps.value].zoneMarkers[index]), index, indexOfFloor);
                if (props.plans[i1].layers[typeMaps.value] !== undefined) {
                    for (var i2 = 0; i2 < props.plans[i1].layers[typeMaps.value].zoneMarkers.length; i2++) {
                        // console.log('onSelectPolygons', i2, index)

                        if (GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]) !== firstId) {
                            if (GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]) !== null) {
                                arr.push(GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]));
                                // console.log('>>>>>>>>>>>', GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]))
                            }
                        }
                    }
                }
            }

            if (firstId !== null) {
                setZoneNameBySelected(props.plans[indexOfFloor === undefined ? 0 : indexOfFloor].layers[typeMaps.value].zoneNames[index]);
                // console.log('>>>>>>>>>>>>>>>>', firstId, arr)
                if (statusSelectPolygons) {
                    getStatusSelectPolygons(false);
                    props.getStatusSelectPolygon(false);
                    props.selectPolygons(firstId, arr, false, 'zones', {
                        ...props,
                        typeNewMaps: typeMaps,
                    });
                } else {
                    getStatusSelectPolygons(status);
                    props.getStatusSelectPolygon(status);
                    props.selectPolygons(firstId, arr, status, 'zones', {
                        ...props,
                        typeNewMaps: typeMaps,
                    });
                }
            } else {
                getStatusSelectPolygons(false);
                props.getStatusSelectPolygon(false);
                getFloorIndexSelectHolst(null);
            }
        }
    };

    // console.log('>>>>>>>>>', statusSelectPolygons)
    const onCheckBackground = (e) => {
        onCheck(!statusCheckedBackgroundImage);
        // props.onCheckBackgroundImage();
    };

    const getStatusShowCount = () => {
        // this.setState({
        //     showCounts: !state.showCounts
        // })
        onShowCounts(!showCounts);
    };

    const showColorCount = (e) => {
        // props.showColorCount(!props.statusColorCount);
        getStatusSelectPolygons(false);
        props.getStatusSelectPolygon(false);
        setStatusColorCount(!statusColorCount);
    };

    const getFloorId = (index) => {
        getFloorIndexSelected(index);
    };

    const getScale = (scaleStatus) => {
        if (scaleStatus === '-') {
            getCastomScale(scaleCastom - 0.1);
        } else {
            getCastomScale(scaleCastom + 0.1);
        }
    };

    let stateController;

    const updateValueMetrics = (newValue) => {
        var arr = [];

        let inputParamsValues = {};
        if (newValue.value !== 'not_selected') {
            newValue.data_report.input_parameters.forEach((item, index) => {
                inputParamsValues[item.key_name] = props.unitedParamsValues[item.key_name];
            });
            if (typeMaps.value === 'places') {
                // var firstId = GeometryVSDataManager.reportObjWithPlace(props.plans[indexOfFloor === undefined ? 0: indexOfFloor].layers.place_layer.markers[index]);

                for (var i = 0; i < props.plans.length; i++) {
                    for (var j = 0; j < props.plans[i].layers.place_layer.markers.length; j++) {
                        if (GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]) !== undefined) {
                            arr.push(GeometryVSDataManager.reportObjWithPlace(props.plans[i].layers.place_layer.markers[j]));
                        }
                    }
                }
                // for(var j = 0; j < props.floors.length; j++) {
                //
                //     if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                //         arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]))
                //     }
                //
                // }
            } else {
                // if (props.floors.length > 1) {
                //     for(var j = 0; j < props.floors.length; j++) {
                //         if (GeometryVSDataManager.reportObjWithFloor(props.floors[j]) !== undefined) {
                //             arr.push(GeometryVSDataManager.reportObjWithFloor(props.floors[j]))
                //         }
                //     }
                // }

                // console.log('@@@@@@@@@@', props.plans, indexOfFloor);

                // var firstId = GeometryVSDataManager.reportObjWithZone(props.plans[indexOfFloor === undefined ? 0: indexOfFloor].layers[typeMaps.value].zoneMarkers[index]);
                // console.log('!!!!!!!!>>>>>>>@@@@@@@@@@', firstId);
                for (var i1 = 0; i1 < props.plans.length; i1++) {
                    // console.log('@@@@@@@@@@', GeometryVSDataManager.reportObjWithZone(props.plans[indexOfFloor].layers[typeMaps.value].zoneMarkers[index]), index, indexOfFloor);
                    if (props.plans[i1].layers[typeMaps.value] !== undefined) {
                        for (var i2 = 0; i2 < props.plans[i1].layers[typeMaps.value].zoneMarkers.length; i2++) {
                            // console.log('onSelectPolygons', i2, index)
                            if (GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]) !== null) {
                                // console.log('@@@@@@@@@@', GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]), props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]);
                                arr.push(GeometryVSDataManager.reportObjWithZone(props.plans[i1].layers[typeMaps.value].zoneMarkers[i2]));
                            }
                        }
                    }
                }
            }

            inputParamsValues['obj_ids'] = arr;
            if (arr.length > 0) {
                getStatusLoadMetrics(true);
                stateController = new AbortController();
                let stringUrlItem = '';
                if (newValue.group.source_of_data === 'STUB_DATA') {
                    stringUrlItem = 'stub';
                } else if (newValue.group.source_of_data === 'REAL_DATA' || newValue.group.source_of_data === 'PRODUCTION_TYPE') {
                    stringUrlItem = 'prod';
                }
                let url = `${hostForLocation.getHostName().analytics}/report_api/query_report/${stringUrlItem}/${
                    newValue.data_report.key_name
                }/`;

                const headers = {
                    'x-token': props.token,
                    'Content-type': 'application/json',
                    'client-key': '0846ba98-8e67-4245-bcd2-2a948656eefb',
                };

                var myInit = {
                    method: 'POST',
                    signal: stateController.signal,
                    headers,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify(inputParamsValues),
                };
                getDiagramsDataFromServer(url, myInit, newValue.index, inputParamsValues, getDataViewInfo);
            } else {
                getObjDataDataViewForMaps(null);
                alert('Data by report objects not found');
                getSelectedReportObjects({
                    value: 'not_selected',
                    label: props.lang === 'ru' ? 'не выбрано' : 'not selected',
                    name_ru: 'не выбрано',
                    name_en: 'not selected',
                });
                return;
            }
        } else {
            getObjDataDataViewForMaps(null);
        }

        getSelectedReportObjects(newValue);
    };

    const getDataViewInfo = (data) => {
        let obj = {};

        let minValue = data.response.data[0].values[0];
        let maxValue = data.response.data[0].values[0];

        data.response.data.forEach((item, index) => {
            if (minValue > item.values[0]) {
                minValue = item.values[0];
            }
            if (maxValue < item.values[0]) {
                maxValue = item.values[0];
            }
            obj[getIdWithDataAspects(item)] = item;
        });
        let list = Tools.copy(data.response.data);
        // console.log('12345', list);
        list.sort(function (a, b) {
            if (a.values[0] > b.values[0]) {
                return -1;
            }
            if (a.values[0] < b.values[0]) {
                return 1;
            }
            // a должно быть равным b
            return 0;
        });
        // console.log('<><><><><><><><><>', list);
        getDValueMetricsByMaps({
            maxValue: maxValue,
            minValue: minValue,
        });
        getObjDataDataViewForMaps(obj);
        getListDataDataViewForMaps(list);
        getStatusLoadMetrics(false);
        props.getStatusShowMetricsPanel(true);
        // console.log('<><><><><><><><><>', obj, maxValue, minValue);
        // getIdWithDataAspects(data)
    };

    var statisticPanel = null;
    var styleMapsComponent = { width: 'calc(100% - 272px)' };
    // console.log('>>>>>>>>>>>>>>>.12', props.panelAnalitics);
    if (props.panelAnalitics === true) {
        statisticPanel = <Panel _t={props._t} statusSelectPolygons={statusSelectPolygons} zoneNameBySelected={zoneNameBySelected} />;
        styleMapsComponent = null;
    }
    if (statusLoadMetrics === false && objDataDataViewForMaps !== null) {
        statisticPanel = (
            <Panel
                _t={props._t}
                statusSelectPolygons={statusSelectPolygons}
                zoneNameBySelected={zoneNameBySelected}
                metricks={true}
                listDataDataViewForMaps={listDataDataViewForMaps}
                DValueMetricsByMaps={DValueMetricsByMaps}
                selectedReportObjects={selectedReportObjects}
            />
        );
        styleMapsComponent = null;
    }
    if (props.mode === 'router') {
        styleMapsComponent = { width: '100%' };
    }
    if (document.getElementById('mapZone') !== null) {
        if (props.panelAnalitics === true) {
            wifthMapsBox = window.innerWidth - (248 + 272 + 24 + 27);
        } else {
            wifthMapsBox = window.innerWidth - (248 + 24 + 27);
        }
    }
    if (props.statusNotData === true) {
        const componentNotData = (
            <div className="mapsNotDate">
                <h2>Нет Данных</h2>
            </div>
        );
        styleMapsComponent = { width: 'calc(100% - 272px)', minHeight: '139px' };
    }
    var component = this;
    var previewMap;
    if (props.prop.floor == null) {
        // map = null;
        previewMap = null;
    } else if (props.prop.floor != null) {
        previewMap = <PreviewMap getFloorId={getFloorId} mode={props.mode} floorIndexSelected={floorIndexSelected} Link={Link} />;
    }
    // if (this.state.style !== null) {
    // heightMaps = this.state.style.height;
    // }
    // console.log('mapmapmapma', heightMaps);
    // var component = this;
    let componentButtonName = null;
    if (props.uuidPlanSet === null) {
        componentButtonName = (
            <button
                className="buttonDataToo"
                onClick={function (e) {
                    component.setState({ showLabels: !showLabels });
                }}
            >
                {showLabels ? 'Скрыть названия' : 'Отобразить названия'}
            </button>
        );
    }

    var componentMapsNew = null;
    var widthbox = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
    var heightbox =
        (window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height) - 173;

    var styleStepBox = null;
    var widthStepBox = 0;
    var heughtBox = 0;

    var componentSpinerLoader = null;

    const spinner = (
        <div className={`boxSpinerLoaderMaps`}>
            <SpinerLoader />
        </div>
    );

    if (props.statusLoadSelectPolygons === true) {
        componentSpinerLoader = spinner;
    } else if (props.statusLoadSelectPolygons === false) {
        componentSpinerLoader = null;
    }
    if (props.statusLoadVersion) {
        componentSpinerLoader = spinner;
    }
    if (statusLoadMetrics) {
        componentSpinerLoader = spinner;
    }

    if (props.statusLoadPlanSets) {
        componentSpinerLoader = spinner;
    }

    var scaleComponents = null;
    var componentPanelBottom = null;
    if (props.uuidPlanSet === null) {
    } else if (props.uuidPlanSet !== null) {
        componentPanelBottom = (
            <div className={'bottomPanel'}>
                <div className={'selectAnalizis select-up'}>
                    <MainSelect
                        options={props.selectForMaps}
                        onChange={updateValue}
                        value={typeMaps}
                        widthBox={218}
                        widthSelect={268}
                        _t={props._t}
                        statusOptionsComponent={null}
                    />
                </div>
                {/*{showCounts ? <div onClick={getStatusShowCount} className={'buttonAnalizis twoElement active'}></div> :*/}
                {/*    <div onClick={getStatusShowCount} className={'buttonAnalizis twoElement'}></div>}*/}

                {/*{statusColorCount ? <div onClick={showColorCount} className={'buttonAnalizis threeElement active'}></div> : <div onClick={showColorCount} className={'buttonAnalizis threeElement'}></div>}*/}

                <div className={'boxDataViewInfo'}>
                    <div className="boxTitleMetric">
                        <span>{props._t.t('Метрика')}</span>
                    </div>
                    <div className={'dataViewInfo'}>
                        <MainSelect
                            options={listReportObjects}
                            onChange={updateValueMetrics}
                            value={selectedReportObjects}
                            widthBox={218}
                            widthSelect={268}
                            _t={props._t}
                            statusOptionsComponent={null}
                        />
                    </div>
                </div>
                <input
                    checked={statusCheckedBackgroundImage}
                    onChange={onCheckBackground}
                    className={'inputShowBackground'}
                    id={'showbackground'}
                    type="checkbox"
                />
                <label className={'inputShowBackgroundLabel'} htmlFor="showbackground">
                    {props._t.t('Отобразить подложку')}
                </label>
                <div className="boxVersionsAndTitle">
                    {/*<span>Версия карт:</span>*/}
                    <div className={'boxVersions'}>
                        {/*<Select*/}
                        {/*    id="state-select"*/}
                        {/*    menuPlacement="bottom"*/}

                        {/*    options={props.uuidDataList}*/}
                        {/*    simpleValue*/}
                        {/*    clearable={false}*/}
                        {/*    name="selected-state"*/}
                        {/*    onChange={updateUuid}*/}
                        {/*    value={props.uuidDataSelected}*/}
                        {/*/>*/}
                        <MainSelect
                            options={props.uuidDataList}
                            onChange={updateUuid}
                            value={props.uuidDataSelected}
                            widthBox={350}
                            widthSelect={350}
                            statusOptionsComponent={'dataUuidComponent'}
                            _t={props._t}
                        />
                    </div>
                </div>
            </div>
        );
    }
    // console.log('12345', props.plans);
    const onClickScaleBuuton = (e, button) => {
        e.stopPropagation();
        getScale(button);
    };
    var o = (
        <div
            className={
                statusSelectPolygons || (statusLoadMetrics === false && objDataDataViewForMaps !== null)
                    ? 'boxMapsZone openPanel'
                    : 'boxMapsZone'
            }
            style={{ position: 'relative' }}
        >
            <div style={styleMapsComponent} className="mapZone" id="mapZone">
                {previewMap}

                {props.plans.length === 0 ? null : (
                    <div className="boxButtonScale">
                        <div onClick={(e) => onClickScaleBuuton(e, '+')}>
                            <span>+</span>
                        </div>
                        <div onClick={(e) => onClickScaleBuuton(e, '-')}>
                            <span>-</span>
                        </div>
                    </div>
                )}
                <MapsHolst
                    // plans={props.plans}
                    typeDrower={typeDrower}
                    {...props}
                    scaleMapsGlobal={scaleMapsGlobal}
                    widthBox={widthBox}
                    heightBox={heightBox}
                    scaleCastom={scaleCastom}
                    onSelectPolygons={onSelectPolygons}
                    dataUneqUsers={typeDrower === 'place_layer' ? props.dataUneqUsers : props.dataUneqUsersOfZones}
                    showCounts={showCounts}
                    statusColorCount={statusColorCount}
                    statusCheckedBackgroundImage={statusCheckedBackgroundImage}
                    floorIndexSelected={floorIndexSelected}
                    Element={Element}
                    FloorIndexSelectedHolst={FloorIndexSelectedHolst}
                    statusSelectPolygons={statusSelectPolygons}
                    DValueMetricsByMaps={DValueMetricsByMaps}
                    objDataDataViewForMaps={objDataDataViewForMaps}
                />

                {componentMapsNew}
                {componentPanelBottom}
            </div>
            {statisticPanel}
            <div style={{ clear: 'both' }}></div>
            {/*{props.prop.statusLoad ? null : spiner}*/}
        </div>
    );
    return (
        <div style={{ height: '100%' }}>
            {o}
            {componentSpinerLoader}
            {/*<div className={`boxSpinerLoaderMaps`}>*/}
            {/*    <SpinerLoader />*/}
            {/*</div>*/}
            {props.uuidPlanSet === null && props.statusLoadPlanSets === false ? (
                <div className="notMapsData">{props._t.t('Нет данных по картам')}</div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        statusNotData: state.MapsApp.statusNotData,
        statusCheckBackground: state.MapsApp.statusCheckBackground,
        dataPlaceMaps: state.MapsApp.dataPlaceMaps,
        places: state.MapsApp.places,
        roomsTransition: state.MapsApp.roomsTransition,
        statusLoadSelectPolygons: state.ReportParametersManager.mapsData.statusLoadSelectPolygons,
        rooms: state.ReportParametersManager.Source.src.roomsMaps,
        markers: state.MapsApp.markers,
        floors: state.MapsApp.floors,
        dataUneqUsers: state.ReportParametersManager.mapsData.dataUneqUsers,
        uuidPlanSet: state.MapsApp.uuidPlanSet,
        optionTypeNewMaps: state.MapsApp.optionTypeNewMaps,
        typeNewMaps: state.MapsApp.typeNewMaps,
        zonesData: state.MapsApp.zonesData,
        zones: state.MapsApp.zones,
        dataUneqUsersOfZones: state.ReportParametersManager.mapsData.dataUneqUsersOfZones,
        statusColorCount: state.MapsApp.statusColorCount,
        zonesObjData: state.MapsApp.zonesObjData,
        selected: {
            start: state.ReportParametersManager.customInputParamsValues.main_date_range[0],
            end: state.ReportParametersManager.customInputParamsValues.main_date_range[1],
        },
        token: state.LoginApp.token,
        plans: state.ReportParametersManager.mapsData.plans,
        heightMapMax: state.ReportParametersManager.mapsData.heightMapMax,
        widthMapMax: state.ReportParametersManager.mapsData.widthMapMax,
        selectForMaps: state.ReportParametersManager.mapsData.selectForMaps,
        optionTypeNewMapsObj: state.ReportParametersManager.mapsData.optionTypeNewMapsObj,
        uuidDataList: state.ReportParametersManager.mapsData.uuidDataList,
        uuidDataSelected: state.ReportParametersManager.mapsData.uuidDataSelected,
        uuidDataByUuid: state.ReportParametersManager.mapsData.uuidDataByUuid,
        statusLoadVersion: state.ReportParametersManager.mapsData.statusLoadVersion,
        uuidDataSelectedTempPlaces: state.ReportParametersManager.mapsData.uuidDataSelectedTempPlaces,
        statusSelectPolygons: state.MapsApp.statusSelectPolygons,
        statusShovedMetricsPanel: state.MapsApp.statusShovedMetricsPanel,
        reportCatalog: state.ReportParametersManager.reportCatalog,
        unitedParamsValues: {
            ...state.ReportParametersManager.customInputParamsValues,
            ...state.ReportParametersManager.globalInputParamsValues,
        },
        statusLoadPlanSets: state.MapsApp.statusLoadPlanSets,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateValue: (value) => {
            dispatch(runAction('updateValue', { value: value }));
        },
        selectPolygons: (data, arrId, status, type, params) => {
            var headers = {
                'x-token': params.token,
                'Content-type': 'application/json',
                'client-key': '0846ba98-8e67-4245-bcd2-2a948656eefb',
            };

            if (type === 'places') {
                dispatch(runAction('selectPolygons', { status: true }));
                // var obj = {
                //     firstId: data,
                //     secondString:string,
                // }
                if (status === false) {
                    dispatch(runAction('setDataSelectPolygons', { json: null, data: null }));
                } else if (status === true) {
                    // console.log('>>>>>>>>>>>', arrId)
                    const url = `${
                        hostForLocation.getHostName().analytics
                    }/report_api/query_report/prod/objs_main_to_contras_intersections_agg/`;

                    const body = JSON.stringify({
                        main_obj_id: data,
                        contra_obj_ids: arrId,
                        main_date_range: [
                            moment(params.selected.start).format('YYYY-MM-DD'),
                            moment(params.selected.end).format('YYYY-MM-DD'),
                        ],
                    });

                    dispatch(runAction('getDataSelectPolygons', { url, headers, data: { obj_id: data, obj_ids: arrId }, body }));
                }
            } else if (type === 'zones') {
                dispatch(runAction('selectPolygons', { status: true }));

                // var obj = {
                //     firstId: data,
                //     secondString:string,
                //
                // }
                // console.log('###########', obj)
                if (status === false) {
                    dispatch(runAction('setDataSelectPolygons', { json: null, data: null }));
                } else if (status === true) {
                    let url = `${
                        hostForLocation.getHostName().analytics
                    }/report_api/query_report/prod/objs_main_to_contras_intersections_agg/`;
                    const body = JSON.stringify({
                        main_obj_id: data,
                        contra_obj_ids: arrId,
                        main_date_range: [
                            moment(params.selected.start).format('YYYY-MM-DD'),
                            moment(params.selected.end).format('YYYY-MM-DD'),
                        ],
                    });
                    dispatch(runAction('getDataSelectPolygons', { url, headers, data: { obj_id: data, obj_ids: arrId }, body }));
                }
            }
        },
        showColorCount: (status) => {
            dispatch(runAction('showColorCount', { status: status }));
        },
        onCheckBackgroundImage: () => {
            dispatch(runAction('onCheckBackgroundImage'));
        },
        getDataByUuid: (uuid, token, uuidSet) => {
            // console.log('/', uuidSet)
            if (uuid !== null) {
                var url = `${hostForLocation.getHostName().maps}/geometry/plan_set/${uuid}/version/${uuidSet}/`;
                var headers = {
                    'x-token': token,
                };
                dispatch(runAction('getDataByUuids_Source', { data: uuidSet }));
                // dispatch(runAction('getDataByUuid_Fetch', {url:url, headers:headers}));
            }
        },
        getStatusSelectPolygon: (status) => {
            dispatch(runAction('getStatusSelectPolygon_MapsReducer', { status: status }));
        },
        getDateForMaps: (selected) => {
            dispatch(runAction('getDateForMaps_Source', { selected: selected }));
        },
        getUuidDataListForMaps: (list) => {
            dispatch(runAction('getUuidDataListForMaps_MapsReducer', { list: list }));
        },
        getUuidDataForMaps: (uuidData) => {
            dispatch(runAction('getUuidDataForMaps_MapsReducer', { uuidData: uuidData }));
        },
        getStatusShowMetricsPanel: (status) => {
            dispatch(runAction('getStatusShowMetricsPanel_MapsReducer', { status: status }));
        },
    };
};

const ConnectMapComponent = connect(mapStateToProps, mapDispatchToProps)(MapComponent);

export default ConnectMapComponent;
