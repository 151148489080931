

import React from 'react';
import PropTypes from 'prop-types';

function SchedulesItem({
                           prop,
                           methodForSchedulesItem,
                           token,
                           _t
					   }) {
    var dateString, timeString;
    var dayOfWeek = {
        Monday: _t.t('Пн'),
        Tuesday: _t.t('Вт'),
        Wednesday: _t.t('Ср'),
        Thursday: _t.t('Чт'),
        Friday: _t.t('Пт'),
        Saturday: _t.t('Сб'),
        Sunday: _t.t('Вс')
    };
    var DayOfWeekArray = [];
    for (var i = 0; i < prop.week_days.length; i++) {
        DayOfWeekArray.push(dayOfWeek[prop.week_days[i]]);
    }
    if (prop.date_period.always === true) {
        dateString = _t.t('всегда');
    }
    else if (prop.date_period.always === false) {
        dateString = _t.t('с') + ' ' + prop.date_period.from + ' ' + _t.t('по') + ' ' + prop.date_period.to;
    }
    if (prop.time_period.always === true) {
        timeString = _t.t('всегда');
    }
    else if (prop.time_period.always === false) {
        timeString = _t.t('c') + ' ' + prop.time_period.from + ' ' + _t.t('до') + ' ' + prop.time_period.to;
    }
    DayOfWeekArray.join(', ');
    // console.log('><!!!>!!!>>>>', prop.page_preview)
    return (
        <div onClick={(e) => methodForSchedulesItem.onClickEdit(e, prop)} style={{outline: '1px solid ' + prop.color}}
			 onMouseLeave={(e) => methodForSchedulesItem.onMouseLeaveSchedulesItem()}
			 onMouseEnter={(e) => methodForSchedulesItem.onMouseEnterSchedulesItem(prop.page_id)} className='radiusBoxPlanCard'>
            <img src={prop.page_preview}/>
            <div>
                <h6 style={{background: prop.color}}>{prop.page_title}</h6>
                <button id='deletePlan' onClick={(e) => methodForSchedulesItem.onDeletePlan(prop.id,token)}><i id='iconDeletePlan' className="fa fa-times"></i></button>
                <span>{_t.t('Период') + ': ' + dateString}</span>
                <span>{_t.t('Дни недели') + ': ' + DayOfWeekArray.join(', ')}</span>
                <span>{_t.t('Время') + ': ' + timeString}</span>
                <span>{_t.t('Язык браузера') + ': ' + (prop.browser_lang_code !== undefined ? (prop.browser_lang_code !== null ? prop.browser_lang_code.toUpperCase() : _t.t('любой')) : _t.t('любой'))}</span>
                <span className="userNameInSchedules">{prop.user}</span>
            </div>
        </div>
    );
}

SchedulesItem.propTypes = {};
SchedulesItem.defaultProps = {};

export default SchedulesItem;
