import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import Title from './components/title';

import { ModalShareCloudLink } from '../../cloud';
import MakeNewObjSetModal from '../leftPanel/obj_sets_panel/components/make_new_set_modal';
import ObjSetInfoModal from '../leftPanel/obj_sets_panel/components/set_info_modal';
import ManualCustomParamsModal from '../leftPanel/pl_panel/components/manual_custom_params_modal';
import EngineeringModal from '../engineering_menu/engineering_modal';
import NewAdCampaignModal from '../ad_campaigns/components/new_ad_campaign_modal';
import NewSegmentModal from '../Diagrams/components/custom_controls/segments_control/components/new_segment_modal';
import AddDiagramModal from '../custom_dashboard_modals/add_diagram_modal';
import NewSubsectionModal from '../custom_dashboard_modals/new_subsection_modal';
import RenameSubsectionModal from '../custom_dashboard_modals/rename_subsection_modal';
import NewGroupModal from '../custom_dashboard_modals/new_group_modal';
import RenameGroupModal from '../custom_dashboard_modals/rename_group_modal';
import DeleteGroupModal from '../custom_dashboard_modals/delete_group_modal';
import DeleteSubsectionModal from '../custom_dashboard_modals/delete_subsection_modal';
import ResetMyDashboard from '../custom_dashboard_modals/reset_my_dashboard_modal';
import AdCampaignInfoModal from '../ad_campaigns/components/ad_campaign_info_modal';
import SegmentInfoModal from '../Diagrams/components/custom_controls/segments_control/components/segment_info_modal';
import DiagramDescriptionModal from './components/diagram_description_modal';

export default React.memo(({ modalParams, ...props }) => {
    const [name, setName] = useState('');
    const [clickStart, set_clickStart] = useState(false);
    const [id, setId] = useState('');
    const [theme, setTheme] = useState(getTheme());
    useEffect(() => {
        if (modalParams.modalArgs) {
            setName(modalParams.modalArgs.name);
            setId(modalParams.modalArgs.id);
        }
    }, []);

    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    const onClick = (action, args) => (e) => {
        e.stopPropagation();
        props.onModalButtonClick(action, args);
        props.toggleModal(false);
    };

    const handleNameChange = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        setName(value);
    };

    const buttons = modalParams?.buttons
        ? modalParams.buttons.map((button, i) => {
              const args = { name, id };
              switch (button.type) {
                  case 'primary':
                      return (
                          <PrimaryButton
                              text={button.text}
                              key={`${button.text}-${i}`}
                              width={button.width}
                              onClick={onClick(button.action, args)}
                          />
                      );

                  default:
                      return (
                          <DefaultButton
                              text={button.text}
                              key={`${button.text}-${i}`}
                              width={button.width}
                              onClick={onClick(button.action, args)}
                          />
                      );
              }
          })
        : undefined;

    const sign = useMemo(() => {
        switch (modalParams.modalType) {
            case 'alert':
                return <Sign className="fa fa-warning" />;
            default:
                return null;
        }
    }, [modalParams.modalType]);

    const dict = {
        alert: (
            <AlertWrapper>
                {sign}
                <div>{modalParams.modalText}</div>
            </AlertWrapper>
        ),
        refresh: <RefreshWrapper>{modalParams.modalText}</RefreshWrapper>,
        input_text: (
            <TextInput autoFocus={'autofocus'} type={'text'} placeholder={modalParams.modalText} value={name} onChange={handleNameChange} />
        ),
        cloud_share: <ModalShareCloudLink onModalButtonClick={props.onModalButtonClick} />,
        make_new_obj_set: <MakeNewObjSetModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        obj_set_info: <ObjSetInfoModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        diagram_description: <DiagramDescriptionModal {...modalParams.modalArgs.props} />,
        manual_edit: <ManualCustomParamsModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        engineering_modal: <EngineeringModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        new_ad_campaign: <NewAdCampaignModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        new_segment: <NewSegmentModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        add_diagram: <AddDiagramModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        new_subsection: <NewSubsectionModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        rename_subsection: <RenameSubsectionModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        delete_subsection: <DeleteSubsectionModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        reset_my_dashboard: <ResetMyDashboard {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        new_group: <NewGroupModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        rename_group: <RenameGroupModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        delete_group: <DeleteGroupModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        ad_campaign_info: <AdCampaignInfoModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
        segment_info: <SegmentInfoModal {...modalParams.modalArgs.props} onModalButtonClick={props.onModalButtonClick} />,
    };

    const main = useMemo(() => {
        if (!(modalParams.modalType in dict)) {
            return null;
        } else {
            return dict[modalParams.modalType];
        }
    });

    const dimensions = {
        width: modalParams.width ? modalParams.width : '600px',
        height: modalParams.height ? modalParams.height : '400px',
    };

    const stackTokens = { childrenGap: 10 };

    const onOverlayMouseDown = (e) => {
        set_clickStart(true);
    };

    const onOverlayMouseUp = (show) => (e) => {
        if (!clickStart) return;
        if (
            !modalParams.modalArgs ||
            modalParams.modalArgs.clickableOverlay === undefined ||
            modalParams.modalArgs.clickableOverlay === true
        ) {
            props.toggleModal(show);
        }

        set_clickStart(false);
    };

    return (
        <Overlay onMouseDown={onOverlayMouseDown} onMouseUp={onOverlayMouseUp(false)}>
            <Container
                width={dimensions.width}
                height={dimensions.height}
                maxHeight={'80%'}
                onClick={onContainerClick}
                onMouseDown={onContainerClick}
                borderColor={`${theme.palette.themePrimary}`}
            >
                {modalParams.modalTitle ? (
                    <Header>
                        <Title title={modalParams.modalTitle} />
                    </Header>
                ) : null}
                <Main>{main}</Main>

                {buttons ? (
                    <Footer>
                        <Stack horizontal tokens={stackTokens}>
                            {buttons}
                        </Stack>
                    </Footer>
                ) : null}
            </Container>
        </Overlay>
    );
});

const Header = styled.section``;
const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    box-sizing: border-box;
    overflow: auto;
`;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Sign = styled.i`
    font-size: 24px !important;
    color: #aaa !important;
    margin: 20px;
`;

const TextInput = styled.input`
    box-sizing: border-box;
    background-color: whitesmoke;
    width: 100%;
    min-height: 30px;
    padding: 10px;
    font-size: 16px;
    outline: none;
    border: none;
    border-bottom: 1px solid #1e90ff;
    margin-bottom: 10px;
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`;

const AlertWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RefreshWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Container = styled.div`
    box-sizing: border-box;
    padding: 15px;
    /* position: absolute; */
    /* left: 50%;
    top: 50%; */
    /* margin-left: ${(p) => -p.width / 2 + 'px'};
    margin-top: ${(p) => -p.height / 2 + 'px'}; */
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    max-height: ${(p) => p.maxHeight};
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border-top: ${(p) => '4px solid' + p.borderColor};
    overflow: auto;
`;
