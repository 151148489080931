import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import Tools from '../../../../tools/tools';
import styled from 'styled-components/macro';
import _t from 'i18next';

const ManualCustomParamsModal = React.memo(
    ({ sectionsByKeyName, currentSection, currentSubSection, customInputParamsValues, catalog, ...props }) => {
        const paramsSet = Tools.useGetParamSet();

        const [paramsCanChange, setParamsCanChange] = useState({});
        const [tempDate, setTempDate] = useState('');
        const [advNames, setAdvExt_Ids] = useState([]);
        const [choiceKey, setChoiceKey] = useState('ids');
        const [allChecked, setAllChecked] = useState(false);
        const [theme] = useState(getTheme());

        useEffect(() => {
            const paramsCanChange = {};
            const customParams = cloneDeep(customInputParamsValues);

            paramsSet.custom.forEach((key) => {
                if (customParams[key]) {
                    paramsCanChange[key] = customParams[key];
                    if (key === 'outdoor_ad_space_ids') {
                        fillAdvExt_Ids(customParams[key]);
                    }
                }
            });

            setParamsCanChange({ ...paramsCanChange });
            // console.log(catalog);
        }, [currentSection, currentSubSection, paramsSet]);

        const fillAdvExt_Ids = (idsArr) => {
            const advNames = [];
            idsArr.forEach((id) => {
                if (id in catalog.outdoorAdSpacesById) {
                    advNames.push(catalog.outdoorAdSpacesById[id].ext_id);
                }
            });
            setAdvExt_Ids(advNames);
        };

        const _onNamesChange = (e, newValue) => {
            const advNames = newValue.split('\n');
            setAdvExt_Ids([...advNames]);
        };

        const _correctSingleId = (parameter) => (e) => {
            let newParamsCanChange = { ...paramsCanChange };
            if (parameter === 'obj_id') {
                let newId =
                    Number(newParamsCanChange[parameter]) in catalog.reportingObjectsById
                        ? Number(newParamsCanChange[parameter])
                        : Number(catalog.reportingObjectsById[Object.keys(catalog.reportingObjectsById)[0]].id);
                newParamsCanChange.obj_id = newId;
                if ('pl_id' in newParamsCanChange) {
                    newParamsCanChange.pl_id = catalog.reportingObjectsById[newId].pl_id;
                }
            } else if (parameter === 'pl_id') {
                let newId =
                    Number(newParamsCanChange[parameter]) in catalog.reportingObjectsByPlId
                        ? Number(newParamsCanChange[parameter])
                        : Number(catalog.reportingObjectsByPlId[Object.keys(catalog.reportingObjectsByPlId)[0]].id);
                newParamsCanChange.pl_id = newId;
                if ('obj_id' in newParamsCanChange) {
                    newParamsCanChange.obj_id = catalog.reportingObjectsByPlId[newId].main_servicepoint.id;
                }
            } else if (parameter === 'main_obj_id') {
                let newObjIds = [];
                let newPlIds = [];
                let newMainObjId =
                    Number(newParamsCanChange[parameter]) in catalog.reportingObjectsById ? Number(newParamsCanChange[parameter]) : null;
                if (newMainObjId) {
                    newParamsCanChange.main_obj_id = newMainObjId;
                    if ('obj_ids' in paramsCanChange) {
                        newObjIds.push(...paramsCanChange.obj_ids);
                        newObjIds.push(Number(newMainObjId));
                        newObjIds = Array.from(new Set(newObjIds.map((item) => Number(item))));
                        newParamsCanChange.obj_ids = newObjIds;
                    }
                    if ('pl_ids' in paramsCanChange) {
                        newObjIds.forEach((objId) => {
                            newPlIds.push(catalog.reportingObjectsById[objId].pl_id);
                        });
                        newPlIds = Array.from(new Set(newPlIds));
                        newParamsCanChange.pl_ids = newPlIds;
                    }
                }
            }
            setParamsCanChange(newParamsCanChange);
        };

        const _correctIds = (parameter) => () => {
            const _handle_obj_ids = (parameter) => {
                let newParamsCanChange = {};
                let newIds = Array.from(new Set(paramsCanChange[parameter])).map((item) => Number(item));

                newIds = [...newIds].filter((id) => Number(id) in catalog.reportingObjectsById);
                newParamsCanChange = { ...paramsCanChange, [parameter]: newIds };
                if ('pl_ids' in paramsCanChange) {
                    let newPlIds = [];
                    newIds.forEach((objId) => {
                        newPlIds.push(catalog.reportingObjectsById[objId].pl_id);
                    });
                    newPlIds = Array.from(new Set(newPlIds));
                    newParamsCanChange.pl_ids = newPlIds;
                }
                setParamsCanChange(newParamsCanChange);
            };

            const _handle_pl_ids = (parameter) => {
                let newParamsCanChange = {};
                let newIds = Array.from(new Set(paramsCanChange[parameter])).map((item) => Number(item));

                newIds = [...newIds].filter((id) => Number(id) in catalog.projectLocationsById);
                newParamsCanChange = { ...paramsCanChange, [parameter]: newIds };
                if ('obj_ids' in newParamsCanChange) {
                    let newObjIds = [...newParamsCanChange.obj_ids].filter((objId) => {
                        return newIds.includes(catalog.reportingObjectsById[objId].pl_id);
                    });

                    newIds.forEach((plId) => {
                        const allObjects = [];
                        Object.keys(catalog.reportingObjectsByPlId[plId].objects).forEach((category) => {
                            catalog.reportingObjectsByPlId[plId].objects[category].forEach((object) => {
                                allObjects.push(object.id);
                            });
                        });
                        if (!newObjIds.some((item) => allObjects.includes(item))) {
                            newObjIds.push(catalog.reportingObjectsByPlId[plId].main_servicepoint.id);
                        }
                    });

                    newObjIds = Array.from(new Set(newObjIds));
                    newParamsCanChange.obj_ids = newObjIds;
                }
                setParamsCanChange(newParamsCanChange);
            };

            const _handle_main_obj_ids = (parameter) => {
                let newParamsCanChange = {};
                let newPlIds = [];
                let newIds = Array.from(new Set(paramsCanChange[parameter])).map((item) => Number(item));
                newIds = [...newIds].map((item) => Number(item)).filter((id) => id in catalog.reportingObjectsById);
                let newObjIds = [...paramsCanChange.obj_ids].concat(newIds);
                newObjIds = Array.from(new Set(newObjIds));
                newObjIds.forEach((objId) => {
                    newPlIds.push(catalog.reportingObjectsById[objId].pl_id);
                });
                newPlIds = Array.from(new Set(newPlIds));
                newParamsCanChange = { ...paramsCanChange, [parameter]: newIds, obj_ids: newObjIds, pl_ids: newPlIds };

                setParamsCanChange({ ...newParamsCanChange });
            };

            if (parameter === 'obj_ids') {
                _handle_obj_ids(parameter);
            } else if (parameter === 'pl_ids') {
                _handle_pl_ids(parameter);
            } else if (parameter === 'main_obj_ids') {
                _handle_main_obj_ids(parameter);
            }
        };

        const _correctAdvExt_IdsAndIds = (mode) => (e) => {
            if (mode === 'ext_ids') {
                const allAdvExt_Ids = Object.keys(catalog.outdoorAdSpacesById).map((id) => catalog.outdoorAdSpacesById[id].ext_id);
                const newAdvExt_Ids = Array.from(new Set(advNames)).filter((ext_id) => allAdvExt_Ids.includes(ext_id));
                const advIds = [];
                newAdvExt_Ids.forEach((ext_id) => {
                    Object.keys(catalog.outdoorAdSpacesById).forEach((id) => {
                        if (ext_id === catalog.outdoorAdSpacesById[id].ext_id) {
                            advIds.push(Number(id));
                        }
                    });
                });
                setAdvExt_Ids(newAdvExt_Ids);
                const newParamsCanChange = { ...paramsCanChange, outdoor_ad_space_ids: advIds };
                setParamsCanChange(newParamsCanChange);
            } else if (mode === 'ids') {
                const newAdvIds = Array.from(new Set(paramsCanChange.outdoor_ad_space_ids)).filter(
                    (id) => id in catalog.outdoorAdSpacesById
                );
                const newParamsCanChange = { ...paramsCanChange, outdoor_ad_space_ids: newAdvIds };
                setParamsCanChange(newParamsCanChange);
                fillAdvExt_Ids(newAdvIds);
            }
        };

        const _onChange = (isArray, param, index) => (e, newValue) => {
            let value = '';
            if (param === 'main_date_range' || param === 'comparison_date_range') {
                if (tempDate === '') {
                    setTempDate(paramsCanChange[param][index]);
                }
                value = paramsCanChange[param];
                value[index] = newValue;
            } else if (isArray) {
                value = processNumbersArray(param, newValue);
            } else {
                value = processNumber(param, newValue);
            }
            const newParamsCanChange = { ...paramsCanChange, [param]: value };
            setParamsCanChange(newParamsCanChange);
        };

        const _onBlur = (param, index) => (e) => {
            if (param === 'main_date_range' || param === 'comparison_date_range') {
                processDate(param, index);
            }
        };

        const processDate = (param, index) => {
            const result = paramsCanChange[param];
            const date = new Date(Date.parse(result[index] + ' 00:00:00'));
            if (moment(date).isValid()) {
                result[index] = moment(date).format('YYYY-MM-DD');
            } else {
                result[index] = tempDate;
            }
            setTempDate(() => '');
            const newParamsCanChange = { ...paramsCanChange, [param]: result };
            setParamsCanChange(newParamsCanChange);
        };

        useEffect(() => {
            setAllChecked(false);
        }, [paramsCanChange, advNames]);

        const processNumbersArray = (param, newValue) => {
            const result = newValue
                .replace(/\n/gim, ',')
                .replace(/[^0-9,]/gm, '')
                .split(',');
            return result;
        };
        const processNumber = (param, newValue) => {
            const result = Number(newValue.replace(/[^0-9]/gim, ''));
            return result;
        };

        const _onChoiceChange = useCallback((ev, option) => {
            setChoiceKey(option.key);
        }, []);

        const ChangeComponent = useMemo(() => {
            const result = [];
            if (Object.keys(paramsCanChange).length === 0) return null;
            Object.keys(paramsCanChange).forEach((parameter) => {
                if (parameter === 'main_date_range' || parameter === 'comparison_date_range') {
                    const inputs = (
                        <InputsWrapper key={`inputs-${parameter}`}>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <TextField
                                    label={_t.t('от')}
                                    value={paramsCanChange[parameter][0]}
                                    onChange={_onChange(false, parameter, 0)}
                                    onBlur={_onBlur(parameter, 0)}
                                    styles={{ fieldGroup: { width: 150 } }}
                                />

                                <TextField
                                    label={_t.t('до')}
                                    value={paramsCanChange[parameter][1]}
                                    onChange={_onChange(false, parameter, 1)}
                                    onBlur={_onBlur(parameter, 1)}
                                    styles={{ fieldGroup: { width: 150 } }}
                                />
                            </Stack>
                        </InputsWrapper>
                    );
                    result.push(
                        <Name key={`name-${parameter}`} primaryColor={theme.palette.themePrimary}>
                            {parameter}
                        </Name>
                    );
                    result.push(inputs);
                } else if (parameter === 'outdoor_ad_space_ids') {
                    const options = [
                        { key: 'ids', text: 'ids' },
                        { key: 'ext_ids', text: 'ext_ids' },
                    ];
                    const inputs = (
                        <InputsWrapper key={`inputs-${parameter}`}>
                            <StStack tokens={{ childrenGap: 15 }}>
                                {choiceKey === 'ids' ? (
                                    <StTextField
                                        multiline
                                        autoAdjustHeight
                                        label={'ids'}
                                        value={paramsCanChange[parameter].join(', ')}
                                        onChange={_onChange(true, parameter)}
                                        onBlur={_correctAdvExt_IdsAndIds('ids')}
                                        // styles={{ fieldGroup: { width: '100% !important', maxHeight: 300, overflow: 'auto' } }}
                                    />
                                ) : (
                                    <StTextField
                                        multiline
                                        autoAdjustHeight
                                        label={'ext_ids'}
                                        value={advNames.join('\n')}
                                        onChange={_onNamesChange}
                                        onBlur={_correctAdvExt_IdsAndIds('ext_ids')}
                                        // styles={{ fieldGroup: { width: '100% !important', maxHeight: 300, overflow: 'auto' } }}
                                    />
                                )}
                            </StStack>
                        </InputsWrapper>
                    );
                    result.push(
                        <Name key={`name-${parameter}`} primaryColor={theme.palette.themePrimary}>
                            {parameter}
                            <ChoiceGroup
                                // label="Pick one icon"
                                defaultSelectedKey="ids"
                                options={options}
                                onChange={_onChoiceChange}
                            />
                        </Name>
                    );
                    result.push(inputs);
                } else if (parameter === 'contra_obj_ids') {
                } else if (Array.isArray(paramsCanChange[parameter])) {
                    const inputs = (
                        <InputsWrapper key={`inputs-${parameter}`}>
                            <StTextField
                                multiline
                                autoAdjustHeight
                                // label={_t.t('от')}
                                value={paramsCanChange[parameter].join(', ')}
                                onChange={_onChange(true, parameter)}
                                onBlur={_correctIds(parameter)}
                                // styles={{ fieldGroup: { width: '100% !important', maxHeight: 300, overflow: 'auto' } }}
                            />
                        </InputsWrapper>
                    );
                    result.push(
                        <Name key={`name-${parameter}`} primaryColor={theme.palette.themePrimary}>
                            {parameter}
                        </Name>
                    );
                    result.push(inputs);
                } else if (!Array.isArray(paramsCanChange[parameter])) {
                    const inputs = (
                        <InputsWrapper key={`inputs-${parameter}`}>
                            <TextField
                                // label={_t.t('от')}
                                value={paramsCanChange[parameter]}
                                onChange={_onChange(false, parameter)}
                                onBlur={_correctSingleId(parameter)}
                                styles={{ fieldGroup: { width: 150 } }}
                            />
                        </InputsWrapper>
                    );
                    result.push(
                        <Name key={`name-${parameter}`} primaryColor={theme.palette.themePrimary}>
                            {parameter}
                        </Name>
                    );
                    result.push(inputs);
                }
            });
            return result;
        }, [paramsCanChange, choiceKey, advNames, allChecked]);

        const stackTokens = { childrenGap: 10 };

        const onModalButtonClick = (action, params) => (e) => {
            if (action !== null && !allChecked) {
                setAllChecked(true);
            } else {
                const newParams = cloneDeep(params);
                if (newParams) {
                    Object.keys(newParams).forEach((key) => {
                        if (key !== 'main_date_range' && key !== 'comparison_date_range') {
                            if (Array.isArray(newParams[key])) {
                                newParams[key] = [...newParams[key]].map((item) => Number(item));
                            } else if (!Array.isArray(newParams[key])) {
                                newParams[key] = Number(newParams[key]);
                            }
                        }
                    });
                }
                props.onModalButtonClick({ name: action }, newParams);
            }
        };

        return (
            <Wrapper>
                <Container>{ChangeComponent}</Container>
                <Footer>
                    <Stack horizontal tokens={stackTokens}>
                        <PrimaryButton
                            text={!allChecked ? _t.t('Проверить') : 'OK'}
                            width={'100px'}
                            onClick={onModalButtonClick('saveCustomParams', paramsCanChange)}
                        />
                        <DefaultButton text={_t.t('Отмена')} width={'100px'} onClick={onModalButtonClick(null)} />
                    </Stack>
                </Footer>
            </Wrapper>
        );
    }
);

export default ManualCustomParamsModal;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 10px;
    overflow: auto;
    width: 100%;
`;
const Name = styled.div`
    border-right: ${(p) => '2px solid' + p.primaryColor};
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 15px;
    font-weight: 700;
`;
const InputsWrapper = styled.div`
    display: flex;
    padding-left: 10px;
    align-items: center;
`;

const StTextField = styled(TextField)`
    width: 100% !important;
    textarea {
        max-height: 300px !important;
        overflow: auto !important;
    }
`;
const StStack = styled(Stack)`
    width: 100% !important;
`;
