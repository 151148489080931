import React, { useState, useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components/macro';
import Plate from '../plate';
import { DiagramsContext } from '../../index';
import { generateSize, getFreeHeight } from '../../tools/diagramTools';
import PdfReportItem from './pdf_report_item';
import Diagram, { getIterations } from '../diagram';

const PdfContentTemp = React.memo(({ ...props }) => {
    const {
        permanentCloudData,
        defaultSize,
        sectionsByKeyName,
        currentSection,
        currentSubSection,
        reportData,
        diagramOptionsByID,
        customInputParamsValues,
        globalInputParamsValues,
        fullScreenId,
        showDiagramParamsPanel,
        DiagramParamsPanelId,
        cabinetMode,
        diagramsHeights,
        setLogOverlayShow,
        dataViewsByGroupID,
        changeLayoutC_D,
        components
    } = useContext(DiagramsContext);


    const diagram = () => (
        <Plate
            padding={props.padding}
            cabinetMode={props.cabinetMode}
            iteration={props.i}
            iterationsNumber={props.iterationsNumber}
            groupId={props.groupId}
            dataView={props.dataView}
            reportData={props.reportData}
            defaultSize={props.defaultSize}
            diagramOptionsByID={props.diagramOptionsByID}
            fullScreenId={props.fullScreenId}
            DiagramParamsPanelId={props.DiagramParamsPanelId}
            showDiagramParamsPanel={false}
            id={`pdfReportDataView${props.dataView.data_view_id}-${props.i}`}
            getPlateEndPosition={props.getPlateEndPosition}
        >
            <Diagram
                cabinetMode={props.cabinetMode}
                iteration={props.i}
                iterationsNumber={props.iterationsNumber}
                dataView={props.dataView}
                diagramOptionsByID={props.diagramOptionsByID}
                lang={props.lang}
                defaultSize={props.defaultSize}
                reportData={props.reportData}
                customInputParamsValues={props.customInputParamsValues}
                globalInputParamsValues={props.globalInputParamsValues}
                onMenuButtonMouseEnterLeave={props.onMenuButtonMouseEnterLeave}
                DiagramParamsPanelId={props.DiagramParamsPanelId}
                showDiagramParamsPanel={props.showDiagramParamsPanel}
                storeInputParamsValues={props.storeInputParamsValues}
                storedInputParamsValues={props.storedInputParamsValues}
                currentSubSection={props.currentSubSection}
                diagramsInnerParams={props.diagramsInnerParams}
                reportingObjectsById={props.reportingObjectsById}
                fullScreenId={props.fullScreenId}
                refresh={props.refresh}
                diagramInnerAction={props.diagramInnerAction}
            />

        </Plate>
    );
    switch (props.type) {

        case 'reportTitle':
            return [
                <GroupTitle>
                    <h2>{props.title}</h2>
                </GroupTitle>,
            ];
        case 'reportString':
            return [<PdfReportItem item={props.item} id={props.id} getReportItemEndPosition={props.getReportItemEndPosition} />];
        case 'lineBreak':
            return [<Break />];

        default:
            return null;
    }
});

export default PdfContentTemp;

// const Plate = styled.div`
//     cursor: ${(p) => {
//     if (p.isDraggable && !p.dragging) {
//         return 'grab';
//     } else if (p.isDraggable && p.dragging) {
//         return 'grabbing';
//     } else {
//         return 'default';
//     }
// }};
//     position: relative;
//     background-color: #fff;
//     display: flex;
//     flex-direction: column;
//     padding: ${(p) => p.padding};
//     box-sizing: border-box;
//     /* > div {
//         transform: translateX(-16);
//     } */
//     /* overflow: auto; */
//
//     opacity: ${(p) => (p.dimPlate ? '0.3' : '1')};
//     outline: ${(p) => (p.showOutline ? '2px solid rgb(75, 218, 75)' : 'none')};
//
//     width: ${(p) => {
//     if (p.size === null) {
//         return '1100px';
//     } else {
//         return 'auto';
//         // return p.fullScreenShow ? 'auto' : 'auto';
//     }
// }};
//
//     height: ${(p) => {
//     if (p.size === null) {
//         return 'auto';
//     }
//     if (p.fullScreenShow && !p.keepRatio) {
//         return '100%';
//     }
//     if (p.fullScreenShow && p.keepRatio) {
//         return 'auto';
//     }
//     if (!p.isFreeHeight) {
//         // console.log(p.dataView.data_view_type.key_name, p.size.height);
//
//         return p.size.height + 'px';
//     } else {
//         return 'auto';
//     }
// }};
//
//     &:first-child {
//         box-shadow: ${(p) => {
//     if (p.iterationsNumber === 1) {
//         return '0px 0px 12px rgba(0, 0, 0, 0.1)';
//     } else {
//         return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)';
//     }
// }};
//     }
//
//     &:last-child {
//         box-shadow: ${(p) => {
//     if (p.iterationsNumber === 1) {
//         return '0px 0px 12px rgba(0, 0, 0, 0.1)';
//     } else {
//         return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)';
//     }
// }};
//     }
//
//     &:only-child {
//         box-shadow: ${(p) => {
//     return '0px 0px 12px rgba(0, 0, 0, 0.1)';
// }};
//     }
//
//     box-shadow: ${(p) => {
//     if (p.iterationsNumber === 1) {
//         return '0px 0px 12px rgba(0, 0, 0, 0.1)';
//     } else if (p.iteration === p.iterationsNumber - 1) {
//         return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.06)';
//     } else if (p.iteration === 0) {
//         return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.06)';
//     } else {
//         return '8px 0 8px -4px rgba(0, 0, 0, 0.06), -8px 0 8px -4px rgba(0, 0, 0, 0.06)';
//     }
// }};
//     margin-bottom: ${(p) => {
//     if (p.iterationsNumber === 1 || p.iteration === p.iterationsNumber - 1) {
//         return '16px';
//     } else {
//         return '0px';
//     }
// }};
// `;

const Break = styled.div`
    flex-basis: 100%;
    width: 0px;
    height: 0px;
    overflow: hidden;
`;

const GroupTitle = styled.div`
    width: 100%;
    border-bottom: 1px solid #99ccae;
    padding-bottom: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
    h2 {
        font-family: ProximaNova;
        font-size: 17px;
        color: rgb(36, 49, 56);
        padding: 0;
        margin: 0;
    }
`;
