import React from 'react';
import Select from "./components/select";
import './style/style_select.scss';

/**
 * @return {null}
 */
function MainSelect(props) {
    if (props.options === undefined) {
        return null;
    }
    return (
        <div className={'boxReactSelect'} style={{
            width: `${props.widthBox}px`
        }}>
            <Select
                options={props.options}
                onChange={props.onChange}
                value={props.value}
                widthSelect={props.widthSelect}
                _t={props._t}
                statusOptionsComponent={props.statusOptionsComponent}
            />
            <div className="SelectInputParameter__arrow">
                <i className="fa fa-angle-down fa-1.5x" aria-hidden="true" />
            </div>
        </div>
    );
}

export default MainSelect;
