import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";


const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];
const BarChartCustom = (props) => {
    const {
        title,
        _data,
        numberWithCommas,
        label = ''
    } = props;

    const { t } = useTranslation();

    function formatYAxis(value) {
        // if(value === 0) return "No"
        // if(value === 1) return "Yes"
        return numberWithCommas(value);
    }

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        return <span style={{ color }}>{t(value)}</span>;
    };

    const CustomTooltip = ({ active, payload, label }) => {


        if (active && payload && payload.length) {
            const {
                color
            } = payload[0]
            return (
                <DivBoxTultipSC color={color} className="custom-tooltip">
                    <p >{`${t(payload[0].name)} : ${payload[0].value.toFixed(2)}`}</p>
                </DivBoxTultipSC>
            );
        }

        return null;
    };

    return (
        <DivBarChartCustomSC>
            <DivBoxTitleDiagramSC>
                <h4>{title}</h4>
            </DivBoxTitleDiagramSC>
            <ResponsiveContainer width="100%" height={249}>
                <BarChart
                    width='100%'
                    height={249}
                    data={_data}
                    // margin={{
                    //     top: 5,
                    //     right: 30,
                    //     left: 0,
                    //     bottom: 5,
                    // }}
                    barSize={20}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis
                        tickFormatter={formatYAxis}
                        label={{ value: label, angle: -90, position: 'insideBottomLeft', offset: 3 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend formatter={renderColorfulLegendText} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="Прогнозируемая частота" fill="#8884d8" background={{ fill: '#eee' }} />
                </BarChart>
            </ResponsiveContainer>
        </DivBarChartCustomSC>
    );
}

const DivBarChartCustomSC = styled.div`
  background: #ffffff;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
`

const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  h4 {
    font-size: 17px;
  }
`

const DivBoxTultipSC = styled.div`
  background: white;
  padding: 10px;
  border: 1px solid grey;
  color: ${({color}) => (color)};
`

export default BarChartCustom;