import React from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import { getPointParams } from './tools';

const Circles = React.memo(({ data, typeOfRouter, selectedSpaces, onCircleClick }) => {
  try {
    return data.map((item, i) => {
      const { circleColor, radius, tooltip } = getPointParams(item, typeOfRouter, selectedSpaces);

      return (
        <Circle
          key={`c-${item.id}`}
          id={item.id}
          center={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
          fillColor={circleColor}
          fillOpacity={1}
          color={'transparent'}
          radius={radius}
          onClick={onCircleClick(item.id, typeOfRouter)}
        >
          <Tooltip key={i}>{tooltip}</Tooltip>
        </Circle>
      );
    });
  } catch (error) {
    return null;
  }
});

export default Circles;
