import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { runAction } from '../../../actions';

const Contra = React.memo(({ mainIdClicked, mode, ...props }) => {
    const dispatch = useDispatch();
    const { customInputParamsValues } = useSelector(state => state.ReportParametersManager);
    const { obj_ids, contra_obj_ids } = customInputParamsValues;

    useEffect(() => {
        if (obj_ids.length !== 0 && contra_obj_ids.length === 0) {
            const contra_obj_ids = [...obj_ids];
            dispatch(runAction('contra_RPM', { contra_obj_ids }));
        }
    }, []);

    useEffect(() => {
        const { id } = mainIdClicked;

        if (obj_ids.length !== 0 && id) {
            if (mode === 'main_2_contra_set') {
                const contra_obj_ids = [...obj_ids];
                if (contra_obj_ids.includes(id)) {
                    contra_obj_ids.splice(contra_obj_ids.indexOf(id), 1);
                }
                dispatch(runAction('contra_RPM', { contra_obj_ids, main_obj_id: id }));
            } else if (mode === 'main_set_2_contra_set') {
                const main_obj_ids = [...customInputParamsValues.main_obj_ids];
                if (main_obj_ids.includes(id)) {
                    main_obj_ids.splice(main_obj_ids.indexOf(id), 1);
                } else {
                    main_obj_ids.push(id);
                }

                const contra_obj_ids = [...obj_ids];
                if (main_obj_ids.length !== 0 && main_obj_ids.some(item => contra_obj_ids.includes(item))) {
                    main_obj_ids.forEach(item => {
                        if (contra_obj_ids.includes(item)) {
                            contra_obj_ids.splice(contra_obj_ids.indexOf(item), 1);
                        }
                    });
                }
                dispatch(runAction('contra_RPM', { contra_obj_ids, main_obj_ids }));
            }
        }
    }, [mainIdClicked]);

    useEffect(() => {
        if (obj_ids.length !== 0) {
            if (mode === 'main_2_contra_set') {
                const contra_obj_ids = [...obj_ids];
                let main_obj_id = customInputParamsValues.main_obj_id;
                if (!obj_ids.includes(main_obj_id)) {
                    main_obj_id = null;
                } else {
                    if (contra_obj_ids.includes(main_obj_id)) {
                        contra_obj_ids.splice(contra_obj_ids.indexOf(main_obj_id), 1);
                    }
                }
                dispatch(runAction('contra_RPM', { contra_obj_ids, main_obj_id }));
            } else if (mode === 'main_set_2_contra_set') {
                const contra_obj_ids = [...obj_ids];
                const main_obj_ids = [...customInputParamsValues.main_obj_ids];

                [...main_obj_ids].forEach(item => {
                    if (!obj_ids.includes(item)) {
                        main_obj_ids.splice(main_obj_ids.indexOf(item), 1);
                    }
                });

                if (main_obj_ids.length !== 0 && main_obj_ids.some(item => contra_obj_ids.includes(item))) {
                    main_obj_ids.forEach(item => {
                        if (contra_obj_ids.includes(item)) {
                            contra_obj_ids.splice(contra_obj_ids.indexOf(item), 1);
                        }
                    });
                }
                dispatch(runAction('contra_RPM', { contra_obj_ids, main_obj_ids }));
            }
        }
    }, [obj_ids, mode]);

    return <React.Fragment></React.Fragment>;
});

export default Contra;
