import { runAction } from '../actions';
import hostForLocation from '../hostnames/hostname';

export const getAdCampaigns = (token, dispatch, campaignsToShow, project_id = null) => {
    const headers = {
        'x-token': token,
        'Content-type': 'application/json',
    };
    const options = { method: 'GET', mode: 'cors', headers };
    const url = `${hostForLocation.getHostName().export}/adapp/campaigns/?items=${campaignsToShow}&project_id=${project_id}`;
    fetch(url, options)
        .then((response) => response.json())
        .then((json) => dispatch(runAction('getAdCampaigns_RPM', { json })))
        .catch((err) => console.warn(`ERROR getAdCampaigns ${err}`));
    // .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
};

export const getSegments = (token, dispatch, segmentsToShow) => {
    const headers = {
        'x-token': token,
        'Content-type': 'application/json',
    };
    const options = { method: 'GET', mode: 'cors', headers };
    const url = `${hostForLocation.getHostName().export}/segment_api/segments/?items=${segmentsToShow}`;
    dispatch(runAction('getRequestSegments_CustomControls', { }))
    fetch(url, options)
        .then((response) => response.json())
        .then((json) => dispatch(runAction('getSegments_CustomControls', { json })))
        .catch((err) => console.warn(`ERROR getSegments ${err}`));
    // .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
};

export const getFoundSegments = (token, dispatch, searchString) => {
    const headers = {
        'x-token': token,
        'Content-type': 'application/json',
    };
    const options = { method: 'GET', mode: 'cors', headers };
    const url = `${hostForLocation.getHostName().export}/segment_api/segments/?search=${searchString}`;
    fetch(url, options)
        .then((response) => response.json())
        .then((json) => dispatch(runAction('getFoundSegments_CustomControls', { json })))
        .catch((err) => console.warn(`ERROR getFoundSegments ${err}`));
    // .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
};
