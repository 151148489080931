import React from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList,
    ResponsiveContainer,
} from 'recharts';
import styled from "styled-components/macro";

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
        label: 'dsa'
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
        label: 'dsa'
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 8000,
        amt: 2290,
        label: 'dsa'
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
        label: 'dsa'
    }
];

const renderCustomizedLabel = (props) => {
    // console.log('>><kirdro>>>', props)
    const { x, y, width, height, value, index } = props;
    const radius = 10;

    return (
        <g>
            <text x={x + width + 10} y={y + height / 2} fill="#8884d8" textAnchor="middle" dominantBaseline="middle">
                {value.split(' ')[1]}
            </text>
        </g>
    );
};


const BarHorizCharCustomLable = (props) => {
    const {
        title,
        _data
    } = props;

    return (
        <DivBarHorizCharCustomLableSC>
            <DivBoxTitleDiagramSC>
                <h4>{title}</h4>
            </DivBoxTitleDiagramSC>
            <ResponsiveContainer width="100%" height={249}>
                <BarChart
                    width='100%'
                    height={249}
                    data={data}
                    // margin={{
                    //     top: 5,
                    //     right: 30,
                    //     left: 20,
                    //     bottom: 5,
                    // }}
                    layout={'vertical'}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis />
                    <YAxis
                     type={'number'} 
                     dataKey="name"
                      />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" minPointSize={5}>
                        <LabelList dataKey="name" content={renderCustomizedLabel} />
                    </Bar>
                    <Bar dataKey="uv" fill="#82ca9d" minPointSize={10} />
                </BarChart>
            </ResponsiveContainer>
        </DivBarHorizCharCustomLableSC>
    );
}

const DivBarHorizCharCustomLableSC = styled.div`
  background: #ffffff;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
`

const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  h4 {
    font-size: 17px;
  }
`

export default BarHorizCharCustomLable;