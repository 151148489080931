import React from 'react';
import styled from 'styled-components/macro';
import ContextMenuButton from '../../obj_sets_panel/components/context_menu_button';
import ColorDot from './color_dot';

const Container = styled.div`
    cursor: pointer;
    background-color: ${(props) => (props.statusSelect ? 'rgb(34,40,108)' : 'rgb(216, 216, 216)')};
    border: 1px solid;
    border-color: ${(props) => (props.statusSelect ? 'rgb(34,40,108)' : 'rgb(189, 189, 189)')};
    width: 100%;
    /* width: 195px; */
    min-height: 23px;
    border-radius: 6px;
    box-sizing: border-box;
`;

const Inner = styled.div`
    border-color: ${(props) => (props.statusSelect ? 'rgb(34,40,108)' : 'rgb(189, 189, 189)')};
    min-height: 23px;
    margin-left: 3px;
    border-radius: 0px 6px 6px 0px;
    border-left: 1px solid;
    background-color: ${(p) => p.backColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-family: 'ProximaNova';
        margin-left: 5px;
    }
    /* i {
        display: ${(props) => (props.showDeleteObjectsButtons ? 'block' : 'none')};
        margin-left: auto;
        margin-right: 5px;
        cursor: pointer;
        color: #bbb;
        :hover {
            color: red;
        }
    } */
`;

const Dash = React.memo(
    ({
        text,
        сontextMenu = null,
        showDeleteObjectsButtons = false,
        dotColor = false,
        plId = undefined,
        backColor = '#fff',
        statusSelect = false,
        ...props
    }) => {
        const onDashClick = () => {
            props.onClick(props.id, plId);
        };

        const onDeleteButtonClick = () => {
            if (showDeleteObjectsButtons) {
                props.onDeleteDashButtonClick(props.id, plId);
            }
        };

        return (
            <Container statusSelect={statusSelect} onClick={onDashClick}>
                <Inner backColor={backColor}>
                    <span>{text}</span>
                    {сontextMenu && сontextMenu.show ? (
                        <ContextMenuButton сontextMenu={сontextMenu} statusSelect={statusSelect} id={props.id} name={props.name} />
                    ) : null}
                    {/* <i onClick={onDeleteButtonClick} className="fa fa-times-circle" /> */}
                    <ColorDot dotColor={dotColor} showDeleteObjectsButtons={showDeleteObjectsButtons} onClick={onDeleteButtonClick} />
                </Inner>
            </Container>
        );
    }
);

export default Dash;
