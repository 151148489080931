import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {IconButton} from "office-ui-fabric-react/lib/Button";
import {runAction} from "../../actions";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import LeftSide from "./components/leftSidePanel";
import RightSideWrapper from "./components/rightSidePanel";
import hostForLocation from "../../hostnames/hostname";
import {ClipLoader} from "react-spinners";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    Stack,
    IStackProps,
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {useTranslation} from "react-i18next";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

const MainPdf = () => {
    const dispatch = useDispatch();
    const closeIcon = { iconName: 'ErrorBadge' };
    const { schedulers, pdfReports, reportParams, statusRequestNewReport, statusSpinner, reportParamsScheduler } = useSelector((state) => state.Pdf);
    const { token } = useSelector((state) => state.LoginApp);
    const {
        status,
        statusLeftPanel,
        sectionsByKeyName,
        lang,
        dataSelectSections
    } = useSelector((state) => state.TopPanel);
    const [theme] = useState(getTheme());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [idSchedulers, setIdSchedulers] = useState(0);
    const [idPdfReport, setIdPdfReport] = useState(0);
    const [isModalOpenPdfDelete, setIsModalOpenPdfDelete] = useState(false);
    const { t, i18n } = useTranslation();




    useEffect(() => {
        if (pdfReports.length > 0) {
            const headers = {
                'x-token': token,
            };
            dispatch(
                runAction('getSchedulers', {
                    url: `${hostForLocation.getHostName().export}/reports/schedulers/`,
                    headers
                })
            );
        }
        else {
            dispatch(
                runAction('hideSpinner', {

                })
            );
        }
    }, [pdfReports]);

    const hideTables = () => {
        // props.hideTables();
        dispatch(runAction('getStatusShowModalPDF', {status: false}));
    };

    const deleteSchedulers = (id) => {
        setIdSchedulers(id)
        setIsModalOpen(true);
    };

    const onHideModal = () => {
        setIsModalOpen(false);
    }

    const contentStyles = mergeStyleSets({
        container: {
            // display: 'flex',
            // flexFlow: 'column nowrap',
            // alignItems: 'stretch',
            minHeight: '100px'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            // flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            // selectors: {
            //     p: { margin: '14px 0' },
            //     'p:first-child': { marginTop: 0 },
            //     'p:last-child': { marginBottom: 0 },
            // },
        },
    });

    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };


    const titleId = useId('title');

    const cancelIcon = { iconName: 'Cancel' };

    const onClickButton = () => {
        setIsModalOpen(false);
        const headers = {
            'x-token': token,
        };
        dispatch(
            runAction('setStatusShowSpinnerPdfReports', {
                status: true
            })
        );
        dispatch(
            runAction('deleteSchedulers', {
                url: `${hostForLocation.getHostName().export}/reports/schedulers/${idSchedulers}/`,
                headers
            })
        );
    }

    const onClickButtonDeletePdfReport = (_idPdfReport) => {
        setIdPdfReport(_idPdfReport);
        setIsModalOpenPdfDelete(true)

    }

    const onClickButtonDeletePdfReportModal = () => {
        setIsModalOpenPdfDelete(false);
        const headers = {
            'x-token': token,
        };
        dispatch(
            runAction('setStatusShowSpinnerPdfReports', {
                status: true
            })
        );
        dispatch(
            runAction('deletePdfReport', {
                url: `${hostForLocation.getHostName().export}/reports/pdf-reports/${idPdfReport}/`,
                headers
            })
        );
    }

    const hideModalPdf = () => {
        setIsModalOpenPdfDelete(false);
    }

    const styleButtonDelete = {
        root: {
            background: '#e00a0a',
            borderColor: '#e00a0a'

        },
        rootHovered: {
            background: '#a70707',
            borderColor: '#a70707'
        }
    }

    return (
        <BoxSCPdf>
            <LeftSCWrapper>
                <LeftSide
                    reportParams={reportParams}
                    reportParamsScheduler={reportParamsScheduler}
                    schedulers={schedulers}
                    deleteSchedulers={deleteSchedulers}
                />
            </LeftSCWrapper>
            <RightSCWrapper>
                <div>
                    <StIconButton iconProps={closeIcon} onClick={hideTables} title="Close" ariaLabel="Close" />
                </div>
                <RightSideWrapper
                    status={status}
                    statusLeftPanel={statusLeftPanel}
                    pdfReports={pdfReports}
                    sectionsByKeyName={sectionsByKeyName}
                    hostExport={hostForLocation.getHostName().export}
                    onClickButtonDeletePdfReport={onClickButtonDeletePdfReport}
                />

            </RightSCWrapper>
            {
                statusRequestNewReport ?
                    <SpinnerContainer>
                        <ClipLoader color={'#46b875'} loading={true} size={30} />
                    </SpinnerContainer> :
                    null
            }
            {
                statusSpinner ?
                    <SpinnerContainer>
                        <ClipLoader color={'#46b875'} loading={true} size={30} />
                    </SpinnerContainer> :
                    null
            }
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={onHideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                // dragOptions={isDraggable ? dragOptions : undefined}
            >
                <div className={contentStyles.header}>
                    <span id={titleId}>Информация</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onHideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <p>
                        {t('Планировщик будет удален. Периодические отчеты по данному планировщику более не будут создаваться.')}
                    </p>

                    <Stack horizontal horizontalAlign="end"
                        // styles={stackStyles}
                    >
                        <PrimaryButton
                            text={t('Удалить')}
                            onClick={onClickButton}
                            allowDisabledFocus
                            // disabled={disabled}
                            // checked={checked}
                        />
                    </Stack>
                </div>

            </Modal>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpenPdfDelete}
                onDismiss={hideModalPdf}
                isBlocking={false}
                containerClassName={contentStyles.container}
                // dragOptions={isDraggable ? dragOptions : undefined}
            >
                <div className={contentStyles.header}>
                    <span id={titleId}>{t('Удаление')}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideModalPdf}
                    />
                </div>
                <div className={contentStyles.body}>
                    <p>
                        {t('Отчет будет удален!')}
                    </p>

                    <Stack horizontal horizontalAlign="end"
                        // styles={stackStyles}
                    >
                        <PrimaryButton
                            text={t('Удалить')}
                            onClick={onClickButtonDeletePdfReportModal}
                            allowDisabledFocus
                            styles={styleButtonDelete}
                            // disabled={disabled}
                            // checked={checked}
                        />
                    </Stack>
                </div>

            </Modal>
        </BoxSCPdf>
    );
};

export default MainPdf;

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    //flex-basis: 100%;
    background: #ffffff91;
    font-family: ProximaNova;
    pointer-events: all;
    z-index: 10000;
`;

const BoxSCPdf = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: grid;
  pointer-events: none;
  grid-template-columns: 238px calc(100% - 238px);
  grid-template-rows: 100%;
  //background: #1c7430;
  //z-index: 300;
`;

const LeftSCWrapper = styled.div`
  pointer-events: auto;
  margin-top: 50px;
  height: calc(100% - 50px);
  width: 100%;
  background: #e9e9e9;
`;

const RightSCWrapper = styled.div`
  pointer-events: auto;
  height: 100%;
  width: 100%;
  background: #fff;
`;

const StIconButton = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 5px;
    i {
        font-size: 24px !important;
    }
`;

