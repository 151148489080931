import React, { useRef, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';

import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import '../style/styleHotdpotButtons.scss';

const MIN_WIDTH = 300;

const TopMenu = ({ sectionHistory, onTopMenuButtonClick, currentSection, showFavoritesOnly, input_parameter_list, dataSections }) => {
    const mainWrapperRef = useRef();
    const testDivRef = useRef();
    let objInputParam = {};
    for (var r = 0; r < input_parameter_list.length; r++) {
        if (input_parameter_list[r].input_parameter_type.key_name === 'global') {
            objInputParam[input_parameter_list[r].key_name] = input_parameter_list[r].details.default_value;
        }
    }

    const [menuArray, set_menuArray] = useState([]);
    const [initialArray, set_initialArray] = useState([]);
    const [initialWidth, set_initialWidth] = useState(MIN_WIDTH);
    const [currentWidth, set_currentWidth] = useState(MIN_WIDTH);


    useEffect(() => {
        const menuArray = dataSections.map((section, i) => {
            // console.log('>!!!!!!!!!!', section.subsections[0].required_input_parameters)
            // let statusUrlAddparam = false;
            // for (var t = 0; t < reportCatalog.length; t++) {
            //     if (section.key_name === reportCatalog[t].group.subsection_key_name) {
            //         statusUrlAddparam = true;
            //     }
            // }
            // let idsObj = '';
            // if (section.subsections[0].required_input_parameters.indexOf('pl_ids') > -1) {
            //     idsObj = stringObj;
            // } else if (section.subsections[0].required_input_parameters.indexOf('pl_id') > -1) {
            //     idsObj = firstIdObj;
            // }

            let statusLeftPanel = sectionHistory[section.key_name].key_name;
            let reqInput = sectionHistory[section.key_name].required_input_parameters;

            const favoritesSubsections = section.subsections.filter((item) => item.favorite);
            const isInFavor = favoritesSubsections.some((item) => item.key_name === statusLeftPanel);
            if (showFavoritesOnly && favoritesSubsections.length !== 0 && !isInFavor) {
                statusLeftPanel = favoritesSubsections[0].key_name;
                reqInput = favoritesSubsections[0].required_input_parameters;
            }

            // console.log('---->>', statusLeftPanel);


            return {
                key: section.translations['en'].name,
                text: section.translations['en'].name,
                name_ru: section.translations['ru'].name,
                name_en: section.translations['en'].name,
                key_name: section.key_name,
                statusLeftPanel,
                onClick: () => onTopMenuButtonClick(section.key_name, reqInput, section, statusLeftPanel),
                // iconProps: { iconName: 'Edit' }
            };
        });

        const initialArray = dataSections.map((section, i) => {
            return {
                key: section.translations['en'].name,
                text: section.translations['en'].name,
            };
        });

        set_menuArray([...menuArray]);
        set_initialArray([...initialArray]);
    }, [dataSections, sectionHistory, showFavoritesOnly]);

    const CustomButton = (props) => {
        const showUnderliner = currentSection === props.key_name;
        return (
            <ButtonWrapper>
                <CommandBarButton {...props} onClick={props.onClick} />
                {showUnderliner ? <Underliner /> : null}
            </ButtonWrapper>
        );
    };

    const _dataDidRender = (data) => {
        set_currentWidth(mainWrapperRef.current.clientWidth);
        if (testDivRef.current.clientWidth > initialWidth) {
            set_initialWidth(testDivRef.current.clientWidth);
        }
    };

    const bar = useMemo(() => {
        if (currentWidth < initialWidth) {
            const items = menuArray.filter((item) => item.key_name === currentSection);
            const overflowArray = menuArray.filter((item) => item.key_name !== currentSection);
            return <StCommandBar dataDidRender={_dataDidRender} buttonAs={CustomButton} items={items} overflowItems={overflowArray} />;
        }

        return <StCommandBar dataDidRender={_dataDidRender} buttonAs={CustomButton} items={menuArray} />;
    }, [menuArray, currentSection, initialWidth, currentWidth]);

    return (
        <MainWrapper MIN_WIDTH={MIN_WIDTH} ref={mainWrapperRef}>
            {menuArray.length !== 0 ? bar : null}
            <TestDiv ref={testDivRef}>
                <StCommandBar buttonAs={CustomButton} items={initialArray} />
            </TestDiv>
        </MainWrapper>
    );
};

const TestDiv = styled.div`
    position: absolute;
    visibility: hidden;
    height: 0;
    width: auto;
    white-space: nowrap;
`;

const MainWrapper = styled.div`
    overflow: hidden;
    flex-grow: 1;
    min-width: ${(p) => p.MIN_WIDTH + 'px'};
`;
const Underliner = styled.div`
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 4px;
    background-color: #f1f8f3 !important;
`;

const ButtonWrapper = styled.div`
    position: relative;
`;

const StCommandBar = styled(CommandBar)`
    background-color: transparent;
    div {
        background-color: transparent;
        padding: 0;
    }
    button {
        margin: 0 15px;
        padding: 0;
        background-color: transparent;
        color: #f1f8f3;
        :hover {
            background-color: transparent !important;
            color: #fff;
        }
    }
    span {
        padding: 0;
        font-size: 18px;
        font-weight: 700;
        color: inherit;
    }
    i {
        font-size: 18px;
        font-weight: 700;
        color: inherit !important;
    }
`;

export default TopMenu;
