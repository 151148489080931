/* eslint-disable no-case-declarations */
/* eslint-disable no-empty */
/* eslint-disable indent */
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import ObjectsList from './obj_list';
import ObjSetsList from '../../obj_sets_panel/components/objsets_list';
import NoSelectedWarning from '../../../warnings/no_selected_warning';
import ColorDot from './color_dot';

const Container = styled.div`
    /* height: 100%; */
    flex-grow: 1;
`;
const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 6px;
`;

const DeleteButton = styled.div`
    cursor: pointer;
    width: 18px;
    height: 18px;

    background-color: #bbb;
    border-radius: 50%;
    flex-shrink: 0;
    :hover {
        background-color: red;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        display: flex;
        width: 8px;
        height: 3px;
        background-color: whitesmoke;
    }
`;

const PlButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 35px;
  flex-shrink: 0;
  background: rgb(242, 242, 242);
  margin-bottom: 6px;
  margin-top: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
  i {
    cursor: pointer;
    color: #bbb;
    :hover {
      color: red;
    }
  };
  
  span {
    cursor: pointer;
    font-family: ProximaNova;
    display: flex;
    align-items: center;
    width: 90%;
    color: ${(props) => props.locationLabelColor};
    font-size: 12pt;
    line-height: 1.1;
    text-align: left;
    /* pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; */
  };
 
`;

const PlList = React.memo(
    ({
        showObjectsColorDots,
        reportingObjectsByPlId_ctg,
        reportingObjectsById_ctg,
        locationLabelColor,
        handlePlButtonClick,
        history,
        colors,
        mode,
        showDeleteButtons,
        showDeleteObjectsButtons,
        handleLocationDeleteClick,
        lang,
        amountOfSelectedLocations,
        onDeleteDashButtonClick,
        dataobjsets,
        ObjSetsHasPlList,
        PlArray,
        independentObjSetsList,
        customInputParamsValues,
        showDeleteObjSetButtons,
        onDeleteObjSetDashButtonClick,
        ...props
    }) => {
        const [mouseOver, setMouseOver] = useState(false);
        const { t } = useTranslation();
        const onPlButtonClick = (plId) => (e) => {
            ReactGA.event({
                category: 'LeftPanel',
                action: `Project Location Name Click`,
                label: `plId: ${plId}`,
            });

            if (
                mode === 'multiLocation_multiObject' ||
                mode === 'main_2_contra_set' ||
                mode === 'main_set_2_contra_set' ||
                mode === 'multiAll'
            ) {
                handlePlButtonClick(plId);
            }
        };

        const onLocationDeleteClick = (plId) => (e) => {
            handleLocationDeleteClick(plId);
        };

        const onObjSetDashClick = (id, plId) => {};

        const onMouseEnter = (e) => {
            setMouseOver(true);
        };

        const onMouseLeave = (e) => {
            setMouseOver(false);
        };

        const lastPlId = history.lastLocationsStack[0];

        const generateList = (mode) => {
            switch (mode) {
                case 'multiLocation_multiObject':
                case 'main_set_2_contra_set':
                case 'main_2_contra_set':
                case 'multiLocation':
                case 'multiAll':
                    const allObjects = [];

                    return amountOfSelectedLocations > 0 || independentObjSetsList.length > 0 || ObjSetsHasPlList.length > 0 ? (
                        <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {independentObjSetsList.length !== 0 && (
                                <ObjSetsList
                                    idList={independentObjSetsList}
                                    dataobjsets={dataobjsets}
                                    history={history}
                                    leftPadding={10}
                                    rightPadding={10}
                                    showDeleteObjSetButtons={showDeleteObjSetButtons}
                                    onDeleteObjSetDashButtonClick={onDeleteObjSetDashButtonClick}
                                    onObjSetDashClick={onObjSetDashClick}
                                />
                            )}

                            <Ul>
                                {reportingObjectsByPlId_ctg &&
                                    reportingObjectsById_ctg &&
                                    PlArray.map((plId) => {
                                        const { objects } = history.locations[plId];
                                        const idList = history.objSets.hasPl[plId]
                                            ? history.objSets.hasPl[plId].sets.filter((item) => item.selected)
                                            : [];

                                        allObjects.push(...objects);
                                        return (
                                            <li key={`projectLocations ${plId}`}>
                                                <PlButton disabled={showDeleteButtons} locationLabelColor={locationLabelColor}>
                                                    <span onClick={onPlButtonClick(plId)}>{reportingObjectsByPlId_ctg[plId].pl_name}</span>
                                                    {showDeleteButtons && (
                                                        <ColorDot showDeleteObjectsButtons={true} onClick={onLocationDeleteClick(plId)} />
                                                        // <i onClick={onLocationDeleteClick(plId)} className="fa fa-times-circle" />
                                                    )}
                                                </PlButton>
                                                {(mode === 'multiLocation_multiObject' ||
                                                    mode === 'main_set_2_contra_set' ||
                                                    mode === 'multiAll' ||
                                                    mode === 'main_2_contra_set') && (
                                                    <div>
                                                        {idList.length !== 0 && (
                                                            <ObjSetsList
                                                                idList={idList}
                                                                dataobjsets={dataobjsets}
                                                                history={history}
                                                                leftPadding={30}
                                                                rightPadding={10}
                                                                showDeleteObjSetButtons={showDeleteObjSetButtons}
                                                                onDeleteObjSetDashButtonClick={onDeleteObjSetDashButtonClick}
                                                                plId={Number(plId)}
                                                                onObjSetDashClick={onObjSetDashClick}
                                                            />
                                                        )}
                                                        {objects.length !== 0 && (
                                                            <ObjectsList
                                                                colors={colors}
                                                                showObjectsColorDots={showObjectsColorDots}
                                                                onMainObjectClick={props.onMainObjectClick}
                                                                mode={mode}
                                                                mouseOver={mouseOver}
                                                                customInputParamsValues={customInputParamsValues}
                                                                lang={lang}
                                                                allObjects={allObjects}
                                                                objects={objects}
                                                                reportingObjectsByPlId_ctg={reportingObjectsByPlId_ctg}
                                                                reportingObjectsById_ctg={reportingObjectsById_ctg}
                                                                showDeleteObjectsButtons={showDeleteObjectsButtons}
                                                                onDeleteDashButtonClick={onDeleteDashButtonClick}
                                                                plId={plId}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                            </Ul>
                        </Container>
                    ) : null;

                case 'singleLocation':
                    return (
                        reportingObjectsByPlId_ctg &&
                        lastPlId && (
                            <Container>
                                <PlButton
                                    //  onClick={onPlButtonClick(plId)}
                                    locationLabelColor={locationLabelColor}
                                >
                                    <span>{reportingObjectsByPlId_ctg[lastPlId].pl_name}</span>
                                </PlButton>
                            </Container>
                        )
                    );

                default:
                    return null;
            }
        };
        return generateList(mode);
    }
);

export default PlList;
