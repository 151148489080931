import React from "react";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import styled from "styled-components/macro";

const data = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
];

const ScatterChartCustom = (props) => {
    const { title, _data } = props;

    return (
        <DivScatterChartCustomSC>
            <DivBoxTitleDiagramSC>
                <h4>{title}</h4>
            </DivBoxTitleDiagramSC>
            <ResponsiveContainer width="100%" height={270}>
                <ScatterChart
                    width="100%"
                    height={270}
                    // margin={{
                    //     top: 20,
                    //     right: 20,
                    //     bottom: 20,
                    //     left: 20,
                    // }}
                    // data={_data}
                >
                    <CartesianGrid />
                    <XAxis 
                    // type="category"
                     dataKey="x"
                    //   name="stature"
                    //    unit="cm"
                        />
                    <YAxis
                     type="number"
                     dataKey="y"
                    //  name="weight"
                    //   unit="kg"
                       />
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                    <Scatter name="A school" data={_data} fill="#8884d8" />
                </ScatterChart>
            </ResponsiveContainer>
        </DivScatterChartCustomSC>
    );
};

const DivScatterChartCustomSC = styled.div`
  background: #ffffff;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  display: grid;
  height: 300px;
  //height: fit-content;
`

const DivBoxTitleDiagramSC = styled.div`
    //padding-left: 10px;
  h4 {
    font-size: 17px;
  }
`;

export default ScatterChartCustom;
