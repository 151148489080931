import React from 'react';
import styled from 'styled-components/macro';
import Dash from '../../pl_panel/components/dash';

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: ${p => p.leftPadding + 'px'};
    padding-right: ${p => p.rightPadding + 'px'};
    width: 100%;
    box-sizing: border-box;
`;

const Li = styled.li`
    list-style: none;
    padding: 0;
    margin: 0 0 7px 0;
    box-sizing: border-box;
`;

export default React.memo(
    ({
        idList,
        leftPadding = 0,
        rightPadding = 0,
        showDeleteObjSetButtons,
        onDeleteObjSetDashButtonClick,
        сontextMenu = null,
        plId,
        ...props
    }) => {
        return (
            <Ul leftPadding={leftPadding} rightPadding={rightPadding}>
                {idList.map(item => {
                    const text = `${item.name} (${item.bound_dataobjs.length})`;
                    return (
                        <Li key={`objset - ${item.name} - ${item.id}`}>
                            <Dash
                                id={item.id}
                                name={item.name}
                                plId={plId}
                                сontextMenu={сontextMenu}
                                onClick={props.onObjSetDashClick}
                                backColor={'#bedfff'}
                                text={text}
                                statusSelect={item.selected}
                                showDeleteObjectsButtons={showDeleteObjSetButtons}
                                onDeleteDashButtonClick={onDeleteObjSetDashButtonClick}
                            />
                        </Li>
                    );
                })}
            </Ul>
        );
    }
);
