import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import Tools from '../../../tools/tools';
import { useTranslation } from 'react-i18next';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import ContextMenu from '../../context_menu/context_menu';
import { DiagramsContext } from '../index';

import img from '../../../img/three_dots.svg';

export default React.memo(({ ...props }) => {
    const { t } = useTranslation();
    const diagramContextValue = useContext(DiagramsContext);
    const {
        permanentCloudData,
        sectionsByKeyName,
        toggleModal,
        addDiagramToC_D,
        toggleSharedAlert,
        // deleteDiagramFromC_D,
        currentSection,
        dataViewsByGroupID,
        groupIDsBySubsectionKeyName,
    } = diagramContextValue;
    let fullScreen = props.showDiagram && props.dataView ? props.diagramOptionsByID[props.dataView.data_view_id].fullScreen : undefined;

    const C_D_subMenuProps = useMemo(() => {
        const items = sectionsByKeyName.custom_section.subsections.map((item) => {
            let disabled = Tools.isDisabled(
                permanentCloudData,
                dataViewsByGroupID,
                groupIDsBySubsectionKeyName,
                item.key_name,
                props.dataView,
                item.id
            );

            const groups = item.data_view_groups.map((group) => {
                return {
                    key: group.id,
                    text: group.translations['en'].name,
                    onClick: () => {
                        addDiagramToC_D({ dataView: props.dataView, group, subsection: item, inputParamsValues: props.inputParamsValues });
                        toggleSharedAlert({
                            show: true,
                            position: 'bottom',
                            type: 'success',
                            message: t('Диаграмма добавлена в My Dashboard'),
                        });
                    },
                };
            });
            // groups.push({
            //     key: 'add',
            //     text: t('Добавить новую группу'),
            //     onClick: () => {
            //         toggleModal({
            //             show: true,
            //             modalType: 'new_group',
            //             modalTitle: t('Добавить новую группу'),
            //             modalText: '',
            //             height: 'auto',
            //             width: '50%',
            //             modalArgs: {
            //                 name: '',
            //                 id: '',
            //                 props: { subsection: item },
            //             },
            //         });
            //     },
            // });
            return {
                disabled,
                key: item.id,
                text: item.translations['en'].name,
                subMenuProps: {
                    items: groups,
                },
            };
        });
        // items.push({
        //     key: 'add_new_subsection',
        //     text: t('Добавить новый раздел'),
        //     onClick: () => {
        //         toggleModal({
        //             show: true,
        //             modalType: 'new_subsection',
        //             modalTitle: t('Добавить новый раздел'),
        //             modalText: '',
        //             height: 'auto',
        //             width: '50%',
        //             modalArgs: {
        //                 name: '',
        //                 id: '',
        //                 props: {},
        //             },
        //         });
        //     },
        // });
        return {
            items,
        };
    }, [JSON.stringify(sectionsByKeyName.custom_section), JSON.stringify(permanentCloudData.custom_dataViews)]);

    const menuItems = [
        {
            key: 'refresh',
            iconProps: { iconName: 'Refresh' },
            name: t('Перезагрузить'),
            onClick: () => {
                props.onClickReloadDiagram(props.dataView, null, props.inputParamsValues);
            },
        },
        {
            key: 'description',
            iconProps: { iconName: 'Unknown' },
            name: t('Описание'),
            onClick: () => {
                const info = props.dataView.data_report.translations[props.lang].description;
                props._toggleModal({
                    show: true,
                    modalType: 'diagram_description',
                    modalTitle: `${props.dataView.data_report.translations[props.lang].name}`,
                    modalText: '',
                    height: 'auto',
                    width: '60%',
                    modalArgs: {
                        name: '',
                        id: '',
                        props: { info },
                    },
                    buttons: [
                        {
                            text: t('Закрыть'),
                            width: '100px',
                            action: null,
                        },
                    ],
                });
            },
        },
    ];

    if (sectionsByKeyName.custom_section && currentSection !== 'custom_section') {
        menuItems.push({
            key: 'add_to_custom_dashboard',
            iconProps: { iconName: 'AppIconDefaultAdd' },
            name: t('Добавить в My Dashboard'),
            subMenuProps: C_D_subMenuProps,
        });
    }
    // else if (sectionsByKeyName.custom_section && currentSection === 'custom_section' && props.editable) {
    //     menuItems.push({
    //         key: 'delete_from_custom_dashboard',
    //         name: t('Удалить из My Dashboard'),
    //         onClick: () => {
    //             deleteDiagramFromC_D({ dataView: props.dataView });
    //         },
    //     });
    // }

    if (props.showDiagram) {
        menuItems.push(
            {
                key: 'params',
                iconProps: { iconName: 'Settings' },
                name: t('Параметры'),
                onClick: () => {
                    props.onContextMenuReportParamsClick(props.dataView.data_view_id);
                },
            },
            {
                key: 'save_xls',
                iconProps: { iconName: 'ExcelLogoInverse' },
                name: t('Сохранить XLSX'),
                onClick: () => {
                    props.onSaveXLSXClick();
                },
            }
        );
    }

    if (fullScreen && fullScreen.allow && props.fullScreenId === null) {
        menuItems.push(
            {
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'expand',
                iconProps: { iconName: 'MiniExpand' },
                name: t('Развернуть'),
                onClick: () => {
                    props.onFullScreenClick(props.dataView.data_view_id);
                },
            }
        );
    } else if (fullScreen && fullScreen.allow && props.fullScreenId !== null) {
        menuItems.push(
            {
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'minimize',
                iconProps: { iconName: 'MiniContract' },
                name: t('Свернуть'),
                onClick: () => {
                    props.fullScreenExit();
                },
            }
        );
    }

    const onMouseEnterLeave = (props, show) => (e) => {
        if (!props.showDiagram) return;
        e.stopPropagation();
        const buttonCoords = {
            y: e.clientY,
            x: e.clientX,
        };
        props.generateAtomicTableData(buttonCoords, props, show);
    };

    const onMouseDown = (e) => {
        e.stopPropagation();
    };

    return (
        <Container
            onMouseEnter={onMouseEnterLeave(props, true)}
            fullScreenShow={props.fullScreenShow}
            onMouseDown={onMouseDown}
            // onMouseLeave={onMouseEnterLeave(props, false)}
        >
            <ContextMenu menuItems={menuItems} margin={'0'}>
                <Button />
            </ContextMenu>
        </Container>
    );
});

const Container = styled.div`
    background: transparent;
    position: absolute;
    top: 5px;
    right: 0px;
    width: 16px;
    height: 16px;
    padding: 0px;
    outline: none;
    border: none;
    /* padding-top: 5px; */
    cursor: pointer;
    z-index: 1000;
`;

const Button = styled.div`
    opacity: 0.3;
    width: 16px;
    height: 16px;
    background: url(${img}) no-repeat center;
    background-size: contain;
    &:hover {
        opacity: 0.8;
    }
`;
