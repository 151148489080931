import hostForLocation from "../hostnames/hostname";

const fetchAction = (request, ...fooNames) => (dispatch) => {
    const { url, ...rest } = request;
    return fetch(url, rest)
        .then((response) => response.json())
        .then((payload) =>
            fooNames.forEach((fooName) =>
                dispatch({
                    type: fooName,
                    payload,
                })
            )
        )
        .catch((err) => console.log(err));
};

const serverApiActions = {
    // getSections_Source: params => dispatch => {
    //     const request = {
    //         url: params.url,
    //         headers: {
    //             'Content-type': 'application/json',
    //             'x-token': params.headers['x-token']
    //         },
    //         mode: 'cors',
    //         method: 'GET',
    //         redirect: 'follow'
    //     };
    //     return dispatch(fetchAction(request, 'getSections_Source'));
    // },
    // getRequestLocation(params) {
    //     return dispatch => {
    //         var myInit = { method: 'GET', headers: params.headers, mode: 'cors' };
    //         return dispatch(fetchPosts(params, myInit, Actions.setLocation));
    //     };
    // },
    getDataObjSets(params) {
        return (dispatch) => {
            var myInit = { method: 'GET', headers: params.headers, mode: 'cors' };
            return dispatch(fetchPosts(params, myInit, Actions.getDataObjSets));
        };
    },
    getAvailableOwners(params) {
        return (dispatch) => {
            var myInit = { method: 'GET', headers: params.headers, mode: 'cors' };
            return dispatch(fetchPosts(params, myInit, Actions.getAvailableOwners));
        };
    },
    getAvailableProjects(params) {
        return (dispatch) => {
            var myInit = { method: 'GET', headers: params.headers, mode: 'cors' };
            return dispatch(fetchPosts(params, myInit, Actions.getAvailableProjects));
        };
    },
    getProjectLocations(params) {
        return (dispatch) => {
            var myInit = { method: 'GET', headers: params.headers, mode: 'cors' };
            return dispatch(fetchPosts(params, myInit, Actions.getLocations));
        };
    },

    getAnalyticsReportObjects(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'].access_token,
                },
                mode: 'cors',
            };
            return dispatch(fetchTest(params, myInit, Actions.getObjects));
        };
    },

    getVersionData_Fetch(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setVersionData_source));
        };
    },
    getDataByUuid_Fetch(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setDataByUuid_source));
        };
    },
    test_Source: (params) => (dispatch) => {
        const headers = { 'x-token': params.headers['x-token'].access_token };
        const request = { url: params.url, headers, method: 'GET', mode: 'cors' };
        return dispatch(fetchAction(request, 'test_Source'));
    },

    loginByToken(params) {
        return (dispatch) => {
            var details = {
                token: params.headers.token,
            };

            var formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + '=' + encodedValue);
            }
            formBody = formBody.join('&');
            var myInit = {
                method: 'GET',
                headers: params.headers2,
                mode: 'cors',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getloginByToken));
        };
    },

    getOutdoorAdSpaces(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getOutdoorSpaces));
        };
    },
    getOutdoorIPoints(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getIPoints));
        };
    },
    getAdSpacesTypes(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getSpacesTypes));
        };
    },
    getPolygonGroups(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getPolygonGroups));
        };
    },

    getDetailInputParams(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setDetailInputParams));
        };
    },

    getSchedulers(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setSchedulers));
        };
    },
    deleteSchedulers(params) {
        return (dispatch) => {
            var myInit = {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            const paramsForFunc = {
                url: `${hostForLocation.getHostName().export}/reports/schedulers/`,
                headers: params.headers
            }
            return dispatch(fetchDelete(params, myInit, serverApiActions.getSchedulersAfterDelete, paramsForFunc));
        };
    },
    deletePdfReport(params) {
        return (dispatch) => {
            var myInit = {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            const paramsForFunc = {
                url: `${hostForLocation.getHostName().export}/reports/pdf-reports/`,
                headers: params.headers
            }
            return dispatch(fetchDelete(params, myInit, serverApiActions.getPdfReportsAfterDelete, paramsForFunc));
        };
    },
    getSchedulersAfterDelete(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setSchedulers));
        };
    },
    getPdfReportsAfterDelete(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setPdfReports));
        };
    },
    getPdfReportsFile(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchFiles(params, myInit, Actions.setPdfFile));
        };
    },
    getPdfReports(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setPdfReports));
        };
    },
    // getRequestSections(params) {
    //     return dispatch => {
    //         var myInit = {
    //             method: 'GET',
    //             headers: {
    //                 'Content-type': 'application/json',
    //                 'x-token': params.headers['x-token']
    //             },
    //             mode: 'cors',
    //             redirect: 'follow'
    //         };
    //         return dispatch(fetchPosts(params, myInit, Actions.setSections));
    //     };
    // },
    setMaps(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getDataMaps));
        };
    },
    setTransition(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getRouterInfo));
        };
    },
    getPLId(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setPLIdInfo));
        };
    },
    createSendPlanSet(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.getSendPlanSet));
        };
    },
    getDataSelectPolygons(params) {
        return (dispatch) => {
            var myInit = {
                method: 'POST',
                headers: params.headers,
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
                body: params.body,
            };
            return dispatch(fetchPostsParams(params, myInit, Actions.setDataSelectPolygons, params.data));
        };
    },
    createPdfReports(params) {
        return (dispatch) => {
            var myInit = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
                body: params.data,
            };
            return dispatch(fetchPostsParams(params, myInit, Actions.getRequestNewPdfReportStatus, params.data));
        };
    },
    getUnekUsers(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setUnekUsers));
        };
    },
    getUnekUsersOfZones(params) {
        return (dispatch) => {
            var myInit = {
                method: 'GET',
                headers: {
                    'x-token': params.headers['x-token'],
                },
                mode: 'cors',
                redirect: 'follow',
                credentials: 'include',
                cache: 'no-cache',
            };
            return dispatch(fetchPosts(params, myInit, Actions.setUnekUsersOfZones));
        };
    },
    getTemplateControlPage(params) {
        paramsgetTemplateControlPage = params;

        return (dispatch) => {
            var myInit = {
                method: 'GET',

                mode: 'cors',
            };

            return dispatch(fetchPosts(params, myInit, Actions.setTemplateControl));
        };
    },
    getSchedulesList(params) {
        paramsgetSchedulesList = params;

        return (dispatch) => {
            var myInit = {
                method: 'GET',

                mode: 'cors',
            };

            return dispatch(fetchPosts(params, myInit, Actions.setScheduleList, Actions.setErrorRequestControlPage));
        };
    },
    deletePlansItem(params) {
        return (dispatch) => {
            var formData = new FormData();

            for (var name in params.data) {
                formData.append(name, params.data[name]);
            }
            var myInit = {
                method: 'POST',

                body: formData,

                credentials: 'include',
            };

            return dispatch(fetchPosts(params, myInit, [this.getTemplateControlPage, this.getSchedulesList]));
        };
    },
    save(params) {
        return (dispatch) => {
            var formData = new FormData();

            for (var name in params.data) {
                formData.append(name, params.data[name]);
            }
            var myInit = {
                method: 'POST',

                body: formData,

                credentials: 'include',
            };

            return dispatch(fetchPosts(params, myInit, [this.getTemplateControlPage, this.getSchedulesList, Actions.onSave]));
        };
    },

    getDeletePageTemplate(params) {
        return (dispatch) => {
            var formData = new FormData();

            for (var name in params.data) {
                formData.append(name, params.data[name]);
            }
            var myInit = {
                method: 'POST',

                body: formData,

                credentials: 'include',
            };

            return dispatch(fetchPosts(params, myInit, [this.getTemplateControlPage, this.getSchedulesList]));
        };
    },
    getCopyPageTemplate(params) {
        return (dispatch) => {
            var formData = new FormData();

            for (var name in params.data) {
                formData.append(name, params.data[name]);
            }
            var myInit = {
                method: 'POST',

                body: formData,

                credentials: 'include',
            };

            return dispatch(fetchPosts(params, myInit, [this.getTemplateControlPage, this.getSchedulesList]));
        };
    },
    getCreateNewPage(params) {
        return (dispatch) => {
            var formData = new FormData();

            for (var name in params.data) {
                formData.append(name, params.data[name]);
            }
            var myInit = {
                method: 'POST',

                body: formData,

                credentials: 'include',
            };

            return dispatch(fetchPosts(params, myInit, [this.getTemplateControlPage, this.getSchedulesList]));
        };
    },
};

const Actions = {};

let paramsgetTemplateControlPage = null;
let paramsgetSchedulesList = null;
let actionLabels = [
    'onSelectLeftPanel',
    'onTopMenuButtonClick',
    'selectPL',
    'selectCalendarType',
    'onCLickButton',
    'onClickButtonCompare',
    'selectDate',
    'selectCalendar',
    'onClickGlobalCompanent',
    'getPolygonGroups',
    'setLocation',
    'getDataObjSets',
    'getAvailableOwners',
    'getAvailableProjects',
    'getSelectLocation',
    'getSpacesTypes',
    'onClickAllLocation',
    'onChancgeInputSearch',
    'closePanelLocations',
    'onClickButtonLocations',
    'getStatusPanelDropDownOne',
    'getStatusPanelDropDownTwo',
    'getStatusPanelDropDownThree',
    'getIdPL',
    'getloginByToken',
    'getToken',
    'getObjects',
    'getLoadLogin',
    'hidePanelRepotringObjects',
    'getLoadReportingObjects',
    'onClickDash',
    'hideDropdownMenus',
    'onClickDashAllObject',
    'getDataInputParams',
    'setDetailInputParams',
    'onChangeSelect',
    'selectDataView',
    'hideSettingsPanel',
    'setSections',
    'getDataItemDataView',
    'getLoadDataDataView',
    'hideSpinerDataView',
    'getDataMaps',
    'getUpdate',
    'handleSaveCenter',
    'getSelectZone',
    'getStatusLoadTransition',
    'getRouterInfo',
    'setPLIdInfo',
    'getSendPlanSet',
    'setFloors',
    'setZones',
    'setRooms',
    'updateValue',
    'getFloorId',
    'selectPolygons',
    'setDataSelectPolygons',
    'getOutdoorSpaces',
    'getIPoints',
    'setUnekUsers',
    'aimOnPoint',
    'setUnekUsersOfZones',
    'showColorCount',
    'onClickTab',
    'onCheckBackgroundImage',
    'getLocation',
    'getLocationsId',
    'getLocationsIdForDate',
    'getLocations',
    'getLocationForInputParam',
    'getLocaleLanguage',
    'onClickDropDownZones',
    'getDateGlobal',
    'onDropDownLogout',
    'onLanguageChange',
    'setTemplateControl',
    'onMouseEnterRadius',
    'onMouseLeaveRadius',
    'setScheduleList',
    'deletePlansItemSchedules',
    'onEnterPlan',
    'planing',
    'openPanel',
    'getTimeMax',
    'getTimeMin',
    'selectAllDay',
    'onClickButtonAllLang',
    'selectAllTime',
    'selectAllDate',
    'resetData',
    'setStatusPlanPanel',
    'openSettingsPanel',
    'getDay',
    'showMaxCalendar',
    'getDate',
    'getBrowserLang',
    'onSave',
    'editPlan',
    'hideSettingsPanelAuth',
    'handleCloseContextMenu',
    'showPanelNameNewPage',
    'hidePanelNameNewPage',
    'onChangeNamePage',
    'handleRightSectorClick',
    'onSectorClick',
    'onChangeDateByTextInput',
    'onSelectDeselectAllClick',
    'onInputParameterChange',
    'generateAtomicTableData',
    'onAtomicTableSaveClick',
    'onShowTablesButtonClick',
    'saveTablesDataToStore',
    'onTableCheck',
    'onCompleteTablesModeChange',
    'saveReportParams',
    'selectLeftPanelButtonList',
    'setInitialZoom',
    'handleEditRouterClick',
    'getPlanId',
    'handleCloseEditModal',
    'handleInputChange',
    'handleSelectChange',
    'onClickLangBrowser',
    'setVersionData_source',
    'setDataByUuid_source',
    'getStatusShowModalPDF',
    'setSchedulers',
    'setPdfReports',
    'setDataObjects',
    'getRequestNewPdfReportStatus',
    'getRequestNewPdfReport',
    'hideSpinner',
    'setPdfFile',
    'getPdfReportsFileName',
    'setStatusShowSpinnerPdfReports',
    'setStatusSpinerPlan',
    'setErrorRequestControlPage',
    'isLoadSettingsRequest'
];

actionLabels.forEach((actionName) => {
    Actions[actionName] = (params) => {
        return { ...params, type: actionName };
    };
});

function fetchPosts(params, options, func, errorFunck = null) {
    return (dispatch) => {
        return fetch(params.url, options)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                // console.log('123456', json);
                if (func.length === 2) {
                    dispatch(func[0](paramsgetTemplateControlPage));
                    dispatch(func[1](paramsgetSchedulesList));
                }
                if (func.length === 3) {
                    dispatch(func[0](paramsgetTemplateControlPage));
                    dispatch(func[1](paramsgetSchedulesList));
                    dispatch(func[2]({ status: 'ok', t: params.t }));
                } else {
                    dispatch(func({ json: json }));
                }
            })
            .catch((err) => {
                
                if (func.length === 2) {
                    if (errorFunck !== null) {
                        errorFunck({ mess: err })
                    }
                } else if (func.length === 3) {
                    if (errorFunck !== null) {
                        errorFunck({ mess: err })
                    }
                    dispatch(func[2]({ status: 'error', t: params.t }));
                } else {
                    console.error(err);
                    
                    if (errorFunck !== null) {
                        
                        dispatch(errorFunck({ mess: err }));
                    }
                    dispatch(func({ json: err }));
                }
            });
    };
}

function fetchFiles(params, options, func) {
    return (dispatch) => {
        return fetch(params.url, options)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                // console.log('123456', json);
                if (func.length === 2) {
                    dispatch(func[0](paramsgetTemplateControlPage));
                    dispatch(func[1](paramsgetSchedulesList));
                }
                if (func.length === 3) {
                    dispatch(func[0](paramsgetTemplateControlPage));
                    dispatch(func[1](paramsgetSchedulesList));
                    dispatch(func[2]({ status: 'ok', t: params.t }));
                } else {
                    dispatch(func({ json: blob }));
                }
            })
            .catch((err) => {
                // console.log('12345error', err);
                if (func.length === 2) {
                } else if (func.length === 3) {
                    dispatch(func[2]({ status: 'error', t: params.t }));
                } else {
                    console.error(err);
                    dispatch(func({ json: err }));
                }
            });
    };
}

function fetchTest(params, options, func) {
    return (dispatch) => {
        return fetch(params.url, options)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                dispatch(func({ json: json }));
            })
            .catch((err) => {
                console.log('ERRROR', err);

                dispatch(func({ json: err }));
            });
    };
};

function fetchDelete(params, options, func, paramsForFunc) {
    return (dispatch) => {
        return fetch(params.url, options)
            .then((response) => {
                return response.json();

            })
            .then((json) => {
                // console.log('>>>>>>>>>>>>>', func)
                dispatch(func(paramsForFunc));
            })
            .catch((err) => {
                console.log('ERRROR', err);

                // dispatch(func({ json: err }));
            });
    };
};

function fetchPostsParams(params, options, func, data) {
    // console.log('>>>>>>>>>>>>>',params.url);
    return (dispatch) => {
        return fetch(params.url, { ...options })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                dispatch(func({ json: json, data: data }));
            })
            .catch((err) => {
                console.log('ERRROR', err);

                dispatch(func({ json: err }));
            });
    };
}

// export default Actions;

export function runAction(type, payload = {}) {
    if (type in serverApiActions) {
        return serverApiActions[type](payload);
    } else if (actionLabels.includes(type)) {
        const oldStylePayload = { ...payload, type };

        return oldStylePayload;
    } else {
        return {
            type,
            payload,
        };
    }
}
