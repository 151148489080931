import React, { useMemo, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import ObjSetsContext from '../../pl_panel/obj_sets_context';
import useHistory from './useHistory';
import ContextMenu from '../../../context_menu/context_menu';
import img from '../../img/three_dots_horizontal.svg';

export default React.memo(props => {
    const { history, reportingObjectsById_ctg, dataObjSetsById, onExpandObjSet, onChangeObjSet, onDeleteObjSet, _toggleModal } = useContext(
        ObjSetsContext
    );
    const { objects, canSaveSet } = useHistory(reportingObjectsById_ctg, history);
    const { t } = useTranslation();

    const isSelectedObjSets = useMemo(() => {
        const selectedNoPl = history.objSets.noPl.filter(set => set.selected).length;
        const selectedHasPl = Object.keys(history.objSets.hasPl).reduce((acc, plId) => {
            return (acc += history.objSets.hasPl[plId].sets.filter(set => set.selected).length);
        }, 0);
        return selectedNoPl + selectedHasPl !== 0;
    }, [history]);

    const _onChangeObjSet = id => {
        onChangeObjSet(id, props.name, objects);
    };

    const _onRenameObjSet = (id, name) => {
        _toggleModal({
            show: true,
            modalType: 'input_text',
            modalTitle: t('Переименовать набор'),
            modalText: t('Введите новое имя'),
            modalArgs: { id, name },
            buttons: [
                {
                    text: t('Сохранить'),
                    width: '100px',
                    action: {
                        name: 'renameObjSet'
                    }
                },
                {
                    text: t('Отмена'),
                    width: '100px',
                    action: null
                }
            ]
        });
    };

    const onObjSetInfo = (show, id, name) => {
        _toggleModal({
            show: true,
            modalType: 'obj_set_info',
            modalTitle: `${t('Набор объектов')} ${name}`,
            modalText: '',
            height: 'auto',
            width: '80%',
            modalArgs: {
                name: '',
                id: '',
                props: { id, history, reportingObjectsById_ctg, dataObjSetsById }
            },
            buttons: [
                {
                    text: t('Закрыть'),
                    width: '100px',
                    action: null
                }
            ]
        });
    };

    const menuItems = [];
    switch (props.сontextMenu.type) {
        case 'objSet':
            menuItems.push(
                {
                    key: 'info',
                    text: t('Информация'),
                    onClick: () => onObjSetInfo(true, props.id, props.name)
                },
                {
                    key: 'expand',
                    text: t('Развернуть'),
                    onClick: () => onExpandObjSet(props.id)
                },
                {
                    key: 'rename',
                    text: t('Переименовать'),
                    disabled: props.statusSelect,
                    onClick: () => _onRenameObjSet(props.id, props.name)
                },
                {
                    key: 'divider_2',
                    itemType: ContextualMenuItemType.Divider
                },
                {
                    key: 'delete',
                    text: t('Удалить'),
                    disabled: props.statusSelect,
                    onClick: () => onDeleteObjSet(props.id)
                }
            );
            canSaveSet &&
                menuItems.push(
                    {
                        key: 'divider_1',
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'overwrite',
                        text: t('Перезаписать'),
                        disabled: isSelectedObjSets,
                        onClick: () => _onChangeObjSet(props.id)
                    }
                );

            break;

        default:
            break;
    }

    return (
        <ContextMenu menuItems={menuItems}>
            <Button />
        </ContextMenu>
    );
});

const Button = styled.div`
    pointer-events: all;
    opacity: 0.2;
    width: 16px;
    height: 16px;
    background: url(${img}) no-repeat center;
    background-size: contain;
    &:hover {
        opacity: 0.8;
    }
`;
