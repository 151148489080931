import React, { useState, useEffect, useMemo, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import PdfPage from './pdf_page';
import createReportParams from '../../../xlsx/tools/create_report_params';

const Item = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #777;
    padding-top: 5px;
`;

const PdfReport = React.memo(({ list, ...props }) => {
    return list.map((item, i) => {
        return <PdfReportItem key={`${item.string}-${i}`} item={item} getReportItemEndPosition={props.getReportItemEndPosition} />;
    });
});

const PdfReportItem = React.memo(({ item, ...props }) => {
    const reportItemRef = useRef();

    useLayoutEffect(() => {
        if (reportItemRef.current) {
            const position = reportItemRef.current.offsetTop + reportItemRef.current.offsetHeight;
            const height = reportItemRef.current.offsetHeight;
            const id = item.id;
            props.getReportItemEndPosition({ id, position, height });
        }
    }, [reportItemRef.current]);

    return (
        <Item ref={reportItemRef}>
            <span>
                <b>{item.string[0]}</b>
            </span>
            <span>{item.string[1]}</span>
        </Item>
    );
});

const TitlesList = React.memo(
    ({
        currentSection,
        currentSubSection,
        input_parameter_list,
        lang,
        locationsСatalog,
        sectionsByKeyName,
        projectLocationsById,
        outdoorAdSpacesById,
        white_label_settings,
        WORK_HEIGHT,
        reportParams,
        ...props
    }) => {
        const [positions, setPositions] = useState({});
        const [report, setReport] = useState([]);

        useEffect(() => {
            const report = createReportParams(
                '',
                reportParams,
                input_parameter_list,
                lang,
                locationsСatalog,
                { currentSection, currentSubSection, sectionsByKeyName, projectLocationsById, outdoorAdSpacesById }
            );
            setReport(report);
        }, [reportParams, input_parameter_list, locationsСatalog, projectLocationsById, outdoorAdSpacesById, lang]);

        const getReportItemEndPosition = data => {
            const pos = positions;
            pos[data.id] = data;
            setPositions({ ...pos });
        };

        const pages = useMemo(() => {
            const pagesArr = [[]];
            let count = 0;
            if (Object.keys(positions).length !== 0 && Object.keys(positions).length === report.length) {
                report.forEach((string, i) => {
                    if (count < WORK_HEIGHT) {
                        pagesArr[pagesArr.length - 1].push({ string, id: i });
                        count = count + positions[i].height;
                    } else {
                        pagesArr.push([{ string, id: i }]);
                        count = 0;
                    }
                });
            } else {
                pagesArr[0].push(...report.map((string, i) => ({ string, id: i })));
            }

            return pagesArr;
        }, [report, positions]);

        const renderPagesList = () => {
            if (!pages) return null;
            const result = pages.map((page, i) => {
                return (
                    <PdfPage
                        currentSubSection={currentSubSection}
                        currentSection={currentSection}
                        logo={white_label_settings.small_logo_url}
                        lang={lang}
                        key={`ppage///${i}`}
                    >
                        <PdfReport list={page} getReportItemEndPosition={getReportItemEndPosition} />
                    </PdfPage>
                );
            });
            return <React.Fragment>{result}</React.Fragment>;
        };

        return renderPagesList();
    }
);

export default TitlesList;
